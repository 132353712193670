import React from 'react';
import { Button, EditIcon, IconButton, } from '@commonsku/styles';

const CheckoutPanelEditButton = ({ color, style = {}, onClick, children = 'Edit', iconProps={}, ...props }) => (
  <IconButton
    float='right'
    style={{
      color: color,
      padding: '5px 5px',
      ...style,
    }}
    className='shop-checkout-box-edit-btn'
    size='medium'
    variant='text'
    onClick={onClick}
    Icon={EditIcon}
    iconProps={{
      color: color,
      hoverColor: color,
      ...iconProps
    }}
    {...props}
  ><span style={{ color }}>{children}</span></IconButton>
);

export default CheckoutPanelEditButton;
