import React, { useState } from 'react';
import styled from 'styled-components';

import Tooltip from './helpers/ReactTooltip';

const StyledWrapper = styled.div`
  &&& {
    position: relative;
    width: 0;
    height: 0;
    top: 0;
    left: 0;

    > * {
      display: none;
    }

    >.show {
      display: inline-block;
    }
  }
`;

let autoId = 0;
const DisabledColorSizeTooltip = ({ showTooltip, children }) => {
  const [show, setShow] = useState(false);
  autoId += 1;
  const id = `disabled-color-size-tooltip-${autoId}`;
  return <>
    <StyledWrapper>
      <Tooltip
        id={id} place="bottom"
        afterShow={() => {
          setShow(true);
        }}
        afterHide={() => {
          setShow(false);
        }}
        className={show ? 'show' : ''}
      >
        <div style={{ color: "rgb(210,215,220)", fontWeight: 'bold' }}>
          This product doesn't allow you to change<br/> sizes and colors.
        </div>
      </Tooltip>
    </StyledWrapper>
    <span data-for={showTooltip ? id : null} data-tip="">
      {children}
    </span>
  </>;
};

export default DisabledColorSizeTooltip;
