import { UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE, LOAD_ORDER_SUCCESS, LOAD_ORDER_LIST_SUCCESS, UPDATE_ORDER_DISABLE_SMART_SUCCESS, DELETE_ORDER_SUCCESS, UPDATE_ORDER_TAX_ID_SUCESS } from '../actions/order';
import { UPDATE_PROJECT_SUCCESS } from '../actions/project';
import { DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS, UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS, UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS, UPDATE_SHOP_SUCCESS } from '../actions/shop';
import {
  ADD_ITEM_SUCCESS,
  UPDATE_ITEM_SUCCESS,
  DELETE_ITEM_SUCCESS,

  ADD_ITEM_BREAKDOWN_SUCCESS,
  UPDATE_ITEM_BREAKDOWN_SUCCESS,
  DELETE_ITEM_BREAKDOWN_SUCCESS,

  ADD_ITEM_COST_SUCCESS,
  UPDATE_ITEM_COST_SUCCESS,
  DELETE_ITEM_COST_SUCCESS,

  ADD_ITEM_LOCATION_SUCCESS,
  UPDATE_ITEM_LOCATION_SUCCESS,
  DELETE_ITEM_LOCATION_SUCCESS,

  ADD_ITEM_TAX_AMOUNT_SUCCESS,
  UPDATE_ITEM_TAX_AMOUNT_SUCCESS,
  DELETE_ITEM_TAX_AMOUNT_SUCCESS,

  UPDATE_ITEM_DECORATION_SUCCESS
} from '../actions/item';
import { CALCULATE_AVALARA_TAX } from '../actions/tax';
import { ADD_ORDER_SUCCESS } from '../actions/project';
import { ADD_EMAIL_SUCCESS } from '../actions/email';
import { ADD_BILL_SUCCESS, UPDATE_BILL_SUCCESS } from '../actions/bill';
import { UPDATE_PURCHASE_ORDER_SUCCESS, REGENERATE_PURCHASE_ORDER_SUCCESS, DELETE_PURCHASE_ORDER_SUCCESS } from '../actions/purchase_order';
import { ADD_COLLECTION_IMAGE_SUCCESS } from '../actions/collection';

const reduce = (state = {}, action) => {
  let order_id, updated_order, templateId;
  switch (action.type) {
    case UPDATE_ORDER_REQUEST:
      // fall through
    case UPDATE_ORDER_SUCCESS:
      // fall through
    case UPDATE_ORDER_FAILURE:
      order_id = action.payload.id;
      updated_order = Object.assign({}, state[order_id], action.payload.data);
      return Object.assign({}, state, {[order_id]: updated_order});
    case UPDATE_PROJECT_SUCCESS:
      return { ...state, ...(action.payload.project?.orders ?? []).reduce(
        (oo, o) => ({ ...oo, [o.order_id]: { ...state[o.order_id], ...o } }),
        {}
      )};
    case UPDATE_ORDER_TAX_ID_SUCESS:
      return { ...state, [action.payload.id]: {...state[action.payload.id], ...action.payload.data.order } };
    case LOAD_ORDER_LIST_SUCCESS:
      return Object.assign({}, state, action.payload.orders.reduce((o, d) => { o[d.order_id] = d; return o; }, {}));
    case UPDATE_ITEM_SUCCESS:
    case UPDATE_ITEM_BREAKDOWN_SUCCESS:
    case UPDATE_ITEM_COST_SUCCESS:
    case UPDATE_ITEM_LOCATION_SUCCESS:
      if (action.payload.response === undefined) {
        return state;
      }
      order_id = action.payload.response.order.order_id;
      updated_order = Object.assign({}, state[order_id], action.payload.response.order);
      return Object.assign({}, state, {[order_id]: updated_order});
    case UPDATE_ITEM_DECORATION_SUCCESS:
      return {
        ...state,
        [action.payload.order.order_id]: {
          ...state[action.payload.order.order_id],
          ...action.payload.order
        }
      };
    case UPDATE_ITEM_TAX_AMOUNT_SUCCESS:
    case ADD_ITEM_SUCCESS:
    case DELETE_ITEM_SUCCESS:
    case ADD_ITEM_BREAKDOWN_SUCCESS:
    case DELETE_ITEM_BREAKDOWN_SUCCESS:
    case ADD_ITEM_COST_SUCCESS:
    case DELETE_ITEM_COST_SUCCESS:
    case ADD_ITEM_LOCATION_SUCCESS:
    case DELETE_ITEM_LOCATION_SUCCESS:
    case ADD_ITEM_TAX_AMOUNT_SUCCESS:
    case DELETE_ITEM_TAX_AMOUNT_SUCCESS:
      order_id = action.payload.order.order_id;
      updated_order = Object.assign({}, state[order_id], action.payload.order);
      return Object.assign({}, state, {[order_id]: updated_order});
    case CALCULATE_AVALARA_TAX:
      return {
        ...state,
        [action.payload.order.order_id]: {
          ...state[action.payload.order.order_id],
          ...action.payload.order,
        }
      };
    case ADD_ORDER_SUCCESS:
      order_id = action.payload.data.order.order_id;
      updated_order = Object.assign({}, state[order_id], action.payload.data.order);
      return Object.assign({}, state, {[order_id]: updated_order});
    case DELETE_ORDER_SUCCESS:
      order_id = action.payload.data.order_id;
      return Object.keys(state).filter(k => k !== order_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case ADD_EMAIL_SUCCESS:
      if ('FEEDBACK' === action.payload.details_parent_type.toUpperCase()) {
        return Object.assign({}, state, {[action.payload.details_parent_id]: Object.assign({}, state[action.payload.details_parent_id], { feedback_requested: 1 })});
      }
      break;
    case ADD_BILL_SUCCESS:
    case UPDATE_BILL_SUCCESS:
      return Object.assign({}, state, action.payload.orders.reduce((o, i) => { o[i.order_id] = Object.assign({}, state[i.order_id], i); return o; }, {}));
    case UPDATE_ORDER_DISABLE_SMART_SUCCESS :
      order_id = action.payload.id;
      updated_order = Object.assign({}, state[order_id], action.payload.data);
      return Object.assign({}, state, {[order_id]: updated_order});
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.order.order_id]: Object.assign({}, state[action.payload.order.order_id], Object.keys(action.payload.order).filter(
          k => k !== 'items'
        ).reduce((o, k) => {
          o[k] = action.payload.order[k];
          return o;
        }, {}))
      });
    case UPDATE_PURCHASE_ORDER_SUCCESS:
    case DELETE_PURCHASE_ORDER_SUCCESS:
      if (!action.payload.order) {
          return state;
      }
      return Object.assign({}, state, {[action.payload.order.order_id]: Object.assign({}, state[action.payload.order.order_id], action.payload.order)});
    case REGENERATE_PURCHASE_ORDER_SUCCESS:
      return Object.assign({}, state, {[action.payload.order.order_id]: Object.assign({}, state[action.payload.order.order_id], { status_id: action.payload.order.status_id })});
    case ADD_COLLECTION_IMAGE_SUCCESS:
      return Object.assign({}, state, {[action.payload.data.collection_id]: Object.assign({}, state[action.payload.data.collection_id], { collection_images: state[action.payload.data.collection_id].collection_images.filter(i => i.collection_image_id !== action.payload.id).concat(action.payload.data) }) });
    case UPDATE_SHOP_SUCCESS:
      const shop = action?.payload?.response?.shop;
      if (shop?.order_id) {
        return {
          ...state,
          [shop.order_id]: {
            ...state[shop.order_id],
            default_avalara_category_id: shop.default_avalara_category_id,
            avalara_shipping_category_id: shop.avalara_shipping_category_id
          }
        };
      }
      return state;
    case UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS:
      if (action.payload.template_data.parent_type !== 'ORDER') {
        return state;
      }
      order_id = action.payload.template_data.parent_id;
      templateId = action.payload.template_data.public_view_template_id;
      updated_order = { template_data: {}, ...state[order_id] };
      const new_template_data = {
        ...(updated_order.template_data[templateId] || {}),
        [action.payload.template_data.name]: action.payload.template_data
      };
      return {
        ...state,
        [order_id]: {
          ...updated_order,
          template_data: {
            ...updated_order.template_data,
            [templateId]: new_template_data,
          }
        }
      };
    case UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS:
      if (action.payload.parent_type !== 'ORDER') {
        return state;
      }
      order_id = action.payload.parent_id;
      updated_order = { template_data: {}, ...state[order_id] };

      if (
        action.payload.deleteNames &&
        action.payload.deleteNames.length > 0
      ) {
        Object.keys(updated_order.template_data[updated_order.public_view_layout_id]).forEach(k => {
          const value = updated_order.template_data[updated_order.public_view_layout_id][k];
          if (action.payload.deleteNames.includes(value.name)) {
            delete updated_order.template_data[updated_order.public_view_layout_id][k];
          }
        });
      }

      if (action.payload.changeType === 'reset-poster-category') {
        updated_order.template_data[updated_order.public_view_layout_id] = {};
      }

      for (const template_data of action.payload.template_data) {
        const templateId = template_data.public_view_layout_id;
        const new_template_data = {
          ...(updated_order.template_data[templateId] || {}),
          [template_data.name]: template_data
        };
        updated_order.template_data = {
          ...updated_order.template_data,
          [templateId]: new_template_data,
        };
      }

      if (action.payload.changeType === 'reset-category-images') {
        Object.keys(updated_order.template_data[updated_order.public_view_layout_id]).forEach(k => {
          const matched = k.match(/CATEGORY-.*-IMAGE-HOVER/g);
          if (matched && matched.length > 0) {
            delete updated_order.template_data[updated_order.public_view_layout_id][k];
          }
        });
      }

      return { ...state, [order_id]: { ...updated_order } };
    case DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS:
      if (action.payload.parent_type !== 'ORDER') {
        return state;
      }
      order_id = action.payload.parent_id;
      templateId = action.payload.template_id;
      updated_order = { template_data: {}, ...state[order_id] };
      const itemsToRemove = action.payload.name.split(',');
      const items = Object.assign({}, updated_order.template_data[templateId]);
      for (const item of itemsToRemove) {
        delete items[item];
      }

      return {
        ...state,
        [order_id]: {
          ...updated_order,
          template_data: { ...updated_order.template_data, [templateId]: items }
        }
      };
  }
  return state;
};

export default reduce;
