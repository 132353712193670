import React from 'react';
import { connect } from 'react-redux';

import { createSelectItemImagesPopup } from '../../actions/popup';

import { getItemImages, getOrder } from '../../selectors/items';

import { getImageSrc } from '../../utils';

import Img from '../Img';

const selectItemImages = item_id => (dispatch, getState) => {
  const order = getOrder(getState(), { item_id });
  dispatch(createSelectItemImagesPopup(item_id, order.client_id, 'CLIENT', 1 == order.locked));
};

class ProductImage extends React.Component {

  constructor(props) {
    super(props);

    ['handleClickImage'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleClickImage(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    dispatch(selectItemImages(item_id));
  }

  render() {
    const { images } = this.props;
    return (
      <div className="item-image-container" onClick={this.handleClickImage}>
        <div className="main">
          <Img src={getImageSrc(images[0], 'large')} />
          <div className="edit">Click to Edit</div>
        </div>
        {images.slice(2, 3).map(image =>
          <div key={image.file_id} className="extra">
            <Img src={getImageSrc(image)} />
          </div>
        )}
        {3 < images.length &&
          <div className="extra">
            {4 === images.length ?
              <Img src={getImageSrc(images[3])} /> :
              <div className="more">+{images.length - 3} more</div>
            }
          </div>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item_images = getItemImages(state, { item_id: ownProps.item_id });
  return {
    images: item_images.map(ii => ii.image)
  };
};

export default connect(mapStateToProps)(ProductImage);
