import React, { Component } from 'react';

import { BASE_ZINDEX } from '../popup-factory';

class Overlay extends Component {
  render() {
    const { popups } = this.props;
    if (popups.length) {
      const style = {
        display: 'block',
        overflow: 'hidden',
        zIndex: BASE_ZINDEX + popups.length - 2
      };
      return (<div className="reveal-overlay" tabIndex="-1" aria-hidden="true" style={style}></div>);
    }
    return null;
  }
};

export default Overlay;
