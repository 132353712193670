import { get, map, mapValues, filter, groupBy, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const useCommunityEvents = () => {
  const { community_events, companies } = useSelector(state => state.community_events.entities);

  return map(community_events, (event) => {
    return {
      ...event,
      company: get(companies, [event.company_id]),
    };
  });
};

export const useUpcomingPastCommunityEvents = () => {
  const events = useCommunityEvents();
  return [
    orderBy(
      filter(events, ({ end_time, start_time, scheduled_time }) => {
        return !end_time && (moment(scheduled_time).isAfter(moment().add(-5, 'minutes')) || start_time);
      }),
      ['scheduled_time'],
      ['asc']
    ),
    orderBy(
      filter(events, ({ end_time }) => {
        return !!end_time;
      }),
      ['scheduled_time'],
      ['desc']
    ),
  ];
};

export const useCompanies = () => {
  const { companies, users, collections } = useSelector(state => state.community_events.entities);
  const companyUsers = groupBy(users, 'company_id');
  const companyCollections = groupBy(collections, 'supplier_id');

  return mapValues(companies, (company) => {
    return {
      ...company,
      users: filter(get(companyUsers, [company.company_id], []), ({ hidden }) => {
        return hidden == "0" || hidden == company.hidden;
      }),
      collections: get(companyCollections, [company.company_id], []),
    };
  });
};
