import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Header from '../components/Header';

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    messages: _.get(state, 'display.messages', []),
  };
};

export default connect(mapStateToProps)(Header);

