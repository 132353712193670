import { ADD_COLLABORATE_MESSAGE_SUCCESS, UPDATE_SUGGESTIONS_SUCCESS, SYNC_COLLABORATE_MESSAGE_SUCCESS } from '../actions/project';
import { ADD_COLLABORATE_COMMENT_SUCCESS } from '../actions/message';

export const collaborateMessageReducer = (state = {}, action) => {
  let event;
  let actor;
  let supplier;
  let event_id;
  let message_id;
  let updated_obj;
  let files;
  let event_parent_id;

  switch (action.type) {
    case ADD_COLLABORATE_MESSAGE_SUCCESS:
      event = action.payload.event;
      actor = action.payload.tmpl.actor;
      files = action.payload.files;
      event_id = event.event_id;

      if(action.payload.supplier.id) {
        supplier = action.payload.supplier;

        return Object.assign({}, state, {[event_id]: {
          event_id: event.event_id,
          actor: {
            user_image_paths: actor.user_image_paths,
            user_first_name: actor.user_first_name,
            user_last_name: actor.user_last_name,
          },
          event_email_id: supplier.contact_email,
          supplier: supplier,
          date_created: event.date_created,
          text: supplier.content,
          parent_id: event.event_parent_id,
          note_id: event.event_note_id,
          latest_update: event.latest_update,
          comments: [],
          replies: [],
          files: files ? files : null
        }});
      }else{
        let content = action.payload.tmpl.data.content;

        return Object.assign({}, state, {[event_id]: {
          event_id: event.event_id,
          actor: {
            user_image_paths: actor.user_image_paths,
            user_first_name: actor.user_first_name,
            user_last_name: actor.user_last_name,
          },
          event_supplier_id: event.event_supplier_id,
          date_created: event.date_created,
          text: content,
          parent_id: event.event_parent_id,
          note_id: event.event_note_id,
          latest_update: event.latest_update,
          comments: [],
          replies: [],
          files: files ? files : null
        }});
      }
    case UPDATE_SUGGESTIONS_SUCCESS:
      if(!action.payload.suggestion.event_id || action.payload.params !== 'collaborate') {
        return state;
      }

      event_id = action.payload.suggestion.event_id;
      message_id = action.payload.suggestion.message_id;
      let suggestion_id = action.payload.suggestion.suggestion_id;
      let first_obj;

      first_obj = Object.assign({}, state[event_id].replies[message_id].suggestions, { [suggestion_id]: Object.assign({}, state[event_id].replies[message_id].suggestions[suggestion_id], {is_added: 1})});
      updated_obj = Object.assign({}, state[event_id].replies, { [message_id]: Object.assign({}, state[event_id].replies[message_id], {suggestions: first_obj})});
      return Object.assign({}, state, { [event_id]: Object.assign({}, state[event_id], {replies: updated_obj})});
    case ADD_COLLABORATE_COMMENT_SUCCESS:
      let updated_message = action.payload.message;
      let parent_id = updated_message.parent_id;
      message_id = updated_message.message_id;
      let comment;
      if (updated_message.event_parent_id) {
        comment = {
          message_id: updated_message.message_id,
          actor: {
            user_image_paths: updated_message.user_image_paths,
            user_first_name: updated_message.user_first_name,
            user_last_name: updated_message.user_last_name,
          },
          date: updated_message.date,
          text: updated_message.message_text,
          parent_id: updated_message.parent_id,
          latest_update: updated_message.latest_update,
          event_parent_id: updated_message.event_parent_id,
          original_parent_id: updated_message.parent_id,
          original_parent_type: updated_message.parent_type.toLowerCase()
        };
      } else {
        comment = {
          message_id: updated_message.message_id,
          actor: {
            user_image_paths: updated_message.user_image_paths,
            user_first_name: updated_message.user_first_name,
            user_last_name: updated_message.user_last_name,
          },
          date: updated_message.date,
          text: updated_message.message_text,
          parent_id: updated_message.parent_id,
          latest_update: updated_message.latest_update,
          original_parent_id: updated_message.parent_id,
          original_parent_type: updated_message.parent_type.toLowerCase()
        };
      }

      if(state[parent_id].comments) {
        updated_obj = Object.assign({}, state[parent_id].comments, { [message_id]: comment });
        return Object.assign({}, state, {[parent_id]: Object.assign({}, state[parent_id], {comments: updated_obj })});
      }else{
        updated_obj = Object.assign({}, state[parent_id].replies, { [message_id]: comment });
        return Object.assign({}, state, {[parent_id]: Object.assign({}, state[parent_id], {replies: updated_obj })});
      }
    case SYNC_COLLABORATE_MESSAGE_SUCCESS:
      return action.payload.collaborate_messages.reduce((o, m) => { o[m.message_id] = m; return o; }, {});
  }
  return state;
};

