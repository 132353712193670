import React from 'react';
import { connect } from 'react-redux';

import { createUpdate } from '../../actions';
import * as actions from '../../actions/item';

import { getItem } from '../../selectors/items';

import TextArea from '../TextArea';

const updateItem = createUpdate('item', {
  request: actions.SIMPLE_UPDATE_ITEM_REQUEST,
  success: actions.SIMPLE_UPDATE_ITEM_SUCCESS,
  failure: actions.SIMPLE_UPDATE_ITEM_FAILURE,
  failure_message: 'Update to update item'
});

class PriceLabel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      price_label: props.price_label
    };
    ['handleChangePriceLabel', 'handleUpdatePriceLabel'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangePriceLabel(e) {
    this.setState({ price_label: e.target.value });
  }

  handleUpdatePriceLabel(e) {
    if (this.props.price_label !== this.state.price_label) {
      this.props.dispatch(updateItem(this.props.item_id, 'price_label', this.props.price_label, this.state.price_label));
    }
  }

  render() {
    const { locked } = this.props;
    return (
      <div className="row columns small-12">
        <div style={{ float: 'left', marginRight: '1rem' }}>
          <h2>Price&nbsp;Label</h2>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <TextArea
	    className="field1"
            style={{ width: '100%' }}
            value={this.state.price_label}
            onChange={this.handleChangePriceLabel}
            onBlur={this.handleUpdatePriceLabel}
            disabled={locked}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, { item_id: ownProps.item_id });
  return {
    price_label: item.price_label
  };
};

export default connect(mapStateToProps)(PriceLabel);
