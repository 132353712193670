import _, { filter, isEmpty, trim } from 'lodash';
import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createDismissCallout } from '../actions/callout';
import { getSupplierAccountOptions, getTaxOptions, getBillItemTypeOptions, getExchangeRate } from '../selectors/dropdowns';
import DropdownMenu from './BasicDropdownMenu';
import { formatMoney, parseMysqlDate, isNumeric, oauth, toTitleCase } from '../utils';
import { createAddBill, createUpdateBill, createUpdateBillExchangeRate } from '../actions/bill';
import Callout from './Callout';
import Form, { createFormSelect } from './Form';
import TaxSelect from './TaxSelect';

const FormTaxSelect = createFormSelect(TaxSelect);

const getBreakdownSubtotal = breakdown => parseFloat(formatMoney(parseFloat(breakdown.quantity) * parseFloat(breakdown.unit_cost)));
const getBreakdownTaxes = (breakdown, taxes, getTaxInfo) => parseFloat(formatMoney(taxes.map(
  t => parseFloat(getTaxInfo(t.tax_id)?.percent ?? 0) / 100
).map(
  t => getBreakdownSubtotal(breakdown) * t).reduce((total, amount) => total + parseFloat(amount), 0)
));
const getItemQuantity = item => parseFloat(item.breakdowns.reduce((total, breakdown) => total + parseFloat(breakdown.quantity), 0)).toFixed(0);
const getItemSubtotal = item => parseFloat(formatMoney(item.breakdowns.reduce((total, breakdown) => total + getBreakdownSubtotal(breakdown), 0) + item.costs.reduce((total, cost) => total + getBreakdownSubtotal(cost), 0)));
const getItemTaxes = (item, getTaxInfo) => parseFloat(formatMoney(item.breakdowns.reduce((total, breakdown) => total + getBreakdownTaxes(breakdown, item.taxes, getTaxInfo), 0) + item.costs.reduce((total, cost) => total + getBreakdownTaxes(cost, item.taxes, getTaxInfo), 0)));
const getBillOrderSubtotal = po => parseFloat(formatMoney(parseFloat(formatMoney(po.items.filter(i => i.selected).reduce((total, item) => total + getItemSubtotal(item), 0))) + parseFloat(formatMoney(po.extra_items.filter(i => i.selected).reduce((total, item) => total + getBreakdownSubtotal(item), 0)))));
const getBillOrderTaxes = (po, getTaxInfo) => parseFloat(formatMoney(parseFloat(formatMoney(po.items.filter(i => i.selected).reduce((total, item) => total + getItemTaxes(item, getTaxInfo), 0))) + parseFloat(formatMoney(po.extra_items.filter(i => i.selected).reduce((total, item) => total + getBreakdownTaxes(item, item.taxes, getTaxInfo), 0)))));
const getBillSubtotal = bill => parseFloat(formatMoney(parseFloat(formatMoney(bill.purchase_orders.reduce((total, po) => total + getBillOrderSubtotal(po), 0))) + parseFloat(formatMoney(bill.orders.reduce((total, o) => total + getBillOrderSubtotal(o), 0)))));
const getBillTaxes = (bill, getTaxInfo) => parseFloat(formatMoney(parseFloat(formatMoney(bill.purchase_orders.reduce((total, po) => total + getBillOrderTaxes(po, getTaxInfo), 0))) + parseFloat(formatMoney(bill.orders.reduce((total, o) => total + getBillOrderTaxes(o, getTaxInfo), 0)))));

let extraItemId = 0;
const getExtraItemId = () => `ID${++extraItemId}`;

class BillItemBreakdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      breakdown: Object.assign({}, this.props.breakdown, {
        quantity: this.formatValue('quantity', this.props.breakdown.quantity),
        unit_cost: this.formatValue('unit_cost', this.props.breakdown.unit_cost)
      })
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      breakdown: Object.assign({}, nextProps.breakdown, {
        quantity: this.formatValue('quantity', nextProps.breakdown.quantity),
        unit_cost: this.formatValue('unit_cost', nextProps.breakdown.unit_cost)
      })
    });
  }

  handleChange(field) {
    return e => {
      this.setState({
        breakdown: Object.assign({}, this.state.breakdown, {[field]: e.target.value})
      });
    };
  }

  handleBlur(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        const breakdown = Object.assign({}, this.state.breakdown, {[field]: this.formatValue(field, e.target.value)});
        this.props.onUpdate(
          breakdown
        );
      } else {
        this.setState({
          breakdown: this.props.breakdown
        });
      }
    };
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'unit_cost':
        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  render() {
    const { breakdown } = this.state;
    const { taxes, zip2tax, getTaxInfo, canEdit } = this.props;
    const subtotal = getBreakdownSubtotal(breakdown);
    const tax_amount = getBreakdownTaxes(breakdown, taxes, getTaxInfo);
    const total = subtotal + tax_amount;

    let desc = '';
    const optionDesc = trim(breakdown.option_description);
    if (isEmpty(optionDesc)) {
      let size_name = breakdown.size_name;
      let color_name = breakdown.color_name;
      if (breakdown.sku && breakdown.sku_options && (size_name === 'TBD' || color_name === 'TBD')) {
        try {
          const sku_options = JSON.parse(breakdown.sku_options);
          if (sku_options?.size) {
            size_name = sku_options.size;
          }
          if (sku_options?.color) {
            color_name = sku_options.color;
          }
        } catch (error) {
          // do nothing
        }
      }

      desc = filter([size_name, color_name]).join(' / ');
    } else {
      desc = filter([breakdown.sku, trim(breakdown.option_description)]).join(' ');
    }

    return (
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{desc}</td>
        <td><input type="text" value={breakdown.quantity} onChange={this.handleChange('quantity')} onBlur={this.handleBlur('quantity')} disabled={!canEdit} /></td>
        <td><input type="text" value={breakdown.unit_cost} onChange={this.handleChange('unit_cost')} onBlur={this.handleBlur('unit_cost')} disabled={!canEdit} /></td>
        <td>${formatMoney(subtotal)}</td>
        <td>&nbsp;</td>
        <td>${formatMoney(total)}</td>
        <td>&nbsp;</td>
      </tr>
    );
  }
}

class BillItemCost extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cost: Object.assign({}, this.props.cost, {
        quantity: this.formatValue('quantity', this.props.cost.quantity),
        unit_cost: this.formatValue('unit_cost', this.props.cost.unit_cost)
      })
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      cost: Object.assign({}, nextProps.cost, {
        quantity: this.formatValue('quantity', nextProps.cost.quantity),
        unit_cost: this.formatValue('unit_cost', nextProps.cost.unit_cost)
      })
    });
  }

  handleChange(field) {
    return e => {
      this.setState({
        cost: Object.assign({}, this.state.cost, {[field]: e.target.value})
      });
    };
  }

  handleBlur(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        const cost = Object.assign({}, this.state.cost, {[field]: this.formatValue(field, e.target.value)});
        this.props.onUpdate(
          cost
        );
      } else {
        this.setState({
          cost: this.props.cost
        });
      }
    };
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'unit_cost':
        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  render() {
    const { cost } = this.state;
    const { taxes, getTaxInfo, canEdit } = this.props;
    const subtotal = getBreakdownSubtotal(cost);
    const tax_amount = getBreakdownTaxes(cost, taxes, getTaxInfo);
    const total = subtotal + tax_amount;
    return (
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>{cost.item_name}</td>
        <td><input type="text" value={cost.quantity} onChange={this.handleChange('quantity')} onBlur={this.handleBlur('quantity')} disabled={!canEdit} /></td>
        <td><input type="text" value={cost.unit_cost} onChange={this.handleChange('unit_cost')} onBlur={this.handleBlur('unit_cost')} disabled={!canEdit} /></td>
        <td>${formatMoney(subtotal)}</td>
        <td>&nbsp;</td>
        <td>${formatMoney(total)}</td>
        <td>&nbsp;</td>
      </tr>
    );
  }
}

class BillItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selected: true,
      item: this.props.item
    };

    _.bindAll(this, ['handleToggleCollapse', 'handleToggleSelected', 'onUpdateBreakdown', 'onUpdateCost', 'onChangeTax', 'onDeleteTax', 'handleAddTax']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ item: nextProps.item });
  }

  handleToggleSelected(e) {
    const item = Object.assign({}, this.state.item, { selected: !this.state.item.selected });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  handleToggleCollapse(e) {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onUpdateBreakdown(breakdown) {
    const item = Object.assign({}, this.state.item, {
      breakdowns: this.state.item.breakdowns.map(b => {
        if (b.bill_item_id === breakdown.bill_item_id) {
          return breakdown;
        } else {
          return b;
        }
      })
    });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  onUpdateCost(cost) {
    const item = Object.assign({}, this.state.item, {
      costs: this.state.item.costs.map(c => {
        if (c.bill_item_id === cost.bill_item_id) {
          return cost;
        } else {
          return c;
        }
      })
    });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  onChangeTax(index) {
    const { onUpdate } = this.props;
    return (tax_id) => {
      const item = { ...this.state.item, taxes: this.state.item.taxes.map((t, i) => i === index ? { ...t, tax_id } : t) };
      this.setState({ item });
      onUpdate(item);
    };
  }

  onDeleteTax(index) {
    return () => {
      const taxes = this.state.item.taxes.filter((t, i) => i !== index);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item });
      this.props.onUpdate(item);
    };
  }

  handleAddTax(e) {
    e.preventDefault();
    const taxes = this.state.item.taxes.concat({ tax_id: this.props.default_tax_id });
    const item = Object.assign({}, this.state.item, { taxes });
    this.setState({ item });
    this.props.onUpdate(item);
  }

  render() {
    const { item } = this.state;
    const { zip2tax, getTaxInfo, canEdit } = this.props;
    const quantity = item.breakdowns.length ? getItemQuantity(item) : null;
    const subtotal = getItemSubtotal(item);
    const tax_amount = getItemTaxes(item, getTaxInfo);
    const total = subtotal + tax_amount;

    return (
      <tbody>
        <tr>
          <td>
            {canEdit ? <input type="checkbox" onChange={this.handleToggleSelected} checked={this.state.item.selected}></input> : null}
          </td>
          <td>
            {item.product_code}
          </td>
          <td>
            {item.product_name}
          </td>
          <td>
            {quantity}
          </td>
          <td>
          </td>
          <td>
            ${formatMoney(subtotal)}
          </td>
          <td>
            {item.taxes.map((t, index) => {
              const tax = Object.assign({label: '* Unknown *', percent: 0}, getTaxInfo(t.tax_id));
              return <span key={index}>{tax.label} {parseFloat(tax.percent).toFixed(2)}%<br /></span>;
            })}
          </td>
          <td>
            ${formatMoney(total)}
          </td>
          <td>
            <a className="button" onClick={this.handleToggleCollapse}>View{canEdit ? ' / Edit' : null} Breakdown</a>
          </td>
        </tr>
        {!this.state.collapsed ?
          item.breakdowns.map((b, index) =>
            <BillItemBreakdown key={index} breakdown={b} taxes={item.taxes} onUpdate={this.onUpdateBreakdown} getTaxInfo={getTaxInfo} canEdit={canEdit} />
          ).concat(
            item.costs.map((c, index) =>
              <BillItemCost key={item.breakdowns.length + index} cost={c} taxes={item.taxes} onUpdate={this.onUpdateCost} getTaxInfo={getTaxInfo} canEdit={canEdit} />
          )).concat(
            canEdit && !zip2tax ? <tr key="taxes">
              <td colSpan="2">&nbsp;</td>
              <td colSpan="7">Taxes: <a className="button" onClick={this.handleAddTax}>Add Tax</a></td>
            </tr> : []
          ).concat(item.taxes.map((t, index) =>
            <BillItemTax key={item.breakdowns.length + item.costs.length + index} zip2tax={zip2tax} tax_amount={t} onChange={this.onChangeTax(index)} onDelete={this.onDeleteTax(index)} canEdit={canEdit} />
          )) : null}
      </tbody>
    );
  }
}

class ExtraBillItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selected: true,
      show_dropdown: false,
      item: this.props.item
    };

    this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
    this.handleToggleSelected = this.handleToggleSelected.bind(this);
    this.onChangeTax = this.onChangeTax.bind(this);
    this.onDeleteTax = this.onDeleteTax.bind(this);
    this.handleAddTax = this.handleAddTax.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onSelectBillItemType = this.onSelectBillItemType.bind(this);
    this.handleItemCodeKeyDown = this.handleItemCodeKeyDown.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ item: nextProps.item });
  }

  handleChange(field) {
    return e => {
      const show_dropdown = 'item_code' === field;
      this.setState({
        item: Object.assign({}, this.state.item, {[field]: e.target.value}),
        show_dropdown
      });
    };
  }

  handleItemCodeKeyDown(e) {
    const bill_item_types = this.props.bill_item_types.filter(o => o.value.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
    switch (e.key) {
      case 'Tab':
      case 'Enter':
        if (this.state.show_dropdown && bill_item_types.length > 0) {
          this.onSelectBillItemType(bill_item_types[0].key);
        }
    }
  }

  handleBlur(field) {
    return e => {
      if (this.isValueValid(field, e.target.value)) {
        const item = Object.assign({}, this.state.item, {[field]: this.formatValue(field, e.target.value)});
        this.props.onUpdate(item);
      } /* else {
        this.setState({
          item: this.props.item,
          show_dropdown: false
        });
      } */
    };
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'unit_cost':
        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  handleToggleSelected(e) {
    const item = Object.assign({}, this.state.item, { selected: !this.state.item.selected });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  handleToggleCollapse(e) {
    this.setState({ collapsed: !this.state.collapsed, show_dropdown: false });
  }

  onChangeTax(index) {
    const { onUpdate } = this.props;
    return (tax_id) => {
      const item = { ...this.state.item, taxes: this.state.item.taxes.map((t, i) => i === index ? { ...t, tax_id } : t) };
      this.setState({ item, show_dropdown: false });
      onUpdate(item);
    };
  }

  onDeleteTax(index) {
    return () => {
      const taxes = this.state.item.taxes.filter((t, i) => i !== index);
      const item = Object.assign({}, this.state.item, { taxes });
      this.setState({ item, show_dropdown: false });
      this.props.onUpdate(item);
    };
  }

  handleAddTax(e) {
    e.preventDefault();
    const taxes = this.state.item.taxes.concat({ tax_id: this.props.default_tax_id });
    const item = Object.assign({}, this.state.item, { taxes });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  onSelectBillItemType(bill_item_type_id) {
    const item = Object.assign({}, this.state.item, {
      bill_item_type_id,
      item_code: this.props.bill_item_types.filter(o => o.key === bill_item_type_id)[0].value,
      item_name: toTitleCase(this.props.bill_item_types.filter(o => o.key === bill_item_type_id)[0].value)
    });
    this.setState({ item, show_dropdown: false });
    this.props.onUpdate(item);
  }

  render() {
    const { item } = this.state;
    const { getTaxInfo, canEdit, zip2tax } = this.props;
    const subtotal = getBreakdownSubtotal(item);
    const tax_amount = getBreakdownTaxes(item, item.taxes, getTaxInfo);
    const total = subtotal + tax_amount;
    const bill_item_types = this.props.bill_item_types.filter(o => o.value.toLowerCase().indexOf(item.item_code.toLowerCase()) > -1).map(o => Object.assign({}, o, { onClick: e => { e.preventDefault(); this.onSelectBillItemType(o.key); } }));

    return (
      <tbody>
        <tr>
          <td>
            {canEdit ? <input style={{marginTop: '5px'}} type="checkbox" onChange={this.handleToggleSelected} checked={this.state.item.selected}></input> : null}
          </td>
          <td style={{ position: 'relative' }}>
            <input style={{marginTop: '15px'}} type="text" value={item.item_code} onChange={this.handleChange('item_code')} onBlur={this.handleBlur('item_code')} onKeyDown={this.handleItemCodeKeyDown} disabled={!canEdit} />
            { this.state.show_dropdown ? <DropdownMenu options={bill_item_types} /> : null }
          </td>
          <td>
            <input style={{marginTop: '15px'}} type="text" value={item.item_name} onChange={this.handleChange('item_name')} onBlur={this.handleBlur('item_name')} disabled={!canEdit} />
          </td>
          <td>
            <input style={{marginTop: '15px'}} type="text" value={item.quantity} onChange={this.handleChange('quantity')} onBlur={this.handleBlur('quantity')} disabled={!canEdit} />
          </td>
          <td>
            <input style={{marginTop: '15px'}} type="text" value={item.unit_cost} onChange={this.handleChange('unit_cost')} onBlur={this.handleBlur('unit_cost')} disabled={!canEdit} />
          </td>
          <td>
            ${formatMoney(subtotal)}
          </td>
          <td>
            {item.taxes.map((t, index) => {
              const tax = Object.assign({label: '* Unknown *', percent: 0}, getTaxInfo(t.tax_id));
              return <span key={index}>{tax.label} {parseFloat(tax.percent).toFixed(2)}%<br /></span>;
            })}
          </td>
          <td>
            ${formatMoney(total)}
          </td>
          <td>
            <a style={{marginTop: '15px'}} className="button" onClick={this.handleToggleCollapse}>View{canEdit ? ' / Edit' : null} Breakdown</a>
          </td>
        </tr>
        {!this.state.collapsed ?
          (canEdit && !zip2tax ? [<tr key="taxes">
              <td colSpan="2">&nbsp;</td>
              <td colSpan="7">Taxes: <a className="button" onClick={this.handleAddTax}>Add Tax</a></td>
            </tr>
          ] : []).concat(item.taxes.map((t, index) =>
            <BillItemTax key={index} tax_amount={t} zip2tax={zip2tax} onChange={this.onChangeTax(index)} onDelete={this.onDeleteTax(index)}  canEdit={canEdit} />
          )) : null}
      </tbody>
    );
  }
}

class BillItemTax extends Component {

  constructor(props) {
    super(props);

    this.handleDeleteTax = this.handleDeleteTax.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleDeleteTax(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  onChange(value) {
    this.props.onChange(value);
  }

  render() {
    const { tax_amount, canEdit, zip2tax } = this.props;
    return (
      <tr>
        <td colSpan="2">&nbsp;</td>
        <td>
          {canEdit && !zip2tax ? <a onClick={this.handleDeleteTax}>&times;</a> : null}
          <TaxSelect zip2tax={zip2tax} value={tax_amount.tax_id} onChange={this.onChange} disabled={!canEdit} />
        </td>
        <td colSpan="6">&nbsp;</td>
      </tr>
    );
  }
}

class BillSection extends Component {

  constructor(props) {
    super(props);

    this.handleCreateItem = this.handleCreateItem.bind(this);
  }

  handleCreateItem(e) {
    e.preventDefault();
    this.props.onCreate(this.props.purchase_order_id, this.props.order_id, this.props.order_form_number);
  }

  render() {
    const { items, extra_items, bill_item_types, getTaxInfo, default_tax_id, onUpdate, onUpdateExtra, canEdit, zip2tax } = this.props;
    const title = this.props.purchase_order_id ?
      <a target="_blank" href={`/purchase_order.php?id=${this.props.purchase_order_id}`}>{`PO# ${this.props.form_number}`}</a> :
      <a target="_blank" href={`sales-order/${this.props.form_number}`}>{`Sales Order# ${this.props.form_number}`}</a>;

    return (
      <table>
        <thead>
          <tr>
            <th colSpan="7">{title}</th>
            <th colSpan="9">
              {canEdit ? <a className="button" onClick={this.handleCreateItem} style={{width: '100%'}}>Add Bill Item</a> : null}
            </th>
          </tr>
          <tr>
            <th>
            </th>
            <th style={{width: '150px'}}>
              Code
            </th>
            <th>
              Item Name
            </th>
            <th style={{width: '70px'}}>
              Qty
            </th>
            <th style={{width: '100px'}}>
              Unit Cost
            </th>
            <th>
              Subtotal
            </th>
            <th>
              Tax
            </th>
            <th>
              Total
            </th>
            <th>
            </th>
          </tr>
        </thead>
        {items.map((bi, index) =>
          <BillItem key={index} item={bi} getTaxInfo={getTaxInfo} default_tax_id={default_tax_id} onUpdate={onUpdate} canEdit={canEdit} zip2tax={zip2tax} />
        )}
        {extra_items.map((i, index) =>
          <ExtraBillItem key={index} item={i} bill_item_types={bill_item_types} getTaxInfo={getTaxInfo} default_tax_id={default_tax_id} onUpdate={onUpdateExtra} canEdit={canEdit} zip2tax={zip2tax} />
        )}
      </table>
    );
  }
}

class Bill extends Component {

  constructor(props) {
    super(props);

    this.state = {
      default_tax_id: false,
      loading: true
    };

    _.bindAll(this, [
      'onChange',
      'onChangeSupplier',
      'handleChange',
      'onChangeDefaultTax',
      'onUpdateItem',
      'onUpdateExtraItem',
      'onCreateItem',
      'onFormRef',
      'hasCapability',
    ]);
  }

  UNSAFE_componentWillMount() {
    this.props.loadBill().then(bill => {
      this.setState({
        loading: false,
        default_tax_id: this.props.getDefaultTaxId(bill.division_id),
        bill: Object.assign({}, bill, {
          bill_date_due: bill.bill_date_due ? parseMysqlDate(bill.bill_date_due) : null,
          bill_date_billdate: bill.bill_date_billdate ? parseMysqlDate(bill.bill_date_billdate) : null,
          items: (bill.items || []).map(i => Object.assign({}, i, { selected: true })),
          purchase_orders: (bill.purchase_orders || []).map(po => Object.assign({}, po, {
            items: (po.items || []).map(i => Object.assign({}, i, { selected: true })),
            extra_items: (po.extra_items || []).map(i => Object.assign({}, i, { selected: true }))
          })),
          orders: (bill.orders || []).map(o => Object.assign({}, o, {
            items: (o.items || []).map(i => Object.assign({}, i, { selected: true })),
            extra_items: (o.extra_items || []).map(i => Object.assign({}, i, { selected: true }))
          }))
        })
      });
    }, error => {
    });
  }

  componentWillUnmount() {
    const { messages, onDismissCallout } = this.props;
    messages.forEach(m => onDismissCallout(m.key)());
  }

  hasCapability(capability) {
    return this.props.identity.capabilities.includes(capability);
  }

  handleChange(field) {
    return e => this.onChange(field)(e.target.value);
  }

  onChange(field) {
    return value => {
      this.setState({ bill: Object.assign({}, this.state.bill, {[field]: value}) });
    };
  }

  onChangeSupplier(division_id) {
    const default_tax_id = this.props.getDefaultTaxId(division_id);
    this.setState({ default_tax_id, bill: Object.assign({}, this.state.bill, {division_id}) });
  }

  onChangeDefaultTax(default_tax_id) {
    this.setState({
      default_tax_id,
      bill: Object.assign({}, this.state.bill, {
        items: (this.state.bill.items || []).map(i => Object.assign({}, i, { taxes: [{tax_id: default_tax_id}] })),
        purchase_orders: this.state.bill.purchase_orders.map(po => Object.assign({}, po, {
          items: po.items.map(i => Object.assign({}, i, { taxes: [{tax_id: default_tax_id}] })),
          extra_items: po.extra_items.map(i => Object.assign({}, i, { taxes: [{tax_id: default_tax_id}] }))
        })),
        orders: this.state.bill.orders.map(o => Object.assign({}, o, {
          items: o.items.map(i => Object.assign({}, i, { taxes: [{tax_id: default_tax_id}] })),
          extra_items: o.extra_items.map(i => Object.assign({}, i, { taxes: [{tax_id: default_tax_id}] }))
        }))
      })
    });
  }

  onUpdateItem(item) {
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.map(i => {
        if (i.item_id === item.item_id) {
          return item;
        }
        return i;
      }),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        Object.assign({}, po, {
          items: po.items.map(i => {
            if (i.item_id === item.item_id) {
              return item;
            }
            return i;
          })
        })
      ),
      orders: this.state.bill.orders.map(o =>
        Object.assign({}, o, {
          items: o.items.map(i => {
            if (i.item_id === item.item_id) {
              return item;
            }
            return i;
          })
        })
      )
    });
    this.setState({ bill });
  }

  onUpdateExtraItem(item) {
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.map(i => {
        if (i.bill_item_id === item.bill_item_id) {
          return item;
        }
        return i;
      }),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        Object.assign({}, po, {
          extra_items: po.extra_items.map(i => {
            if (i.bill_item_id === item.bill_item_id) {
              return item;
            }
            return i;
          })
        })
      ),
      orders: this.state.bill.orders.map(o =>
        Object.assign({}, o, {
          extra_items: o.extra_items.map(i => {
            if (i.bill_item_id === item.bill_item_id) {
              return item;
            }
            return i;
          })
        })
      )
    });
    this.setState({ bill });
  }

  onCreateItem(purchase_order_id, order_id, order_form_number) {
    const item = {
      bill_item_id: getExtraItemId(),
      purchase_order_id,
      order_id,
      order_form_number,
      quantity: 1,
      unit_cost: 0,
      item_code: '',
      item_name: '',
      bill_item_type_id: '',
      taxes: [{ tax_id: this.state.default_tax_id }],
      selected: true,
      extra: true
    };
    const bill = Object.assign({}, this.state.bill, {
      items: this.state.bill.items.concat(item),
      purchase_orders: this.state.bill.purchase_orders.map(po =>
        po.purchase_order_id === purchase_order_id ?
          Object.assign({}, po, { extra_items: po.extra_items.concat(item) }) :
          po
      ),
      orders: this.state.bill.orders.map(o =>
        o.order_id === order_id ?
          Object.assign({}, o, { extra_items: o.extra_items.concat(item) }) :
          o
      )
    });
    this.setState({ bill });
  }

  onFormRef(form) {
    this._form = form;
  }

  renderBill(canEdit) {
    const { supplier_accounts, getSupplierAccount, project, zip2tax, bill_item_types, getTaxInfo, messages, onDismissCallout, onUpdateExchangeRate, getExchangeRate, identity } = this.props;
    const { bill } = this.state;
    const supplier_account = getSupplierAccount(bill.division_id);
    const subtotal = getBillSubtotal(bill);
    const tax_amount = getBillTaxes(bill, getTaxInfo);
    const total = subtotal + tax_amount;
    const exchange_rate = bill.exchange_rate ?? getExchangeRate(supplier_account.default_currency_id, project.currency_id);

    const handleUpdateExchangeRate = (e) => {
       e.preventDefault();
       onUpdateExchangeRate(bill.bill_id).then(action => {
         this.setState((state) => ({ bill: { ...state.bill, exchange_rate: action.payload.bill.exchange_rate }}));
       });
    };

    return (
      <BillFormContainer ref={this.onFormRef} canEdit={canEdit} bill={bill} location={this.props.location}>
          {messages.length ?
            <div className="small-12 columns">
            {messages.map(message =>
              <Callout key={message.key} type={message.type} onClose={onDismissCallout(message.key)}>
                <p>{message.message}</p>
              </Callout>
            )}
            </div> : null
          }
          <div className="small-12 medium-6 columns">
            <Form.Select label="Supplier" field="division_id"
              searchable={true}
              options={supplier_accounts}
              value={this.state.bill.division_id}
              onChange={this.onChangeSupplier}
              required={true}
              disabled={!canEdit}
              withMarginBottom
            />
            <Form.TextInput label="Invoice #" field="bill_reference_number" value={this.state.bill.bill_reference_number} onChange={this.onChange('bill_reference_number')} required={true} disabled={!canEdit} />
            <Form.DateInput label="Invoice Date" field="bill_date_billdate" value={this.state.bill.bill_date_billdate} onChange={this.onChange('bill_date_billdate')} required={true} disabled={!canEdit} />
            <Form.DateInput label="Due Date" field="bill_date_due" value={this.state.bill.bill_date_due} onChange={this.onChange('bill_date_due')} required={true} disabled={!canEdit} />
            <Form.Textarea label="Notes" field="bill_memo" value={this.state.bill.bill_memo} onChange={this.onChange('bill_memo')} disabled={!canEdit} />
          </div>
          <div className="small-12 medium-6 columns">
            <div className="row">
              <div className="small-12 medium-4 columns">
                <strong>Subtotal</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <div style={{marginBottom: 16, height: '38px'}}>${formatMoney(subtotal)}</div>
              </div>
            </div>
            <FormTaxSelect label="Default Tax" field="default_tax_id" zip2tax={zip2tax} value={this.state.default_tax_id} onChange={this.onChangeDefaultTax} disabled={!canEdit} />
            <div className="row">
              <div className="small-12 medium-4 columns">
                <strong>Total Tax</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <div style={{marginBottom: 16, height: '38px'}}>${formatMoney(tax_amount)}</div>
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-4 columns">
                <strong>Amount Due</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <div style={{marginBottom: 16, height: '38px'}}>${formatMoney(total)}</div>
              </div>
            </div>
	    {(supplier_account.default_currency_id !== project.currency_id && +identity.use_margin_currency === 1) && <div className="row">
	      <div className="small-12 medium-4 columns">
	        <strong>Currency</strong>
	      </div>
	      <div className="small-12 medium-8 columns">
	        <strong>Bill:</strong>{supplier_account.default_currency_id} <strong>Invoice:</strong>{project.currency_id}
	      </div>
	    </div>}
	    {(supplier_account.default_currency_id !== project.currency_id && +identity.use_margin_currency === 1) && <div className="row">
	      <div className="small-12 medium-4 columns">
	        <strong>Currency Conversion</strong>
	      </div>
	      <div className="small-12 medium-8 columns">
	        1 {supplier_account.default_currency_id} = <input type="text" style={{ width: '20%', display: 'inline-block' }} readOnly={true} value={exchange_rate} /> {project.currency_id} {!!bill.bill_id && <button className="button tiny" onClick={handleUpdateExchangeRate}>Update</button>}
	      </div>
	    </div>}
        </div>
        {bill.purchase_orders.map(po =>
          <BillSection
            key={po.purchase_order_id}
            purchase_order_id={po.purchase_order_id}
            order_id={po.order_id}
            form_number={po.form_number}
            order_form_number={po.order_form_number}
            items={po.items}
            extra_items={po.extra_items}
            bill_item_types={bill_item_types}
            getTaxInfo={getTaxInfo}
            default_tax_id={this.state.default_tax_id}
            onUpdate={this.onUpdateItem}
            onCreate={this.onCreateItem}
            onUpdateExtra={this.onUpdateExtraItem}
            canEdit={canEdit}
            zip2tax={zip2tax}
          />
        )}
        {bill.orders.map(o =>
          <BillSection
            key={o.order_id}
            order_id={o.order_id}
            form_number={o.form_number || o.order_form_number}
            order_form_number={o.form_number}
            items={o.items}
            extra_items={o.extra_items}
            bill_item_types={bill_item_types}
            getTaxInfo={getTaxInfo}
            default_tax_id={this.state.default_tax_id}
            onUpdate={this.onUpdateItem}
            onCreate={this.onCreateItem}
            onUpdateExtra={this.onUpdateExtraItem}
            canEdit={canEdit}
            zip2tax={zip2tax}
          />
        )}
      </BillFormContainer>
    );
  }

  render() {
    const { popups, } = this.props;
    const { loading, bill } = this.state;
    const isInsert = !bill || !bill.bill_id;
    const canEdit = isInsert ? this.hasCapability('CREATE-BILL') : this.hasCapability('MODIFY-BILL');
    return (
      <div className="row full-width mega-modal-content">
        <div className="small-12 columns modal-header">
          <h3>{loading ? 'Loading...' : (canEdit ? (this.state.bill.bill_id ? 'Edit Bill' : 'Add Bill') : 'View Bill')}</h3>
          {!popups.length ? <CancelBtn canEdit={canEdit} /> : null}
          {!popups.length && !loading && canEdit ? <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => { e.preventDefault(); this._form.submit();}}>Save</a> : null}
        </div>
        {!loading ? this.renderBill(canEdit) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const taxes = getTaxOptions(state);
  const zip2tax = 1 == ownProps.project.zip2tax;
  return {
    supplier_accounts: getSupplierAccountOptions(state),
    getSupplierAccount: division_id => Object.values(state.entities.supplier_accounts).filter(sa => sa.division_id === division_id)[0] || {},
    getExchangeRate: (from_currency, to_currency) => getExchangeRate(state, from_currency, to_currency),
    getTaxInfo: tax_id => state.entities.taxes[tax_id],
    bill_item_types: getBillItemTypeOptions(state),
    messages: state.display.messages.filter(m => m.key.substr(0, 5) === 'bill.'),
    getDefaultTaxId: supplier_account_id => {
      return zip2tax ? taxes.filter(t => t.value === 'Exempt (0%)')[0].key : _.get(
        state.entities.supplier_accounts, [supplier_account_id, 'default_tax_id']
      ) || taxes[0].key;
    },
    zip2tax,
    identity: state.identity,
    loadBill: () => {
      if (ownProps.params.bill_id) {
        return Promise.resolve(state.entities.bills[ownProps.params.bill_id]);
      }

      const order_id = new URLSearchParams(ownProps.location.search).get('order_id');
      const purchase_order_id = new URLSearchParams(ownProps.location.search).get('purchase_order_id');

      if (order_id) {
        return Promise.resolve({ orders: [{ order_id, form_number: state.entities.orders[order_id].form_number }]});
      }
      if (purchase_order_id) {
        const data = { purchase_order_id, unbilled: true };
        return oauth('GET', 'purchase-order-item', data).then(({ json }) => {
          const purchase_order = state.entities.purchase_orders[purchase_order_id];
          const bill = {
            division_id: purchase_order.division_id || purchase_order.supplier_id,
            supplier_id: purchase_order.division_id || purchase_order.supplier_id,
            purchase_orders: [ Object.assign({}, purchase_order, {
              items: json.purchase_order_items.map(bi => Object.assign({}, bi, {
                bill_item_id: getExtraItemId(),
                breakdowns: bi.breakdowns.map(bd => Object.assign({}, bd, {
                  bill_item_id: getExtraItemId()
                })),
                costs: bi.costs.map(c => Object.assign({}, c, {
                  bill_item_id: getExtraItemId()
                }))
              }))
            }) ]
          };
          return bill;
        }, ({ json }) => {
          return Promise.reject('Unabled to create bill from purchase order');
        });
      }
      return Promise.reject(false);
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpdateExchangeRate: bill_id => dispatch(createUpdateBillExchangeRate(bill_id)),
    onDismissCallout: key => () => dispatch(createDismissCallout(key))
  };
};

const CancelBtn = ({ canEdit }) => {
  const navigate = useNavigate();
  const onCloseDialog = () => navigate(-1);
  return (
    <a className="alert button"
      style={{ position: 'fixed', right: (canEdit ? '5rem' : '1rem'), top: '1rem' }}
      onClick={e => onCloseDialog()}
    >Cancel</a>
  );
};

const BillFormContainer = React.forwardRef(({ canEdit, bill, location, children }, ref) => {
  const order_id = new URLSearchParams(location.search).get('order_id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onUpdateBill = bill => dispatch(createUpdateBill(bill)).then(
    () => navigate(-1),
    x => x
  );
  const onAddBill = bill => dispatch(createAddBill(order_id, bill)).then(
    () => navigate(-1),
    x => x
  );
  const onSubmit = (values, errors) => {
    if (!canEdit) {
      return;
    }
    if (!_.every(errors, (error) => error === null)) {
      return;
    }
    if (bill.bill_id) {
      onUpdateBill(bill);
    } else {
      onAddBill(bill);
    }
  };
  return (
    <Form className="small-12 columns" ref={ref} onSubmit={onSubmit}>
      {children}
    </Form>
  );
});

const ConnectedBill = connect(mapStateToProps, mapDispatchToProps)(Bill);
export default ConnectedBill;
