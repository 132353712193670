import React from 'react';
import { findDOMNode } from 'react-dom';

import { formatMoney, getImageSrc } from '../utils';
import BaseItemSummary, { ItemSummaryTaxTd } from './BaseItemSummary';
import { withDraggableItem } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import Img from './Img';

const INVENTORY_BADGE_STYLE = {
  color: 'white',
  backgroundColor: '#5CA3B6',
  borderRadius: '5rem',
  padding: '0.25rem',
  fontSize: '0.75rem',
  marginLeft: '0.5rem'
};

class ProductItemSummary extends BaseItemSummary {

  constructor(props) {
    super(props);

    this.state = {
      showActions: false
    };
  }

  componentDidMount() {
    this.root = findDOMNode(this);
  }

  componentDidUpdate() {
    this.root = findDOMNode(this);
  }

  renderActions() {
    const { display, item } = this.props;
    const { showActions } = this.state;
    const buttons = (
      <span className={display !== 'grid' ? "actions" : "actions-new"}>
        {['ESTIMATE', 'PRESENTATION', 'SHOP'].includes(this.props.order_type) ? this.renderToggleAction('hidden', 'hide', 'show') : null}
        {this.renderEditAction()}
        {this.renderCopyAction()}
        {this.renderCopyToOrderAction()}
        {(!this.props.item.hasInventory || 'SHOP' !== this.props.order_type) && this.renderDeleteAction()}
      </span>
    );

    if (display !== 'grid') {
      return buttons;
    } else {
      return (
        <>
          <div className="grid-info">
            <div className="title"><div className="name">{item.item_name}</div></div>
            <div>
              <a className='tiny button' href="/" style={{display: 'inline-block'}} onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.setState(state => ({
                  showActions: !state.showActions
                }));
              }}>
                {showActions ? <>&#9660;</> : <>&#9650;</>}
              </a>
            </div>
          </div>
          {showActions ? buttons : null}
        </>
      );
    }
  }

  renderItem() {
    const { item, display } = this.props;
    const product_url = `/product.php?id=${item.parent_id}`;
    const vendor_url = `/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`;
    const phone = (item.division_phones || [])[0] ? item.division_phones[0] : null;
    const default_image = item.item_images.map(ii => ii.image)[0];
    let image = (
      <div className="image">
        <Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOver('item')} onMouseOut={this.handleMouseOut('item')} />
      </div>);
    if (display === 'grid') {
      image = (
        <div className="image"
          style={{background:"url(" + getImageSrc(default_image) +")", backgroundSize:"cover", marginBottom: "8px"}}
          onMouseOver={this.handleMouseOver('item')}
          onMouseOut={(e) => {this.handleMouseOut('item')(e); this.setState({showActions: false });}}
        >
        </div>
      );
    }
    return (
      <tr>
        <td className="description">
          {image}
          <a className="product-sku" href={product_url} target="_blank" onClick={e => e.stopPropagation()}>{item.item_sku}</a>
          {item.hasInventoryBreakdowns && <span style={INVENTORY_BADGE_STYLE}>Inventory</span>}
          <div className="product-vendor-container">
            <a className="product-vendor" href={vendor_url} target="_blank" onClick={e => e.stopPropagation()}>{item.division_name}</a>{phone && phone.phone_number ? ` (${phone.phone_number}${phone.phone_extension ? ' x' + phone.phone_extension : ''})` : null}
          </div>
          <span className="product-name">{item.item_name}</span>
          {this.renderActions()}
        </td>
        <td className="quantity">{parseInt(item.total_units, 10)}</td>
        <td className="margin">{parseFloat(item.total_margin).toFixed(2)}%</td>
        <ItemSummaryTaxTd item={item}/>
        <td className="amount" onMouseOver={this.handleMouseOver('breakdown')} onMouseOut={this.handleMouseOut('breakdown')}>${formatMoney(item.total_subtotal)}</td>
      </tr>
    );
  }
}

const BaseProductItemSummary = ProductItemSummary;
export { BaseProductItemSummary, INVENTORY_BADGE_STYLE };
export default withDraggableItem(DragItemTypes.ITEM, ProductItemSummary);
