import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@commonsku/styles';
import { calculateAvalaraTax } from '../actions/tax';
import { useAvalara } from './avalara/hooks';
import AddressErrorPopup from './avalara/AddressErrorPopup';

const AvalaraOrderTaxButton = ({ order }) => {
  const avalara = useAvalara();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [addressResolution, setAddressResolution] = useState(null);

  return <tr key="calculateTaxButton" className="totals">
    <td colSpan="2">
      {order.avalara_status === "" && <Button onClick={() => {
        setError(null);
        setAddressResolution(null);
        avalara.resolveAddress({ order_id: order.order_id })
          .then(({ json }) => {
            if (json.error) {
              setError(json.error);
            } else if (!isEmpty(get(json, 'address_resolution.messages'))) {
              setAddressResolution(json.address_resolution);
            } else {
              return false;
            }
            return true;
          })
          .then((hasError) => {
            if (!hasError) {
              return dispatch(calculateAvalaraTax(order.order_id));
            }
          })
          .catch(({ json }) => {
            setError(json.error);
          })
        ;
      }}>Calculate Tax</Button>}
      {(!isEmpty(error) || !isEmpty(addressResolution)) && <AddressErrorPopup
        error={error}
        resolution={addressResolution}
        onClose={() => {
          setError(null);
          setAddressResolution(null);
        }}
        ignoreError={() => {
          dispatch(calculateAvalaraTax(order.order_id)).catch(({ json }) => {
            setError(json.error);
          });
        }}
      />}
    </td>
  </tr>;
};

export default React.memo(AvalaraOrderTaxButton);