import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { AlertIcon, Button, colors, CompletedCheckmarkIcon, Popup, SkubotSpinner, XIcon } from "@commonsku/styles"
import PS from './PromostandardsLabel';
import styled from "styled-components";
import { fetchPurchaseOrder } from '../actions/purchase_order';

const PopUpHeader = styled.div`
  &&& {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    color: ${colors.neutrals[90]};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PopUpContent = styled.div`
  &&& {
    display: flex;
    padding: 16px;
    border-radius: 5px;
    gap: 16px;
    ${props => props.color && `color: ${props.color};`}
    ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  }
`;

const EPOPopup = ({
  status,
  purchaseOrder,
  onClose,
  handleResendEPO
}) => {
  const dispatch = useDispatch();
  const checkStatusIntervalRef = useRef(null);

  const checkEPOStatus = () => {
    dispatch(fetchPurchaseOrder(purchaseOrder.purchase_order_id, { get_epo_status: true }))
      .then(purchaseResponse => {
        if ('PENDING' !== purchaseResponse.payload.purchase_order.epo_submission_status) {
          clearInterval(checkStatusIntervalRef.current);
        }
      });
  };

  useEffect(() => {
    checkStatusIntervalRef.current = setInterval(checkEPOStatus, 5000);
  }, []);

  const handleRetryClick = () => {
    handleResendEPO().then(response => {
      clearInterval(checkStatusIntervalRef.current); // Clear any existing interval before setting a new one
      checkStatusIntervalRef.current = setInterval(checkEPOStatus, 5000);
    });
  };

  if (isEmpty(status)) {
    status = 'PENDING';
  }
  const header = () => {
    const statusMap = {
      PENDING: 'Sending ePO',
      FAILED: 'ePO Not Sent',
      SUCCESS: 'ePO Sent'
    };
    return <PopUpHeader>
      <div>
        <PS iconColor={colors.neutrals.darkest} style={{ lineHeight: 0 }} />
        {statusMap[status]}
      </div>
      <XIcon size="large" pointer onClick={onClose} />
    </PopUpHeader>
  }

  const renderMessages = (status, purchaseOrder) => {
    const formNumber = purchaseOrder.form_number ?? '';
    const divisionName = purchaseOrder.origin.division_name ?? '';
    const errorMessage = purchaseOrder.epo_error_message ?? '';

    switch (status) {
      case 'PENDING':
        return <>
          <div>
            Sending <strong>PO #{formNumber}</strong> to <strong>{divisionName}</strong>. This may take a few minutes, and will continue in the background if you close this popup.
          </div>
        </>
      case 'FAILED':
        return <>
          <AlertIcon style={{ minWidth: 24 }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div>We were not able to send the PO due to an error.</div>
            <div>You can send the PO by email instead, or try again later.</div>
            <div style={{ fontSize: 14, color: colors.neutrals[80] }}>
              {errorMessage}
            </div>
          </div>
        </>
      case 'SUCCESS':
        return <>
          <CompletedCheckmarkIcon />
          <div><strong>PO #{formNumber}</strong> was successfully sent to <strong>{divisionName}</strong>!</div>
        </>
      default:
        return null;
    }
  }

  const renderActions = (status) => {
    switch (status) {
      case 'PENDING':
        return <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', alignItems: 'center ', flexGrow: 1 }}>
          <SkubotSpinner size="small" skubot={false} />
        </div>
      case 'FAILED':
        return <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'end ', flexGrow: 1, justifyContent: 'end', gap: 8 }}>
          <a
            target="_blank"
            rel="opener"
            href={`/send_email.php?parent_type=purchase_order&parent_id=${purchaseOrder.purchase_order_id}`}
          >
            <Button onClick={onClose}>Send PO By Email</Button>
          </a>
          <Button onClick={handleRetryClick}>Retry ePO</Button>
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
        </div>
      case 'SUCCESS':
        return <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'end ', flexGrow: 1, justifyContent: 'end' }}>
          <Button onClick={onClose}>Done</Button>
        </div>
      default:
        return null;
    }
  }

  const renderBody = () => {
    const statusMap = {
      PENDING: {
        color: colors.secondary4[80],
        backgroundColor: colors.secondary4[10]
      },
      FAILED: {
        color: colors.errors[80],
        backgroundColor: colors.errors[10]
      },
      SUCCESS: {
        color: colors.secondary3[80],
        backgroundColor: colors.secondary3[10]
      }
    };

    return status && <>
      <PopUpContent color={statusMap[status].color} backgroundColor={statusMap[status].backgroundColor}>
        {renderMessages(status, purchaseOrder)}
      </PopUpContent>
      {renderActions(status)}
    </>
  }

  return <Popup
    header={header()}
    width="600px" height="377px"
    style={{ padding: 16, gap: 16, borderRadius: 10, display: 'flex', flexDirection: 'column' }}
    popupContentStyle={{ display: 'flex', flexDirection: 'column', gap: 16 }}
  >
    {renderBody()}
  </Popup>
};

export default EPOPopup;
