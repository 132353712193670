import { ADD_BILL_SUCCESS, UPDATE_BILL_SUCCESS } from '../actions/bill';

const reduce = (state = {}, action) => {
  switch (action.type) {
    case ADD_BILL_SUCCESS:
      // fall through
    case UPDATE_BILL_SUCCESS:
      return Object.assign({}, state, {[action.payload.bill.bill_id]: action.payload.bill});
  }

  return state;
};

export default reduce;
