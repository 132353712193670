import React from 'react';
import {
    Row, Col, Label,
    IconButton, BotIcon, GalleryIcon, ListIcon, TilesIcon,
    ResizableIcon,
    ArrowIcon,
} from '@commonsku/styles';
import PresalesFields from './PresalesFields';
import { useIdentity } from '../../../hooks';
import { getIdentityUtils } from '../../../utils';
import { Span } from '../../helpers/Typography';

const PresentationFields = React.memo(({
    form,
    updateField,
}) => {
    const identity = useIdentity();
    const { hasUserFlags } = getIdentityUtils(identity);
    const newDesignEnabled = form.view_type === 'SHOP_DESIGN' || hasUserFlags('SHOP_DESIGN_ENABLED');

    return (
        <>
            <PresalesFields
                form={form}
                updateField={updateField}
            />
            <Col xs padded>
                <Label>Presentation Style</Label>
                <Row>
                    <Col padded xs sm={6}>
                        <IconButton
                            Icon={TilesIcon}
                            style={{ width: '100%' }}
                            variant={form.view_type === 'TILE' ? "secondary" : "primary-light"}
                            onClick={() => updateField('view_type', 'TILE')}
                        ><span style={{ verticalAlign: 'middle' }}>Tiles</span></IconButton>
                    </Col>
                    <Col padded xs sm={6}>
                        <IconButton
                            Icon={ListIcon}
                            style={{ width: '100%' }}
                            variant={form.view_type === 'LIST' ? "secondary" : "primary-light"}
                            onClick={() => updateField('view_type', 'LIST')}
                        ><span style={{ verticalAlign: 'middle' }}>Lists</span></IconButton>
                    </Col>
                    <Col padded xs sm={6}>
                        <IconButton
                            Icon={GalleryIcon}
                            style={{ width: '100%' }}
                            variant={form.view_type === 'GALLERY' ? "secondary" : "primary-light"}
                            onClick={() => updateField('view_type', 'GALLERY')}
                        ><span style={{ verticalAlign: 'middle' }}>Gallery</span></IconButton>
                    </Col>
                    <Col padded xs sm={6}>
                        <IconButton
                            Icon={BotIcon}
                            style={{ width: '100%' }}
                            variant={form.view_type === 'SMART' ? "secondary" : "primary-light"}
                            onClick={() => updateField('view_type', 'SMART')}
                        ><span style={{ verticalAlign: 'middle' }}>Smart Gallery</span></IconButton>
                    </Col>
                    {newDesignEnabled && <Col padded xs>
                        <Row>
                            <Col xs sm={6}>
                                <IconButton
                                    Icon={ResizableIcon}
                                    style={{ width: '100%' }}
                                    variant={form.view_type === 'SHOP_DESIGN' ? "secondary" : "primary-light"}
                                    onClick={() => updateField('view_type', 'SHOP_DESIGN')}
                                ><span style={{ verticalAlign: 'middle' }}>Design Beta</span></IconButton>
                            </Col>
                            <Col xs sm={6} style={{ alignSelf: 'center' }}>
                                <Span style={{ paddingLeft: 2, verticalAlign: 'middle' }}>
                                    <ArrowIcon
                                        color='var(--color-pink-60)'
                                        direction='left'
                                        size='large'
                                        height={32}
                                        width={32}
                                        style={{ paddingLeft: 8, paddingRight: 4, verticalAlign: 'middle', }}
                                    />
                                    <Span bold color='var(--color-pink-60)' style={{ verticalAlign: 'middle' }}>New!</Span>
                                </Span>
                            </Col>
                        </Row>
                    </Col>}
                </Row>
            </Col>
        </>
    );
});

export default PresentationFields;
