import React from 'react';
import MediaQuery from 'react-responsive';

export const SmallOnly = props => <MediaQuery {...props} maxWidth={639} />;
export const MediumOnly = props => <MediaQuery {...props} minWidth={640} maxWidth={1023} />;
export const LargeOnly = props => <MediaQuery {...props} minWidth={1024} />;

export const Small = props => <MediaQuery {...props} minWidth={0} />;
export const Medium = props => <MediaQuery {...props} minWidth={640} />;
export const Large = props => <MediaQuery {...props} minWidth={1024} />;

const Sizes = {
 SmallOnly,
 MediumOnly,
 LargeOnly,
 Small,
 Medium,
 Large
};

export default Sizes;
