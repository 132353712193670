import { createSelector } from '@reduxjs/toolkit';

export const getIndustries = s => s.entities.industries;
export const getIndustriesList = createSelector(
    getIndustries,
    s => Object.values(s || {})
);

const industryOptionMapFunc = v => ({
    label: `${v.industry_name}`.trim(),
    value: v.industry_id
});

export const industryOptions = createSelector(
    getIndustriesList,
    s => s.map(industryOptionMapFunc)
);
