export const ACTIVITY_TYPE_MAP = {
    'SALES ORDER': { val: 'SO', },
    'PURCHASE ORDER': { val: 'PO', },
    'INVOICE': { val: 'Invoice', },
    'ESTIMATE': { val: 'Estimate', },
    'PRESENTATION': { val: 'Presentation', },
    'OPPORTUNITY': { val: 'Opportunity', },
    'MEETING': { val: 'Meeting', href: 'none', },
    'CALL': { val: 'Call', href: 'none', },
    'NOTE': { val: 'Note', href: 'none', },
    'EMAIL': { val: 'Email', href: 'none', },
};

export const ORDER_TYPE_MAP = {
    'OPPORTUNITY': 'Opportunity',
    'PRESENTATION': 'Presentation',
    'ESTIMATE': 'Estimate',
    // 'STORE': 'Store',
    'SALES ORDER': 'Sales Order',
    'INVOICE': 'Invoice'
};

export const PHONE_TYPE_MAP = {
    'WORK': 'Work',
    'HOME': 'Home',
    'CELL': 'Cell',
    'FAX': 'Fax',
};

export const ADDRESS_TYPE_MAP = {
    'BILLING': 'Billing',
    'SHIPPING': 'Shipping',
    'BOTH': 'Both',
};

