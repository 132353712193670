import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCompanyData } from '../../selectors';
import { updateCompanyData, updateAvalaraTesting } from '../../actions';
import { createAvalaraShopIssuesPopup } from '../../actions/popup';
import { oauth, parseRestBoolean } from '../../utils';
import {
  connectAvalara, createAvalaraCategory, updateAvalaraCategory, deleteAvalaraCategory, disconnectAvalara
} from '../../actions/avalara';
import { memoize } from 'lodash';

const getAvalaraTaxCodes = memoize(
  () => {
    return oauth("GET", "avalara/tax-codes", {});
  }
);

const getAvalaraEntityUseCodes = memoize(
  () => {
    return oauth("GET", "avalara/entity-use-codes", {});
  }
);

const resolveAddress = (data) => {
  return oauth("GET", "avalara/resolve-address", data);
};

export const useAvalara = () => {
  const dispatch = useDispatch();
  const {
    company_id, avalara_company_code, avalara_environment, default_avalara_category_id, default_avalara_shipping_category_id,
    avalara_enable, avalara_testing, avalara_recording,
  } = useSelector(getCompanyData) || {};

  return {
    avalara_company_code,
    default_avalara_category_id,
    default_avalara_shipping_category_id,
    avalara_testing: parseRestBoolean(avalara_testing),
    avalara_recording: parseRestBoolean(avalara_recording),
    getEnvironment: useCallback(() => {
      return avalara_environment !== 'production' ? 'sandbox' : 'production';
    }),
    isConnected: useCallback(() => {
      return avalara_enable;
    }, [avalara_enable]),
    connect: useCallback(({ avalara_account_id, avalara_license_key, avalara_environment }) => {
      return dispatch(connectAvalara({ avalara_account_id, avalara_license_key, avalara_environment }));
    }, []),
    disconnect: useCallback(() => {
      return dispatch(disconnectAvalara());
    }, []),
    updateDefaultCompanyCode: useCallback((avalara_company_code) => {
      return dispatch(updateCompanyData(company_id, { avalara_company_code }));
    }, [company_id]),
    updateDefaultCategory: useCallback((default_avalara_category_id) => {
      return dispatch(updateCompanyData(company_id, { default_avalara_category_id }));
    }, [company_id]),
    updateShippingCategory: useCallback((default_avalara_shipping_category_id) => {
      return dispatch(updateCompanyData(company_id, { default_avalara_shipping_category_id }));
    }, [company_id]),
    createCategory: useCallback(({ category, avalara_tax_code }) => {
      return dispatch(createAvalaraCategory({ category, avalara_tax_code }));
    }, []),
    updateCategory: useCallback(({
      avalara_category_id, category, avalara_tax_code
    }) => {
      return dispatch(updateAvalaraCategory({
        avalara_category_id, category, avalara_tax_code
      }));
    }, []),
    deleteCategory: useCallback((avalara_category_id) => {
      return dispatch(deleteAvalaraCategory(avalara_category_id));
    }, []),
    updateTesting: useCallback((avalara_testing) => {
      return dispatch(updateAvalaraTesting(company_id, avalara_testing)).catch(
        ({ json }) => dispatch(createAvalaraShopIssuesPopup(company_id, json.avalara_errors))
      );
    }, [company_id]),
    updateRecording: useCallback((avalara_recording) => {
      return dispatch(updateCompanyData(company_id, { avalara_recording }));
    }, [company_id]),
    getAvalaraTaxCodes,
    getAvalaraEntityUseCodes,
    resolveAddress,
  };
};
