import { connect } from 'react-redux';
import GlobalSearch from '../components/GlobalSearch';
import { createSearch } from '../actions/project';

const mapStateToProps = (state) => {
  return {
    identity: state.identity,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearch: (string) => {
    dispatch(createSearch(string));
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(GlobalSearch);
