import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import WebsocketContext from '../../context/websocket-context';
import { oauth } from '../../utils';
import { window } from '../../global';
import ChatBots from './ChatBots';
import ChatSupportSessions from './ChatSupportSessions';
import { Box } from '@commonsku/styles';

export default function ChatAgentPortal({ identity, minimal = false }) {
    const { chats, saveActivity, addChat } = useContext(WebsocketContext);
    const [loading, setLoading] = useState(true);
    const ulElement = useRef(null);
    const unmountedRef = useRef(false);

    useEffect(() => {
        window.setTimeout(() => {
            const ele = ulElement.current;
            if (!ele) return false;
            ele.scrollTop = ele.scrollHeight - ele.clientHeight;
        }, 200);
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const previousChats = await oauth('GET', 'chat-user-activity', {});
            for (const chat of previousChats.json.activities) {
                addChat(chat.activity, chat.date_created);
            }
            if (unmountedRef.current) return;
            setLoading(false);
        };

        fetchData();

        return () => {
            unmountedRef.current = true;
        };
    }, [addChat]);

    const notifyMe = useCallback((chat) => {
        if (loading) return;
        const data = chat.fullMessage.split(':');
        const user = data[0].split('-').pop();
        const url = data.slice(1).join(':');

        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            const notification = new Notification(
                'new user: ' + user, { body: 'New client connected on ' + url, icon: '/images/favicons/favicon.ico', requireInteraction: true }
            );
            notification.onclick = () => {
                window.open(url);
            };
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification(
                        'new user: ' + user, { body: 'new chat user body', icon: '/images/favicons/favicon.ico', requireInteraction: true }
                    );
                    notification.onclick = () => {
                        window.open(url);
                    };
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }, [loading]);

    useEffect(() => {
        if (loading) return;

        // Notify recently received
        const lastChat = chats.slice(chats.length - 1);
        if (!lastChat || lastChat.length === 0) return;
        const chat = lastChat.pop();

        if (!chat.notify) return;

        notifyMe(chat);
        chat.message = chat.fullMessage;
        saveActivity(chat);

    }, [chats, loading, notifyMe, saveActivity]);

    const updateAgentStatus = () => {
        oauth('POST', 'chat-online-agent', {});
    };

    useEffect(() => {
        updateAgentStatus();
        const intervalId = setInterval(updateAgentStatus, 600 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Minimal portal
    if (minimal) {
        return <ChatSupportSessions identity={identity} minimal={true} />;
    }

    const activities = (<div className="medium-12 large-6 columns">
        <h1>Clients activities</h1>
        {chats.length === 0 && loading && <p>Loading...</p>}
        {chats.length === 0 && !loading && <p>No client is active right now...</p>}
        {chats.length > 0 && (<ul className="chat-messages" ref={ulElement}>
            {
                chats.map(({ fullMessage: chat, time }, index) => {
                    const data = chat.split(':');
                    const user = data[0].split('-').pop();
                    const url = data.slice(1).join(':');
                    const location = url.replace(window.location.origin, '');
                    const chatMessage = (
                        <span><span className="time" style={{ color: "grey", fontSize: '0.7em', marginRight: '1em' }}>
                            {time.toLocaleString()}</span><span className="username">{user}</span> connected on <a href={url} target="_blank" rel="noreferrer">{location}</a>
                        </span>
                    );
                    return <li className='chatMessage' key={index}>{chatMessage}</li>;
                })
            }
        </ul>)}
    </div>);

    const chatBlock = (
        <div className="main-content shop-config-container">
            <div className="row section-checkout" style={{ padding: '15px' }}>
                <div className="medium-12 large-6 columns" style={{marginBottom: '15px'}}>
                    <Box>
                        <div className="row" style={{ padding: '15px' }}>
                            <h4 style={{borderBottom: 'none', marginBottom: '0'}}>Support Chat Sessions</h4>
                            <ChatSupportSessions identity={identity} />
                        </div>
                    </Box>
                </div>
                <div className="medium-12 large-6 columns">
                    <ChatBots />
                </div>
            </div>
        </div>
    );
    return (
        <div>
            {chatBlock}
        </div>
    );
}
