import { get, trim } from 'lodash';
import React, { Component } from 'react';

import ProofStatus from '../models/ProofStatus';
import { FromNow } from './moment';

export default class ProofComment extends Component {
  render() {
    const { commenter_name, date_created, text, meta } = this.props.comment;
    let status_id = null;
    try {
      status_id = get(JSON.parse(meta), 'status_id');
    } catch(error) {
      console.log(error);
    }
    const status = status_id === ProofStatus.Approved ? '(Approved)' :
        status_id === ProofStatus.ChangeRequested ? '(Request Change)' : ''
    ;
    return (!trim(text) && !status) ? null : <div style={{ padding: 5 }}>
      <div>
        <span style={{ fontWeight: 'bold', fontSize: 14 }}>{ commenter_name }:</span>
        <span style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 14 }}>{
            status_id === ProofStatus.Approved ? '(Approved)' :
            status_id === ProofStatus.ChangeRequested ? '(Request Change)' : ''
        }</span>
        <span style={{ float: 'right', fontSize: 10, fontColor: '#F1F1F1' }}><FromNow value={date_created}/></span>
      </div>
      <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{
        __html: text,
      }}/>
    </div>;
  }
}