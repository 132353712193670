import * as actions from '../actions/message';
import { UPDATE_SUGGESTIONS_SUCCESS } from '../actions/project';

const reduce = (state = {}, action) => {
  let message_id;
  let updated_message;
  let parent_id;
  let comment;
  let updated_note;
  let reminder_user;
  switch (action.type) {
    case actions.UPDATE_MESSAGE_REQUEST:
      // fall through
    case actions.UPDATE_MESSAGE_SUCCESS:
      parent_id=action.payload.parent_id;
      return Object.assign({}, state, {[parent_id]:Object.assign({},  state[parent_id], {
       pinned : action.payload.pinned
      })});
    case actions.DELETE_MESSAGE_SUCCESS:
      message_id = action.payload.id;
      return Object.keys(state).reduce((result, key) => {
              if (key != message_id) {
                  result[key] = state[key];
              }
              return result;
          }, {});
    case actions.UPDATE_MESSAGE_FAILURE:
      message_id = action.payload.id;
      updated_message = Object.assign({}, state[message_id], action.payload.data);
      return Object.assign({}, state, {[message_id]: updated_message});
    case actions.ADD_MESSAGE_SUCCESS:
      updated_message = action.payload.message || {};
      message_id = updated_message.message_id;
      reminder_user = updated_message.reminder_user || {};
      return Object.assign({}, state, {[message_id]: Object.assign({}, updated_message, {
        actor: {
          user_id: updated_message.user_id || updated_message.created_by,
          user_image_paths: updated_message.user_image_paths,
          user_first_name: updated_message.user_first_name,
          user_last_name: updated_message.user_last_name,
        },
        note: updated_message.note_id ? {
          note_id: updated_message.note_id,
          date_reminder: updated_message.date_reminder,
          reminder_user: reminder_user.user_id,
          reminder_user_full_name: (reminder_user.user_first_name || '') + (reminder_user.user_first_name ? ' ' : '') + (reminder_user.user_last_name || ''),
          reminder_complete: updated_message.reminder_complete,
          note_type: updated_message.note_type,
          date_complete: updated_message.date_complete,
          contact_full_name: updated_message.contact ? updated_message.contact.contact_full_name : null,
          //for tasks.js
          message_id: updated_message.message_id,
          message_text: updated_message.message_text,
          reminder_user_first_name: updated_message.reminder_user ? updated_message.reminder_user.user_first_name : null,
          reminder_user_last_name: updated_message.reminder_user ? updated_message.reminder_user.user_last_name : null,
          created_by: updated_message.user_id,
          creator_user_first_name: updated_message.user_first_name,
          creator_user_last_name: updated_message.user_last_name,
          date_created: updated_message.date_created,
          latest_update: updated_message.latest_update,
          pinned:updated_message.pinned,
          parent_id:updated_message.note_parent_id,
          file: action.payload.files,
          user_image_paths:updated_message.user_image_paths
        } : null,
        date: updated_message.date ? updated_message.date : updated_message.date_created,
        text: updated_message.message_text,
        files: action.payload.files,
        parent_id: updated_message.message_id,
        parent_type: 'message'
      })});
    case actions.ADD_COMMENT_SUCCESS:
      updated_message = action.payload.message;
      parent_id = updated_message.parent_id;
      message_id = updated_message.message_id;
      if (!state[parent_id]) {
        return state;
      }
      comment = Object.assign({}, updated_message, {
        actor: {
          user_id: updated_message.user_id || updated_message.created_by,
          user_image_paths: updated_message.user_image_paths,
          user_first_name: updated_message.user_first_name,
          user_last_name: updated_message.user_last_name,
        },
        date: updated_message.date ? updated_message.date : updated_message.date_created,
        text: updated_message.message_text,
        original_parent_id: updated_message.parent_id,
        original_parent_type: updated_message.parent_type ? updated_message.parent_type.toLowerCase() : null
      });

      if (state[parent_id].comments) {
        return Object.assign({}, state, {[parent_id]: Object.assign({}, state[parent_id], {comments: state[parent_id].comments.concat(comment) })});
      } else {
        return Object.assign({}, state, {[parent_id]: Object.assign({}, state[parent_id], {replies: state[parent_id].replies.concat(comment) })});
      }
    case actions.UPDATE_NOTE_REQUEST:
    //fall through
    case actions.UPDATE_NOTE_SUCCESS:
    //fall through
    case actions.UPDATE_NOTE_FAILURE:
      if(!state[action.payload.id]) {
        return state;
      }
      if (state[action.payload.id].note) {
        updated_note = Object.assign({}, state[action.payload.id], {
          note: Object.assign({}, state[action.payload.id].note, action.payload.data)
        });
        return Object.assign({}, state, {[action.payload.id]: updated_note});
      }
      return Object.assign({}, state, {[action.payload.id]: Object.assign({}, state[action.payload.id], action.payload.data)});
    case UPDATE_SUGGESTIONS_SUCCESS:
      if(action.payload.params !== 'message-suggest') {
        return state;
      }
      message_id = action.payload.suggestion.message_id;
      let suggestion_id = action.payload.suggestion.suggestion_id;
      return Object.assign({}, state, {[message_id] : Object.assign({}, state[message_id], {suggestions: Object.assign({}, state[message_id].suggestions, {[suggestion_id]: Object.assign({}, state[message_id].suggestions[suggestion_id], { is_added: 1 }) })})});
    case actions.SYNC_MESSAGE_SUCCESS:
      let messages = action.payload.messages;
      let ordered_messages = Object.keys(messages)
      .map(k =>
        messages[k]
      ).sort((a, b) =>
        b.latest_update - a.latest_update
      );
      return ordered_messages.reduce((o, message) => {
        o[message.message_id || message.comment_id] = message;
        return o;
      }, {});
    case actions.UPDATE_REMINDER_SUCCESS:
      message_id = action.payload.id;
      if (!state[message_id]) {
        return state;
      }
       let update = action.payload.note;
      updated_note = Object.assign({}, state[message_id], {
        text: action.payload.note.message_text,
        note: Object.assign({}, state[message_id].note, update)
      });
      return Object.assign({}, state, {[message_id]: updated_note});
  }
  return state;
};

export default reduce;
