import {
  ADD_ITEM_SUCCESS,
  ADD_ITEM_LOCATION_SUCCESS,
  DELETE_ITEM_LOCATION_SUCCESS,
  UPDATE_ITEM_DECORATION_SUCCESS,
  UPDATE_ITEM_SUCCESS,
} from '../actions/item';
import { ADD_ARTWORK_SUCCESS, UPDATE_ARTWORK_SUCCESS, DELETE_ARTWORK_SUCCESS } from '../actions/artwork';
import { ADD_ORDER_SUCCESS } from '../actions/project';
import { LOAD_ORDER_SUCCESS } from '../actions/order';
import { UPDATE_PRODUCT_ITEMS_SUCCESS } from '../actions/shop';
import { omitBy } from 'lodash';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ITEM_SUCCESS:
      return Object.assign({}, state, action.payload.artworks.reduce((o, a) => {o[a.artwork_id] = a; return o;}, {}));
    case ADD_ARTWORK_SUCCESS:
      return Object.assign({}, state, {[action.payload.id]: action.payload.data.artwork});
    case UPDATE_ARTWORK_SUCCESS:
      return Object.assign({}, state, {[action.payload.id]: Object.assign({}, state[action.payload.id], action.payload.data.artwork)});
    case DELETE_ARTWORK_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case ADD_ITEM_LOCATION_SUCCESS:
      if(!action.payload.item_locations) {
        return state;
      }

      let state_copy = Object.assign({}, state);
      action.payload.item_locations.forEach(il => {
        il.artworks.forEach(a => {
          state_copy[a.artwork_id] = a;
        });
      });
      return state_copy;
    case ADD_ORDER_SUCCESS:
      const artworks = action.payload.data.order.items.reduce((a, i) => a.concat(i.artworks), []);
      return Object.assign({}, state, artworks.reduce((o, a) => {o[a.artwork_id] = a; return o;}, {}));
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.artworks.reduce((o, a) => {
          o[a.artwork_id] = a;
          return o;
        }, {})
      )));
    case UPDATE_ITEM_DECORATION_SUCCESS:
      return {
        ...state,
        ...Object.values(state).filter(a => a.item_location_id === action.payload.item_decoration.item_location_id).reduce(
          (o, a) => ({
            ...o,
            [a.artwork_id]: {
              ...a,
              imprint_id: action.payload.item_decoration.imprint_id
            }
          }), {}
        )
      };
    case DELETE_ITEM_LOCATION_SUCCESS:
      return Object.keys(state).filter(k => !action.payload.deleted.artworks.includes(k)).reduce((o, k) => ({ ...o, [k]: state[k] }), {});
    case UPDATE_PRODUCT_ITEMS_SUCCESS:
      return { ...state, ...action.payload.artworks };
    case UPDATE_ITEM_SUCCESS: {
      if (action.payload.field === 'is_3rd_party_decorator') {
        const { id, response: { artworks } } = action.payload;
        if (artworks.length !== 0) {
          return state;
        }
        return _.omitBy(state, a => a.item_id === id);
      }
      return state;
    }
  }
  return state;
};

export default reducer;
