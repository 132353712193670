import React from "react";
import BasicLocationDropdownMenu from "./BasicLocationDropdownMenu";

const NonPromostandardsLocationMenu = ({ children, options, ...props }) => {
  return (
    <BasicLocationDropdownMenu
      options={options}
      children={children}
      {...props}
    />
  );
};

export default NonPromostandardsLocationMenu;
