import React from 'react';

import { formatMoney } from '../utils';
import TaxSelect from './TaxSelect';

const ItemTaxAmount = ({ tax_amount, taxes, zip2tax = false, onUpdate, onDelete, locked }) => (
  <tr className="totals">
    <td colSpan="1">
      {(!locked && !zip2tax) && <a className="delete" onClick={e => { e.preventDefault(); onDelete(tax_amount.tax_amount_id); }}>&times;</a>}
      <TaxSelect
        disabled={locked} zip2tax={zip2tax} value={tax_amount.tax_id} avalara={true}
        onChange={onUpdate(tax_amount.tax_amount_id, 'tax_id', tax_amount.tax_id)}
      />
    </td>
    <td colSpan="1" style={{textAlign: 'right'}}>${formatMoney(tax_amount.amount)}</td>
  </tr>
);

export default ItemTaxAmount;
