import { oauth, uploadFile, uploadGuestFile } from '../utils';

import { createAddTemp } from './temp';

import { createFinalizeAddMessage } from './message';
import { AddCollaborateMessageToProject } from './project';
import { createFinalizeAddProductImage } from './index';
import { addProof } from './purchase_order_proof';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const LOAD_FILES_LIST_REQUEST = 'LOAD_FILES_LIST_REQUEST';
export const LOAD_FILES_LIST_SUCCESS = 'LOAD_FILES_LIST_SUCCESS';
export const LOAD_FILES_LIST_FAILURE = 'LOAD_FILES_LIST_FAILURE';

const createUpdateFileRequest = file => ({
  type: UPDATE_FILE_REQUEST,
  payload: {
    id: file.file_id,
    data: file
  }
});

const createFinalizeUpdateFile = file => ({
  type: UPDATE_FILE_SUCCESS,
  payload: {
    id: file.file_id,
    data: file
  }
});

const createFailedUpdateFile = file_id => ({
  type: UPDATE_FILE_FAILURE,
  payload: {
    id: file_id
  }
});

export const createUpdateFile = file => dispatch => {
  dispatch(createUpdateFileRequest(file));

  oauth('PUT', `file/${file.file_id}`, file).then(
    ({ json }) => dispatch(createFinalizeUpdateFile(json.file)),
    ({ json }) => dispatch(createFailedUpdateFile(file.file_id))
  );
};

export const createUpdateFileV2 = file => dispatch => {
  dispatch(createUpdateFileRequest(file));

  if (file.update_remote === false) { return dispatch(createFinalizeUpdateFile(file)); }

  oauth('PUT', `file/${file.file_id}`, file).then(
    ({ json }) => {
      if (file.additionalPayload) {
        return dispatch(createFinalizeUpdateFile({
          ...(json.file || {}),
          ...file.additionalPayload
        }));
      }
      return dispatch(createFinalizeUpdateFile(json.file));
    },
    ({ json }) => dispatch(createFailedUpdateFile(file.file_id))
  );
};

const createUploadFileRequest = (parent_id, parent_type, file_name) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: {
    data: {
      parent_id,
      parent_type,
      file_name
    }
  }
});

const createFinalizeUploadFile = file => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    id: file.file_id,
    data: file
  }
});

const createFailedUploadFile = (parent_id, parent_type, file_name) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: {
    data: {
      parent_id,
      parent_type,
      file_name
    }
  }
});

export const createUploadFile = (parent_id, parent_type, file_blob, folder_id = null, progress_cb = null, index = false) => dispatch => {
  dispatch(createUploadFileRequest(parent_id, parent_type, file_blob.name));
  return uploadFile(parent_id, parent_type, file_blob, folder_id, progress_cb, index).then(
    (response) =>  {
      //axios uses data and fetch uses json
      let response_file;
      if(response.data) {
        response_file = response.data.file;
      }else{
        response_file = response.json.file;
      }
      dispatch(createFinalizeUploadFile(response_file));
      if(parent_type === 'ITEM') {
        dispatch(createFinalizeAddProductImage(parent_id, response_file.file_id));
      }else if(parent_type === 'USER') {
        // used in send_email.tpl
        var attachments = document.querySelector('input[name=attachment_file_ids]');
        var attachmentList = document.querySelector('div.attachment-list');
        attachments.value = (attachments.value + "," + response_file.file_id);
        var html = "<p data-file-id='" + response_file.file_id + "'>" + response_file.file_display_name + " <a href='#' class='remove-file'>Remove</a></p>";
        attachmentList.insertAdjacentHTML('beforeend', html);
        attachmentList.style.display = 'block';
      }
      return {
        type: UPLOAD_FILE_SUCCESS,
        payload: {
          id: response_file.file_id,
          data: response_file
        }
      };
    },
    () => dispatch(createFailedUploadFile(file_blob.name))
  );
};

export const createUploadGuestFile = (parent_id, parent_type, file_blob, tenant_id) => dispatch => {
  dispatch(createUploadFileRequest(parent_id, parent_type, file_blob.name));
  return uploadGuestFile(parent_id, parent_type, file_blob, tenant_id).then(
    ({ json }) => dispatch(createFinalizeUploadFile(json.file)),
    ({ json }) => dispatch(createFailedUploadFile(file_blob.name))
  );
};

const createDeleteFileRequest = file_id => ({
  type: DELETE_FILE_REQUEST,
  payload: {
    id: file_id
  }
});

const createFinalizeDeleteFile = file_id => ({
  type: DELETE_FILE_SUCCESS,
  payload: {
    id: file_id
  }
});

const createFailedDeleteFile = file_id => ({
  type: DELETE_FILE_FAILURE,
  payload: {
    id: file_id
  }
});

export const createDeleteFile = file_id => dispatch => {
  dispatch(createDeleteFileRequest(file_id));

  oauth('DELETE', `file/${file_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteFile(file_id)),
    ({ json }) => dispatch(createFailedDeleteFile(file_id))
  );
};

const createLoadFilesListRequest = parent_id => ({
  type: LOAD_FILES_LIST_REQUEST,
  payload: {
    parent_id
  }
});

const createFinalizeLoadFilesList = files => ({
  type: LOAD_FILES_LIST_SUCCESS,
  payload: {
    files
  }
});

const createFailedLoadFilesList = files => ({
  type: LOAD_FILES_LIST_FAILURE,
  payload: {
    files
  }
});

export const createLoadFilesList = (parent_id, parent_type) => dispatch => {
  dispatch(createLoadFilesListRequest(parent_id));

  oauth('GET', `file`, {parent_id, parent_type}).then(
    ({ json }) => dispatch(createFinalizeLoadFilesList(json.files)),
    ({ json }) => dispatch(createFailedLoadFilesList(json.files))
  );
};

export const createSearchFiles = (parent_id, parent_type, search={}) => dispatch => {
  dispatch(createLoadFilesListRequest(parent_id));

  oauth('GET', `file/search`, {parent_id, parent_type, ...search}).then(
    ({ json }) => dispatch(createFinalizeLoadFilesList(json.files)),
    ({ json }) => dispatch(createFailedLoadFilesList(json.files))
  );
};
