import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { colors, ShowPopup, Tooltip } from '@commonsku/styles';
import { getThirdPartyShippingAccountDropdown } from '../selectors/dropdowns';
import { useIdentity } from '../hooks';
import { getIdentityUtils } from '../utils';
import TextInput from './TextInput';
import { createAddShippingQuote, createUpdateShippingQuote, createDeleteShippingQuote } from '../actions/shipping';
import Select from './Select';
import AddThirdPartyShippingAccountPopup from './AddThirdPartyShippingAccountPopup';

const getSortedThirdPartyShippingAccountDropdown = (state) => {
  return getThirdPartyShippingAccountDropdown(state).sort((a, b) => {
    const cmp = (a, b) => {
      if (a.account_name < b.account_name) {
        return -1;
      }
      if (a.account_name > b.account_name) {
        return 1;
      }
      return 0;
    };
    if (!a.client_id) {
      if (!b.client_id) {
        return cmp(a, b);
      }
      return -1;
    }
    if (!b.client_id) {
      return 1;
    }
    return cmp(a, b);
  });
};

export const useInitialShippingType = (data) => {
  const third_party_accounts = useSelector(getSortedThirdPartyShippingAccountDropdown);
  const tpaById = _.keyBy(third_party_accounts, 'third_party_account_id');
  if (data.third_party_account_id) {
    const { client_id, job_id } = _.get(tpaById, [data.third_party_account_id]) || {};
    if (client_id) {
      return 'CLIENT';
    } else if (!job_id) {
      return 'DISTRIBUTOR';
    }
  } else if (data.ship_method_other) {
    return 'OTHER';
  }
  return 'NONE';
};

const getErrors = ({ ship_type, ship_method_other, third_party_account_id, ship_method_id }) => {
  const errors = {};
  if (ship_type === 'OTHER') {
    if (_.isEmpty(ship_method_other)) {
      errors.ship_method_other = {message: `Supplier Shipping Method (Others) is required`};
    }
  } else {
    if (ship_type !== 'NONE' && _.isEmpty(third_party_account_id)) {
      errors.third_party_account_id = {message: `Shipping account is required`};
    }
    if (_.isEmpty(ship_method_id)) {
      errors.ship_method_id = {message: `Supplier Ship method is required`};
    }
  }
  return errors;
};

const handleChange = (prefix, newState, newErrors, onChange) => {
  return onChange({
    ..._.mapKeys(
      _.pick(newState, [
        'ship_type', 'third_party_account_id', 'ship_method_id', 'ship_method_other', 'ship_method_details'
      ]),
      (v, k) => `${prefix}_${k}`,
    ),
    [`${prefix}_ship_errors`]: newErrors,
  });
};

function filterShipMethodOptions(ship_method_options, ship_methods, tpaById, state, fobsSet) {
  const { ship_type, third_party_account_id } = state;
  const courier_id = _.get(tpaById, [third_party_account_id, 'courier_id']);
  if (['DISTRIBUTOR', 'CLIENT'].indexOf(ship_type) > -1) {
    return _.filter(ship_method_options, ({ key }) => {
      return (!courier_id || _.get(ship_methods, [key, 'courier_id']) === courier_id) && (fobsSet || !_.get(ship_methods, [key, 'is_pickup']) != 1);
    });
  } else if (ship_type === 'NONE') {
    return _.filter(ship_method_options, ({ key }) => {
      return _.get(ship_methods, [key, 'third_party']) !== 'REQUIRED' && (fobsSet || _.get(ship_methods, [key, 'is_pickup']) != 1);
    });
  }
  return ship_method_options;
}

const StormtechShippingCostLink = ({ division_id }) => {
  const url = {
    // Stormtech Canada
    'ce978bc4-a30d-11e2-80a6-1231392238ea': 'https://www.stormtechperformance.com/support/shipping/?&selectedRegionId=0',
    // Stormtech USA
    'cea002ae-a30d-11e2-80a6-1231392238ea': 'https://www.stormtechperformance.com/support/shipping/?&selectedRegionId=2',
  }[division_id] ?? null;

  return !url ? null : <a
    href={url} target="_blank" rel="noreferrer"
    style={{ color: colors.primary1.main, fontSize: 'medium', margin: '5px 10px 0' }}
  >Estimate Stormtech shipping costs</a>;
};

const ChooseShipping = ({
  prefix, hasShippingCost, fobsSet, company_avatar, psEligible,
  data = {},
  onChange = _.identity,
  ship_method_options,
  isSanMarEpo,
}) => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const initialData = _.mapKeys(
    _.pick(
      data,
      _.map(
        ['ship_method_id', 'third_party_account_id', 'ship_method_other', 'ship_method_details'],
        (field) => {
          return `${prefix}_${field}`;
        }
      )
    ),
    (value, field) => {
      return field.slice(prefix.length + 1);
    }
  );

  const quirks = useSelector((state) => {
    return state.entities.promostandards_quirks['vendor' === prefix ? data.vendor_source_parent_id : data.vendor_destination_parent_id] ?? {};
  });

  const hasPSQuirk = quirk => !!quirks[quirk];
  const getPSQuirk = quirk => quirks[quirk];

  const { hasCapabilities } = getIdentityUtils(identity);
  const third_party_accounts = useSelector(getSortedThirdPartyShippingAccountDropdown);
  const ship_methods = useSelector((state) => {
    return state.entities.ship_methods;
  });
  const job_id = useSelector((state) => {
    return Object.keys(state.entities.projects)[0];
  });
  const tpaById = _.keyBy(third_party_accounts, 'third_party_account_id');
  const initialShippingType = useInitialShippingType(initialData);
  const [state, setState] = useState({
    ship_type: initialShippingType, third_party_account_id: '',
    ship_method_id: '', ship_method_other: '', ship_method_details: '',
    ...initialData
  });
  const {
    ship_type, third_party_account_id, ship_method_id, ship_method_other, ship_method_details,
  } = state;
  const [errors, setErrors] = useState(getErrors(state));
  const tpa = _.get(tpaById, [third_party_account_id]);
  const shippingQuote = !!_.get(tpa, 'job_id') ? tpa : null;
  const [accountNumber, setAccountNumber] = useState(_.get(shippingQuote, 'account_number') || '');
  const imgStyle = { maxWidth: '1.5rem', maxHeight: '1.5rem', marginRight: '0.25rem' };
  const filteredShipMethodOptions = filterShipMethodOptions(ship_method_options, ship_methods, tpaById, state, fobsSet || !psEligible);

  const distributorAccounts = third_party_accounts.filter(
    tpa => !tpa.client_id && !tpa.job_id
  ).map(
    tpa => ({
      key: tpa.third_party_account_id,
      value: <span>
        <img style={imgStyle} src={company_avatar} alt="" />
        {tpa.account_name} {tpa.account_number} ({tpa.account_postal})
      </span>
    })
  );
  const clientAccounts = third_party_accounts.filter(
    tpa => !!tpa.client_id
  ).map(
    tpa => ({
      key: tpa.third_party_account_id,
      value: `${tpa.account_name} ${tpa.account_number} (${tpa.account_postal})`
    })
  );
  const hasThirdPartyAccountMethods = !psEligible || Object.values(ship_methods).filter(sm => sm.third_party !== 'UNAVAILABLE').length > 0;

  const handleFieldChange = useCallback(
    (field, value) => {
      const newState = { ...state, [field]: value };
      if (field === 'ship_type') {
        if (value === 'OTHER') {
          newState.third_party_account_id = null;
        } else {
          newState.ship_method_other = '';
          newState.ship_method_details = '';
        }
        if (value === 'NONE') {
          newState.third_party_account_id = _.trim(accountNumber) === ''
            ? null
            : (_.get(shippingQuote, 'third_party_account_id') || null)
          ;
        } else if (value === 'DISTRIBUTOR') {
          newState.third_party_account_id = _.get(distributorAccounts, '0.key') || null;
        } else if (value === 'CLIENT') {
          newState.third_party_account_id = _.get(clientAccounts, '0.key') || null;
        }
      }
      const newOptions = filterShipMethodOptions(ship_method_options, ship_methods, tpaById, newState, fobsSet);
      if (
        (field === 'third_party_account_id' || field === 'ship_type') &&
        !_.find(newOptions, { key: newState.ship_method_id })
      ) {
        newState.ship_method_id = _.get(newOptions, '0.key');
      }

      const newErrors = getErrors(newState);
      if (!_.isEqual(state, newState) || !_.isEmpty(newErrors)) {
        setState(newState);
        setErrors(newErrors);
        handleChange(prefix, newState, newErrors, onChange);
      }
    },
    [prefix, state, filteredShipMethodOptions, onChange]
  );

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      handleChange(prefix, state, errors, onChange);
    }
  }, []);

  useEffect(() => {
    if (!_.find(filteredShipMethodOptions, { key: state.ship_method_id }) && !_.isEmpty(filteredShipMethodOptions)) {
      handleFieldChange('ship_method_id', _.get(filteredShipMethodOptions, '0.key'));
    }
  }, [filteredShipMethodOptions, state]);

  useEffect(() => {
    if ((hasShippingCost || isSanMarEpo) && ship_type !== 'NONE') {
      handleFieldChange('ship_type', 'NONE');
    }
  }, [hasShippingCost, ship_type, handleFieldChange, isSanMarEpo]);

  return <>
    <div className="row">
      <div className="field row">
        <div className="small-12 medium-4 columns">
          <label style={{ fontSize: 'initial' }}>Shipping Account</label>
        </div>
        <div className="small-12 medium-8 columns">
          {hasPSQuirk('THIRD-PARTY-ACCOUNT-MESSAGE') && !hasThirdPartyAccountMethods ?
            <p>{getPSQuirk('THIRD-PARTY-ACCOUNT-MESSAGE')}</p> :
            <>
              <Tooltip
                id={`sanmar-tooltip-${prefix}`}
                place='top'
                style={{ backgroundColor: "rgba(18, 57, 82, 0.9)", padding: '13px 16px 13px 16px', borderRadius: 5, width: 334, display: isSanMarEpo && prefix === 'vendor' ? 'inline-block' : 'none', fontSize: 16 }}
              />
              <div data-tooltip-id={isSanMarEpo && prefix === 'vendor' ? `sanmar-tooltip-${prefix}` : null} data-tooltip-content="SanMar ePOs can only be shipped on Supplier account">
                <Select
                  options={[
                    { key: 'NONE', value: 'Supplier\'s Account' },
                    { key: 'DISTRIBUTOR', value: 'Our Account', disabled: hasShippingCost || !hasThirdPartyAccountMethods || isSanMarEpo },
                    { key: 'CLIENT', value: 'Client\'s Account', disabled: hasShippingCost || !hasThirdPartyAccountMethods || isSanMarEpo },
                    ...(!psEligible ? [{ key: 'OTHER', value: 'Other', disabled: hasShippingCost || isSanMarEpo }] : []),
                  ]}
                  value={ship_type}
                  onChange={({ value }) => {
                    if (value !== 'NONE') {
                      if (!_.isEmpty(shippingQuote)) {
                        dispatch(createDeleteShippingQuote(shippingQuote.third_party_account_id));
                      }
                      if (!_.isEmpty(accountNumber)) {
                        setAccountNumber('');
                      }
                    }

                    handleFieldChange('ship_type', value);
                  }}
                  style={{ marginBottom: '9px !important' }}
                  disabled={isSanMarEpo && prefix === 'vendor'}
                />
              </div>
            </>
          }
          {'DISTRIBUTOR' === ship_type && <>
            {!!errors.third_party_account_id && <div className="field-error">
              {errors.third_party_account_id.message}
            </div>}
            <Select
              placeholder="Select Account"
              options={distributorAccounts} value={third_party_account_id}
              onChange={({ value }) => handleFieldChange('third_party_account_id', value)}
              style={{ marginBottom: '9px !important' }}
            />
          </>}
          {'NONE' === ship_type && !!ship_method_id && !hasPSQuirk('NO-SHIP-QUOTES') && <TextInput
            placeholder="Shipping Quote" value={accountNumber}
            onBlur={() => {
              let promise;
              const quote_courier_id = _.get(ship_methods, [ship_method_id, 'courier_id']) || '';
              if (_.isEmpty(shippingQuote)) {
                promise = dispatch(createAddShippingQuote(job_id, quote_courier_id, accountNumber));
              } else {
                if (_.isEmpty(accountNumber)) {
                  promise = dispatch(createDeleteShippingQuote(shippingQuote.third_party_account_id));
                } else {
                  promise = dispatch(createUpdateShippingQuote(
                    shippingQuote.third_party_account_id, {account_number: accountNumber, courier_id: quote_courier_id}
                  ));
                }
              }
              promise.then((r) => {
                handleFieldChange(
                  'third_party_account_id',
                  _.get(r, 'payload.third_party_shipping_account.third_party_account_id') || null
                );
              });
            }}
            onChange={(e) => setAccountNumber(e.target.value)}
          />}
          {'CLIENT' === ship_type && <>
            {!!errors.third_party_account_id && <div className="field-error">
              {errors.third_party_account_id.message}
            </div>}
            <Select options={clientAccounts} value={third_party_account_id}
              style={{ display: 'inline-block', width: '80%', marginBottom: '9px !important' }}
              onChange={({ value }) => handleFieldChange('third_party_account_id', value)}
            />
            {hasCapabilities('MODIFY-DOWNSTREAM-ACCOUNT') ? <ShowPopup popup={AddThirdPartyShippingAccountPopup}
              render={({ onClick }) => {
                return <a style={{ float: 'right', verticalAlign: 'top' }} className="button hollow"
                  onClick={onClick}
                >+</a>;
              }}
            /> : null}
          </>}
          {!psEligible && 'OTHER' === ship_type && <>
            {!!errors.ship_method_other && <div className="field-error">
              {errors.ship_method_other.message}
            </div>}
            <TextInput placeholder="Shipping Method (Others)" value={ship_method_other}
              onChange={(e) => {
                handleFieldChange('ship_method_other', e.target.value);
              }}
            />
            <TextInput placeholder="Shipping Method (Details)" value={ship_method_details} onChange={(e) => {
              handleFieldChange('ship_method_details', e.target.value);
            }}/>
          </>}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="field row">
        <div className="small-12 medium-4 columns">
          <label style={{ fontSize: 'initial' }}>Shipping Method</label>
        </div>
        <div className="small-12 medium-8 columns" style={{ paddingBottom: '1rem' }}>
          {!!errors.ship_method_id && <div className="field-error">{errors.ship_method_id.message}</div>}
          <Select
            style={{ margin: 0, marginBottom: '9px !important' }}
            options={filteredShipMethodOptions} value={ship_method_id}
            className={errors.ship_method_id ? 'has-shipping-error' : ''}
            onChange={({ value }) => {
              handleFieldChange('ship_method_id', value);
              if (!_.isEmpty(shippingQuote)) {
                dispatch(createUpdateShippingQuote(
                  shippingQuote.third_party_account_id,
                  {courier_id: _.get(ship_methods, [value, 'courier_id']) || ''}
                ));
              }
            }}
          />
          <StormtechShippingCostLink division_id={data.vendor_source_parent_id}/>
        </div>
      </div>
    </div>
  </>;
};

export default ChooseShipping;
