import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Row, Col, Button,
    ArrowIcon,
    components,
    colors,
    IconButton,
} from '@commonsku/styles';
import {
    OpportunityFields,
    PresentationFields,
    EstimateFields,
    SalesOrderFields,
} from './StageFormFields';
import { contactsByCompanyIdOptions } from '../../selectors/contacts';
import { ContactSelect } from './Fields';

export default function DetailsForm({
    onSave,
    onShowNewContact,
    onShowNewAddress,
    form,
    updateField,
    updateForm,
    disableBillingContactSelect = false,
    ...props
}) {
  const contact_options = useSelector(s => contactsByCompanyIdOptions(s, form.client_id));
  const [isSaving, setIsSaving] = useState(false);

  const canSave = useMemo(() => {
    return (
      form.stage === 'SALES ORDER'
      && form.date_inhandsdate
    ) || (
      [
        'OPPORTUNITY',
        'PRESENTATION',
        'ESTIMATE',
      ].includes(form.stage)
      && form.total_budget !== ''
      && form.total_budget !== null
      && form.date_inhandsdate
    );
  }, [form]);

  async function handleSave() {
    if (!canSave || isSaving) { return; }
    setIsSaving(true);
    try {
      await onSave(form);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  }

  const SelectMenuList = useCallback((p) => {
    let text = '';
    if (p.selectProps.isContact) {
      text = 'Contact';
    } else if (p.selectProps.isAddress) {
      text = 'Address';
    }

    const onClick = () => {
      if (p.selectProps.isContact) {
        if (p.selectProps.isShipping) {
          onShowNewContact('SHIPPING');
        } else if (p.selectProps.isBilling) {
          onShowNewContact('BILLING');
        }
      } else if (p.selectProps.isAddress) {
        if (p.selectProps.isShipping) {
          onShowNewAddress('SHIPPING');
        } else if (p.selectProps.isBilling) {
          onShowNewAddress('BILLING');
        }
      }
    };

    return (
      <components.MenuList {...p}>
        {p.children}
        <div
          onClick={onClick}
          style={{
            cursor: 'pointer',
            paddingTop: '8px',
            paddingBottom: '8px',
            background: '#E1F7FA',
            color: '#00A0B6',
            bottom: 0,
            position: 'sticky',
            textAlign: 'center',
            marginBottom: 0,
            fontWeight: 'bold',
          }}
        >+ New {text}</div>
      </components.MenuList>
    );
  }, [onShowNewContact, onShowNewAddress,]);

  const renderStageFields = useCallback(() => {
    switch (form.stage) {
      case 'PRESENTATION':
        return <PresentationFields
          form={form}
          updateField={updateField}
          SelectMenuList={SelectMenuList}
        />;
      case 'ESTIMATE':
        return <EstimateFields
          form={form}
          updateField={updateField}
          SelectMenuList={SelectMenuList}
          disableBillingContactSelect={disableBillingContactSelect}
        />;
      case 'SALES ORDER':
        return <SalesOrderFields
          form={form}
          updateField={updateField}
          SelectMenuList={SelectMenuList}
          disableBillingContactSelect={disableBillingContactSelect}
        />;
      default:
        return <OpportunityFields
          form={form}
          updateField={updateField}
          SelectMenuList={SelectMenuList}
        />;
    }
  }, [
    form,
    updateField,
    SelectMenuList,
    disableBillingContactSelect,
  ]);

  return (
    <Row>
      <Col xs padded
           style={!['OPPORTUNITY', 'PRESENTATION'].includes(form.stage) ? { display: 'none' } : {}}
      >
        <ContactSelect
          isBilling
          label={'Client Contact'}
          options={contact_options}
          value={form.billing_contact_id}
          onChange={value => updateField('billing_contact_id', value)}
          components={{ MenuList: SelectMenuList }}
          isDisabled={disableBillingContactSelect}
        />
      </Col>
      {renderStageFields()}
      <Col xs pb={10} style={{ textAlign: "center" }} padded>
        <IconButton
          style={{ width: '100%' }}
          variant={canSave && !isSaving ? "primary" : "disabled"}
          onClick={handleSave}
          Icon={form.stage === 'OPPORTUNITY' ? null : ArrowIcon}
          iconPosition={'right'}
          iconProps={{direction: 'right'}}
        >
          {form.stage === 'OPPORTUNITY' ? 'Done' : 'Select Products'}
        </IconButton>
      </Col>
    </Row>
  );
}
