import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  isMidClick,
  isRightClick,
  loadImage,
  srcToFile,
  useImage,
  canvasToBlob,
  imageToBlob,
} from './utils';
import { rawOAuth } from '../../utils';
import useWindowSize from '../../hooks/useWindowSize';
import { ActivePanelState, ImageEditorToolkit } from './ImageEditorToolkit';
import { Point, Logo, LogoDragContext } from './Logo';
import './ImageEditor.css';

const MAXIMUM_IMAGE_WIDTH = 600;
const MAXIMUM_IMAGE_HEIGHT = 600;
const INITIAL_BRUSH_SIZE = 40;
const INITIAL_LOGO_SIZE = 150;
const BRUSH_COLOR = '#00A0B6BB';

function updateLogoDragContext(dragContext: LogoDragContext, position: Point) {
  return {
    target: dragContext.target,
    initial: dragContext.initial,
    delta: {
      x: position.x - dragContext.initial.x,
      y: position.y - dragContext.initial.y
    }
  };
}

interface Line {
  size?: number;
  pts: { x: number; y: number; }[];
}

type LineGroup = Array<Line>;

function drawLines(
  ctx: CanvasRenderingContext2D,
  lines: LineGroup,
  color = BRUSH_COLOR
) {
  ctx.strokeStyle = color;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';

  lines.forEach(line => {
    if (!line?.pts.length || !line.size) {
      return;
    }
    ctx.lineWidth = line.size;
    ctx.beginPath();
    ctx.moveTo(line.pts[0].x, line.pts[0].y);
    line.pts.forEach(pt => ctx.lineTo(pt.x, pt.y));
    ctx.stroke();
  });
}

interface ImageEditorProps {
  imageSource: string;
  logoFile?: File;
  disabled?: boolean;
  onAddLogo?: () => void;
  onRemoveLogo?: () => void;
  onUpdate?: (canvas: HTMLCanvasElement) => void;
  onToggleProcessing?: (isProcessing: boolean) => void; 
}

export const ImageEditor = ({
  imageSource,
  logoFile,
  disabled,
  onAddLogo,
  onRemoveLogo,
  onUpdate,
  onToggleProcessing
}: ImageEditorProps) => {
  const [file, setFile] = useState<File>();
  const [isInpainting, setIsInpainting] = useState<boolean>(false);
  const [isRemovingBackground, setIsRemovingBackground] = useState<boolean>(false);
  const isProcessing = isInpainting || isRemovingBackground;
  const [original, isOriginalLoaded] = useImage(file);
  const [renders, setRenders] = useState<HTMLImageElement[]>([]);
  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const [{ x, y }, setCoords] = useState({ x: -1, y: -1 });
  const [windowWidth, windowHeight] = useWindowSize();
  const [imageWidth, setImageWidth] = useState<number>(MAXIMUM_IMAGE_WIDTH);
  const [imageHeight, setImageHeight] = useState<number>(MAXIMUM_IMAGE_HEIGHT);

  // Erase
  const [brushSize, setBrushSize] = useState<number>(INITIAL_BRUSH_SIZE);
  const [maskCanvas] = useState<HTMLCanvasElement>(() => document.createElement('canvas'));
  const [lineGroups, setLineGroups] = useState<LineGroup[]>([]);
  const [curLineGroup, setCurLineGroup] = useState<LineGroup>([]);
  const [brushActive, setBrushActive] = useState(true);
  const [showBrush, setShowBrush] = useState(false);
  const [showRefBrush, setShowRefBrush] = useState(false);
  const [isPainting, setIsPainting] = useState(false);

  // Redo
  const [redoRenders, setRedoRenders] = useState<HTMLImageElement[]>([]);
  const [redoCurLines, setRedoCurLines] = useState<Line[]>([]);
  const [redoLineGroups, setRedoLineGroups] = useState<LineGroup[]>([]);

  // Logo
  const [logoImage, isLogoLoaded] = useImage(logoFile);
  const [logoDragContext, setLogoDragContext] = useState<LogoDragContext | undefined>();
  const [logoScale, setLogoScale] = useState<number>(1);
  const [logoRotation, setLogoRotation] = useState<number>(0);
  const [logoActive, setLogoActive] = useState(true);

  const logo = useMemo<Logo | null>(
    () => {
      if (isLogoLoaded) {
        return new Logo(logoImage, INITIAL_LOGO_SIZE, INITIAL_LOGO_SIZE);
      }
      return null;
    },
    [isLogoLoaded]
  );

  // Cursor
  const [hoveringCanvas, setHoveringCanvas] = useState(false);

  const mouseXY = useCallback((ev: SyntheticEvent): Point => {
    const mouseEvent = ev.nativeEvent as MouseEvent;
    return { x: mouseEvent.offsetX, y: mouseEvent.offsetY };
  }, [context]);

  const draw = useCallback(
    (render: HTMLImageElement, lineGroup: LineGroup) => {
      if (!context) {
        return;
      }

      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.drawImage(render, 0, 0, imageWidth, imageHeight);
      drawLines(context, lineGroup);

      if (logo) {
        logo.scale(logoScale);
        logo.rotate(logoRotation);
        logo.draw(context, logoDragContext);
      }
    },
    [
      context,
      imageHeight,
      imageWidth,
      logo,
      logoScale,
      logoRotation,
      logoDragContext,
    ]
  );

  // Load the initial image
  useEffect(() => {
    const fetchAndSetFile = async () => {
      const fileFromSource = await srcToFile(imageSource, 'render_0', 'image/png');
      setFile(fileFromSource);
    };
    fetchAndSetFile();
  }, []);

  // Let parent know we're processing something
  useEffect(() => {
    if (onToggleProcessing != null) {
      onToggleProcessing(isProcessing)
    }
  }, [isProcessing])

  const drawLinesOnMask = useCallback(
    (_lineGroups: LineGroup[]) => {
      if (!context?.canvas.width || !context?.canvas.height) {
        throw new Error('canvas has invalid size');
      }
      maskCanvas.width = context?.canvas.width;
      maskCanvas.height = context?.canvas.height;
      const ctx = maskCanvas.getContext('2d');
      if (!ctx) {
        throw new Error('could not retrieve mask canvas');
      }

      _lineGroups.forEach(lineGroup => {
        drawLines(ctx, lineGroup, 'white');
      });
    },
    [context, maskCanvas, imageWidth, imageHeight]
  );

  const disableUndo = () => {
    if (isProcessing) return true;
    if (renders.length > 0) return false;
    return curLineGroup.length === 0;
  };

  const disableRedo = () => {
    if (isProcessing) return true;
    if (redoRenders.length > 0) return false;
    return redoCurLines.length === 0;
  };

  const hadDrawSomething = useCallback(
    () => curLineGroup.length !== 0,
    [curLineGroup],
  );

  const drawOnCurrentRender = useCallback(
    (lineGroup: LineGroup) => {
      if (renders.length === 0) {
        draw(original, lineGroup);
      } else {
        draw(renders[renders.length - 1], lineGroup);
      }
    },
    [original, renders, logo, draw]
  );

  const inpaint = useCallback(
    async () => {
      if (!file || !hadDrawSomething()) {
        return;
      }
      const newLineGroups = [...lineGroups, curLineGroup];

      setCurLineGroup([]);
      setIsPainting(false);
      setIsInpainting(true);
      drawLinesOnMask([curLineGroup]);

      const targetFile = await getCurrentRender();
      const maskFile = await canvasToBlob(maskCanvas, 'image/png');

      const data = {
        action: 'inpaint',
        image: targetFile,
        mask: maskFile
      };

      try {
        const res = await rawOAuth('POST', 'image-editor', null, data, { 'Accept': 'image/png' });
        if (!res || !res.ok) {
          throw new Error('Server error');
        }

        const blob = await res.blob();
        const newRender = new Image();
        await loadImage(newRender, URL.createObjectURL(blob));

        const newRenders = [...renders, newRender];
        setRenders(newRenders);

        draw(newRender, []);
        // Only append new LineGroup after inpainting success
        setLineGroups(newLineGroups);

        // Clear redo stack
        resetRedoState();
      } catch (e: any) {
        drawOnCurrentRender([]);
      } finally {
        setIsInpainting(false);
      }
    },
    [
      lineGroups,
      curLineGroup,
      maskCanvas,
      drawOnCurrentRender,
      hadDrawSomething,
      drawLinesOnMask,
    ]
  );

  const getCurrentRender = useCallback(async () => {
    if (renders.length > 0) {
      const lastRender = renders[renders.length - 1];
      return imageToBlob(lastRender, 'image/png');
    }

    const [width, height] = getCurrentWidthHeight();
    return imageToBlob(original, 'image/png', width, height);
  }, [context, renders]);

  const removeBackground = useCallback(
    async () => {
      if (isProcessing) return;

      try {
        setIsRemovingBackground(true);
        const targetFile = await getCurrentRender();

        // remove background controller
        const data = {
          action: 'remove_background',
          image: targetFile,
        };

        const res = await rawOAuth('POST', 'image-editor', null, data, { Accept: 'image/png' });

        if (!res.ok) {
          throw new Error('Server error');
        }

        const blob = await res.blob();
        const newRender = new Image();
        await loadImage(newRender, URL.createObjectURL(blob));
        const newRenders = [...renders, newRender];
        setRenders(newRenders);
        const newLineGroups = [...lineGroups, []];
        setLineGroups(newLineGroups);
      } finally {
        setIsRemovingBackground(false);
      }
    },
    [
      renders,
      setRenders,
      isProcessing,
      lineGroups,
      setLineGroups,
    ]
  );

  const getCurrentWidthHeight = useCallback(() => {
    let width = MAXIMUM_IMAGE_WIDTH;
    let height = MAXIMUM_IMAGE_HEIGHT;
    if (!original) {
      return [width, height];
    }
    if (renders.length === 0) {
      width = original.naturalWidth;
      height = original.naturalHeight;
    } else if (renders.length !== 0) {
      width = renders[renders.length - 1].width;
      height = renders[renders.length - 1].height;
    }
    if (width > MAXIMUM_IMAGE_WIDTH) {
      height = height * MAXIMUM_IMAGE_WIDTH / width;
      width = MAXIMUM_IMAGE_WIDTH;
    }
    if (height > MAXIMUM_IMAGE_HEIGHT) {
      width = width * MAXIMUM_IMAGE_HEIGHT / height;
      height = MAXIMUM_IMAGE_HEIGHT;
    }

    return [width, height];
  }, [original, renders]);

  // Draw once the original image is loaded
  useEffect(() => {
    if (!isOriginalLoaded) {
      return;
    }

    const [width, height] = getCurrentWidthHeight();
    setImageWidth(width);
    setImageHeight(height);

    const rW = windowWidth / width;
    const rH = windowHeight / height;

    let s = 1.0;
    if (rW < 1 || rH < 1) {
      s = Math.min(rW, rH);
    }

    if (context?.canvas) {
      context.canvas.width = width;
      context.canvas.height = height;
      drawOnCurrentRender([]);
    }
  }, [
    isOriginalLoaded,
    windowWidth,
    windowHeight,
    drawOnCurrentRender,
    getCurrentWidthHeight,
  ]);

  const resetRedoState = () => {
    setRedoCurLines([]);
    setRedoLineGroups([]);
    setRedoRenders([]);
  };

  const onMouseMove = (ev: SyntheticEvent) => {
    const mouseEvent = ev.nativeEvent as MouseEvent;
    const offsetLeft = context?.canvas.offsetLeft ?? 0;
    const offsetTop = context?.canvas.offsetTop ?? 0;
    setCoords({ x: mouseEvent.offsetX + offsetLeft, y: mouseEvent.offsetY + offsetTop });
    toggleShowBrush(hoveringCanvas && (logo == null || !logo.isUnderMouse(mouseXY(ev))));
  };

  const onMouseDrag = (ev: SyntheticEvent) => {
    if (disabled) return;

    if (logoDragContext) {
      setLogoDragContext(updateLogoDragContext(logoDragContext, mouseXY(ev)));
      return;
    }

    if (!isPainting) return;
    if (curLineGroup.length === 0) return;

    const lineGroup = [...curLineGroup];
    lineGroup[lineGroup.length - 1].pts.push(mouseXY(ev));
    setCurLineGroup(lineGroup);
    drawOnCurrentRender(lineGroup);
  };

  const onPointerUp = (ev: SyntheticEvent) => {
    if (logo && logoDragContext) {
      logo.applyDragContext(logoDragContext);
      setLogoDragContext(undefined);
    }

    if (isMidClick(ev)) {
      return;
    }
    if (!original.src) {
      return;
    }
    const canvas = context?.canvas;
    if (!canvas) {
      return;
    }
    if (isInpainting) {
      return;
    }
    if (!isPainting) {
      return;
    }

    setIsPainting(false);
  };

  const handleLogoClick = (mousePosition: Point) => {
    setLogoDragContext(logo.getDragContext(mousePosition));
  }

  const handleBrushStroke = (mousePosition: Point) => {
    setIsPainting(true);
    const newLineGroup = [
      ...curLineGroup,
      {
        size: brushSize,
        pts: [mousePosition],
      },
    ];
    
    setCurLineGroup(newLineGroup);
    drawOnCurrentRender(newLineGroup);
  }

  const onMouseDown = (ev: SyntheticEvent) => {
    if (isRightClick(ev) || isMidClick(ev)) return;
    if (isProcessing || !original.src) return;

    const canvas = context?.canvas;
    if (!canvas) return;

    const mousePosition = mouseXY(ev);

    if (logo && logoActive && logo.isUnderMouse(mousePosition)) {
      handleLogoClick(mousePosition);
    } else if (brushActive) {
      handleBrushStroke(mousePosition);
    }
  };

  const undoStroke = useCallback(() => {
    if (curLineGroup.length === 0) {
      return;
    }

    const lastLine = curLineGroup.pop()!;
    const newRedoCurLines = [...redoCurLines, lastLine];
    setRedoCurLines(newRedoCurLines);

    const newLineGroup = [...curLineGroup];
    setCurLineGroup(newLineGroup);
    drawOnCurrentRender(newLineGroup);
  }, [curLineGroup, redoCurLines, drawOnCurrentRender]);

  const undoRender = useCallback(() => {
    if (!renders.length) {
      return;
    }

    // save line Group
    const latestLineGroup = lineGroups.pop()!;
    setRedoLineGroups([...redoLineGroups, latestLineGroup]);
    // If render is undo, clear strokes
    setRedoCurLines([]);

    setLineGroups([...lineGroups]);
    setCurLineGroup([]);
    setIsPainting(false);

    // save render
    const lastRender = renders.pop()!;
    setRedoRenders([...redoRenders, lastRender]);

    const newRenders = [...renders];
    setRenders(newRenders);
  }, [
    draw,
    renders,
    redoRenders,
    redoLineGroups,
    lineGroups,
    original,
    context,
  ]);

  const undo = () => {
    if (curLineGroup.length !== 0) {
      undoStroke();
    } else {
      undoRender();
    }
  };

  const redoStroke = useCallback(() => {
    if (redoCurLines.length === 0) {
      return;
    }
    const line = redoCurLines.pop()!;
    setRedoCurLines([...redoCurLines]);

    const newLineGroup = [...curLineGroup, line];
    setCurLineGroup(newLineGroup);
    drawOnCurrentRender(newLineGroup);
  }, [curLineGroup, redoCurLines, drawOnCurrentRender]);

  const redoRender = useCallback(() => {
    if (redoRenders.length === 0) {
      return;
    }
    const lineGroup = redoLineGroups.pop()!;
    setRedoLineGroups([...redoLineGroups]);

    setLineGroups([...lineGroups, lineGroup]);
    setCurLineGroup([]);
    setIsPainting(false);

    const render = redoRenders.pop()!;
    const newRenders = [...renders, render];
    setRenders(newRenders);
  }, [draw, renders, redoRenders, redoLineGroups, lineGroups, original]);

  const redo = () => {
    if (redoCurLines.length !== 0) {
      redoStroke();
    } else {
      redoRender();
    }
  };

  // Convert the current image into a canvas, then call onUpdate prop
  const dispatchUpdate = () => {
    if (isProcessing || onUpdate == null || context == null || file == null || !isOriginalLoaded) {
      return;
    }

    const lastImage = renders[renders.length - 1] ?? original;
    const cv = document.createElement('canvas');
    cv.width = context?.canvas.width || MAXIMUM_IMAGE_WIDTH;
    cv.height = context?.canvas.height || MAXIMUM_IMAGE_HEIGHT;
    const ctx = cv.getContext('2d');

    if (ctx == null) return;

    ctx.drawImage(lastImage, 0, 0, cv.width, cv.height);
    if (logo) {
      logo.draw(ctx, logoDragContext);
    }

    onUpdate(cv);
  }

  // Update parent on Logo change
  useEffect(dispatchUpdate, [renders, logoImage, logo?.handles]);

  const toggleShowBrush = (newState: boolean) => {
    if (newState !== showBrush && logoDragContext == null) {
      setShowBrush(newState);
    }
  };

  const getCursor = useCallback(() => {
    if (logoDragContext != null) {
      return 'grab';
    }
    if (showBrush && brushActive) {
      return 'none';
    }
    return undefined;
  }, [showBrush, brushActive, logoDragContext]);

  const getBrushStyle = (left?: number, top?: number) => {
    if (left == null && top == null) {
      return {
        width: `${brushSize}px`,
        height: `${brushSize}px`,
      };
    }

    return {
      width: `${brushSize}px`,
      height: `${brushSize}px`,   
      left: `${left}px`,
      top: `${top}px`,
      transform: 'translate(-50%, -50%)',
    }
  };

  const renderCanvas = () => {
    return (
      <div className="editor-canvas-wrapper">
        <div className={`editor-canvas-container ${isProcessing ? 'editor-canvas-loading' : ''}`} style={{ position: 'relative' }}>
          <canvas
            className="editor-canvas"
            style={{ cursor: getCursor() }}
            onContextMenu={e => {
              e.preventDefault();
            }}
            onMouseOver={() => setHoveringCanvas(true)}
            onFocus={() => setHoveringCanvas(true)}
            onMouseLeave={() => setHoveringCanvas(false)}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseDrag}
            ref={r => {
              if (r && !context) {
                const ctx = r.getContext('2d');
                if (ctx) {
                  setContext(ctx);
                }
              }
            }}
          />
          {logo !== null && logoActive && logo.renderControls(context, onLogoHandleMouseDown, logoDragContext)}
          <div
            className="original-image-container"
            style={{
              width: `${imageWidth}px`,
              height: `${imageHeight}px`,
            }}
          >
          </div>
        </div>
      </div>
    );
  };

  const onLogoHandleMouseDown = useCallback((index) => (ev: SyntheticEvent) => {
    if (logo == null || !logoActive) return;

    const canvasBounds = context?.canvas.getBoundingClientRect();

    if (canvasBounds == null) {
      throw new Error('canvas has invalid size');
    }

    const handleBounds = (ev.target as HTMLDivElement).getBoundingClientRect();
    const mousePosition = mouseXY(ev);
    const adjustedPosition = {
      x: mousePosition.x + handleBounds.x - canvasBounds.x,
      y: mousePosition.y + handleBounds.y - canvasBounds.y
    };
    setLogoDragContext(logo.getDragContext(adjustedPosition, index));
  }, [context, logo]);

  const onToolkitPanelChange = (activePanel: ActivePanelState) => {
    setLogoActive(false);
    setBrushActive(false);

    switch (activePanel) {
      case 'erase':
        setBrushActive(true);
        break;
      case 'logo':
        setLogoActive(true);
        break;
    }
  }

  return (
    <div
      className="editor-container"
      aria-hidden="true"
      onMouseMove={onMouseMove}
      onMouseUp={onPointerUp}
    >
      {renderCanvas()}

      {showBrush && brushActive && !isInpainting && (
        <div
          className="brush-shape"
          style={getBrushStyle(x, y)}
        />
      )}

      {showRefBrush && (
        <div
          className="brush-shape"
          style={getBrushStyle()}
        />
      )}

      <ImageEditorToolkit
        onActivePanelChange={onToolkitPanelChange}

        // AI Erase
        brushSize={brushSize}
        setBrushSize={setBrushSize}
        showRefBrush={showRefBrush}
        setShowRefBrush={setShowRefBrush}
        onErase={inpaint}
        eraseDisabled={disabled || isProcessing || !hadDrawSomething()}

        // Logo
        hasLogo={logo != null}
        onAddLogo={onAddLogo}
        onRemoveLogo={onRemoveLogo}
        logoControlDisabled={disabled}
        logoScale={logoScale}
        setLogoScale={setLogoScale}
        logoRotation={logoRotation}
        setLogoRotation={setLogoRotation}

        // BG
        onRemoveBackground={removeBackground}
        removeBackgroundDisabled={disabled}
      />
    </div>
  );
};
