import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import { get } from 'lodash';
import { ShowPopup } from "@commonsku/styles";

import Select from './Select';

import { download, getImageSrc, parseDate, formatShortDate } from '../utils';
import { getItemShipping } from '../selectors';
import { getSupplierAccountOptions } from '../selectors/dropdowns';
import { createLoadOrder } from '../actions/order';

import ProductItemTooltip from './ProductItemTooltip';
import Img from './Img';
import PS from './PromostandardsLabel';
import EditShippingPopup from "./EditShippingPopup";
import { AutoSelectWarehousePopup } from './SelectWarehouse';

class ItemShippingDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show_item_tooltip: false,
      item_tooltip_position: {top: 0, left: 0},
    };

    this.handleMouseOverItem = this.handleMouseOverItem.bind(this);
    this.handleMouseOutItem = this.handleMouseOutItem.bind(this);
  }

  componentDidMount() {
    this.setState(Object.assign({}, this.state, this.getTooltipPosition()));
  }

  getTooltipPosition() {
    const row_position = this.row.getBoundingClientRect();
    return {
      item_tooltip_position: { top: row_position.bottom + window.scrollY + 15, left: row_position.left }
    };
  }

  handleMouseOverItem(e) {
    this.setState(Object.assign({}, this.state, this.getTooltipPosition(), { show_item_tooltip: true }));
  }

  handleMouseOutItem(e) {
    this.setState({ show_item_tooltip: false });
  }

  render() {
    const { item, onChangeSelected, checked, selectable, project } = this.props;
    const item_tooltip = this.state.show_item_tooltip ?
      <ProductItemTooltip item={item} position={this.state.item_tooltip_position} /> :
      null;
    const default_image = item.item_images.map(ii => ii.image)[0];

    return (
      <tr ref={ref => this.row = ref}>
        <td>{item_tooltip}<input type="checkbox" checked={checked} onChange={onChangeSelected} disabled={!checked && !selectable} /></td>
        <td><Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOverItem} onMouseOut={this.handleMouseOutItem} /></td>
        <td>
          {'ps-products' === item.copied_from && <PS />}<a>{item.item_name}</a><br /><br />
          <ShowPopup popup={EditShippingPopup} account_id={project.account_id} items={[item]} render={({ onClick }) => {
            return <a className="button small" onClick={onClick}><i className="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Edit</a>;
          }}/>
        </td>
        <td>{item.vendor_details !== undefined ? <ShippingDetail details={item.vendor_details} /> : <span>&nbsp;</span>}</td>
        <td>{item.decorator_details !== undefined ? <ShippingDetail details={item.decorator_details} /> : <span>&nbsp;</span>}</td>
      </tr>
    );
  }
}

const getFormatShipdate = (shippingDetails) => {
  const { shipdate_option, date_inhandsdate, date_shipdate } = shippingDetails || {};
  let date = date_shipdate;
  if (shipdate_option === 'IN-HANDS-DATE') {
    date = date_inhandsdate;
  }
  return date ? formatShortDate(parseDate(date) / 1000) : '';
};

const ShippingDetail = ({details}) => {
  const origin = get(details, 'origin', {});
  const quirk = useSelector((state) => get(state, ['entities', 'promostandards_quirks', origin.division_id, 'HAS-RUSH']));
  let checkbox = 'Rush';
  try {
    checkbox = get(JSON.parse(quirk), 'checkbox');
  } catch (e) {
  }
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <div className="row collapse">
              <div className="medium-12 large-4 columns"><strong>Name</strong></div>
              <div className="medium-12 large-8 columns">{origin.company_name || origin.division_name}</div>
            </div>
            {details.date_shipdate ?
            <div className="row collapse">
              <div className="medium-12 large-4 columns">
                <strong>{(details || {}).shipdate_option === 'IN-HANDS-DATE'
                    ? 'In Hands Date' : 'Ship Date'
                }</strong>
              </div>
              <div className="medium-12 large-8 columns">{getFormatShipdate(details)}</div>
            </div> : null}
            {(!details.firm || +details.firm === 0) ? null : <div className="row collapse">
              <div className="medium-12 large-4 columns"><strong>Firm</strong></div>
              <div className="medium-12 large-8 columns">Yes</div>
            </div>}
            {1 == details.is_rush && <div className="row collapse">
              <div className="medium-12 large-4 columns"><strong>{checkbox}</strong></div>
              <div className="medium-12 large-8 columns">Yes</div>
            </div>}
            {details.destination ?
            <div className="row collapse">
              <div className="medium-12 large-4 columns">{!!details.split_shipping ? <strong>Split Shipping</strong> : <strong>Ship To</strong>}</div>
              {!!details.split_shipping ?
              <div className="medium-12 large-8 columns"><a target="_blank" onClick={e => { e.preventDefault(); download(getImageSrc(details.split_shipping, 'original'), details.split_shipping.file_display_name); }}>{details.split_shipping.file_display_name}</a></div> :
              <div className="medium-12 large-8 columns">{details.destination.destination_name}</div>}
            </div> : null}
            {details.address ?
            <div className="row collapse">
              <div className="medium-12 large-4 columns"><strong>Address</strong></div>
              <div className="medium-12 large-8 columns">{details.address.address_name}<br />{details.address.address_line_1}<br />{details.address.address_city}, {details.address.address_state} {details.address.address_postal}</div>
            </div> : null}
            {details.ship_method_name ?
            <div className="row collapse">
              <div className="medium-12 large-4 columns"><strong>Method</strong></div>
              <div className="medium-12 large-8 columns">{details.ship_method_name}</div>
            </div> : null}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

class ProjectShipping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      filtered_supplier_id: 'show_all'
    };
    this.onChangeSelected = this.onChangeSelected.bind(this);
    this.onChangeSupplierFilter = this.onChangeSupplierFilter.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
  }

  componentDidMount() {
    const { order, loadOrder } = this.props;
    if (!order.loaded) {
      loadOrder(order.order_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { order, loadOrder, items } = this.props;
    if (!order.loaded && (prevProps.order.loaded || loadOrder !== prevProps.loadOrder)) {
      loadOrder(order.order_id);
    }
  }

  onChangeSelected(item_id) {
    if (this.state.selected.includes(item_id)) {
      this.setState({ selected: this.state.selected.filter(i => i !== item_id)});
    } else {
      this.setState({ selected: this.state.selected.concat([item_id])});
    }
  }

  onChangeSupplierFilter(supplier_id) {
    this.setState({ filtered_supplier_id: supplier_id, selected: []});
  }

  handleCheckAll(e) {
    if (e.target.checked) {
      this.setState({ selected: ('show_all' === this.state.filtered_supplier_id ? this.props.items : this.props.items.filter(i => i.vendor_details.source_parent_id === this.state.filtered_supplier_id)).map(i => i.item_id) });
    } else {
      this.setState({ selected: [] });
    }
  }

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}}>
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  render() {
    const { order } = this.props;

    if (!order.loaded) {
      return this.renderLoading();
    }
    /*
     * If there are inventory items and not all the items have the same warehouse, then remove select all
     * If an inventory item is selected, the only other items that can be selected are inventory items with the same warehouse
     */

    const { project, items, suppliers, onEditShipping } = this.props;
    const { filtered_supplier_id, selected } = this.state;
    const item_supplier_ids = items.map(i => i.vendor_details.source_parent_id);

    const filtered_suppliers = [{ key: 'show_all', value: 'Suppliers' }].concat(suppliers.filter(s => item_supplier_ids.includes(s.key)));
    const filtered_items = 'show_all' === filtered_supplier_id ? items : items.filter(i => i.vendor_details.source_parent_id === filtered_supplier_id);
    const hasInvItems = filtered_items.reduce((t, i) => t || !!i.warehouse_id, false);
    const hasMultipleSuppliers = (new Set(filtered_items.map(i => i.vendor_details.source_parent_id))).size > 1;
    const hasMultipleWarehouses = (new Set(filtered_items.map(i => i.warehouse_id))).size > 1;
    const selectedItems = items.filter(i => selected.includes(i.item_id));
    const selectedWarehouseId = Array.from(new Set(selectedItems.map(i => i.warehouse_id)))[0];
    const selectedSuppliers = new Set(selectedItems.map(i => i.vendor_details.source_parent_id));

    return (
      <div>
        <div className="main-content">

            <div className="row">
              <div className="small-12 large-6 columns">
                <Select value={this.state.filtered_supplier_id} options={filtered_suppliers} change={this.onChangeSupplierFilter} withMarginBottom />
              </div>
              <div className="small-12 large-6 columns">
                {this.state.selected.length > 0 && <ShowPopup
                  popup={EditShippingPopup} account_id={project.account_id}
                  items={items.filter(i => this.state.selected.includes(i.item_id))}
                  render={({ onClick }) => {
                    return <a className="button small" style={{margin: 0}} onClick={onClick}>Edit Selected</a>;
                  }}
                />}
              </div>
            </div>

          <div className="small-12 columns">
            <table className="shipping-table">
              <thead>
                <tr>
                  <th>
                    {!(hasInvItems && hasMultipleWarehouses) && <input type="checkbox" name="check-all" checked={this.state.selected.length === filtered_items.length && filtered_items.length > 0} onChange={this.handleCheckAll} />}
                  </th>
                  <th></th>
                  <th>Product</th>
                  <th>Supplier Shipping</th>
                  <th>Decorator Shipping</th>
                </tr>
              </thead>
              <tbody>
                {filtered_items.map(i => {
                  const selectable = (!hasMultipleWarehouses || i.warehouse_id === selectedWarehouseId) || selected.length === 0;
                  return (
                    <ItemShippingDetail
                      key={i.item_id}
                      item={i}
                      project={project}
                      onChangeSelected={() => this.onChangeSelected(i.item_id)}
                      checked={selected.includes(i.item_id)}
                      selectable={selectable}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <AutoSelectWarehousePopup items={items}/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = Object.assign({ loaded: false }, ownProps.project.sales_orders[0]);
  const items = order.loaded ? getItemShipping(state) : null;
  return {
    order,
    items,
    suppliers: getSupplierAccountOptions(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectShipping);
