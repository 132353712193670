import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import DropdownMenu, {MenuTrigger} from '../components/DropdownMenu';
import { createSupportPopup } from '../actions/popup';
import { getIdentityUtils, oauth } from '../utils';

import { handleOpenSupportChat } from '../utils';

const ProfileDropdownMenu = ({
  identity, onSupportClick
}) => {
  const { hasCapabilities } = getIdentityUtils(identity);
  const isActive = hasCapabilities([
    'FEATURE-ADMIN', 'FEATURE-LIMITED', 'FEATURE-FULL', 'FEATURE-SOCIAL',
    'FEATURE-COLLABORATE', 'FEATURE-INSIGHTS', 'FEATURE-CONNECTED',
    'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS',
    'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS', 'FEATURE-ESSENTIAL',
    'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
  ], true);

  const help_route = identity.company_type === 'SUPPLIER' ?
    'https://commonsku.freshdesk.com/' :
    'https://help.commonsku.com/knowledge';

  const isNewNav = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');

  const options_profile  = _.map(
    _.filter([{
      key: 'your-profile', value: 'Profile',
      route: `/user.php?id=${identity.user_id}`,
      show: isActive,
    }, {
      key: 'security-two-factor', value: 'Password & Security', route: '/security-two-factor.php',
      show: isActive,
    }, {
      key: 'admin-settings-new', value: 'Settings', route: '/admin_settings_new.php',
      show: identity.company_type === 'TENANT',
    }, {
      key: 'colleagues', value: 'Colleagues', route: '/company.php',
      show: isActive && !isNewNav,
    }, {
      key: 'company', value: 'Company', show: isActive && !isNewNav,
      route:
        '/' + (identity.company_type === 'TENANT' ? 'distributor' : 'vendor') +
        '.php?id=' + identity.company_id
    }, {
      key: 'support', value: 'Support', route: handleOpenSupportChat, show: isActive && !isNewNav
    }, {
      key: 'help-articles',
      show: isActive && !isNewNav,
      value: <a href={help_route} target="_blank" rel="noreferrer"
        style={{
          margin: '-0.5rem',
          padding: '0.5rem',
        }}
      >Help Articles</a>
    }, {
      key: 'commonsku-university',
      show: isActive && identity.company_type === 'TENANT' && !isNewNav,
      value: <a href="/university.php" target="_blank"
        style={{
          margin: '-0.5rem',
          padding: '0.5rem',
        }}
      >Commonsku University</a>,
    }, {
      key: 'old-dashboard',
      show: isActive && identity.company_type === 'TENANT' && isNewNav,
      value: <a href="/dashboard.php" target="_blank"
        style={{
          margin: '-0.5rem',
          padding: '0.5rem',
        }}
      >Old Dashboard</a>,
    }, {
      key: 'logout', value: 'Logout', route: '/logout.php',
      show: true,
    }], (item) => {
      return item.show;
    }),
    (profile) => {
      const { route, onClick, ...other } = profile;
      return {
        ...other,
        onClick: onClick ?? (
          !route ? f => f :
          (_.isFunction(route) ? route :
          () => {window.location = route;})
        ),
      };
    }
  );
  let avatar = identity?.user_image_paths?.small || '/images/favicons/favicon-96x96-company-avatar.png';
  if (!_.startsWith(avatar, '/') && !_.startsWith(avatar, 'http')) {
    avatar = '/' + avatar;
  }

  return <DropdownMenu options={options_profile} align="right" className="profile-menu">
    <MenuTrigger>
      <div className="show-for-large">
        <img src={avatar} alt="Profile Menu" style={{width: '39px'}}/>
      </div>
      <i className="fi-torso hide-for-large"></i>
    </MenuTrigger>
  </DropdownMenu>;
};

const mapStateToProps = (state) => {
  return {
    identity: state.identity || {},
  };
};

const mapDispatchToProps = dispatch => ({
  onSupportClick: function() {
    dispatch(createSupportPopup());
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(ProfileDropdownMenu);
