import React from 'react';
import { connect } from 'react-redux';

import { getFileURL } from '../state/getters';
import { createHeaderPopup } from '../actions/popup';
import Img from './Img';

let unique_id = 0;
const id_prefix = 'LabelledBanner';

class LabelledBanner extends React.Component {
  UNSAFE_componentWillMount() {
    ++unique_id;
    this.setState({label_id: id_prefix + unique_id});
  }

  render() {
    const { onReplaceHeader } = this.props;
    return (
      <div className={this.props.className} onClick={e => {e.preventDefault(); onReplaceHeader();}}>
        <a className="button tiny replace-header"><i className="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Replace Header</a>
        <label>
          {this.props.label}
        </label>
        <br />
        {this.props.value ? <Img id={this.state.label_id} src={this.props.banner_url} /> : 'No Header' }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    banner_url: ownProps.value ? getFileURL(state, ownProps.value, 'headers') : ''
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onReplaceHeader: () => {
      dispatch(createHeaderPopup(ownProps.job_id, ownProps.value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelledBanner);

