import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@commonsku/styles';

import { find, get, map, orderBy } from 'lodash';

const AvalaraCategorySelect = ({ value, onChange, ...props }) => {
  const avalara_categories = useSelector((state) => {
    return get(state, 'entities.avalara_categories');
  });

  const options = map(orderBy(avalara_categories, 'category'), ({ avalara_category_id, category }) => {
    return {value: avalara_category_id, label: category};
  });

  return <Select
    value={find(options, { value })}
    options={options}
    onChange={(option) => {
      return onChange(option.value);
    }}
    {...props}
  />;
};

export default AvalaraCategorySelect;