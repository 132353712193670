import React from 'react';
import { LabeledSelect, Select } from '../../helpers';

const ContactSelect = React.memo(({
    value,
    onChange,
    options,
    isDisabled = false,
    isBilling = false,
    isShipping = false,
    label = null,
    placeholder = 'Select Contact',
    name = 'select_project_contact',
    components = {},
}) => {
    const SelectField = React.useMemo(() => {
        if (label) {
            return LabeledSelect;
        }
        return Select;
    }, [label]);

    return (
        <SelectField
            inPopup
            isClearable
            label={label}
            placeholder={placeholder}
            name={name}
            options={options}
            value={value}
            onChange={e => onChange(e ? e.value : '')}
            isContact
            isBilling={isBilling}
            isShipping={isShipping}
            isDisabled={isDisabled}
            components={components}
        />
    );
});

export default ContactSelect;
