import * as actions from '../actions/shipping';
import { REGENERATE_PURCHASE_ORDER_SUCCESS } from '../actions/purchase_order';

const reduce = (state = {}, action) => {
  switch (action.type) {
    case REGENERATE_PURCHASE_ORDER_SUCCESS:
      if (!action.payload.shipping_id) {
        return state;
      }
      return Object.values(state).filter(
        s => action.payload.shipping_id[s.shipping_id] !== 'delete'
      ).reduce((o, s) => {
        o[s.shipping_id] = s;
        return o;
      }, {});
    case actions.UPDATE_SHIPPING_SUCCESS:
      return Object.assign({}, state, action.payload.shipping);
  }
  return state;
};

export default reduce;
