export const ADD_TEMP = 'ADD_TEMP';
export const DELETE_TEMP = 'DELETE_TEMP';
export const CLEAR_TEMP = 'CLEAR_TEMP';
export const ADD_MULTI_TEMP = 'ADD_MULTI_TEMP';
export const DELETE_MULTI_TEMP = 'DELETE_MULTI_TEMP';

export const createAddTemp = (key, value) => ({
  type: ADD_TEMP,
  payload: {
    key,
    value
  }
});

export const createDeleteTemp = key => ({
  type: DELETE_TEMP,
  payload: {
    key
  }
});

export const createClearTemp = key => ({
  type: CLEAR_TEMP
});

export const createAddManyTemp = keyvalues => ({
  type: ADD_MULTI_TEMP,
  payload: {
    keyvalues
  }
});

export const createDeleteManyTemp = keys => ({
  type: DELETE_MULTI_TEMP,
  payload: {
    keys
  }
});
