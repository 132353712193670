import React, { useRef, useMemo } from 'react';
import ViewportList from 'react-viewport-list';
import Post from './Post';
import { sortMessages } from '../utils';

const Feed = (props) => {
  const {
    posts, job_id, comment, comment_allowed, show_add_comment = false
  } = props;
  const items = useMemo(() => posts.sort(sortMessages), [posts]);
  const ref = useRef(null);

  return (
    <ul className={['feed', (comment ? 'comments' : '')].join(' ')} ref={ref}>
      <ViewportList viewportRef={ref} items={items} itemMinSize={40} margin={8}>
        {(item, idx) => (
          <Post
            key={idx}
            post={item}
            job_id={job_id}
            comment_allowed={comment_allowed}
            comment={comment}
            show_add_comment={show_add_comment && idx === (items.length - 1)}
          />
        )}
      </ViewportList>
    </ul>
  );
};

export default Feed;
