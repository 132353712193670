import React from 'react';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class BookmarkSearchFilter extends ProductSearchFilter {

  loadDivisions() {
    this.props.loadDivisions('bookmark');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: '',
        net_min: '',
        net_max: ''
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.bookmark_division_id || '',
        net_min: this.props.user_search.net_min || '',
        net_max: this.props.user_search.net_max || ''
      };
    }
  }

  getEndpoint() {
    return 'bookmarked-product';
  }

  getSearchTerms(page) {
    let search_terms = {
      keyword_sku: this.state.search.search_term,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.division_id) {
      search_terms.division_id = this.state.search.division_id;
    }
    if (this.state.search.net_min) {
      search_terms.net_min = this.state.search.net_min;
    }
    if (this.state.search.net_max) {
      search_terms.net_max = this.state.search.net_max;
    }
    return search_terms;
  }

  render() {
    const { company_type, divisions, style } = this.props;
    const { search } = this.state;

    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-3 columns">
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </div>
          <div className="medium-3 columns">
            {divisions &&
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={'SUPPLIER' !== this.props.company_type}
                options={divisions}
                change={e => this.onChangeDivision(e, 'bookmark_division_id')}
                placeholder="Supplier"
              />}
          </div>
          <div className="medium-3 columns">
            <div className="row">
              <div className="medium-5 columns">
                <input
                  type="text"
                  placeholder="Net min"
                  onChange={this.handleChangeNetMin}
                  value={search.net_min}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
              <div className="medium-2 columns">
                <center><span>&mdash;</span></center>
              </div>
              <div className="medium-5 columns">
                <input
                  type="text"
                  placeholder="Net max"
                  onChange={this.handleChangeNetMax}
                  value={search.net_max}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
            </div>
          </div>

          <div className="medium-3 columns">
            <div className="button-group expanded">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('bookmark')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default BookmarkSearchFilter;
