import React from 'react';
import _, { find, findIndex } from 'lodash';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import PSCategorySelect from '../PSCategorySelect';
import { Col, IconButton, Row } from '@commonsku/styles';
import { makeCancelable, memoizeOAuthGet } from '../../utils';
import styled from 'styled-components';
import config from '../../config';
import ProductChevronPopup from '../product/ProductChevronPopup';

const CurrencyIconButton = styled.button`
  && {
  width: 38px;
  height: 38px;
  border-radius: 20px;
  padding: 5px;
  line-height: 1.5rem;
  cursor: pointer;
  background: #E1F7FA;
  mix-blend-mode: normal;

  &.selected {
    border: 3px solid #00A0B6;
    background: #FFFFFF;

  }
}`;

class PromostandardsSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showProductPopup: false,
      selectedProduct: null
    };

    [
      'onChangeSortOption',
      'onClickSearch',
      'onClose',
      'onClickProduct',
    ].forEach(f => this[f] = this[f].bind(this));
  }

  getProductSource() {
    return 'ps-products';
  }

  loadDivisions() {
    this.props.loadDivisions('promostandards');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: '',
        currency: '',
        ps_sort: '',
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.promostandards_division_id || '',
        currency: '',
        ps_sort: '',
      };
    }
  }

  getEndpoint() {
    return 'promostandards-product';
  }

  getSearchTerms(page) {
    const { divisions } = this.props;
    const { search } = this.state;
    const division_id = this.state.division_id || search.division_id || (this.props.company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    const search_terms = {
      ...search,
      keyword: search.search_term,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (division_id) {
      search_terms.division_id = division_id;
    }
    if (this.state.search.ps_sort) {
      search_terms.ps_sort = this.state.search.ps_sort;
    }
    return search_terms;
  }

  onChangeCurrency(currency) {
    this.setState({ search: { ...this.state.search, currency } });
  }

  onChangeDivision(division_id, params) {
    const division = _.find(this.props.divisions, { division_id });
    this.setState({
      search: {
        ...this.state.search,
        division_id,
        currency: _.get(division, 'default_currency_id', ''),
      }
    });
    this.props.handleStoringSearch(params, division_id);
  }

  onChangeSortOption(ps_sort) {
    if (this.state.previous_ps_sort && this.state.previous_ps_sort != ps_sort) {
      this.setState({ search: { ...this.state.search, ps_sort } });
    } else {
      this.setState({ search: { ...this.state.search, ps_sort } });
    }
  }

  onResponseResult(response) {
    const dimensions = response.suppliers ? response.suppliers : null;
    const divisions = this.props.divisions;
    const divisions_map = divisions.reduce((acc, { ext_division_id, key }) => ({ ...acc, [ext_division_id]: key }), {})
    const suppliers_products_count = dimensions && dimensions.companies ?
      Object.values(dimensions.companies).map(d => ({ key: divisions_map[d.id], value: `${d.name} (${d.products_count})` })).filter(e => e.key) :
      divisions;
    this.setState({
      dimensions,
      suppliers_products_count,
    });
  }

  onClickSearch(e) {
    this.handleClickSearch(e);
    const search_terms = this.getSearchTerms(0);
    const search = makeCancelable(memoizeOAuthGet(this.getEndpoint() + '/stats', search_terms));
    this.active_searches.push(search);
    search.promise.then(
      ({ json }) => {
        this.onResponseResult(json);
      },
      ({ json }) => {
        this.onErrorResponse(json);
      }
    );
  }

  onClickProduct(product) {
    this.setState({
      showProductPopup: true,
      selectedProduct: product
    })
  }

  onClose() {
    this.setState({
      showProductPopup: false,
      selectedProduct: null
    })
  }


  render() {
    const { company_type, divisions, style, isProductsPage, isCollectionPage, index, order_id, order_type, onAddProduct } = this.props;
    const { search, suppliers_products_count, showProductPopup, products } = this.state;
    const { ps_sort_options } = config;
    const supplier_dimensions_divisions = suppliers_products_count ? suppliers_products_count : null;
    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    const division = _.find(divisions, { division_id });

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <Col xs={2} padded>
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </Col>
          <Col xs={2} padded>
            {divisions &&
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={'SUPPLIER' !== company_type}
                options={supplier_dimensions_divisions || divisions}
                change={(division_id) => {
                  this.onChangeDivision(division_id, 'promostandards_division_id');
                }}
                placeholder="Supplier"
              />}
          </Col>
          <Col xs={2} lg={1} padded>
            <input
              type="text"
              placeholder="Min"
              onChange={(e) => {
                this.setState({ search: { ...this.state.search, price_min: e.target.value } });
              }}
              onKeyDown={this.handlePressEnter}
            />
          </Col>
          <Col xs={2} lg={1} padded>
            <input
              type="text"
              placeholder="Max"
              onChange={(e) => {
                this.setState({ search: { ...this.state.search, price_max: e.target.value } });
              }}
              onKeyDown={this.handlePressEnter}
            />
          </Col>
          <Col xs={1} padded flex style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', minWidth: 100 }}>
            <CurrencyIconButton className={this.state.search.currency == 'USD' && 'selected'} style={{ marginRight: 5 }} onClick={() => this.onChangeCurrency('USD')}>
              <div style={{ opacity: this.state.search.currency == 'USD' ? 1 : 0.4 }} >🇺🇸</div>
            </CurrencyIconButton>
            <CurrencyIconButton className={this.state.search.currency == 'CAD' && 'selected'} onClick={() => this.onChangeCurrency('CAD')}>
              <div style={{ opacity: this.state.search.currency == 'CAD' ? 1 : 0.4 }}>🇨🇦</div>
            </CurrencyIconButton>
          </Col>
          {/* {
            !isProductsPage &&
            <Col xs={2} lg={division ? 1 : 2} padded>
              <Select
                id="sort"
                value={search.ps_sort}
                searchable={true}
                clearable={true}
                options={ps_sort_options}
                change={this.onChangeSortOption}
                placeholder="Sort"
              />
            </Col>
          } */}
          {division &&
            <Col xs={2} padded>
              <PSCategorySelect
                promostandards_id={division.ext_division_id}
                change={(category) => {
                  this.setState({ search: { ...this.state.search, category } });
                  this.props.handleStoringSearch('category', category);
                }}
              />
            </Col>
          }
          <Col xs={1} padded flex style={{ flexDirection: 'row', minWidth: 210 }}>
            <IconButton onClick={this.handleClickReset} variant="secondary" mr={18} >Clear</IconButton>
            <IconButton onClick={this.onClickSearch} >Search</IconButton>
          </Col>
        </Row>
        {/* <div className="medium-4 columns">
        <input
        type="text"
        placeholder="Search term"
        onChange={this.handleChangeSearchTerm}
        value={search.search_term}
        onKeyPress={this.handlePressEnter}
        />
        </div>
        <div className="medium-4 columns">
        {divisions &&
        <Select
        id="division"
        value={division_id}
        searchable={true}
        clearable={'SUPPLIER' !== company_type}
        options={divisions}
        change={(division_id) => {
        this.onChangeDivision(division_id, 'promostandards_division_id');
        }}
        placeholder="Supplier"
        />}
        </div>

        <div className="medium-4 columns">
        <div className="button-group expanded">
        <a onClick={this.handleClickReset}><IconButton variant="secondary" mr={18} >Clear</IconButton></a>
        <a onClick={this.handleClickSearch}><IconButton >Search</IconButton></a>
        </div>
        </div>

        <div className="medium-2 columns">
        <input
        type="text"
        placeholder="Min Price"
        onChange={(e) => {
        this.setState({ search: { ...this.state.search, price_min: e.target.value } });
        }}
        onKeyPress={this.handlePressEnter}
        />
        </div>
        <div className="medium-2 columns" style={{ float: 'left' }}>
        <input
        type="text"
        placeholder="Max Price"
        onChange={(e) => {
        this.setState({ search: { ...this.state.search, price_max: e.target.value } });
        }}
        onKeyPress={this.handlePressEnter}
        />
        </div>

        <div className="medium-4 columns" style={{ float: 'left' }}>

        <Select
        id="currency"
        value={this.state.search.currency}
        clearable={true}
        options={['USD', 'CAD'].map((currency) => {
        return { value: currency, key: currency };
        })}
        change={(value) => this.onChangeCurrency(value)}
        placeholder="Currency"
        />
        </div>
        {division && <div className="medium-4 columns" style={{ float: 'left' }}>
            <PSCategorySelect
              promostandards_id={division.ext_division_id}
              change={(category) => {
                this.setState({ search: { ...this.state.search, category } });
                this.props.handleStoringSearch('category', category);
              }}
            />
          </div>}
        */}
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll} >
          {this.renderProducts('promostandards')}
          {this.renderLoading()}
        </Row>
        {showProductPopup && <ProductChevronPopup
          products={products}
          productIndex={findIndex(products, this.state.selectedProduct)}
          onClose={this.onClose} handleLoadNextPage={this.handleLoadNextPage}
          onClickProductAction={() => this.handleClickProductAction(this.state.selectedProduct, onAddProduct(order_id, order_type, this.getProductSource(), null, index, this.isProductSageConnect()))}
          isSelected={(product) => {
            return !!find(this.state.addedProducts, { productId: product.product_id });
          }}
          isProductsPage={isProductsPage}
          isCollectionPage={isCollectionPage}
          isPS={true}
        />}
      </>
    );
  }
}

export default PromostandardsSearchFilter;

