import type { CSSProperties, FC } from 'react'
import React, { memo } from 'react'
import { useDrop } from 'react-dnd'
import { colors } from '@commonsku/styles'

const style: CSSProperties = {
    padding: '0',
    margin: '0',
}

export interface DroppableFolderProps {
    accept: string[]
    lastDroppedItem?: any
    onDrop: (item: any) => void
    children: any
}

export const DroppableFolder: FC<DroppableFolderProps> = memo(function DroppableFolder({
    accept,
    lastDroppedItem,
    onDrop,
    children
}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const isActive = isOver && canDrop
    let backgroundColor = 'transparent';
    if (isActive) {
        backgroundColor = colors.teal[60];
    } else if (canDrop) {
        backgroundColor = colors.teal[50];
    }

    return (
        <div ref={drop} style={{ ...style, backgroundColor }} data-testid="DroppableFolder">
            {children}
        </div>
    )
})
