import React from "react";
import { Button, Text, } from "@commonsku/styles";

export default function FeaturedProductsBlock(props) {
  const {
    value,
    onClick,
  } = props;

  return (
    <>
      <Text as="p" style={{
        color: 'var(--color-neutrals-90)',
        fontFamily: 'var(--font-family-bold)',
        fontSize: 16,
        marginBottom: 0,
      }}>Featured Products</Text>
      <Button
        variant="primary"
        size="medium"
        style={{ width: '100%' }}
        onClick={onClick}
      >Add Featured Products</Button>
    </>
  );
}
