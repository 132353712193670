import { toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IconButton, colors } from '@commonsku/styles';

import Tooltip from './helpers/ReactTooltip';
import styled from 'styled-components';
import { formatMoney } from '../utils';
import { window } from '../global';

const StyledTooltip = styled(Tooltip)`
  &&.update-indicator {
    display: flex;
    padding: 13px 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    border-radius: 5px;
    font-family: "skufont-regular", sans-serif;
  }
`;

const UpdateTooltip = ({ id, showTooltip, userCost, supplierCost, onUpdate, onIgnore, children }) => {
  return <>
    <StyledTooltip className='update-indicator' id={id} place="bottom" effect='solid'
      delayHide={500}
      delayUpdate={500}
    >
      <div style={{ justifyContent: 'space-between', width: '100%', gap: 10, display: 'inline-flex' }}>
        <div style={{ color: 'white', fontSize: 16, fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word' }}>Your cost:</div>
        <div style={{ color: 'white', fontSize: 16, fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word' }}>${formatMoney(userCost)}</div>
      </div>

      <div style={{ justifyContent: 'space-between', width: '100%', gap: 10, display: 'inline-flex' }}>
        <div style={{ color: 'white', fontSize: 16, fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word' }}>Cost from supplier:</div>
        <div style={{ color: 'white', fontSize: 16, fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word' }}>${formatMoney(supplierCost)}</div>
      </div>
      <div style={{ justifyContent: 'flex-end', alignItems: 'flex-end', gap: 10, display: 'inline-flex' }}>
        <IconButton variant="secondary" size="tiny" onClick={onIgnore}>Ignore</IconButton>
        <IconButton size="tiny" onClick={onUpdate}>Update</IconButton>
      </div>
    </StyledTooltip>
    <span
      data-for={showTooltip ? id : null}
      data-tip=""
      style={{
        position: 'absolute',
        top: '1.25rem',
        left: '2rem'
      }}>
      {children}
    </span>
  </>;
};


export default function UpdateIndicator({ breakdownId, userCost, supplierCost, onUpdate, onIgnore, children, inputRef }) {
  const inputSize = 150;
  const [displayMessage, setDisplayMessage] = useState(true)
  useEffect(() => {
    const getWidth = () => inputRef.current.offsetWidth

    const handleResize = () => {
      setDisplayMessage(Math.floor(getWidth()) > inputSize)
    }

    if (inputRef.current) {
      setDisplayMessage(Math.floor(getWidth()) > inputSize)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [inputRef])
  return (
    toNumber(supplierCost) && toNumber(supplierCost) !== toNumber(userCost) ?
      <UpdateTooltip id={`update-tooltip-${breakdownId}`} showTooltip={true} userCost={userCost} supplierCost={supplierCost} onUpdate={onUpdate} onIgnore={onIgnore}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}>
          <div style={{ display: 'flex' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 2.66667V0.666672L5.33333 3.33334L8 6V4C10.2067 4 12 5.79334 12 8C12 8.67334 11.8333 9.31334 11.5333 9.86667L12.5067 10.84C13.0267 10.02 13.3333 9.04667 13.3333 8C13.3333 5.05334 10.9467 2.66667 8 2.66667ZM8 12C5.79333 12 4 10.2067 4 8C4 7.32667 4.16667 6.68667 4.46667 6.13334L3.49333 5.16C2.97333 5.98 2.66667 6.95334 2.66667 8C2.66667 10.9467 5.05333 13.3333 8 13.3333V15.3333L10.6667 12.6667L8 10V12Z" fill="#00A0B6" />
            </svg>
          </div>
          {
            displayMessage && <div style={{ lineHeight: '16px', fontSize: 10, fontWeight: 600, color: colors.teal['70'] }}>
              Update
            </div>
          }
        </div>
      </UpdateTooltip>
      :
      <></>

  )
}
