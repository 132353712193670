import React from 'react';
import styled from 'styled-components';

export const FolderContainer = styled.div`
  &&& {
    display: flex;
    vertical-align: middle;
    align-items: top;
    max-width: 100%;
    gap: 10px;
  }
`;

export const NavContainerUl = styled.ul`
  &&& {
    padding: 0;
    margin: 0;
    background-color: rgb(225, 247, 250);
    height: calc(-250px + 100vh);
    list-style: none, 
    margin: 0;
    overflow-Y: auto;
    overflow-X: hidden;
    padding-bottom: 20px;
    position: fixed; 
    width: 250px;
  }
`;