import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as BaseSelect,
  LabeledSelect as BaseLabeledSelect,
  CreatableSelect as BaseCreatableSelect,
  LabeledCreatableSelect as BaseLabeledCreatableSelect,
  AsyncSelect as BaseAsyncSelect,
  LabeledAsyncSelect as BaseLabeledAsyncSelect,
  components, createFilter,
} from '@commonsku/styles';
import { FixedSizeList } from 'react-window';
import { findInOptions, _createEventHandler } from '../../utils';

const commonPropTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired)]),
  onChange: PropTypes.func, // if not given, '_createEventHandler' is used
  isMulti: PropTypes.bool,

  // These will be used if onChange is not passed
  // These will be passed into '_createEventHandler' function
  onUpdate: PropTypes.func, // Required if `onChange` not given
  changeKey: PropTypes.string, // Required if `onChange` not given
  onChangeOptions: PropTypes.func, // Passed into '_createEventHandler' if `onChange` not given
};

const commonLabeledPropTypes = {
  ...commonPropTypes,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const MenuList = (props) => {
  const children = props.children;

  if (!children.length) {
    return (<components.MenuList>{children}</components.MenuList>);
  }

  return (
    <components.MenuList {...props}>
      {children.length && children.map((key, i) => {
        delete key.props.innerProps.onMouseMove; //FIX LAG!!
        delete key.props.innerProps.onMouseOver;  //FIX LAG!!
        return (<div key={"menuList-" + i}>{key}</div>);
      })}
    </components.MenuList>
  );
};

export const WindowedMenuList = (props) => {
  const {
    options,
    children,
    getValue,
    innerRef,
    selectProps,
    height = 50,
    maxHeight,
    paddingBottom = 0,
    paddingTop = 0,
  } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  const {
    classNamePrefix,
    isMulti,
  } = selectProps || {};
  return (
    <FixedSizeList
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
        className={classNamePrefix ? `${classNamePrefix}__menu-list${isMulti ? ` ${classNamePrefix}__menu-list--is-multi`: ''}` : ''}
        outerRef={innerRef}
        innerElementType={React.forwardRef(({ style, ...rest }, ref) => (
          <div
            ref={ref}
            style={{
              ...style,
              height: `${ parseFloat(style.height) + paddingBottom + paddingTop }px`
            }}
            {...rest}
          />
        ))}
    >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
};

function getCustomOptions(options) {
  if (!options) { return options; }
  if (options.length && 'key' in options[0] && 'value' in options[0] && !('label' in options[0])) {
    return options.map(v => ({ value: v.key, label: v.value }));
  } else {
    return options;
  }
}

function getExtraProps({options, ...props}) {
  const extra_props = { ...props };
  // for large lists, use custom menu list to fix lag
  if (options && options.length >= 700) {
    extra_props['components'] = {
      MenuList: WindowedMenuList,
      ..._.get(extra_props, 'components', {}),
    };
    extra_props['filterOption'] = createFilter({ignoreAccents: false});
  }
  return extra_props;
}

/**
 * @type {(
 *   typeof BaseSelect
 * )}
 */
export const Select = React.forwardRef(({ options, value, onChange, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseSelect
      value={findInOptions(_options, value)}
      options={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
Select.propTypes = commonPropTypes;

/**
 * @type {(
 *   typeof BaseLabeledSelect
 * )}
 */
export const LabeledSelect = React.forwardRef(({ options, value, onChange, label, name, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseLabeledSelect
      label={label}
      name={name}
      value={findInOptions(_options, value)}
      options={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
LabeledSelect.propTypes = commonLabeledPropTypes;

/**
 * @type {(
 *   typeof BaseCreatableSelect
 * )}
 */
export const CreatableSelect = React.forwardRef(({ options, value, onChange, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseCreatableSelect
      value={findInOptions(_options, value)}
      options={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
CreatableSelect.propTypes = commonPropTypes;

/**
 * @type {(
 *   typeof BaseLabeledCreatableSelect
 * )}
 */
export const LabeledCreatableSelect = React.forwardRef(({ options, value, onChange, label, name, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseLabeledCreatableSelect
      label={label}
      name={name}
      value={findInOptions(_options, value)}
      options={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
LabeledCreatableSelect.propTypes = commonLabeledPropTypes;

/**
 * @type {(
 *   typeof BaseAsyncSelect
 * )}
 */
export const AsyncSelect = React.forwardRef(({ options, value, onChange, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseAsyncSelect
      value={findInOptions(_options, value)}
      defaultOptions={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
AsyncSelect.propTypes = commonPropTypes;

/**
 * @type {(
 *   typeof BaseLabeledAsyncSelect
 * )}
 */
export const LabeledAsyncSelect = React.forwardRef(({ options, value, onChange, label, name, isMulti = false, onUpdate = () => { }, changeKey, onChangeOptions = {}, ...props }, ref) => {
  const _options = React.useMemo(() => getCustomOptions(options), [options]);
  const extended_props = getExtraProps({options: _options, ...props});

  return (
    <BaseLabeledAsyncSelect
      label={label}
      name={name}
      value={findInOptions(_options, value)}
      options={_options}
      onChange={onChange || _createEventHandler(onUpdate, changeKey, { type: 'select', isMulti, ...onChangeOptions })}
      isMulti={isMulti}
      {...extended_props}
      ref={ref}
    />
  );
});
LabeledAsyncSelect.propTypes = commonLabeledPropTypes;
