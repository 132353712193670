import { debounce } from 'lodash';
import Tooltip from 'react-tooltip';

export * from './RestrictedAccess';
export * from './Pagination';
export * from './Select';
export { default as Alert } from './Alert';
export { default as SearchDropdownMenu } from './SearchDropdown';
export { default as Chart } from './DynamicChart';
export { default as ChartPopup } from './ChartPopup';
export { default as NumberInput } from './NumberInput';
export { default as createGlobalStyle } from './createGlobalStyle';
export { default as DashboardTile } from './DashboardTile';
export { default as DashboardSalesTotalsTile } from './DashboardSalesTotalsTile';
export { default as HubspotForm } from './HubspotForm';
export { default as MarketingTile } from './MarketingTile';
export { default as DebouncedInput } from './DebouncedInput';
export { default as Swipeable } from './Swipeable';
export { default as RichTextEditor } from './RichTextEditor';
export { default as withRouter } from './withRouter';

export const rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true
});
