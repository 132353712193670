import React from 'react';

const MaximumQuantity = ({ style={} }) => (
  <div style={{
    color: '#BF3F69',
    position: 'absolute',
    right: '0.5rem',
    top: '2rem',
    fontWeight: 'bold',
    ...style
  }}>MAX</div>
);

export default MaximumQuantity;
