import { ItemColor } from "../interfaces/ItemColor";
import { ItemSize } from "../interfaces/ItemSize";
import { ItemSku } from "../interfaces/ItemSku";

type CartItem = {
  item_id: string;
  color_id: string;
  size_id: string;
  product_sku_id: string | null;
  quantity: number;
};

type LocalCartState = {
  [key: string]: CartItem[];
};

interface SkuType extends ItemSku {
  sku: string;
  description: string;
  sku_description: string;
}

export const saveCartStateToLocalStorage = (
  shop_id: string,
  data: LocalCartState
) => {
  localStorage.setItem(`shop.${shop_id}.cart`, JSON.stringify(data));
};

/**
 *
 * We need to check if the items are still available in the store, otherwise need to filter them out and update the local storage and the initial states of the redux store
 * @param localCartState the current state of the cart store in the local storage
 * @param sizes available colors for the sizes in the shop
 * @param skus available skus for the items in the shop
 * @param colors available colors for the items in the shop
 * @param items available items for the items in the shop, just for the additional step
 */
const checkCartAvailability = (
  localCartState: LocalCartState,
  sizes: { [K: string]: ItemSize },
  skus: { [K: string]: SkuType },
  colors: { [K: string]: ItemColor },
  items: string[]
) => {
  if (!localCartState) {
    return null;
  }

  let updatedLocalCartState: LocalCartState = {};

  for (const key in localCartState) {
    const cartItem = localCartState[key];
    const availableItems = cartItem.filter((item) => {
      const { item_id, color_id, size_id, product_sku_id } = item;

      if (product_sku_id) {
        return Object.values(skus).some(
          (sku) =>
            sku.product_sku_id === product_sku_id && sku.item_id === item_id
        );
      }

      if (
        (size_id ?? "TBD") !== "TBD" &&
        !Object.values(sizes).some(
          (size) => size.size_id === size_id && size.item_id === item_id
        )
      ) {
        return false;
      }

      if (
        (color_id ?? "TBD") !== "TBD" &&
        !Object.values(colors).some(
          (color) => color.color_id === color_id && color.item_id === item_id
        )
      ) {
        return false;
      }

      if (!items.some((item) => item === item_id)) {
        return false;
      }

      return true;
    });

    if (availableItems.length > 0) {
      updatedLocalCartState[key] = availableItems;
    }
  }

  return updatedLocalCartState;
};

export const loadCartStateFromLocalStorage = (
  shop_id: string,
  itemSkus: { [K: string]: SkuType },
  itemColors: { [K: string]: ItemColor },
  itemSizes: { [K: string]: ItemSize },
  items: string[]
) => {
  if (!shop_id) {
    return null;
  }

  const localCartString = localStorage.getItem(`shop.${shop_id}.cart`);

  if (!localCartString) {
    return {};
  }

  const localState = JSON.parse(localCartString);

  if (!localState || Object.keys(localState).length === 0) {
    return {};
  } else {
    const availableItems = checkCartAvailability(
      localState,
      itemSizes,
      itemSkus,
      itemColors,
      items
    );

    saveCartStateToLocalStorage(shop_id, availableItems);
    return availableItems;
  }
};

export const checkoutCartChecker = (shop_id: string) => {
  const localCartState = localStorage.getItem(`shop.${shop_id}.cart`);
  const localState = JSON.parse(localCartState);

  if (!localState || Object.keys(localState).length === 0) {
    return null;
  } else {
    return localState;
  }
};

export const cleanCartFromLocalStorage = (shop_id: string) => {
  if (!shop_id) {
    return;
  }
  localStorage.removeItem(`shop.${shop_id}.cart`);
};
