import { filter, find, get, map, isEmpty, chain, uniq } from 'lodash';

export const getBreakdownOptionDescription = (breakdown, { sizes, colors, skus }, axes) => {
  const { product_sku_id } = breakdown;
  const sku = find(skus, { product_sku_id });
  if (!sku) {
    const size = find(sizes, { size_id: breakdown.size_id });
    const color = find(colors, { color_id: breakdown.color_id });

    return [get(color, 'color_name', 'TBD'), get(size, 'size_name', 'TBD')].join('/');
  }

  const sku_options = chain(skus).map('options').flatten()
    .groupBy('option_axis')
    .mapValues((options, option_axis) => {
      return uniq(map(options, 'option_name'));
    })
    .value()
  ;
  if (isEmpty(axes)) {
    axes = Object.keys(sku_options)
      .filter((option_axis) => {
        return 'dimension' !== option_axis || (!isEmpty(sku_options['dimension']) && isEmpty(sku_options['size']));
      })
      .concat('sku')
    ;
  }

  return (!isEmpty(sku.options) ? sku.options : [{ option_axis: 'sku', option_name: sku.sku }])
    .filter(o => axes.includes(o.option_axis))
    .sort((a, b) => {
      const ia = axes.indexOf(a.option_axis);
      const ib = axes.indexOf(b.option_axis);
      return ia < ib ? -1 : (ia > ib ? 1 : 0);
    })
    .map(o => o.option_name)
    .join('/')
  ;
};

export const getOrderItems = ({ items, order_type }) => {
  const order_items = filter(items, i => {
    return !['SHOP', 'PRESENTATION', 'COLLECTION'].includes(order_type) || 'PRODUCT' !== i.parent_type;
  });

  return order_items.sort((a, b) => {
    if (parseInt(a.display_order) < parseInt(b.display_order)) {
      return -1;
    }

    if (parseInt(a.display_order) > parseInt(b.display_order)) {
      return 1;
    }

    return 0;
  });
};
