export const isNumber = (num: unknown): num is number => 
    typeof num === 'number';

export const isString = (string: unknown): string is string =>
    typeof string === 'string';

export const isBool = (bool: unknown): bool is boolean =>
    typeof bool === 'boolean';

export const isURL = (url: unknown): url is URL =>
    url instanceof URL;

export const isDate = (date: unknown): date is Date =>
    date instanceof Date;
