import { find, get  } from 'lodash';
import React, { Component } from 'react';

import Form from './Form';
import Img from './Img';
import PresentationItemLocationBadge from './PresentationItemLocationBadge';
import PS from './PromostandardsLabel';
import PSDecorationSelect from './PSDecorationSelect';
import TextInput from './TextInput';

import { getImageSrc } from '../utils';
import { getUoms, getUomName, getUomValues } from '../promostandard_utils';
import { isValidExtLocationId } from '../helpers/ps_locations';

const DELETE_PROPS = {
  className: 'small-1 columns',
  style: {
    textAlign: 'center',
    color: '#5ca3B6',
    padding: '0.5rem',
    marginTop: '1rem'
  }
};

class PresentationItemLocation extends Component {

  constructor(props) {
    super(props);
    const item_decoration = props.item_decorations.filter(id => id.item_location_id === props.item_location.item_location_id)[0];
    const uoms = item_decoration ? getUomValues(item_decoration) : {};
    this.state = {
      uoms
    };
  }

  componentDidUpdate(prevProps) {
    const prev_item_decoration = prevProps.item_decorations.filter(id => id.item_location_id === prevProps.item_location.item_location_id)[0];
    const item_decoration = this.props.item_decorations.filter(id => id.item_location_id === this.props.item_location.item_location_id)[0];
    const oldUoms = getUomValues(prev_item_decoration);
    const newUoms = getUomValues(item_decoration);
    const uoms = Object.keys(newUoms).filter(
      k => oldUoms[k] != newUoms[k]
    );

    if (uoms.length > 0) {
      this.setState(state => ({
        uoms: uoms.reduce(
          (o, k) => ({
            ...o,
            [k]: newUoms[k]
          }),
          state.uoms
        )
      }));
    }
  }

  handleChangeItemDecorationUom(uom, e) {
    const value = e.target.value;
    this.setState(state => ({
      uoms: ({
        ...state.uoms,
        [uom]: value
      })
    }));
  }

  handleUpdateItemDecorationUom(uom, e) {
    const { item_location, item_decorations, decorations, onUpdateItemDecoration } = this.props;
    const item_decoration = item_decorations.filter(id => id.item_location_id === item_location.item_location_id)[0];
    const decoration = find(decorations, ({ ps_decoration_id }) => {
      return `${ps_decoration_id}` == get(item_decoration, 'ext_decoration_id');
    });
    const maxUnits = decoration && decoration.decoration_units_max;
    const uoms = getUoms(decoration);

    const maxValue = Math.max(maxUnits, uoms[uom] && uoms[uom].length > 0 ? uoms[uom][uoms[uom].length - 1] : maxUnits);
    const value = e.target.value;
    if (value < 0 || value > maxValue) {
      this.setState(state => ({
        uoms: ({
          ...state.uoms,
          [uom]: item_decoration[`decoration_uom.${uom}`]
        })
      }));
    } else {
      onUpdateItemDecoration(item_decoration.item_decoration_id, 'decoration_uom', uoms, {[uom]: e.target.value});
    }
  }

  getInputProps() {
    const { error, field, item_location, locked, onFieldChange, onFieldBlur, onFieldFocus, value } = this.props;
    return {
      className: 'small-10 columns',
      containerClassName: 'field',
      containerStyle: {
        position: 'relative',
        paddingRight: 0,
        paddingLeft: '0.25rem'
      },
      disabled: locked || isValidExtLocationId(item_location.ext_location_id),
      displayLabel: false,
      error,
      extra: isValidExtLocationId(item_location.ext_location_id) && <PS style={{ position: 'absolute', top: '1.5rem', left: '0.5rem' }} />,
      field,
      onFieldBlur,
      onFieldChange,
      onFieldFocus,
      placeholder: `Location ${parseFloat(item_location.display_order) + 1}`,
      style: {
        marginRight: 0,
        paddingRight: 0,
        paddingLeft: isValidExtLocationId(item_location.ext_location_id) ? '2rem' : '0.25rem',
        marginTop: '1rem'
      },
      value
    };
  }

  getArtworkProps() {
    const { artworks, item_decorations, item_location, onEditArtwork } = this.props;

    const handleAddArtwork = e => {
      e.stopPropagation();
      if (artworks.length && !artworks.find((a) => !a.ext_artwork_id)) {
        return;
      }
      const item_decoration = item_decorations.filter(id => id.item_location_id === item_location.item_location_id)[0] || {};
      if (isValidExtLocationId(item_location.ext_location_id) && !item_decoration.ext_decoration_id) {
        return;
      }
      onEditArtwork({
        item_id: item_location.item_id,
        item_location_id: item_location.item_location_id,
        item_decoration_id: item_decoration.item_decoration_id,
        imprint_id: item_decoration.imprint_id,
        ext_artwork_id: item_decoration.ext_decoration_id
      });
    };

    return {
      className: 'row small-12 columns',
      style: {
        textAlign: artworks.length > 0 ? 'left' : 'center',
        padding: artworks.length > 0 ? '0.5rem' : '2rem',
        border: '1px dashed #5CA3B6',
        color: '#5CA3B6',
        cursor: 'pointer'
      },
      onClick: handleAddArtwork
    };
  }

  render() {
    const {
      artworks,
      decorations,
      item_decorations,
      item_location,
      locked,
      onDelete,
      onEditArtwork,
    } = this.props;

    const inputProps = this.getInputProps();
    const artworkProps = this.getArtworkProps();

    const validYUoM = ['Colors', 'Inches', 'Stitches', 'SquareInches'];

    const handleDelete = e => {
      e.preventDefault();
      onDelete(item_location.item_location_id);
    };

    const handleEditArtwork = artwork => e => {
      e.stopPropagation();
      onEditArtwork(artwork);
    };

    const item_decoration = item_decorations.filter(id => id.item_location_id === item_location.item_location_id)[0];
    const decoration = find(decorations, ({ ps_decoration_id }) => {
      return `${ps_decoration_id}` == get(item_decoration, 'ext_decoration_id');
    });
    const maxUnits = decoration && decoration.decoration_units_max;
    const uoms = getUoms(decoration);
    return (
      <div className="row collapse">
        <div className="row small-12 columns show-on-hover-container">
          <PresentationItemLocationBadge className="small-1 columns" index={item_location.index} style={{ marginTop: '1rem' }}/>
          <div style={{ position: 'relative' }}>
            <Form.TextInput {...inputProps} />
          </div>
          <div {...DELETE_PROPS}><a className="delete show-on-hover" onClick={handleDelete}>&times;</a></div>
        </div>
        {isValidExtLocationId(item_location.ext_location_id) &&
          item_decorations.map(id =>
            <div key={id.item_decoration_id} className="row small-12 columns">
              <PSDecorationSelect item_decoration={id} decorations={decorations}/>
              {decorations.filter(
                 d => d.ps_decoration_id == id.ext_decoration_id && validYUoM.includes(d.decoration_units_included_uom)
               ).map(
                 d => {
                   const maxValue = Math.max(maxUnits, d.decoration_units_max, uoms[d.decoration_units_included_uom] && uoms[d.decoration_units_included_uom].length > 0 ? uoms[d.decoration_units_included_uom][uoms[d.decoration_units_included_uom].length - 1] : maxUnits);

                   const hasYUom = (uoms[d.decoration_units_included_uom] || []).length > 0;
                   const maxYUom = `Max ${maxValue} ${getUomName(d.decoration_units_max, d.decoration_units_included_uom)}`;
                   const uomText = hasYUom ? `${d.decoration_units_included} ${getUomName(d.decoration_units_included, d.decoration_units_included_uom)} Included (${maxYUom})` : maxYUom;

                   return (
                     <span key={d.ps_decoration_id} style={{ fontSize: 'small' }}>
                       {uomText}
                     </span>
                   );
                 }
              )}
            </div>
          )
        }
        {(!!item_decoration && !!item_decoration.ext_decoration_id) &&
          <div>
            {Object.keys(uoms).filter(u => uoms[u].length > 0 && uoms[u][uoms[u].length - 1] > 0).map(u =>
              <div key={`decoration-uom-${u}`}>
                <span style={{ fontWeight: 'bold' }}>{`# ${u}`}</span>
                <TextInput
                  type="number"
                  min={0}
                  max={maxUnits || uoms[u][uoms[u].length - 1]}
                  value={this.state.uoms[u] || ''}
                  onChange={this.handleChangeItemDecorationUom.bind(this, u)}
                  onBlur={this.handleUpdateItemDecorationUom.bind(this, u)}
                  selectOnFocus={true}
                />
              </div>
            )}
          </div>
        }
        {(!isValidExtLocationId(item_location.ext_location_id) || (!!item_decoration && !!item_decoration.ext_decoration_id)) &&
          <div {...artworkProps}>
            {artworks.map(a =>
              <Img key={a.artwork_id} src={getImageSrc(a.image)} onClick={handleEditArtwork(a)} />
            )}
            {(!artworks.length && !locked) && 'Add Artwork'}
          </div>
        }
      </div>
    );
  }
}

export default PresentationItemLocation;
