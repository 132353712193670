import React from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import { Theme } from '@commonsku/styles';

import '../../scss/css/app.css';
import configureStore from '../store/configureReskuStore';
import ErrorBoundary from '../components/ErrorBoundary';
import CskuApp from './CskuApp';
import ProjectRouter from '../components/ProjectRouter';
import { window } from '../global';

const additionalGlobalStyles = `
@font-face {
  font-family: "skufont-bold";
  src: url("/fonts/TT-Norms-Pro-Bold.eot");
  src: url("/fonts/TT-Norms-Pro-Bold.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Bold.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-demibold";
  src: url("/fonts/TT-Norms-Pro-DemiBold.eot");
  src: url("/fonts/TT-Norms-Pro-DemiBold.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-DemiBold.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-DemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-medium";
  src: url("/fonts/TT-Norms-Pro-Medium.eot");
  src: url("/fonts/TT-Norms-Pro-Medium.otf") format("opentype"),
      url("/fonts/TT-Norms-Pro-Medium.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Medium.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-regular";
  src: url("/fonts/TT-Norms-Pro-Regular.eot");
  src: url("/fonts/TT Norms Pro Regular.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Regular.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Regular.woff2") format("woff2");
}
`;

const NotFound = () => null;
const components = {
  AdminDecorationChargesApp: loadable(() => import('./AdminDecorationChargesApp')),
  AdminAvalaraApp: loadable(() => import('./AdminAvalaraApp')),
  AdminSettingsNewApp: loadable(() => import('./AdminSettingsNewApp')),
  AnalyticsApp: loadable(() => import('./AnalyticsApp')),
  AutoDKIM: loadable(() => import('./AutoDKIM')),
  ClientApp: loadable(() => import('./ClientApp')),
  CollectionRouter: loadable(() => import('../components/CollectionRouter')),
  CollectionsRouter: loadable(() => import('../components/CollectionsRouter')),
  CompanyRouter: loadable(() => import('../components/CompanyRouter')),
  CompanySearchApp: loadable(() => import('./CompanySearchApp')),
  ChatAgentApp: loadable(() => import('./ChatAgentApp')),
  CommunityEventsApp: loadable(() => import('../components/community-events')),
  CompanySetup: loadable(() => import('../components/company-setup/CompanySetup')),
  ContactRouter: loadable(() => import('../components/ContactRouter')),
  CskuApp,
  DashboardApp: loadable(() => import('./DashboardApp')),
  DistributorAddressesApp: loadable(() => import('./DistributorAddressesApp')),
  FileExplorer: loadable(() => import('./FileExplorer')),
  GalleryRouter: loadable(() => import('../components/GalleryRouter')),
  LicenseChangeApp: loadable(() => import('./LicenseChangeApp')),
  MarketingSettingsApp: loadable(() => import('./MarketingSettingsApp')),
  XeroAdmin: loadable(() => import('../components/XeroAdmin')),
  PresentApp: loadable(() => import('./PresentApp')),
  ProductsApp: loadable(() => import('./ProductsApp')),
  SalesAnalyticsApp: loadable(() => import('./SalesAnalyticsApp')),
  FinanceAnalyticsApp: loadable(() => import('./FinanceAnalyticsApp')),
  ManagementAnalyticsApp: loadable(() => import('./ManagementAnalyticsApp')),
  ManageThemesApp: loadable(() => import('./ManageThemesApp')),
  ProjectDashboardApp: loadable(() => import('./ProjectDashboardApp')),
  ProjectRouter,
  ProofApproval: loadable(() => import('./ProofApproval')),
  PSChargesReportApp: loadable(() => import('./PSChargesReportApp')),
  PSReportApp: loadable(() => import('./PSReportApp')),
  PSSupplierApp: loadable(() => import('./PSSupplierApp')),
  PublicShopRouter: loadable(() => import('../components/shop/PublicShopRouter')),
  PublicOrderShopRouter: loadable(() => import('../components/PublicOrderShopRouter')),
  PortalRouter: loadable(() => import('../components/PortalRouter')),
  PromoDataProductApp: loadable(() => import('./PromoDataProductApp')),
  ShopsFeesApp: loadable(() => import('./ShopsFeesApp')),
  ShopRouter: loadable(() => import('../components/shop/ShopRouter')),
  ShopsRouter: loadable(() => import('../components/shop/ShopsRouter')),
  SuppliersApp: loadable(() => import('./SuppliersApp')),
  TeamAutoDKIM: loadable(() => import('../components/CompanyEmailDomainTable')),
  TourApp: loadable(() => import('./TourApp')),
  SelectFileApp: loadable(() => import('./SelectFileApp')),
  ProductionReportApp: loadable(() => import('./ProductionReportApp')),
  MarketingSoftwareApp: loadable(() => import('./MarketingSoftwareApp')),
  ClientPortalRouter: loadable(() => import('../components/client-portal/ClientPortalRouter')),
  LoginApp: loadable(() => import('./LoginApp')),
  EmptyApp: loadable(() => import('./EmptyApp')),
  ResetPasswordApp: loadable(() => import('./ResetPasswordApp')),
  TwoFactorSetupApp: loadable(() => import('./TwoFactorSetupApp')),
  CompanyActivationApp: loadable(() => import('./CompanyActivationApp')),
  UserInvitationApp: loadable(() => import('./UserInvitationApp')),
  AdminUsersNewApp: loadable(() => import('./AdminUsersNewApp')),
  ReportApp: loadable(() => import('./ReportApp')),
  SupplierBillingApp: loadable(() => import('./SupplierBillingApp')),
  AllowDeny: loadable(()=>import('../components/AllowDeny')),
  ManageDistributorApp: loadable(() => import('./ManageDistributorApp')),
  ContractChangesApp: loadable(() => import('./ContractChangesApp')),
  StartDiscussion: loadable(() => import('../components/StartDiscussion')),
  FinanceApp: loadable(() => import('./FinanceApp')),

  ///////////////// example ///////////////////////
  ReskuExampleApp: loadable(() => import('./ReskuExampleApp')),
};

const App = ({ component, initialState, defaultReducers, ...props }) => {
  const LazyComponent = components[component] || NotFound;
  if (typeof (window.initialState) === 'undefined') {
    window.initialState = initialState;
  }

  const store = configureStore(initialState || window.initialState, defaultReducers);

  return <Provider store={store}>
    <ErrorBoundary>
      <Theme globalStyles={true} additionalGlobalStyles={additionalGlobalStyles}>
        <LazyComponent attachReducers={store.attachReducers} {...props} />
      </Theme>
    </ErrorBoundary>
  </Provider>;
};

export default App;
