import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowIcon, Button, Col, IconButton, LabeledInput, Row } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import { createAddClient } from '../../actions/client';
import { Alert, LabeledSelect } from '../helpers';
import { industryOptions } from '../../selectors/industries';
import { oauth } from '../../utils';

export default function NewClientForm({
    onCreate,
    ...props
}) {
    const identity = useIdentity();
    const dispatch = useDispatch();
    const industry_options = useSelector(industryOptions);

    const [clients, setClients] = useState([]);
    const [clientExists, setClientExists] = useState(false);
    const [form, setForm] = useState({
        client_name: '',
        industry_id: '',
    });

    function onChangeField(field, value) {
        setForm(
            s => ({ ...s, [field]: value })
        );
    }

    const clientNameExists = React.useMemo(
        () => _.get(clientExists, ['client_name'], null),
        [clientExists]
    );

    const renderAlert = React.useCallback(() => {
        if (clientNameExists) {
            return (
                <Alert type="danger">
                    <span style={{ fontWeight: 'bold' }}>{clientNameExists}</span> already exists!
                </Alert>
            );
        }
        return null;
    }, [clientNameExists]);

    const canCreate = React.useMemo(() => {
        return form.client_name
            && form.industry_id
            && !Boolean(clientNameExists);
    }, [form.client_name, form.industry_id, clientNameExists]);

    const searchClient = useCallback(
        _.debounce(async (clientName) => {
            const { json } = await oauth('GET', 'company', {
                company_type: 'CLIENT',
                search: true,
                include_inactive: true,
                'max-results': 20,
                company_name: clientName.toLowerCase().trim(),
                order_by: 'latest_use',
                order_dir: 'DESC',
            });

            setClients(json.companies);
            return json.companies;
        }, 400),
        []
    );

    function handleCreate() {
        if (!canCreate) { return; }

        Promise.resolve(
            dispatch(createAddClient(form.client_name, form.industry_id, identity.user_id))
        ).then(action => {
            const clientId = action.payload.client.client_id;
            onCreate && onCreate(clientId);
        });
    }

    useEffect(() => {
        setClientExists(false);
        if (!form.client_name) {
            return;
        }

        searchClient(form.client_name);
    }, [form.client_name, searchClient]);

    useEffect(() => {
        if (!clients.length) { return; }
        const found = _.find(
            clients,
            v => v.client_name.toLowerCase().trim() === form.client_name.toLowerCase().trim()
        );
        setClientExists(found);
    }, [form.client_name, clients]);

    return (
        <Row>
            <Col xs padded>
                {renderAlert()}
            </Col>
            <Col xs padded>
                <LabeledInput
                    name="client_name"
                    label="Client Name"
                    value={form.client_name}
                    onChange={e => {
                        const value = e.target.value ? `${e.target.value}`.trimStart() : '';
                        if (value.length > 250) {
                            return;
                        }
                        onChangeField('client_name', value);
                    }}
                    error={Boolean(clientNameExists)}
                />
            </Col>
            <Col xs padded>
                <LabeledSelect
                    name="client_industry"
                    label="Client Industry"
                    options={industry_options}
                    value={form.industry_id}
                    onChange={e => onChangeField('industry_id', e.value)}
                />
            </Col>
            <Col padded xs pb={10}>
                <IconButton
                    style={{ width: '100%' }}
                    variant={canCreate ? "primary" : "disabled"}
                    onClick={handleCreate}
                    Icon={ArrowIcon}
                    iconPosition='right'
                    iconProps={{direction: 'right'}}
                >Create Client</IconButton>
            </Col>
        </Row>
    );
}
