import _ from 'lodash';
import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import { window } from '../global';
import { getItemShipping } from '../selectors';
import ProjectMenuItem from './ProjectMenuItem';
import { Text } from '@commonsku/styles';
import { useHasCapabilities } from '../hooks';

const activeClassName = ({ isActive, childLink=false }) => {
  let result = isActive ? "active" : "";
  if (childLink) {
    result += " no-parent-hover";
  }
  return result.trim();
};

const ProjectMenu = ({ project, identity, has_shipping }) => {
  const job_number = project.job_number;
  const v2 = useHasCapabilities('COMPANY-TYPE-DISTRIBUTOR');
  const POsText = v2 ? 'POs' : 'Purchase Orders';
  const hasCommissions = useHasCapabilities(['HAS-COMMISSIONS']);

  const pptMatched = useMatch(`/project/${job_number}/presentation/:formNumber`);
  const pptDesignMatched = useMatch(`/project/${job_number}/presentation/:formNumber/design`);

  return (
    <>
      {v2 ? <div className="sub-right-menu-top">
        <Text as="p"className="sub-right-menu-top-title">Project</Text>
      </div> : null}
      <ul className="vertical menu">
        <li className="show-for-small-only"><a href={identity.user_flags.includes('DASHBOARD-HOME') ? '/dashboard.php' : '/home.php'}>Home</a></li>
        <li><NavLink end to={`/project/${job_number}`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Overview</NavLink></li>
        <li><NavLink to={`/project/${job_number}/files`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Files</NavLink></li>
        <li>{project.collaborate_messages.length || window.location.pathname === `/project/${job_number}/collaborate` ? <NavLink to={`/project/${job_number}/collaborate`} className={activeClassName}>Collaborate</NavLink> : <a className="disabled">Collaborate</a>}</li>
        {project.presentations.map(p => {
          if ((!pptMatched && !pptDesignMatched) || p.view_type !== 'SHOP_DESIGN') {
            return (
              <ProjectMenuItem
                key={job_number + '-' + p.form_number + '-' + p.order_id}
                onClick={e => { document.body.scrollTop = 0; }}
                order_type="PRESENTATION"
                job_number={job_number}
                form_number={p.form_number}
                end
              >
                {project.presentations.length > 1 ? 'Presentation #' + p.form_number : 'Presentation'}
              </ProjectMenuItem>
            );
          }
          return (
            <li key={job_number + '-' + p.form_number + '-' + p.order_id}>
              <ul className='vertical menu sub-menu' style={{ listStyle: 'none', background: '#006672' }}>
                <li className="no-bg">
                  <a className="no-bg" style={{ background: 'transparent' }}>
                    {project.presentations.length > 1 ? 'Presentation #' + p.form_number : 'Presentation'}
                  </a>
                </li>
                <li style={{ paddingLeft: '16px' }}>
                  <NavLink end
                    to={`/project/${job_number}/presentation/${p.form_number}`}
                    onClick={e => { document.body.scrollTop = 0; }}
                    className={v => activeClassName({ ...v, childLink: true })}
                  >Build</NavLink>
                </li>
                {/* disable if beta not selected */}
                <li style={{ paddingLeft: '16px' }}>
                  <NavLink
                    to={`/project/${job_number}/presentation/${p.form_number}/design`}
                    onClick={e => { document.body.scrollTop = 0; }}
                    className={v => activeClassName({ ...v, childLink: true })}
                  >Design</NavLink>
                </li>
              </ul>
            </li>
          );
        })}
        {!project.presentations.length ? <li><a className="disabled">Presentation</a></li> : null}
        {project.estimates.map(p =>
          <ProjectMenuItem key={p.form_number} onClick={e => { document.body.scrollTop = 0; }} order_type="ESTIMATE" job_number={job_number} form_number={p.form_number}>
            {project.estimates.length > 1 ? 'Estimate #' + p.form_number : 'Estimate'}
          </ProjectMenuItem>
        )}
        {!project.estimates.length ? <li><a className="disabled">Estimate</a></li> : null}
        {project.sales_orders.map(p =>
          <ProjectMenuItem key={p.form_number} onClick={e => { document.body.scrollTop = 0; }} order_type="SALES ORDER" job_number={job_number} form_number={p.form_number}>
            {project.sales_orders.length > 1 ? 'Sales Order #' + p.form_number : 'Sales Order'}
          </ProjectMenuItem>
        )}
        {!project.sales_orders.length ? <li><a className="disabled">Sales Order</a></li> : null}
        <li>{project.sales_orders.length ? <NavLink to={`/project/${job_number}/shipping`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Shipping</NavLink> : <a href="#" className="disabled">Shipping</a>}</li>
        <li>{project.sales_orders.length && has_shipping || project.purchase_orders.length ? <NavLink to={`/project/${job_number}/production`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>{POsText}</NavLink> : <a href="#" className="disabled">{POsText}</a>}</li>
        {project.invoices.map(p =>
          <ProjectMenuItem key={p.form_number} onClick={e => { document.body.scrollTop = 0; }} order_type="INVOICE" job_number={job_number} form_number={p.form_number}>
            {project.invoices.length > 1 ? 'Invoice #' + p.form_number : 'Invoice'}
          </ProjectMenuItem>
        )}
        {!project.invoices.length ? <li><a className="disabled">Invoice</a></li> : null}
        <li>{project.sales_orders.length ? <NavLink to={`/project/${job_number}/bills`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Bills</NavLink> : <a href="#" className="disabled">Bills</a>}</li>
        {hasCommissions &&
          <li>{project.sales_orders.length && project.invoices.length ? <NavLink to={`/project/${job_number}/commissions`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Commissions</NavLink> : <a className="disabled">Commissions</a>}</li>
        }
        <li>{project.invoices.length && project.sales_orders.length ? <NavLink to={`/project/${job_number}/feedback`} onClick={e => { document.body.scrollTop = 0; }} className={activeClassName}>Feedback</NavLink> : <a href="#" className="disabled">Feedback</a>}</li>
      </ul>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const item_shipping = getItemShipping(state);
  const has_shipping = item_shipping.reduce((t, i) => t && i.shipping && !!i.shipping.length, !!item_shipping.length);
  return {
    identity: state.identity,
    has_shipping
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMenu);
