import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PublisherCollaborate from './PublisherCollaborate';
import Feed from './Feed';
import ProjectStatus from './ProjectStatus';

import { syncCollaborateMessages } from '../actions/project';
import { createUpdateDisplayCommenting } from '../actions/display';

import { oauth } from '../utils';

class ProjectCollaborate extends Component{
  constructor(props) {
    super(props);

    this.state = {
      collaborate_messages: props.project.collaborate_messages
    };

    _.bindAll(this, ['loadMessagesFromServer', 'startRefresh', 'stopRefresh']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      collaborate_messages: nextProps.project.collaborate_messages
    });

    if (nextProps.commenting) {
      this.stopRefresh();
    } else {
      this.startRefresh();
    }
  }

  componentDidMount() {
    if (!this.props.commenting) {
      this.startRefresh();
    }
  }

  componentWillUnmount() {
    this.stopRefresh();
    this.props.onStopCommenting();
  }

  startRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(this.loadMessagesFromServer, this.props.newsfeed_refresh_interval);
  }

  stopRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  loadMessagesFromServer() {
    const data = {
      job_id: this.props.project.job_id
    };
    return oauth('GET', 'newsfeed/collaborate', data).then(
      ({ json }) => {
        this.props.onSyncCollaborateMessages(json.projectfeed);
      }
    );
  }

  render() {
    const { project, onChangeProject, onUpdateProject, onSyncCollaborateMessages, items } = this.props;

    return (
      <div className="main-content project-collaborate">
        <div className="medium-12 large-4 large-push-8 columns">
          <ProjectStatus project={project} items={items} />
          <br /><br />
          <div className="row">
            <div className="small-12 columns">
              <div className="tip highlight">
                <h4>Collaborate with your suppliers</h4>
                <br />
                <p>Select a supplier and its rep from the lists, tell them about your project and click Collaborate. Your supplier rep will be notified via email so they can work with you on your project. Suppliers that are part of commonsku collaborate will be able to suggest products which you can add to your project in 1 click.</p>
                <br />
                <p>You have full control over which product suggestions are added to your project and can see all requests and suggestions here in your project.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="medium-12 large-8 large-pull-4 columns">
          <PublisherCollaborate job_id={project.job_id} client_id={project.account_id} opportunity_id={_.get(project, 'opportunities.0.order_id')}/>
          <Feed posts={this.state.collaborate_messages} job_id={project.job_id} comment_allowed={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.entities.items,
    commenting: state.display.commenting,
    newsfeed_refresh_interval: state.display.newsfeed_refresh_interval
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSyncCollaborateMessages: collaborate_messages => dispatch(syncCollaborateMessages(collaborate_messages)),
    onStopCommenting: () => dispatch(createUpdateDisplayCommenting(false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCollaborate);
