import React, { useState } from "react";
import { Tab as BaseTab, TabBar } from "@commonsku/styles";

function TabTitle(props) {
  const { children, width, selected = false, onClick } = props;
  return (
    <BaseTab selected
      onClick={onClick}
      style={{
        background: `var(--color-neutrals-${selected ? 40 : 30})`,
        color: 'var(--color-primary1-main)',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        textAlign: 'center',
        border: 'none',
        width: width,
        margin: 0,
        padding: 8,
        opacity: 1,
      }}
    >{children}</BaseTab>
  );
}

// Dummy component used to help layout in StyledTabs
export function Tab(props) {
  const {
    children,
    title,
    width,
    selected = false,
  } = props;
  return children;
}

// Example:
// <StyledTabs selectedIndex={2} setTabIndex={idx => onUpdateTabIdx(idx)}>
//    <Tab title="Text selected>
//      <Input value="test content input" />
//      ...
//    </Tab>
//    <Tab title="Text selected>
//      <Img src="" alt="" />
//      ...
//    </Tab>
// </StyledTabs>
export default function StyledTabs(props) {
  const {
    children,
    childWidth,
    selectedIndex = 0,
    setTabIndex,
  } = props;
  const length = React.Children.count(children);

  return (
    <>
      <TabBar>
        {React.Children.map(
          children,
          (c, i) => <TabTitle
            selected={selectedIndex === i}
            width={childWidth || c.props.width || `${(1/length) * 100}%`}
            children={c.props.title}
            onClick={() => {
              setTabIndex && setTabIndex(i);
            }}
          />)}
      </TabBar>
      <div style={{
        padding: '14px 14px',
        background: 'var(--color-neutrals-40)',
        borderBottomRightRadius: 7,
        borderBottomLeftRadius: 7,
      }}>
        {children[selectedIndex]}
      </div>
    </>
  );
}

export function Tabs(props) {
  const {
    children,
    childWidth,
    initialIndex = 0,
    onSelectTab,
  } = props;

  const [selectedTabIndex, setTabIndex] = useState(initialIndex);
  const handleChangeTabIndex = React.useCallback((tabIndex) => {
    setTabIndex(tabIndex);
    onSelectTab && onSelectTab(tabIndex);
  }, [onSelectTab]);

  return (
    <StyledTabs
      childWidth={childWidth}
      selectedIndex={selectedTabIndex}
      setTabIndex={handleChangeTabIndex}
    >{children}</StyledTabs>
  );
}
