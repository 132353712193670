import { chain, difference, filter, first, get, isEmpty, keyBy, keys, map, max, min, sortBy, toNumber, uniq, values } from 'lodash';
import React from 'react';

import { getInventoryLevels, getParts } from '../promostandards';
import { formatMoney, sizeSort } from '../utils';

export const axisSort = {
  size: sizeSort,
};
export const COLUMN_CLASSES = {
  color: 0,
  size: 1,
  dimension: 2,
};
export const columnSort = (a, b) => (COLUMN_CLASSES[b] || Object.keys(COLUMN_CLASSES).length) - (COLUMN_CLASSES[a] || Object.keys(COLUMN_CLASSES).length);
export const defaultSort = (a, b) => a < b ? -1 : (a > b ? 1 : 0);

export function getCostCategory(sku, currency_id = "USD") {
  return uniq(map(sku.costs[currency_id] || sku.costs['USD'] || first(values(sku.costs)), (price) => toNumber(price.price))).sort().toString();
};

export async function reloadInventory(ext_product_id) {
  const response = await getInventoryLevels({ productId: ext_product_id });
  const inventory = response.reduce(
    (o, p) => {
      const quantity = get(p, 'quantity_available.Quantity');
      return isEmpty(quantity) ? o : {
        ...o,
        [p.ps_part_id]: `${quantity.value} ${quantity.uom}`,
      };
    },
    {}
  );
  return { inventory, inventoryLoaded: true };
};

export async function loadProductSkus(product, loadProduct, skus) {
  let product_skus = keyBy(get(product, 'skus', []), 'sku');
  const missing = difference(skus, keys(product_skus));
  if (missing.length > 0) {
    const response = await loadProduct({ skus: missing });
    product_skus = keyBy(get(response, 'payload.product.skus', []), 'sku');
  }
  return filter(map(skus, (sku) => get(product_skus, [sku])));
};

export async function fetchParts(product, currency_id, initial_skus, offset, v = 1, params={}) {
  const { results, count } = await getParts({
    product: product.ext_product_id, currency: currency_id,
    fields: JSON.stringify({ sku: null, options: null, costs: null, description: null, id: null }),
    v: v,
    ...params,
    // ordering: 'ps_part_id', limit: 20, offset,
  });

  const skus = values({
    ...keyBy(initial_skus, 'id'),
    ...keyBy(results, 'id'),
  });
  return {
    count,
    offset: offset + results.length,
    skus: skus.filter(s => Object.keys(s.costs).length)
  };
};

export function getCostForSku(sku, currency_id = "USD") {
  const costs = map(sku.costs[currency_id] || sku.costs['USD'] || first(values(sku.costs)), (price) => toNumber(price.price));
  const minCost = min(costs);
  const maxCost = max(costs);
  if (typeof minCost !== 'undefined') {
    if (maxCost > minCost) {
      return <span>${formatMoney(minCost)} &ndash; ${formatMoney(maxCost)}</span>;
    }
    return `$${formatMoney(minCost)}`;
  }
  return 'Unknown';
};

export function getOptionsFromSkus(skus = []) {
  const options = {};
  map(skus, (sku) => {
    map(sku.options, ({ option_axis, option_name }) => {
      if (option_axis === 'dimension') {
        return;
      }
      if (!options[option_axis]) {
        options[option_axis] = [];
      }
      options[option_axis].push(option_name);
    });
  });
  map(options, (v, k) => {
    options[k] = uniq(v);
  });

  return options;
};

export function getBasePartsFromSkus(skus = [], currency_id = "USD", inventory = {}, inventoryLoaded = true) {
  const result = chain(skus)
    .map(part => ({
      sku: part.sku,
      description: part.description,
      inventory: inventory[part.sku] || (inventoryLoaded ? 'Unknown' : 'Checking...'),
      cost: getCostForSku(part),
      cost_category: getCostCategory(part, currency_id),
      costs: part.costs[currency_id] || part.costs['USD'] || first(values(part.costs)),
      ...part.options.reduce(
        (o, op) => ({
          ...o,
          [op.option_axis]: op.option_name
        }),
        {}
      )
    }));

  return result;
};

/**
 *
 * @param {ReturnType<typeof getBasePartsFromSkus>} baseParts
 * @returns {Array<object>}
 */
export function getCostCategoriesByBaseParts(baseParts) {
  const costCategories = Object.values(baseParts.value().reduce(
    (c, p) => ({
      ...c,
      [p.cost_category.toString()]: {
        key: p.cost_category.toString(),
        value: p.cost,
        parts: [...(get(c, [p.cost_category.toString(), 'parts']) || []), p]
                .sort((a, b) => {
                  const axes = [];
                  if (a.color) { axes.push('color'); }
                  if (a.size) { axes.push('size'); }
                  if (a.dimension) { axes.push('dimension'); }
                  return axes.concat('sku').map(axis => (axisSort[axis] || defaultSort)(a[axis], b[axis])).reduce((t, s) => t || s, 0);
                }),
      }
    }),
    {}
  )).sort(
    (a, b) => a.key < b.key ? -1 : (b.key < a.key ? 1 : 0)
  );
  if (costCategories.length) {
    costCategories[0].value = <span>{costCategories[0].value} (Lowest cost)</span>;
  }

  return costCategories;
};

/**
 *
 * @param {ReturnType<typeof getBasePartsFromSkus>} baseParts
 * @param {object} options
 * @returns {Array<object>}
 */
export function getPartsByBaseParts(baseParts, options, axes = null) {
  const _axes = axes || Object.keys(options).sort(columnSort);
  return baseParts
    .value()
    .sort((a, b) => _axes.concat('sku').map(axis => (axisSort[axis] || defaultSort)(a[axis], b[axis])).reduce((t, s) => t || s, 0));
};

export function getPartsFromSkus(skus = [], currency_id = "USD", inventory = {}, inventoryLoaded = true) {
  const options = getOptionsFromSkus(skus);
  const baseParts = getBasePartsFromSkus(skus, currency_id, inventory, inventoryLoaded);
  return getPartsByBaseParts(baseParts, options);
};
