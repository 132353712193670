import { Col, colors, CompletedCheckmarkIcon, Csku, fontFamilies, Row, Text } from '@commonsku/styles';
import React from 'react';
import CheckoutPanelEditButton from './CheckoutPanelEditButton';
import CheckoutPanelSaveButton from './CheckoutPanelSaveButton';

const boxStyles = {
  background: colors.neutrals[20],
  padding: 20,
  borderRadius: 4,
};

export default function CheckoutPanel({
  title,
  panel,
  content,
  template_color,
  isOpen=false,
  hasErrors=false,
  borderError=false,
  showEditButton=true,
  showCheckmark=false,
  editBtnInBody=false,
  previewContent=null,
  closeMdSize=6,
  contentClassName='',
  toggleOpenPanel,
  titleStyle={},
  titleWrapperStyle={},
}) {

  const EditBtn = showEditButton ?
    <CheckoutPanelEditButton color={template_color} onClick={() => toggleOpenPanel(panel)} /> :
    null;
  const CompletedIcon = !hasErrors && showCheckmark ?
    <CompletedCheckmarkIcon style={{ verticalAlign: 'text-bottom' }} /> :
    null;

  return (
    <Csku
      as={Row}
      id={`PANEL-${panel}`}
      style={{
        ...boxStyles,
        background: colors.neutrals[isOpen ? 30 : 20],
        marginBottom: 20,
        cursor: isOpen ? 'initial' : 'pointer',
        ...(isOpen ? {border: `2px solid ${template_color}`} : {}),
        ...(borderError ? {border: `2px solid var(--color-errors-main)`} : {}),
        ':hover': {
          ...(!isOpen ? {
            outline: `2px solid ${template_color}`,
            background: colors.neutrals[30],
            ...(borderError ? {outline: `2px solid #fff`} : {}),
          } : {}),
        },
      }}
      onClick={() => !isOpen && toggleOpenPanel(panel)}
    >
      <Col xs={editBtnInBody ? 12 : 10} md={!isOpen && !editBtnInBody ? closeMdSize : 12} style={titleWrapperStyle}>
        <Text style={{
          fontSize: '22px',
          fontFamily: fontFamilies.bold,
          color: 'var(--color-neutrals-100)',
          ...titleStyle,
        }}>
          {title}
          {!isOpen ? <span style={{ paddingLeft: 7 }}>{CompletedIcon}</span> : null}
        </Text>
      </Col>
      {!isOpen ? <>
        {!editBtnInBody ? <Col xs={2} md={12 - closeMdSize} style={{ textAlign: 'right' }}>
          {EditBtn}
        </Col> : null}
        <Col xs md={editBtnInBody ? 9 : 12} style={{wordBreak: 'break-word', }}>{previewContent}</Col>
        {editBtnInBody ? <Col xs md={3}>
          {EditBtn}
        </Col> : null}
      </> : <>
        <Col xs className={contentClassName}>{content}</Col>
        <Col xs style={{ paddingTop: 24 }}>
          <CheckoutPanelSaveButton color={template_color} onClick={() => toggleOpenPanel(panel)} />
        </Col>
      </>}
    </Csku>
  );
}
