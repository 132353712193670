import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import TextArea from 'react-textarea-autosize';

import ProductItemSummary from './BaseItemSummary';
import LabelledCheckbox from './LabelledCheckbox';
import Img from './Img';
import Form from './Form';
import TruncatedText from './TruncatedText';

import { createAddComment, createDeleteComment } from '../actions';

import { getFullOptionItem } from '../selectors';
import { getImageSrc, formatFriendlyDateTime, toTimestamp } from '../utils';

class ProductCommentSummary extends ProductItemSummary {

    constructor(props) {
        super(props);

        this.state = {
            comment: '',
        };

        _.bindAll(this, ['handleChangeComment', 'handleAddComment']);
    }

    handleChangeComment(value) {
        this.setState({ comment: value });
    }

    handleAddComment(item_id, tenant_id, commenter_name, commenter_email, comment) {
        this.props.onAddComment(item_id, tenant_id, commenter_name, commenter_email, comment);
        this.setState({ comment: '' });
    }

    renderItem() {
        const { item, order, locked, company_id, user_name, user_email, onDeleteComment } = this.props;
        const default_image = item.item_images.map(ii => ii.image)[0];

        return (
            <tr>
                <td style={{'padding': '8px 10px 10px 10px'}}>
                    <div className="image">
                        <Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOver('item')} onMouseOut={this.handleMouseOut('item')} />
                    </div>
                    <div className="small-10 columns" style={{ padding: '0px', 'float': 'left' }}>
                        <a className="product-name">{item.item_name}</a>
                        <ul className="presentation-comments" style={{marginLeft: '0px'}}>
                            {item.comments.map(c =>
                                <li key={c.presentation_comment_id}>
                                    <div className="small-8 columns" style={{ padding: '0px' }}>
                                        <label style={{ marginRight: '10px', float: 'left' }}>{c.commenter_name}:</label>
                                        <TruncatedText html={c.comment} limit={200} />
                                    </div>
                                    <div className="small-4 columns" style={{ padding: '0px' }}>
                                        <span style={{ marginLeft: '10px' }}>{formatFriendlyDateTime(toTimestamp(c.date_created))} &nbsp;&nbsp;</span>
                                        <a className="delete"
                                            style={0 == c.rep_comment ? { visibility: 'hidden' } : {}}
                                            onClick={e => { e.stopPropagation(); onDeleteComment(c.presentation_comment_id); }}>
                                            &times;
                                        </a>
                                    </div>
                                </li>
                            )}
                        </ul>
                        <div onContextMenu={e => { e.stopPropagation(); }}>
                            <div className="small-10 columns" style={{padding: '0px', marginTop: '10px'}}>
                                <TextArea
                                    style={{ float: 'left', height: '42px' }}
                                    minRows={2}
                                    value={this.state.comment}
                                    disabled={locked}
                                    onChange={e => this.handleChangeComment(e.target.value)}
                                    onClick={e => { e.stopPropagation(); }}>
                                </TextArea>
                            </div>
                            <div className="small-2 columns" style={{padding: '0px', marginTop: '10px'}}>
                                <button
                                  style={{ height: '42px', marginLeft: '10px' }}
                                  className="button"
                                  onClick={e => {
                                    e.stopPropagation();
                                    if (this.state.comment != '') {
                                      this.handleAddComment(
                                        item.item_id,
                                        company_id,
                                        user_name,
                                        user_email,
                                        this.state.comment
				      );
                                    }
                                  }}
                                >
                                  Comment
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const order = state.entities.orders[ownProps.item.order_id];
    const item = getFullOptionItem(state, { item_id: ownProps.item.item_id });

    return {
        item,
        order,
        locked: 1 == order.locked,
        company_id: state.identity.company_id,
        user_name: state.identity.user_first_name,
        user_email: state.identity.user_email,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddComment: (item_id, tenant_id, commenter_name, commenter_email, comment) => {
            dispatch(createAddComment(item_id, tenant_id, commenter_name, commenter_email, comment));
        },
        onDeleteComment: comment_id => {
            dispatch(createDeleteComment(comment_id));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductCommentSummary);
