import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createLoadOrder } from '../actions/order';
import { formatMoney } from '../utils';

const ProjectMarginSummary = ({ project, order_id, force_subtotal = false }) => {
  const dispatch = useDispatch();
  const { invoices, margin_data } = project;
  const { total_subtotal, total_margin_amount, billed_margin, booked_margin } = (margin_data[order_id] || {});
  const [loadedInvoiceIds, setLoadedInvoiceIds] = useState(invoices.filter(i => i.loaded).map(i => i.order_id));

  useEffect(() => {
    map(invoices, (invoice) => {
      if (!loadedInvoiceIds.includes(invoice.order_id)) {
        setLoadedInvoiceIds(invoiceIds => invoiceIds.concat(invoice.order_id));
        dispatch(createLoadOrder(invoice.order_id));
      }
    });
  }, [invoices]);

  return (
    <div className="large-4 column" style={{ padding: '2px' }}>
      <table className="summary">
        <tbody>
        {project.invoices.length > 1 || force_subtotal ? <tr>
          <td>Subtotal (all invoices)</td>
          <td>${formatMoney(total_subtotal)}</td>
        </tr> : null}
        <tr>
          <td>Billed Margin Amount</td>
          <td>${formatMoney(total_margin_amount)}</td>
        </tr>
        <tr>
          <td>Billed Margin</td>
          <td>{parseFloat(billed_margin).toFixed(2)}%</td>
        </tr>
        <tr>
          <td>Booked Margin</td>
          <td>{parseFloat(booked_margin).toFixed(2)}%</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProjectMarginSummary;
