export const ADD_ITEM_REQUEST = 'ADD_ITEM_REQUEST';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_REDIRECT_ITEM_SUCCESS = 'ADD_REDIRECT_ITEM_SUCCESS';
export const ADD_ITEM_FAILURE = 'ADD_ITEM_FAILURE';
export const ADD_ITEM_CANCEL = 'ADD_ITEM_CANCEL';

export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE';

export const SIMPLE_UPDATE_ITEM_REQUEST = 'SIMPLE_UPDATE_ITEM_REQUEST';
export const SIMPLE_UPDATE_ITEM_SUCCESS = 'SIMPLE_UPDATE_ITEM_SUCCESS';
export const SIMPLE_UPDATE_ITEM_FAILURE = 'SIMPLE_UPDATE_ITEM_FAILURE';

export const COMPLEX_UPDATE_ITEM_REQUEST = 'COMPLEX_UPDATE_ITEM_REQUEST';
export const COMPLEX_UPDATE_ITEM_SUCCESS = 'COMPLEX_UPDATE_ITEM_SUCCESS';
export const COMPLEX_UPDATE_ITEM_FAILURE = 'COMPLEX_UPDATE_ITEM_FAILURE';

export const COMPLEX_UPDATE_BREAKDOWN_REQUEST = 'COMPLEX_UPDATE_BREAKDOWN_REQUEST';
export const COMPLEX_UPDATE_BREAKDOWN_SUCCESS = 'COMPLEX_UPDATE_BREAKDOWN_SUCCESS';
export const COMPLEX_UPDATE_BREAKDOWN_FAILURE = 'COMPLEX_UPDATE_BREAKDOWN_FAILURE';

export const COMPLEX_UPDATE_ITEM_COST_REQUEST = 'COMPLEX_UPDATE_ITEM_COST_REQUEST';
export const COMPLEX_UPDATE_ITEM_COST_SUCCESS = 'COMPLEX_UPDATE_ITEM_COST_SUCCESS';
export const COMPLEX_UPDATE_ITEM_COST_FAILURE = 'COMPLEX_UPDATE_ITEM_COST_FAILURE';

export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';

export const ADD_ITEM_COST_REQUEST = 'ADD_ITEM_COST_REQUEST';
export const ADD_ITEM_COST_SUCCESS = 'ADD_ITEM_COST_SUCCESS';
export const ADD_ITEM_COST_FAILURE = 'ADD_ITEM_COST_FAILURE';

export const UPDATE_ITEM_COST_REQUEST = 'UPDATE_ITEM_COST_REQUEST';
export const UPDATE_ITEM_COST_SUCCESS = 'UPDATE_ITEM_COST_SUCCESS';
export const UPDATE_ITEM_COST_FAILURE = 'UPDATE_ITEM_COST_FAILURE';

export const DELETE_ITEM_COST_REQUEST = 'DELETE_ITEM_COST_REQUEST';
export const DELETE_ITEM_COST_SUCCESS = 'DELETE_ITEM_COST_SUCCESS';
export const DELETE_ITEM_COST_FAILURE = 'DELETE_ITEM_COST_FAILURE';

export const ADD_ITEM_LOCATION_REQUEST = 'ADD_ITEM_LOCATION_REQUEST';
export const ADD_ITEM_LOCATION_SUCCESS = 'ADD_ITEM_LOCATION_SUCCESS';
export const ADD_ITEM_LOCATION_FAILURE = 'ADD_ITEM_LOCATION_FAILURE';

export const UPDATE_ITEM_LOCATION_REQUEST = 'UPDATE_ITEM_LOCATION_REQUEST';
export const UPDATE_ITEM_LOCATION_SUCCESS = 'UPDATE_ITEM_LOCATION_SUCCESS';
export const UPDATE_ITEM_LOCATION_FAILURE = 'UPDATE_ITEM_LOCATION_FAILURE';

export const DELETE_ITEM_LOCATION_REQUEST = 'DELETE_ITEM_LOCATION_REQUEST';
export const DELETE_ITEM_LOCATION_SUCCESS = 'DELETE_ITEM_LOCATION_SUCCESS';
export const DELETE_ITEM_LOCATION_FAILURE = 'DELETE_ITEM_LOCATION_FAILURE';

export const UPDATE_ITEM_DECORATION_REQUEST = 'UPDATE_ITEM_DECORATION_REQUEST';
export const UPDATE_ITEM_DECORATION_SUCCESS = 'UPDATE_ITEM_DECORATION_SUCCESS';
export const UPDATE_ITEM_DECORATION_FAILURE = 'UPDATE_ITEM_DECORATION_FAILURE';

export const ADD_ITEM_BREAKDOWN_REQUEST = 'ADD_ITEM_BREAKDOWN_REQUEST';
export const ADD_ITEM_BREAKDOWN_SUCCESS = 'ADD_ITEM_BREAKDOWN_SUCCESS';
export const ADD_ITEM_BREAKDOWN_FAILURE = 'ADD_ITEM_BREAKDOWN_FAILURE';

export const UPDATE_ITEM_BREAKDOWN_REQUEST = 'UPDATE_ITEM_BREAKDOWN_REQUEST';
export const UPDATE_ITEM_BREAKDOWN_SUCCESS = 'UPDATE_ITEM_BREAKDOWN_SUCCESS';
export const UPDATE_ITEM_BREAKDOWN_FAILURE = 'UPDATE_ITEM_BREAKDOWN_FAILURE';

export const DELETE_ITEM_BREAKDOWN_REQUEST = 'DELETE_ITEM_BREAKDOWN_REQUEST';
export const DELETE_ITEM_BREAKDOWN_SUCCESS = 'DELETE_ITEM_BREAKDOWN_SUCCESS';
export const DELETE_ITEM_BREAKDOWN_FAILURE = 'DELETE_ITEM_BREAKDOWN_FAILURE';

export const ADD_ITEM_TAX_AMOUNT_REQUEST = 'ADD_ITEM_TAX_AMOUNT_REQUEST';
export const ADD_ITEM_TAX_AMOUNT_SUCCESS = 'ADD_ITEM_TAX_AMOUNT_SUCCESS';
export const ADD_ITEM_TAX_AMOUNT_FAILURE = 'ADD_ITEM_TAX_AMOUNT_FAILURE';

export const UPDATE_ITEM_TAX_AMOUNT_REQUEST = 'UPDATE_ITEM_TAX_AMOUNT_REQUEST';
export const UPDATE_ITEM_TAX_AMOUNT_SUCCESS = 'UPDATE_ITEM_TAX_AMOUNT_SUCCESS';
export const UPDATE_ITEM_TAX_AMOUNT_FAILURE = 'UPDATE_ITEM_TAX_AMOUNT_FAILURE';

export const DELETE_ITEM_TAX_AMOUNT_REQUEST = 'DELETE_ITEM_TAX_AMOUNT_REQUEST';
export const DELETE_ITEM_TAX_AMOUNT_SUCCESS = 'DELETE_ITEM_TAX_AMOUNT_SUCCESS';
export const DELETE_ITEM_TAX_AMOUNT_FAILURE = 'DELETE_ITEM_TAX_AMOUNT_FAILURE';

export const COPY_ITEM_REQUEST = 'COPY_ITEM_REQUEST';
export const COPY_ITEM_SUCCESS = 'COPY_ITEM_SUCCESS';
export const COPY_ITEM_FAILURE = 'COPY_ITEM_FAILURE';

export const COPY_ITEM_FROM_COLLECTION_REQUEST = 'COPY_ITEM_FROM_COLLECTION_REQUEST';
export const COPY_ITEM_FROM_COLLECTION_SUCCESS = 'COPY_ITEM_FROM_COLLECTION_SUCCESS';
export const COPY_ITEM_FROM_COLLECTION_FAILURE = 'COPY_ITEM_FROM_COLLECTION_FAILURE';

export const ADD_ITEM_COMMENT_REQUEST = 'ADD_ITEM_COMMENT_REQUEST';
export const ADD_ITEM_COMMENT_SUCCESS = 'ADD_ITEM_COMMENT_SUCCESS';
export const ADD_ITEM_COMMENT_FAILURE = 'ADD_ITEM_COMMENT_FAILURE';

export const DELETE_ITEM_COMMENT_REQUEST = 'DELETE_ITEM_COMMENT_REQUEST';
export const DELETE_ITEM_COMMENT_SUCCESS = 'DELETE_ITEM_COMMENT_SUCCESS';
export const DELETE_ITEM_COMMENT_FAILURE = 'DELETE_ITEM_COMMENT_FAILURE';

export const REORDER_ITEM_REQUEST = 'REORDER_ITEM_REQUEST';
export const REORDER_ITEM_SUCCESS = 'REORDER_ITEM_SUCCESS';
export const REORDER_ITEM_FAILURE = 'REORDER_ITEM_FAILURE';

export const REORDER_ITEM_BREAKDOWN_REQUEST = 'REORDER_ITEM_BREAKDOWN_REQUEST';
export const REORDER_ITEM_BREAKDOWN_SUCCESS = 'REORDER_ITEM_BREAKDOWN_SUCCESS';
export const REORDER_ITEM_BREAKDOWN_FAILURE = 'REORDER_ITEM_BREAKDOWN_FAILURE';

export const REORDER_ITEM_COST_REQUEST = 'REORDER_ITEM_COST_REQUEST';
export const REORDER_ITEM_COST_SUCCESS = 'REORDER_ITEM_COST_SUCCESS';
export const REORDER_ITEM_COST_FAILURE = 'REORDER_ITEM_COST_FAILURE';

export const REORDER_ITEM_LOCATION_REQUEST = 'REORDER_ITEM_LOCATION_REQUEST';
export const REORDER_ITEM_LOCATION_SUCCESS = 'REORDER_ITEM_LOCATION_SUCCESS';
export const REORDER_ITEM_LOCATION_FAILURE = 'REORDER_ITEM_LOCATION_FAILURE';

export const ADD_BREAKDOWN_COLOR_REQUEST = 'ADD_BREAKDOWN_COLOR_REQUEST';
export const ADD_BREAKDOWN_COLOR_SUCCESS = 'ADD_BREAKDOWN_COLOR_SUCCESS';
export const ADD_BREAKDOWN_COLOR_FAILURE = 'ADD_BREAKDOWN_COLOR_FAILURE';

export const ADD_BREAKDOWN_SIZE_REQUEST = 'ADD_BREAKDOWN_SIZE_REQUEST';
export const ADD_BREAKDOWN_SIZE_SUCCESS = 'ADD_BREAKDOWN_SIZE_SUCCESS';
export const ADD_BREAKDOWN_SIZE_FAILURE = 'ADD_BREAKDOWN_SIZE_FAILURE';

export const ADD_ITEM_COLOR_REQUEST = 'ADD_ITEM_COLOR_REQUEST';
export const ADD_ITEM_COLOR_SUCCESS = 'ADD_ITEM_COLOR_SUCCESS';
export const ADD_ITEM_COLOR_FAILURE = 'ADD_ITEM_COLOR_FAILURE';

export const ADD_ITEM_SIZE_REQUEST = 'ADD_ITEM_SIZE_REQUEST';
export const ADD_ITEM_SIZE_SUCCESS = 'ADD_ITEM_SIZE_SUCCESS';
export const ADD_ITEM_SIZE_FAILURE = 'ADD_ITEM_SIZE_FAILURE';

export const ADD_ITEM_SKU_REQUEST = 'ADD_ITEM_SKU_REQUEST';
export const ADD_ITEM_SKU_SUCCESS = 'ADD_ITEM_SKU_SUCCESS';
export const ADD_ITEM_SKU_FAILURE = 'ADD_ITEM_SKU_FAILURE';

export const DELETE_ITEM_COLOR_REQUEST = 'DELETE_ITEM_COLOR_REQUEST';
export const DELETE_ITEM_COLOR_SUCCESS = 'DELETE_ITEM_COLOR_SUCCESS';
export const DELETE_ITEM_COLOR_FAILURE = 'DELETE_ITEM_COLOR_FAILURE';

export const DELETE_ITEM_SIZE_REQUEST = 'DELETE_ITEM_SIZE_REQUEST';
export const DELETE_ITEM_SIZE_SUCCESS = 'DELETE_ITEM_SIZE_SUCCESS';
export const DELETE_ITEM_SIZE_FAILURE = 'DELETE_ITEM_SIZE_FAILURE';

export const DELETE_ITEM_SKU_REQUEST = 'DELETE_ITEM_SKU_REQUEST';
export const DELETE_ITEM_SKU_SUCCESS = 'DELETE_ITEM_SKU_SUCCESS';
export const DELETE_ITEM_SKU_FAILURE = 'DELETE_ITEM_SKU_FAILURE';

export const ADD_ITEM_IMAGE_REQUEST = 'ADD_ITEM_IMAGE_REQUEST';
export const ADD_ITEM_IMAGE_SUCCESS = 'ADD_ITEM_IMAGE_SUCCESS';
export const ADD_ITEM_IMAGE_FAILURE = 'ADD_ITEM_IMAGE_FAILURE';

export const UPDATE_ITEM_IMAGE_REQUEST = 'UPDATE_ITEM_IMAGE_REQUEST';
export const UPDATE_ITEM_IMAGE_SUCCESS = 'UPDATE_ITEM_IMAGE_SUCCESS';
export const UPDATE_ITEM_IMAGE_FAILURE = 'UPDATE_ITEM_IMAGE_FAILURE';

export const DELETE_ITEM_IMAGE_REQUEST = 'DELETE_ITEM_IMAGE_REQUEST';
export const DELETE_ITEM_IMAGE_SUCCESS = 'DELETE_ITEM_IMAGE_SUCCESS';
export const DELETE_ITEM_IMAGE_FAILURE = 'DELETE_ITEM_IMAGE_FAILURE';

export const UPDATE_PRODUCT_WARNINGS_REQUEST = 'UPDATE_PRODUCT_WARNINGS_REQUEST';
export const UPDATE_PRODUCT_WARNINGS_SUCCESS = 'UPDATE_PRODUCT_WARNINGS_SUCCESS';
export const UPDATE_PRODUCT_WARNINGS_FAILURE = 'UPDATE_PRODUCT_WARNINGS_FAILURE';

export const ADD_ITEM_WARNING_REQUEST = 'ADD_ITEM_WARNING_REQUEST';
export const ADD_ITEM_WARNING_SUCCESS = 'ADD_ITEM_WARNING_SUCCESS';
export const ADD_ITEM_WARNING_FAILURE = 'ADD_ITEM_WARNING_FAILURE';

export const UPDATE_ITEM_WARNING_REQUEST = 'UPDATE_ITEM_WARNING_REQUEST';
export const UPDATE_ITEM_WARNING_SUCCESS = 'UPDATE_ITEM_WARNING_SUCCESS';
export const UPDATE_ITEM_WARNING_FAILURE = 'UPDATE_ITEM_WARNING_FAILURE';

export const DELETE_ITEM_WARNING_REQUEST = 'DELETE_ITEM_WARNING_REQUEST';
export const DELETE_ITEM_WARNING_SUCCESS = 'DELETE_ITEM_WARNING_SUCCESS';
export const DELETE_ITEM_WARNING_FAILURE = 'DELETE_ITEM_WARNING_FAILURE';

export const ADD_ITEM_RETAIL_ADJUSTMENT_REQUEST = 'ADD_ITEM_RETAIL_ADJUSTMENT_REQUEST';
export const ADD_ITEM_RETAIL_ADJUSTMENT_SUCCESS = 'ADD_ITEM_RETAIL_ADJUSTMENT_SUCCESS';
export const ADD_ITEM_RETAIL_ADJUSTMENT_FAILURE = 'ADD_ITEM_RETAIL_ADJUSTMENT_FAILURE';

export const UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST = 'UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST';
export const UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS = 'UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS';
export const UPDATE_ITEM_RETAIL_ADJUSTMENT_FAILURE = 'UPDATE_ITEM_RETAIL_ADJUSTMENT_FAILURE';

export const DELETE_ITEM_RETAIL_ADJUSTMENT_REQUEST = 'DELETE_ITEM_RETAIL_ADJUSTMENT_REQUEST';
export const DELETE_ITEM_RETAIL_ADJUSTMENT_SUCCESS = 'DELETE_ITEM_RETAIL_ADJUSTMENT_SUCCESS';
export const DELETE_ITEM_RETAIL_ADJUSTMENT_FAILURE = 'DELETE_ITEM_RETAIL_ADJUSTMENT_FAILURE';

export const CHECK_ITEM_IN_COLLECTION_REQUEST = 'CHECK_ITEM_IN_COLLECTION_REQUEST';
export const CHECK_ITEM_IN_COLLECTION_SUCCESS = 'CHECK_ITEM_IN_COLLECTION_SUCCESS';
export const CHECK_ITEM_IN_COLLECTION_FAILURE = 'CHECK_ITEM_IN_COLLECTION_FAILURE';

export const UPDATE_POPUP_IMAGE_REQUEST = 'UPDATE_POPUP_IMAGE_REQUEST';
export const UPDATE_POPUP_IMAGE_SUCCESS = 'UPDATE_POPUP_IMAGE_SUCCESS';
export const UPDATE_POPUP_IMAGE_FAILURE = 'UPDATE_POPUP_IMAGE_FAILURE';
