import _ from 'lodash';
import { oauth } from '../utils';
import { window } from '../global';

export const FETCH_REMINDERS_SUCCESS = 'FETCH_REMINDERS_SUCCESS';
export const createFetchReminders = () => dispatch => {
  oauth('INDEX', 'note', {
    'max-results': 10, 'note_type': 'REMINDER'
  }).then(({json}) => {
    dispatch({
      type: FETCH_REMINDERS_SUCCESS,
      payload: json.notes,
    });
  });
};

export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export const createUpdateReminder = (reminder, data) => dispatch => {
  oauth('PUT', 'note/' + reminder.note_id, data).then(({json}) => {
    dispatch({
      type: UPDATE_REMINDER_SUCCESS,
      payload: json.note,
    });
  });
};

export const dismissNotification = ({
  notification_id,
  parent_id,
  user_id,
  parent_type,
}) => dispatch => {
  oauth('PUT', 'notification/' + notification_id, {
    parent_id,
    user_id,
  }).then(({json}) => {
    if (!_.startsWith(parent_type, '/')) {
      parent_type = '/' + parent_type;
    }
    window.location = parent_type.toLowerCase() + ".php?id=" + parent_id;
  });
};

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const clearNotifications= (user_id) => dispatch => {
  oauth('PUT', 'notification/clear', {
    user_id,
  }).then(({json}) => {
    return dispatch({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications: [],
        active: 0,
      },
    });
  });
};
export const createFetchNotifications = (user_id) => dispatch => {
  oauth('GET', 'notification', { user_id, }).then(({json}) => {
    return dispatch({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: json,
    });
  });
};

export const FETCH_RECENT_ORDERS_SUCCESS = 'FETCH_RECENT_ORDERS_SUCCESS';
export const createFetchRecentOrders = () => dispatch => {
  return oauth('GET', 'order', {
    'current-sales-rep-recent': true,
  }).then(({json}) => {
    return dispatch({
      type: FETCH_RECENT_ORDERS_SUCCESS,
      payload: json.orders,
    });
  });
};

export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const createDeleteBookmarkRequest = (bookmarkId) => dispatch => {
  oauth('DELETE', 'bookmark/' + bookmarkId, {}).then(({json}) => {
    return dispatch({
      type: DELETE_BOOKMARK_SUCCESS,
      payload: bookmarkId,
    });
  });
};
