import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProjectMarginSummary from './ProjectMarginSummary';

import { getCommissionsBySalesOrder } from '../selectors';
import { formatMoney } from '../utils';
import { createCommissionPopup } from '../actions/popup';
import { createLoadOrder } from '../actions/order';
import { createDeleteCommission, createLoadCommissions, createRemoveEstimatedCommissions } from '../actions/commission';

const CommissionExplanation = ({ commission }) => {
  const title = commission.estimated ? 'BOOKED MARGIN EXPLANATION' : 'CURRENT BILLED MARGIN EXPLANATION';
  return (
    <div className="commission-details">
      <h4>{title}</h4>
      {'SUBTOTAL' === commission.commission_source ?
        <p>The order subtotal is <strong>${formatMoney(commission.base_amount)}</strong>.</p> :
        <p>The order margin amount is <strong>${formatMoney(commission.base_amount)}</strong>.</p>}
      <p>{commission.user_name}'s base commission as <strong>{'ORDER' === commission.commission_type ? 'Order Rep' : ('CLIENT' === commission.commission_type ? 'Client Rep' : 'Order and Client Rep')}</strong> is {commission.base_rate}%.</p>
      {commission.margin || commission.margin_multiplier != 1 ?
        <p>
          The margin is <strong>{parseFloat(commission.margin).toFixed(0)}%</strong>.
          <br />
          The margin multiplier is &times;{commission.margin_multiplier}.
        </p> : null}
      {'ORDER' !== commission.commission_type ?
        <p>
          {commission.user_name}'s year to date sales are <strong>${formatMoney(commission.sales_ytd)}</strong>.
          <br />
          The sales goal multiplier for this amount is &times;{commission.sales_target_multiplier}.
        </p> : null}
      {commission.client_rate || commission.client_multiplier != 1 ?
        <p>
          The client multiplier for this client is &times;{commission.client_multiplier}.
        </p> : null}
      <p>
        {commission.user_name}'s commission = ${formatMoney(commission.base_amount)}
          &times; {commission.base_rate}% &times; {commission.margin_multiplier} &times; {'ORDER' !== commission.commission_type ? <span>{commission.sales_target_multiplier} &times;</span> : null } {commission.client_multiplier}
      </p>
      <p>
        = ${formatMoney(commission.initial_amount)}
      </p>
      {formatMoney(commission.initial_amount) !== formatMoney(commission.commission_amount) ?
        (1 == commission.commission_override ?
          <p>Commission manually adjusted; the final commission is ${formatMoney(commission.commission_amount)}</p> :
          <p>Modified by commission threshold; the final commission is ${formatMoney(commission.commission_amount)}</p>) : null}
    </div>
  );
};

class CommissionRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      has_tooltip: false
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
    this.setState({ has_tooltip: 1 == this.props.commission.has_breakdown });
  }

  handleMouseOut() {
    this.setState({ has_tooltip: false });
  }

  render() {
    const {commission, onEditCommission, onDeleteCommission, hasCapability} = this.props;
    const commission_type = 'ORDER' === commission.commission_type ? 'Order Rep' : ('CLIENT' === commission.commission_type ? 'Client Rep' : 'Client + Order Rep');
    const style = 1 == commission.commission_override ? { color: 'red' } : {};

    return (
      <tr onClick={e => { e.preventDefault(); if (hasCapability('MODIFY-COMMISSION') && !commission.estimated) { onEditCommission(commission); } }} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} style={style}>
        <td>{hasCapability('DELETE-COMMISSION') && !commission.estimated ? <a className="delete" onClick={e => { e.preventDefault(); e.stopPropagation(); onDeleteCommission(commission.commission_id); }}>&times;</a> : null}{this.state.has_tooltip ? <CommissionExplanation commission={commission} /> : null}</td>
        <td>{commission.user_name}</td>
        <td>{commission_type}</td>
        <td>{parseFloat(commission.commission_rate).toFixed(2)}% of {'MARGIN' === commission.commission_source ? 'Margin' : 'Total'}</td>
        <td>{commission.estimated ? 'Est. ' : null}${formatMoney(commission.commission_amount)}</td>
        <td>{parseFloat(commission.commission_paid) ? commission.date_commission_paid : 'No' }</td>
        <td>{commission.commission_notes ? commission.commission_notes : 'None'}</td>
      </tr>
    );
  }
}

class ProjectCommissions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.project.loaded) {
      this.props.loadOrder(this.props.project.sales_orders[0].order_id);
    }

    this.props.loadCommissions().then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.removeEstimatedCommissions();
  }

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}} >
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  render() {
    const { project } = this.props;
    const { loading } = this.state;

    if (!project.loaded || loading) {
      return this.renderLoading();
    }

    const { commissions_by_sales_order, onEditCommission, onDeleteCommission, hasCapability } = this.props;
    return (
      <div className="main-content">
      {commissions_by_sales_order.map(i =>
        <div key={i.order_id}>
          <div className="small-12 columns">
            <h3>Commissions for Sales Order #{i.form_number}</h3>
          </div>

          <div className="small-12 columns">
            <table className="commission-table">
              <thead>
                <tr>
                  <th colSpan="2">Rep</th>
                  <th>Type</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
              {i.commissions.map(c =>
                <CommissionRow key={c.commission_id} commission={c} hasCapability={hasCapability} onEditCommission={onEditCommission} onDeleteCommission={onDeleteCommission} />
              )}
              </tbody>
            </table>
          </div>
          <ProjectMarginSummary project={project} order_id={i.order_id} force_subtotal={true} />
          <div className="large-8 columns">&nbsp;</div>
        </div>
      )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const loaded = ownProps.project.sales_orders.reduce((t, o) => t && o.loaded, true) && ownProps.project.invoices.reduce((t, o) => t && o.loaded, true);
  const project = loaded ? Object.assign({ loaded }, ownProps.project) :
    ownProps.project.sales_orders[0].loaded ? Object.assign(ownProps.project, {
      loaded: true,
      margin_data: {
        [ownProps.project.sales_orders[0].order_id]: ownProps.project.sales_orders[0].margin_data
      }
    }) : Object.assign({ loaded: false }, ownProps.project);
  return {
    project,
    hasCapability: capability => state.identity.capabilities.includes(capability),
    commissions_by_sales_order: getCommissionsBySalesOrder(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    loadCommissions: () => dispatch(createLoadCommissions(ownProps.project.job_id)),
    removeEstimatedCommissions: () => dispatch(createRemoveEstimatedCommissions()),
    onEditCommission: commission => {
      dispatch(createCommissionPopup(commission));
    },
    onDeleteCommission: commission_id => {
      dispatch(createDeleteCommission(commission_id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCommissions);
