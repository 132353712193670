import { Select, colors } from '@commonsku/styles';
import { useMenu } from 'react-instantsearch';

function CustomMenuSelect({ defaultLabel, divisions = [], ...props }) {
  const { items, refine } = useMenu(props);
  const { value: selectedValue } = items.find((item) => item.isRefined) || {
    value: '',
  };

  const getValue = (value) => {
    const division = divisions[value];
    return division ? division : value;
  };

  const options = [
    { value: '', label: defaultLabel },
    ...items.map((item) => ({
      value: item.value,
      label: `${getValue(item.value)} (${item.count})`,
    })),
  ];

  return (
    <Select
      controlStyles={{ background: 'transparent', border: 'none', boxShadow: 'none', justifyContent: 'flex-start' }}
      valueContainerStyles={{ flex: 'none' }}
      singleValueStyles={{ fontSize: 14, fontWeight: '600', color: colors.primary1['65'] }}
      menuStyles={{ borderRadius: 5 }}
      value={options.find(option => option.value === selectedValue)}
      onChange={(event) => {
        refine(event.value);
      }}
      options={options}
    />
  );
}

export default CustomMenuSelect;