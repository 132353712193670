import { differenceBy } from 'lodash';
import React, { Component, useEffect, useState } from 'react';

import { Col, Loading, Row } from '@commonsku/styles';
import { oauth, makeCancelable, memoizeOAuthGet, getIdentityUtils } from '../../utils';
import Product from '../Product';
import ProductPreviousOrder from './ProductPreviousOrder';
import { useIdentity } from '../../hooks';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import NewProduct from '../NewProduct';
import { find, some } from 'lodash';

export const PAGE_SIZE = 32;

const StyledTip = styled.div`
  && {
    width: 100%;
    height: 43px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #899CA9;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }
`;

const SageConnectProductsHelp = () => {
  const [sageConnectSearch, setSageConnectSearch] = useState(null);

  useEffect(() => {
    memoizeOAuthGet('tenant/has-sage-connect-service', {service: 'search'}).then((response) => {
      setSageConnectSearch(response.json.has_sage_connect_service);
    });
  }, []);

  const baseStyle = {
    textAlign: 'left'
  };

  return (
    <div className="column tip">
      <img src="/images/logo-sage.png" className="source-logo" style={{'height': '66px'}} />
      {
        sageConnectSearch === null ? <Loading/> : (
          !sageConnectSearch
            ? <div style={baseStyle}>SAGE Connect hasn't been configured yet.</div>
            : <>
              <div style={baseStyle}>
                Products in the SAGE Connect tab are displayed directly from the SAGE product database. Use this integrated feature with advanced search options to source, then bookmark, tag, or add products to your projects or shops.
              </div>
              <br />
              <div style={baseStyle}>
                Enter some criteria to search for products.
              </div>
              <br />
              <div style={baseStyle}>
                <strong>NOTE:</strong> SAGE product push and pull functionality is available via the SAGE search tab
              </div>
              <br />
            </>
        )
      }
      <div style={baseStyle}>
        For more information click <a href="https://help.commonsku.com/knowledge/sage" target="_blank">here</a>
      </div>
    </div>
  );
};

const SageProductsHelp = () => {
  const identity = useIdentity();
  const baseStyle = {
    textAlign: 'left'
  };

  return <div className="column tip">
    <img src="/images/logo-sage.png" className="source-logo" style={{'height': '66px'}} />
    {
      !identity.has_sage
      ? <>
        <div style={baseStyle}>SAGE account hasn't been configured yet.</div>
        <div>For more information click <a href="https://help.commonsku.com/knowledge/sage" target="_blank">here</a></div>
      </>
      : <>
        <div style={baseStyle}>SAGE products can be pushed or pulled from the SAGE product database in this search tab. Once added, these products are stored in your account and searchable within this tab to bookmark, tag, or add products to your projects or shops.</div>
        <br />
        <div style={baseStyle}>Push products from SAGE to commonsku:</div>
        <ul style={{ marginLeft: '30px', ...baseStyle, }}>
          <li>Open SAGE Web and search for the product(s) {`"Actions" > "Push Tagged Products to commonsku"`}</li>
          <li>Select the check box in the top left corner of the product(s)</li>
          <li>Click {`"Actions" > "Push Tagged Products to commonsku"`}</li>
        </ul>
        <br />
        <div style={baseStyle}>Pull products from SAGE to commonsku:</div>
        <ul style={{ marginLeft: '30px', ...baseStyle, }}>
          <li>Click &#43; Add &gt; Product</li>
          <li>Select the SAGE database</li>
          <li>Type the product's SKU number into the "Search Term" field and select the supplier from the Supplier drop-down or enter the SPC in the "Search Term" field</li>
          <li>Click Search</li>
          <li>Select the product(s) you'd like to add to your form; the product's background will turn blue once it's added to the form</li>
        </ul>
        <br />
        <div style={baseStyle}><strong>NEW:</strong> Enable the SAGE Connect integration to begin searching and displaying products from the SAGE database and adding them to projects and shops directly in commonsku. <a href="https://help.commonsku.com/knowledge/sage" target="_blank">Learn more</a></div>
      </>
    }
  </div>;
};

const EspProductsHelp = () => {
  const identity = useIdentity();
  return <div className="column tip">
    <img src="/images/logo-esp.gif" className="source-logo" style={{'float' : 'right'}} />
    {
      !identity.has_esp
      ? <>
        <div>ESP account hasn't been configured yet.</div>
        <div>For more information click <a href="https://help.commonsku.com/knowledge/esp" target="_blank">here</a></div>
      </>
      : <>
        <p>ASI/ESP products are displayed directly from the ASI product database.  Use our integrated search feature to source, then bookmark or add products onto your orders.</p>
        <p>Enter some criteria to search for products.</p>
      </>
    }
  </div>;
};


/* ProductSearchFilter serves as a base class for specific search filter types */

class ProductSearchFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: this.getInitialSearch(),
      products: [],
      total_products: -1,
      page: 0,
      loading: false,
      addedProducts: []
    };

    this.active_searches = [];

    [
      'handleScroll',
      'handlePressEnter',
      'handleClickReset',
      'handleClickSearch',
      'handleChangeSearchTerm',
      'handleChangeNetMin',
      'handleChangeNetMax',
      'handleFilterSearch',
      'onChangeDivision',
      'onSearchResult',
      'onSearch',
      'removeSageProduct',
      'onErrorResponse',
      'handleLoadNextPage',
      'onAddSelectedProduct',
      'handleClickProductAction',
    ].forEach(f => this[f] = this[f].bind(this));
  }

  getProductSource() {
    return;
  }

  isProductSageConnect() {
    return '0';
  }

  componentWillUnmount() {
    this.active_searches.forEach(x => x.cancel());
  }

  handleClickSearch(e, order_type, ignore_id) {
    e.preventDefault();
    this.onSearch(0, true, null, null, order_type, ignore_id);
  }

  handleFilterSearch(field, options, suppliers_dimensions) {
    this.onSearch(this.state.page, true, field, options, null, null, suppliers_dimensions);
  }

  onSearch(page, clear, field, options, order_type, ignore_id, suppliers_dimensions) {
    if (this.props.onSearch) {
      this.props.onSearch();
    }
    this.props.clearNewProduct();
    const endpoint = this.getEndpoint();
    const search_terms = this.getSearchTerms(page);

    if (['PRESENTATION', 'ESTIMATE', 'SALES ORDER', 'INVOICE'].includes(order_type)) {
      search_terms.order_type = order_type;
    }

    if (field && options && Object.keys(search_terms).indexOf(field) == -1) {
      search_terms[field] = options;
    }

    if (clear) {
      search_terms['start-index'] = 0;
      this.setState({
        loading: true,
        products: [],
        total_products: -1,
        page: 0
      });
    }else{
      this.setState({ loading: true });
    }

    let return_search_terms = { ...search_terms };
    if (suppliers_dimensions) {
      return_search_terms.suppliers_dimensions = suppliers_dimensions;
    }
    if (search_terms.esp_sort) {
      return_search_terms.previous_esp_sort = search_terms.esp_sort;
    }
    if (search_terms.sage_sort) {
      return_search_terms.previous_sage_sort = search_terms.sage_sort;
    }
    if (search_terms['start-index'] == 0) {
      delete search_terms['start-index'];
    }

    // we also should post the price value back to backend
    if (this.state.selectedPriceName) {
      search_terms['price_value'] = this.state.selectedPriceName;
    }

    const search = makeCancelable(oauth('GET', endpoint, search_terms));
    this.active_searches.push(search);
    search.promise.then(
      ({ json }) => {
        this.onSearchResult(page, json, return_search_terms, field, options);
      },
      ({ json }) => {
        console.log(json);
        this.onErrorResponse(json);
      }
    );
  }

  onErrorResponse(json) {
    // Override
    if (json && json.error) {
      toast.error(json.exception || json.error, {
        autoClose: 6000,
      });
    }
    this.setState({ loading: false });
  }

  handlePressEnter(e, order_type, ignore_id) {
    if ('Enter' === e.key) {
      this.onSearch(this.state.page, true, null, null, order_type, ignore_id);
    }
  }

  handleScroll(event) {
    if (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <= 100) {
      this.handleLoadNextPage();
    }
  }

  handleLoadNextPage() {
    if (this.state.total_products && this.state.products.length < this.state.total_products && !this.state.loading) {
      this.onSearch(this.state.page + 1);
    }
  }

  onAddSelectedProduct(product) {
    this.setState({
      addedProducts: [...this.state.addedProducts, product]
    });
  }

  handleClickProductAction = async (product, onAddProduct) => {
    const { addedProducts } = this.state;
    const added = find(addedProducts, { productId: product.product_id });
    if (!added) {
      const item = await onAddProduct(product.product_id, product.product_supplier_code, product.division_id, product.currency_id);
      this.onAddSelectedProduct({ 'itemId': item.item_id, 'productId': product.product_id });
    } else {
      const { itemId } = added;
      this.props.onDeleteItem(itemId);
      this.setState({
        addedProducts: differenceBy(addedProducts, [{ itemId }], 'itemId')
      });
    }
  };

  onSearchResult(page, response, search_terms, field, options) {
    const products = page > 0 ? this.state.products.concat(response.products) : response.products;
    const dimensions = response.dimensions ? response.dimensions : null;
    const suppliers_dimensions = dimensions && dimensions.Suppliers ?
      Object.values(response.dimensions.Suppliers).map(d => ({ key: d.ContextPath, value: `${d.Name} (${d.Products})`})) :
      search_terms.suppliers_dimensions ?
      search_terms.suppliers_dimensions :
      null;
    const selectedCategory = search_terms.category ? search_terms.category : null;
    const selectedColor = search_terms.color ? search_terms.color : null;
    const selectedMaterial = search_terms.material ? search_terms.material : null;
    const selectedPrice = search_terms.price ? search_terms.price : null;
    const selectedSize = search_terms.size ? search_terms.size : null;
    const selectedTheme = search_terms.theme ? search_terms.theme : null;
    const selectedSupplierState = search_terms.supplier_state ? search_terms.supplier_state : null;
    const selectedShape = search_terms.shape ? search_terms.shape : null;
    const selectedRating = search_terms.supplier_rating ? search_terms.supplier_rating : null;
    const previous_esp_sort = search_terms.esp_sort ? search_terms.previous_esp_sort : null;
    const previous_sage_sort = search_terms.sage_sort ? search_terms.previous_sage_sort : null;

    this.setState({
      page,
      products,
      total_products: response.total_products,
      loading: false,
      dimensions,
      selectedCategory,
      selectedColor,
      selectedMaterial,
      selectedPrice,
      selectedSize,
      selectedTheme,
      selectedSupplierState,
      selectedShape,
      selectedRating,
      suppliers_dimensions,
      previous_esp_sort,
      previous_sage_sort,
      [field]: options
    });

    this.props.getTotalProducts(response.total_products);
  }

  onChangeDivision(division_id, params) {
    this.setState({ search: { ...this.state.search, division_id }});
    this.props.handleStoringSearch(params, division_id);
  }

  handleClickReset(e) {
    e.preventDefault();
    if (this.props.onClear) {
      this.props.onClear();
    }
    this.setState({
      dimensions: null,
      division_id: '',
      search: this.getInitialSearch('reset'),
      products: [],
      total_products: -1,
      page: 0,
      selectedCategory: null,
      selectedColor: null,
      selectedMaterial: null,
      selectedPrice: null,
      selectedSize: null,
      selectedTheme: null,
      selectedShape: null,
      selectedRating: null,
      suppliers_dimensions: null,
      preferred: null,
      production_time: null,
      quantity: null,
      has_rush_service: null,
      is_canadian_priced: null,
      is_canadian_friendly: null,
      is_new: null,
      is_made_in_usa: null,
      is_full_color_process: null,
      suppliers_products_count: null
    }, () => this.props.handleClearingSearch());
  }

  handleChangeSearchTerm(e, params) {
    const search_term = e.target.value;
    if (params == 'esp') {
      this.setState({
        search: { ...this.state.search, search_term },
        selectedCategory: null,
        selectedColor: null,
        selectedMaterial: null,
        selectedPrice: null,
        selectedSize: null,
        selectedTheme: null,
        selectedShape: null,
        selectedRating: null,
      });

      if (e.target && e.target.value) {
        this.props.handleStoringSearch('search_term', e.target.value);
      }
    } else {
      this.setState({
        search: { ...this.state.search, search_term }
      });

      if (e.target && e.target.value) {
        this.props.handleStoringSearch('search_term', e.target.value);
      }
    }
  }

  handleChangeNetMin(e) {
    const net_min = e.target.value;
    this.setState({ search: { ...this.state.search, net_min }});
    this.props.handleStoringSearch('net_min', e.target.value);
  }

  handleChangeNetMax(e) {
    const net_max = e.target.value;
    this.setState({ search: { ...this.state.search, net_max }});
    this.props.handleStoringSearch('net_max', e.target.value);
  }

  renderPersonalProducts() {
    return (
      <div className="column tip">
        <p>Your {this.props.company_name} database contains products that you or your co-workers have added directly into commonsku. These products will often be {this.props.company_name}'s go-to products, preferred supplier products, or local decorators that may not be on commonsku otherwise.</p>
        <p>Enter some criteria to search for products.</p>
      </div>
    );
  }

  renderCompanyProducts() {
    return (
      <div className="column tip">
        <p>Your {this.props.company_name} database contains products that you or your co-workers have added directly into commonsku.</p>
        <p>Enter some criteria to search for products.</p>
      </div>
    );
  }

  renderDistributorCentralProducts() {
    return (
      <div className="column tip">
        <img src="/images/logo-dc.png" className="source-logo" />
        <p>DistributorCentral products are displayed directly from the DistributorCentral product database. Use the free integrated search feature to source, then bookmark or add products onto your orders.</p>
        <p>Enter some criteria to search for products.</p>
      </div>
    );
  }

  renderBookmarkedProducts() {
    return (
      <div className="column tip">
        <p>Bookmark products to quickly add them to orders. You can also access your bookmarks from the Bookmarks menu at the top right.</p>
      </div>
    );
  }

  renderTaggedProducts() {
    return (
      <div className="column tip">
        <p>Search for tagged products.</p>
      </div>
    );
  }

  renderPreviousOrderProducts() {
    return (
      <div className="column tip">
        <p>Add a product (and all associated information including; breakdowns, pricing and decoration) from a previously created sales order.</p>
        <p>Note: If the product is on a sales order for the same client as the original then the artwork will also be copied.</p>
      </div>
    );
  }

  renderConnectedPlusProducts() {
    return (
      <StyledTip>Search your own Connected+ products</StyledTip>
    );
  }

  renderNoProductsFound() {
    return (
      <div className="small-12 columns">There are no products matching your search criteria.</div>
    );
  }

  renderAllPreviousOrderProducts() {
    const { index, loaded, loading, order_id, order_type, onAddProduct } = this.props;
    const { products } = this.state;
    return (
      <>
        {
          products.map((p, idx) =>
            <ProductPreviousOrder
              key={idx}
              className={idx === products.length - 1 ? 'end' : ''}
              product={p}
              loading={loading.includes(p.item_id)}
              loaded={loaded.includes(p.item_id)}
              onAddProduct={onAddProduct(order_id, order_type, null, 'COPY', index, this.isProductSageConnect())}
            />
          )
        }
      </>
    );
  }

  renderAllDefaultProducts() {
    const { index, loaded, loading, order_id, order_type, onAddProduct, isProductsPage, supplier_popup, isConnectedPlus } = this.props;
    const { products, addedProducts } = this.state;

    return (
      <>
        {products.map((p, idx) =>
          isConnectedPlus ?
            <NewProduct
              key={idx}
              className={idx === products.length - 1 ? 'end' : ''}
              product={p}
              title={p.product_name}
              sku={p.product_supplier_code}
              subtitle={p.division_name}
              loading={loading.includes(p.product_id)}
              loaded={find(addedProducts, { productId: p.product_id })}
              onClickProductAction={() => {
                if (loading.includes(p.product_id)) return;
                this.handleClickProductAction(p, onAddProduct(order_id, order_type, this.getProductSource(), null, index, this.isProductSageConnect()));
              }}
              onClickProduct={this.onClickProduct}
              isProductsPage={isProductsPage}
              supplier_popup={supplier_popup}
            />
            :
            <Product
              key={idx}
              className={idx === products.length - 1 ? 'end' : ''}
              product={p}
              loading={loading.includes(p.product_id)}
              loaded={loaded.includes('' + p.product_id)}
              onAddProduct={onAddProduct(order_id, order_type, this.getProductSource(), null, index, this.isProductSageConnect())}
              isProductsPage={isProductsPage}
              supplier_popup={supplier_popup}
              removeSageProduct={this.removeSageProduct}
            />
        )}
      </>
    );
  }

  removeSageProduct(product_id) {
    oauth('DELETE', `sage-product/${product_id}`, {}).then((response) => {
      this.setState({ products: this.state.products.filter(p => p.product_id !== product_id) });
    });
  }

  renderProducts(params) {
    const { onAddProduct, order_id, order_type, loading, loaded, index } = this.props;
    if (this.state.total_products >= 0) {
      if (this.state.products.length) {
        if ('previous_order' === params) {
          return this.renderAllPreviousOrderProducts();
        } else {
          return this.renderAllDefaultProducts();
        }
      } else {
        this.renderNoProductsFound();
      }
    }
    switch (params) {
      case 'personal':
        return this.renderPersonalProducts();
      case 'company':
        return this.renderCompanyProducts();
      case 'dc':
        return this.renderDistributorCentralProducts();
      case 'esp':
        return <EspProductsHelp/>;
      case 'sage':
        return <SageProductsHelp/>;
      case 'sage-connect':
        return <SageConnectProductsHelp/>;
      case 'bookmark':
        return this.renderBookmarkedProducts();
      case 'tagged':
        return this.renderTaggedProducts();
      case 'previous_order':
        return this.renderPreviousOrderProducts();
      case 'promostandards':
        return this.renderConnectedPlusProducts();
    }
  }

  renderLoading() {
    if (this.state.loading) {
      return (
        <Col xs={12} className="row full-width text-center">
          <Loading mt={16} mb={16} />
        </Col>
      );
    }
  }
}

export default ProductSearchFilter;
