import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/item';
import { createUpdate, createFinalizeUpdate, createDeleteItemCost } from '../../actions';
import { createApplyMatrixToItemPopup } from '../../actions/popup';

import { getItemCost } from '../../selectors/items';

import Field from './Field';
import { TableIcon } from '@commonsku/styles';

import { getExtraCharges } from '../../promostandard_utils';

const updateItemCost = (field_lock = 'unit_cost', exchange_rate = 1.0) => (id, field, prev_value, value) => createUpdate('item-cost', {
  request: actions.COMPLEX_UPDATE_ITEM_COST_REQUEST,
  success: actions.COMPLEX_UPDATE_ITEM_COST_SUCCESS,
  failure: actions.COMPLEX_UPDATE_ITEM_COST_FAILURE,
  failure_message: 'Unable to update item cost'
})(id, field, prev_value, value, null, {}, { field_lock, exchange_rate});
const updateRequestItemCost = (field_lock = 'unit_cost', exchange_rate = 1.0) => (id, field, prev_value, value) => createFinalizeUpdate(actions.COMPLEX_UPDATE_ITEM_COST_REQUEST)(id, field, prev_value, value, null, {}, { field_lock, exchange_rate });

class FixedCost extends React.Component {

  constructor(props) {
    super(props);

    ['handleDeleteCost', 'handleUpdateItemCost', 'handleClickDecorationMatrix'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleDeleteCost(e) {
    const { dispatch, item_cost_id } = this.props;
    e.preventDefault();
    dispatch(createDeleteItemCost(item_cost_id));
  }

  handleUpdateItemCost(value, field, error) {
    const { item_cost_id, field_lock, exchange_rate } = this.props;
    if (error) {
      return;
    }
    this.props.dispatch(updateItemCost(field_lock, exchange_rate)(item_cost_id, field, this.props[field], value));
  }

  handleClickDecorationMatrix(e) {
    const { item_cost_id, item_location_id, options } = this.props;
    e.preventDefault();
    this.props.dispatch(createApplyMatrixToItemPopup({
      qty: null,
      charge_type: 'fixed',
      onAddItemCost: this.handleUpdateItemCost,
      isPresentation: true,
      unit_cost_field: 'unit_cost',
      label_field: 'item_cost_title'
    }));

  }

  renderDecorationMatrix() {
    return (
      <TableIcon style={{ position: 'absolute', width: '2rem',  top: '.35rem', right: '0.25rem' }} onClick={this.handleClickDecorationMatrix} />
    );
  }

  render() {
    const { item_cost_id, ext_cost_id, location_index, location_title, item_cost_title, unit_cost, total_margin, unit_price, share_fixed_charges, hidden, locked, field_lock, exchange_rate, division_id } = this.props;

    const extraCharges = getExtraCharges(division_id);
    const isEditableCharge = () => {
      if (ext_cost_id === null) {
        return true;
      }
      const charge = extraCharges.find(
        c => c.ps_charge_id == ext_cost_id && 'RUN' !== c.charge_type.toUpperCase()
      );
      return !!charge && charge.is_editable;
    };

    return (
      <tr className="hover-x-container">
        <td>
          <div className="flex-label">
            {!locked && <div className="hover-x"><a onClick={this.handleDeleteCost}>&times;</a></div>}
	    <div className="icon-label-container" style={{ width: '100%', position: 'relative' }}>
              {!!location_index && <div className="icon-label" title={location_title}>{location_index}</div>}
              <Field
                id={item_cost_id}
                value={item_cost_title}
                update={updateItemCost(field_lock, exchange_rate)}
                field="item_cost_title"
	        className="icon-field"
                dispatch={this.props.dispatch}
                disabled={locked || !isEditableCharge()}
              />
              {(!locked && !ext_cost_id && !!location_index) && this.renderDecorationMatrix()}
            </div>
          </div>
        </td>
        <td>
          <Field
            id={item_cost_id}
            value={unit_cost}
            update={updateItemCost(field_lock, exchange_rate)}
            request={updateRequestItemCost(field_lock, exchange_rate)}
            decimals={4}
            field="unit_cost"
            dispatch={this.props.dispatch}
            disabled={locked}
          />
        </td>
        {share_fixed_charges && <td>
	  <Field
            id={item_cost_id}
            value={total_margin}
            update={updateItemCost(field_lock, exchange_rate)}
            request={updateRequestItemCost(field_lock, exchange_rate)}
            decimals={2}
            field="total_margin"
            dispatch={this.props.dispatch}
            disabled={locked || !share_fixed_charges}
          />
        </td>}
        {share_fixed_charges && <td>
	  <Field
            id={item_cost_id}
            value={unit_price}
            update={updateItemCost(field_lock, exchange_rate)}
            request={updateRequestItemCost(field_lock, exchange_rate)}
            decimals={2}
            field="unit_price"
            dispatch={this.props.dispatch}
            disabled={locked || !share_fixed_charges}
          />
        </td>}
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item_cost = getItemCost(state, { item_cost_id: ownProps.item_cost_id });
  return item_cost;
};

export default connect(mapStateToProps)(FixedCost);
