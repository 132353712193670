import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Button, Col, colors, Popup, Row, Text, MailIcon } from '@commonsku/styles';
import { oauth } from '../../utils';
import { window } from '../../global';
import { useIdentity } from '../../hooks';

const getEmailVerified = createSelector(
  s => s.identity,
  s => {
    if ((s.login_email || '').startsWith('commonsku@')
      || s.company_type === 'SUPPLIER'
    ) {
      return true;
    }

    const result = _.get(s, ['email_verified'], false);
    if (!_.isBoolean(result)) {
      if (_.isString(result)) {
        return result !== '0';
      }
      return result === 1;
    }
    return result;
  }
);
const getVerificationEmailSent = createSelector(
  s => s.identity,
  s => _.get(s, ['verification_email_sent'], "")
);
const getContactId = createSelector(
  s => s.identity,
  s => _.get(s, ['contact_id'], "")
);
const tomorrow = () => {
  const d = new Date();
  const newD = new Date(d.getTime() + 60 * 60 * 24 * 1000);
  return newD;
};
const shouldShowLater = (contact_id) => {
  let showLaters = window.localStorage.getItem('SES_VERIFICATION_SHOW_LATER');
  if (!showLaters) { return false; }
  try {
    showLaters = JSON.parse(showLaters);
  } catch (error) {
    return false;
  }
  const value = _.get(showLaters, [contact_id], '');
  const diff = (new Date(value)).getTime() - new Date().getTime();
  if (isNaN(diff) || !value) {
    return true;
  }
  return diff > 0;
};
const setShowLater = (contact_id, value) => {
  if (_.isDate(value)) {
    value = value.toISOString();
  }
  let showLaters = window.localStorage.getItem('SES_VERIFICATION_SHOW_LATER');
  if (!showLaters) { showLaters = "{}"; }
  try {
    showLaters = JSON.parse(showLaters);
  } catch (error) {
    showLaters = {};
  }
  showLaters[contact_id] = value;
  window.localStorage.setItem('SES_VERIFICATION_SHOW_LATER', JSON.stringify(showLaters));
};

const getSesVerified = (user_id) => {
  if (!user_id) { return false; }
  let userIds = window.localStorage.getItem('SES_VERIFIED');
  if (!userIds) { userIds = "{}"; }
  try {
    userIds = JSON.parse(userIds);
  } catch (error) {
    userIds = {};
  }
  return userIds[user_id] === 'true';
};

const setSesVerified = (user_id, value) => {
  if (!user_id) { return; }
  let userIds = window.localStorage.getItem('SES_VERIFIED');
  if (!userIds) { userIds = "{}"; }
  try {
    userIds = JSON.parse(userIds);
  } catch (error) {
    userIds = {};
  }
  userIds[user_id] = value;
  window.localStorage.setItem('SES_VERIFIED', JSON.stringify(userIds));
};

const VerifyEmail = () => {
  const identity = useIdentity();
  const contactId = useSelector(getContactId);
  const emailVerified = useSelector(getEmailVerified);
  const verificationEmailSent = useSelector(getVerificationEmailSent);
  const [showPopup, setShowPopup] = useState(true);
  const timeoutId = useRef(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');

  const userId = useMemo(() => identity.user_id, [identity.user_id]);

  const updateShowPopup = useCallback((value) => {
    if (timeoutId.current && !value) {
      clearTimeout(timeoutId.current);
    }
    setShowPopup(value);
  }, [timeoutId]);

  function shouldResend(date) {
    if (!date) {
      return true;
    }
    let diff = new Date().getTime() - new Date(date).getTime();
    if (isNaN(diff)) {
      return true;
    }
    return diff / (1000 * 3600 * 24) > 2;
  }

  const checkVerifyStatus = useCallback(() => {
    if (!showPopup) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      return;
    }
    oauth('GET', 'contact/verification_status', {})
      .then(({ json }) => {
        if (json.status === 'Success') {
          updateShowPopup(false);
        } else {
          timeoutId.current = setTimeout(checkVerifyStatus, 15000);
        }
      });
  }, [showPopup, updateShowPopup, timeoutId]);

  useEffect(() => {
    oauth('GET', 'contact/verification_status', {})
      .then(({ json }) => {
        if (json.status !== 'Success' && json.status !== 'Pending') {
          setShowPopup(true);
          setSesVerified(userId, 'false');
        } else if (json.status === 'Success') {
          setShowPopup(false);
          setSesVerified(userId, 'true');
        }
      });
  }, [userId]);

  useEffect(() => {
    if (!showPopup) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      return;
    }
    if (emailVerified) {
      updateShowPopup(false);
      setSesVerified(userId, 'true');
      return;
    }
    if (shouldShowLater(contactId)) {
      updateShowPopup(false);
      return;
    }
    if (!shouldResend(verificationEmailSent)) {
      updateShowPopup(false);
      oauth('GET', 'contact/verification_status', {});
      return;
    }
    if (getSesVerified(userId)) {
      return;
    }

    oauth('GET', 'contact/verification_status', {})
      .then(({ json }) => {
        if (json.status !== 'Success' && json.status !== 'Pending') {
          updateShowPopup(true);
          checkVerifyStatus();
          setSesVerified(userId, 'false');
        } else if (json.status === 'Success') {
          updateShowPopup(false);
          setSesVerified(userId, 'true');
        }
      });
  }, [emailVerified, showPopup, verificationEmailSent, checkVerifyStatus, updateShowPopup, contactId, userId]);

  const sendVerification = useCallback((resend) => {
    oauth('GET', 'contact/verify-email', {resend: resend})
      .then(({ json }) => {
        setVerificationSent(true);
        setVerificationEmail(json.email);
        if (json.sent && (new Date(json.sent).getTime() - new Date().getTime() < 0)) {
          updateShowPopup(true);
        }
        if (json.verified != 1) {
          checkVerifyStatus();
        } else {
          updateShowPopup(false);
        }
      });
  }, [checkVerifyStatus, updateShowPopup]);

  if (!showPopup ||
      emailVerified ||
      !shouldResend(verificationEmailSent) ||
      shouldShowLater(contactId) ||
      getSesVerified(userId) ||
      identity.company_type === 'SUPPLIER'
  ) {
    return null;
  }

  return (
    <VerifyEmailPopup
      onClose={() => updateShowPopup(false)}
      showLater={() => {
        setShowLater(contactId, tomorrow());
        updateShowPopup(false);
      }}
      sendVerification={() => sendVerification(true)}
      verificationSent={verificationSent}
      verificationEmail={verificationEmail}
    />
  );
};

export function VerifyEmailPopup(props) {
  const {
    onClose,
    showLater,
    sendVerification,
    verificationSent=false,
    verificationEmail='',
  } = props;

  return (
    <Popup
      onClose={onClose}
      header={
        <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }} pb={30}>
          <Col xs>
            <div style={{ paddingRight: 10, display: 'inline', verticalAlign: 'middle' }}>
              <MailIcon color={colors.neutrals['90']} size='large' />
            </div>
            <Text bold style={{ fontSize: 20, color: colors.neutrals.bodyText }}>
              {verificationSent
                ? `We've sent a verification email ${verificationEmail ? 'to' : ''} ${verificationEmail}`.trim()
                : 'Action required: Verify your email'}
            </Text>
            {verificationSent && <Button style={{ float: 'right', marginLeft: 20 }} onClick={onClose} variant='primary' size="medium">Close</Button>}
          </Col>
        </Row>
      }
      style={{width: 780, height: 'auto'}}
    >
      <Row>
        {verificationSent ? <>
          <Col xs style={{ paddingBottom: 50, }}>
            <Text style={{ color: colors.neutrals['90'] }}>Please check your inbox (or SPAM folder) and click the verification link.</Text>
          </Col>
        </> : <>
          <Col xs>
            <Text as="p" style={{ paddingBottom: 20, }}>
              Please click the <strong>Verify your email</strong> button below to prevent emails from landing in your client' SPAM folders.
            </Text>
          </Col>
          <Col xs>
            <Button
              variant={verificationSent ? 'disabled' : 'cta'}
              size="medium"
              mr={10}
              disabled={verificationSent}
              onClick={sendVerification}
            >
              Verify your email
            </Button>
            <Button variant='secondary' onClick={showLater} size="medium">
              Do this later
            </Button>
          </Col>
        </>}
      </Row>
    </Popup>
  );
}

export default VerifyEmail;
