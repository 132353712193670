export const isBrowser = () => {
  return typeof window !== 'undefined' && typeof navigator !== 'undefined' && window.document;
};

const El = isBrowser() ? Element : class {
  parentNode = null;
  children = [];
  childNodes = [];
  style = {};
  innerText = '';

  setAttribute() {}

  getElementsByTagName() {
    return [];
  }

  click() {}

  appendChild(el) {
    return el;
  }

  removeChild(el) {}
};

const doc = isBrowser() ? document : {
  body: new El(),
  hidden: false,
  addEventListener() {},
  removeEventListener() {},
  activeElement: {
    blur() {},
    nodeName: '',
  },
  querySelector() {
    return null;
  },
  querySelectorAll() {
    return [];
  },
  getElementById() {
    return null;
  },
  createEvent() {
    return {
      initEvent() {},
    };
  },
  createElement() {
    return new El();
  },
  createElementNS() {
    return {};
  },
  importNode() {
    return null;
  },
  execCommand() {},
  location: {
    hash: '',
    host: '',
    hostname: '',
    href: '',
    origin: '',
    pathname: '',
    protocol: '',
    search: '',
  },
};

function FakeStorage() {
  this._storage = {};
};
FakeStorage.prototype.setItem = function (name, value) {
  this._storage['' + name] = '' + value;
};
FakeStorage.prototype.getItem = function (name) {
  return this._storage['' + name];
};
FakeStorage.prototype.removeItem = function (name) {
  delete this._storage['' + name];
};
FakeStorage.prototype.key = function (i) {
  const keys = Object.keys(this._storage);
  return keys.length > i ?  keys[i] : null;
};
FakeStorage.prototype.clear = function (i) {
  Object.keys(this._storage).forEach((key) => {
    delete this._storage[key];
  });
};

const win = isBrowser() ? window : {
  document: doc,
  cookie: '',
  navigator: {
    userAgent: '',
  },
  location: {
    hash: '',
    host: '',
    hostname: '',
    href: '',
    origin: '',
    pathname: '',
    protocol: '',
    search: '',
  },
  history: {
    replaceState() {},
    pushState() {},
    go() {},
    back() {},
  },
  CustomEvent: function CustomEvent() {
    return this;
  },
  addEventListener() {},
  removeEventListener() {},
  getComputedStyle() {
    return {
      getPropertyValue() {
        return '';
      },
    };
  },
  Image() {},
  Date() {},
  screen: {},
  setTimeout() {},
  clearTimeout() {},
  matchMedia() {
    return {};
  },
  alert(msg) {},
  confirm(msg) {},
  localStorage: new FakeStorage(),
  open(url='', target='', features=null) {},
  crypto: {
    randomUUID: () => {
      return '';
    },
  }
};

const ssr = {
  window: win,
  document: doc,
};

export default ssr;
