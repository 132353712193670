import React, { useState, useMemo, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Input, LabeledInput } from '@commonsku/styles';

export default function DebouncedSearchBox({
    label=null, // Labeled Input
    inputType='text',
    handleSearch,
    placeholder='',
    debounceInterval=100,
    cRef=null,
    ...props
}) {
    const [query, setQuery] = useState('');

    const debounceSearch = useMemo(() => {
        return debounce(handleSearch, debounceInterval);
    }, [handleSearch]);

    const handleOnChange = (e) => {
        setQuery(e.target.value);
        debounceSearch(e.target.value);
    };

    useImperativeHandle(cRef, () => ({
        setInputValue: (value) => {
            setQuery(value);
            debounceSearch(value);
        }
    }));

    return label
        ? <LabeledInput
            label={label}
            type={inputType || 'text'}
            placeholder={placeholder}
            value={query}
            onChange={handleOnChange}
            {...props}
        /> : <Input
            type={inputType || 'text'}
            className="form-control"
            placeholder={placeholder}
            value={query}
            onChange={handleOnChange}
            {...props}
        />;
}

DebouncedSearchBox.propTypes = {
    label: PropTypes.string,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    debounceInterval: PropTypes.number,
    handleSearch: PropTypes.func.isRequired,
};
