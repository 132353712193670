import { isEmpty } from 'lodash';

import {
  CREATE_AVALARA_CATEGORY_SUCCESS,
  DELETE_AVALARA_CATEGORY_SUCCESS,
  UPDATE_AVALARA_CATEGORY_SUCCESS
} from '../actions/avalara';
import update from 'immutability-helper';

export const avalaraCategoriesReducer = (state = {}, action) => {
  const { type, payload } = action;
  const { avalara_category } = payload || {};

  switch (type) {
    case CREATE_AVALARA_CATEGORY_SUCCESS: // pass through
    case UPDATE_AVALARA_CATEGORY_SUCCESS:
      return isEmpty(avalara_category) ? state : update(state, {
        [avalara_category.avalara_category_id]: {$set: avalara_category}
      });
    case DELETE_AVALARA_CATEGORY_SUCCESS:
      return isEmpty(avalara_category) ? state : update(state, {
        $unset: [avalara_category.avalara_category_id]
      });
  }
  return state;
};