import React from 'react';
import { toast } from 'react-toastify';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class SageSearchFilter extends ProductSearchFilter {

  getProductSource() {
    return 'sage-products';
  }

  loadDivisions() {
    this.props.loadDivisions('sage');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: ''
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.sage_division_id || ''
      };
    }
  }

  getEndpoint() {
    return 'sage-product';
  }

  getSearchTerms(page) {
    let search_terms = {
      keyword_sku: this.state.search.search_term,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.division_id) {
      search_terms.division_id = this.state.search.division_id;
    }
    return search_terms;
  }

  onErrorResponse(json) {
    if (json && json.message) {
      toast.error(json.exception || json.message, {
        autoClose: 6000,
      });
      this.setState({ loading: false });
    }
  }

  render() {
    const { company_type, divisions, style } = this.props;
    const { search } = this.state;

    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    const onChangeDivision = division_id => this.onChangeDivision(division_id, 'sage_division_id');

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-4 columns">
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </div>
          <div className="medium-4 columns">
            {divisions &&
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={'SUPPLIER' !== company_type}
                options={divisions}
                change={onChangeDivision}
                placeholder="Supplier"
              />}
          </div>

          <div className="medium-4 columns">
            <div className="button-group expanded">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('sage')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default SageSearchFilter;

