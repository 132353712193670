import { filter } from 'lodash';
import React from 'react';
import { colors, Row, Col, H5, CollapsiblePanels } from '@commonsku/styles';

import Badge from './Badge';

const SupplierBenefits = ({ supplier }) => {
  const benefits = supplier.benefits.filter(sb => sb.benefit_name !== 'KEY-PRODUCT-CATEGORIES');
  const key_product_categories = supplier.benefits.filter(sb => sb.benefit_name === 'KEY-PRODUCT-CATEGORIES')[0];
  return (
    <div>
      {benefits.length > 0 && <Row>
        <Col xs style={{ marginTop: 15 }}>
          <H5>commonsku Distributor Benefits</H5>
          <CollapsiblePanels spaceBetween={true} panels={benefits.map(sb => ({
            title: <p style={{ margin: '0 1rem' }} ><span style={{ fontWeight: 'bold', fontSize: '2em', color: '#00D374' }}>✓</span> {sb.benefit_label}</p>,
            children: !!sb.benefit_description && <p style={{ padding: '1rem 1rem 0 1rem', whiteSpace: 'pre-line' }}>{sb.benefit_description || 'Contact a sales rep for details.'}</p>,
            wrapperProps: { style: { padding: 0 } },
            titleProps: { style: { padding: 0 } }
          }))} />
        </Col>
      </Row>}
      <Row>
        <Col xs style={{ marginTop: 15 }}>
          <H5>Connected Workflow</H5>
          <div style={{ display: 'flex' }}>
            {filter([
              0 != supplier.public && {type: 'collaborate', text: 'Collaborate'},
              'no' !== supplier.inventory_service && {type: 'inventory', text: 'Inventory'},
              'no' !== supplier.order_status_service && {type: 'order_status', text: 'Order Status'},
              'no' !== supplier.epo_service && {type: 'epo', text: 'ePO'},
            ]).map(({ type, text }, i) => {
              const color = colors.secondary4.main;
              return <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '135px' }}>
                <Badge type={type} size="huge" altText={text} color={color}/>
                <p style={{
                  fontFamily: '‘skufont-medium’, sans-serif', fontStyle: 'normal', fontWeight: 600, fontSize: 20, color,
                }}>{text}</p>
              </div>;
            })}
          </div>
        </Col>
      </Row>
      {key_product_categories &&
        <Row>
          <Col xs style={{ marginTop: 15 }}>
            <H5>Key Product Categories</H5>
            <p>{key_product_categories.benefit_description}</p>
          </Col>
        </Row>
      }
    </div>
  );
};

export default SupplierBenefits;
