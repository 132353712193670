import { filter, isEmpty, find, last, uniq } from 'lodash';
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';

import { colors, ChevronIcon, useFallbackRef } from '@commonsku/styles';
import { useHasCapabilities } from '../hooks';

const ChevronIconInput = forwardRef(({onFocus, onBlur, ...props}, ref) => {
  const inputRef = useFallbackRef(ref);
  const [focus, setFocus] = useState(false);

  return <div style={{ position: 'relative' }}>
    <input
      {...props} ref={inputRef}
      onFocus={(e) => {
        setFocus(true);
        return onFocus && onFocus(e);
      }}
      onBlur={(e) => {
        setFocus(false);
        return onBlur && onBlur(e);
      }}
    />
    <ChevronIcon
      direction='down'
      style={{
        marginTop: '4px', position: 'absolute', top: '3px', right: 0, cursor: 'default',
      }}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        if (focus) {
          inputRef.current.blur();
        } else {
          inputRef.current.focus();
        }
      }}
    />
  </div>;
});

const AutosuggestInput = forwardRef(({
  suggestions: initialSuggestions, // [{ label, key }]
  onSuggestionSelected,
  ...props
}, ref) => {
  const hasCreateTags = useHasCapabilities(['CREATE-TAGS']);
  const [suggestions, setSuggestions] = useState(initialSuggestions);
  const getSuggestionValue = ({ label }) => label;

  return <Autosuggest
    ref={ref} suggestions={suggestions} inputProps={props}
    focusInputOnSuggestionClick={false}
    getSuggestionValue={getSuggestionValue}
    shouldRenderSuggestions={() => true}
    onSuggestionsFetchRequested={({ value, reason }) => {
      let found = false;
      const inputValue = (value || '').trim().toLowerCase();
      let availableSuggestions = filter(initialSuggestions, (suggestion) => {
        const suggestionValue = (suggestion.label || '').trim().toLowerCase();
        if (suggestionValue === inputValue) {
          found = true;
        }
        return suggestionValue.indexOf(inputValue) !== -1;
      });
      if (!found && hasCreateTags && value !== '') {
        availableSuggestions.push({value, label: `Create new tag "${value}"`});
      }
      setSuggestions(availableSuggestions);
    }}
    onSuggestionsClearRequested={() => setSuggestions([])}
    onSuggestionSelected={onSuggestionSelected}
    renderSuggestionsContainer={({ containerProps, children, query }) => {
      return <div {...containerProps}>
        {children || (
          !isEmpty(query) && <div style={{paddingLeft:'5px'}}>
            <p>
              The tag "{query}" does not exist.
            </p>
            <p>
              Ask your admin to create it, or try a different tag.
            </p>
            <p>
              <a href="https://help.commonsku.com/knowledge/project-overview#tags"> Learn more about tagging </a>
            </p>
          </div>
        )}
      </div>;
    }}
    renderSuggestion={(suggestion) => {
      return <span>{getSuggestionValue(suggestion)}</span>;
    }}
    renderInputComponent={(inputProps) => {
      return <ChevronIconInput {...inputProps}/>;
    }}
  />;
});

const AutosuggestTagsInput = styled(({
  style, placeholder = 'Select Tags', className, tags, value, readOnly, onChange, ...props
}) => {
  const hasCreateTags = useHasCapabilities(['CREATE-TAGS']);

  return <TagsInput
    {...props}
    style={style}
    className={`react-tagsinput ${className}`} tagDisplayProp="label" value={value} onlyUnique={true}
    onChange={(newTags, changed, changedIndexes) => {
      if (!hasCreateTags) {
        newTags = filter(newTags, ({ label }) => {
          return find(tags, { label });
        });
      } else {
        newTags = newTags.map(t => ({ ...t, label: t.value ?? t.label }));
      }
      return onChange && onChange(uniq(newTags));
    }}
    renderInput={({addTag, value, ...inputProps}) => {
      return <AutosuggestInput
        {...inputProps} value={value || ''} suggestions={tags} placeholder={placeholder} readOnly={readOnly}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
      />;
    }}
  />;
})`
  &&& {
    border: 1px ${colors.neutrals['60']} solid;
    padding: 1px;
    box-shadow: 0 0 5px #CCD5DA;

    :hover, &.react-tagsinput--focused {
      border: 2px ${colors.primary1['60']} solid;
      padding: 0;
    }

    .react-tagsinput-input {
      width: 100%;
      height: 34px;
      padding: 0 8px;
      margin: 0;
      border: none;
    }

    .react-autosuggest__suggestions-container {
      background-color: white;
      max-height: 302px;
      overflow-y: auto;
    }

    .react-autosuggest__suggestion {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
    }
    
    .react-autosuggest__suggestions-list {
      padding: 0;
    }
    
    .react-autosuggest__suggestion--highlighted {
      background-color: ${colors.neutrals['40']};
    }
  }
`;

export default AutosuggestTagsInput;
