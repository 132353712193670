import React from 'react';
import { Button, colors, IconButton } from '@commonsku/styles';

function getTemplateButtonProps(
  template_color = colors.primary1.main,
  isSecondary = false
) {
  let variantColor = '#fff';
  let variantBg = template_color;
  let variantBorderColor = template_color;
  let variantHoverColor = variantColor;
  let variantHoverBorderColor = template_color;
  let variantHoverBg = template_color;
  if (isSecondary) {
    variantColor = template_color;
    variantBg = '#fff';
    variantHoverColor = template_color;
    variantHoverBg = '#fff';
  }
  return {
    variantColor,
    variantBg,
    variantBorderColor,
    variantHoverColor,
    variantHoverBg,
    variantHoverBorderColor
  };
}

export const TemplateButton = ({
  template_color = colors.primary1.main,
  isSecondary = false,
  disabled = false,
  children,
  ...props
}) => (
  <Button
    variant={disabled ? 'disabled' : 'custom'}
    {...getTemplateButtonProps(template_color, isSecondary)}
    {...props}
  >{children}</Button>
);

export const TemplateIconButton = ({
  template_color = colors.primary1.main,
  isSecondary = false,
  disabled = false,
  children,
  ...props
}) => (
  <IconButton
    variant={disabled ? 'disabled' : 'custom'}
    {...getTemplateButtonProps(template_color, isSecondary)}
    {...props}
  >{children}</IconButton>
);

export const TemplateTextButton = ({
  template_color = colors.primary1.main,
  disabled = false,
  children,
  ...props
}) => (
  <Button
    variant={disabled ? 'disabled' : 'custom'}
    variantColor={template_color}
    variantBorderColor={'transparent'}
    variantBg={'transparent'}
    variantHoverColor={template_color}
    variantHoverBorderColor={'transparent'}
    variantHoverBg={'#fff'}
    {...props}
  >{children}</Button>
);

export const TemplateTextIconButton = ({
  template_color = colors.primary1.main,
  disabled = false,
  hover_color = '#fff',
  children,
  ...props
}) => (
  <IconButton
    variant={disabled ? 'disabled' : 'custom'}
    variantColor={template_color}
    variantBorderColor={'transparent'}
    variantBg={'transparent'}
    variantHoverColor={template_color}
    variantHoverBorderColor={'transparent'}
    variantHoverBg={hover_color}
    {...props}
  >{children}</IconButton>
);

