import { find, bindAll } from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';

import { formatMoney, isNumeric } from '../utils';
import { itemBreakdownReducer } from '../reducers/item';
import { createFinalizeUpdate } from '../actions';
import { UPDATE_ITEM_BREAKDOWN_REQUEST } from '../actions/item';

import TextInput from './TextInput';
import DragItemTypes from '../DragItemTypes';
import { DraggableItemContainer } from './DraggableItem';
import { getItemSku } from '../promostandard_utils';
import { getBreakdownOptionDescription } from '../utils/order';
import {
  ChevronIcon,
  LabeledIconInput,
} from '@commonsku/styles';
import BreakdownDetailsDropdown from './BreakdownDetailsDropdown';
import DisabledColorSizeTooltip from './DisabledColorSizeTooltip';
import UpdateIndicator from './UpdateIndicator';

export const reduceSingleBreakdown = (breakdown, action) => {
  const old_state = {[breakdown.breakdown_id] : breakdown};
  const new_state = itemBreakdownReducer(old_state, action);
  return new_state[breakdown.breakdown_id];
};

export const changeItemBreakdown = (breakdown, field, value, field_lock = 'unit_cost', exchange_rate = 1.0) => {
  return createFinalizeUpdate(UPDATE_ITEM_BREAKDOWN_REQUEST)(breakdown.breakdown_id, field, breakdown[field], value, null, {}, { field_lock, exchange_rate });
};

const StyledLabeledIconInput = styled(({ className, iconProps, inputProps, ...props }) => {
  return <LabeledIconInput labelProps={{ className }} className={className} iconProps={iconProps} inputProps={inputProps} {...props}/>;
})`
  &&& div:hover {
    background-color: #FFFFFF;
  }
  &&& div {
    background-color: #FFFFFF;
  }
  &&& input {
    height: auto;
  }
`;

class ItemBreakdown extends Component {

  constructor(props) {
    super(props);

    const breakdown = this.formatBreakdown(props.breakdown);
    this.state = {
      details: false,
      breakdown,
      initial_breakdown: breakdown,
      displayUpdateIndicator: true
    };
    this.netCostRef = React.createRef();
    bindAll(this, ['isValueValid', 'formatBreakdown', 'formatValue', 'onChange', 'onUpdate', 'handleClickDetails']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const initial_breakdown = this.formatBreakdown(nextProps.breakdown);
    const breakdown = Object.assign({}, initial_breakdown, Object.keys(this.state.breakdown).reduce((o, k) => {
      if (this.state.breakdown[k] !== this.state.initial_breakdown[k]) {
        o[k] = this.state.breakdown[k];
      }
      return o;
    }, {}));

    this.setState({
      breakdown,
      initial_breakdown
    });
  }

  handleClickDetails(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ details: true });
  }

  formatBreakdown(breakdown) {
    return Object.keys(breakdown).reduce((o, k) => {
      o[k] = this.formatValue(k, breakdown[k]);
      return o;
    }, {});
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'breakdown_margin':      case 'unit_cost':      case 'unit_price':        return isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':        return parseFloat(value).toFixed(0);
      case 'unit_cost':        return parseFloat(value).toFixed(4);
      case 'breakdown_margin':      case 'unit_price':        return parseFloat(value).toFixed(2);
    }
    return value;
  }

  onChange(field, value) {
    if (this.isValueValid(field, value)) {
      const breakdown = reduceSingleBreakdown(
        this.state.initial_breakdown,
        changeItemBreakdown(this.state.initial_breakdown, field, value, this.props.field_lock, this.props.exchange_rate)
      );
      this.setState({
        details: false,
        breakdown
      });
    } else {
      this.setState({ details: false, breakdown: Object.assign({}, this.state.breakdown, { [field]: value })});
    }
  }

  onUpdate(field, value) {
    if (this.isValueValid(field, value)) {
      const formattedValue = this.formatValue(field, value);
      if (formattedValue === this.state.initial_breakdown[field]) {
        this.setState({
          details: false,
          breakdown: Object.assign({}, this.state.initial_breakdown)
        });
      } else {
        this.setState({
          breakdown: Object.assign({}, this.state.breakdown, { [field]: formattedValue }),
          initial_breakdown: Object.assign({}, this.state.initial_breakdown, { [field]: formattedValue })
        }, () => {
          this.props.onUpdate(this.props.breakdown.breakdown_id, field, this.state.initial_breakdown[field], this.props.field_lock, this.props.exchange_rate)(formattedValue);
        });
      }
    } else {
      this.setState({ details: false, breakdown: this.state.initial_breakdown });
    }
  }

  render() {
    const {
      item, hidden_costs, locked, breakdowns_count, skus,
      onUpdate, onDelete, onUpdateBreakdownDimensions, onCopyItemBreakdown,
      connectDragSource, connectDropTarget, editPS, exchange_rate = 1.0,
      id = '', onEnter, field_lock, supplierCost
    } = this.props;
    const {
      details,
      breakdown,
      disableDrag,
      displayUpdateIndicator
    } = this.state;
    const client_price = parseFloat(breakdown.unit_price) + parseFloat(hidden_costs);
    const total_subtotal = client_price * parseFloat(breakdown.quantity);

    const dndProps = {
      selectOnFocus: true,
      onMouseEnter: () => this.setState({ disableDrag: true }),
      onMouseLeave: () => this.setState({ disableDrag: false }),
    };

    const option_description = getBreakdownOptionDescription(
      breakdown, { sizes: item.sizes, colors: item.colors, skus }, ['color', 'size']
    );

    const isDisabled = (field_name) => {
      switch (field_name) {
        case 'unit_cost':          return locked || (!!skus && !editPS);
        case 'breakdown_margin':          return locked || exchange_rate !== 1;
        case 'unit_price':          return locked || 'unit_price' === field_lock;
        default:          return locked;
      }
    };
    const handleKeyDown = (field_name) => e => {
      if (isDisabled(field_name)) { return; }
      if (e.key === 'Enter') {
        onEnter && onEnter(e, field_name, !e.shiftKey);
      }
    };
    const disabled = 'ps-products' === item.copied_from && !!item.ext_fob_id && !find(skus, ({ options }) => {
      return find(options, ({ option_axis }) => {
        return option_axis === 'color' || option_axis === 'size';
      });
    });

    return (
      <DraggableItemContainer
        type={DragItemTypes.BREAKDOWN}
        index={this.props.index}
        onDrop={this.props.onDrop}
        onMove={this.props.onMove}
        item={this.props.item_sku}
        canDrag={!(locked || disableDrag)}
        id={id}
      >
        <tr style={{'textAlign' : 'right'}} id={id}>
          <td>{!locked && breakdowns_count != 1 ? <a className="delete" onClick={e => { e.preventDefault(); onDelete(breakdown.breakdown_id); }}>&times;</a> : null}</td>
          <td onClick={(!locked && !disabled) ? this.handleClickDetails : null}>
            <DisabledColorSizeTooltip showTooltip={disabled}>
              <StyledLabeledIconInput
                  Icon={<ChevronIcon style={{backgroundColor: '#FFFFFF'}} direction={'down'}/>}
                  iconPosition='right'
                  iconProps={{style: {backgroundColor: '#FFFFFF'}}}
                  readOnly={true}
                  type='text'
                  iconColor='#B8C4CB'
                  value={option_description}
                  style={{
                    height: '39px', background: '#FFFFFF', display: 'flex', marginBottom: 0,
                    borderWidth: 1, borderStyle: 'solid', borderColor: '#B8C4CB',
                  }}
              />
            </DisabledColorSizeTooltip>
            {details && <BreakdownDetailsDropdown
              item={item}
              breakdown={breakdown}
              onCloseBreakdownDetails={() => {
                this.setState({ details: false });
              }}
              onDone={(size_id, color_id, product_sku_id) => {
                if (product_sku_id) {
                  return onUpdate(breakdown.breakdown_id, 'product_sku_id', breakdown.product_sku_id, field_lock, exchange_rate)(product_sku_id);
                } else {
                  return onUpdateBreakdownDimensions(
                    breakdown.breakdown_id, size_id, null, color_id, null, breakdown.quantity
                  );
                }
              }}
              skus={skus}
            />}
          </td>
          <td>
            <input type="text" readOnly={true} value={breakdown.warehouse_code || breakdown.sku || getItemSku(item)}
              onClick={(!locked && !disabled) ? this.handleClickDetails.bind(this) : null} />
          </td>
          <td>
            <TextInput disabled={isDisabled('quantity')} value={breakdown.quantity} className="item-breakdown-quantity-input"
              {...dndProps}
              onChange={e => this.onChange('quantity', e.target.value)}
              onBlur={e => this.onUpdate('quantity', e.target.value)}
              onKeyDown={handleKeyDown('quantity')}
            />
          </td>
          <td>
            <span className="dollar">$</span>
            {
              displayUpdateIndicator &&
              <UpdateIndicator
                inputRef={this.netCostRef}
                breakdownId={breakdown.breakdown_id}
                userCost={breakdown.unit_cost}
                supplierCost={supplierCost}
                onUpdate={() => this.onUpdate('unit_cost', supplierCost)}
                onIgnore={() => this.setState({ displayUpdateIndicator: false })}
              />
            }
            <TextInput
              disabled={isDisabled('unit_cost')}
              className="text-right item-breakdown-unit_cost-input"
              inputRef={this.netCostRef}
              {...dndProps}
              value={breakdown.unit_cost}
              onChange={e => this.onChange('unit_cost', e.target.value)}
              onBlur={e => this.onUpdate('unit_cost', e.target.value)}
              onKeyDown={handleKeyDown('unit_cost')}
            />
          </td>
          <td>
            <TextInput disabled={isDisabled('breakdown_margin')} className="text-right indent-percent item-breakdown-breakdown_margin-input"
              {...dndProps}
              value={breakdown.breakdown_margin}
              onChange={e => this.onChange('breakdown_margin', e.target.value)}
              onBlur={e => this.onUpdate('breakdown_margin', e.target.value)}
              onKeyDown={handleKeyDown('breakdown_margin')}
            />
            <span className="percent">%</span>
          </td>
          <td>
            <span className="dollar">$</span>
            <TextInput disabled={isDisabled('unit_price')} className="text-right item-breakdown-unit_price-input"
              {...dndProps}
              value={breakdown.unit_price}
              onChange={e => this.onChange('unit_price', e.target.value)}
              onBlur={e => this.onUpdate('unit_price', e.target.value)}
              onKeyDown={handleKeyDown('unit_price')}
            />
          </td>
          <td>{'$' + formatMoney(client_price)}</td>
          <td>{'$' + formatMoney(total_subtotal)}</td>
          <td style={{fontSize: 'x-large'}}>{!locked ? <a className="delete" onClick={e => { e.preventDefault(); onCopyItemBreakdown(breakdown.breakdown_id); }}>&darr;</a> : null}</td>
        </tr>
      </DraggableItemContainer>
    );
  }
}

export default ItemBreakdown;
