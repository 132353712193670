import { get, find } from 'lodash';
import React, { Component } from 'react';

import ArtworkProofRequired from './ArtworkProofRequired';
import { DraggableItemContainer } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';
import ItemArtwork from './ItemArtwork';
import ItemCost from './ItemCost';
import ItemDecoration from './ItemDecoration';
import PS from './PromostandardsLabel';
import TextInput from './TextInput';
import { getUomValues, getAvailableCharges, isPSChargeEditable } from '../promostandard_utils';
import { isValidExtLocationId } from '../helpers/ps_locations';

const DeleteItemLocation = ({ onDelete, locked }) => (
  <td>
    {!locked &&
      <a
        className="delete"
        onClick={e => {
          e.preventDefault();
          onDelete();
        }}
      >
        &times;
      </a>
    }
  </td>
);

const ItemLocationTitle = ({ display_order, isPS, locked, onChange, onUpdate, title }) => {
  const locationId = title.match(/^Location [0-9]*:: /) ? title.substring(0, title.lastIndexOf(':: ')) : '';
  const baseTitle = title.match(/^Location [0-9]*:: /) ? title.substring(title.lastIndexOf(':: ') + 3) : title;
  return (
    <td colSpan={2}>
      <label>{locationId}</label>
      <div style={{ position: 'relative' }}>
        {isPS && <PS style={{ position: 'absolute', top: '0.5rem', left: '0.5rem' }} />}
        <TextInput
          style={isPS ? { paddingLeft: '2.5rem' } : {}}
          disabled={locked || isPS}
          selectOnFocus={true}
          value={baseTitle}
          placeholder={`Location ${parseInt(display_order, 10) + 1}`}
          onChange={e => onChange((locationId ? locationId + ':: ' : locationId) + e.target.value)}
          onBlur={e => onUpdate((locationId ? locationId + ':: ' : locationId) + e.target.value)}
        />
      </div>
    </td>
  );
};

const AddRunChargeButton = ({ onAdd }) => (
  <a onClick={e => { e.preventDefault(); onAdd(); }}>
    + Run charge for this decoration
  </a>
);

const AddFixedChargeButton = ({ onAdd }) => (
  <a onClick={e => { e.preventDefault(); onAdd(); }}>
    + Fixed charge for this decoration
  </a>
);

const CopyLocationButton = ({ onCopy }) => (
  <a onClick={e => { e.preventDefault(); onCopy(); }}>
    + Copy item location
  </a>
);

const AddPSChargeMenu = ({ onAdd, charge_type, getCharges, children }) => {
  const options = getCharges(charge_type).map(
    c => ({
      key: c.ps_charge_id,
      value: !!c.is_divider ? c.description : <span><PS />{c.description || c.name}</span>,
      divider: !!c.is_divider,
      onClick: !c.is_divider ? () => {
        onAdd(c.description, c.ps_charge_id);
      } : null
    })
  );
  return options.length > 0 && (
    <DropdownMenu options={options} optionsProps={{ hideOnClick: true }}>
      <MenuTrigger>
        <a onClick={e => e.preventDefault()}>
          {children}
        </a>
      </MenuTrigger>
    </DropdownMenu>
  );
};

class ItemLocation extends Component {
  isPS() {
    return isValidExtLocationId(this.props.item_location.ext_location_id);
  }

  renderNonPS() {
    const {
      artworks,
      breakdown_quantity,
      breakdown_cost,
      item_costs,
      item_location,
      onAddItemCost,
      onChange,
      onCopy,
      onEditArtwork,
      onDelete,
      onDeleteItemCost,
      onUpdate,
      onUpdateItemCost,
      locked,
      onCreateApplyMatrixToItemPopup,
      field_lock = 'unit_cost',
      exchange_rate = 1.0
    } = this.props;
    const onDeleteItemLocation = () => onDelete(item_location.item_location_id);
    const onChangeTitle = onChange(item_location.item_location_id, 'item_location_title', item_location.item_location_title);
    const onUpdateTitle = onUpdate(item_location.item_location_id, 'item_location_title', item_location.item_location_title);
    const onAddRunCharge = () => onAddItemCost(item_location.item_id, item_location.item_location_id, null);
    const onAddFixedCharge = () => onAddItemCost(item_location.item_id, item_location.item_location_id, 1);
    const onCopyLocation = () => onCopy(item_location.item_id, [item_location.item_location_id], 'COPY','yes');

    return (
      <tbody>
        <tr className="separator">
          <DeleteItemLocation onDelete={onDeleteItemLocation} locked={locked} />
          <ItemLocationTitle
            display_order={item_location.display_order}
            isPS={false}
            locked={locked}
            onChange={onChangeTitle}
            onUpdate={onUpdateTitle}
            title={item_location.item_location_title}
          />
          <ItemArtwork
            artworks={artworks}
            isPS={false}
            onEditArtwork={onEditArtwork(item_location.item_id, item_location.item_location_id)}
            locked={locked}
          />
          <ArtworkProofRequired artworks={artworks} />
          <td colSpan={3}></td>
        </tr>
        {item_costs.map(ic =>
          <ItemCost
            key={ic.item_cost_id}
            item_cost={ic}
            breakdown_quantity={breakdown_quantity}
            breakdown_cost={breakdown_cost}
            onUpdate={onUpdateItemCost(breakdown_cost)}
            onDelete={onDeleteItemCost}
            locked={locked}
            editPS={true}
            onCreateApplyMatrixToItemPopup={onCreateApplyMatrixToItemPopup}
            field_lock={field_lock}
            exchange_rate={exchange_rate}
          />
        )}
        {!locked &&
          <tr>
            <td colSpan={10}>
              <AddRunChargeButton onAdd={onAddRunCharge} />
              <AddFixedChargeButton onAdd={onAddFixedCharge} />
              <CopyLocationButton onCopy={onCopyLocation} />
            </td>
          </tr>
        }
      </tbody>
    );
  }

  render() {
    if (!this.isPS()) {
      return this.renderNonPS();
    }
    const {
	    product,
      artworks,
      breakdown_quantity,
      breakdown_cost,
      item_costs,
      item_decorations,
      item_location,
      ps_location,
      onAddItemCost,
      onChange,
      onCopy,
      onEditArtwork,
      onDelete,
      onDeleteItemCost,
      onUpdate,
      onUpdateItemCost,
      locked,
      isPSChargeMandatory,
      editPS,
      quirks,
      division_id,
      field_lock = 'unit_cost',
      exchange_rate = 1.0
    } = this.props;
    const onDeleteItemLocation = () => onDelete(item_location.item_location_id);
    const onChangeTitle = onChange(item_location.item_location_id, 'item_location_title', item_location.item_location_title);
    const onUpdateTitle = onUpdate(item_location.item_location_id, 'item_location_title', item_location.item_location_title);
    const onAddRunCharge = () => onAddItemCost(item_location.item_id, item_location.item_location_id, null);
    const onAddFixedCharge = () => onAddItemCost(item_location.item_id, item_location.item_location_id, 1);
    const onAddPSRunCharge = item_decoration_id => (charge_name, charge_id) => onAddItemCost(
      item_location.item_id,
      item_location.item_location_id,
      null,
      'MISCELLANEOUS',
      charge_name,
      item_decoration_id,
      charge_id
    );
    const onAddPSFixedCharge = item_decoration_id => (charge_name, charge_id) => onAddItemCost(
      item_location.item_id,
      item_location.item_location_id,
      1,
      'MISCELLANEOUS',
      charge_name,
      item_decoration_id,
      charge_id
    );
    const onCopyLocation = () => onCopy(item_location.item_id, [item_location.item_location_id], 'COPY','yes');
    const isPS = this.isPS();
    const isPSConfigurable = isPS && !!ps_location;

    const item_decoration = item_decorations.filter(id => id.item_location_id === item_location.item_location_id)[0];
    const decorations = get(ps_location, 'decorations') || [];

    return (
      <DraggableItemContainer
        type={DragItemTypes.LOCATION}
        index={this.props.index}
        onDrop={this.props.onDrop}
        onMove={this.props.onMove}
        item={item_location}
        canDrag={!(locked)}
        id={this.props.id}
      >
        <tbody>
          <tr className="separator">
            <DeleteItemLocation onDelete={onDeleteItemLocation} locked={locked} />
            <ItemLocationTitle
              display_order={item_location.display_order}
              isPS={isPS}
              locked={locked}
              onChange={onChangeTitle}
              onUpdate={onUpdateTitle}
              title={item_location.item_location_title}
            />
            {(!!item_decoration && !!item_decoration.ext_decoration_id && item_decoration.imprint_id) && <ItemArtwork
              isPS={isPS}
              artworks={artworks}
              onEditArtwork={onEditArtwork(item_location.item_id, item_location.item_location_id, item_decoration.item_decoration_id, item_decoration.imprint_id, item_decoration.ext_decoration_id)}
              locked={locked}
            />}
            <ArtworkProofRequired artworks={artworks} />
            <td colSpan={3}>
            </td>
          </tr>
          {item_decorations.map(id => {
            const decoration = find(decorations, ({ ps_decoration_id }) => {
              return `${ps_decoration_id}` == id.ext_decoration_id;
            });
            const uoms = getUomValues(id);
            const getCharges = charge_type => getAvailableCharges(
              get(decoration, 'charges') ?? [],
              quirks,
              division_id
            )(
              charge_type,
              item_location.display_order,
              uoms,
              item_costs.map(ic => ic.ext_cost_id).filter(cid => !!cid)
            );
            return [
              <ItemDecoration
                key={id.item_decoration_id}
                product={product}
                artworks={artworks.filter(a => a.item_decoration_id === id.item_decoration_id)}
                decorations={decorations}
                item_decoration={id}
                locked={locked}
              />,
              ...item_costs.filter(ic => ic.item_decoration_id === id.item_decoration_id).map(ic => {
                const charge = find(get(decoration, 'charges'), ({ ps_charge_id }) => {
                  return ic.ext_cost_id == `${ps_charge_id}`;
                });
                return <ItemCost
                  key={ic.item_cost_id}
                  item_cost={ic}
                  breakdown_quantity={breakdown_quantity}
                  breakdown_cost={breakdown_cost}
                  onUpdate={onUpdateItemCost(breakdown_cost)}
                  onDelete={onDeleteItemCost}
                  locked={locked}
                  editPS={editPS}
                  editPSTitle={isPSChargeEditable(charge)}
                  mandatory={isPSChargeMandatory(charge)}
                  field_lock={field_lock}
                  exchange_rate={exchange_rate}
                />;
              }),
              !locked &&
                <tr key={`${id.item_decoration_id}-actions`}>
                  <td colSpan={10}>
                    <AddPSChargeMenu
                      charge_type="RUN"
                      getCharges={getCharges}
                      onAdd={onAddPSRunCharge(id.item_decoration_id)}
                    >
                    <PS style={{ marginRight: '0.25rem' }} />Add run charge
                    </AddPSChargeMenu>
                    <AddPSChargeMenu
                      charge_type="SETUP"
                      getCharges={getCharges}
                      onAdd={onAddPSFixedCharge(id.item_decoration_id)}
                    >
                      <PS style={{ marginRight: '0.25rem' }} />Add fixed charge
                    </AddPSChargeMenu>
                  </td>
                </tr>
            ];
          })}
          {(!locked && !isPSConfigurable) &&
            <tr>
              <td colSpan={10}>
                <AddRunChargeButton onAdd={onAddRunCharge} />
                <AddFixedChargeButton onAdd={onAddFixedCharge} />
                <CopyLocationButton onCopy={onCopyLocation} />
              </td>
            </tr>
          }
        </tbody>
      </DraggableItemContainer>
    );
  }
}

export default ItemLocation;
