import { get, filter, map, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@commonsku/styles';

import DropdownMenu, { MenuTrigger, MenuOptions } from '../components/DropdownMenu';
import { createFetchRecentOrders } from '../actions/header';
import Loading from '../components/Loading';
import NewProjectPopup from '../components/project/NewProjectPopup';

const NEW_PROJECT_BUTTON_STYLE = {
  width: '100%',
  padding: '0.5rem',
  textAlign: 'center',
  backgroundColor: '#5ca2b6',
  color: '#fff',
  lineHeight: '0.8rem',
  fontSize: '0.8rem'
};

class RecentDropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      show_new_project_popup: false
    };

    this.renderLink = this.renderLink.bind(this);
  }

  renderLink({
    job_name, job_number, client_name, order_type = '', form_number,
  }, key) {
    const type = order_type.replace(' ', '-').toLowerCase();
    let href = `/project/${job_number}`;
    if (type !== 'opportunity') {
      href += `/${type}/${form_number}`;
    }
    return <li key={key}>
      <a href={href}># {form_number} {job_name} - {client_name}</a>
    </li>;
  }

  renderOrders(filters) {
    return map(filter(this.props.orders, filters), this.renderLink);
  }

  render() {
    const { loaded } = this.state;
    const { fetchRecentOrders, orders } = this.props;

    return <>
      {this.state.show_new_project_popup && <NewProjectPopup
        onClose={() => {
          this.setState({ show_new_project_popup: false });
        }} />}      
      <DropdownMenu
        className="recent"
        onDropdownShow={() => {
          if (!loaded && isEmpty(orders)) {
            fetchRecentOrders().then(() => {
              this.setState({ loaded: true });
            });
          }
        }}
      >
        <MenuTrigger>
          <i className="fi-clock"></i>
        </MenuTrigger>
        <MenuOptions align="right">
          {(!loaded && isEmpty(orders)) ? <Loading/> : <>
            <li>
              <ul className="quick-create">
                <li>
                  <Button variant="primary" onClick={() => this.setState({ show_new_project_popup: true })} style={NEW_PROJECT_BUTTON_STYLE}>New Project</Button>
                </li>
              </ul>
            </li>
            <li>
              <ul id="recent_opportunities_link">
                <li><h5>Recent Opportunities</h5></li>
                {this.renderOrders({order_type: 'OPPORTUNITY'})}
              </ul>
            </li>
            <li>
              <ul id="recent_presentations_link">
                <li><h5>Recent Presentations</h5></li>
                {this.renderOrders({order_type: 'PRESENTATION'})}
              </ul>
            </li>
            <li>
              <ul id="recent_estimates_link">
                <li><h5>Recent Estimates</h5></li>
                {this.renderOrders({order_type: 'ESTIMATE'})}
              </ul>
            </li>
            <li>
              <ul id="recent_orders_link">
                <li><h5>Recent Orders</h5></li>
                {this.renderOrders({order_type: 'SALES ORDER'})}
              </ul>
            </li>
            <li>
              <ul id="recent_invoices_link">
                <li><h5>Recent Invoices</h5></li>
                {this.renderOrders({order_type: 'INVOICE'})}
              </ul>
            </li>
          </>}
        </MenuOptions>
      </DropdownMenu>
    </>;
  }
};

const mapStateToProps = (state) => {
  return {
    identity: state.identity,
    orders: get(state, 'entities.recent_orders', {}),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRecentOrders: () => {
    return dispatch(createFetchRecentOrders());
  }
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(RecentDropdownMenu);

