import React from 'react';

const Icon = React.forwardRef(
  ({
    className,
    children,
    size=20,
    isActive = false,
    ...props
  }, ref) => {
    let ChildElem = children;
    const height = size;
    const width = size;
    const viewBox = "0 -960 960 960";
    const svgColor = isActive ? '#fff' : 'var(--color-teal-70)';

    switch (children) {
      case 'format_bold':
      case 'bold':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M275-200v-560h228q66 0 114.5 42T666-612q0 38-21 70t-56 49v6q43 14 69.5 50t26.5 81q0 68-52.5 112T510-200H275Zm86-76h144q38 0 66-25t28-63q0-37-28-62t-66-25H361v175Zm0-247h136q35 0 60.5-23t25.5-58q0-35-25.5-58.5T497-686H361v163Z"/></svg>;
        break;
      case 'format_italic':
      case 'italic':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M224-199v-80h134l139-409H338v-80h380v80H584L445-279h159v80H224Z"/></svg>;
        break;
      case 'format_underlined':
      case 'underlined':
      case 'underline':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M200-140v-60h560v60H200Zm280-140q-100 0-156.5-58.5T267-497v-343h83v343q0 63 34 101t96 38q62 0 96-38t34-101v-343h83v343q0 100-56.5 158.5T480-280Z"/></svg>;
        break;
      case 'code':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M320-242 80-482l242-242 43 43-199 199 197 197-43 43Zm318 2-43-43 199-199-197-197 43-43 240 240-242 242Z"/></svg>;
        break;
      case 'looks_one':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M492-277h60v-406H409v60h83v346ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>;
        break;
      case 'looks_two':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M365-277h230v-60H425v-115h110q24 0 42-18t18-42v-111q0-24-18-42t-42-18H365v60h170v111H425q-24 0-42 18t-18 42v175ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>;
        break;
      case 'h1':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M200-280v-400h60v170h180v-170h60v400h-60v-170H260v170h-60Zm500 0v-340h-80v-60h140v400h-60Z"/></svg>;
        break;
      case 'h2':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-400h60v170h180v-170h60v400h-60v-170H180v170h-60Zm420 0v-170q0-24.75 17.625-42.375T600-510h180v-110H540v-60h240q25 0 42.5 17.625T840-620v110q0 24.75-17.625 42.375T780-450H600v110h240v60H540Z"/></svg>;
        break;
      case 'h3':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-400h60v170h180v-170h60v400h-60v-170H180v170h-60Zm420 0v-60h240v-110H620v-60h160v-110H540v-60h240q25 0 42.5 17.625T840-620v280q0 24.75-17.625 42.375T780-280H540Z"/></svg>;
        break;
      case 'h4':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-400h60v170h180v-170h60v400h-60v-170H180v170h-60Zm620 0v-130H540v-270h60v210h140v-210h60v210h80v60h-80v130h-60Z"/></svg>;
        break;
      case 'h5':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-400h60v170h180v-170h60v400h-60v-170H180v170h-60Zm420 0v-60h240v-110H540v-230h300v60H600v110h180q24.75 0 42.375 17.625T840-450v110q0 24.75-17.625 42.375T780-280H540Z"/></svg>;
        break;
      case 'h6':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-400h60v170h180v-170h60v400h-60v-170H180v170h-60Zm480 0q-24.75 0-42.375-17.625T540-340v-280q0-24.75 17.625-42.375T600-680h240v60H600v110h180q24.75 0 42.375 17.625T840-450v110q0 24.75-17.625 42.375T780-280H600Zm0-170v110h180v-110H600Z"/></svg>;
        break;
      case 'format_quote':
      case 'blockquote':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M580-500h160v-160H580v160Zm-360 0h160v-160H220v160Zm406 220 80-160H520v-280h280v288l-76 152h-98Zm-360 0 80-160H160v-280h280v288l-76 152h-98Zm34-300Zm360 0Z"/></svg>;
        break;
      case 'format_list_numbered':
      case 'ordered list':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-80v-60h100v-30h-60v-60h60v-30H120v-60h120q17 0 28.5 11.5T280-280v40q0 17-11.5 28.5T240-200q17 0 28.5 11.5T280-160v40q0 17-11.5 28.5T240-80H120Zm0-280v-110q0-17 11.5-28.5T160-510h60v-30H120v-60h120q17 0 28.5 11.5T280-560v70q0 17-11.5 28.5T240-450h-60v30h100v60H120Zm60-280v-180h-60v-60h120v240h-60Zm189 431v-60h471v60H369Zm0-243v-60h471v60H369Zm0-243v-60h471v60H369Z"/></svg>;
        break;
      case 'format_list_bulleted':
      case 'bullet list':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M377-198v-60h463v60H377Zm0-252v-60h463v60H377Zm0-253v-60h463v60H377ZM189-161q-28.05 0-48.025-19Q121-199 121-227.5t19.5-48q19.5-19.5 48-19.5t47.5 19.975Q255-255.05 255-227q0 27.225-19.387 46.613Q216.225-161 189-161Zm0-252q-28.05 0-48.025-19.681Q121-452.362 121-480t19.975-47.319Q160.95-547 189-547q27.225 0 46.613 19.681Q255-507.638 255-480t-19.387 47.319Q216.225-413 189-413Zm-1-253q-27.637 0-47.319-19.681Q121-705.362 121-733t19.681-47.319Q160.363-800 188-800q27.637 0 47.319 19.681Q255-760.638 255-733t-19.681 47.319Q215.637-666 188-666Z"/></svg>;
        break;
      case 'format_align_left':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-120v-60h720v60H120Zm0-165v-60h472v60H120Zm0-165v-60h720v60H120Zm0-165v-60h472v60H120Zm0-165v-60h720v60H120Z"/></svg>;
        break;
      case 'format_align_center':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-120v-60h720v60H120Zm164-165v-60h393v60H284ZM120-450v-60h720v60H120Zm164-165v-60h393v60H284ZM120-780v-60h720v60H120Z"/></svg>;
        break;
      case 'format_align_right':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-120v-60h720v60H120Zm249-165v-60h471v60H369ZM120-450v-60h720v60H120Zm249-165v-60h471v60H369ZM120-780v-60h720v60H120Z"/></svg>;
        break;
      case 'format_align_justify':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-120v-60h720v60H120Zm0-165v-60h720v60H120Zm0-165v-60h720v60H120Zm0-165v-60h720v60H120Zm0-165v-60h720v60H120Z"/></svg>;
        break;
      case 'horizontal rule':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M160-450v-60h640v60H160Z"/></svg>;
        break;
      case 'code block':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="m379-343 44-44-93-93 92-92-44-44-136 136 137 137Zm202 0 137-137-137-137-44 44 93 93-93 93 44 44ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>;
        break;
      case 'paragraph':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M370-160v-260h-20q-78.85 0-134.425-55.606Q160-531.212 160-610.106T215.575-744.5Q271.15-800 350-800h370v60h-90v580h-60v-580H430v580h-60Z"/></svg>;
        break;
      case 'clear nodes':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M120-280v-60h560v60H120Zm80-170v-60h560v60H200Zm80-170v-60h560v60H280Z"/></svg>;
        break;
      case 'strike':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M504-160q-78 0-142-41.5T269-313l69-29q20 48 65 77t101 29q52 0 83-27t31-73q0-23-9.5-48.5T582-430h84q14 23 21 46t7 48q0 78-53 127t-137 49ZM80-490v-60h800v60H80Zm394-316q66 0 117 31t75 86l-69 31q-14-34-46.5-53T474-730q-49 0-79 24t-30 66q0 8 1 15t3 15h-74q-2-8-3-16t-1-16q0-73 51-118.5T474-806Z"/></svg>;
        break;
      case 'link':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="M450-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h170v60H280q-58.333 0-99.167 40.765-40.833 40.764-40.833 99Q140-422 180.833-381q40.834 41 99.167 41h170v60ZM325-450v-60h310v60H325Zm185 170v-60h170q58.333 0 99.167-40.765 40.833-40.764 40.833-99Q820-538 779.167-579 738.333-620 680-620H510v-60h170q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H510Z"/></svg>;
        break;
      case 'link_off':
        ChildElem = <svg xmlns="http://www.w3.org/2000/svg" fill={svgColor} height={height} viewBox={viewBox} width={width}><path d="m750-291-49-49q51-10 85-48t34-89q0-58-41-99t-99-41H525v-60h155q83 0 141.5 58.5T880-477q0 62-36 112t-94 74ZM594-447l-60-60h101v60h-41ZM814-56 63-807l43-43L857-99l-43 43ZM450-280H280q-83 0-141.5-58.5T80-480q0-72 44.5-127T238-676l56 56h-14q-58 0-99 41t-41 99q0 58 41 99t99 41h170v60ZM325-450v-60h79l60 60H325Z"/></svg>;
        break;
      default:
        break;
    }

    return (
      <span
        {...props}
        ref={ref}
        className={`material-symbols-outlined ${className}`}
        style={{
          verticalAlign: 'text-bottom',
          padding: '7px 8px 0px',
          borderRadius: 100,
          margin: 0,
          cursor: 'pointer',
          ...(isActive ? {
            background: 'var(--color-teal-70)',
          } : {}),
          ...props.style,
        }}
      >{ChildElem}</span>
    );
  }
);

export default Icon;
