import { oauth } from '../utils';

export const UPDATE_PUBLIC_VIEW_PROPERTY_REQUEST = 'UPDATE_PUBLIC_VIEW_PROPERTY_REQUEST';
export const UPDATE_PUBLIC_VIEW_PROPERTY_SUCCESS = 'UPDATE_PUBLIC_VIEW_PROPERTY_SUCCESS';
export const UPDATE_PUBLIC_VIEW_PROPERTYS_SUCCESS = 'UPDATE_PUBLIC_VIEW_PROPERTYS_SUCCESS';
export const UPDATE_PUBLIC_VIEW_PROPERTY_FAILURE = 'UPDATE_PUBLIC_VIEW_PROPERTY_FAILURE';

export const DELETE_PUBLIC_VIEW_PROPERTY_REQUEST = 'DELETE_PUBLIC_VIEW_PROPERTY_REQUEST';
export const DELETE_PUBLIC_VIEW_PROPERTY_SUCCESS = 'DELETE_PUBLIC_VIEW_PROPERTY_SUCCESS';
export const DELETE_PUBLIC_VIEW_PROPERTY_FAILURE = 'DELETE_PUBLIC_VIEW_PROPERTY_FAILURE';

const createFinalizeUpdatePublicViewProperty = (public_view_id, public_view_type, public_view_property) => ({
  type: UPDATE_PUBLIC_VIEW_PROPERTY_SUCCESS,
  payload: {
    public_view_id,
    public_view_type,
    public_view_property,
  }
});

const createFinalizeDeletePublicViewProperty = (public_view_id, parent_type, field) => ({
  type: DELETE_PUBLIC_VIEW_PROPERTY_SUCCESS,
  payload: {
    public_view_id,
    parent_type,
    field,
  }
});

export const createUpdatePublicViewProperty = (public_view_id, parent_type, field, value) => (dispatch, getState) => oauth(
  'POST', 'public-view-property',
  { public_view_id, parent_type, field, value }
).then(
  ({ json }) => {
    return dispatch(createFinalizeUpdatePublicViewProperty(public_view_id, parent_type, json.template_data));
  }
);

export const createDeletePublicViewProperty = (public_view_id, parent_type, field) => dispatch => oauth(
  'DELETE', 'public-view-property',
  { public_view_id, parent_type, field }
).then(
  ({ json }) => dispatch(createFinalizeDeletePublicViewProperty(public_view_id, parent_type, field))
);
