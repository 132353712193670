import { createSelector } from '@reduxjs/toolkit';

export const getEventTypes = s => s.entities.event_types;
export const getEventTypesList = createSelector(
    getEventTypes,
    s => Object.values(s || {})
);

const eventTypeOptionMapFunc = v => ({
    label: `${v.event_type}`.trim(),
    value: v.event_type_id
});

export const eventTypeOptions = createSelector(
    getEventTypesList,
    s => s.map(eventTypeOptionMapFunc)
);
