import React from "react";
import { Button, Csku, ImageIcon, Text, } from "@commonsku/styles";
import Img from "../../../Img";

export default function CarouselImagesBlock(props) {
  const {
    value,
    onClick,
  } = props;

  return (
    <>
      <Text as="p" style={{
        color: 'var(--color-neutrals-90)',
        fontFamily: 'var(--font-family-regular)',
        fontSize: 16,
        marginBottom: 0,
      }}>Carousel Images</Text>
      {!value || value.length === 0 ? <>
        <Csku as="p"
          onClick={onClick}
          style={{
            color: 'var(--color-primary1-main)',
            fontFamily: 'var(--font-family-bold)',
            fontSize: 16,
            marginBottom: 0,
            padding: 16,
            paddingBottom: 0,
            borderRadius: 3,
            cursor: 'pointer',
          }}
          sx={{
            background: 'var(--color-neutrals-40)',
            ':hover': {
              background: 'var(--color-neutrals-30)',
            },
          }}
        >
          <ImageIcon style={{ verticalAlign: 'middle' }} />
          <span style={{ paddingLeft: 7 }}>Add Images</span>
        </Csku>
        <Text as="p" style={{
          color: 'var(--color-neutrals-70)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 13,
        }}>Preferred size: 1400 x 1000</Text>
      </> : <CarouselImages carouselImages={value} onClick={onClick} />}
      <Button
        variant="primary"
        size="medium"
        style={{ width: '100%' }}
        onClick={onClick}
      >Edit Carousel Images</Button>
    </>
  );
}

function CarouselImages(props) {
  const { carouselImages, onClick } = props;
  return (
    <div onClick={onClick} style={{
      position: 'relative',
      maxWidth: 500,
      height: 70,
      margin: 0,
      marginBottom: 8,
      overflowX: 'hidden',
      overflowY: 'hidden',
    }}>
      <ul style={{
        height: 88,
        marginLeft: 0,
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
      }}>
        {carouselImages.slice(0, 3).map((image, idx) => (
          <li key={`carouselImage-${image.key || idx}-${image.original || image.image}`}
            style={{
              display: 'inline-block',
              width: 88,
              height: '100%',
              padding: '8px',
              boxSizing: 'border-box',
            }}
          >
            <Img alt="" src={image.original || image.image} style={{ width: 80, display: 'block', }} />
          </li>
        ))}
      </ul>
      {carouselImages.length > 2 ? <div style={{
        padding: 8,
        position: 'absolute',
        top: 8,
        height: 85,
        bottom: 0,
        width: '2em',
        right: 0,
        background: 'var(--color-neutrals-60)',
        opacity: '0.8',
        color: 'var(--color-primary1-main)',
        paddingTop: 12,
        fontWeight: 'bold',
      }}>
          <span>+{carouselImages.length-2}</span>
      </div> : null}
    </div>
  );
}
