import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';

import ProductArtworkItemLocation from './ProductArtworkItemLocation';

import { getImageSrc } from '../utils';
import ProofType from '../models/ProofType';
import ProductItemSummary from './BaseItemSummary';
import LabelledCheckbox from './LabelledCheckbox';
import Img from './Img';

import { createArtworkPopup } from '../actions/popup';

class ProductArtworkSummary extends ProductItemSummary {

  constructor(props) {
    super(props);
  }

  renderItem() {
    const { item, order, onEditArtwork, locked } = this.props;
    const default_image = item.item_images.map(ii => ii.image)[0];

    const isProofRequired = !!find(item.artworks, (artwork) => {
      return artwork.proof_id !== ProofType.None;
    });
    return (
      <tr>
        <td className="description">
          <div className="image">
            <Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOver('item')} onMouseOut={this.handleMouseOut('item')} />
          </div>
          <a className="product-name">{item.item_name}</a>
          {!isProofRequired ? null : <div>
            <Img src={'/images/jq.toast.notice.png'} style={{ height: 15, marginRight: 5 }}/>
            <span style={{ color: '#A0A0A0' }}>Proof Required</span>
          </div>}
        </td>
        <td>
          {item.item_locations.map(il =>
            <div key={il.item_location_id} style={{display: 'inline-block', float: 'left', width: '45%'}}>
              <label style={{textAlign: 'left'}}>
                {!!find(il.artworks, (artwork) => {
                  return artwork.proof_id !== ProofType.None;
                }) ? <Img src={'/images/jq.toast.notice.png'} style={{ height: 20, marginRight: 5 }}/> : null}
                {il.item_location_title.replace(/:: /, ': ')}
              </label>
              <div style={{width: '100%', textAlign: 'left'}}>
                <ProductArtworkItemLocation item_location={il} item_costs={item.item_costs.filter(ic => ic.item_location_id === il.item_location_id)} onEditArtwork={onEditArtwork(order.client_id, 'CLIENT')} locked={locked || !!item.hasInventoryBreakdowns} />
              </div>
            </div>
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = state.entities.orders[ownProps.item.order_id];
  return {
    order,
    locked: 1 == order.locked
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onEditArtwork: (account_id, account_type) => (item_id, item_location_id, item_decoration_id, imprint_id, ext_artwork_id) => (artwork, locked, copy) => {
      return dispatch(createArtworkPopup({ item_id, item_location_id, item_decoration_id, imprint_id, ext_artwork_id, ...artwork }, locked, copy, account_id, account_type));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductArtworkSummary);
