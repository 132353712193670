import styled from 'styled-components';
import BaseReactTooltip from 'react-tooltip';
import { colors } from '@commonsku/styles';

const ReactTooltip = styled(BaseReactTooltip)`
  max-width: 400px;
  &.type-dark.place-top {
    background-color: ${colors.neutrals['100']};
    padding: 5px;

    &:after {
      border-top-color: ${colors.neutrals['100']};
      background-color: ${colors.neutrals['100']};
    }
  }
  &.type-dark.place-bottom {
    background-color: ${colors.neutrals['100']};
    padding: 5px;

    &:after {
      border-bottom-color: ${colors.neutrals['100']};
      background-color: ${colors.neutrals['100']};
    }
  }
  &.type-dark.place-left {
    background-color: ${colors.neutrals['100']};
    padding: 5px;

    &:after {
      border-left-color: ${colors.neutrals['100']};
      background-color: ${colors.neutrals['100']};
    }
  }
  &.type-dark.place-right {
    background-color: ${colors.neutrals['100']};
    padding: 5px;

    &:after {
      border-right-color: ${colors.neutrals['100']};
      background-color: ${colors.neutrals['100']};
    }
  }

  &.type-dark table,
  &.type-dark.place-right table,
  &.type-dark.place-left table,
  &.type-dark.place-top table,
  &.type-dark.place-bottom table {
    margin-bottom: 0;
  }

  &.type-dark table tr th,
  &.type-dark table tr td,
  &.type-dark table thead,
  &.type-dark table tbody {
    background: transparent;
    color: #fff;
    border: none;
  }

  &.type-dark table tr th,
  &.type-dark table tr td,
  &.type-dark.place-right table tr th,
  &.type-dark.place-right table tr td,
  &.type-dark.place-left table tr th,
  &.type-dark.place-left table tr td,
  &.type-dark.place-top table tr th,
  &.type-dark.place-top table tr td,
  &.type-dark.place-bottom table tr th,
  &.type-dark.place-bottom table tr td {
    padding: 2px;
  }

  &.type-success.place-top,
  &.type-success.place-bottom,
  &.type-success.place-left,
  &.type-success.place-right {
    background-color: ${colors.green['20']};
    color: ${colors.green['90']};
    padding: 0.3rem 1rem;
  }
  &.type-success.place-top {
    &:after {
      border-top-color: ${colors.green['20']};
      background-color: ${colors.green['20']};
    }
  }
  &.type-success.place-bottom {
    &:after {
      border-bottom-color: ${colors.green['20']};
      background-color: ${colors.green['20']};
    }
  }
  &.type-success.place-left {
    &:after {
      border-left-color: ${colors.green['20']};
      background-color: ${colors.green['20']};
    }
  }
  &.type-success.place-right {
    &:after {
      border-right-color: ${colors.green['20']};
      background-color: ${colors.green['20']};
    }
  }
`;

export default ReactTooltip;
