import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState, } from 'react';
import { Input, LabeledInput } from '@commonsku/styles';

function NumberInput({
    defaultValue = "",
    onChange,
    onFocus,
    onBlur,
    inputMode,
    label=null,
    localeOptions={},
    icon=null,
    onClickIcon=null,
    iconStyles={},
    ...props
}) {
    const inputRef = useRef(null);
    const [state, setState] = useState({
        last_value: +defaultValue || '',
        value: +defaultValue || '',
        type: 'number',
    });

    useEffect(() => {
        numToStr(+defaultValue);
    }, []);

    function checkMin() {
        return !((props.min !== null
            && props.min !== undefined
            && !isNaN(props.min)
            && !isNaN(inputRef.current.value)
            && props.min > inputRef.current.value));
    }

    function strToNum() {
        setState(s => ({
            ...s,
            type: 'number',
            value: s.last_value,
        }));
    }

    function numToStr(num = state.value) {
        setState(s => ({
            ...s,
            type: '',
            last_value: num || '',
            value: num === '' ? '' : (+num).toLocaleString(undefined, localeOptions),
        }));
    }

    function handleChange() {
        if (!checkMin()) { return; }

        setState(s => ({...s, value: inputRef.current.value }));
        onChange && onChange(inputRef.current);
    }

    function handleBlur(e) {
        if (!checkMin()) { return; }

        numToStr();
        onBlur && onBlur(e);
    }

    function handleFocus(e) {
        if (!checkMin()) { return; }

        strToNum();
        onFocus && onFocus(e);
    }

    function clearInput() {
        setState(s => ({...s, value: '0', last_value: 0,}));
        onChange && onChange(0);
    }

    const inputProps = {
        ...props,
        ref: inputRef,
        value: state.value,
        inputMode: inputMode || 'decimal',
        type: state.type,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
    };

    const handleClickIcon = () => {
        if (onClickIcon) {
            const action = onClickIcon(state.value);
            if (action && action == 'clear') {
                clearInput();
            }
        }
    };

    return (
        <>
            {label ? <LabeledInput label={label} {...inputProps} /> : <Input {...inputProps} />}
            {icon && state.type !== 'number' ? <span style={{ fontStyle: 'normal', cursor: 'pointer', position: 'absolute', marginLeft: '-35px', marginTop: '5px', ...(iconStyles) }} onClick={handleClickIcon}>
                {icon}
            </span> : null}
        </>
    );
}

NumberInput.propTypes = {
    value           : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label           : PropTypes.string,
    inputMode       : PropTypes.string,
    onChange        : PropTypes.func,
    onBlur          : PropTypes.func,
    onFocus         : PropTypes.func,
    min             : PropTypes.number,
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    localeOptions   : PropTypes.shape({
        maximumFractionDigits   : PropTypes.number,
        minimumSignificantDigits: PropTypes.number,
        maximumSignificantDigits: PropTypes.number,
        numberingSystem         : PropTypes.string,
        currencySign            : PropTypes.string,
        currency                : PropTypes.string,
        unit                    : PropTypes.string,
        style                   : PropTypes.oneOf(['decimal', 'currency', 'percent', 'unit']),
        unitDisplay             : PropTypes.oneOf(['long', 'short', 'narrow']),
        currencyDisplay         : PropTypes.oneOf(['symbol', 'narrowSymbol', 'code', 'name']),
        notation                : PropTypes.oneOf(['standard', 'scientific', 'engineering', 'compact']),
        signDisplay             : PropTypes.oneOf(['auto', 'never', 'always', 'exceptZero']),
        localeMatcher           : PropTypes.string,
        useGrouping             : PropTypes.bool,
        minimumIntegerDigits    : PropTypes.number,
        minimumFractionDigits   : PropTypes.number,
    }),
};

export default NumberInput;
