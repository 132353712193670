import React, { useState } from 'react';
import { ProductCard } from '@commonsku/styles';
import { remove } from 'lodash';
import { getImageSrc } from '../utils';
import { window } from '../global';

const NewProduct = ({
  product,
  loading,
  loaded,
  isProductsPage,
  supplier_popup,
  onClickProduct,
  onClickProductAction,
  onSubTitleClick,
  title,
  sku,
  description,
  subtitle
}) => {
  const [supplierPopupSelected, setSupplierPopupSelected] = useState(false);
  const getImage = () => {
    if (product.product_img) {
      return product.product_img.replace('files', '/files');
    }
    if (product.item_images) {
      const indexed_files = product.files.reduce((o, f) => ({ ...o, [f.file_id]: f }), {});
      const default_image = product.item_images.map(ii => indexed_files[ii.file_id])[0];
      return getImageSrc(default_image, 'medium');
    }
    return null;
  };


  const handleClick = e => {
    e.stopPropagation();
    if (!loading) {
      onClickProduct(product);
    }
  };

  const handleLocalStorage = (product, isAddProduct) => {
    let storage = window.localStorage.getItem('commonsku_supplier_products');
    let saved_items = [];
    if (storage) {
      saved_items = JSON.parse(storage);
    }
    let temp = window.localStorage.getItem('commonsku_add_product_ids');
    let product_ids = [];
    if (temp) {
      product_ids = JSON.parse(temp);
    }
    if (isAddProduct) {
      saved_items.push(product);
      product_ids.push(product.product_id);
    } else {
      remove(saved_items, (item) => item.product_id == product.product_id);
      remove(product_ids, (id) => id == product.product_id);
    }

    window.localStorage.setItem('commonsku_supplier_products', JSON.stringify(saved_items));
    window.localStorage.setItem('commonsku_add_product_ids', JSON.stringify(product_ids));
    setSupplierPopupSelected(isAddProduct);
  };

  const handleClickProductEvent = () => {
    if (supplier_popup) {
      handleLocalStorage(product, !supplierPopupSelected);
      return;
    }
    onClickProductAction();
  };
  return (
    <ProductCard
      handleClick={handleClick}
      loading={loading}
      selected={loaded || supplierPopupSelected}
      showButton={!isProductsPage || supplier_popup}
      imgUrl={getImage()}
      title={title}
      description={description}
      sku={sku}
      handleSubTitleClick={onSubTitleClick}
      subTitle={subtitle}
      handleClickProductEvent={handleClickProductEvent}
    />
  );
};

export default NewProduct;
