import React, { Component } from 'react';

import { getOrderName, toTitleCase } from '../utils';
import ActionMenu from './ActionMenu';
import { withRouter } from './helpers';

class ProjectTitle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_full: false
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({ show_full: true });
    }

    handleMouseLeave() {
        this.setState({ show_full: false });
    }

    getPageType() {
      const { location: { pathname } } = this.props;
      if (pathname.match(/collaborate/)) {
        return 'collaborate';
      } else if (pathname.match(/presentation/)) {
        return 'presentation';
      } else if (pathname.match(/estimate/)) {
        return 'estimate';
      } else if (pathname.match(/sales-order/)) {
        return 'sales-order';
      } else if (pathname.match(/invoice/)) {
        return 'invoice';
      } else if (pathname.match(/bills/)) {
        return 'bills';
      } else if (pathname.match(/shipping/)) {
        return 'shipping';
      } else if (pathname.match(/production/)) {
        return 'production';
      } else if (pathname.match(/commissions/)) {
        return 'commissions';
      } else if (pathname.match(/feedback/)) {
        return 'feedback';
      }
    }

    render() {
        const { project, order, location, panel } = this.props;
        const account = project.account;
        const account_link = account ? <a href={`/client.php?id=${project.account_id}`} target="_blank">{account.company_name}</a> : null;
        const order_link = order ? <a href={`/${order.order_type.replace(' ', '_').toLowerCase()}.php?id=${order.order_id}`} target="_blank">
          {toTitleCase(order.order_type)} #{order.form_number}
        </a> : null;
        const page_type = this.getPageType();
        let path = null;
        switch (page_type) {
          case 'collaborate':
            path = 'Collaborate';
            break;
          case 'presentation':
          case 'estimate':
          case 'sales-order':
          case 'invoice':
            if (order) {
              path = order_link;
            }
            break;
          case 'bills':
            path = 'Bills';
            break;
          case 'shipping':
            path = 'Shipping';
            break;
          case 'production':
            path = 'Purchase Orders';
            break;
          case 'commisssions':
            path = 'Commissions';
            break;
          case 'feedback':
            path = 'Feedback';
            break;
        }
        return (
          <div className="columns">
            <h4 style={{ display: 'inline-block' }}>
              {account_link}
              <span>{account && '—'} Project #{project.job_number}&nbsp;
                  <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                      {project.job_name.length > 30 && !this.state.show_full ? project.job_name.substring(0, 30) + '...' : project.job_name }
                  </span>
              </span>
              {path ? <span> — </span> : null}
              {path ? path : null}
            </h4>
            <ActionMenu style={{ float: 'right' }} project={project} order={order} page={page_type} />
          </div>
        );
    }
}

export default withRouter(ProjectTitle);
