import { oauth } from '../utils';

import ProofStatus from '../models/ProofStatus';

export const ADD_PROOF_COMMENT_REQUEST = 'ADD_PROOF_COMMENT_REQUEST';
export const ADD_PROOF_COMMENT_SUCCESS = 'ADD_PROOF_COMMENT_SUCCESS';
export const ADD_PROOF_COMMENT_FAILURE = 'ADD_PROOF_COMMENT_FAILURE';
export const ADD_PORTAL_COMMENT_REQUEST = 'ADD_PORTAL_COMMENT_REQUEST';
export const ADD_PORTAL_COMMENT_SUCCESS = 'ADD_PORTAL_COMMENT_SUCCESS';
export const ADD_PORTAL_COMMENT_FAILURE = 'ADD_PORTAL_COMMENT_FAILURE';
export const DELETE_PORTAL_COMMENT_REQUEST = 'DELETE_PORTAL_COMMENT_REQUEST';
export const DELETE_PORTAL_COMMENT_SUCCESS = 'DELETE_PORTAL_COMMENT_SUCCESS';
export const DELETE_PORTAL_COMMENT_FAILURE = 'DELETE_PORTAL_COMMENT_FAILURE';

// { parent_id, parent_type, commenter_name, commenter_email, text } = comment
const requestAddProofComment = (comment) => ({
  type: ADD_PROOF_COMMENT_REQUEST,
  payload: { comment }
});

export const finalizeAddProofComment = comment => ({
  type: ADD_PROOF_COMMENT_SUCCESS,
  payload: { comment }
});

const failedAddProofComment = comment => ({
  type: ADD_PROOF_COMMENT_FAILURE,
  payload: { comment }
});

export const addProofComment = (comment, isPublic, status_id) => dispatch => {
  comment = {
    ...comment,
    parent_type: 'PROOF',
    status_id,
  };
  if (isPublic) {
    comment.public = isPublic;
  }
  dispatch(requestAddProofComment(comment));

  return oauth('POST', 'comment', comment).then(
    ({ json }) => dispatch(finalizeAddProofComment(json.comment)),
    ({ json }) => dispatch(failedAddProofComment(comment))
  );
};

/* Client Portal */

const requestAddPortalComment = (comment) => ({
  type: ADD_PORTAL_COMMENT_REQUEST,
  payload: { comment }
});

export const finalizeAddPortalComment = comment => ({
  type: ADD_PORTAL_COMMENT_SUCCESS,
  payload: { comment }
});

const failedAddPortalComment = comment => ({
  type: ADD_PORTAL_COMMENT_FAILURE,
  payload: { comment }
});

export const addPortalComment = (comment, isPublic, status_id) => dispatch => {
  comment = {
    ...comment,
    parent_type: 'PORTAL',
    status_id,
  };
  if (isPublic) {
    comment.public = isPublic;
  }
  dispatch(requestAddPortalComment(comment));

  return oauth('POST', 'comment', comment).then(
    ({ json }) => dispatch(finalizeAddPortalComment(json.comment)),
    ({ json }) => dispatch(failedAddPortalComment(comment))
  );
};

const requestDeletePortalComment = comment_id => ({
  type: DELETE_PORTAL_COMMENT_REQUEST,
  payload: { comment_id }
});

export const finalizeDeletePortalComment = comment_id => ({
  type: DELETE_PORTAL_COMMENT_SUCCESS,
  payload: { comment_id }
});

const failedDeletePortalComment = comment_id => ({
  type: DELETE_PORTAL_COMMENT_FAILURE,
  payload: { comment_id }
});

export const deletePortalComment = (comment_id) => dispatch => {
  dispatch(requestDeletePortalComment(comment_id));

  return oauth('PUT', 'comment/' + comment_id, {active: 0}).then(
    ({ json }) => dispatch(finalizeDeletePortalComment(comment_id)),
    ({ json }) => dispatch(failedDeletePortalComment(comment_id))
  );
};
