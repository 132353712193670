import React, { useCallback, useState } from 'react';
import { Button, LabeledSelect, LabeledInput, LabeledTextarea } from '@commonsku/styles';

export default function BotForm({ bot, users, shops, saveBot }) {
    const [open, setOpen] = useState(bot.new ?? false);
    const [name, setName] = useState(bot.name);
    const [chatOnlineMessage, setChatOnlineMessage] = useState(bot.chat_welcome_message);
    const [chatOfflineMessage, setChatOfflineMessage] = useState(bot.chat_offline_message);
    const [chatNotificationUser, setChatNotificationUser] = useState(bot.chat_notification_user.split(','));

    const getBotStatus = useCallback((botId) => {
        const usedShops = shops.filter(s => s.chat_bot_id === botId);
        let status = 'Inactive';
        if (usedShops.length > 0)
        {
            status = 'Active on ' + usedShops.length + ' shop' + (usedShops.length > 1 ? 's' : '');
        }
        return status;
    }, [shops]);

    const options = [
        { label: 'Client Rep', value: 'client_rep_role' },
        { label: 'Order Rep', value: 'sale_rep_role' },
        { label: '-------------', value: '', isDisabled: true },
    ];
    for (const user of users) {
        options.push({
            label: user.contact_first_name + ' ' + user.contact_last_name,
            value: user.user_id,
        });
    }

    const colourStyles = {
        multiValue: (styles, { data }) =>({
            ...styles,
            marginRight: '0.5em !important',
            padding: '0.3em !important',
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#02c0da',
            marginRight: '0.5em',
        }),
    };

    const selected = options.filter(o => chatNotificationUser.includes(o.value));

    const simpleForm = (
        <div onClick={e => setOpen(true)}>
            <div style={{float: 'right'}}>{getBotStatus(bot.bot_id)}</div>
            {name}
        </div>
    );
    const detailedForm = (
        <>
            <button className="close-button" onClick={e => setOpen(false)}>&times;</button>
            <LabeledInput label="Bot Name" name="name" value={name} onChange={e => setName(e.target.value)} />
            <LabeledTextarea rows={2} label="Online Message" value={chatOnlineMessage} onChange={e => {
                setChatOnlineMessage(e.target.value);
            }} />
            <LabeledTextarea rows={2} label="Offline Message" value={chatOfflineMessage} onChange={e => {
                setChatOfflineMessage(e.target.value);
            }} />
            <LabeledSelect
                label="Users To Notify"
                value={selected}
                options={options}
                styles={colourStyles}
                isMulti
                onChange={value => {
                    setChatNotificationUser(value?.map(o => o.value) ?? []);
                }}
            />
            <div style={{marginBottom: '1rem'}}>
                <label>Status</label>
                <div>{getBotStatus(bot.bot_id)}</div>
            </div>
            <Button onClick={() => saveBot({ bot_id: bot.bot_id, name, chatOnlineMessage, chatOfflineMessage, chatNotificationUser })}>Save</Button>
        </>
    );

    return (
        <div className="row botRow" style={{ marginBottom: '12px', padding: '15px' }}>
            { !open && simpleForm}
            { open && detailedForm}
        </div>
    );
}
