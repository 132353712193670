import { useCallback, useEffect, useState } from 'react';
import config from '../config';

function playStream(stream) {
    const audio_elem = document.createElement("audio");
    // var audio_elem = document.getElementById("audio");
    audio_elem.srcObject = stream;
    audio_elem.play();

    return audio_elem;
}

let Peer;
if (typeof window !== 'undefined') {
    Peer = require('peerjs').default;
}

function usePeer(peerId, setChatUserCallStatus, sendMessage, disconnect = false) {
    const [peer, setPeer] = useState(null);
    const [currentCall, setCurrentCall] = useState(null);

    useEffect(() => {
        if (Peer) {
            const peer = new Peer(peerId, {
                host: config.peerjsServer,
                path: '/peerjs/',
            });
            peer.on('call', (call) => {
                setCurrentCall(call);
                setChatUserCallStatus(call.peer, 'incoming');
            });
            setPeer(peer);
        }
    }, [peerId, setChatUserCallStatus]);

    const callUser = useCallback((uuid) => {
        navigator.mediaDevices.getUserMedia({ video: false, audio: true })
            .then((stream) => {
                setChatUserCallStatus(uuid, 'dialing');
                const call = peer.call(uuid, stream);
                call.on('stream', (remoteStream) => {
                    setChatUserCallStatus(uuid, 'calling');
                    playStream(remoteStream);
                    // Show stream in some <video> element.
                });

                call.on('close', (remoteStream) => {
                    setChatUserCallStatus(uuid, 'connected');
                    // Show stream in some <video> element.
                });

                setCurrentCall(call);
            })
            .catch((err) => {
                setChatUserCallStatus(uuid, 'connected');
                console.error('Failed to get local stream', err);
            });
    }, [peer, setChatUserCallStatus]);

    const handleAnswerCall = useCallback((uuid) => {
        navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
            currentCall.answer(stream); // Answer the call with an A/V stream.
            currentCall.on('stream', (remoteStream) => {
                // Show stream in some <video> element.
                playStream(remoteStream);
                setChatUserCallStatus(currentCall.peer, 'calling');
            });
            currentCall.on('close', (remoteStream) => {
                setChatUserCallStatus(currentCall.peer, 'connected');
                // Show stream in some <video> element.
            });
        }).catch((err) => {
            console.error('Failed to get local stream', err);
        });
    }, [currentCall, setChatUserCallStatus]);

    const handleEndCall = useCallback((uuid, notify = true) => {
        if (uuid === peerId) {
            return;
        }

        currentCall.close();

        if (notify) {
            // Currently peersjs not sending close event
            sendMessage('endcall:' + uuid);
        }
    }, [currentCall, peerId, sendMessage]);

    useEffect(() => {
        let timer;

        // Give it a loading time buffer from tenant option loading
        // Like in client portal
        if (disconnect && peer) {
            timer = setTimeout(() => {
                peer.disconnect();
            }, 2000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [disconnect, peer]);

    return [
        callUser,
        handleAnswerCall,
        handleEndCall,
    ];
}

export default usePeer;
