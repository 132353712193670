import { oauth } from '../utils';

export const ADD_COLLECTION_REQUEST = 'ADD_COLLECTION_REQUEST';
export const ADD_COLLECTION_SUCCESS = 'ADD_COLLECTION_SUCCESS';
export const ADD_COLLECTION_FAILURE = 'ADD_COLLECTION_FAILURE';

export const ADD_COLLECTION_THEME_REQUEST = 'ADD_COLLECTION_THEME_REQUEST';
export const ADD_COLLECTION_THEME_SUCCESS = 'ADD_COLLECTION_THEME_SUCCESS';
export const ADD_COLLECTION_THEME_FAILURE = 'ADD_COLLECTION_THEME_FAILURE';

export const DELETE_COLLECTION_THEME_REQUEST = 'DELETE_COLLECTION_THEME_REQUEST';
export const DELETE_COLLECTION_THEME_SUCCESS = 'DELETE_COLLECTION_THEME_SUCCESS';
export const DELETE_COLLECTION_THEME_FAILURE = 'DELETE_COLLECTION_THEME_FAILURE';

export const ADD_COLLECTION_IMAGE_REQUEST = 'ADD_COLLECTION_IMAGE_REQUEST';
export const ADD_COLLECTION_IMAGE_SUCCESS = 'ADD_COLLECTION_IMAGE_SUCCESS';
export const ADD_COLLECTION_IMAGE_FAILURE = 'ADD_COLLECTION_IMAGE_FAILURE';



const createAddCollectionRequest = () => ({
  type: ADD_COLLECTION_REQUEST,
  payload: {}
});

const createFinalizeAddCollection = collection => ({
  type: ADD_COLLECTION_SUCCESS,
  payload: {
    id: collection.order_id,
    data: collection
  }
});

const createFailedAddCollection = () => ({
  type: ADD_COLLECTION_FAILURE,
  payload: {}
});

export const createAddCollection = () => dispatch => {
  dispatch(createAddCollectionRequest());

  return oauth('POST', 'order', { order_type: 'COLLECTION' }).then(
    ({ json }) => dispatch(createFinalizeAddCollection(json.order)),
    ({ json }) => dispatch(createFailedAddCollection())
  ).then(action => {
    window.location = `/collection/${action.payload.data.form_number}`;
  });
};

const createAddCollectionThemeRequest = (collection_id, theme_id) => ({
  type: ADD_COLLECTION_THEME_REQUEST,
  payload: {
    collection_id,
    theme_id
  }
});

const createFinalizeAddCollectionTheme = collection_theme => ({
  type: ADD_COLLECTION_THEME_SUCCESS,
  payload: {
    id: collection_theme.collection_theme_id,
    data: collection_theme
  }
});

const createFailedAddCollectionTheme = (collection_id, theme_id) => ({
  type: ADD_COLLECTION_THEME_FAILURE,
  payload: {
    collection_id,
    theme_id
  }
});

export const createAddCollectionTheme = (collection_id, theme_id) => dispatch => {
  dispatch(createAddCollectionThemeRequest(collection_id, theme_id));

  const data = {
    collection_id,
    theme_id
  };
  return oauth('POST', 'collection-theme', data).then(
    ({ json }) => dispatch(createFinalizeAddCollectionTheme(json.collection_theme)),
    ({ json }) => dispatch(createFailedAddCollectionTheme(collection_id, theme_id))
  );
};

const createDeleteCollectionThemeRequest = collection_theme_id => ({
  type: DELETE_COLLECTION_THEME_REQUEST,
  payload: {
    collection_theme_id
  }
});

const createFinalizeDeleteCollectionTheme = collection_theme_id => ({
  type: DELETE_COLLECTION_THEME_SUCCESS,
  payload: {
    id: collection_theme_id
  }
});

const createFailedDeleteCollectionTheme = collection_theme_id => ({
  type: DELETE_COLLECTION_THEME_FAILURE,
  payload: {
    collection_theme_id
  }
});

export const createDeleteCollectionTheme = collection_theme_id => dispatch => {
  dispatch(createDeleteCollectionThemeRequest(collection_theme_id));

  return oauth('DELETE', `collection-theme/${collection_theme_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteCollectionTheme(collection_theme_id)),
    ({ json }) => dispatch(createFailedDeleteCollectionTheme(collection_theme_id))
  );
};

const createAddCollectionImageRequest = (collection_id, file_id, display_order) => ({
  type: ADD_COLLECTION_IMAGE_REQUEST,
  payload: {
    collection_id,
    file_id,
    display_order
  }
});

const createFinalizeAddCollectionImage = collection_image => ({
  type: ADD_COLLECTION_IMAGE_SUCCESS,
  payload: {
    id: collection_image.collection_image_id,
    data: collection_image
  }
});

const createFailedAddCollectionImage = (collection_id, file_id, display_order) => ({
  type: ADD_COLLECTION_IMAGE_FAILURE,
  payload: {
    collection_id,
    file_id,
    display_order
  }
});

export const createAddCollectionImage = (collection_id, file_id, display_order) => dispatch => {
  dispatch(createAddCollectionImageRequest(collection_id, file_id, display_order));

  const data = {
    collection_id,
    file_id,
    display_order
  };
  return oauth('POST', 'collection-image', data).then(
    ({ json }) => dispatch(createFinalizeAddCollectionImage(json.collection_image)),
    ({ json }) => dispatch(createFailedAddCollectionImage(collection_id, file_id, display_order))
  );
};
