import React from "react";
import { Text } from "@commonsku/styles";
import { DebouncedInput } from "../../../helpers";

export default function ShopTitle(props) {
  const {
    title = 'Title',
    value,
    onChange,
  } = props;

  return (
    <>
      <Text as="p" style={{
        color: 'var(--color-neutrals-90)',
        fontFamily: 'var(--font-family-regular)',
        fontSize: 16,
        marginBottom: 4,
      }}>{title}</Text>
      <DebouncedInput
        value={value}
        onChange={onChange}
      />
    </>
  );
}
