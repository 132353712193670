import type { CSSProperties, FC } from 'react';
import type { XYCoord } from 'react-dnd';
import { useDragLayer } from 'react-dnd';

import { BoxDragPreview } from './BoxDragPreview';
import React from 'react';

const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`; // The offset is off because of artwork size
    return {
        transform,
        WebkitTransform: transform,
    };
}

export interface CustomDragLayerProps { }

export const CustomDragLayer: FC<CustomDragLayerProps> = (props) => {
    const { isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

    function renderItem(item) {
        return <BoxDragPreview item={item} />;
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {renderItem(item)}
            </div>
        </div>
    );
};
