import _ from 'lodash';
import React, { Component } from 'react';

export class MenuTrigger extends Component {
  render() {
    const {
      children,
      ...props
    } = this.props;
    return <div className='dropdown-menu-trigger' {...props}>
      {children}
    </div>;
  }
}

let uniqueId = 0;
const getUniqueId = () => {
  return 'dropdown-menu-option-' + (++uniqueId);
};
export class MenuOptions extends Component {
  renderOptions(options) {
    return _.map(options, ({
      key,
      value,
      hideOnClick,
      onClick = _.identity,
      show,
      divider = false,
      ...liProps
    }) => {
      const dropdown = this.props.dropdownMenu;
      if (!key) {
        key = getUniqueId();
      }
      if (divider) {
        return <hr key={key} />;
      }
      if (_.isString(value)) {
        value = <span>{value}</span>;
      }
      return <li {...liProps} key={key} onClick={dropdown && hideOnClick ?
        (...args) => {
          dropdown.showDropdown(false);
          onClick(...args);
        } : onClick}>
        {value}
        {key === 'support' ? <span><img id="hubspot-loading" src="/images/gears.gif" style={{ width: '30px', float: 'right', marginTop: '-25px', display: 'none' }} /></span> : null}
      </li>;
    });
  }

  render() {
    const {
      align,
      options = [],
      children,
      dropdownMenu,
			hideOnClick,
      ...props
    } = this.props;

    return <ul className={align === 'right' ? 'align-right' : ''}
      {...props}>
      {children}
      {this.renderOptions(options)}
    </ul>;
  }
}

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: props.initialShowDropdown,
    };

    _.each([
      'handleClickElsewhere', 'renderChildren',
    ], (method) => {
      this[method] = this[method].bind(this);
    });
    this.debounceShowDropdown = this._debounceShowDropdown();
    this.debounce = _.debounce((show, position) => {
      this.showDropdown(show, position);
    }, 300);

  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickElsewhere, false);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickElsewhere, false);
    this.debounce.cancel();
  }

  handleClickElsewhere(e) {
    if (!!this.state.showDropdown) {
      this.showDropdown(false);
    }
  }

  showDropdown(show, position) {
    const {
      onDropdownShow,
      onDropdownHide,
    } = this.props;
    const alreadyShow = this.state.showDropdown;
    if (show !== alreadyShow || !_.isEqual(position, this.state.position)) {
      this.setState({showDropdown: show, position: position});
    }
    if (!alreadyShow && show && _.isFunction(onDropdownShow)) {
      onDropdownShow();
    }
    if (alreadyShow && !show && _.isFunction(onDropdownHide)) {
      onDropdownHide();
    }
  }

  _debounceShowDropdown() {
    return (show, position) => {
      this.debounce(show, position);
      if (show) {
        this.debounce.flush();
      }
    };
  }

  renderMenuOptions(child, optionsProps) {
    const {
      showDropdown, position,
    } = this.state;
    return showDropdown ?
      React.cloneElement(child, _.assign({
        dropdownMenu: this,
        onMouseEnter: () => this.debounceShowDropdown(true, position),
        onMouseLeave: () => this.debounceShowDropdown(false),
      }, optionsProps)) :
      null;
  }

  renderChildren({
    children,
    triggerProps,
    optionsProps,
  }) {
    return React.Children.map(children, (child) => {
      // TODO: add warning if user pass in onClick/onMouseLeave/onMouseEnter
      // props, should warn them use triggerProps/optionsProps instead
      switch (child.type) {
        case MenuTrigger: {
          if (this._triggerRendered) {
            throw new Error('can not have more than one trigger');
          }
          this._triggerRendered = true;
          return React.cloneElement(child, _.assign({
            onClick: () => this.debounceShowDropdown(true),
            onMouseLeave: () => this.debounceShowDropdown(false),
          }, triggerProps));
        }
        case MenuOptions: {
          if (this._optionsRendered) {
            throw new Error('can not have more than one options menu');
          }
          this._optionsRendered = true;
          return this.renderMenuOptions(child, optionsProps);
        }
        default: {
          throw new Error('child is not MenuTrigger or MenuOptions');
        }
      }
    });
  }

  render() {
    const {
      className = '',
      align,
      options,
      children,
      triggerProps,
      optionsProps,
      onDropdownShow,
      ...props
    } = this.props;

    const position = this.state.position;

    let _optionsProps = _.assign({}, optionsProps, {align, options});
    this._triggerRendered = false;
    this._optionsRendered = false;

    if (!_.isEmpty(position)) {
      _optionsProps = _.assign(_optionsProps, {
        style: {
          position: 'fixed',
          top: position.top - 5,
          left: position.left - 5,
        }
      });
    }

    if (React.Children.count(children) > 2) {
      throw new Error('can not have more than two children');
    }

    return <div className={"dropdown-menu " + className}
      onClick={(e) => { e.stopPropagation(); }}
      {...props}>
      {this.renderChildren({
        children, triggerProps, _optionsProps
      })}
      {!this._optionsRendered && !_.isEmpty(_optionsProps.options) ?
        this.renderMenuOptions(<MenuOptions/>, _optionsProps) : null}
    </div>;
  }
}

export default DropdownMenu;
