import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/item';
import { createUpdate, createAddItemCost } from '../../actions';

import { getItem, getItemFixedCosts, getBasicItemLocations } from '../../selectors/items';

import Select from '../Select';
import FixedCost from './FixedCost';
import ChargeMenu from './ChargeMenu';

const updateItemComplex = createUpdate('item', {
  request: actions.COMPLEX_UPDATE_ITEM_REQUEST,
  success: actions.COMPLEX_UPDATE_ITEM_SUCCESS,
  failure: actions.COMPLEX_UPDATE_ITEM_FAILURE,
  failure_message: 'Unable to update item'
});

class FixedCharges extends React.Component {

  constructor(props) {
    super(props);

    ['onToggleSharePricing', 'handleAddFixedCharge'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  onToggleSharePricing(value) {
    const { dispatch, item_id, share_fixed_charges, field_lock, exchange_rate } = this.props;
    dispatch(updateItemComplex(item_id, 'share_fixed_charges', share_fixed_charges ? 0 : 1, parseInt(value, 10)), { field_lock, exchange_rate });
  }

  handleAddFixedCharge(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    dispatch(createAddItemCost(item_id, null, 1, false));
  }

  render() {
    const { item_id, isPS, share_fixed_charges, item_costs, has_locations, locked, field_lock, exchange_rate, division_id } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="large-12 columns flex-hdr-row">
            <h2>Fixed Charges</h2>
            <Select disabled={locked} value={share_fixed_charges ? '1' : '0'} change={this.onToggleSharePricing} style={{ width: '20%', marginLeft: '1rem' }} options={[{ key: '0', value: 'Subtract from Margin' }, { key: '1', value: 'Display to Client' }]} />
          </div>
        </div>

        {item_costs.length > 0 && <div className="row columns small-12">
          <div className="table-wrapper" style={{ overflow: 'visible' }}>
            <table className="pricing-table1 unstriped">
              <thead>
                <tr>
                  <th className="table-col-title">Label</th>
                  <th className="table-col-title">Cost</th>
		  {share_fixed_charges && <th className="table-col-title">Margin</th>}
		  {share_fixed_charges && <th className="table-col-title">Retail</th>}
                </tr>
              </thead>
              <tbody>
                {item_costs.map(c => <FixedCost key={c.item_cost_id} item_cost_id={c.item_cost_id} locked={locked} share_fixed_charges={share_fixed_charges} field_lock={field_lock} exchange_rate={exchange_rate} division_id={division_id} />)}
              </tbody>
            </table>
          </div>
        </div>}

        {!locked && <div className="row row-pb">
          {(has_locations || isPS) ?
            <ChargeMenu item_id={item_id} charge_type="SETUP" isPS={isPS}>
              <a style={{ paddingLeft: '1rem' }}>+ add fixed charge</a>
            </ChargeMenu> :
            <a style={{ paddingLeft: '1rem' }} onClick={this.handleAddFixedCharge}>+ add fixed charge</a>
          }
        </div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, { item_id: ownProps.item_id });
  const item_costs = getItemFixedCosts(state, { item_id: ownProps.item_id });
  const locations = getBasicItemLocations(state, { item_id: ownProps.item_id });
  return {
    share_fixed_charges: item.share_fixed_charges == 1,
    item_costs,
    has_locations: locations.length > 0,
    exchange_rate: item.exchange_rate,
    division_id: item.division_id
  };
};

export default connect(mapStateToProps)(FixedCharges);
