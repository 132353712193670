import { first, get, groupBy, orderBy } from 'lodash';

import Tax from '../models/Tax';

export const isAvalaraOrder = (order) => {
  return get(order, 'tax_id') === Tax.avalara_tax_id;
};

export const isAvalaraShop = (shop, addresses = []) => {
  return get(shop, 'default_tax_id') === Tax.avalara_tax_id || addresses.some(a => a.tax_id === Tax.avalara_tax_id);
};

export const getOrderTypeLabel = (order) => {
  switch (order?.order_type) {
    case 'OPPORTUNITY': return 'Opportunity'; 
    case 'PRESENTATION': return 'Presentation'; 
    case 'ESTIMATE': return 'Estimate'; 
    case 'SALES ORDER': return 'Sales Order'; 
    case 'INVOICE': return 'Invoice'; 
    case 'SHOP': return 'Shop';
    case 'STORE': return 'Shopify Store';
    case 'PARTIAL': return 'Shop Checkout';
    default: return 'Order';
  }
};

// removes any items separators that do not have items (not separators) under it
// example: New Items = [Product1, Separator1, Separator2, Separator3, Product2, Separator4]
// this will exclude items at index 1, 2 and 5 front result since they don't have products/services under it
// final result: New Items = [Product1, Separator3, Product2]
export function getNewOrderedItemsForUpgrade(newItems, orderItems, checkQty = false) {
  const orderItemsByItemId = groupBy(orderItems, 'item_id');

  let result = orderBy(
    newItems.map(v => ({
      ...v,
      display_order: parseInt(first(orderItemsByItemId[v.item_id])?.display_order || 0, 10),
    })),
    ['display_order'],
    ['asc']
  );
  if (checkQty) {
    result = result.filter(
      v => (v.parent_type === 'SEPARATOR' || parseInt(v.quantity, 10) > 0)
    );
  }
  return result.map((v, i, arr) => {
    if (i === arr.length - 1) {
      if (v.parent_type === 'SEPARATOR') {
        return null;
      }
      return v;
    }

    if (v.parent_type === 'SEPARATOR') {
      const nextItem = arr[i + 1];
      if (nextItem.parent_type === 'SEPARATOR') {
        return null;
      }
    }

    return v;
  }).filter(Boolean);
}
