import { LOAD_DIVISION_SUCCESS, ADD_SUPPLIER_SUCCESS, LOAD_SUPPLIER_SUCCESS, FETCH_SUPPLIERS_SUCCESS } from '../actions/supplier';

export const divisionReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_DIVISION_SUCCESS:
      return Object.assign({}, state, action.payload.divisions.reduce((o, d) => { o[d.division_id] = d; return o; }, {}));
    case ADD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, { [action.payload.supplier.division_id]: action.payload.supplier });
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        ...action.payload.suppliers
      };
  }
  return state;
};

export const supplierReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, { [action.payload.supplier.supplier_id]: action.payload.supplier });
    case LOAD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, action.payload.suppliers.reduce((o, s) => { o[s.supplier_id] = { ...(state[s.supplier_id] || {}), ...s}; return o; }, {}));

  }
  return state;
};

export const supplierAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, { [action.payload.supplier.account_id]: action.payload.supplier });
  }
  return state;
};
