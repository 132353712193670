export const ADD_CALLOUT = 'ADD_CALLOUT';
export const DISMISS_CALLOUT = 'DISMISS_CALLOUT';

export const createAddCallout = (key, message, type='warning') => ({
  type: ADD_CALLOUT,
  payload: {
    key,
    message,
    type
  }
});

export const createDismissCallout = key => ({
  type: DISMISS_CALLOUT,
  payload: {
    key
  }
});
