import React, { Component } from 'react';

export class ItemWarning extends Component {

  constructor(props) {
    super(props);

    this.state = { warning: this.props.item_warning.warning };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item_warning.warning !== this.props.item_warning.warning) {
      this.setState({ warning: nextProps.item_warning.warning });
    }
  }

  handleChange(e) {
    this.setState({ warning: e.target.value });
  }

  handleBlur(e) {
    this.props.onUpdateItemWarning(e.target.value);
  }

  render() {
    const { locked = false } = this.props;
    const { warning } = this.state;
    return (
      <p>
        {!locked &&
          <a className="delete" style={{ float: 'right' }} onClick={e => {
            e.preventDefault();
            this.props.onDeleteItemWarning();
          }}>
            &times;
          </a>
        }
        <img style={{ width: '20px', height: '18px', float: 'left' }} src="/images/warning.svg" />
        <b style={{ float: 'left' }}>WARNING:</b>
        {locked ? <span dangerouslySetInnerHTML={{ __html: warning }} /> : <input type="text" style={{ width: '85%', marginLeft: '7rem' }} value={warning} onChange={this.handleChange} onBlur={this.handleBlur} disabled={locked} />}
      </p>
    );
  }
}

class ItemWarnings extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { item_warnings, onAddItemWarning, onUpdateItemWarning, onDeleteItemWarning, locked = false } = this.props;

    return (
      <div>
        {item_warnings.map(iw =>
          <ItemWarning
            key={iw.item_warning_id}
            locked={locked}
            item_warning={iw}
            onUpdateItemWarning={onUpdateItemWarning(iw)}
            onDeleteItemWarning={() => onDeleteItemWarning(iw.item_warning_id)}
          />
        )}
        {!locked && <span><button type="button" className="button" onClick={onAddItemWarning}>Add Warning</button>&nbsp;Please check supplier website for any warnings now shown</span>}
      </div>
    );
  }
}

export default ItemWarnings;
