import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addPartial, removePartial } from '../actions/order';
import { formatMoney, parseMysqlDate, getAbsoluteUrl } from '../utils';

class PartialList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ selected: nextProps.selected });
    }

    render() {
        const { partials, handleClickPartial, handleSelectAllPartials, partials_loaded } = this.props;
        const { selected } = this.state;

        if (!partials_loaded) return <div style={{ textAlign: 'center'}}><img alt="loading" style={{ margin: '1rem' }} src="/images/gears.gif" /></div>;

        return (
            <table className='partials-table'>
                <thead className="order-summary">
                    <tr>
                        <th><input type="checkbox" checked={selected.length === partials.length} onClick={e => {e.stopPropagation(); handleSelectAllPartials(selected.length === partials.length);}} /></th>
                        <th style={{ textAlign: 'left' }}>Created</th>
                        <th style={{ textAlign: 'left' }}>Reference</th>
                        <th style={{ textAlign: 'left' }}>Customer Name</th>
                        <th style={{ textAlign: 'left' }}>Email</th>
                        <th style={{ textAlign: 'left' }}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {partials.length ?
                        partials.map(o =>
                            <tr key={o.order_id} onClick={e => {e.stopPropagation(); handleClickPartial(o.order_id);}}>
                                <td><input type="checkbox" checked={selected.includes(o.order_id)} /></td>
                                <td>{parseMysqlDate(o.date_created)}</td>
                                <td>
                                    <a href={getAbsoluteUrl(`public_order.php?order_id=${o.order_id}`)} target="_blank" rel="noopener noreferrer">
                                        {o.customer_po}
                                    </a>
                                </td>
                                <td>{o.checked_out_by}</td>
                                <td>{o.checked_out_email}</td>
                                <td>${formatMoney(o.total_subtotal)}</td>
                            </tr>)
                    :
                        <tr><td colSpan="6" style={{ textAlign: 'center' }}>No partial orders available</td></tr>
                    }
                </tbody>
            </table>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddPartial: (order_id, parent_order_id) => {
            dispatch(addPartial(order_id, parent_order_id));
        },
        onRemovePartial: (order_id, parent_order_id) => {
            dispatch(removePartial(order_id, parent_order_id));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartialList);
