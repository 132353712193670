import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dismissNotification, clearNotifications } from '../actions/header';
import { getImageSrcByImgPaths } from '../utils';

import DropdownMenu, {
  MenuTrigger, MenuOptions
} from '../components/DropdownMenu';

class NotificationDropdownMenu extends Component {

  render() {
    const {
      identity, notifications, notification_count,
      onClickNotification, onClickClear,
    } = this.props;
    const options = _.map(notifications, (notification, key) => {
      const imgSrc = getImageSrcByImgPaths(notification?.avatar, '/images/user-avatar3-36x36.png');
      return <li key={key}
        className={notification.active == 1 ? "active" : ''}
        data-form-number={notification.active == 1}
        onClick={() => {
          onClickNotification(notification);
          this.dropdown.showDropdown(false);
        }} >
        <img src={imgSrc} />
        <div>
          {notification.notification_text}
          <time>{notification.date_modified}</time>
        </div>
      </li>;
    });
    const triggerProps = {};

    if (+notification_count === 0) {
      triggerProps.onClick = () => {};
    }

    return <DropdownMenu className="notification-menu" align="right"
      ref={(dropdown) => {this.dropdown = dropdown;}}
      triggerProps={triggerProps}
    >
      <MenuTrigger>
        <span className={
          'new-message-icon ' + (notification_count > 0 ? 'enabled' : '')
        }>{notification_count}</span>
      </MenuTrigger>
      <MenuOptions align="right">
        <div>
          <a className="clear-notifications button" onClick={() => {
            onClickClear(identity.user_id);
            this.dropdown.showDropdown(false);
          }}
            >Clear</a>
          <h2>Notifications</h2>
        </div>
        {options}
      </MenuOptions>
    </DropdownMenu>;
  }
}

const mapStateToProps = (state) => {
  return {
    identity: state.identity,
    notifications: _.get(state, 'entities.notifications', {}),
    notification_count: _.get(state, 'entities.notification_count', 0),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickNotification: (notification) => {
    dispatch(dismissNotification(notification));
  },
  onClickClear: (user_id) => {
    dispatch(clearNotifications(user_id));
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(NotificationDropdownMenu);

