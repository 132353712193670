import React, { useState } from "react";
import { ArrowIcon, Col, Csku, IconButton, LabeledCheckbox, Row, StarIcon, Text } from "@commonsku/styles";
import { getImageSrc } from "../../../../utils";
import { Categories, Filters, isShopThemeFavourite } from "./helpers";
import ThemeTile from "./ThemeTile";
import Img from "../../../Img";

export default function ThemeListView({
  templateData,
  themes,
  filters,
  category,
  selectedTheme=null,
  onClose,
  setCategory,
  onToggleFilters,
  onClickSelect,
  onClickPreview,
  onClickFavourite,
}) {
  return (
    <Row>
      {/* TODO: not enough themes to filter through right now */}
      {/* <Col padded xs={3} md={2}>
        <Row>
          <Col xs>
            <Text style={{ color: 'var(--color-neutrals-90)', fontFamily: 'var(--font-family-regular)', fontSize: 18 }}>Categories</Text>
          </Col>
          {Categories.map((v, i) => (
            <Col key={v.key + i} xs>
              <Csku forceStyles
                onClick={() => setCategory(v.key)}
                sx={{
                  cursor: 'pointer',
                  padding: '8px !important',
                  background: v.key === category ? 'var(--color-primary1-20)' : 'var(--color-neutrals-white)',
                  borderRadius: 5,
                  ':hover': {
                    background: 'var(--color-primary1-20)',
                  },
                }}
              >
                <Text style={{ color: 'var(--color-primary1-main)', fontFamily: 'var(--font-family-regular)', fontSize: 16 }}>{v.value}</Text>
                {v.key === 'favourites' && <StarIcon filled style={{ paddingLeft: 8, verticalAlign: 'middle' }} />}
              </Csku>
            </Col>
          ))}
          <Col xs style={{ paddingTop: 32, paddingBottom: 16, }}>
            <Text style={{ color: 'var(--color-neutrals-90)', fontFamily: 'var(--font-family-regular)', fontSize: 18 }}>Filters</Text>
          </Col>
          {Filters.map((v, i) => (
            <Col key={v.key + i} xs>
              <LabeledCheckbox
                label={v.value}
                checked={filters.includes(v.key)}
                onChange={() => onToggleFilters(v.key)}
                labelStyle={{
                  color: 'var(--color-primary1-main)',
                  fontFamily: 'var(--font-family-regular)',
                }}
              />
            </Col>
          ))}
        </Row>
      </Col> */}
      <Col padded xs={12} md={12} style={{ padding: 16, paddingTop: 0 }}>
        <Row>
          <Col xs style={{ paddingBottom: 16, paddingLeft: 8, }}>
            <Text as="p" style={{ color: 'var(--color-neutrals-90)', fontFamily: 'var(--font-family-bold)', fontSize: 32 }}>
              {selectedTheme ? `Selected theme: ${selectedTheme.public_view_template_name} Theme` : 'Please select a theme'}
            </Text>
            <Text as="p" style={{
              fontFamily: 'var(--font-family-regular)',
              fontSize: 18,
            }}>
              Customizations on themes will be saved to that specific theme until reset.
            </Text>
          </Col>
          <Col xs>
            <Row style={{ overflow: 'auto' }}>
              {themes.map((t, i) => (
                <Col padded xs sm={6} md={4} key={t.title + '-' + i}>
                  <ThemeTile
                    title={t.title}
                    template_name={t.title}
                    isFavourite={isShopThemeFavourite(templateData, t)}
                    isSelected={selectedTheme && t.public_view_template_id === selectedTheme.public_view_template_id}
                    imgSrc={typeof t.image === 'string' ? t.image : getImageSrc(t.image || {}, 'original')}
                    onClose={onClose}
                    onClickFavourite={() => onClickFavourite(t)}
                    onClickSelect={() => onClickSelect(t)}
                    onClickPreview={() => onClickPreview(t)}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
