import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import { Input, CalendarIcon, Datepicker, LabeledInput } from '@commonsku/styles';
import ErrorBoundary from './ErrorBoundary';

const CustomInput = React.forwardRef((props, ref) => (
  <>
    <input
      {...props}
      ref={ref}
      style={{ marginRight: '-2rem', display: 'inline-block' }}
      type="text"
      autoComplete="off"
    />
    <CalendarIcon key="icon"
      style={{ fontStyle: 'normal', cursor: 'pointer', verticalAlign: 'middle' }}
      onClick={props.onClick}
    />
  </>
));

export const LabeledDateInput = React.forwardRef(({ isClearable, onClick, label, ...props }, ref) => {
  return (
    <React.Fragment>
        <LabeledInput {...props} label={label} ref={ref} />
        <span
            onClick={onClick}
            style={{ fontStyle: 'normal', cursor: 'pointer', position: 'absolute', top: '30px', right: '5px' }}
        >
        {!isClearable ? <CalendarIcon style={{ width: '1.9rem', verticalAlign: 'middle', }} /> : null}
        </span>
    </React.Fragment>
  );
});

export const CSKUInput = React.forwardRef((props, ref) => (
  <>
    <Input
      {...props}
      ref={ref}
      style={{ paddingRight: '2rem', display: 'inline-block' }}
      type="text"
      autoComplete="off"
    />
    <span style={{ fontStyle: 'normal', cursor: 'pointer', position: 'absolute', top: '7px', right: '5px' }} onClick={props.onClick}>
      <CalendarIcon style={{ width: '1.9rem', verticalAlign: 'middle', }} />
    </span>
  </>
));

export default class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value !== "" ? this.parsePropsValue(props.value) : null};

    _.bindAll(this, ['onBlur', 'onChange',]);

    this.debounceOnBlur = _.debounce(this.onBlur, 300);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({value: this.parsePropsValue(this.props.value)});
    }
  }

  parsePropsValue(value) {
    const { inputFormat, outputFormat } = this.props;

    if(value === '0000-00-00 00:00:00' || value === '0000-00-00' || !value) {
      return null;
    }

    let m = moment(value, inputFormat);

    if(m.format(outputFormat) === value) {
      return m;
    } else {
      return moment(moment(value, 'YYYY-MM-DD HH:mm:ss').format(outputFormat), inputFormat);
    }
  }

  onBlur(e) {
    const inputValue = moment(e.target.value);
    const value = inputValue.isValid() ? inputValue : this.state.value;
    const { outputFormat, onBlur } = this.props;

    if (onBlur) {
      if (!value) {
        onBlur('');
      } else if(value.isValid && value.isValid()) {
        onBlur(value.format(outputFormat));
      }
    }
  }

  onChange(value) {
    const {
      outputFormat, onChange, onBlur
    } = this.props;

    this.setState({ value }, () => {
      if (onChange) {
        if (!value) {
          onChange('');
        } else if (!value.isValid) { // no function (isValid) exists
          value = this.parsePropsValue(value);
        }

        if (value && value.isValid()) {
          onBlur(value.format(outputFormat));
          onChange(value.format(outputFormat));
        }
      }
    });
  }

  getDateFromValue(value) {
    if(value === '0000-00-00 00:00:00' || value === '0000-00-00' || !value) {
      return null;
    }

    if (moment.isMoment(value)) {
        return value.toDate();
    } else if (!_.isDate(value)) {
        return new Date(value);
    }
    return value;
  }

  render() {
    const { dateFormat, placeholder, customInput, peekNextMonth, ...props } = this.props;
    const { value } = this.state;

    return <ErrorBoundary>
      <Datepicker
        locale="en"
        selected={this.getDateFromValue(value)} // value should be Date obj
        todayButton={'today'}
        onChange={this.onChange}
        onBlur={this.onBlur}
        dateFormatCalendar={dateFormat}
        dateFormat={dateFormat}
        customInput={customInput || <CustomInput/>}
        placeholderText={placeholder}
        placeholder={placeholder}
        peekNextMonth={peekNextMonth}
        {..._.omit(props, [
          'locale', 'selected', 'value',
          'todayButton', 'onChange', 'onBlur', 'dateFormatCalendar',
          'dateFormat', 'outputFormat', 'inputFormat', 'peekNextMonth',
          'customInput', 'placeholder', 'placeholderText',
        ])}
      />
    </ErrorBoundary>;
  }
}

DateInput.defaultProps = {
  onChange: _.identity,
  onBlur: _.identity,
  dateFormat: 'yyyy-MM-dd',
  inputFormat: 'YYYY-MM-DD',
  outputFormat: 'YYYY-MM-DD',
  placeholder: 'YYYY-MM-DD',
  isClearable: false,
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
};
