import { get, map, isEmpty, uniq, find, keys } from 'lodash';

const VALID_Y_UOMS = ['Colors', 'Inches', 'Stitches', 'SquareInches'];

export const getUoms = (decoration) => {
  const uoms = {};
  if (!!decoration && VALID_Y_UOMS.includes(decoration.decoration_units_included_uom)) {
    uoms[decoration.decoration_units_included_uom] = [decoration.decoration_units_included, decoration.decoration_units_max];
  }
  map(get(decoration, 'charges') || [], (charge) => {
    map(charge.prices, ({ y_uom, y_min_qty }) => {
      uoms[y_uom] = (uoms[y_uom] || []).concat(y_min_qty);
    });
  });
  map(uoms, (quantities, y) => {
    if (VALID_Y_UOMS.includes(y)) {
      uoms[y] = uniq(quantities).sort((a, b) => a - b);
    } else {
      delete uoms[y];
    }
  });
  return uoms;
};

export const isPSChargeMandatory = (charge) => {
  const prices = get(charge, 'prices') || [];
  return !isEmpty(prices) && !find(prices, { x_min_qty: 0 });
};

export const isPSChargeEditable = (charge) => {
  return !charge || !!charge.is_editable;
};

const HIT_MANDATORY_CHARGES = [
  'SILK SCREEN',
  'TRANSFER',
  'EMBROIDERED',
  'LASER ENGRAVE',
  'DEBOSS',
  'PAD PRINT',
  'SETUP CHARGE',
  'DIRECT PRINT',
  '4 COLOR PROCESS MACHINE (X-JET)',
  'FOUR COLOR PROCESS',
  'HEAT TRANSFER',
  'DOME PROCESS',
  'EMBROIDERY CHARGE',
  'APPAREL DIRECT PRINT',
  'CLEAR INSERT',
  'SILVER GLITTER',
  'SILVER MIRROR',
  'WHITE INSERT',
  'DE-BOSS',
  'HP PRINTER',
  'TAPE CHARGE',
  'CB DRINKWARE',
  'RUN CHARGE',
  'FLEXOGRAPHY',
  'PAD PRINT - 2C',
  'ETCH PROCESS',
  'DIRECT IMPRINT',
  'DIRECT TO GARMENT CHARGE',
  'SETUP',
  'EMBROIDERY',
  'DIRECT TO GARMENT',
  '4-COLOR PROCESS ON A WHITE LABEL',
  '4-COLOR PROCESS ON A CLEAR LABEL',
  'COLORBRITE DRINKWARE',
  'SILK-SCREEN',
  'DYE-SUBLIMATION',
  'SUBLIMATION PROCESS',
  '4 COLOR PROCESS',
  '4-COLOR PROCESS',
  '3D EMBROIDERY',
  'OFFSET PRINTING',
  'DYE SUBLIMATION',
  'CALENDARS FOIL STAMP',
  'COLORBRITE',
  'SANDBLASTING',
  'CB DRINKWARE SMALL',
  'CB DRINKWARE LARGE',
  'LASER ENGRAVING',
  'PAD-PRINT',
  'OVERSIZED VERTICAL IMPRINT'
];

export const isChargeMandatory = (quirks, division_id, charge) => {
  if (!!charge && !!quirks && !!quirks[division_id]) {
    if (!!quirks[division_id]['MANDATORY-COST-CONVENTION']) {
      const prices = get(charge, 'prices') || [];
      return !isEmpty(prices) && !find(prices, { x_min_qty: 0 });
    } else if (!!quirks[division_id]['HIT-MANDATORY-COST-CONVENTION']) {
      return 'SETUP' === charge.charge_type.toUpperCase() || HIT_MANDATORY_CHARGES.includes(charge.charge_name.toUpperCase());
    }
  }
  return false;
};

export const hasArtworkDimension = (quirks, division_id) => {
  if (!!quirks && !!quirks[division_id] && !!quirks[division_id]['USE-ARTWORK-DIMENSIONS']) {
    return true;
  }
  return false;
};

export const isChargeLocationAppropriate = (quirks, division_id, charge, location_index) => {
  if (!quirks || !quirks[division_id] || !quirks[division_id]['USE-CHARGES-PER-LOCATION-YUOM']) {
    return true;
  }
  if ((+charge.per_location) === 1) {
    return (+location_index) === 0;
  }
  if (charge.per_location > 1) {
    return (location_index + 1) >= charge.per_location;
  }
  return true;
};

export const getUomValues = (item_decoration) => keys(item_decoration).filter(
  k => k.startsWith('decoration_uom.')
).map(
  k => k.slice(15)
).reduce(
  (o, k) => ({
    ...o,
    [k]: item_decoration[`decoration_uom.${k}`] || 1
  }),
  {}
);

export const getConfigurationDecoration = (configuration, ext_location_id, ext_decoration_id) => {
  const location = find(get(configuration, 'locations'), ({ ps_location_id }) => `${ps_location_id}` == ext_location_id);
  return find(get(location, 'decorations'), ({ ps_decoration_id }) => `${ps_decoration_id}` == ext_decoration_id);
};

const UOM_MAP = {
  'Colors': {
    zero: 'Colors',
    singular: 'Color',
    multiple: 'Colors'
  },
  'Stitches': {
    zero: 'Stitches',
    singular: 'Stitch',
    multiple: 'Stitches'
  },
  'Inches': {
    zero: 'Inches',
    singular: 'Inch',
    multiple: 'Inches'
  },
  'SquareInches': {
    zero: 'Square Inches',
    singular: 'Square Inch',
    multiple: 'Square Inches'
  },
  'Other': {
    zero: '',
    singular: '',
    multiple: ''
  }
};

export const getUomName = (quantity, uom) => {
  const key = quantity ==  0 ? 'zero' : (quantity == 1 ? 'singular' : 'multiple');
  return UOM_MAP[uom]?.[key] || '';
};

const UNIVERSAL_CHARGES = [
  {
    ps_charge_id: 'divider',
    description: '-----------',
    is_divider: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -1,
    charge_type: 'Run',
    description: 'Personalization',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -2,
    charge_type: 'Setup',
    description: 'Personalization',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -4,
    charge_type: 'Setup',
    description: 'Rush',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -6,
    charge_type: 'Setup',
    description: 'Less Than Minimum',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -7,
    charge_type: 'Run',
    description: 'Handling',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -8,
    charge_type: 'Setup',
    description: 'Handling',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -9,
    charge_type: 'Run',
    description: 'Packaging',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -10,
    charge_type: 'Run',
    description: 'Labeling',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  },
  {
    ps_charge_id: -11,
    charge_type: 'FREIGHT',
    description: 'Shipping',
    is_extra: true,
    is_editable: false,
    is_multiple: false
  }
];

// TODO: This should probably go in the database where we can specify associated costs
const PCNA_EXTRA_CHARGES = [
  {
    ps_charge_id: 0,
    charge_type: 'Run',
    description: 'Other',
    is_extra: true,
    is_editable: true,
    is_multiple: true
  },
  {
    ps_charge_id: 0,
    charge_type: 'Setup',
    description: 'Other',
    is_extra: true,
    is_editable: true,
    is_multiple: true
  }
];
const EXTRA_CHARGES = {
  '030ecb4e-4e2d-4240-8b9c-75493f85e10a': PCNA_EXTRA_CHARGES,
  '16ac8e08-2681-4cd9-93d2-1d0c45b51268': PCNA_EXTRA_CHARGES,
  '289f05f5-263b-4e91-972e-7fabd1cead3c': PCNA_EXTRA_CHARGES,
  '67627a89-8c82-4795-96da-8d1b0e49b200': PCNA_EXTRA_CHARGES,
  '9f455ad7-5e8f-4e6d-aef1-96a4e690df9a': PCNA_EXTRA_CHARGES,
  'cdf58e5a-a30d-11e2-80a6-1231392238ea': PCNA_EXTRA_CHARGES,
  'cada690e-8a53-44ba-80bb-3cff2463f458': PCNA_EXTRA_CHARGES,
  '056886d1-e960-11ed-983b-0ad1fa230847': PCNA_EXTRA_CHARGES,
};

const hasExtraCharges = (division_id) => !!EXTRA_CHARGES[division_id];

export const getExtraCharges = (division_id) => hasExtraCharges(division_id) ? EXTRA_CHARGES[division_id] : [];

const hasUniversalCharges = (quirks, division_id) => !!quirks && !!quirks[division_id] && !!quirks[division_id]['UNIVERSAL-CHARGES'];

export const getUniversalCharges = (quirks, division_id) => hasUniversalCharges(quirks, division_id) ? UNIVERSAL_CHARGES : [];

export const isChargeTypeMatch = (charge, reference) => reference.toUpperCase() === 'RUN' ? (charge || '').toUpperCase() === 'RUN' : (charge || '').toUpperCase() !== 'RUN';

export const getAvailableCharges = (baseCharges, quirks, division_id) => (charge_type, display_order, uoms, used_charge_ids) => {
  const chargeMap = baseCharges.reduce(
    (o, c) => ({ ...o, [`${c.ps_charge_id}`]: c }),
    {}
  );
  const chargeDescriptionMap = baseCharges.reduce(
    (o, c) => ({ ...o, [c.charge_description]: (o[c.charge_description] ?? []).concat(`${c.ps_charge_id}`) }),
    {}
  );
  const getChargesByDescription = charge_description => (chargeDescriptionMap[charge_description] ?? []).map(
    i => chargeMap[i]
  ).filter(
    c => !!c
  );

  const isChargeUsed = charge => {
    return used_charge_ids.map(
      cid => chargeMap[cid]
    ).filter(
      c => !!c
    ).some(
      c => getChargesByDescription(c.charge_description).filter(
        c2 => c2.ps_charge_id === charge.ps_charge_id
      ).length > 0
    );
  };

  const isQtyOk = price => {
    if (price.y_uom === 'Other' || price.y_uom === 'Locations') {
      return true;
    }
    const exactColorMatch = !!quirks && !!quirks[division_id] && !!quirks[division_id]['USE-EQUAL-COLOR-YUOM'];
    if (exactColorMatch && price.y_uom === 'Colors') {
      return !!uoms[price.y_uom] && price.y_min_qty == uoms[price.y_uom];
    }
    return !!uoms[price.y_uom] && price.y_min_qty <= uoms[price.y_uom];
  };

  const isChargeYUoMAppropriate = charge => {
    const charges = getChargesByDescription(
      charge.charge_description
    ).filter(
      c => isChargeLocationAppropriate(quirks, division_id, c, display_order)
    );
    const prices = charges.reduce(
      (o, c) => [
        ...o,
        ...c.prices.map(p => ({ ...p, ps_charge_id: c.ps_charge_id }))
      ],
      []
    ).filter(
      p => isQtyOk(p)
    ).sort(
      (a, b) => a.y_min_qty - b.y_min_qty
    );
    return prices.length > 0 && (prices[prices.length - 1].ps_charge_id === charge.ps_charge_id);
  };

  return baseCharges.filter(
    c => isChargeTypeMatch(c.charge_type, charge_type)
  ).filter(
    c => isChargeLocationAppropriate(quirks, division_id, c, display_order)
  ).filter(
    c => !isChargeUsed(c)
  ).filter(
    c => isChargeYUoMAppropriate(c)
  ).concat(
    getUniversalCharges(quirks, division_id).filter(
      c => isChargeTypeMatch(c.charge_type, charge_type)
    ).filter(
      c => !used_charge_ids.includes(c.ps_charge_id)
    )
  ).concat(
    getExtraCharges( division_id).filter(
      c => isChargeTypeMatch(c.charge_type, charge_type)
    ).filter(
      c => c.is_extra || !used_charge_ids.includes(c.ps_charge_id)
    )
  );
};

export const getItemSku = (item, def = null) => item.item_sku || get(item, 'product.product_supplier_code') || def;
