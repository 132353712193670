import { get, last, chain, includes } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import config from '../../config';
import { oauth } from '../../utils';
import { getCompanyData } from '../../selectors';
import { createFinalizeUpdateCompanyData } from '../../actions';
import { useIdentity } from '../../hooks';

const instance = axios.create({
  baseURL: config.promodata_url,
});

const fetcher = async (args) => {
  const [url, params] = args;
  const { data } = await instance.get(url, { params });
  return data;
};

const getKeyFunc = (url, {stopFetch, reFetch, ...params} = {}) => {
  return (index, prev) => {
    const next = get(prev, 'next', null);
    if (stopFetch || (prev && (next === null))) {
      // reached the end
      return null;
    }
    if (next) {
      params = {...params, cursor: next};
    }
    return [url, params];
  };
};

const useInfinite = (url, { autoLoading, ...params } = {}) => {
  const { data, error, size, setSize, isLoading, isValidating, mutate } = useSWRInfinite(
    getKeyFunc(url, params),
    fetcher,
    { revalidateFirstPage: false },
  );
  const isReachingEnd = get(last(data), 'next', null) === null;

  useEffect(() => {
    if (autoLoading && !error && !isReachingEnd && !isLoading) {
      setSize((size) => {
        return size > (data?.length || 0) ? size : size + 1;
      });
    }
  }, [autoLoading, error, data, isLoading, isReachingEnd]);
  if (error) {
    console.error(error);
  }
  return {
    size, setSize, isLoading, isReachingEnd, mutate,
    results: chain(data).map('results').flatMap().value(),
    error, isValidating
  };
};

export const useAllSuppliers = (params) => {
  const { results, ...rest } = useInfinite('/suppliers', { autoLoading: true, ...params });
  return { ...rest, suppliers: results };
};

export const useCheckStockLevels = (id) => {
  const { data, error } = useSWR(`/products/${id}/check-stock-levels`, async (url) => {
    const { data } = await instance.post(url);
    return data;
  });
  return { stock_levels: data, error };
};

export const useProduct = (product_id) => {
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    instance
    .get(`/products/${product_id}`)
    .then((result) => {
      setProduct(result.data);
      setError(null);
    })
    .catch((e) => {
      setProduct(null);
      setError(e);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [product_id]);

  return { product, error, loading };
};

export const useProducts = (params) => {
  const status = usePromodataStatus();
  const isBetaUser = usePromodataBetaUser();
  const {results, ...rest} = useInfinite((status === 'ACTIVE' || isBetaUser) ? '/products' : null, {
    ...params,
    limit: 30,
  });
  return { ...rest, products: results };
};

export const usePromodataStatus = () => {
  const dispatch = useDispatch();
  const { promodata_date_checked } = useSelector(getCompanyData);
  const date_checked = moment(promodata_date_checked || null);
  const wasValid = date_checked.isValid();
  const isActive = wasValid && moment().diff(date_checked, 'hours') < 24;
  const refresh = wasValid && moment().diff(date_checked, 'hours') > 6;

  useEffect(() => {
    if (!isActive || refresh) {
      // should move this check to products and suppliers api later
      oauth('GET', 'company-data/check-promodata-service', {}, {}, {logout: false}).then((response) => {
        return dispatch(createFinalizeUpdateCompanyData({ company_data: get(response, 'json.company_data') }));
      });
    }
  }, [dispatch, isActive, refresh]);

  return isActive ? 'ACTIVE' : (wasValid ? 'EXPIRED' : 'NOT_CONFIGURED');
};

export const usePromodataBetaUser = () => {
  const identity = useIdentity();
  return config.promodata_beta && includes(get(identity, 'preferences.product_search_tab_order'), 'promodata-products');
};
