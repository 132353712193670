import { useEffect, useState } from 'react';

const LiveUpdate = ({ interval, render, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const handle = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, interval);

    return () => {
      clearInterval(handle);
    };
  }, []);
  return render(props);
};

export default LiveUpdate;