import { find, findIndex, get, isEmpty, map, filter, pick } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { createAddItem, createDeleteItem } from '../../actions';

import { Box, Row } from '@commonsku/styles';
import { oauth } from '../../utils';
import { getOrderItems } from '../../utils/order';
import { useThemeItems } from './hooks';
import SupplierIconContainer from './SupplierIconContainer';
import { ThemeTile } from './ThemeTile';
import ga from '../../utils/ga';
import NewProduct from '../NewProduct';
import ProductChevronPopup from '../product/ProductChevronPopup';
import { createFetchProductDetails } from '../../actions/product';
import { createLoadOrder } from '../../actions/order';
import { getOrderByNumber } from '../../selectors';

const useOrderItems = (order) => {
  const dispatch =  useDispatch();
  const full_order = useSelector(state => getOrderByNumber(state, pick(order, ['form_number', 'order_type'])));
  const [items, setItems] = useState(order ? getOrderItems(order) : []);
  useEffect(() => {
    if (full_order) {
      if (!full_order.loaded) {
        dispatch(createLoadOrder(full_order.order_id));
      } else {
        setItems(getOrderItems(full_order));
      }
    }
  }, [dispatch, full_order]);
  return [items, setItems];
};

function ThemeProductContainer({ order, selectedTheme, loading, onClose, onAddItem, onDeleteItem }) {
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [addedItems, setAddedItems] = useOrderItems(order);
  const [selectedItem, setSelectedItem] = useState(null);
  const { results: items, isValidating, setSize, size, isReachingEnd } = useThemeItems({ id: selectedTheme.theme_id, supplier_id: selectedSupplier });
  const [previousScrollTopPosition, setPreviousScrollTopPosition] = useState(0);
  const [hideSupplier, setHideSupplier] = useState(false);
  const { theme_id } = selectedTheme;

  useEffect(() => {
    oauth('GET', 'theme/' + theme_id, { include: ['suppliers'] }).then((response) => {
      const theme = get(response, 'json.theme') || {};
      setSupplierList(theme.suppliers);
    });
  }, [theme_id]);

  const findSelected = (id) => {
    return find(addedItems, ({ ext_product_id, parent_id }) => {
      return (ext_product_id && ext_product_id === id) || (parent_id && parent_id === id);
    });
  };

  const handleClickProductAction = async (item, onAddProduct) => {
    const added = findSelected(item.ext_product_id || item.parent_id);
    if (!added) {
      const addedItem = await onAddProduct(item);
      setAddedItems([...addedItems, addedItem]);
    } else {
      onDeleteItem(added.item_id);
      setAddedItems(filter(addedItems, { item_id: added.item_id }));
    }
  };

  const handleLoadNextPage = useCallback(() => {
    if (!isValidating) {
      setSize(size + 1);
    }
  }, [isValidating, size, setSize]);

  return <Box borderless style={{
    flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', marginTop: 20
  }}>
    <ThemeTile theme={selectedTheme} imageSize={'headers'} header short={previousScrollTopPosition > 0} onClickBack={() => onClose()}></ThemeTile>

    <Row
      style={{ flex: '1 1 auto', overflowY: 'auto', padding: 16, alignContent: 'flex-start', gap: 16 }}
      onScroll={(event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;

        setHideSupplier(scrollTop > 150 && scrollTop > previousScrollTopPosition);
        setPreviousScrollTopPosition(scrollTop);

        if (!isValidating && (scrollHeight - scrollTop - clientHeight < 300 * 2)) {
          setSize(size + 1);
        }
      }}
    >
      <SupplierIconContainer hideSupplier={hideSupplier} previousScrollTopPosition={previousScrollTopPosition} supplierList={supplierList} handleClickTab={(supplier_id) => setSelectedSupplier(supplier_id)} />
      <Row className='full-width' style={{ gap: 10 }}>
        {map(items, (item, idx) => {
          if (!item) return;
          const parent_id = item.ext_product_id || item.parent_id;
          const onLoading = loading.includes(parent_id);
          return <NewProduct
            key={idx}
            className={idx === items.length - 1 ? 'end' : ''}
            product={item}
            title={item.item_name}
            sku={item.item_sku}
            subtitle={item.division_name}
            loading={onLoading}
            loaded={!!findSelected(parent_id)}
            type="others"
            onClickProduct={setSelectedItem}
            onClickProductAction={() => {
              if (onLoading) return;
              handleClickProductAction(item, onAddItem);
            }}
          />;
        }
        )}
      </Row>
      {isEmpty(selectedItem) ? null : <ProductChevronPopup
        products={items}
        productIndex={findIndex(items, selectedItem)}
        isSelected={(item) => {
          return !!findSelected(item.ext_product_id || item.parent_id);
        }}
        onClose={() => setSelectedItem(null)}
        handleLoadNextPage={handleLoadNextPage}
        isEnd={isReachingEnd}
        onClickProductAction={() => handleClickProductAction(selectedItem, onAddItem)}
      />}
    </Row>
  </Box>;
};

const mapStateToProps = state => ({
  loading: state.display.loading.add_item,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: (item, kwargs = { fetch_update: true }) => {
    const { order, selectedTheme, userId } = ownProps;
    const { order_type, order_id } = order;
    ga.gtag("event", "add", {
      'order_id': order_id,
      'theme_id': selectedTheme.theme_id,
      'product_id': item.item_id,
      'userId': userId,
    });
    const parent_id = item.ext_product_id || item.parent_id;
    if (order_type === 'OPPORTUNITY' || !order_type) {
      return dispatch(createFetchProductDetails(parent_id, item.copied_from, 'MESSAGE'));
    } else if ('PRESENTATION' === order_type || 'COLLECTION' === order_type || 'SHOP' === order_type) {
      return dispatch(createAddItem(
        order_id, 'OPTION', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.item_sku, item.division_id, 'reference', item.copied_from == 'sage_connect', { ...kwargs, target_item_id: item.item_id }
      ));
    } else {
      return dispatch(createAddItem(
        order_id, 'PRODUCT', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.item_sku, item.division_id, 'all', item.copied_from == 'sage_connect', { ...kwargs, target_item_id: item.item_id }
      ));
    }
  },
  onDeleteItem: item_id => {
    return dispatch(createDeleteItem(item_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProductContainer);
