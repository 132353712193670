import { isEqual, bindAll } from 'lodash';
import React, { Component } from 'react';

class LazyRichTextEditorTinymce extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, value: null };
    bindAll(this, ['onChange'], ['onBlur']);
  }

  componentDidMount() {
    import(/* webpackChunkName: "tinymce-react" */ '@tinymce/tinymce-react').then(loaded => {
      const { value } = this.props;
      this.setState({
        loaded,
        value: value,
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }
    return nextProps.value !== this.props.value;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loaded, value } = this.state;
    if (loaded && nextProps.value !== value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  onChange(content) {
    const { onChange } = this.props;
    const value = content;

    this.setState({ value }, () => {
      if (onChange) {
        onChange(value);
      }
    });
  }

  onBlur() {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(this.state.value);
    }
  }

  render() {
    const { loaded, value } = this.state;
    if (!loaded) {
      return null;
    }
    const { Editor: Component } = loaded;

    const toolbarConfig = {
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
      toolbar: [
        'undo redo | formatselect fontselect fontsizeselect | bold italic underline strikethrough backcolor',
        'alignleft aligncenter | alignright alignjustify bullist numlist outdent indent | removeformat | link unlink image | blockquote | table | code'
      ],
      image_advtab: true,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      relative_urls: false,
      remove_script_host: false,
    };

    return <Component init={toolbarConfig} value={value} onEditorChange={this.onChange} onBlur={this.onBlur} />;
  }
}

export default LazyRichTextEditorTinymce;
