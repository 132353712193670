import React, { useCallback } from "react";
import {AlertIcon, Button, Col, PopupHeader, Row} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../popup-factory";

const ConfirmSwitchPsDecoratorPopup = ({onClose, onClickYes, is3rdPartyDecorator, zIndex}) => {
    const handleYes = useCallback(() => {
        onClickYes();
        onClose();
    }, [onClickYes, onClose]);

    if (!zIndex) {
        zIndex = 1;
    }

    return <div id="replace-header-modal" className="reveal" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + zIndex}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
            <div className="small-12 columns">
                <PopupHeader>
                    <span className='title' id="modalTitle"><b>Are you sure?</b></span>
                </PopupHeader>
            </div>
        </div>
        <div className="row column popup-content">
            <div style={{background:'#FFF2F3', color:'#B4000C', marginBottom: '16px', padding: '16px'}}>
                <Row>
                    <Col xl={1} xs={1} style={{display: 'flex', alignItems: 'center'}}>
                        <AlertIcon  />
                    </Col>
                    <Col xl={11} xs={11}>
                        <span style={{ fontWeight: 500, fontSize: '16px'}}>
                            Switching to {is3rdPartyDecorator ? '1st' : '3rd'} Party Decorator will remove all decoration locations.
                            Are you sure you want to switch?
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Button variant="secondary" size="medium"
                            style={{width:'45%', marginRight:'5%'}}
                            onClick={e => {e.preventDefault(); onClose(); }}>
                        <b>Cancel</b>
                    </Button>
                    <Button variant="primary" size="medium"
                            style={{width:'50%'}}
                            onClick={e => {e.preventDefault(); handleYes(); }}>
                        <b>Switch and delete locations</b>
                    </Button>
                </Row>
            </div>

        </div>
    </div>;
};

export default ConfirmSwitchPsDecoratorPopup;