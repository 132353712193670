import React from 'react';

import { isNumeric, formatMoney } from '../../utils';

class Field extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      changing: false,
      changed: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value || nextState.value !== this.state.value;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.changing && nextProps.value !== this.props.value && nextProps.value !== this.format(this.state.value)) {
      this.setState({ value: nextProps.value, changed: false });
    }
  }

  handleChange(e) {
    const { dispatch, request, field, id } = this.props;
    this.setState({
      value: e.target.value,
      changing: true,
      changed: !!request
    }, () => {
      if (request) {
        const value = this.format(this.state.value);
        if (value !== this.props.value) {
          dispatch(request(id, field, this.props.value, value));
        }
      }
    });
  }

  format(value) {
    return isNumeric(this.props.decimals) ? (isNumeric(value) ? formatMoney(value, this.props.decimals) : this.props.value) : value;
  }

  handleUpdate(e) {
    const { dispatch, update, field, id } = this.props;
    const { changed } = this.state;
    const value = this.format(this.state.value);
    this.setState({
      value,
      changing: false,
      changed: false
    }, () => {
      if (changed || value !== this.props.value) {
        dispatch(update(id, field, this.props.value, value));
      }
    });
  }

  render() {
    const { dispatch, decimals, request, update, field, id, value, className = 'field1', ...props } = this.props;
    return (
      <input {...props} className={className} type="text" value={this.state.value} onChange={this.handleChange} onBlur={this.handleUpdate} />
    );
  }
}

export default Field;
