import React from 'react';

const AvailableInventory = ({ quantity, label = null, block = false, wrapped = false, style = {}, }) => (
  <span
    style={{ ...(block ? { display: 'block' } : {}), ...style, }}
  >
    {wrapped ? '(' : ''}
    {label}{quantity === null ? 'N/A' : quantity}
    {wrapped ? ')' : ''}
  </span>
);

export default AvailableInventory;
