import { useState, useEffect, useMemo } from 'react';

/**
 *
 * @param {any} ref
 * @param {{
 *  rootMargin: string,
 *  root: Element | Document | null,
 *  thresholds: number | number[],
 * }} options
 * @returns {boolean}
 */
export default function useOnScreen(ref, options, onIntersection) {
    const [isIntersecting, setIntersecting] = useState(false);
    const { root, rootMargin, threshold } = options || {};
    const cachedOptions = useMemo(
        () => ({ root, rootMargin: rootMargin || '0px', threshold }),
        [root, rootMargin, threshold]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                onIntersection?.({ entry, observer });
            },
            cachedOptions
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, cachedOptions]);

    return isIntersecting;
}
