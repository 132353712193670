import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="row full-width">
      <div className="small-12 columms">
        <h1 style={{ float: 'left' }}>Edit Item</h1>
        <a className="large-button primary-button button" onClick={() => navigate(-1)} style={{ float: 'right' }}>Done</a>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({});

export default connect(null, mapDispatchToProps)(Header);
