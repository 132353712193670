import { orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { PublicViewTemplate } from '../models/PublicViewTemplate';
import { IPublicViewTemplateData } from '../interfaces/PublicViewTemplateData';
import { PublicViewTemplateProperty } from '../interfaces/PublicViewTemplateProperty';
import { IItem } from '../interfaces/Item';
import { getImageSrc } from '../utils';

const defaultCategoryTitle = (index: number) => `Category ${index + 1}`;
function getCategoryItems(items: IItem[], categoryId: string) {
  items = orderBy(items, [(i: IItem) => parseInt((i.display_order ?? 0) + '', 10)], 'asc');

  if (!categoryId) {
    return items.filter(i => 'OPTION' === i.parent_type);
  }
  const startIndex = items.map(i => i.item_id).indexOf(categoryId);
  if (-1 === startIndex) {
    return [];
  }
  const endIndex = items.slice(startIndex + 1).map(i => i.parent_type).indexOf('SEPARATOR');
  if (-1 === endIndex) {
    return items.slice(startIndex).filter(i => 'OPTION' === i.parent_type);
  }
  return items.slice(startIndex, startIndex + endIndex + 1).filter(i => 'OPTION' === i.parent_type);
}

const getPosterCategoryProductImage = (items: IItem[], category: { item_id: string }): string => {
  const image = getCategoryItems(items, category.item_id)
    .filter((i) => +i.hidden !== 1)
    .map((i) => i.images?.[0])?.[0];

  return image ? getImageSrc(image, "medium") : "";
};

const propertyFilter = (propertyName: string) => (stp: PublicViewTemplateProperty) => (
  stp.public_view_template_property_context === "DEFAULT" &&
  stp.public_view_template_property_name === propertyName
);

type TPublicViewTemplateData = IPublicViewTemplateData & {
  template_color?: string;
  product_list_style?: string;
  product_page_style?: string
};
const usePublicViewTemplateData = (initialValue: TPublicViewTemplateData) => {
  const [state, setState] = useState(initialValue);

  const templateId = state.public_view_template_id;
  const templateColor = state.templateData.template_color || state.template_color || '#5ca3b6';
  const productListStyle = state.templateData.product_list_style || state.product_list_style || 'TILE';
  const productPageStyle = state.templateData.product_page_style || state.product_page_style || 'CAROUSEL';

  const productInPopup = productPageStyle === 'POPUP';
  const productInDropdown = productPageStyle === 'DROPDOWN';
  const productInCarousel = productPageStyle === 'CAROUSEL';

  const hasSidebar = +((state.properties['HasSidebar'] ?? { value: 0 })?.value ?? 0) === 1;
  const isPoster = PublicViewTemplate.LEFT_NAV_HERO_IMAGE === state.public_view_template_name;
  const showHelpScreen = +(state.templateData.help_screen_visible ?? 0) === 1;

  const getPublicViewTemplateBooleanProperty = useCallback((
    propertyName: string
  ) => (
    state.templateData[propertyName] !== undefined && state.templateData[propertyName] !== null
      ? +(state.templateData[propertyName]) === 1
      : +(Object.values(state.public_view_template_properties).find(
        propertyFilter(propertyName)
      )?.public_view_template_property_value ?? "0") === 1
  ), [state.public_view_template_properties, state.templateData]);

  const getPublicViewTemplateDefaultProperty = useCallback((
    propertyName: string,
    defaultValue: string | number | null = null
  ) => (
    (Object.values(state.public_view_template_properties).find(
      propertyFilter(propertyName)
    )?.public_view_template_property_value) ?? defaultValue
    ), [state.public_view_template_properties]);

  const templateBannerOnHover = useMemo(
    () => getPublicViewTemplateBooleanProperty("BANNER-ON-HOVER"),
    [getPublicViewTemplateBooleanProperty]
  );
  const applyBannerToAllCategories = useMemo(
    () => getPublicViewTemplateBooleanProperty("APPLY-BANNER-TO-ALL-CATEGORIES"),
    [getPublicViewTemplateBooleanProperty]
  );
  const shopHelpScreenImage = useMemo(() => {
    const helpScreenImage = state.templateData.help_screen_image;
    if (!!helpScreenImage) {
      return helpScreenImage;
    }
    return getPublicViewTemplateDefaultProperty(
      'help-screen-image',
      '/images/shop-resources/left-nav-hero-template-default-7.jpeg'
    );
  }, [getPublicViewTemplateDefaultProperty, state.templateData.help_screen_image]);

  const getCategoryImage = useCallback((
    category: { item_id: string },
    index: number,
    banner = false
  ) => {
    let imageKey = `CATEGORY-${category.item_id}-IMAGE-${
      banner ? "BANNER" : "MAIN"
    }`;
    // if already customized, use the customized image
    const image = state.templateData[imageKey];
    if (image) {
      return image;
    }
    // fallback to default image
    const imageIndex = ((index + (banner ? 1 : 0)) % 10) + 1;
    imageKey = `CATEGORY-${imageIndex}-IMAGE-MAIN`;
    const defaultImage = `/images/shop-resources/left-nav-hero-template-default-${imageIndex}.jpeg`;

    return getPublicViewTemplateDefaultProperty(imageKey, defaultImage);
  }, [state.templateData, getPublicViewTemplateDefaultProperty]);

  const getPosterCategories = useCallback((items: IItem[]) => {
    const categories = items.filter((i) => 'SEPARATOR' === i.parent_type);
    const allCategoriesBannerImage = state.template_data?.['CATEGORY-ALL-IMAGE-BANNER']?.value || '';
    const applyBannerToAllCategpties = !!allCategoriesBannerImage
      && +(state.template_data?.['APPLY-BANNER-TO-ALL-CATEGORIES']?.value || 0)  === 1;
    const defaultBannerDimPercentage = getPublicViewTemplateDefaultProperty(
      'BANNER-DIM_PERCENTAGE',
      '0.5'
    );
    const useProductImages = +(state.template_data?.['useProductImages']?.value ?? 1) === 1;

    const categoriesWithImage = categories
      .map((c, i) => {
        const bannerDimPercentage = (
          state.template_data?.[`CATEGORY-${c.item_id}-BANNER-DIM`]?.value
        ) || defaultBannerDimPercentage;

        // 0 is banner, 1 is category image on home page
        const categoryImages = [
          applyBannerToAllCategpties
            ? allCategoriesBannerImage
            : getCategoryImage(c, i, true),
          useProductImages
            ? getPosterCategoryProductImage(items, c)
            : getCategoryImage(c, i),
        ];
        const itemImages = getCategoryItems(items, c.item_id).filter(i => +i.hidden !== 1).map(i => i.images?.[0]);

        return {
          item_id: c.item_id,
          display_order: c.display_order,
          item_name: c.item_name.trim() || defaultCategoryTitle(i),
          bannerDimPercentage,
          categoryImages,
          itemImages,
        };
      })
      .concat({
        item_name: 'ALL PRODUCTS',
        item_id: 'ALL_PRODUCTS',
        display_order: 0,
        bannerDimPercentage: (
          state.template_data?.['ALL-PRODUCTS-BANNER-DIM']?.value
        ) || defaultBannerDimPercentage,
        categoryImages: [
          applyBannerToAllCategpties
            ? allCategoriesBannerImage
            : (state.template_data?.['ALL-PRODUCTS-IMAGE-MAIN']?.value
                || getPublicViewTemplateDefaultProperty(
                  'CATEGORY-1-IMAGE-MAIN',
                  '/images/shop-resources/left-nav-hero-template-default-1.jpeg'
                )),
        ],
        itemImages: [],
      });
    return orderBy(
      categoriesWithImage,
      [(v: (typeof categoriesWithImage)[number]) => +v.display_order],
      ["asc"]
    ) as typeof categoriesWithImage;
  }, [state.template_data, getPublicViewTemplateDefaultProperty, getCategoryImage]);

  const getHeroImages = useCallback((limit = 1) => {
    if (!state.template_data) return [];

    let index = 0;
    const existing = [];
    let largeIndex = 0;
    for (const data in state.template_data) {
      if (data.indexOf('hero') === 0) {
        const index = parseInt(data.split('-')[0].replace('hero', ''));
        if (index > largeIndex) largeIndex = index;
      }
    }
    while (index <= largeIndex) {
      if (state.template_data[`hero${index}-image`] || state.template_data[`hero${index}-title`]) {
        existing.push({
          key: `hero${index}`,
          image: state.template_data[`hero${index}-image`] ? state.template_data[`hero${index}-image`].value : '',
          title: state.template_data[`hero${index}-title`] ? state.template_data[`hero${index}-title`].value : '',
        });
      }
      index++;
    }

    while (existing.length < limit) {
      largeIndex++;
      existing.push({
        key: `hero${largeIndex}`,
        image: '',
        title: '',
      });
    }

    return existing;
  }, [state.template_data]);

  const getCarouselImages = useCallback((limit = 1) => {
    return orderBy(
      getHeroImages(limit).map((image) => ({
        ...image,
        original: !image.image || image.image.length === 0 ? '/images/template3.jpg' : image.image,
      })),
      [(v: { key: string }) => parseInt(v.key.replace('-image', '').replace('-title', '').replace('hero', ''))],
      ['asc']
    );
  }, [getHeroImages]);

  const getCarouselImagesLastPos = useCallback((limit = 1) => {
    const carouselImages = getCarouselImages(limit);
    let heroImagesLen = carouselImages.length;
    if (heroImagesLen > 0) {
      const heroImages = orderBy(
        carouselImages.map((v: { key: string }) => v.key.replace('-image', '').replace('-title', '').replace('hero', '')),
        [(v: string) => parseInt(v)],
        ['asc']
      );
      heroImagesLen = parseInt(heroImages[heroImages.length-1]);
      if (isNaN(heroImagesLen)) {
        heroImagesLen = carouselImages.length;
      }
    }
    return heroImagesLen;
  }, [getCarouselImages]);

  const getShopCategoryWithImages = useCallback((items: IItem[]) => {
    const categories = items.filter((i: IItem) => 'SEPARATOR' === i.parent_type);

    return orderBy(
      categories.map((category, index) => {
        const itemImages = getCategoryItems(items, category.item_id).filter(i => +i.hidden !== 1).map(i => i.images?.[0]);
        const categoryImages = [];
        const mainImg = state.templateData?.[`CATEGORY-${category.item_id}-IMAGE-MAIN`];
        const hoverImg = state.templateData?.[`CATEGORY-${category.item_id}-IMAGE-HOVER`];

        if (mainImg) {
          categoryImages.push(mainImg);
        }
        if (hoverImg) {
          categoryImages.push(hoverImg);
        }
        return {
          ...category,
          categoryImages,
          itemImages,
          item_name: category.item_name.trim() || defaultCategoryTitle(index),
        };
      }),
      [(v: IItem) => +v.display_order],
      ['asc']
    );
  }, [state.templateData]);

  return useMemo(() => ({
    template: state,
    setState,
    templateData: state.templateData,
    template_data: state.template_data,
    templateId,
    templateColor,
    productListStyle,
    isPoster,
    hasSidebar,
    productPageStyle,
    showHelpScreen,
    productInPopup,
    productInDropdown,
    productInCarousel,

    getPublicViewTemplateBooleanProperty,
    getPublicViewTemplateDefaultProperty,
    getCategoryImage,
    getPosterCategories,
    getHeroImages,
    getCarouselImages,
    getCarouselImagesLastPos,
    getShopCategoryWithImages,

    templateBannerOnHover,
    applyBannerToAllCategories,
    shopHelpScreenImage,
  }), [
    state,
    templateId,
    templateColor,
    productListStyle,
    isPoster,
    hasSidebar,
    productPageStyle,
    showHelpScreen,
    productInPopup,
    productInDropdown,
    productInCarousel,

    getPublicViewTemplateBooleanProperty,
    getPublicViewTemplateDefaultProperty,
    getCategoryImage,
    getPosterCategories,

    templateBannerOnHover,
    applyBannerToAllCategories,
    shopHelpScreenImage,
    getCarouselImages,
    getCarouselImagesLastPos,
    getShopCategoryWithImages,
    getHeroImages,
  ]);
};

export default usePublicViewTemplateData;
