import _ from 'lodash';
import React, { Component, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import { colors, Button, TableIcon, themeOptions } from '@commonsku/styles';

import { getProductConfigurations } from '../promostandards';
import { getExtraCharges } from '../promostandard_utils';
import * as actions from '../actions/item';
import {
  createUpdate, updateItemCost, createAddItemCost,
  createDeleteItemCost, createAddComment, createDeleteComment, createAddItemLocation,
  createDeleteItemLocation, createFetchProductWarnings, createCheckProductWarnings,
  createAddItemWarning, createDeleteItemWarning,
  createAddItemRetailAdjustment, createDeleteItemRetailAdjustment,
  createUpdateItemDecoration, createCheckItemInCollection,
} from '../actions';
import { createLoadOrder } from '../actions/order';
import { createArtworkPopup, createSelectItemImagesPopup, createCheckInventoryLevelPopup,
  createSkuAxesSubsetPopup, createUpdateNetCostPopup, createSelectDecorationProductPopup,
  createSelectSkuOrAxisOptionPopup, createSelectBreakdownSkuPopup,
  createSelectPSSkuOptionsByColorSizePopup, createApplyMatrixToItemPopup, } from '../actions/popup';
import { createFetchProduct } from '../actions/product';
import { getFullOptionItem, getProduct } from '../selectors';
import { getImageSrc, isNumeric, applyMargin, recalculateMargin, generateUUID, parseMysqlDate, sizeSort, toTitleCase } from '../utils';
import Form from './Form';
import Img from './Img';
import ProductWarning from './ProductWarning';
import ItemWarnings from './ItemWarnings';
import PS from './PromostandardsLabel';
import PresentationItemChargeMenu from './PresentationItemChargeMenu';
import PresentationItemLocation from './PresentationItemLocation';
import Toggle from './Toggle';
import DecoratorSelector from "./decorate/DecoratorSelector";
import LocationDropdown from './decorate/LocationDropdown';
import { useSize } from '../hooks/useSize';
import { hasCustomLocations } from '../helpers/ps_locations';

const CloseDialogBtn = () => {
  const navigate = useNavigate();
  return (
    <a className="button"
      style={{ position: 'fixed', right: '1rem', top: '1rem', zIndex: 1 }}
      onClick={() => navigate(-1)}
    >Close</a>
  );
};

const _updatedUuidCache = {};
const cacheUUID = () => {
  const uuid = generateUUID();
  _updatedUuidCache[uuid] = true;
  return uuid;
};

const extraImageStyle = {
  display: 'inline-block',
  width: '32%',
  paddingTop: '32%',
  position: 'relative',
  margin: '0 1px'
};

const centerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
};

const PSColorAndSizeWarning = ({ product, isPS, render }) => {
  const [ref, size] = useSize();
  const style = {
    fontSize: 18,
    lineHeight: '28px',
    padding: '21px 16px',
  };
  const isSamePrice = !(_.toNumber(_.get(product, 'num_price_groups')) > 1);
  return !isPS || isSamePrice ? render() : <>
    <div style={{
      position: 'relative', margin: 0, padding: 0, display: 'inline-block', verticalAlign: 'top'
    }}>
      {render({ ref })}
      <div style={{
        position: 'absolute',
        left: size.offsetWidth / 2 - 17, top: size.offsetHeight + 12, width: 0, height: 0,
        borderLeft: '17px solid transparent',
        borderRight: '17px solid transparent',
        borderBottom: `24px solid ${colors.secondary2['20']}`,
      }}/>
    </div>
    <div style={{ marginTop: 17 }}>{
      <div style={{ ...style, backgroundColor: colors.secondary2['20'], color: colors.secondary2['dark'] }}>
        Not all colors and sizes are the same price
      </div>
    }</div>
  </>;
};

const ExtraValidImages = ({ images, opacity }) => {
  const [failed, setFailed] = useState({});
  const validImages = _.filter(images, (img) => {
    return !_.has(failed, img.file_id);
  });
  return <>
    {
      validImages.slice(0, 3).map((image, i) => {
        return <Toggle key={image.file_id} render={(setToggle) => {
          return <div style={{...extraImageStyle, ...(validImages.length > 3 && i === 2 ? {display: 'none'} : {})}}>
            <Img
              src={getImageSrc(image, 'large')} style={{ ...centerStyle, opacity, maxHeight: '100px' }}
              onError={() => {
                setToggle(false);
                setFailed({...failed, [image.file_id]: true});
              }}
            />
          </div>;
        }}/>;
      })
    }
    {
      validImages.length <= 3 ? null : <div style={{ ...extraImageStyle, fontWeight: 'bold', color: '#5CA3B6' }}>
        <div style={{ ...centerStyle, fontSize: 'small', opacity }}>
          +{validImages.length - 2} more
        </div>
      </div>
    }
  </>;
};

class PresentationItem extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    const state = this.initStateFromProps(this.props);
    this.state = {
      ...state,
      showRunCostMenu: false,
      showFixedCostMenu: false,
      comment: '',
      fetching_product_warning: false,
      changed: false,
      editPS: true,
      locations: [],
      in_collection: false,
			psLoaded: false,
      failedImages: [],
    };
    this._modified = {};

    _.bindAll(this, [
      'handleClickLeft', 'handleClickRight', 'handleAddComment',
      'handleChangeComment', 'handleFieldChange', 'handleFieldBlur',
      'onMouseEnterImage', 'onMouseLeaveImage', 'handleClickAddRunCost',
      'handleClickAddFixedCost', 'handleClickElsewhere', 'handleDeleteItemLocation',
      'handleClickFetchWarnings', 'handleClickAddRetailAdjustment', 'handleDeleteRetailAdjustment'
    ]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = this.initStateFromProps(newProps);
    if (newState) {
      this.setState(newState);
      if (_.isEmpty(this.state.item)) {
        if ('ps-products' === newProps.item.copied_from && !!newProps.item.ext_fob_id) {
          if (!newProps.product) {
            newProps.loadProduct(newProps.item.parent_id, {}).then(({ payload: { product } }) => {
              return this.loadPSProductConfigurations(product);
            });
          } else {
            this.loadPSProductConfigurations();
          }
        };
      }
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickElsewhere, false);
    if (!this.props.order.loaded) {
      this.props.loadOrder(this.props.order.order_id).then(() => {
        if (this.isPS()) {
          if (!this.props.product) {
            this.loadPSProduct(this.props.item);
          } else {
            this.loadPSProductConfigurations();
          }
        }
      });
    } else if (['esp-products', 'sage-products', 'dc-products'].includes(this.props.item.copied_from) &&
      1 == this.props.project.show_product_warnings) {
      this.props.onCheckProductWarnings(this.props.item.item_id, this.props.item.parent_id);
    } else if (this.isPS()) {
      if (!this.props.product) {
        this.loadPSProduct(this.props.item);
      } else {
        this.loadPSProductConfigurations();
      }
    }

    if (this.props.params && this.props.params.item_id) {
      this.props.checkItemInCollection(this.props.params.item_id).then((resp) => {
        if (resp && resp.payload !== null && resp.payload !== undefined) {
          this.setState({in_collection: resp.payload.value});
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickElsewhere, false);
  }

  async loadPSProductConfigurations(product) {
    const { locations } = await getProductConfigurations({
      productId: (product || this.props.product).ext_product_id,
    }) || {};
    this.setState({ locations: locations || [], psLoaded: true });
  }

  loadPSProduct(item) {
    this.props.loadProduct(item.parent_id, {}).then(({ payload: { product } }) => {
      return this.loadPSProductConfigurations(product);
    });
  }

  isPS() {
    return 'ps-products' === this.props.item.copied_from && !!this.props.item.ext_fob_id;
  }

  getBaseFormObjectsFromProps(props) {
    if (!props.order.loaded) {
      return null;
    }
    const item = this.formatItem(props.item);
    const formObjects = _.assign(
      { [item.item_id]: item },
      _.keyBy(item.options, 'item_id'),
      _.keyBy(item.fixed_costs, 'item_cost_id'),
      _.keyBy(item.item_locations.map(il => ({
        ...il,
        item_location_title: il.item_location_title.replace(/^Location [0-9]+:: /, '')
      })), 'item_location_id'),
      _.keyBy(item.base_run_costs, 'item_cost_id'),
      item.item_retail_adjustments.reduce(
        (o, ira) => ({
          ...o,
          [ira.item_retail_adjustment_id]: ira
        }),
        {}
      ),
      _.reduce(item.run_costs, (obj, rcs) => {
        _.each(rcs, (rc) => {
          obj[rc.item_cost_id] = rc;
        });
        return obj;
      }, {})
    );
    return formObjects;
  }

  initStateFromProps(props) {
    if (!props.order.loaded) {
      return null;
    }
    const { changed } = this.state;
    const item = this.formatItem(props.item);
    const origFormObjects = _.get(this, 'state.formObjects', {});
    const prevFormObjects = this.getBaseFormObjectsFromProps(this.props);
    let formObjects = this.getBaseFormObjectsFromProps(props);
    const resetCache = [];
    formObjects = _.mapValues(formObjects, (obj, key) => {
      if (changed && _.isEqual(prevFormObjects[key], obj)) {
        return origFormObjects[key];
      }
      if (!origFormObjects[key]) {
        return obj;
      } else if (!_updatedUuidCache[obj.updateUUID]) {
        resetCache.push(obj.updateUUID);
        return Object.assign({}, origFormObjects[key], obj);
      } else {
        resetCache.push(obj.updateUUID);
        return origFormObjects[key];
      }
    });
    resetCache.forEach(uuid => { _updatedUuidCache[uuid] = false; });

    return {
      item: item,
      formObjects: formObjects
    };
  }

  getFormData() {
    return _.reduce(this.state.formObjects, (formData, obj, key) => {
      _.each(obj, (value, field) => {
        formData[key + '.' + field] = value;
      });
      return formData;
    }, {});
  }

  isValueValid(field, value) {
    switch (field) {
      case 'quantity':
      case 'total_units':
        return isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'total_margin':
      case 'unit_cost':
      case 'unit_price':
        return isNumeric(value);
    }
    return true;
  }

  formatCost(cost) {
    return _.mapValues(cost, (v, k) => {
      return this.formatValue(k, v);
    });
  }

  formatItem(item) {
    return Object.assign({}, item, {
      private_notes: item.private_notes ?? '',
      fixed_costs: item.fixed_costs.map(ic => this.formatCost(ic)),
      run_costs: item.run_costs.map(rcs => rcs.map(ic => this.formatCost(ic))),
      item_retail_adjustments: item.item_retail_adjustments.map(ira => this.formatCost(ira)),
      options: item.options.map(o => _.mapValues(o, (v, k) => {
        return this.formatValue(k, v);
      }))
    });
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
      case 'total_units':
        return parseFloat(value).toFixed(0);
      case 'unit_price':
      case 'total_margin':
      case 'adjustment':
        return parseFloat(value).toFixed(2);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
    }
    return value;
  }

  handleClickLeft(item) {
    const index = _.findIndex(item.files, { file_id: item.image_id });
    const file = item.files[(index === 0 ? item.files.length : index) - 1];
    const { field_lock } = this.props;
    this.props.onUpdateItem(item.item_id, 'image_id', item.item_id, field_lock)(file.file_id);
  }

  handleClickRight(item) {
    const index = _.findIndex(item.files, { file_id: item.image_id }) + 1;
    const file = item.files[index === item.files.length ? 0 : index];
    const { field_lock } = this.props;
    this.props.onUpdateItem(item.item_id, 'image_id', item.item_id, field_lock)(file.file_id);
  }

  handleChangeComment(value) {
    this.setState({ comment: value });
  }

  handleAddComment(item_id, tenant_id, commenter_name, commenter_email, comment) {
    this.props.onAddComment(item_id, tenant_id, commenter_name, commenter_email, comment);
    this.setState({ comment: '' });
  }

  handleFieldChange(value, _field) {
    const [id, field] = _field.split('.');
    if (this.isValueValid(field, value)) {
      this.updateForm(id, value, field);
    }
  }

  handleFieldBlur(value, _field, error) {
    if (error) {
      return;
    }
    const [id, field] = _field.split('.');
    this.updateForm(id, this.formatValue(field, value), field, true);
  }

  onMouseEnterImage() {
    this.setState({ hoverImage: true });
  }

  onMouseLeaveImage() {
    this.setState({ hoverImage: false });
  }

  updateForm(id, value, field, update) {
    let formObjects = this.state.formObjects;
    const item_id = this.state.item.item_id;
    const orig = formObjects[id];
    let obj = orig;
    const _field = id + '.' + field;
    if (!_.isEqual(orig[field], value)) {
      this._modified[_field] = true;
      formObjects = this.updateFormObject(formObjects, id, { [field]: value });
      obj = formObjects[id];
      let item = formObjects[obj.item_id];
      if (obj.item_retail_adjustment_id !== id && item.parent_type === 'PRODUCT' && item.option_item_id === item_id) {
        // option item or run cost
        formObjects = this.recalcuateOption(obj.item_id, field, formObjects);
      } else if (obj.item_cost_id === id) {
        // fix cost
        formObjects = this.recalcuateFixedCost(obj.item_cost_id, field, formObjects);
      }
      this.setState({
        formObjects: formObjects,
        changed: true
      }, () => {
        if (update && this._modified[_field]) {
          this.submit(id, value, field, obj, orig);
        }
      });
    } else {
      if (update && this._modified[_field]) {
        this.submit(id, value, field, obj, orig);
      }
    }
  }

  submit(id, value, field, obj, orig) {
    const { field_lock } = this.props;
    const item_id = this.state.item.item_id;
    delete this._modified[id + '.' + field];
    if (item_id === id) {
      // update item
      this.props.onUpdateItem(id, field, orig[field], field_lock)(value);
    } else if (obj.parent_type === 'PRODUCT' && obj.option_item_id === item_id) {
      // update item option
      if (field === 'unit_cost') {
        this.props.onUpdateBreakdown(
          obj.breakdown_id, field, orig[field], value, field_lock, {
            id,
            data: { unit_price: obj['unit_price'] },
          });
      } else {
        let alsoUpdate = {};
        if (field === 'unit_price') {
          alsoUpdate = { total_margin: obj['total_margin'] };
        } else if (field === 'total_margin') {
          alsoUpdate = { unit_price: obj['unit_price'] };
        }
        this.props.onUpdateItem(id, field, orig[field], field_lock, alsoUpdate)(value);
      }
    } else if (obj.item_cost_id === id) {
      // run / fixed cost
      this.props.onUpdateCost(
        id, this.state.item.breakdown_cost, field, orig[field], value);
    } else if (obj.item_location_id === id) {
      if (field === 'item_location_title') {
        value = _.keyBy(this.props.item.item_locations, 'item_location_id')[id].item_location_title.replace(/^(Location [0-9]+:: )(.*)$/, `$1${value}`);
      }
      this.props.onUpdateItemLocation(obj.item_location_id, field, orig[field], value);
    } else if (obj.item_retail_adjustment_id === id) {
      this.props.onUpdateRetailAdjustment(obj.item_retail_adjustment_id, field, orig[field], value);
    }
  }

  updateFormObject(formObjects, id, patch) {
    const obj = formObjects[id];
    if (_.isEmpty(patch) || _.isEqual(obj, patch)) {
      return formObjects;
    }
    return {
      ...formObjects,
      [id]: {
        ...obj,
        ...patch,
      }
    };
  }

  recalcuateFixedCost(item_cost_id, field, formObjects) {
    let {
      unit_cost, total_margin, unit_price
    } = formObjects[item_cost_id];
    switch (field) {
      case 'unit_price':
        total_margin = this.formatValue(
          'total_margin', recalculateMargin(unit_cost, unit_price, Number(this.props.item.exchange_rate)));
        break;
      case 'unit_cost':
        unit_price = this.formatValue(
          'unit_price', applyMargin(unit_cost, total_margin, Number(this.props.item.exchange_rate)));
        break;
      case 'total_margin':
        unit_price = this.formatValue(
          'unit_price', applyMargin(unit_cost, total_margin, Number(this.props.item.exchange_rate)));
        break;
    }
    return this.updateFormObject(formObjects, item_cost_id, {
      unit_cost, total_margin, unit_price,
    });
  }

  recalcuateOption(item_id, field, formObjects) {
    // recalcuateOption is called in constructor and componentWillReceiveProps,
    // so this method can not depends on this.state and this.props
    let {
      unit_cost, total_margin, unit_price
    } = formObjects[item_id];
    const sum_cost = _.sumBy(_.filter(formObjects, { item_id, }), (obj) => {
      return parseFloat(obj.unit_cost || 0, 10);
    });
    if (parseFloat(total_margin, 10) === 100) {
      total_margin = 0;
    }
    switch (field) {
      case 'unit_price':
        total_margin = this.formatValue(
          'total_margin', recalculateMargin(sum_cost, unit_price, Number(this.props.item.exchange_rate)));
        break;
      case 'unit_cost':
        unit_price = this.formatValue(
          'unit_price', applyMargin(sum_cost, total_margin, Number(this.props.item.exchange_rate)));
        break;
      case 'total_margin':
        unit_price = this.formatValue(
          'unit_price', applyMargin(sum_cost, total_margin, Number(this.props.item.exchange_rate)));
        break;
    }
    return this.updateFormObject(formObjects, item_id, {
      unit_cost, total_margin, unit_price,
    });
  }

  handleDeleteCost(item_cost_id) {
    let {
      item, formObjects,
    } = this.state;
    formObjects = _.omitBy(formObjects, (obj) => {
      return obj.item_cost_id === item_cost_id ||
        obj.option_item_cost_id === item_cost_id;
    });
    const alsoUpdate = _.map(item.options, ({ item_id, }) => {
      formObjects = this.recalcuateOption(item_id, 'unit_cost', formObjects);
      return {
        id: item_id, data: { unit_price: formObjects[item_id].unit_price }
      };
    });
    return this.props.onDeleteCost(item_cost_id, alsoUpdate);
  }

  handleDeleteItemLocation(item_location_id) {
    let {
      item, formObjects,
    } = this.state;
    const itemLocation = formObjects[item_location_id];
    formObjects = _.omitBy(formObjects, (obj) => {
      return obj.item_location_id === item_location_id ||
        obj.option_item_location_id === itemLocation.item_location_id;
    });
    const alsoUpdate = _.map(item.options, ({ item_id, }) => {
      formObjects = this.recalcuateOption(item_id, 'unit_cost', formObjects);
      return {
        id: item_id, data: { unit_price: formObjects[item_id].unit_price }
      };
    });
    return this.props.onDeleteItemLocation(item_location_id, alsoUpdate);
  }

  handleClickAddRunCost(e) {
    e.stopPropagation();
    const { item } = this.state;
    if (item.item_locations.length) {
      this.setState({ showRunCostMenu: true, showFixedCostMenu: false });
    } else {
      this.setState({ showRunCostMenu: false, showFixedCostMenu: false }, () => this.props.onAddCost(item.item_id, null, true));
    }
  }

  handleClickAddFixedCost(e) {
    e.stopPropagation();
    const { item } = this.state;
    if (item.item_locations.length) {
      this.setState({ showFixedCostMenu: true, showRunCostMenu: false });
    } else {
      this.setState({ showRunCostMenu: false, showFixedCostMenu: false }, () => this.props.onAddCost(item.item_id, 1));
    }
  }

  handleClickAddRetailAdjustment(e) {
    e.stopPropagation();
    const { item } = this.state;
    this.props.onCreateSelectSkuOrAxisOptionPopup(item.parent_id, 'axis', null, item.item_id);
  }

  handleDeleteRetailAdjustment(item_retail_adjustment_id) {
    this.props.onDeleteRetailAdjustment(item_retail_adjustment_id);
  }

  handleClickElsewhere(e) {
    const node = this.state.showRunCostMenu ? findDOMNode(this.runCostMenu) : findDOMNode(this.fixedCostMenu);
    if (!node || e.target !== node && !node.contains(e.target)) {
      if (node) {
        e.preventDefault();
      }
      this.setState({ showRunCostMenu: false, showFixedCostMenu: false });
    }
  }

  handleClickFetchWarnings(item_id, product_id) {
    this.setState({ fetching_product_warning: true });

    const _this = this;
    window.setTimeout(function() {
      _this.props.onCreateFetchProductWarnings(item_id, product_id);
      _this.setState({ fetching_product_warning: false });
    }, 1000);
  }

  renderLoading() {
    return (
      <div
        className="presentation-item"
        style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, background: 'white', width: '100%', height: '100%' }}>
        <div className="row full-width">
          <div className="small-12 columns modal-header">
            <h1>Edit Item</h1>
            <CloseDialogBtn />
          </div>
        </div>
        <div className="row full-width">
          <div className="small-12 columns" style={{ textAlign: 'center' }}>
            <img src="/images/gears.gif" />
          </div>
        </div>
      </div>
    );
  }

  renderImages() {
    const {
      locked,
      onCreateSelectItemImagesPopup
    } = this.props;

    const {
      item,
      hoverImage
    } = this.state;

    const default_image = item.item_images.map(ii => ii.image)[0];
    const extra_images = item.item_images.map(ii => ii.image).slice(1);

    const handleSelectItemImages = e => {
      e.preventDefault();
      onCreateSelectItemImagesPopup(item.item_id, locked);
    };

    const opacity = hoverImage ? 0.5 : 1;

    const centerStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    };

    const editStyle = {
      ...centerStyle,
      color: 'white',
      fontSize: 'large',
      fontWeight: 'bold'
    };

    return (
      <div onMouseEnter={this.onMouseEnterImage} onMouseLeave={this.onMouseLeaveImage} onClick={handleSelectItemImages} style={{ backgroundColor: hoverImage ? 'grey' : 'white', cursor: 'pointer' }}>
        <div style={{ position: 'relative', width: '100%', marginBottom: '3px' }}>
          <Img src={getImageSrc(default_image, 'large')} style={{ opacity }} />
          {hoverImage &&
            <div style={editStyle}>
              Click to {locked ? 'View' : 'Edit'}
            </div>}
        </div>
        <ExtraValidImages images={extra_images} opacity={opacity}/>
      </div>
    );
  }

  renderSelectColors() {
    const {
      onCreateColorSizeSubsetPopup,
      locked,
      order
    } = this.props;
    const { item } = this.state;
    if (this.isPS()) {
      return null;
    }
    const item_sku = item.item_sku ? item.item_sku : (item.product ? item.product.product_supplier_code : '** Unknown **');
    const values = item.product_colors.split(',').map(v => v.trim()).filter(v => v);
    return [
      <div key="colors-row" className='row'>
        <div className='small-12 columns'>
          <label key="colors-label" style={{ float: 'left' }}>Product Colors</label>
          {(!locked && 'COLLECTION' !== order.order_type) && <Button
            key="colors-button"
            size='tiny'
            style={{ fontWeight: 'bold', float: 'right', marginBottom: '0.25rem' }}
            onClick={e => { e.preventDefault(); onCreateColorSizeSubsetPopup(item.item_id, item.parent_id, 'color', item_sku, item.promo_api_name); }}
          >Update Colors</Button>}
          {values.length ? <div className='small-12 columns' key='product_color-tags'>
              {values.map((v, i) => <SkuOptionTag uppercase key={'product_color-tag-'+v+i}>{v}</SkuOptionTag>)}
          </div> : null}
        </div>
      </div>
    ];
  }

  renderSelectSizes() {
    const {
      onCreateColorSizeSubsetPopup,
      locked,
      order
    } = this.props;
    const { item } = this.state;
    if (this.isPS()) {
      return null;
    }
    const item_sku = item.item_sku ? item.item_sku : (item.product ? item.product.product_supplier_code : '** Unknown **');
    const values = item.product_sizes.split(',').map(v => v.trim()).filter(v => v);
    return [
      <div key="sizes-row" className='row'>
        <div className='small-12 columns'>
          <label key="sizes-label" style={{ float: 'left' }}>Product Sizes</label>
          {(!locked && 'COLLECTION' !== order.order_type) && <Button
            key="sizes-button"
            size='tiny'
            style={{ fontWeight: 'bold', float: 'right', marginBottom: '0.25rem' }}
            onClick={e => { e.preventDefault(); onCreateColorSizeSubsetPopup(item.item_id, item.parent_id, 'size', item_sku, item.promo_api_name); }}
          >Update Sizes</Button>}
          {values.length ? <div className='small-12 columns' key='product_size-tags'>
              {values.map((v, i) => <SkuOptionTag uppercase key={'product_size-tag-'+v+i}>{v}</SkuOptionTag>)}
          </div> : ''}
        </div>
      </div>
    ];
  }

  renderSelectSkus() {
    const {
      onCreateSelectSkuOptionsPopup,
      locked,
      order
    } = this.props;
    const { item } = this.state;
    if (!this.isPS()) {
      return null;
    }

    const sku_options = (item.item_skus || []).reduce(
      (t, item_sku) => ({
        ...t,
        ...item_sku.options.reduce(
          (o, option) => ({
            ...o,
            [option.option_axis]: Array.from(new Set((t[option.option_axis] || []).concat(option.option_name))).sort(
              'size' === option.option_axis ? sizeSort : undefined
            )
          }),
          {}
        )
      }),
      {}
    );

    const colors = sku_options['color'] ?? [];
    const sizes = sku_options['size'] ?? [];
    const item_sku = item.item_sku ? item.item_sku : (item.product ? item.product.product_supplier_code : '** Unknown **');

    return [
      <div key="label-row" className='row' style={{ paddingBottom: 14 }}>
        <div className='small-12 columns'>
          <label key="options-label" style={{ float: 'left' }}>Product Options</label>
        </div>
      </div>,
      <div key="colors-row" className='row' style={{ paddingBottom: 14 }}>
        <div className='small-12 columns'>
          {(!locked && 'COLLECTION' !== order.order_type) && <Button
            key="color-skus-button"
            className='colors-button'
            size='tiny'
            style={{ fontWeight: 'bold', float: 'right', marginBottom: '0.25rem' }}
            onClick={e => { e.preventDefault(); onCreateSelectSkuOptionsPopup(item.item_id, item.parent_id, 'sku', 0, item_sku, item.promo_api_name, item.default_sku_id); }}
          >Update Colors</Button>}
        </div>
        {colors.length ? <div className='small-12 columns'>
            {colors.map(c => <SkuOptionTag key={'color-sku-tag-'+c}>{c}</SkuOptionTag>)}
        </div> : null}
      </div>,
      <div key="sizes-row" className='row'>
        <div className='small-12 columns'>
          {(!locked && 'COLLECTION' !== order.order_type) && <Button
            key="size-skus-button"
            className='sizes-button'
            size='tiny'
            style={{ fontWeight: 'bold', float: 'right', marginBottom: '0.25rem' }}
            onClick={e => { e.preventDefault(); onCreateSelectSkuOptionsPopup(item.item_id, item.parent_id, 'sku', 1, item_sku, item.promo_api_name, item.default_sku_id); }}
          >Update Sizes</Button>}
        </div>
        {sizes.length ? <div className='small-12 columns'>
            {sizes.map(s => <SkuOptionTag key={'size-sku-tag-'+s}>{s}</SkuOptionTag>)}
        </div> : null}
      </div>,
    ];
  }

  render() {
    const {
      product,
      onAddCost,
      onAddItemLocationCost,
      onAddItemLocation,
      onDeleteComment,
      company_id,
      user_name,
      user_email,
      locked,
      popups,
      onCreateCheckInventorylevelPopup,
      onCreateUpdateNetCostPopup,
      onCreateSelectDecorationProductPopup,
      onAddItemWarning,
      onUpdateItem,
      onUpdateItemWarning,
      onDeleteItemWarning,
      order_currency_id,
      order,
      hasMargin,
      show_product_warnings,
      onUpdatePricingSku,
      canEditPS,
      onCreateApplyMatrixToItemPopup,
      useMarginCurrency,
			quirks,
      onCreateSelectSkuOptionsPopup,
    } = this.props;

    const { item, locations, editPS, psLoaded } = this.state;

    if (!order.loaded) {
      return this.renderLoading();
    }

    const extraCharges = getExtraCharges(item.division_id);
    const isEditableCharge = (item_cost) => {
      if (!this.isPS() || item_cost.ext_cost_id === null) {
        return true;
      }
      const charge = extraCharges.find(
        c => c.ps_charge_id == item_cost.ext_cost_id && (
          ('RUN' === c.charge_type.toUpperCase() && item_cost.quantity === null) ||
          ('RUN' !== c.charge_type.toUpperCase() && item_cost.quantity !== null)
        )
      );
      return !!charge && charge.is_editable;
    };

    const item_locations = item.item_locations.map((il, index) => ({ ...il, index }));
    const indexedOptions = item.options.reduce((o, i) => ({ ...o, [i.item_id]: i }), {});
    const option_item_locations = item.options.reduce((o, i) => ({
      ...o,
      ...(i.item_locations.reduce((o2, il, index) => ({
        ...o2,
        [il.item_location_id]: { ...il, index }
      }), {}))
    }), {});
    const indexed_item_locations = item_locations.reduce((o, il) => ({ ...o, [il.item_location_id]: il }), option_item_locations);
    const product_url = `/product.php?id=${item.parent_id}`;

    const hidden_style = {
      opacity: 0.5
    };
    const division_name = ((null == item.division_name) ? (null == item.product || null == item.product.division_name ? 'Unspecified' : item.product.division_name) : item.division_name);

    const fieldProps = {
      disabled: locked,
      displayLabel: false,
      validate: (value, _fieldProps, __) => {
        const [_, field] = _fieldProps.field.split('.');
        return this.isValueValid(field, value) ? null : {
          error: 'Invalid Value',
        };
      },
    };

    const item_sku = item.item_sku ? item.item_sku : (item.product ? item.product.product_supplier_code : '** Unknown **');

    const handleClickUpdateCost = e => {
      e.preventDefault();
      if (!this.isPS()) {
        onCreateUpdateNetCostPopup(item, order_currency_id, locked);
      } else {
        const isCostChanged = item.options.reduce(
          (t, o) => t || 1 == o.costs_changed,
          false
        );
        onCreateSelectSkuOptionsPopup(item.item_id, item.parent_id, 'pricing', 3, item_sku, item.promo_api_name, item.default_sku_id, isCostChanged);
      }
    };

    const ext_location_ids = item.item_locations.map(il => il.ext_location_id);
    const isPSConfigurable = this.isPS() && !!locations.length;
    const is3rdPartyDecorator = !!JSON.parse(item.is_3rd_party_decorator);
    const showNonPSAddChargeButton = !this.isPS()
      || (!locations.length > 0 && item_locations.length > 0)
      || (is3rdPartyDecorator && item_locations.length > 0)
      || hasCustomLocations(item.item_locations)
    ;
    const showPSAddChargeButton = !is3rdPartyDecorator && isPSConfigurable && item_locations.length > 0  ;

    const sku = (product || { skus: []}).skus.filter(sku => sku.product_sku_id === item.default_sku_id).map(sku => sku.sku)[0];

    const availableLocations = _.filter(locations, l => !ext_location_ids.includes(`${l.ps_location_id}`));
    const allLocationsTaken = !_.isEmpty(locations) && _.isEmpty(availableLocations);

    const isCostChanged = this.isPS() &&
      item.options.reduce(
        (t, o) => t || 1 == o.costs_changed,
        false
      );

    const retailAdjustmentPrefix = <span style={{
      position: 'absolute',
      top: '0.65rem',
      left: '0.7rem',
      fontWeight: 'bold',
      background: '#EDF2F5',
      borderRight: '1px solid #CCD5DA',
      padding: '0.3rem 0.5rem 0.45rem 0.5rem',
      cursor: 'default'
    }}>+</span>;

    return (
      <div
        className="presentation-item"
        style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, background: 'white', width: '100%', height: '100%', overflow: popups.length ? 'hidden' : 'auto' }}>
        <div className="row full-width">
          <div className="small-12 columns modal-header">
            <h1>Edit Item</h1>
            {!popups.length ? <CloseDialogBtn /> : null}
          </div>
        </div>

        <Form className="row full-width mega-modal-content form"
          object={this.getFormData()}
          onFieldChange={this.handleFieldChange}
          onFieldBlur={this.handleFieldBlur}>
          <div className="medium-9 columns">
            <div className="row collapse">
              <div className="small-12 large-4 columns" style={{ paddingRight: '1rem' }}>
                <label>Product Name</label>
                <Form.TextInput {...fieldProps} field={item.item_id + '.item_name'} />
              </div>

              <div className="small-12 medium-4 large-3 columns" style={{ paddingRight: '1rem' }}>
                <label>Supplier</label>
                <p>{division_name}</p>
              </div>

              <div className="small-12 medium-4 large-2 columns" style={{ paddingRight: '1rem' }}>
                <label>SKU</label>
                <p>{item_sku}</p>
              </div>

              {!this.isPS() && <div className="small-12 medium-4 large-3 columns" style={{ paddingRight: '1rem' }}>
                <label>Imprint Methods</label>
                <p>{item.product_imprints && item.product_imprints.length ? item.product_imprints.join(', ') : 'Unspecified'}</p>
              </div>}
            </div>

            <label>Description</label>
            <Form.Textarea {...fieldProps}
              field={item.item_id + '.item_description'} />

            {item.product_warnings ? <ProductWarning warnings={item.product_warnings} show={show_product_warnings} loading={this.state.fetching_product_warning} /> : null}
            <ItemWarnings locked={locked} onAddItemWarning={onAddItemWarning} onUpdateItemWarning={onUpdateItemWarning} onDeleteItemWarning={onDeleteItemWarning} item_warnings={item.item_warnings} />

            <a href={product_url} target="_blank" className="button">View Product Page</a>
            &nbsp; &nbsp; &nbsp;
            {
              'COLLECTION' !== order.order_type && <>
                {(item.promo_api_name || this.isPS()) && <div style={{ display: 'inline-block' }}>
                  <a
                    className="button"
                    onClick={e => {
                      e.preventDefault();
                      if (!this.isPS()) {
                        onCreateCheckInventorylevelPopup(item_sku, item.promo_api_name, item.item_id);
                      } else {
                        onCreateSelectSkuOptionsPopup(item.item_id, item.parent_id, 'inventory', 2, item_sku, item.promo_api_name, item.default_sku_id);
                      }
                    }}
                  >Check inventory levels</a>
                  &nbsp; &nbsp; &nbsp;
                </div>}
                <PSColorAndSizeWarning product={product} isPS={this.isPS()} render={(props) => {
                  return <a {...props} className="button" onClick={handleClickUpdateCost}>Check Pricing
                    {item.preferred_pricing == 1 ?
                      <span style={{ fontStyle: 'italic', fontSize: '0.75rem' }}> (ESP Preferring Pricing Available)</span>
                      : null}
                  </a>;
                }} />
              </>
            }

            &nbsp; &nbsp; &nbsp;
            {show_product_warnings == 1 &&
              <div style={{ display: 'inline-block', position: 'absolute' }}>
                <a className="button" style={{ marginBottom: 0 }} onClick={e => { e.preventDefault(); this.handleClickFetchWarnings(item.item_id, item.parent_id); }}>
                  Check Prop65 Warnings
                </a>
                <p style={{ fontStyle: 'italic', fontSize: '0.75rem' }}>
                  {item.date_warnings_updated ? `Last updated ${item.date_warnings_updated}` : 'Never checked'}
                </p>
              </div>}

            <span style={{ float: 'right' }}>
              <label style={{ display: 'inline-block' }}>Currency: </label>
              <p style={{ margin: 0, display: 'inline-block' }}>{item.currency_id}</p>
              {(item.currency_id !== order.currency_id && useMarginCurrency) && <>
                <br />
                <label style={{ display: 'inline-block' }}>Currency Conversion: </label>
                <p style={{ margin: 0, display: 'inline-block' }}>
                  1 {item.currency_id} = <input type="text" style={{ width: '50%', display: 'inline-block' }} readOnly={true} value={item.exchange_rate} /> {order.currency_id} <button className="button tiny" onClick={e => onUpdateItem(item.item_id, 'exchange_rate', item.exchange_rate)(item.exchange_rate)}>Update</button>
                </p>
              </>}
            </span>

            {'COLLECTION' !== order.order_type && ('SHOP' !== order.order_type ?
              <div className="checkbox-field">
                {order.hide_pricing == 0 ? <Form.Checkbox {...fieldProps} field={item.item_id + '.share_pricing'} /> : null}
                <label>{order.hide_pricing == 0 ? 'Pricing:' : 'Pricing Hidden'}</label>
                {(this.isPS() && canEditPS) && <div style={{ float: 'right' }}>
                  <input type="checkbox" checked={editPS} onChange={() => this.setState(prevState => ({ editPS: !prevState.editPS }))} />
                  <label>Edit <PS style={{ marginRight: 'initial' }} /> Pricing</label>
                </div>}
              </div> :
              <label>Pricing:</label>)}
            {'COLLECTION' !== order.order_type && <table className="pricing-table">
              <tbody>
                <tr>
                  <td colSpan="2">Qty</td>
                  {_.map(item.options, (i, k) =>
                    <td key={k} style={i.hidden == 1 ? hidden_style : null}>
                      <Form.TextInput {...fieldProps} selectOnFocus={true}
                        field={i.item_id + '.total_units'} />
                    </td>)}
                </tr>
                <tr>
                  <td colSpan="2">Show to client?</td>
                  {_.map(item.options, (i, k) =>
                    <td key={k} style={{ textAlign: 'left', ...(i.hidden == 1 ? hidden_style : null) }}>
                      <Form.Checkbox {...fieldProps}
                        getValue={e => e.target.checked ? '0' : '1'}
                        setValue={v => v == 0}
                        field={i.item_id + '.hidden'} />
                    </td>)}
                </tr>
                <tr>
                  <td colSpan="2">Cost {isCostChanged && <i title="The costs have been updated from those provided by the supplier.  The cost displayed will be used for all SKUs." style={{ color: '#BF3F69' }} className="fi-info"></i>}</td>
                  {_.map(item.options, (i, k) =>
                    <td key={k} style={i.hidden == 1 ? hidden_style : null}>
                      <Form.TextInput {...fieldProps} selectOnFocus={true} disabled={locked || (this.isPS() && !editPS)}
                        field={i.item_id + '.unit_cost'} />
                    </td>)}
                </tr>
                {_.map(item.run_costs, (rcs, idx) => <tr key={idx}>
                  <td>
                    {/* {(!locked && !isPSChargeMandatory(rcs[0].ext_cost_id)) ?
                    <a className="delete"
                      onClick={e => {
                        e.preventDefault();
                        this.handleDeleteCost(rcs[0].option_item_cost_id);
                      }}>&times;</a> : null} */}

                    {!locked &&
                      <a className="delete" style={{padding: '5px'}}
                        onClick={
                          e => {e.preventDefault(); this.handleDeleteCost(rcs[0].option_item_cost_id);}
                        }>
                        &times;
                      </a>}
                  </td>
                  <td style={{ paddingLeft: 0, ...(item.hidden == 1 ? hidden_style : null) }}>
                    {indexed_item_locations[rcs[0].item_location_id] &&
                      <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: 0 === idx ? 0 : '0.25rem' }}>{indexed_item_locations[rcs[0].item_location_id].index + 1}</div>}
                    {!!rcs[0].ext_cost_id && <PS style={{ position: 'absolute', top: '1rem', left: '2rem' }} />}
                    <Form.TextInput {...fieldProps} selectOnFocus={true} containerStyle={{ float: 'left', paddingLeft: '0.25rem', width: 'calc(100% - 1.75rem)'}} disabled={locked || !isEditableCharge(rcs[0])} style={{ paddingLeft: !!rcs[0].ext_cost_id ? '2rem': '0.5rem' }}
                      field={rcs[0].option_item_cost_id + '.item_cost_title'}
                      hasIcon={!(!indexed_item_locations[rcs[0].item_location_id] || locked || (!!rcs[0].ext_cost_id || this.state.in_collection))}
                      iconRight={true}
                      IconElem={TableIcon}
                      iconStyles={{width: 30, height: 30, cursor: 'pointer'}}
                      containerStyles={{paddingBottom: 0}}
                      iconProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          if (!this.state.in_collection && onCreateApplyMatrixToItemPopup) {
                            onCreateApplyMatrixToItemPopup({
                              qty: fieldProps.value,
                              charge_type: 'run',
                              onAddItemCost: this.handleFieldBlur,
                              isPresentation: true,
                              label_field: rcs[0].option_item_cost_id + '.item_cost_title',
                              presentation_item_data: {
                                item_options: item.options,
                                item_option_qty_key: 'total_units',
                                item_costs: rcs,
                                item_cost_key: 'item_cost_id',
                              },
                            });
                          }
                        }
                      }}
                    />
                  </td>
                  {_.map(rcs, (rc) => <td key={rc.item_cost_id} style={{ ...(indexedOptions[rc.item_id].hidden == 1 ? hidden_style : null) }}>
                    <Form.TextInput {...fieldProps} selectOnFocus={true}
                      field={rc.item_cost_id + '.unit_cost'} disabled={locked || (!!rc.ext_cost_id && !editPS)}
                    />
                  </td>)}
                </tr>)}
                {(!locked && 'COLLECTION' !== order.order_type) ? <tr>
                  <td colSpan="7" style={{ textAlign: 'center' }}>
                    {showNonPSAddChargeButton && <a onClick={this.handleClickAddRunCost}>
                      + add run charge
                    </a>}
                    {showPSAddChargeButton && <PresentationItemChargeMenu
                      charge_type="RUN"
                      ps_locations={locations}
                      item_locations={item_locations}
                      item_decorations={item.item_decorations}
                      item_costs={item.base_run_costs}
											quirks={quirks}
											division_id={item.division_id}
                      onAdd={onAddItemLocationCost}
                    >
                      <a> <PS style={{ marginRight: 0 }} />  Add run charge</a>
                    </PresentationItemChargeMenu>}
                    {this.state.showRunCostMenu &&
                      <div ref={(ref) => this.runCostMenu = ref} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 1, border: '1px solid #5CA3B6', borderRadius: '2px', background: 'white', color: '#5CA3B6', padding: '0.25rem' }}>
                        {item_locations.map(il =>
                          <div key={il.item_location_id} style={{ clear: 'both', cursor: 'pointer' }} onClick={() => this.setState({ showRunCostMenu: false }, () => onAddItemLocationCost(item.item_id, il.item_location_id, null, true))}>
                            <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: 0 === il.index ? 0 : '0.25rem' }}>{il.index + 1}</div>
                            <div style={{ float: 'left', padding: '0.5rem', marginTop: 0 === il.index ? 0 : '0.25rem' }}>{il.item_location_title.replace(/^Location [0-9]+:: /, '')}</div>
                          </div>
                        )}
                        {!this.isPS() && <div style={{ clear: 'both', cursor: 'pointer' }} onClick={() => this.setState({ showRunCostMenu: false }, () => onAddCost(item.item_id, null, true))}>
                          <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: '0.25rem' }}>&nbsp;&nbsp;</div>
                          <div style={{ float: 'left', padding: '0.5rem', marginTop: '0.25rem' }}>Don't associate with a location</div>
                        </div>}
                      </div>}
                  </td>
                </tr> : null}
                {hasMargin ? <tr>
                  <td colSpan="2">Margin</td>
                  {_.map(item.options, i => <td key={i.item_id}
                    style={i.hidden == 1 ? hidden_style : null}>
                    <Form.TextInput {...fieldProps} disabled={locked || Number(i.exchange_rate) !== 1} selectOnFocus={true}
                      field={i.item_id + '.total_margin'} />
                  </td>)}
                </tr> : null}
                <tr>
                  <td colSpan="2">Total</td>
                  {_.map(item.options, (i, k) => <td key={k}
                    style={i.hidden == 1 ? hidden_style : null}>
                    <Form.TextInput {...fieldProps} disabled={locked || !hasMargin} selectOnFocus={true}
                      field={i.item_id + '.unit_price'} />
                  </td>)}
                </tr>
                {'COLLECTION' !== order.order_type && item.item_retail_adjustments.map(ira => <tr key={ira.item_retail_adjustment_id}>
                  <td>{!locked ?
                    <a className="delete"
                      onClick={e => {
                        e.preventDefault();
                        this.handleDeleteRetailAdjustment(ira.item_retail_adjustment_id);
                      }}>&times;</a> : null}
                  </td>
                  <td style={{ paddingLeft: 0, ...(item.hidden == 1 ? hidden_style : null) }}>
                    <input type="text" {...fieldProps} disabled={true} onClick={() => this.props.onCreateSelectSkuOrAxisOptionPopup(item.parent_id, (!!ira.axis ? 'axis' : 'sku'), (!!ira.axis ? { axis: ira.axis, option: ira.option } : { sku: ira.sku }), item.item_id, ira.item_retail_adjustment_id)}
                      value={!!ira.axis ? ira.axis : product.skus.filter(sku => sku.product_sku_id === ira.sku).map(sku => sku.sku)[0]} />
                  </td>
                  <td>
                    <Form.TextInput {...fieldProps} selectOnFocus={true} style={{ paddingLeft: '2.25rem' }}
                      prefix={retailAdjustmentPrefix}
                      field={ira.item_retail_adjustment_id + '.adjustment'} disabled={locked}
                    />
                  </td>
                  <td colSpan={item.options.length - 1}>&nbsp;</td>
                </tr>)}
                {(false && !locked && this.isPS()) && <tr>
                  <td colSpan={7} style={{ textAlign: 'center' }}>
                    <a onClick={this.handleClickAddRetailAdjustment}>+ add retail adjustment</a>
                  </td>
                </tr>}
                {'SHOP' === order.order_type && order.aggregate == 1 ?
                  <tr>
                    <td colSpan="2">Retail</td>
                    <td>
                      <Form.TextInput {...fieldProps} disabled={locked || !hasMargin} selectOnFocus={true}
                        field={item.item_id + '.retail_price'} />
                    </td>
                  </tr>
                : null}
              </tbody>
            </table>}

            {'PRESENTATION' === order.order_type ?
              <div className="checkbox-field">
                {order.hide_pricing == 0 ? <Form.Checkbox {...fieldProps} field={item.item_id + '.share_fixed_charges'} /> : null}
                <label>{order.hide_pricing == 0 ? 'Fixed Charges:' : 'Fixed Charges Hidden'}</label>
              </div> :
              'SHOP' === order.order_type && <label>Fixed Charges:</label>}
            {('COLLECTION' !== order.order_type) && <table className="pricing-table">
              <thead>
                <tr>
                  <td colSpan="2">Label</td>
                  <td>Cost</td>
                  {hasMargin ? <td>Margin</td> : null}
                  {hasMargin ? <td>Retail</td> : null}
                </tr>
              </thead>
              <tbody>
                {item.fixed_costs.map((ic, index) => <tr key={ic.item_cost_id}>
                  <td>{!locked ? <a className="delete" onClick={e => {
                    e.preventDefault();
                    this.handleDeleteCost(ic.item_cost_id);
                  }}>&times;</a> : <span>&nbsp;</span>}</td>
                  <td style={{ paddingLeft: 0 }}>
                    {indexed_item_locations[ic.item_location_id] &&
                      <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: 0 === index ? 0 : '0.25rem' }}>{indexed_item_locations[ic.item_location_id].index + 1}</div>}
                    {!!ic.ext_cost_id && <PS style={{ position: 'absolute', top: '1rem', left: '2rem' }} />}
                    <Form.TextInput {...fieldProps} selectOnFocus={true}
                      style={{
                        width: '85%',
                        paddingLeft: (!!ic.ext_cost_id ? '2rem': '0.5rem')
                      }}
                      containerStyle={{ float: 'left', paddingLeft: '0.25rem', width: 'calc(100% - 1.75rem)'}}
                      field={ic.item_cost_id + '.item_cost_title'}
                      disabled={locked || !isEditableCharge(ic)}
                      hasIcon={!(!indexed_item_locations[ic.item_location_id] || locked || !!ic.ext_cost_id || this.state.in_collection)}
                      iconRight={true}
                      IconElem={TableIcon}
                      iconStyles={{width: 30, height: 30, cursor: 'pointer', top: 'unset', marginTop: '0.3em', }}
                      containerStyles={{paddingBottom: 0}}
                      iconProps={{
                        onClick: (e) => {
                          e.preventDefault();
                          if (!this.state.in_collection && onCreateApplyMatrixToItemPopup) {
                            onCreateApplyMatrixToItemPopup({
                              isPresentation: true,
                              qty: fieldProps.value,
                              charge_type: 'fixed',
                              onAddItemCost: this.handleFieldBlur,
                              unit_cost_field: ic.item_cost_id + '.unit_cost',
                              label_field: ic.item_cost_id + '.item_cost_title',
                              unit_cost_field_postfix: '.unit_cost',
                              label_field_postfix: '.item_cost_title',
                              fixed_costs: item.fixed_costs,
                            });
                          }
                        }
                      }}
                    /></td>
                  <td><Form.TextInput {...fieldProps} selectOnFocus={true}
                    field={ic.item_cost_id + '.unit_cost'} disabled={locked}
                  /></td>
                  {hasMargin ? <td><Form.TextInput {...fieldProps} disabled={locked || Number(item.exchange_rate) !== 1} selectOnFocus={true}
                    field={ic.item_cost_id + '.total_margin'}
                  /></td> : null}
                  {hasMargin ? <td><Form.TextInput {...fieldProps} selectOnFocus={true}
                    field={ic.item_cost_id + '.unit_price'}
                  /></td> : null}
                </tr>)}
                {!locked ? <tr><td colSpan="5" style={{ textAlign: 'center' }}>

                  {showNonPSAddChargeButton && <a onClick={this.handleClickAddFixedCost}>
                    + add fixed charge
                  </a>}
                  {showPSAddChargeButton && <PresentationItemChargeMenu
                    charge_type="SETUP"
                    ps_locations={locations}
                    item_locations={item_locations}
                    item_decorations={item.item_decorations}
                    item_costs={item.fixed_costs}
                    onAdd={onAddItemLocationCost}
                    quirks={quirks}
                    division_id={item.division_id}
                  >
                    <a> <PS style={{ marginRight: 0 }} />  Add fixed charge</a>
                  </PresentationItemChargeMenu>}

                  {this.state.showFixedCostMenu &&
                    <div ref={(ref) => this.fixedCostMenu = ref} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 1, border: '1px solid #5CA3B6', borderRadius: '2px', background: 'white', color: '#5CA3B6', padding: '0.25rem' }}>
                      {item_locations.map(il =>
                        <div key={il.item_location_id} style={{ clear: 'both', cursor: 'pointer' }} onClick={() => this.setState({ showFixedCostMenu: false }, () => onAddItemLocationCost(item.item_id, il.item_location_id, 1))}>
                          <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: 0 === il.index ? 0 : '0.25rem' }}>{il.index + 1}</div>
                          <div style={{ float: 'left', padding: '0.5rem', marginTop: 0 === il.index ? 0 : '0.25rem' }}>{il.item_location_title.replace(/^Location [0-9]+:: /, '')}</div>
                        </div>
                      )}
                      {!this.isPS() && <div style={{ clear: 'both', cursor: 'pointer' }} onClick={() => this.setState({ showFixedCostMenu: false }, () => onAddCost(item.item_id, 1))}>
                        <div style={{ float: 'left', textAlign: 'center', background: '#394e59', color: 'white', fontWeight: 'bold', padding: '0.5rem', borderRadius: '3px', marginTop: '0.25rem' }}>&nbsp;&nbsp;</div>
                        <div style={{ float: 'left', padding: '0.5rem', marginTop: '0.25rem' }}>Don't associate with a location</div>
                      </div>}
                    </div>}
                </td></tr> : null}
              </tbody>
            </table>}

            <label>Price Label: </label>
            <Form.TextInput {...fieldProps}
              field={item.item_id + '.price_label'} />
            {'COLLECTION' !== order.order_type && 'SHOP' !== order.order_type ?
              <div>
                <div className="small-12 medium-6 columns" style={{ padding: '0px' }}>
                  <label>Personal Comment:
                    <button
                      style={{ float: 'right', margin: 0, padding: '0.25rem' }}
		      className="button"
		      onClick={e => {
                        e.preventDefault();
                        if (this.state.comment != '') {
                          this.handleAddComment(
                            item.item_id,
                            company_id,
                            user_name,
                            user_email,
                            this.state.comment
			  );
                        }
		      }}
		    >Post</button>
                  </label>
                  <TextArea value={this.state.comment} disabled={locked} onChange={e => this.handleChangeComment(e.target.value)}></TextArea>
                  <ul className="presentation-comments">
                    {item.comments.map(c =>
                      <li key={c.presentation_comment_id}>
                        <a className="delete presentation-comment-delete"
                          style={0 == c.rep_comment ? { visibility: 'hidden' } : {}}
                          onClick={() => onDeleteComment(c.presentation_comment_id)}>
                          &times;
                      </a>
                        <span style={{ float: 'right' }}>{parseMysqlDate(c.date_created)} &nbsp;&nbsp;</span>
                        <label>{c.commenter_name}:</label>
                        <span>{c.comment}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="small-12 medium-6 columns">
                  <label>Private Notes: </label>
                  <Form.Textarea {...fieldProps}
                    field={item.item_id + '.private_notes'} />
                </div>
              </div> : null}
          </div>
          <div className="medium-3 columns">
            {this.renderImages()}
            {
              'COLLECTION' !== order.order_type && <DecoratorSelector
                itemId={item.item_id}
                defaultIndex={item.is_3rd_party_decorator}
                isPS={item.copied_from === 'ps-products'}
                style={{ marginTop: '16px' }}
                itemLocationNum={item.item_locations.length}
              />
            }

            {item_locations.map(il => this.renderItemLocation(il))}
            {
              (locked  || 'COLLECTION' === order.order_type ) ? <div>&nbsp;</div> :
              <LocationDropdown
                locked={locked}
                isPS={this.isPS()}
                item_id={item.item_id}
                division_id={item.division_id}
                locations={availableLocations}
                popupTitle="Add artwork location"
                is3rdPartyDecorator={item.is_3rd_party_decorator}
                onOptionClick={(location) => onAddItemLocation(item.item_id, location)}
                onClickNewDecoration={() => onAddItemLocation(item.item_id)}
                onClickCopySameOrder={() => onCreateSelectDecorationProductPopup(order.form_number, order.order_type, item.item_id)}>
                <a style={{ display: 'block', padding: '1rem 0', fontWeight: 'bold' }} onClick={e => e.preventDefault()}> + Decoration Location </a>
              </LocationDropdown>
            }
            {this.renderSelectColors()}
            {this.renderSelectSizes()}
            {this.renderSelectSkus()}
          </div>
        </Form>
      </div>
    );
  }

  renderItemLocation(item_location) {
    const { order, product, locked, onEditArtwork, onUpdateItemDecoration } = this.props;
    const { item, locations } = this.state;
    const ps_location = _.find(locations, ({ ps_location_id }) => {
      return `${ps_location_id}` == item_location.ext_location_id;
    });
    const decorations = _.get(ps_location, 'decorations') ||  Object.values(product ? (product.decorations || {}) : {});
    return <PresentationItemLocation
      key={item_location.item_location_id}
      artworks={item.artworks.filter(a => a.item_location_id === item_location.item_location_id)}
      decorations={decorations}
      product={product}
      field={item_location.item_location_id + '.item_location_title'}
      item_decorations={item.item_decorations.filter(id => id.item_location_id === item_location.item_location_id)}
      item_location={item_location}
      locked={locked}
      onDelete={item_location_id => this.handleDeleteItemLocation(item_location_id)}
      onUpdateItemDecoration={onUpdateItemDecoration}
      onEditArtwork={(artwork = null) => onEditArtwork(
        item.item_id,
        item_location.item_location_id,
        artwork,
        locked,
        order.client_id,
        'CLIENT'
      )}
    />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = ownProps.order;
  const project = ownProps.project;
  const item = order.loaded ? getFullOptionItem(state, { item_id: ownProps.params.item_id }) : {};
  const product = item ? getProduct(state, { product_id: item.parent_id }) : null;
  return {
    item,
    product,
    company_id: state.identity.company_id,
    user_name: state.identity.user_first_name,
    user_email: state.identity.user_email,
    locked: 1 == order.locked,
    order_currency_id: order.currency_id,
    hasMargin: 'COLLECTION' !== order.order_type,
    show_product_warnings: !!project && project.show_product_warnings,
    quirks: state.entities.promostandards_quirks,
    canEditPS: false, // 'SUPPLIER' === state.identity.company_type
    useMarginCurrency: +state.identity.use_margin_currency === 1
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  const updateItemWarning = createUpdate('item-warning', {
    request: actions.UPDATE_ITEM_WARNING_REQUEST,
    success: actions.UPDATE_ITEM_WARNING_SUCCESS,
    failure: actions.UPDATE_ITEM_WARNING_FAILURE,
    failure_message: 'Unable to update item warning'
  });
  const updateBreakdown = createUpdate('breakdown', {
    request: actions.UPDATE_ITEM_BREAKDOWN_REQUEST,
    success: actions.UPDATE_ITEM_BREAKDOWN_SUCCESS,
    failure: actions.UPDATE_ITEM_BREAKDOWN_FAILURE,
    failure_message: 'Unable to update item'
  });
  const updateItemLocation = createUpdate('item-location', {
    request: actions.UPDATE_ITEM_LOCATION_REQUEST,
    success: actions.UPDATE_ITEM_LOCATION_SUCCESS,
    failure: actions.UPDATE_ITEM_LOCATION_FAILURE,
    failure_message: 'Unable to update item location'
  });
  const updateItemRetailAdjustment = createUpdate('item-retail-adjustment', {
    request: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST,
    success: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS,
    failure: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_FAILURE,
    failure_message: 'Unable to update retail adjustment'
  });
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    loadProduct: product_id => dispatch(createFetchProduct(product_id)),
    onAddCost: (item_id, quantity, option = false) => {
      dispatch(createAddItemCost(item_id, null, quantity, option));
    },
    onAddItemLocationCost: (item_id, item_location_id, quantity, option = false, item_cost_title = null, item_decoration_id = null, ext_cost_id = null) => {
      dispatch(createAddItemCost(item_id, item_location_id, quantity, option, 'MISCELLANEOUS', item_cost_title, item_decoration_id, ext_cost_id));
    },
    onAddItemLocation: (item_id, ext_location = null) => dispatch(createAddItemLocation(item_id, null, null, null, ext_location)),
    onUpdateItemLocation: (item_location_id, field, previous_value, value) => {
      return dispatch(updateItemLocation(item_location_id, field, previous_value, value));
    },
    onUpdateItemDecoration: (item_decoration_id, field, previous_value, value) => {
      return dispatch(createUpdateItemDecoration(item_decoration_id, field, previous_value, value));
    },
    onCreateSelectSkuOrAxisOptionPopup: (product_id, selector, value, item_id, item_retail_adjustment_id) => dispatch(createSelectSkuOrAxisOptionPopup(product_id, selector, value, item_id, item_retail_adjustment_id)),
    onAddRetailAdjustment: item_id => dispatch(createAddItemRetailAdjustment(item_id)),
    onDeleteRetailAdjustment: item_retail_adjustment_id => dispatch(createDeleteItemRetailAdjustment(item_retail_adjustment_id)),
    onUpdateRetailAdjustment: (item_retail_adjustment_id, field, previous_value, value) => dispatch(updateItemRetailAdjustment(item_retail_adjustment_id, field, previous_value, value, {}, { updateUUID: cacheUUID() })),
    onEditArtwork: (item_id, item_location_id, artwork, locked, account_id, account_type) => {
      return dispatch(createArtworkPopup({ item_id, item_location_id, ...artwork }, locked, null, account_id, account_type));
    },
    onUpdateCost: (item_cost_id, breakdown_cost, field, previous_value, value) => {
      dispatch(updateItemCost(
        item_cost_id, breakdown_cost, field, previous_value, value, {
          updateUUID: cacheUUID(),
        }
      ));
    },
    onDeleteCost: (item_cost_id, alsoUpdate) => {
      dispatch(createDeleteItemCost(item_cost_id));
      _.each(alsoUpdate, (payload) => {
        dispatch({
          type: actions.UPDATE_ITEM_REQUEST,
          payload: payload
        });
      });
    },
    onDeleteItemLocation: item_location_id => dispatch(createDeleteItemLocation(item_location_id)),
    onUpdateBreakdown: (breakdown_id, field, previous_value, value, field_lock, alsoUpdate) => {
      dispatch(updateBreakdown(breakdown_id, field, previous_value, value, { field_lock }, {
        updateUUID: cacheUUID(),
      }));
      dispatch({
        type: actions.UPDATE_ITEM_REQUEST,
        payload: alsoUpdate,
      });
    },
    onUpdateItem: (item_id, field, previous_value, field_lock = 'unit_cost', alsoUpdate = {}) => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }, {
        updateUUID: cacheUUID(),
        alsoUpdate,
      }));
    },
    onAddComment: (item_id, tenant_id, commenter_name, commenter_email, comment) => {
      dispatch(createAddComment(item_id, tenant_id, commenter_name, commenter_email, comment));
    },
    onDeleteComment: comment_id => {
      dispatch(createDeleteComment(comment_id));
    },
    onCreateSelectItemImagesPopup: (item_id, locked) => dispatch(createSelectItemImagesPopup(
      item_id, ownProps.order.client_id, 'CLIENT', locked, {autoHideOnError: ['item']}
    )),
    onCreateCheckInventorylevelPopup: (sku, api_name, item_id) => {
      dispatch(createCheckInventoryLevelPopup(sku, api_name, item_id));
    },
    onCreateColorSizeSubsetPopup: (item_id, product_id, params, sku, api_name) => {
      dispatch(createSkuAxesSubsetPopup(item_id, product_id, params, sku, api_name));
    },
    onCreateSelectSkuOptionsPopup: (item_id, product_id, params, selectTabIndex=0, sku, api_name, default_sku_id=null, isCostChanged=null) => {
      dispatch(createSelectPSSkuOptionsByColorSizePopup(
        item_id,
        product_id,
        params,
        selectTabIndex,
        sku,
        api_name,
        default_sku_id,
        product_sku_id => dispatch(updateItem(item_id, 'default_sku_id', default_sku_id, product_sku_id))
      ));
    },
    onCreateUpdateNetCostPopup: (item, _, locked) => {
      dispatch(createUpdateNetCostPopup(item, locked));
    },
    onCreateFetchProductWarnings: (item_id, product_id) => {
      dispatch(createFetchProductWarnings(item_id, product_id));
    },
    onCheckProductWarnings: (item_id, product_id) => {
      dispatch(createCheckProductWarnings(item_id, product_id));
    },
    onCreateSelectDecorationProductPopup: (order_number, order_type, item_id) => dispatch(createSelectDecorationProductPopup(order_number, order_type, item_id)),
    onAddItemWarning: () => dispatch(createAddItemWarning(ownProps.params.item_id)),
    onDeleteItemWarning: item_warning_id => dispatch(createDeleteItemWarning(item_warning_id)),
    onUpdateItemWarning: item_warning => warning => dispatch(updateItemWarning(item_warning.item_warning_id, 'warning', item_warning.warning, warning)),
    onUpdatePricingSku: (item_id, default_sku_id, product_id, isCostChanged) => () => dispatch(createSelectBreakdownSkuPopup(product_id, default_sku_id, product_sku_id => dispatch(updateItem(item_id, 'default_sku_id', default_sku_id, product_sku_id)), null, null, <span>Please select the SKU you wish to use for the displayed costs.{isCostChanged && <span><br /><i style={{ color: '#BF3F69' }} className="fi-info" />&nbsp;Updating the pricing SKU will override the base cost you have entered.</span>}</span>)),

    onCreateApplyMatrixToItemPopup: (data) => {
      return dispatch(createApplyMatrixToItemPopup({presentationItem: true, ...data}));
    },
    checkItemInCollection: (item_id) => dispatch(createCheckItemInCollection(item_id)),
  };
};

function SkuOptionTag(props) {
  const { uppercase = false } = props;
  const extraStyles = {
    ...(uppercase ? {textTransform: 'uppercase'} : {}),
  };
  return (
    <div
      style={{
        background: themeOptions.colors.neutrals[40],
        color: themeOptions.colors.neutrals[100],
        borderRadius: '25px',
        width: 'fit-content',
        padding: '5px 10px 5px 10px',
        margin: 5,
        display: 'inline-block',
        fontSize: 14,
        ...extraStyles,
      }}
    >{props.children}</div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationItem);
