import React, { Component } from 'react';

class Loading extends Component {

  render() {
    const { ...props } = this.props;
    return <img {...props} src={props.src || '/images/gears.gif'}/>;
  }
}

export default Loading;
