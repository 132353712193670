import { Loading, ChevronPopup, ProductDetail, colors } from '@commonsku/styles';
import { filter, get, map, union, uniq, sortBy, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { usePsProduct } from '../../hooks/usePSProduct';
import { getImageSrc, sizeSort } from '../../utils';

const PsProductDetails = ({ productId, handleClickProductEvent, selected, showAddButton, productHref, addToTarget, divisionHref, divisionName }) => {
  const { product, part, loading } = usePsProduct(productId);
  const parts = product ? get(product, 'parts') : [];
  const netPrices = !part ? [] : sortBy(part.net_prices, ['min_quantity', 'price']);
  const customerPrices = !part ? [] : sortBy(part.prices, ['min_quantity', 'price']);
  const images = get(product, 'media_contents') || [];
  const mainImage = get(product, 'display_image') || images[0];

  return loading ?
    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}><Loading /></div>
    :
    <ProductDetail
      name={product.product_name}
      sku={product.ps_product_id}
      descriptions={product.descriptions}
      divisionName={divisionName}
      divisionHref={divisionHref}
      sizes={filter(uniq(map(parts, 'size'))).sort((a, b) => sizeSort(a, b))}
      allColors={filter(union(...map(parts, ({ colors }) => {
        return map(colors, 'color_name');
      })))
      }
      images={images}
      mainImage={mainImage}
      selectedPart={part}
      handleClickProductEvent={handleClickProductEvent}
      selected={selected}
      showAddButton={showAddButton}
      prices={isEmpty(customerPrices) ? netPrices : customerPrices}
      costBased={part && `Cost is based on${part.size}/${part.color_name}.`}
      productHref={productHref}
      addToTarget={addToTarget}
    />;
};

const ItemDetails = ({ item, handleClickProductEvent, selected, showAddButton, divisionHref, divisionName }) => {
  const indexed_files = item.files.reduce((o, f) => ({ ...o, [f.file_id]: f }), {});
  const images = item.item_images.map(ii => ({ url: getImageSrc(indexed_files[ii.file_id], 'medium') }));
  const mainImage = images[0];
  const prices = sortBy(map(item.options, item => ({ min_quantity: item.total_units, price: item.unit_cost })), item => parseFloat(item.min_quantity));
  const buildProductUrl = () => {
    const params = {
      id: item.ext_product_id,
      source: item.copied_from,
      sku: item.item_supplier_code,
      sage_id: item.sage_id || '',
      sage_connect: item.sage_connect || '',
      currency_id: item.currency_id || ''
    };
    const query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    return `/product.php?${query}`;
  };

  return <ProductDetail
    name={item.item_name}
    sku={item.item_sku}
    descriptions={item.item_description}
    sizes={map(item.item_sizes, 'size_name').sort((a, b) => sizeSort(a, b))}
    allColors={map(item.item_colors, 'color_name')}
    images={images}
    mainImage={mainImage}
    handleClickProductEvent={handleClickProductEvent}
    selected={selected}
    showAddButton={showAddButton}
    prices={prices}
    productHref={buildProductUrl()}
    divisionName={divisionName}
    divisionHref={divisionHref}
  />;
};

const ProductChevronPopup = ({
  products, productIndex, onClose, handleLoadNextPage, isSelected, selectedProducts, isProductsPage, isCollectionPage,
  onClickProductAction, isEnd
}) => {
  const [index, setIndex] = useState(productIndex);
  const onPreviousButtonClick = (event) => {
    setIndex(index - 1);
  };
  const onNextButtonClick = (event) => {
    setIndex(index + 1);
  };
  useEffect(() => {
    if (handleLoadNextPage && index >= products.length - 3 && !isEnd) {
      handleLoadNextPage();
    }
  }, [products.length, index, handleLoadNextPage, isEnd]);
  const product = products[index];
  const selected = isSelected ? isSelected(product) : false;
  const isPS = product.copied_from == 'ps-products' || product.source == 'ps-products';
  const handleClickProductEvent = (e) => {
    e.stopPropagation();
    onClickProductAction();
    onClose();
  };

  const buildProductUrl = () => {
    const params = {
      id: product.ext_product_id || product.product_id,
      source: product.source || product.copied_from,
      sku: product.product_supplier_code,
      sage_id: product.sage_id || '',
      sage_connect: product.sage_connect || '',
      currency_id: product.currency_id || ''
    };
    const query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    return `/product.php?${query}`;
  };

  const buildSupplierUrl = () => {
    if (product.supplier_id && product.division_id) {
      return `/vendor.php?id=${product.supplier_id}&division_id=${product.division_id}`;
    }
    return undefined;
  }

  return <ChevronPopup
    hidePreviousButton={index === 0}
    hideNextButton={index === products.length - 1}
    onPreviousButtonClick={onPreviousButtonClick}
    onNextButtonClick={onNextButtonClick}
    controls={<span
      style={{ fontSize: '1.6rem', color: colors.teal[65], cursor: 'pointer', marginTop: '-10px' }}
      onClick={onClose}
    >&times;</span>}
    width='63%'
    height='87%'
  >
    {
      isPS
        ? <PsProductDetails
          productId={product.product_id || product.ext_product_id}
          divisionName={product.division_name}
          divisionHref={buildSupplierUrl()}
          handleClickProductEvent={handleClickProductEvent}
          selected={selected} showAddButton={!isProductsPage} productHref={buildProductUrl()}
          addToTarget={isCollectionPage ? 'Collection' : 'Project'}
        />
        : <ItemDetails
          item={product} handleClickProductEvent={handleClickProductEvent}
          selected={selected} showAddButton={!isProductsPage}
          divisionName={product.division_name}
          divisionHref={buildSupplierUrl()}
        />
    }
  </ChevronPopup>;
};

export default ProductChevronPopup;
