import { get, orderBy, values } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ArrowIcon, Button, Col, Csku, Row, Text } from "@commonsku/styles";
import { Pagination, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TemplateTextIconButton } from "../../helpers/TemplateButtons";

export default function ThemePreview({
  theme,
  maxHeight,
  hasNext=true,
  hasPrev=true,
  onGoBack,
  onSelectTheme,
  onClickNextTheme,
  onClickPrevTheme,
}) {
  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const descContainerRef = useRef(null);
  const [descContainerWidth, setDescContainerWidth] = useState(descContainerRef.current?.clientWidth || 100);

  const previewImages = orderBy(
    values(get(theme, ['public_view_template_properties']) || {})
      .filter(v => (
        v.public_view_template_property_name.startsWith('slider-preview-') &&
        v.public_view_template_property_type === 'IMAGE_URL' &&
        v.public_view_template_property_context === 'PREVIEW'
      )),
      ['public_view_template_property_name'],
      ['asc']
  );

  useEffect(() => {
    const elem = descContainerRef.current;
    function updateWidth() {
      setDescContainerWidth(elem?.clientWidth || 100);
    }
    const mutationObserver = new MutationObserver(updateWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    if (elem) {
      mutationObserver.observe(elem, { childList: true, subtree: true });
      resizeObserver.observe(elem);
    }
    updateWidth();
    return () => {
      mutationObserver.disconnect();
      if (elem) {
        resizeObserver.unobserve(elem);
      } else {
        resizeObserver.disconnect();
      }
    };
  }, [theme]);

  return (
    <Row className="preview-theme">
      <Csku as={Col} padded xs sm={8.5}
        style={{ background: 'var(--color-neutrals-20)', }}
        sx={{
          sm: {
            width: '100%',
            position: 'absolute',
            overflow: 'auto',
            height: '100%',
          },
        }}
      >
        <Row style={{ padding: '16px 40px', }}>
          <Col xs style={{ paddingBottom: 8 }}>
            <TemplateTextIconButton
              size="medium"
              hover_color="transparent"
              style={{ padding: '8px 0px' }}
              Icon={<ArrowIcon direction="left" />}
              onClick={onGoBack}
            >See all themes</TemplateTextIconButton>
          </Col>
          <Col xs>
            <div style={{ width: '100%' }}>
              {previewImages.length === 0 ? null : <Swiper
                modules={[Pagination]}
                centeredSlides={true}
                grabCursor={true}
                pagination={{
                  enabled: true,
                  clickable: true,
                }}
              >
                {previewImages.map(asset => (
                  <SwiperSlide key={'asset-'+asset.file_id} style={{ textAlign: 'center' }}>
                    <img style={{ maxHeight: maxHeight-105 }}
                      alt=""
                      src={asset.public_view_template_property_value}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>}
            </div>
          </Col>
        </Row>
      </Csku>
      <Csku as={Col} padded xs sm={3.5}
        ref={descContainerRef}
        style={{ background: 'var(--color-neutrals-white)', padding: '54px 24px', }}
        sx={{
          sm: {
            height: '100%',
            position: 'absolute',
            overflow: 'auto',
            width: '100%',
          },
          xs: {
            left: `calc(100% - ${descContainerWidth+14}px)`,
          },
        }}
      >
        <Text as="p" style={{
          textAlign: 'left',
          color: 'var(--color-neutrals-90)',
          fontSize: 40,
          fontFamily: 'var(--font-family-bold)',
        }}>
          {theme.title}
        </Text>
        <Text as="p" style={{
          textAlign: 'left',
          color: 'var(--color-neutrals-90)',
          fontSize: 16,
          fontFamily: 'var(--font-family-regular)',
          minHeight: 150,
        }}>
          {theme.description}
        </Text>
        <Row>
          <Col xs style={{ textAlign: 'center' }}>
            <Button variant="primary" size="medium" style={{ width: '100%' }} onClick={onSelectTheme}>Select This Theme</Button>
          </Col>
          <Col xs={6.5} style={{ textAlign: 'left', paddingTop: 8 }}>
            <Text
              style={{ cursor: 'pointer', color: 'var(--color-neutrals-70)', padding: '5px 5px 5px 0px', fontSize: 13, }}
              variant="text"
              size="medium"
              disabled={!hasPrev}
              onClick={onClickPrevTheme}
            >
              <ArrowIcon color="var(--color-neutrals-70)" direction="left" style={{ verticalAlign: 'middle' }} />
              <span style={{ paddingLeft: 3 }}>Previous Theme</span>
            </Text>
          </Col>
          <Col xs={5.5} style={{ textAlign: 'right', paddingTop: 8 }}>
            <Text
              style={{ cursor: 'pointer', color: 'var(--color-neutrals-70)', padding: '5px 0px 5px 5px', fontSize: 13, }}
              variant="text"
              size="medium"
              disabled={!hasNext}
              onClick={onClickNextTheme}
            >
              <span style={{ paddingRight: 3 }}>Next Theme</span>
              <ArrowIcon color="var(--color-neutrals-70)" direction="right" style={{ verticalAlign: 'middle' }} />
            </Text>
          </Col>
        </Row>
      </Csku>
    </Row>
  );
}
