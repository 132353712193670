import _ from "lodash";
import { combineReducers } from "redux";
import update from "immutability-helper";

import orderReducer from "./order";
import {
  itemReducer,
  itemCostReducer,
  itemLocationReducer,
  itemDecorationReducer,
  itemBreakdownReducer,
  itemTaxAmountReducer,
  commentReducer,
  skuReducer,
  sizeReducer,
  colorReducer,
  itemImageReducer,
  itemWarningReducer,
  itemRetailAdjustmentReducer,
} from "./item";
import {
  divisionReducer,
  supplierReducer,
  supplierAccountReducer,
} from "./supplier";
import {
  UPDATE_COSTS_SUCCESS,
  REORDER_TAB_SUCCESS,
  REORDER_TAB_FAILURE,
  LOAD_INDUSTRIES_SUCCESS,
  LOAD_ACCOUNT_STATUSES_SUCCESS,
  LOAD_USER_REPS_SUCCESS,
  LOAD_TAGS_SUCCESS,
  LOAD_DEPARTMENT_LIST_SUCCESS,
  ADD_TAG_STATE,
  LOAD_STATUSES_SUCCESS,
  UPDATE_COMPANY_DATA_SUCCESS,
  LOAD_FINANACE_ANALYTICS_SUCCESS,
  LOAD_MNGMNT_ANALYTICS_SUCCESS,
  LOAD_TEAMS_SUCCESS,
  FETCH_MNGMNT_ANALYTICS_SUCCESS,
  LOAD_ANALYTICS_REPORT_SUCCESS,
  UPDATE_LOADING,
  UPDATE_FINANCE_CHART_SUCCESS,
  UPDATE_ANALYTICS_REPORT_PROJECT_DATA,
  UPDATE_MNGMNT_CHART_SUCCESS,
  LOAD_EVENT_TYPES_SUCCESS,
  LOAD_ALL_STATUSES_SUCCESS,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  LOAD_TARGETS_MNGMNT_SUCCESS,
  UPDATE_TARGETS_MNGMNT_SUCCESS,
  CREATE_TARGETS_MNGMNT_SUCCESS,
  UPDATE_SHOW_TARGETS_TENANT_SUCCESS,
  LOAD_USERS_SUCCESS,
  LOAD_COMPANY_ROLES,
  LOAD_FEATURES_SUCCESS,
} from "../actions";
import {
  LOAD_DIVISION_SUCCESS,
  LOAD_DIVISION_ADDRESSES_SUCCESS,
  LOAD_DIVISION_CONTACTS_SUCCESS,
  ADD_SUPPLIER_SUCCESS,
  LOAD_SUPPLIER_SUCCESS,
  LOAD_ACCOUNT_CONTACTS_SUCCESS,
} from "../actions/supplier";
import {
  LOAD_ORDER_LIST_SUCCESS,
  DELETE_ORDER_SUCCESS,
  UPDATE_ORDER_TAX_ID_SUCESS,
  ADD_ACCOUNT_SUCCESS,
  UPDATE_ORDER_SUCCESS,
} from "../actions/order";
import { UPLOAD_FILE_SUCCESS } from "../actions/file";
import { ADD_PRODUCT_SUCCESS, ADD_ORDER_SUCCESS } from "../actions/project";
import { ADD_EMAIL_TEMPLATE_SUCCESS } from "../actions/email";
import { ADD_BILL_SUCCESS, UPDATE_BILL_SUCCESS } from "../actions/bill";
import {
  CREATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS,
} from "../actions/address";
import {
  ADD_CONTACT_PHONE_SUCCESS,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT_PHONE_SUCCESS,
  DELETE_CONTACT_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
} from "../actions/contact";
import {
  ADD_ITEM_SUCCESS,
  UPDATE_ITEM_TAX_AMOUNT_SUCCESS,
  UPDATE_ITEM_DECORATION_SUCCESS,
} from "../actions/item";
import {
  ADD_COLLECTION_THEME_SUCCESS,
  DELETE_COLLECTION_THEME_SUCCESS,
} from "../actions/collection";
import {
  ADD_CLIENT_SUCCESS,
  LOAD_CLIENT_LIST_SUCCESS,
  UPDATE_CLIENT_PHONE_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from "../actions/client";
import { LOAD_SALES_ANALYTICS_SUCCESS } from "../actions/index";
import { LOAD_COMPANY_CONTACT_LIST_SUCCESS } from "../actions/contact";
import { LOAD_ADDRESS_LIST_SUCCESS } from "../actions/address";
import { ADD_TAX_SUCCESS } from "../actions/tax";
import { FETCH_PRODUCT_DETAIL_SUCCESS } from "../actions/product";
import {
  ADD_ARTWORK_SUCCESS,
  UPDATE_ARTWORK_SUCCESS,
} from "../actions/artwork";
import {
  LOAD_SHIP_METHODS_SUCCESS,
  ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
  UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
  DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
} from "../actions/shipping";
import {
  LOAD_TAXES_LIST_SUCCESS,
  LOAD_TERMS_LIST_SUCCESS,
} from "../actions/company_search";
import {
  LOAD_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_SUCCESS,
} from "../actions/inventory";
import { CALCULATE_AVALARA_TAX } from "../actions/tax";

import fileReducer from "./file";
import artworkReducer from "./artwork";
import messageReducer from "./message";
import projectReducer from "./project";
import purchaseOrderReducer from "./purchase_order";
import purchaseOrderProofReducer from "./purchase_order_proof";
import depositInvoiceReducer from "./deposit_invoice";
import commissionReducer from "./commission";
import displayReducer from "./display";
import tempReducer from "./temp";
import shippingReducer from "./shipping";
import { proofCommentReducer } from "./comment";
import billReducer from "./bill";
import { collaborateMessageReducer } from "./collaborate_message";
import companyContactReducer from "./company_contact";
import {
  bookmarksReducer,
  notificationsReducer,
  recentOrdersReducer,
  remindersReducer,
  notificationCountReducer,
} from "./header";
import shopReducer from "./shop";
import {
  decoratorReducer,
  decorationsReducer,
  decorationUnitsReducer,
  decorationChargesReducer,
  decorationChargeItemReducer,
} from "./admin_decoration_charges";
import company_search_reducer from "./company_search";
import discountReducer from "./discount";
import heroImageReducer from "../redux/heroImage";
import botsReducer from "../redux/chatBots";
import clientPortalsReducer from "../redux/clientPortals";
import foldersReducer from "../redux/folders";
import psstAddressesReducer from "../redux/psstAddresses";
import {
  inventoryReducer,
  warehouseReducer,
  inventoryItemReducer,
} from "./inventory";
import { avalaraCategoriesReducer } from "./avalara_categories";
import { formatTargetMoney, month_names } from "../containers/AnalyticsHelper";
import { window } from "../global";
import {
  ADD_THEME_SUCCESS,
  DELETE_THEME_SUCCESS,
  UPDATE_THEME_SUCCESS,
  LOAD_THEMES_SUCCESS,
} from "../actions/theme";
import isLoadingReducer from "../redux/isLoading";
import { added_to_orders } from "../store/configureShopStore";

if (typeof window.initialState === "undefined") {
  var initialState = {};
}
const defaultDropdownState =
  initialState && initialState.dropdowns ? initialState.dropdowns : {};

const updateTaxes = (state, payload) => {
  const tax_id = _.get(payload, "taxes.0.tax_id");
  if (!_.isEmpty(tax_id) && !state.taxes.includes(tax_id)) {
    return { ...state, taxes: state.taxes.concat(tax_id) };
  }
  return state;
};

const dropdowns = (state = defaultDropdownState, action) => {
  switch (action.type) {
    case LOAD_DIVISION_SUCCESS:
      const divisions = action.payload.divisions.map((d) => d.division_id);
      if (action.payload.type === "tagged-product") {
        action.payload.type = "tagged";
      }
      return Object.assign({}, state, {
        divisions: Object.assign({}, state.divisions, {
          [action.payload.type]: divisions,
        }),
      });
    case LOAD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, {
        suppliers: [
          ...new Set(
            (state.suppliers || []).concat(
              action.payload.suppliers.map((s) => s.supplier_id)
            )
          ),
        ],
      });
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [
          ...new Set(
            (state.clients || []).concat(action.payload.client.client_id)
          ),
        ],
      };
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clients: [
          ...new Set(
            (state.clients || []).concat(
              action.payload.clients.map((c) => c.client_id)
            )
          ),
        ],
      };
    case LOAD_THEMES_SUCCESS:
      return Object.assign({}, state, {
        themes: action.payload.themes.map((t) => t.theme_id),
      });
    case LOAD_DIVISION_ADDRESSES_SUCCESS:
      const addresses = action.payload.addresses.map((a) => a.address_id);
      return Object.assign({}, state, {
        addresses: Object.assign({}, state.addresses, {
          [action.payload.division_id]: addresses,
        }),
      });
    case LOAD_DIVISION_CONTACTS_SUCCESS:
      return {
        ...state,
        division_contacts: action.payload.contacts.map((c) => c.contact_id),
      };
    case LOAD_ACCOUNT_CONTACTS_SUCCESS:
      return {
        ...state,
        division_contacts: action.payload.contacts.map((c) => c.contact_id),
      };
    case LOAD_ORDER_LIST_SUCCESS:
      const orders = action.payload.orders.map((o) => o.order_id);
      return Object.assign({}, state, {
        orders: Object.assign({}, state.orders, {
          [action.payload.client_id]: orders,
        }),
      });
    case UPLOAD_FILE_SUCCESS:
      if (action.payload.data.parent_type === "TENANT-HEADER") {
        return Object.assign({}, state, {
          headers: state.headers.concat(action.payload.id),
        });
      }

      return state;
    case ADD_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        divisions: Object.assign({}, state.divisions, {
          personal: state.divisions.personal.concat(
            action.payload.product.division_id
          ),
        }),
      });
    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        email_templates: state.email_templates.concat(action.payload.id),
      });
    case CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addresses: Object.assign({}, state.addresses, {
          [action.payload.address.parent_id]: (
            state.addresses[action.payload.address.parent_id] || []
          ).concat(action.payload.address.address_id),
        }),
      });
    case DELETE_ADDRESS_SUCCESS:
      if (!('parent_id' in action.payload.address)) {
        return state;
      }

      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.payload.address.parent_id]: (
            state.addresses[action.payload.address.parent_id] || []
          ).filter((c) => c !== action.payload.address.address_id),
        },
      };
    case LOAD_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.payload.company_id]: action.payload.addresses.map(
            (a) => a.address_id
          ),
        },
      };
    case LOAD_COMPANY_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.company_id]: action.payload.contacts.map(
            (c) => c.contact_id
          ),
        },
      };
    case CREATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        contacts: Object.assign({}, state.contacts, {
          [action.payload.contact.company_id]: (
            state.contacts[action.payload.contact.company_id] || []
          ).concat(action.payload.contact.contact_id),
        }),
      });
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.contact.company_id]: state.contacts[
            action.payload.contact.company_id
          ].filter((c) => c !== action.payload.contact.contact_id),
        },
      };
    case ADD_SUPPLIER_SUCCESS:
      return Object.assign({}, state, {
        divisions: Object.assign({}, state.divisions, {
          personal: (state.divisions?.personal ?? []).includes(
            action.payload.supplier.division_id
          )
            ? state.divisions.personal
            : (state.divisions?.personal ?? []).concat(
                action.payload.supplier.division_id
              ),
        }),
        suppliers: (state.suppliers ?? []).includes(action.payload.supplier.supplier_id)
          ? state.suppliers
          : (state.suppliers ?? []).concat(action.payload.supplier.supplier_id),
        supplier_accounts: (state.supplier_accounts ?? []).includes(
          action.payload.supplier.account_id
        )
          ? state.supplier_accounts
          : (state.supplier_accounts ?? []).concat(action.payload.supplier.account_id),
      });
    case ADD_ITEM_SUCCESS:
      return Object.assign({}, state, {
        product_colors: Object.assign({}, state.product_colors, {
          [action.payload.item.parent_id]: (
            state.product_colors[action.payload.item.parent_id] || []
          ).concat(
            (action.payload.item.colors || [])
              .filter((c) => c.color_id !== "TBD")
              .map((c) => c.color_id)
          ),
        }),
        product_sizes: Object.assign({}, state.product_sizes, {
          [action.payload.item.parent_id]: (
            state.product_sizes[action.payload.item.parent_id] || []
          ).concat(
            (action.payload.item.sizes || [])
              .filter((c) => c.size_id !== "TBD")
              .map((c) => c.size_id)
          ),
        }),
        product_skus: {
          ...state.product_skus,
          [action.payload.item.parent_id]: Array.from(
            new Set(
              (state.product_skus[action.payload.item.parent_id] || []).concat(
                (action.payload.item.skus || []).map((s) => s.product_sku_id)
              )
            )
          ),
        },
      });
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product_skus: {
          ...state.product_skus,
          [action.payload.product_id]: Array.from(
            new Set(
              (state.product_skus[action.payload.product_id] || []).concat(
                (action.payload.product.skus || []).map((s) => s.product_sku_id)
              )
            )
          ),
        },
      };
    case UPDATE_ORDER_TAX_ID_SUCESS:
    case ADD_ORDER_SUCCESS:
      return updateTaxes(state, action.payload.data);
    case UPDATE_ITEM_TAX_AMOUNT_SUCCESS:
      return updateTaxes(state, action.payload);
    case ADD_TAX_SUCCESS:
      if (
        _.get(action.payload, "tax.tax_id") &&
        !state.taxes.includes(action.payload.tax.tax_id)
      ) {
        return {
          ...state,
          taxes: state.taxes.concat(action.payload.tax.tax_id),
        };
      }
      return state;
    case ADD_ARTWORK_SUCCESS:
      if (!state.imprints.includes(action.payload.data.imprint.imprint_id)) {
        return {
          ...state,
          imprints: state.imprints.concat(
            action.payload.data.imprint.imprint_id
          ),
        };
      }
      return state;
    case UPDATE_ARTWORK_SUCCESS:
      if (!state.imprints.includes(action.payload.data.imprint.imprint_id)) {
        return {
          ...state,
          imprints: state.imprints.concat(
            action.payload.data.imprint.imprint_id
          ),
        };
      }
      return state;
    case UPDATE_ITEM_DECORATION_SUCCESS:
      if (!state.imprints.includes(action.payload.imprint.imprint_id)) {
        return {
          ...state,
          imprints: state.imprints.concat(action.payload.imprint.imprint_id),
        };
      }
      return state;
    case LOAD_SHIP_METHODS_SUCCESS:
      return {
        ...state,
        ship_methods: {
          ...state.ship_methods,
          ...action.payload.ship_methods.reduce(
            (o, sm) => ({
              ...o,
              [sm.tenant_id]: (o[sm.tenant_id] || []).concat(sm.ship_method_id),
            }),
            {}
          ),
        },
      };
    case ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      return {
        ...state,
        third_party_shipping_accounts:
        (state.third_party_shipping_accounts ?? []).concat(
          action.payload.third_party_shipping_account.third_party_account_id
        )
      };
    case DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      return update(state, {
        third_party_shipping_accounts: {
          $set: (state.third_party_shipping_accounts || []).filter((tpa) => {
            return tpa !== action.payload.third_party_shipping_account_id;
          }),
        },
      });
    case LOAD_ACCOUNT_STATUSES_SUCCESS:
      return {
        ...state,
        account_statuses: action.payload.account_statuses,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.user.user_id]: action.payload.user,
        },
      };
    case LOAD_USER_REPS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
      };
    case LOAD_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload.teams,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        tags: Array.from(
          new Set(
            (state.tags || []).concat(
              action.payload.client.tags.map((t) => t.tag_id)
            )
          )
        ),
      };
    case LOAD_TAGS_SUCCESS:
      return {
        ...state,
        tags: Array.from(
          new Set((state.tags || []).concat(action.payload.dropdowns))
        ),
      };
    case ADD_TAG_STATE:
      return {
        ...state,
        tags: (state.tags || []).concat(action.payload.tag.tag_id),
      };
    case LOAD_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: action.payload.industries,
      };
    case LOAD_TAXES_LIST_SUCCESS:
      return {
        ...state,
        taxes: action.payload.taxes,
      };
    case LOAD_TERMS_LIST_SUCCESS:
      return {
        ...state,
        terms: action.payload.terms,
      };
    case LOAD_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        departments: action.payload.departments,
      };
    case LOAD_INVENTORY_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.inventory.inventory_id]:
            action.payload.inventory.contacts.map((c) => c.contact_id),
        },
      };
    case UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.inventory.inventory_id]:
            action.payload.inventory.contacts.map((c) => c.contact_id),
        },
      };
    case LOAD_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: action.payload.statuses,
      };
    case LOAD_ALL_STATUSES_SUCCESS:
      const parent_types = action.payload.statuses.map((v) => v.parent_type);
      return {
        ...state,
        statuses: parent_types.reduce((acc, k) => {
          return {
            ...acc,
            [k]: action.payload.statuses
              .filter((v) => v.parent_type === k)
              .map((v) => v.status_id),
          };
        }, {}),
      };
    case LOAD_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        event_types: action.payload.event_types.map((v) => v.event_type_id),
      };
  }

  const new_state = {
    ...state,
    decorators: decoratorReducer(
      state.decorators ? state.decorators : [],
      action
    ),
    decorations: decorationsReducer(
      state.decorations ? state.decorations : [],
      action
    ),
    decoration_units: decorationUnitsReducer(
      state.decoration_units ? state.decoration_units : [],
      action
    ),
  };

  return new_state;
};

const taxReducer = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_ORDER_TAX_ID_SUCESS:
    case ADD_ORDER_SUCCESS:
      if (payload.data.taxes && _.get(payload, "data.taxes.0.tax_id")) {
        return {
          ...state,
          [payload.data.taxes[0].tax_id]: payload.data.taxes[0],
        };
      }
      return state;
    case UPDATE_ITEM_TAX_AMOUNT_SUCCESS:
      if (payload.taxes && _.get(payload, "taxes.0.tax_id")) {
        return { ...state, [payload.taxes[0].tax_id]: payload.taxes[0] };
      }
      return state;
    case ADD_TAX_SUCCESS:
      return { ...state, [payload.tax.tax_id]: payload.tax };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        ..._.keyBy(
          _.flatMap(_.get(payload.order, "items"), ({ tax_amounts }) => {
            return _.map(tax_amounts, (ta) => {
              return _.pick(ta, [
                "tax_id",
                "label",
                "description",
                "percent",
                "editable",
                "zip2tax_id",
              ]);
            });
          }),
          "tax_id"
        ),
      };
    case CALCULATE_AVALARA_TAX:
      return {
        ...state,
        ..._.keyBy(
          _.flatMap(_.get(payload.order, "items"), ({ tax_amounts }) => {
            return _.map(tax_amounts, (ta) => {
              return _.pick(ta, [
                "tax_id",
                "label",
                "description",
                "percent",
                "editable",
                "zip2tax_id",
              ]);
            });
          }),
          "tax_id"
        ),
      };
    case LOAD_TAXES_LIST_SUCCESS:
      return {
        ...state,
        ..._.keyBy(payload.taxes, "tax_id"),
      };
  }
  return state;
};

export const termsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_TERMS_LIST_SUCCESS:
      return {
        ...state,
        ..._.keyBy(action.payload.terms, "terms_id"),
      };
  }
  return state;
}

export const addressReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.addresses.reduce(
          (o, a) => ({ ...o, [a.address_id]: a }),
          {}
        ),
      };
    case LOAD_DIVISION_ADDRESSES_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload.addresses.reduce((t, a) => {
          t[a.address_id] = a;
          return t;
        }, {})
      );
    case CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.address.address_id]: action.payload.address,
      });
    case UPDATE_ADDRESS_SUCCESS:
      const address_id = action.payload.id;
      const updated_address = Object.assign(
        {},
        state[address_id],
        action.payload.data
      );
      return Object.assign({}, state, { [address_id]: updated_address });
    case DELETE_ADDRESS_SUCCESS:
      return Object.values(state)
        .filter((a) => a.address_id !== action.payload.address.address_id || 'active' in a)
        .reduce((o, a) => {
          if (a.address_id === action.payload.address.address_id) {
            a.active = 0;
          }
          o[a.address_id] = a;
          return o;
        }, {});
  }
  return state;
};

export const departmentReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.departments.reduce(
          (o, d) => ({ ...o, [d.department_id]: d }),
          {}
        ),
      };
  }
  return state;
};

export const contactReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_COMPANY_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.contacts.reduce((o, c) => {
          o[c.contact_id] = c;
          return o;
        }, {}),
      };
    case LOAD_DIVISION_CONTACTS_SUCCESS:
      return {
        ...state,
        ...action.payload.contacts.reduce(
          (t, c) => ({ ...t, [c.contact_id]: c }),
          {}
        ),
      };
    case LOAD_ACCOUNT_CONTACTS_SUCCESS:
      return {
        ...state,
        ...action.payload.contacts.reduce(
          (t, c) => ({ ...t, [c.contact_id]: c }),
          {}
        ),
      };
    case CREATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.contact.contact_id]: action.payload.contact,
      });
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.data
        },
      };
    case DELETE_CONTACT_SUCCESS:
      return state; // contacts are indexed poorly, but there is no harm keeping them around
    case LOAD_INVENTORY_SUCCESS:
      return {
        ...state,
        ...action.payload.inventory.contacts.reduce(
          (o, c) => ({ ...o, [c.contact_id]: c }),
          {}
        ),
      };
    case UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        ...action.payload.inventory.contacts.reduce(
          (o, c) => ({ ...o, [c.contact_id]: c }),
          {}
        ),
      };
    case ADD_CONTACT_PHONE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          phones: [
            ...new Set(
              (state[action.payload.id].phones || []).concat(
                action.payload.data.phones.map((p) => p.phone_id)
              )
            ),
          ]
        },
      };
    case DELETE_CONTACT_PHONE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          phones: [
            ...new Set(
              (state[action.payload.id].phones || []).filter(
                (p) => p !== action.payload.data.phone_id
              )
            ),
          ],
        },
      };
  }
  return state;
};

const thirdPartyShippingAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      return {
        ...state,
        [action.payload.third_party_shipping_account.third_party_account_id]:
          action.payload.third_party_shipping_account,
      };
    case UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      return {
        ...state,
        [action.payload.third_party_shipping_account.third_party_account_id]:
          action.payload.third_party_shipping_account,
      };
    case DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS:
      return update(state, { $unset: [action.payload.third_party_account_id] });
  }
  return state;
};

const shipMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_SHIP_METHODS_SUCCESS:
      return {
        ...state,
        ...action.payload.ship_methods.reduce(
          (o, sm) => ({
            ...o,
            [sm.ship_method_id]: sm,
          }),
          {}
        ),
      };
  }
  return state;
};

const phoneReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONTACT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload.contact.phones.reduce((o, p) => {
          o[p.phone_id] = p;
          return o;
        }, {})
      );
    case ADD_CONTACT_PHONE_SUCCESS:
      return {
        ...state,
        ...action.payload.data.phones.reduce((o, p) => {
          o[p.phone_id] = p;
          return o;
        }, {}),
      };
    case UPDATE_CLIENT_PHONE_SUCCESS:
      if (action.payload.phone.active === 0) {
        return Object.keys(state)
          .filter((k) => k !== action.payload.phone.phone_id)
          .reduce((o, k) => {
            o[k] = state[k];
            return o;
          }, {});
      };

      return {
        ...state,
        [action.payload.phone.phone_id]: action.payload.phone,
      };
  }
  return state;
};

const emailTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload.data,
      });
  }
  return state;
};

const billPurchaseOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ORDER_SUCCESS:
      return Object.keys(state)
        .filter((k) => k !== action.payload.data.order_id)
        .reduce((o, k) => {
          o[k] = state[k];
          return o;
        }, {});
    case ADD_BILL_SUCCESS:
      const new_state = action.payload.purchase_orders.reduce((o, po) => {
        o[po.order_id] = (o[po.order_id] || []).concat({
          bill_id: action.payload.bill.bill_id,
          purchase_order_id: po.purchase_order_id,
          order_id: po.order_id,
        });
        return o;
      }, Object.assign({}, state));
      if (!action.payload.purchase_orders.length) {
        return Object.assign(
          {},
          {
            [action.payload.order_id]: (
              state[action.payload.order_id] || []
            ).concat({
              bill_id: action.payload.bill.bill_id,
              purchase_order_id: null,
              order_id: action.payload.order_id,
            }),
          }
        );
      }
      return new_state;
    case UPDATE_BILL_SUCCESS:
  }
  return state;
};

const collectionThemeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_COLLECTION_THEME_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload.data,
      });
    case DELETE_COLLECTION_THEME_SUCCESS:
      return Object.keys(state)
        .filter((k) => k !== action.payload.id)
        .reduce((o, k) => {
          o[k] = state[k];
          return o;
        }, {});
  }
  return state;
};

const themeReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_THEMES_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload.themes.reduce((o, t) => {
          o[t.theme_id] = t;
          return o;
        }, {})
      );
    case ADD_THEME_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload.data,
      });
    case UPDATE_THEME_SUCCESS:
      const theme_id = action.payload.id;
      const updated_theme = Object.assign(
        {},
        state[theme_id],
        action.payload.data
      );
      return Object.assign({}, state, { [theme_id]: updated_theme });
    case DELETE_THEME_SUCCESS:
      return Object.keys(state)
        .filter((k) => k !== action.payload.id)
        .reduce((o, k) => {
          o[k] = state[k];
          return o;
        }, {});
  }
  return state;
};

const tenantReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ACCOUNT_SUCCESS:
      if ("DISTRIBUTOR" === action.payload.account.company_type) {
        return Object.assign({}, state, {
          [action.payload.account.company_id]: Object.assign(
            {},
            state[action.payload.account.company_id],
            {
              tenant_account_id: action.payload.account.account_id,
            }
          ),
        });
      }
      break;
  }
  return state;
};

const imprintReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ARTWORK_SUCCESS:
      return {
        ...state,
        [action.payload.data.imprint.imprint_id]: action.payload.data.imprint,
      };
    case UPDATE_ARTWORK_SUCCESS:
      return {
        ...state,
        [action.payload.data.imprint.imprint_id]: action.payload.data.imprint,
      };
    case UPDATE_ITEM_DECORATION_SUCCESS:
      return {
        ...state,
        [action.payload.imprint.imprint_id]: action.payload.imprint,
      };
  }
  return state;
};

const baseCostsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COSTS_SUCCESS:
      return { ...state, [action.payload.id]: action.payload.data };
  }
  return state;
};

const baseCommentReducer = (comments, action) => {
  return [commentReducer, proofCommentReducer].reduce(
    (accumulator, reducer) => {
      return reducer(accumulator, action);
    },
    comments
  );
};

const salesAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_SALES_ANALYTICS_SUCCESS:
      return action.payload.json;
  }
  return state;
};

const finanaceAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FINANACE_ANALYTICS_SUCCESS:
      return action.payload.json;
    case UPDATE_FINANCE_CHART_SUCCESS:
      return {
        ...state,
        [action.payload.state_placeholder]: action.payload.data,
      };
  }
  return state;
};

const managementAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_MNGMNT_ANALYTICS_SUCCESS:
      return action.payload.json;
    case FETCH_MNGMNT_ANALYTICS_SUCCESS:
      return action.payload.json;
    case UPDATE_MNGMNT_CHART_SUCCESS:
      return { ...state, ...action.payload.data };
  }
  return state;
};

export const targetsManagementReducer = (state = [], action) => {
  switch (action.type) {
    case LOAD_TARGETS_MNGMNT_SUCCESS: {
      return action.payload.json.map((x) => ({
        ...x,
        invoice_target: formatTargetMoney(x.invoice_target),
        sales_orders_target: formatTargetMoney(x.sales_orders_target),
      }));
    }
    case UPDATE_TARGETS_MNGMNT_SUCCESS: {
      const target = action.payload.sales_target;
      return state.map((x) =>
        x.target_id === action.payload.id
          ? {
              ...x,
              invoice_target: formatTargetMoney(target.sales_target_value),
              sales_orders_target: formatTargetMoney(
                target.in_production_target_value
              ),
            }
          : x
      );
    }
    case CREATE_TARGETS_MNGMNT_SUCCESS: {
      const newTarget = action.payload.json.sales_target;
      var date = new Date(newTarget.sales_target_time);
      return state.map((x) =>
        x.month === month_names[date.getMonth()]
          ? {
              ...x,
              invoice_target: formatTargetMoney(newTarget.sales_target_value),
              sales_orders_target: formatTargetMoney(
                newTarget.in_production_target_value
              ),
              target_id: newTarget.sales_target_id,
            }
          : x
      );
    }
  }
  return state;
};

const analyticsChartReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ANALYTICS_REPORT_SUCCESS:
      return {
        [action.payload.report_type]: action.payload.data,
      };
    case UPDATE_ANALYTICS_REPORT_PROJECT_DATA:
      const report_type = action.payload.report_type;
      const projects = state[report_type].data;
      const foundIdx =
        !isNaN(action.index) && action.payload.index > -1
          ? action.index
          : _.findIndex(projects, (v) => v.job_id === action.payload.job_id);
      if (foundIdx === -1) {
        return state;
      }
      const found = state[report_type].data[foundIdx];
      return {
        ...state,
        [action.payload.report_type]: {
          ...state[report_type],
          data: state[report_type].data.map((dataitem) =>
            dataitem.order_id === found.order_id
              ? {
                  ...dataitem,
                  ...action.payload.data,
                  latest_order: action.payload.data.latest_order,
                }
              : dataitem
          ),
        },
      };
  }
  return state;
};

export const analyticsLoadingReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOADING:
      return {
        [action.payload.report_type]: action.payload.loading,
      };
  }
  return state;
};
export const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        [action.payload.client.client_id]: action.payload.client,
      };
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.clients.reduce((o, c) => {
          o[c.client_id] = c;
          return o;
        }, {}),
      };
    case UPDATE_CLIENT_SUCCESS:
      const updatedClient = {
        ...action.payload.client,
        profile: action.payload.client.profile ?? action.payload.client.client_profile,
      };
      return {
        ...state,
        [updatedClient.client_id]: {
          ...state[updatedClient.client_id],
          ...updatedClient,
          tags: (action.payload.client.tags ?? []).map(t => t.tag_id)
        },
      };
    case UPDATE_CLIENT_PHONE_SUCCESS:
      if (
        !(action.payload.phone.parent_id in state) ||
        !('phones' in state[action.payload.phone.parent_id])
      ) {
        return state;
      }

      return {
        ...state,
        [action.payload.phone.parent_id]: {
          ...state[action.payload.phone.parent_id],
          phones: [
            ...state[action.payload.phone.parent_id].phones.filter(
              (p) => p.phone_id !== action.payload.phone.phone_id
            ),
            action.payload.phone,
          ],
        },
      };
  }
  return state;
};

const productSkuReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.payload.product_id]: action.payload.product.skus,
      };
  }
  return state;
};

const productReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.payload.product_id]: action.payload.product,
      };
  }
  return state;
};

export const tagReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        ...action.payload.client.tags.reduce(
          (o, t) => ({
            ...o,
            [t.tag_id]: t,
          }),
          {}
        ),
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        ...(action.payload.tags ?? []).reduce(
          (o, t) => ({
            ...o,
            [t.tag_id]: t,
          }),
          {}
        ),
      };
    case LOAD_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload.tags,
      };
    case ADD_TAG_STATE:
      return {
        ...state,
        [action.payload.tag.tag_id]: action.payload.tag,
      };
  }
  return state;
};

const taggedResourceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        ...action.payload.client.tags.reduce(
          (o, t) => ({
            ...o,
            [t.tagged_resource_id]: t,
          }),
          {}
        ),
      };
    case LOAD_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload.tagged_resources,
      };
    case ADD_TAG_STATE:
      return {
        ...state,
        [action.payload.tag.tagged_resource_id]: action.payload.tag,
      };
  }
  return state;
};

const defaultEntitiesState =
  initialState && initialState.entities ? initialState.entities : {};

const companyDataReducer = (state = {}, action) => {
  const { type, payload } = action;
  const company_data = _.get(payload, "company_data") || {};

  switch (type) {
    case UPDATE_COMPANY_DATA_SUCCESS:
      return !company_data.company_id
        ? state
        : update(state, {
            [company_data.company_id]: { $set: company_data },
          });
  }
  return state;
};

export const eventTypeReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        ...payload.event_types.reduce(
          (acc, v) => ({ ...acc, [v.event_type_id]: v }),
          {}
        ),
      };
  }

  return state;
};

export const industryReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INDUSTRIES_SUCCESS:
      return {
        ...state,
        ...payload.industries.reduce(
          (acc, v) => ({ ...acc, [v.industry_id]: v }),
          {}
        ),
      };
  }

  return state;
};

const statusReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ALL_STATUSES_SUCCESS:
      return {
        ...state,
        ...payload.statuses.reduce(
          (acc, v) => ({ ...acc, [v.status_id]: v }),
          {}
        ),
      };
  }

  return state;
};

const accountStatusReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ACCOUNT_STATUSES_SUCCESS:
      return {
        ...state,
        ...payload.account_statuses.reduce(
          (acc, status) => ({ ...acc, [status.account_status_id]: status }),
          {}
        ),
      };
  }

  return state;
};

const userReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_USER_SUCCESS:
      return {
        ...state,
        [payload.user.user_id]: payload.user,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        [payload.user.user_id]: {
          ...(state[payload.user.user_id] || {}),
          ...payload.user,
        },
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        ...payload.users.reduce((acc, v) => ({ ...acc, [v.user_id]: v }), {}),
      };
  }

  return state;
};

const roleReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_COMPANY_ROLES:
      return {
        ...state,
        ...payload.roles.reduce((acc, v) => ({ ...acc, [v.role_id]: v }), {}),
      };
  }

  return state;
};

const featureReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FEATURES_SUCCESS:
      return {
        ...state,
        ...payload.features.reduce(
          (acc, v) => ({ ...acc, [v.feature_id]: v }),
          {}
        ),
      };
  }

  return state;
};

export const entitiesReducer = (state = defaultEntitiesState, action) => {
  const new_state = Object.assign({}, state, {
    addresses: addressReducer(state.addresses, action),
    artworks: artworkReducer(state.artworks, action),
    avalara_categories: avalaraCategoriesReducer(
      state.avalara_categories,
      action
    ),
    base_costs: baseCostsReducer(state.costs, action),
    bills: billReducer(state.bills, action),
    bill_purchase_orders: billPurchaseOrderReducer(
      state.bill_purchase_orders,
      action
    ),
    breakdowns: itemBreakdownReducer(state.breakdowns, action),
    bookmarks: bookmarksReducer(state.bookmarks, action),
    clients: clientReducer(state.clients, action),
    collaborate_messages: collaborateMessageReducer(
      state.collaborate_messages,
      action
    ),
    collection_themes: collectionThemeReducer(state.collection_themes, action),
    colors: colorReducer(state.colors, action),
    comments: baseCommentReducer(state.comments, action),
    commissions: commissionReducer(state.commissions, action),
    company_contacts: companyContactReducer(state.company_contacts, action),
    company_data: companyDataReducer(state.company_data, action),
    contacts: contactReducer(state.contacts, action),
    deposit_invoices: depositInvoiceReducer(state.deposit_invoices, action),
    discounts: discountReducer(state.discounts, action),
    divisions: divisionReducer(state.divisions, action),
    email_templates: emailTemplateReducer(state.email_templates, action),
    files: fileReducer(state.files, action),
    imprints: imprintReducer(state.imprints, action),
    inventory: inventoryReducer(state.inventory || {}, action),
    inventory_items: inventoryItemReducer(state.inventory_items || {}, action),
    inventory_warehouses: warehouseReducer(
      state.inventory_warehouses || {},
      action
    ),
    items: itemReducer(state.items, action),
    item_costs: itemCostReducer(state.item_costs, action),
    item_decorations: itemDecorationReducer(state.item_decorations, action),
    item_images: itemImageReducer(state.item_images, action),
    item_warnings: itemWarningReducer(state.item_warnings, action),
    item_locations: itemLocationReducer(state.item_locations, action),
    item_retail_adjustments: itemRetailAdjustmentReducer(
      state.item_retail_adjustments,
      action
    ),
    messages: messageReducer(state.messages, action),
    notifications: notificationsReducer(state.notifications, action),
    notification_count: notificationCountReducer(
      state.notification_count,
      action
    ),
    orders: orderReducer(state.orders, action),
    phones: phoneReducer(state.phones, action),
    products: productReducer(state.products, action),
    product_skus: productSkuReducer(state.product_skus, action),
    projects: projectReducer(state.projects, action),
    purchase_orders: purchaseOrderReducer(state.purchase_orders, action),
    purchase_order_proofs: purchaseOrderProofReducer(
      state.purchase_order_proofs,
      action
    ),
    psst_addresses: psstAddressesReducer(state.psst_addresses, action),
    recent_orders: recentOrdersReducer(state.recent_orders, action),
    reminders: remindersReducer(state.reminders, action),
    ship_methods: shipMethodReducer(state.ship_methods, action),
    shipping: shippingReducer(state.shipping, action),
    shops: shopReducer(state.shops, action),
    sizes: sizeReducer(state.sizes, action),
    skus: skuReducer(state.skus, action),
    supplier_accounts: supplierAccountReducer(state.supplier_accounts, action),
    suppliers: supplierReducer(state.suppliers, action),
    tagged_resources: taggedResourceReducer(state.tagged_resources, action),
    tags: tagReducer(state.tags, action),
    taxes: taxReducer(state.taxes, action),
    terms: termsReducer(state.terms, action),
    tax_amounts: itemTaxAmountReducer(state.tax_amounts, action, {
      items: state.items,
    }),
    themes: themeReducer(state.themes, action),
    tenants: tenantReducer(state.tenants, action),
    users: userReducer(state.users, action),
    roles: roleReducer(state.roles, action),
    features: featureReducer(state.features, action),

    salesAnalyticsData: salesAnalyticsReducer(state.salesAnalyticsData, action),
    finance_analytics: finanaceAnalyticsReducer(
      state.finance_analytics,
      action
    ),
    management_analytics: managementAnalyticsReducer(
      state.management_analytics,
      action
    ),
    analytics_report_data: analyticsChartReducer(
      state.analytics_report_data,
      action
    ),
    loading_analytics_popup: analyticsLoadingReducer(
      state.loading_analytics_popup,
      action
    ),
    third_party_shipping_accounts: thirdPartyShippingAccountReducer(
      state.third_party_shipping_accounts,
      action
    ),
    targets_management: targetsManagementReducer(
      state.targets_management,
      action
    ),

    decoration_charges: decorationChargesReducer(
      state.decoration_charges ? state.decoration_charges : [],
      action
    ),
    decoration_charge_data: decorationChargeItemReducer(
      state.decoration_charge_data ? state.decoration_charge_data : {},
      action
    ),
    company_search: company_search_reducer(state.company_search || {}, action),
    departments: departmentReducer(state.departments || {}, action),
    event_types: eventTypeReducer(state.event_types || {}, action),
    industries: industryReducer(state.industries || {}, action),
    statuses: statusReducer(state.statuses || {}, action),
    account_statuses: accountStatusReducer(state.account_statuses || {}, action),
  });
  return new_state;
};

const defaultIdentityState =
  initialState && initialState.identity ? initialState.identity : {};
//const identity = (state = defaultIdentityState, action) => state

export const identityReducer = (state = defaultIdentityState, action) => {
  switch (action.type) {
    case REORDER_TAB_FAILURE:
    case REORDER_TAB_SUCCESS:
      let new_preferences = Object.assign({}, state["preferences"], {
        product_search_tab_order: JSON.parse(
          action.payload.product_search_tab_order
        ),
      });
      return Object.assign({}, state, { preferences: new_preferences });
    case UPDATE_SHOW_TARGETS_TENANT_SUCCESS:
      const target_type = action.payload.target_type;
      return {
        ...state,
        [target_type]: action.payload.json.tenant[target_type],
      };
  }
  return state;
};

export const dropdownsReducer = dropdowns;

export const reducers = {
  display: displayReducer,
  temp: tempReducer,
  dropdowns,
  entities: entitiesReducer,
  identity: identityReducer,
  heroImage: heroImageReducer,
  chatBots: botsReducer,
  clientPortals: clientPortalsReducer,
  folders: foldersReducer,
  isLoading: isLoadingReducer,
  added_to_orders: added_to_orders,
};

const rootReducer = () => combineReducers(reducers);

export default rootReducer;
