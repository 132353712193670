import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getIdentityUtils } from '../utils';

export const getTeams = s => {
    const dropdownTeams = s.dropdowns.teams;
    if (dropdownTeams
        && dropdownTeams.length
        && _.get(dropdownTeams[0], ['team_id'], false)
    ) {
        return dropdownTeams.reduce(
            (acc, v) => ({...acc, [v.team_id]: v}),
            {}
        );
    }

    return s.entities.teams || {};
};

export const getUsers = s => {
    const dropdownUsers = s.dropdowns.users;
    if (dropdownUsers
        && dropdownUsers.length
        && _.get(dropdownUsers[0], ['user_id'], false)
    ) {
        return dropdownUsers.reduce(
            (acc, v) => ({...acc, [v.user_id]: v}),
            {}
        );
    }

    return s.entities.users || {};
};
export const getUsersList = createSelector(
    getUsers,
    s => Object.values(s || {})
);
export const getActiveUsersList = createSelector(
    getUsers,
    s => Object.values(s || {}).filter(v => ('' + v.active) === '1')
);
export const getInactiveUsersList = createSelector(
    getUsers,
    s => Object.values(s || {}).filter(v => ('' + v.active) === '0')
);

export const userOptionMapFunc = v => ({ label: `${v.contact_first_name} ${v.contact_last_name}`.trim(), value: v.user_id });
export const userOptions = createSelector(
    getUsersList,
    s => s.map(userOptionMapFunc)
);
export const getMentionUsers = createSelector(
    getUsersList,
    s => s.filter(u => u.mask && u.mask !== '')
);
export const mentionUserOptions = createSelector(
    getMentionUsers,
    s => s.map(userOptionMapFunc)
);

export function filterReps(users, teamList, identity, withAllOption = true) {
    const { hasCapabilities } = getIdentityUtils(identity);
    const all_users = { ...users };
    if (!all_users[identity.user_id]) {
        all_users[identity.user_id] = {
            user_id: identity.user_id,
            user_email: identity.user_email,
            contact_first_name: identity.user_first_name,
            contact_last_name: identity.user_last_name,
            user_role: identity.user_role,
        };
    }

    let reps = Object.values(all_users);
    const prepend = [];
    if (hasCapabilities(['VIEW-REPORT-ALL-USERS'])) {
        if (withAllOption) {
            prepend.push({ user_id: '', contact_first_name: 'All', contact_last_name: '' });
        }
    } else if (hasCapabilities(['VIEW-REPORT-OWN-TEAMS'])) {
        const currentTeamUsers = teamList // _.values(state.entities.teams)
            .filter(v => (v.user_ids || []).includes(identity.user_id))
            .reduce((acc, v) => ([...acc, ...(v.user_ids || [])]), []);
        reps = reps.filter(
            v => currentTeamUsers.includes(v.user_id)
        );
    } else {
        reps = reps.filter(
            v => v.user_id === identity.user_id
        );
    }

    return prepend.concat(reps);
}

export const getUserRepOptions = createSelector(
    [
        s => ({
            teamList: Object.values(getTeams(s)),
            users: getUsers(s),
            identity: s.identity
        }),
    ],
    ({ users, teamList, identity }) => {
        const reps = filterReps(users, teamList, identity);
        return reps.map(userOptionMapFunc);
    }
);

export const getUserRepOptionsNoAll = createSelector(
    [
        s => ({
            teamList: Object.values(getTeams(s)),
            users: getUsers(s),
            identity: s.identity
        }),
    ],
    ({ users, teamList, identity }) => {
        const reps = filterReps(users, teamList, identity, false);
        return reps.map(userOptionMapFunc);
    }
);

