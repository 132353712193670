import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTaxOptions } from '../selectors/dropdowns';
import { formatMoney } from '../utils';

import { UPDATE_ITEM_TAX_AMOUNT_SUCCESS } from '../actions/item';

import {
  createFinalizeUpdate,
  createAddItemTaxAmount,
  createUpdateItemTaxAmount,
  createDeleteItemTaxAmount
} from '../actions';

import ItemTaxAmount from './ItemTaxAmount';
import { isAvalaraOrder } from '../helpers/order';

const ItemSummary = ({ order, item, order_currency_id, locked, zip2tax }) => {
  const taxes = useSelector(getTaxOptions);
  const dispatch = useDispatch();

  const onAddItemTaxAmount = () => {
    dispatch(createAddItemTaxAmount(item.item_id, taxes[0].key));
  };

  const onDeleteItemTaxAmount = (tax_amount_id) => dispatch(
    createDeleteItemTaxAmount(tax_amount_id)
  );

  const onUpdateItemTaxAmount = (item_tax_amount_id, field, previous_value) => value => dispatch(
    createUpdateItemTaxAmount(item_tax_amount_id, value)
  );

  return (
    <div className="large-4 column" style={{ height: '100%', padding: '2px' }}>
      <table className="summary">
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td>${formatMoney(item.total_subtotal)}</td>
          </tr>
          {!isAvalaraOrder(order) && [
            <tr className="totals" key="add-tax">
              <td>
                Tax {(!locked && !zip2tax) && <a style={{ float: 'right' }} onClick={onAddItemTaxAmount}>+ Another</a>}
              </td>
              <td>&nbsp;</td>
            </tr>,
            ...item.tax_amounts.map((ta) =>
              <ItemTaxAmount
                key={ta.tax_amount_id}
                tax_amount={ta}
                taxes={taxes}
                zip2tax={zip2tax}
                onUpdate={onUpdateItemTaxAmount}
                onDelete={onDeleteItemTaxAmount}
                locked={locked}
              />
            )
          ]}
          <tr>
            <td>Total</td>
            <td>${formatMoney(item.total_total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ItemSummary;
