import { createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import { oauth } from "../utils";

const LIMIT = 28;

const initialState = {
    keyword: '',
    offset: 1,
    limit: LIMIT,
    total: 0,
    images: [],
    loading: false,
    endOfImages: false,
};

const webImageSlice = createSlice({
    name: 'webImage',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setImages(state, action) {
            const { photos, total } = action.payload;
            state.images.push(...photos);
            state.total = total;
            state.offset += 1;
        },
        resetSearch(state, action) {
            state.keyword = '';
            state.offset = 1;
            state.limit = LIMIT;
            state.images = [];
            state.endOfImages = false;
        },
        setKeyword(state, action) {
            state.keyword = action.payload;
        },
        setNextPage(state, action) {
            state.offset += 1;
        },
        setEndOfImages(state, action) {
            state.endOfImages = true;
        },
    },
});

export const searchImages = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {keyword, offset, limit, images, total} = getState().webImage;

    if (offset > 1 && images.length >= total) {
        dispatch(setEndOfImages());
        return;
    }

    let photos = [];
    let newTotal = 0;

    const order = !!keyword ? 'relevant' : 'popular';
    const { json } = await oauth(
        'GET',
        'web-image/search-image',
        {keyword, offset, limit, order, orientation: 'landscape'});

    photos = _.get(json, 'photos', []);
    newTotal = _.get(json, 'total', 0);

    if (photos.length === 0) {
        dispatch(setEndOfImages());
        return;
    }

    dispatch(setImages({ photos, total: newTotal }));
    dispatch(setLoading(false));

    return { photos, total: newTotal };
};

export const notifyImageDownload = (imageId, imageProvider='unsplash') => async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { json } = await oauth(
        'GET',
        'web-image/notify-download',
        {imageId, imageProvider},
    );
    const success = _.get(json, 'success', false);

    dispatch(setLoading(false));

    return success;
};

export const {
    setLoading,
    setImages,
    resetSearch,
    setKeyword,
    setNextPage,
    setEndOfImages,
} = webImageSlice.actions;

export default webImageSlice.reducer;