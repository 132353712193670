import React, { useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { window } from '../../global';
import { createAddItem, createDeleteItem } from '../../actions';
import { createLoadDivision, createLoadSupplier } from '../../actions/supplier';
import { createDeleteTemp } from '../../actions/temp';
import { createFetchProductDetails } from '../../actions/product';
import { getConnectedPlusDivisionOptions } from '../../selectors/dropdowns';


import { Background, Button, Col, H3, Row } from '@commonsku/styles';
import { InstantSearch, Configure } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';
import config from '../../config';

import CustomSearchBox from './CustomSearchBox';
import CustomMenuSelect from './CustomMenuSelect';
import CustomInfiniteHits from './CustomInfiniteHits';

const searchClient = algoliasearch(config.algolia.applicationID, config.algolia.searchKey);
const indexName = config.algolia.index;
const itemsPerPage = 40;
const cadDivisionFilters = config.algolia.cadDivisions.map(id => `"division_id:-${id}"`);

const ProductSuggestionModal = ({ order, loading, onAddItem, onDeleteItem, divisions, loadDivisions, popups = [], location }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = order.tenant_id;
  useEffect(() => {
    loadDivisions('connected-plus');
    window.aa('setUserToken', userToken);
  }, [])

  const onClose = () => {
    dispatch(createDeleteTemp('new_product'));
    if (2 < window.history.length) {
      navigate(-1);
    } else if (location && location.pathname && (
      location.pathname.endsWith('/add-product-suggestion')
      || location.pathname.endsWith('/add-product-suggestion/')
    )) {
      window.location = location.pathname.replace('/add-product-suggestion', '');
    }
  };

  const divisionsArray = useMemo(() => {
    return divisions.reduce((acc, division) => {
      acc[division.key] = division.value;
      return acc;
    }, {});
  }, [divisions]);

  const headerText = 'Add Product';

  return (
    <Background fillWindow padded style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: 29
    }}>
      <Row style={{ flex: '0 0 auto', alignItems: 'center' }}>
        <Col style={{ flex: 2 }}>
          <H3 style={{ marginBottom: 0 }}>{headerText}</H3>
        </Col>
        <Col style={{ flex: 1 }}>
          {!popups.length && <Button size='medium' onClick={onClose} style={{ float: 'right' }}>Done</Button>}
        </Col>
      </Row>
      <InstantSearch searchClient={searchClient} indexName={indexName} future={{ preserveSharedStateOnUnmount: true }} insights={true} >
        <CustomSearchBox />
        <Row>
          <Col xs={6} sm={4} md={3} lg={2}>
            <CustomMenuSelect attribute='categories' defaultLabel="All Categories" />
          </Col>
          <Col xs={6} sm={4} md={3} lg={2}>
            <CustomMenuSelect attribute='division_id' defaultLabel="All Suppliers" divisions={divisionsArray} />
          </Col>
        </Row>
        <CustomInfiniteHits loading={loading} order={order} onAddItem={onAddItem} onDeleteItem={onDeleteItem} divisions={divisionsArray} />
        <Configure userToken={userToken} hitsPerPage={itemsPerPage} clickAnalytics={true} facetFilters={`[${cadDivisionFilters}]`} />
      </InstantSearch >

    </Background>
  );
};

const mapStateToProps = (state, ownProps) => ({
  loading: state.display.loading.add_item,
  divisions: getConnectedPlusDivisionOptions(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: (item, kwargs = { fetch_update: true }) => {
    const { order } = ownProps;
    const { order_type, order_id } = order;
    const parent_id = item.ext_product_id || item.objectID;
    if ('OPPORTUNITY' === order_type || !order_type) {
      return dispatch(createFetchProductDetails(parent_id, item.copied_from, 'MESSAGE'));
    } else if ('PRESENTATION' === order_type || 'COLLECTION' === order_type || 'SHOP' === order_type) {
      return dispatch(createAddItem(
        order_id, 'OPTION', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.product_supplier_code, item.division_id, 'reference', 'sage_connect' === item.copied_from, { ...kwargs }
      ));
    } else {
      return dispatch(createAddItem(
        order_id, 'PRODUCT', parent_id, item.copied_from, null, null, null,
        item.currency_id,
        null, item.product_supplier_code, item.division_id, 'all', 'sage_connect' === item.copied_from, { ...kwargs }
      ));
    }
  },
  onDeleteItem: item_id => {
    return dispatch(createDeleteItem(item_id));
  },
  loadDivisions: supplier_type => {
    if ('collection' === supplier_type) {
      dispatch(createLoadSupplier(supplier_type));
    } else {
      supplier_type = 'sage-connect' === supplier_type ? 'sage' : supplier_type;
      dispatch(createLoadDivision('commonsku' === supplier_type ? 'supplier-direct' : supplier_type));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSuggestionModal);
