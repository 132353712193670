
export const VIEW_TYPES = {
  LIST: 'LIST',
  PREVIEW: 'PREVIEW',
};
export const POPUP_MAX_HEIGHT = 900;
export const POPUP_MAX_WIDTH = 1300;

export const CATEGORIES = {
  featured: {
    key: 'featured',
    value: 'Featured',
  },
  favourites: {
    key: 'favourites',
    value: 'Favourites',
  },
  light: {
    key: 'light',
    value: 'Light',
  },
  dark: {
    key: 'dark',
    value: 'Dark',
  },
  all: {
    key: 'all',
    value: 'All',
  },
};
export const Categories = Object.values(CATEGORIES);

export const FILTERS = {
  topNav: {
    key: 'topNav',
    value: 'Top Nav',
  },
  leftNav: {
    key: 'leftNav',
    value: 'Left Nav',
  },
  rightNav: {
    key: 'rightNav',
    value: 'Right Nav',
  },
  productPopup: {
    key: 'productPopup',
    value: 'Product Popup',
  },
  productDropdown: {
    key: 'productDropdown',
    value: 'Product Dropdown',
  },
  productSlideshow: {
    key: 'productSlideshow',
    value: 'Product Slideshow',
  },
};
export const Filters = Object.values(FILTERS);

export const isShopThemeFavourite = (templateData, t) =>
  templateData.favourite && templateData.favourite.value === t.public_view_template_id;
export const isThemeFeatured = (t) => t.title === 'Featured';
