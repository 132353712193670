import { isEmpty, map, take } from 'lodash';
import React from 'react';

import { Row, Col, Text, colors, Button } from '@commonsku/styles';
import { FromNow } from '../moment';
import CompanyLink from '../CompanyLink';
import CommunityEventLink from './CommunityEventLink';

const Briefing = ({ events }) => {
  return <Col>
    {map(take(events, 3), (event, i) => {
      const interested = !isEmpty(event.community_event_user_id);
      return <Row key={i} mt={10}>
        <Col style={{ flex: 9 }}>
          <CommunityEventLink event={event}/>
          <Row>
            <Text style={{ fontSize: '0.8rem' }}>
              Hosted by <CompanyLink company={event.company}/>
            </Text>
          </Row>
          <Row justify="space-between">
            <Text style={{ fontSize: '0.8rem', padding: '2px 0' }}>
              Starts <FromNow value={event.scheduled_time}/>
            </Text>
            {interested && <Button size="tiny" bg={colors.special2}
              style={{ borderColor: colors.special2, padding: '2px 8px', borderRadius: '14px', cursor: 'default' }}
            > &#10003; Interested</Button>}
          </Row>
        </Col>
      </Row>;
    })}
  </Col>;
};

export default Briefing;