import { Col, H1, Row, Text, SalesArrowIcon, colors, themeOptions } from '@commonsku/styles';
import React from 'react';
import { useMediaQuery } from '../../hooks';
import { formatMoney } from '../../utils';
import DashboardTile from './DashboardTile';

function DashboardSalesTotalsTile({
  title,
  prevTotalCurrency,
  prevTotalCount,
  totalCurrency,
  totalCount,
  percent = null,
  up = null,
  onClick = null,
  style={},
  ...props
}) {
  const isSmallScreen = useMediaQuery('(min-width: 768px) and (max-width: 1090px)');

  const textStyle = { display: 'block', padding: 5, };
  const isUp = up === null ? (totalCurrency - prevTotalCurrency) >= 0 : up;
  const percentVal = React.useMemo(() => {
    if (percent !== null) { return parseFloat(percent).toFixed(2); }

    const diff = Math.abs(totalCurrency-prevTotalCurrency);
    if (diff === 0) { return 0; }

    const val = parseFloat(prevTotalCurrency === 0 ? 1 : diff/prevTotalCurrency) * 100;
    return val.toFixed(2);
  }, [percent, totalCurrency, prevTotalCurrency]);

  return (
    <DashboardTile style={{
      textAlign: 'center',
      color: '#16344E',
      overflowY: 'auto',
      ...(onClick ? { cursor: 'pointer' } : {}),
      ...style,
    }} onClick={onClick} {...props}>
      <Row>
        <Col xs padded style={{ display: 'flex', alignSelf: 'center', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
          {isUp ? <SalesArrowIcon /> : <SalesArrowIcon direction="down"/>}
          <span style={{
            paddingLeft: '8px',
            color: isUp ? colors.secondary3['60'] : colors.errors['60'],
            textAlign: 'center',
            verticalAlign: 'middle',
            fontSize: 24,
          }}>{formatMoney(percentVal > 100 ? 100 : percentVal, 0)}%</span>
        </Col>
        <Col xs style={{textAlign: 'center',}}>
          <Text style={{...textStyle, fontFamily: themeOptions.fontFamilies.regular, fontSize: 16, fontWeight: 600}}>{title}</Text>
          <Text style={{...textStyle, fontFamily: themeOptions.fontFamilies.regular, fontSize: 14}}>{isSmallScreen ? 'Last 7' : 'Last 7 days'}</Text>
        </Col>
      </Row>
    </DashboardTile>
  );
};

export default DashboardSalesTotalsTile;
