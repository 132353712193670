import { get, pickBy } from 'lodash';

import {
  UPDATE_PURCHASE_ORDER_REQUEST,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAILURE,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAILURE,
  REGENERATE_PURCHASE_ORDER_SUCCESS,
  FETCH_POS_SUCCESS,
  FETCH_PURCHASE_ORDER_SUCCESS,
} from '../actions/purchase_order';
import {
  UPLOAD_PO_PROOF_SUCCESS,
  UPDATE_PO_PROOF_SUCCESS,
  DELETE_PO_PROOF_SUCCESS,
} from '../actions/purchase_order_proof';
import { ADD_BILL_SUCCESS, UPDATE_BILL_SUCCESS } from '../actions/bill';
import { DELETE_ORDER_SUCCESS } from '../actions/order';
import { DELETE_ITEM_SUCCESS } from '../actions/item';
import ProofStatus from '../models/ProofStatus';

const reduce = (state = {}, action) => {
  let purchase_order_id, updated_po;
  let state_copy;
  switch (action.type) {
    case FETCH_POS_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload.purchase_orders.reduce((o, po) => {
          o[po.purchase_order_id] = po;
          return o;
        }, {})
      );
    case FETCH_PURCHASE_ORDER_SUCCESS:
      purchase_order_id = action.payload.purchase_order.purchase_order_id;
      return {
        ...state,
        [purchase_order_id]: {
          ...(state[purchase_order_id] || {}),
          ...action.payload.purchase_order,
        },
      };
    case REGENERATE_PURCHASE_ORDER_SUCCESS:
      return Object.assign(
        Object.values(state).filter(po => !(action.payload.deleted || []).includes(po.purchase_order_id)).reduce((o, po) => {
          o[po.purchase_order_id] = po;
          return o;
        }, {}),
        action.payload.purchase_orders.map(
          po => Object.assign({}, state[po.purchase_order_id], po)
        ).reduce((o, po) => {
          o[po.purchase_order_id] = po;
          return o;
        }, {})
      );
    case UPDATE_PURCHASE_ORDER_REQUEST:
      // fall through
    case UPDATE_PURCHASE_ORDER_SUCCESS:
      // fall through
    case UPDATE_PURCHASE_ORDER_FAILURE:
      purchase_order_id = action.payload.id;
      updated_po = Object.assign({}, state[purchase_order_id], action.payload.data);
      return Object.assign({}, state, {[purchase_order_id]: updated_po});
    case DELETE_PURCHASE_ORDER_SUCCESS:
      return Object.values(state).filter(po => po.purchase_order_id !== action.payload.id).reduce((o, po) => { o[po.purchase_order_id] = po; return o; }, {});
    case DELETE_PURCHASE_ORDER_FAILURE:
      state_copy = Object.assign({}, state);
      state_copy[action.payload.id] = action.payload.purchase_order;
      return state_copy;
    case ADD_BILL_SUCCESS:
    case UPDATE_BILL_SUCCESS:
      return Object.assign({}, state, (action.payload.purchase_orders || []).map(po => Object.assign({}, state[po.purchase_order_id], po)).reduce((o, po) => { o[po.purchase_order_id] = po; return o; }, {}));
    case DELETE_ORDER_SUCCESS:
      return Object.values(state).filter(po => po.order_id !== action.payload.data.order_id).reduce((o, po) => { o[po.purchase_order_id] = po; return o; }, {});
    case UPDATE_PO_PROOF_SUCCESS:
    case UPLOAD_PO_PROOF_SUCCESS:
      return poProofReducer(state, action);
    case DELETE_PO_PROOF_SUCCESS:
      purchase_order_id = (get(action, 'payload.purchase_order_proof') || {}).purchase_order_id;
      return {
        ...state,
        [purchase_order_id]: {
          ...state[purchase_order_id],
          proof_status_id: ProofStatus.ProofRequired,
          all_proof_uploaded: '0',
        },
      };
  }
  return state;
};

const poProofReducer = (state, action) => {
  const {
    purchase_order_id, proof_status_id, all_proof_uploaded
  }= get(action, 'payload.purchase_order') || {};
  return {
    ...state,
    [purchase_order_id]: {
      ...state[purchase_order_id],
      proof_status_id, all_proof_uploaded
    },
  };
};

export default reduce;
