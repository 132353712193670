import { H2, Popup, XIcon, Select } from '@commonsku/styles'
import React from 'react';

function SanmarPsstPopup({ onClose, handleChoosePsstAddress, psstAddresses }) {
  const options = psstAddresses.map((address) => ({
    label: address.address_name,
    value: address.address_id,
  }));

  const onChoosePsstAddress = ({ value }) => {
    handleChoosePsstAddress(psstAddresses.find((address) => address.address_id === value))
    onClose();
  }

  return (
    <Popup style={{ width: 701, borderRadius: 10 }} height="500px" noHeader popupContentStyle={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <H2 style={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '40px',
            fontFamily: 'skufont-regular',
            marginBottom: 0,
          }}>Select address from SanMar’s list</H2>
          <div style={{ cursor: 'pointer' }} onClick={onClose}><XIcon /></div>
        </div>
        <Select placeholder="Select Address" menuIsOpen options={options} onChange={onChoosePsstAddress} dropdownIndicatorStyles={{ display: 'none' }} />
      </div>
    </Popup>
  )
}

export default SanmarPsstPopup