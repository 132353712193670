import { oauth } from '../utils';

export const CLEAR_CLIENT_STATE = 'CLEAR_CLIENT_STATE';

export const SAVE_SEARCH_FILTERS_STATE = 'SAVE_SEARCH_FILTERS_STATE';

export const LOAD_COMPANY_CLIENT_LIST_REQUEST = 'LOAD_COMPANY_CLIENT_LIST_REQUEST';
export const LOAD_COMPANY_CLIENT_LIST_SUCCESS = 'LOAD_COMPANY_CLIENT_LIST_SUCCESS';
export const LOAD_COMPANY_CLIENT_LIST_FAILURE = 'LOAD_COMPANY_CLIENT_LIST_FAILURE';

// Update in state only
export const UPDATE_COMPANY_CLIENT_STATE_REQUEST = 'UPDATE_COMPANY_CLIENT_STATE_REQUEST';

export const LOAD_PARENT_CLIENT_LIST_REQUEST = 'LOAD_PARENT_CLIENT_LIST_REQUEST';
export const LOAD_PARENT_CLIENT_LIST_SUCCESS = 'LOAD_PARENT_CLIENT_LIST_SUCCESS';
export const LOAD_PARENT_CLIENT_LIST_FAILURE = 'LOAD_PARENT_CLIENT_LIST_FAILURE';

export const LOAD_TAXES_LIST_REQUEST = 'LOAD_TAXES_LIST_REQUEST';
export const LOAD_TAXES_LIST_SUCCESS = 'LOAD_TAXES_LIST_SUCCESS';
export const LOAD_TAXES_LIST_FAILURE = 'LOAD_TAXES_LIST_FAILURE';

export const LOAD_TERMS_LIST_REQUEST = 'LOAD_TERMS_LIST_REQUEST';
export const LOAD_TERMS_LIST_SUCCESS = 'LOAD_TERMS_LIST_SUCCESS';
export const LOAD_TERMS_LIST_FAILURE = 'LOAD_TERMS_LIST_FAILURE';

export const LOAD_TEAM_LIST_REQUEST = 'LOAD_TEAM_LIST_REQUEST';
export const LOAD_TEAM_LIST_SUCCESS = 'LOAD_TEAM_LIST_SUCCESS';
export const LOAD_TEAM_LIST_FAILURE = 'LOAD_TEAM_LIST_FAILURE';

export const LOAD_COMPANY_USER_LIST_REQUEST = 'LOAD_COMPANY_USER_LIST_REQUEST';
export const LOAD_COMPANY_USER_LIST_SUCCESS = 'LOAD_COMPANY_USER_LIST_SUCCESS';
export const LOAD_COMPANY_USER_LIST_FAILURE = 'LOAD_COMPANY_USER_LIST_FAILURE';

const createSaveSearchFiltersState = (search) => ({
    type: SAVE_SEARCH_FILTERS_STATE,
    payload: {
        search
    }
});

const createLoadCompanyClientListRequest = () => ({
    type: LOAD_COMPANY_CLIENT_LIST_REQUEST,
    payload: {}
});

const createFinalizeLoadCompanyClientList = (companies) => ({
    type: LOAD_COMPANY_CLIENT_LIST_SUCCESS,
    payload: {
        companies
    }
});

// With Search params
export const createLoadCompanyClientList = (search = {
    company_type: 'CLIENT',
    'start-index': 0,
    'max-results': 50,
    'order-by': 'latest_use',
    'order-dir': 'desc',
    search: true,
    with_sales_report: true,
    company_name: '', tag_id: '', industry_id: '', sales_rep_id: '', account_status_id: ''
}) => dispatch => {

    dispatch(createLoadCompanyClientListRequest());

    return oauth('GET', 'company/clients', search).then(
        ({ json }) => dispatch(createFinalizeLoadCompanyClientList(json.companies))
    );
};

export const createUpdateCompanyClientState = (client_id, client) => ({
    type: UPDATE_COMPANY_CLIENT_STATE_REQUEST,
    payload: { client_id, client }
});

export const createClearClientState = () => ({
    type: CLEAR_CLIENT_STATE,
    payload: {}
});

const createLoadTaxesListRequest = () => ({
    type: LOAD_TAXES_LIST_REQUEST,
    payload: {}
});

const createFailedLoadTaxesList = error => ({
    type: LOAD_TAXES_LIST_FAILURE,
    payload: {
        error
    }
});

const createFinalizeLoadTaxesList = taxes => ({
    type: LOAD_TAXES_LIST_SUCCESS,
    payload: {
        taxes
    }
});

export const createLoadTaxesList = (options = { z2t: '', zip2tax: '' }) => dispatch => {
    dispatch(createLoadTaxesListRequest());

    return oauth('GET', 'tax', options).then(
        ({ json }) => dispatch(createFinalizeLoadTaxesList(json.taxes)),
        ({ json }) => dispatch(createFailedLoadTaxesList('Unable to Load Taxes'))
    );
};

const createLoadTermsListRequest = () => ({
    type: LOAD_TERMS_LIST_REQUEST,
    payload: {}
});

const createFailedLoadTermsList = error => ({
    type: LOAD_TERMS_LIST_FAILURE,
    payload: {
        error
    }
});

const createFinalizeLoadTermsList = terms => ({
    type: LOAD_TERMS_LIST_SUCCESS,
    payload: {
        terms
    }
});

export const createLoadTermsList = (options = {}) => dispatch => {
    dispatch(createLoadTermsListRequest());

    return oauth('GET', 'terms', options).then(
        ({ json }) => dispatch(createFinalizeLoadTermsList(json.terms)),
        ({ json }) => dispatch(createFailedLoadTermsList('Unable to load terms'))
    );
};

export const createLoadCompanyTermsList = (options = {}) => dispatch => {
    dispatch(createLoadTermsListRequest());

    return oauth('GET', 'company-term', options).then(
        ({ json }) => dispatch(createFinalizeLoadTermsList(json.company_terms)),
        ({ json }) => dispatch(createFailedLoadTermsList('Unable to load Company terms'))
    );
};

const createLoadParentClientListRequest = () => ({
    type: LOAD_PARENT_CLIENT_LIST_REQUEST,
    payload: {}
});

const createFailedLoadParentClientList = error => ({
    type: LOAD_PARENT_CLIENT_LIST_FAILURE,
    payload: {
        error
    }
});

const createFinalizeLoadParentClientList = parent_clients => ({
    type: LOAD_PARENT_CLIENT_LIST_SUCCESS,
    payload: {
        parent_clients
    }
});

export const createLoadParentClientList = (options = {}) => dispatch => {
    dispatch(createLoadParentClientListRequest());

    return oauth('GET', 'client', {...options, parent_list: true}).then(
        ({ json }) => dispatch(createFinalizeLoadParentClientList(json.clients)),
        ({ json }) => dispatch(createFailedLoadParentClientList('Unable to Load Parent Clients'))
    );
};

const createLoadTeamListRequest = () => ({
    type: LOAD_TEAM_LIST_REQUEST,
    payload: {}
});

const createFailedLoadTeamList = error => ({
    type: LOAD_TEAM_LIST_FAILURE,
    payload: {
        error
    }
});

const createFinalizeLoadTeamList = teams => ({
    type: LOAD_TEAM_LIST_SUCCESS,
    payload: {
        teams
    }
});

export const createLoadTeamList = (options = {}) => dispatch => {
    dispatch(createLoadTeamListRequest());

    return oauth('GET', 'team', options).then(
        ({ json }) => dispatch(createFinalizeLoadTeamList(json.teams)),
        ({ json }) => dispatch(createFailedLoadTeamList('Unable to Load Teams'))
    );
};

const createLoadCompanyUserListRequest = () => ({
    type: LOAD_COMPANY_USER_LIST_REQUEST,
    payload: {}
});

const createFailedLoadCompanyUserList = error => ({
    type: LOAD_COMPANY_USER_LIST_FAILURE,
    payload: {
        error
    }
});

const createFinalizeLoadCompanyUserList = company_users => ({
    type: LOAD_COMPANY_USER_LIST_SUCCESS,
    payload: {
        company_users
    }
});

export const createLoadCompanyUserList = (parent_id, parent_type='TENANT', options = {}) => dispatch => {
    dispatch(createLoadCompanyUserListRequest());

    return oauth('GET', 'user', {
        ...options,
        parent_id,
        parent_type
    }).then(
        ({ json }) => dispatch(createFinalizeLoadCompanyUserList(json.users)),
        ({ json }) => dispatch(createFailedLoadCompanyUserList('Unable to Load Company Users'))
    );
};

