import React from 'react';
import { jsgl } from './jsgl';

export interface Point {
    x: number;
    y: number;
}

export interface Triangle {
    p0: Point;
    p1: Point;
    p2: Point;
    t0: Point;
    t1: Point;
    t2: Point;
}

export interface LogoDragContext {
    target?: number;
    initial: Point;
    delta: Point;
}

function drawTriangle(
    image: HTMLImageElement,
    triangle: Triangle,
    context: CanvasRenderingContext2D
) {
    jsgl.drawTriangle(
        context,
        image,
        triangle.p0.x,
        triangle.p0.y,
        triangle.p1.x,
        triangle.p1.y,
        triangle.p2.x,
        triangle.p2.y,
        triangle.t0.x,
        triangle.t0.y,
        triangle.t1.x,
        triangle.t1.y,
        triangle.t2.x,
        triangle.t2.y
    );
}

function scaleVector(origin: Point, point: Point, scale: number) {
    return {
        x: origin.x + scale * (point.x - origin.x),
        y: origin.y + scale * (point.y - origin.y),
    };
}

function getCenterFromPoints(points: Point[]): Point {
    const avgX = points.reduce((sum, point) => sum + point.x, 0) / points.length;
    const avgY = points.reduce((sum, point) => sum + point.y, 0) / points.length;

    return {
        x: avgX,
        y: avgY,
    };        
}

function scaleTriangle(triangle: Triangle, scale: number): Triangle {
    const center = getCenterFromPoints([triangle.p0, triangle.p1, triangle.p2]);

    return {
        p0: scaleVector(center, triangle.p0, scale),
        p1: scaleVector(center, triangle.p1, scale),
        p2: scaleVector(center, triangle.p2, scale),
        t0: triangle.t0,
        t1: triangle.t1,
        t2: triangle.t2,
    }
}

export class Logo {
    private NUM_SUBS = 10;
    private NUM_DIVS = 10;
    private HANDLE_RADIUS = 10;
    private CONTROL_PADDING = 8;
    private TRIANGLE_SCALE_TWEAK = 1.025;
    private currentScale = 1;
    private currentAngle = 0;
    public handles: Point[];
    public img: HTMLImageElement;

    public constructor(img: HTMLImageElement, width: number, height: number) {
        this.img = img;

        const aspectRatio = img.width / img.height;
        let scaledWidth = width;
        let scaledHeight = height;

        if (aspectRatio < 1) {
            scaledWidth *= aspectRatio;
        } else {
            scaledHeight *= 1 / aspectRatio;
        }

        this.handles = [
            { x: 0, y: 0 },
            { x: scaledWidth, y: 0 },
            { x: scaledWidth, y: scaledHeight },
            { x: 0, y: scaledHeight }
        ];
    }

    public getDragContext(initial: Point, target?: number): LogoDragContext {
        return {
            target,
            initial,
            delta: { x: 0, y: 0 }
        };
    }

    public getDraggedHandles(dragContext?: LogoDragContext): Point[] {
        if (dragContext == null) {
            return this.handles;
        }

        return this.handles.map((handle, index) => ({
            x: handle.x + ((dragContext.target ?? index) === index ? dragContext.delta.x : 0),
            y: handle.y + ((dragContext.target ?? index) === index ? dragContext.delta.y : 0),
        }));
    }

    public applyDragContext(dragContext: LogoDragContext): void {
        this.handles = this.getDraggedHandles(dragContext);
    }

    public bounds(): Point[] {
        return [{
            x: Math.min(...this.handles.map(h => h.x)),
            y: Math.min(...this.handles.map(h => h.y))
        }, {
            x: Math.max(...this.handles.map(h => h.x)),
            y: Math.max(...this.handles.map(h => h.y))
        }];
    }

    public isUnderMouse(mousePosition: Point): boolean {
        const [topLeft, bottomRight] = this.bounds();
        return (
            mousePosition.x >= topLeft.x &&
            mousePosition.y >= topLeft.y &&
            mousePosition.x <= bottomRight.x &&
            mousePosition.y <= bottomRight.y
        );
    }

    private renderHandles(handles: Point[], onLogoHandleMouseDown): JSX.Element[] {
        return handles.map((handle, index) => (
            <div
                key={index}
                className="node"
                style={{ left: handle.x, top: handle.y }}
                onMouseDown={onLogoHandleMouseDown(index)}
            />
        ));
    }

    private renderBorderSegment(handle1: Point, handle2: Point): JSX.Element {
        return (
            <line
                x1={handle1.x + this.HANDLE_RADIUS}
                y1={handle1.y + this.HANDLE_RADIUS}
                x2={handle2.x + this.HANDLE_RADIUS}
                y2={handle2.y + this.HANDLE_RADIUS}
                stroke="white"
                strokeWidth="2"
                strokeDasharray="8"
            />
        );
    }

    private renderBorder(handles: Point[]): JSX.Element {
        const borderSegments = [];

        for (let index = 0; index < handles.length; ++index) {
            const handle = handles[index];
            const nextHandle = (index === handles.length - 1)
                ? handles[0]
                : handles[index + 1];

            borderSegments.push(this.renderBorderSegment(handle, nextHandle));
        }

        return (
            <svg key="border-svg" className="logo-border">
                {...borderSegments}
            </svg>
        );
    }

    public renderControls(
        context: CanvasRenderingContext2D | undefined,
        onLogoHandleMouseDown: (index: number) => React.MouseEventHandler<HTMLDivElement>,
        dragContext?: LogoDragContext
    ): JSX.Element[] {
        if (context == null) {
            throw new Error('canvas has invalid size');
        }

        const xOffset = context.canvas.offsetLeft - this.HANDLE_RADIUS;
        const yOffset = context.canvas.offsetTop - this.HANDLE_RADIUS;

        const handles = this.getDraggedHandles(dragContext);

        const screenHandles = handles.map(handle => {
            const hasLowX = handles.filter((other => other.x > handle.x)).length >= 2;
            const hasLowY = handles.filter((other => other.y > handle.y)).length >= 2;

            return {
                x: handle.x + xOffset + (hasLowX ? -this.CONTROL_PADDING : this.CONTROL_PADDING),
                y: handle.y + yOffset + (hasLowY ? -this.CONTROL_PADDING : this.CONTROL_PADDING),
            };
        });

        return [
            this.renderBorder(screenHandles),
            ...this.renderHandles(screenHandles, onLogoHandleMouseDown)
        ];
    }

    private getLogoCenter(): Point {
        return getCenterFromPoints(this.handles);
    }

    private scaleFromCenter(point: Point, scale: number): Point {
        return scaleVector(this.getLogoCenter(), point, scale);
    }

    public scale(scale: number): void {
        if (scale === this.currentScale) return;
        const adjustedScale = scale / this.currentScale;
        this.handles = this.handles.map(handle => this.scaleFromCenter(handle, adjustedScale));
        this.currentScale = scale;
    }

    private rotateFromCenter({ x, y }: Point, angle: number): Point {
        const { x: cX, y: cY } = this.getLogoCenter();

        const radians = (Math.PI / 180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        return {
            x: cX + (cos * (x - cX)) + (sin * (y - cY)),
            y: cY + (cos * (y - cY)) - (sin * (x - cX)),
        };
    }

    public rotate(angle: number): void {
        if (angle === this.currentAngle) return;
        const adjustedAngle = angle - this.currentAngle;
        this.handles = this.handles.map(handle => this.rotateFromCenter(handle, adjustedAngle));
        this.currentAngle = angle;
    }

    public draw(context: CanvasRenderingContext2D, dragContext?: LogoDragContext): void {
        const handles = this.getDraggedHandles(dragContext);
        const dx1 = handles[3].x - handles[0].x;
        const dy1 = handles[3].y - handles[0].y;
        const dx2 = handles[2].x - handles[1].x;
        const dy2 = handles[2].y - handles[1].y;

        for (let sub = 0; sub < this.NUM_SUBS; ++sub) {
            const curRow = sub / this.NUM_SUBS;
            const nextRow = (sub + 1) / this.NUM_SUBS;

            const curRowX1 = handles[0].x + dx1 * curRow;
            const curRowY1 = handles[0].y + dy1 * curRow;

            const curRowX2 = handles[1].x + dx2 * curRow;
            const curRowY2 = handles[1].y + dy2 * curRow;

            const nextRowX1 = handles[0].x + dx1 * nextRow;
            const nextRowY1 = handles[0].y + dy1 * nextRow;

            const nextRowX2 = handles[1].x + dx2 * nextRow;
            const nextRowY2 = handles[1].y + dy2 * nextRow;

            for (let div = 0; div < this.NUM_DIVS; ++div) {
                const curCol = div / this.NUM_DIVS;
                const nextCol = (div + 1) / this.NUM_DIVS;

                const dCurX = curRowX2 - curRowX1;
                const dCurY = curRowY2 - curRowY1;
                const dNextX = nextRowX2 - nextRowX1;
                const dNextY = nextRowY2 - nextRowY1;

                const p0 = {
                    x: curRowX1 + dCurX * curCol,
                    y: curRowY1 + dCurY * curCol
                };
                const p1 = {
                    x: curRowX1 + (curRowX2 - curRowX1) * nextCol,
                    y: curRowY1 + (curRowY2 - curRowY1) * nextCol
                };
                const p2 = {
                    x: nextRowX1 + dNextX * nextCol,
                    y: nextRowY1 + dNextY * nextCol
                };
                const p3 = {
                    x: nextRowX1 + dNextX * curCol,
                    y: nextRowY1 + dNextY * curCol
                };

                const u1 = curCol * this.img.naturalWidth * this.TRIANGLE_SCALE_TWEAK;
                const u2 = nextCol * this.img.naturalWidth * this.TRIANGLE_SCALE_TWEAK;
                const v1 = curRow * this.img.naturalHeight * this.TRIANGLE_SCALE_TWEAK;
                const v2 = nextRow * this.img.naturalHeight * this.TRIANGLE_SCALE_TWEAK;

                const triangle1: Triangle = {
                    p0: p0,
                    p1: p2,
                    p2: p3,
                    t0: { x: u1, y: v1 },
                    t1: { x: u2, y: v2 },
                    t2: { x: u1, y: v2 }
                };

                const triangle2: Triangle = {
                    p0: p0,
                    p1: p1,
                    p2: p2,
                    t0: { x: u1, y: v1 },
                    t1: { x: u2, y: v1 },
                    t2: { x: u2, y: v2 }
                };

                // Scale triangles up slightly to eliminate gap artifacts
                drawTriangle(this.img, scaleTriangle(triangle1, this.TRIANGLE_SCALE_TWEAK), context);
                drawTriangle(this.img, scaleTriangle(triangle2, this.TRIANGLE_SCALE_TWEAK), context);
            }
        }
    }
}
