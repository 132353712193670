import React from 'react';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class DistributorCentralSearchFilter extends ProductSearchFilter {

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user_search.search_term && nextProps.user_search.search_term !== this.state.search.search_term && nextProps.divisions.length > 0 && this.props.divisions.length == 0) {
      this.setState({ search: Object.assign({}, this.state.search, { search_term: nextProps.user_search.search_term }) }, function () {
        this.onSearch(0, true, null, null, null, null);
      });
    }
  }

  getProductSource() {
    return 'dc-products';
  }

  loadDivisions() {
    this.props.loadDivisions('dc');
  }

  getSearchTerms(page) {
    let search_terms = {
      keyword_sku: this.state.search.search_term,
      'max-results': PAGE_SIZE
    };
    if (page > 0) {
      search_terms['start-index'] = page * PAGE_SIZE;
    }
    const company_divisions = (this.props.divisions || []).filter(d => d.company_id === this.props.company_id);
    const division_id = this.state.search.division_id || (this.props.company_type === 'SUPPLIER' && company_divisions.length ? company_divisions[0].key : null);
    if (division_id) {
      search_terms.division_id = division_id;
    }
    if (this.state.search.net_min) {
      search_terms.net_min = this.state.search.net_min;
    }
    if (this.state.search.net_max) {
      search_terms.net_max = this.state.search.net_max;
    }
    return search_terms;
  }

  getEndpoint() {
    return 'dc-product';
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: '',
        net_min: '',
        net_max: ''
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.dc_division_id || '',
        net_min: this.props.user_search.net_min || '',
        net_max: this.props.user_search.net_max || ''
      };
    }
  }

  render() {
    const { company_type, divisions, style } = this.props;
    const { search } = this.state;

    const company_divisions = (divisions || []).filter(d => d.company_id === this.props.company_id);
    const division_id = search.division_id || (company_type === 'SUPPLIER' && company_divisions.length ? company_divisions[0].key : null);

    const onChangeDivision = division_id => this.onChangeDivision(division_id, 'dc_division_id');

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-3 columns">
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={this.state.search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </div>
          <div className="medium-2 columns">
            {divisions &&
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={true}
                options={divisions}
                change={onChangeDivision}
                placeholder="Supplier"
              />}
          </div>
          <div className="medium-4 columns">
            <div className="row">
              <div className="medium-5 columns">
                <input
                  type="text"
                  placeholder="Net min"
                  onChange={this.handleChangeNetMin}
                  value={search.net_min}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
              <div className="medium-2 columns">
                <center><span>&mdash;</span></center>
              </div>
              <div className="medium-5 columns">
                <input
                  type="text"
                  placeholder="Net max"
                  onChange={this.handleChangeNetMax}
                  value={search.net_max}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
            </div>
          </div>

          <div className="medium-3 columns">
            <div className="button-group expanded">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('dc')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default DistributorCentralSearchFilter;
