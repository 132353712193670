import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Row, Col, Label, LabeledCheckbox, AlertIcon,
} from '@commonsku/styles';
import PreviewAddress from '../PreviewAddress';
import { contactsByCompanyIdOptions } from '../../../selectors/contacts';
import { addressByTypeOptions, addressByParentOptions, addressByTypeAndParentOptions } from '../../../selectors/addresses';
import { AddressSelect, ContactSelect, InHandsDateField } from '../Fields';

const WARNING_CONTAINER_STYLE = {
    background:'rgb(254,250,202)',
    color:'rgb(208,182,84)',
    marginBottom: '16px',
    padding: '16px'
};

const BillingOnlyAddressWarning = () =>
  <div style={{padding: '8px'}}>
      <Col style={WARNING_CONTAINER_STYLE}>
          <Row>
              <Col xl={1} xs={1} style={{display: 'flex', alignItems: 'top'}}>
                  <AlertIcon color="rgb(208,182,84)" />
              </Col>
              <Col xl={11} xs={11}>
                <span style={{ fontWeight: 600}}>
                    This address is not listed as a shipping address on the customer profile. &nbsp;
                    <a href="https://help.commonsku.com/knowledge/client-addresses" target="_blank" rel="noreferrer">Learn More</a>
                </span>
              </Col>
          </Row>
      </Col>
  </div>;

const SalesOrderFields = React.memo(({
    form,
    updateField,
    SelectMenuList,
    disableBillingContactSelect = false,
}) => {
    const contact_options = useSelector(s => contactsByCompanyIdOptions(s, form.client_id));
    const address_options = useSelector(s => addressByParentOptions(s, 'CLIENT', form.client_id));
    const shipping_address_options = useSelector(s =>
        addressByTypeAndParentOptions(s, 'SHIPPING', 'CLIENT', form.client_id)
    );
    const billing_address_options = useSelector(s =>
        addressByTypeAndParentOptions(s, 'BILLING', 'CLIENT', form.client_id)
    );
    const [shippingAddressOptions, setShippingAddressOptions] = useState(shipping_address_options);
    const showBillingOnlyAddressWarning = form.sameAsBilling && address_options.find(a => a.value === form.billing_address_id)?.type === 'BILLING';

    useEffect(() => {
        if (form.sameAsBilling && (
            (form.shipping_contact_id !== form.billing_contact_id) ||
            (form.shipping_address_id !== form.billing_address_id)
        )) {
            updateField('shipping_contact_id', form.billing_contact_id);
            updateField('shipping_address_id', form.billing_address_id);
        }
    }, [
        updateField,
        form.sameAsBilling,
        form.shipping_contact_id,
        form.shipping_address_id,
        form.billing_contact_id,
        form.billing_address_id,
    ]);

    const handleSameAsBillingChange = () => {
        // if from false to true, populate the options with all addresses
        if (!form.sameAsBilling) {
            setShippingAddressOptions(address_options);
        } else {
            setShippingAddressOptions(shipping_address_options);
            updateField('shipping_address_id', null);
        }
        updateField('sameAsBilling', !form.sameAsBilling);
    };

    return (
        <>
            <Col xs padded>
                <InHandsDateField
                    value={form.date_inhandsdate}
                    onChange={date => updateField('date_inhandsdate', date)}
                />
            </Col>
            <Col xs sm={6} padded>
                <Label>Billing</Label>
                <Row>
                    <Col xs pb={10}>
                        <ContactSelect
                            name="billing_contact_id"
                            options={contact_options}
                            value={form.billing_contact_id}
                            onChange={value => updateField('billing_contact_id', value)}
                            isBilling
                            components={{ MenuList: SelectMenuList }}
                            isDisabled={disableBillingContactSelect}
                        />
                    </Col>
                    <Col xs>
                        <AddressSelect
                            name="billing_address_id"
                            options={billing_address_options}
                            value={form.billing_address_id}
                            onChange={value => updateField('billing_address_id', value)}
                            isBilling
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs sm={6} padded>
                <Label>Shipping</Label>
                <Row>
                    <Col xs pb={10}>
                        <ContactSelect
                            name="shipping_contact_id"
                            options={contact_options}
                            value={form.shipping_contact_id}
                            onChange={value => updateField('shipping_contact_id', value)}
                            isShipping
                            isDisabled={form.sameAsBilling}
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                    <Col xs>
                        <AddressSelect
                            name="shipping_address_id"
                            options={shippingAddressOptions}
                            value={form.shipping_address_id}
                            onChange={value => updateField('shipping_address_id', value)}
                            isShipping
                            isDisabled={form.sameAsBilling}
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs padded end={1}>
                <LabeledCheckbox
                    label="Same as Billing"
                    checked={form.sameAsBilling}
                    onChange={() => handleSameAsBillingChange()}
                />
            </Col>
            <Col xs sm={6} padded>
                <PreviewAddress address_id={form.billing_address_id} />
            </Col>
            <Col xs sm={6} padded>
                <PreviewAddress address_id={form.shipping_address_id} />
            </Col>

            {showBillingOnlyAddressWarning && <BillingOnlyAddressWarning />}
        </>
    );
});

export default SalesOrderFields;
