import {
  ADD_PROOF_COMMENT_SUCCESS,
  ADD_PORTAL_COMMENT_SUCCESS,
  DELETE_PORTAL_COMMENT_SUCCESS,
} from '../actions/comment';

import { SYNC_MESSAGE_SUCCESS } from '../actions/message';

export const proofCommentReducer = (comments, action) => {
  const { comment } = action.payload || {};
  switch (action.type) {
    /* TODO: optimistic update
    case ADD_PROOF_COMMENT_REQUEST:
    case ADD_PROOF_COMMENT_FAILURE:
    */
    case ADD_PROOF_COMMENT_SUCCESS:
      return {
        ...comments,
        [comment.comment_id]: comment,
      };
  }
  return comments;
};

export const portalCommentReducer = (comments, action) => {
  const { comment } = action.payload || {};
  switch (action.type) {
    case ADD_PORTAL_COMMENT_SUCCESS:
      return {
        ...comments,
        [comment.comment_id]: comment,
      };
    case DELETE_PORTAL_COMMENT_SUCCESS:
      return Object.values(comments).filter(c => c.comment_id !== action.payload.comment_id).reduce((o, c) => { o[c.message_id || c.comment_id || c.presentation_comment_id] = c; return o; }, {});
    case SYNC_MESSAGE_SUCCESS:
      return action.payload.messages;
  }
  return comments;
};
