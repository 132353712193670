import React from 'react';
import { Col, DollarIcon, LabeledIconInput } from '@commonsku/styles';
import { onChangeNumber } from '../../../utils';
import { InHandsDateField } from '../Fields';

const PresalesFields = React.memo(({
    form,
    updateField,
}) => (
    <>
        <Col xs sm={6} padded>
            <LabeledIconInput
                labelOnTop
                required
                Icon={<DollarIcon style={{paddingLeft: 8, paddingRight: 0}} />}
                name="total_budget"
                label="Budget"
                value={form.total_budget.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                onChange={e => {
                    const val = onChangeNumber(e.target.value);
                    if (val === null) { return; }
                    updateField('total_budget', val);
                }}
            />
        </Col>
        <Col xs sm={6} padded>
            <InHandsDateField
                value={form.date_inhandsdate}
                onChange={date => updateField('date_inhandsdate', date)}
            />
        </Col>
    </>
));

export default PresalesFields;
