import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import * as actions from '../../actions/item';
import { createUpdate, createDeleteItemLocation } from '../../actions';
import {
  createArtworkPopup,
  createAddItemLocationPopup,
  createSelectDecorationProductPopup,
} from '../../actions/popup';

import {
  getOrder,
  getItemLocations,
  getLocation,
  getArtwork,
  getOrderByItemLocationId,
} from '../../selectors/items';

import { getImageSrc } from '../../utils';

import Field from './Field';
import Img from '../Img';
import SelectPromostandardsDecoration from './SelectPromostandardsDecoration';
import DecoratorSelector from '../decorate/DecoratorSelector';
import LocationDropdown from '../decorate/LocationDropdown';

const updateItemLocation = createUpdate('item-location', {
  request: actions.UPDATE_ITEM_LOCATION_REQUEST,
  success: actions.UPDATE_ITEM_LOCATION_SUCCESS,
  failure: actions.UPDATE_ITEM_LOCATION_FAILURE,
  failure_message: 'Unable to update item location',
});

const createAddArtwork =
  (item_location_id, decorations = null) =>
  (dispatch, getState) => {
    const order = getOrderByItemLocationId(getState(), { item_location_id });
    if (decorations && decorations.length > 0) {
      dispatch(
        createArtworkPopup(
          {
            ...decorations[0],
            ext_artwork_id: decorations[0].ext_decoration_id,
          },
          false,
          null,
          order.client_id,
          'CLIENT',
        ),
      );
    } else {
      const location = getLocation(getState(), { item_location_id });
      dispatch(
        createArtworkPopup(
          { item_id: location.item_id, item_location_id },
          false,
          null,
          order.client_id,
          'CLIENT',
        ),
      );
    }
  };

const createEditArtwork = (artwork_id, locked) => (dispatch, getState) => {
  const artwork = getArtwork(getState(), { artwork_id });
  const order = getOrderByItemLocationId(getState(), artwork);
  dispatch(
    createArtworkPopup(artwork, locked, null, order.client_id, 'CLIENT'),
  );
};

function LocationArtwork({ image, artwork_id, locked }) {
  const dispatch = useDispatch();

  function handleEditArtwork(e) {
    e.preventDefault();
    e.stopPropagation();
    dispatch(createEditArtwork(artwork_id, locked));
  }

  return (
    <Img
      style={{ height: '90px', margin: '4px 0' }}
      onClick={handleEditArtwork}
      src={getImageSrc(image)}
    />
  );
}

function Location({
  artworks,
  item_location_id,
  isPS,
  locked,
  decorations,
  item_location_title,
  index,
}) {
  const dispatch = useDispatch();
  const match = item_location_title.match(/^Location [0-9]*:: /);
  const lastIndex = item_location_title.lastIndexOf(':: ');
  const baseTitle = match
    ? item_location_title.substring(lastIndex + 3)
    : item_location_title;
  const canAddArtwork =
    !locked && (!isPS || decorations.every((d) => !!d.imprint_id));
  const isPSConfigurable = isPS && decorations.length > 0;

  const handleDeleteLocation = (e) => {
    e.preventDefault();
    dispatch(createDeleteItemLocation(item_location_id));
  };

  const handleAddArtwork = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const canAddArtwork =
      !locked &&
      (!isPS ||
        (decorations.every((d) => !!d.imprint_id) && artworks.length === 0));
    if (!canAddArtwork) {
      return;
    }
    dispatch(createAddArtwork(item_location_id, decorations));
  };

  return (
    <div className="add-artwork-box hover-x-container">
      <div className="location-details">
        {!locked && (
          <div className="hover-x" style={{ marginLeft: '-2rem' }}>
            <a onClick={handleDeleteLocation}>&times;</a>
          </div>
        )}
        <div className="icon-label-container">
          <div className="icon-label">{index + 1}</div>
          <Field
            id={item_location_id}
            value={baseTitle}
            placeholder={`Location ${index + 1}`}
            update={updateItemLocation}
            className="icon-field"
            field="item_location_title"
            dispatch={dispatch}
            disabled={locked || (isPS && isPSConfigurable)}
          />
        </div>
        {isPS &&
          decorations.map((d) => (
            <div key={d.item_decoration_id} className="flex-label">
              <SelectPromostandardsDecoration
                item_decoration_id={d.item_decoration_id}
              />
            </div>
          ))}
      </div>
      {artworks.length > 0 ? (
        <div
          className="add-artwork-btn"
          style={{ borderColor: '#02C0DA' }}
          onClick={canAddArtwork && handleAddArtwork}
        >
          {artworks.map((a) => (
            <LocationArtwork
              key={a.artwork_id}
              artwork_id={a.artwork_id}
              image={a.image}
              locked={locked}
              isPS={isPS}
            />
          ))}
        </div>
      ) : (
        <div
          className="add-artwork-btn"
          style={{ borderColor: '#02C0DA' }}
          onClick={handleAddArtwork}
        >
          {locked ? (
            'No Artwork'
          ) : isPS && !canAddArtwork ? (
            'Select imprint before adding artwork'
          ) : (
            <a className="link-buton">Add Artwork</a>
          )}
        </div>
      )}
    </div>
  );
}

const Artwork = ({
  item_id,
  itemLocations,
  locked,
  isPS,
  order,
  is3rdPartyDecorator,
  division_id,
}) => {
  const dispatch = useDispatch();

  const handleAddLocation = () => {
    dispatch(createAddItemLocationPopup(item_id));
  };

  const handleCopyDecoration = () => {
    dispatch(
      createSelectDecorationProductPopup(
        order.form_number,
        order.order_type,
        item_id,
      ),
    );
  };

  return (
    <>
      <div className="row">
        <div className="large-12 columns flex-hdr-row">
          <h2>Decoration Information</h2>
          <DecoratorSelector
            itemId={item_id}
            defaultIndex={is3rdPartyDecorator}
            isPS={isPS}
            style={{ marginLeft: '20px' }}
            itemLocationNum={itemLocations.length}
          />
        </div>

        <div className="row row-pb">
          <div className="add-artwork-container">
            {itemLocations.map((l, index) => (
              <Location
                key={l.item_location_id}
                index={index}
                {...l}
                dispatch={dispatch}
                locked={locked}
                isPS={isPS}
              />
            ))}
          </div>
        </div>
        <LocationDropdown
          item_id={item_id}
          locked={locked}
          isPS={isPS}
          division_id={division_id}
          is3rdPartyDecorator={is3rdPartyDecorator}
          onClickNewDecoration={handleAddLocation}
          onClickCopySameOrder={handleCopyDecoration}
        >
          <a style={{ paddingLeft: '1rem', fontWeight: 'bold' }}>
            + Decoration Location
          </a>
        </LocationDropdown>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const itemLocations = getItemLocations(state, ownProps);
  const order = getOrder(state, ownProps);
  return {
    order,
    itemLocations,
  };
};

export default connect(mapStateToProps)(Artwork);
