import React from 'react';
import { useSelector } from 'react-redux';
import {
    Col, Textarea,
} from '@commonsku/styles';
import { LabeledSelect } from '../../helpers';
import { mentionUserOptions } from '../../../selectors/users';
import PresalesFields from './PresalesFields';

const OpportunityFields = React.memo(({
    form,
    updateField,
}) => {
    const mention_user_options = useSelector(mentionUserOptions);
    return (
        <>
            <PresalesFields
                form={form}
                updateField={updateField}
            />
            {/* <Col xs padded>
                <LabeledSelect
                    inPopup
                    isClearable
                    label={'@ Mention'}
                    name="project_at_mention"
                    options={mention_user_options}
                    value={form.at_mention}
                    onChange={e => updateField('at_mention', e ? e.value : '')}
                />
            </Col>
            <Col xs padded>
                <Textarea
                    placeholder="Add a note (optional)"
                    value={form.note}
                    onChange={e => updateField('note', e.target.value)}
                />
            </Col> */}
        </>
    );
});

export default OpportunityFields;
