import { get, find, map, bindAll } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { getImageSrc, getDownloadSrc } from '../utils';
import { getStatusOptions, } from '../selectors/dropdowns';
import { addProofComment } from '../actions/comment';
import {
  updateProofStatus, replaceProof, deletePurchaseOrderProof
} from '../actions/purchase_order_proof';
import { createValidateConfirmationPopup } from '../actions/popup';
import ProofStatus from '../models/ProofStatus';
import Select from './Select';
import TextArea from './TextArea';
import ProofComment from './ProofComment';

class PurchaseOrderProof extends Component {

  constructor(props) {
    super(props);

    this.state = {
      commentText: '',
      status_id: get(props, 'proof.status.status_id'),
    };

    bindAll(this, ['handleProofStatusChange']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      status_id: get(nextProps, 'proof.status.status_id'),
    });
  }

  handleProofStatusChange(status_id) {
    const { proof, updateProofStatus, user_first_name, user_last_name } = this.props;

    updateProofStatus(proof, status_id, {
      commenter_name: `${user_first_name} ${user_last_name}`,
    });
    this.setState({ status_id, });
  }

  renderComments(comments) {
    return map(comments, (comment) => <ProofComment key={comment.comment_id} comment={comment}/>);
  }

  render() {
    const {
      proof, tenant_id, user_id, proof_statuses,
      addProofComment, replaceProof, deletePurchaseOrderProof,
      createValidateConfirmationPopup
    } = this.props;
    const { status_id, commentText } = this.state;
    const {
      file, purchase_order_proof_id, comments, status,
    } = proof;
    const { file_display_name, is_image } = file || {};

    return <div className="row purchase-order-proof-row" style={{ paddingTop: 20 }}>
      <div className="medium-4 large-2 columns" style={{
        background: `url(${is_image ? getImageSrc(file) : '/images/404.png'}) no-repeat center`,
        backgroundSize: 'cover',
        width: 120,
        height: 120 ,
      }}/>
      <div className="medium-8 large-5 columns">
        <div style={{ fontWeight: 'bold' }}>{ file_display_name }</div>
        <Select style={{ width: 200 }} options={proof_statuses.filter(({ key }) => {
          return [
            ProofStatus.ProofReceived, ProofStatus.PendingApproval,
            ProofStatus.ChangeRequested, ProofStatus.Approved, ProofStatus.ProofingComplete
          ].indexOf(key) > -1;
        })} value={status_id}
          change={this.handleProofStatusChange}
        />
        <div className="proof-controls">
          <a href={getDownloadSrc(file, tenant_id)} target="_blank">Download</a>
          {(status_id === ProofStatus.Approved || status_id === ProofStatus.ProofingComplete) ? null :
            <Dropzone style={{border: 'none'}} multiple={false}
              onDrop={(files) => {
                replaceProof(proof, files);
              }}
            >
              <a>Replace</a>
            </Dropzone>
          }
          {(status_id === ProofStatus.Approved || status_id === ProofStatus.ProofingComplete) ? null :
            <a onClick={() => {
              createValidateConfirmationPopup(deletePurchaseOrderProof, [proof]);
            }}>Delete</a>
          }
        </div>
      </div>
      <div className="medium-12 large-5 columns">
        <div className="row small-collapse">
          <TextArea className="small-12 columns"  value={commentText}
            style={{ padding: 8, marginTop: 20 }}
            minRows={1}
            placeholder="Comment on this proof"
            onFieldChange={(text) => {
              this.setState({ commentText: text });
            }}
          />
          {commentText && <button className="button tiny small-3 offset-9 columns" onClick={() => {
            addProofComment({
              parent_id: purchase_order_proof_id,
              commenter_id: user_id,
              text: commentText,
            });
            this.setState({ commentText: '' });
          }}>Comment</button>}
        </div>
        <div className="row small-collapse">
          <div className="small-12 columns">
            {this.renderComments(comments)}
          </div>
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    tenant_id, user_id, user_first_name, user_last_name,
  } = state.identity || {};
  return {
    tenant_id, user_id, user_first_name, user_last_name,
    proof_statuses: getStatusOptions(state, { order_type: 'PROOF' }),
  };
};

export default connect(mapStateToProps, {
  addProofComment, updateProofStatus, deletePurchaseOrderProof,
  createValidateConfirmationPopup, replaceProof
})(PurchaseOrderProof);
