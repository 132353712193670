import React from "react";
import { Csku, ImageIcon, Text } from "@commonsku/styles";
import Img from "../../../Img";

export default function BackgroundImage(props) {
  const {
    value,
    onClick,
    label = "Background Image",
    title = "Change Image",
    testIdPrefix = 'background'
  } = props;

  return (
    <>
      <Text
        as="p"
        style={{
          color: "var(--color-neutrals-90)",
          fontFamily: "var(--font-family-bold)",
          fontSize: 16,
          marginBottom: 4,
          fontWeight: 400,
        }}
      >
        {label}
      </Text>
      <Csku
        as="p"
        onClick={onClick}
        data-testid={`${testIdPrefix}_image_btn`}
        style={{
          color: "var(--color-primary1-main)",
          fontFamily: "var(--font-family-bold)",
          fontSize: 16,
          marginBottom: 0,
          padding: 16,
          borderRadius: 3,
          cursor: "pointer",
        }}
        sx={{
          background: "var(--color-neutrals-40)",
          ":hover": {
            background: "var(--color-neutrals-30)",
          },
        }}
      >
        {value ? (
          <Img src={value} />
        ) : (
          <>
            <ImageIcon style={{ verticalAlign: "middle" }} />
            <span style={{ paddingLeft: 7 }}>{title}</span>
          </>
        )}
      </Csku>
    </>
  );
}
