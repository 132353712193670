import React, { Component } from "react";

import { getImageSrc } from "../utils";
import Img from "./Img";
import PS from "./PromostandardsLabel";

class ItemArtwork extends Component {
  render() {
    const {
      artworks,
      isPS,
      onEditArtwork,
      onAddArtwork,
      locked,
      hasAssociatedCharges = false,
    } = this.props;
    const style = !artworks.length
      ? { minHeight: "72px", overflow: "auto", textAlign: "center" }
      : undefined;

    const canAddArtwork =
      onEditArtwork != null &&
      !locked &&
      (!isPS ||
        artworks.length === 0 ||
        artworks.reduce((o, a) => !a.ext_artwork_id || o, false));

    return (
      <td colSpan="2">
        <div
          className="artwork-container"
          style={style}
          onClick={(e) => {
            e.stopPropagation();
            if (canAddArtwork) {
              onEditArtwork();
            }
          }}
        >
          {artworks.map((a) => (
            <div key={a.artwork_id} style={{ position: "relative" }}>
              {!!a.ext_artwork_id && (
                <PS
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "scale(0.75, 0.75)",
                  }}
                />
              )}
              <Img
                src={getImageSrc(a.image)}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onEditArtwork != null) {
                    onEditArtwork(a, locked);
                  }
                }}
              />
            </div>
          ))}
          {!artworks.length && !locked ? "Add Artwork" : null}
        </div>
      </td>
    );
  }
}

export default ItemArtwork;
