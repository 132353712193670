import _ from "lodash";
import React from "react";
import {
  colors,
  NavConnectIcon,
  NavFinanceIcon,
  NavManagementIcon,
  NavProdIcon,
  NavSalesIcon,
  NavResourcesIcon,
  NavReportsIcon,
} from "@commonsku/styles";
import { getNavItems } from "./csku_menu_utils";
import BaseCskuMenu from "./BaseCskuMenu";
import { getIdentityUtils } from "../utils";

const iconsProps = {
  style: {
    verticalAlign: "middle",
    marginRight: 5,
  },
};

const filledInIcon = {
  connect: <NavConnectIcon filled {...iconsProps} />,
  sales: <NavSalesIcon filled {...iconsProps} />,
  production: <NavProdIcon filled {...iconsProps} />,
  finance: <NavFinanceIcon filled {...iconsProps} />,
  management: <NavManagementIcon filled {...iconsProps} />,
  reports: <NavReportsIcon filled {...iconsProps} />,
  learn: <NavResourcesIcon filled {...iconsProps} />,
};

class CskuMenuV2 extends BaseCskuMenu {
  constructor(props) {
    super(props);

    this.state = {
      category: "connect",
      menuClicked: false,
    };

    this.handleClickSubMenu = this.handleClickSubMenu.bind(this);
  }

  routeMap = {
    distributor: "distributors",
    product: "products",
    client: "clients",
  };

  routes = [
    "home",
    "dashboard",
    "discover",
    "tool",
    "promostandards",
    "admin",
    "trial",
    "suppliers_production",
    "production_dashboard",
    "production_report",
    "distributor",
    "clients",
    "client",
    "suppliers",
    "products",
    "product",
    "suspended",
    "set_up",
    "collections",
    "shops",
    "whatsnew",
    "projects",
    "promostandards_supplier",
    "marketing",
    "blog",
    "operation_billing",
    "operation_invoicing",
    "finance_billing",
    "finance_invoicing",
    "operation_wip",
    "sales_analytics",
    "finance_analytics",
    "management_analytics",
    "sales_dashboard",
    "finance_dashboard",
    "management_dashboard",
    "support",
    "report",
  ];

  routesCategories = [
    {
      name: "connect",
      title: "Connect",
      icon: <NavConnectIcon {...iconsProps} />,
      type: "sub-menu",
      routes: ["home", "suppliers", "collections", "products", "discover"],
      disabled: this.props.lockedOut,
      show: true,
    },
    {
      name: "sales",
      title: "Sales",
      icon: <NavSalesIcon {...iconsProps} />,
      type: "sub-menu",
      routes: [
        "sales_dashboard",
        "projects",
        "clients",
        "commissions",
        "shops",
        "sales_analytics",
      ],
      disabled: this.props.lockedOut || !this.userCanViewMenu("SALES"),
      show: true,
    },
    {
      name: "production",
      title: "Production",
      icon: <NavProdIcon {...iconsProps} />,
      type: "sub-menu",
      routes: ["production_dashboard", "production_report"],
      disabled: this.props.lockedOut || !this.userCanViewMenu("PRODUCTION"),
      show: true,
    },
    {
      name: "finance",
      title: "Finance",
      icon: <NavFinanceIcon {...iconsProps} />,
      type: "sub-menu",
      routes: [
        "finance_dashboard",
        "operation_billing",
        "operation_invoicing",
        "finance_billing",
        "finance_invoicing",
        "operation_wip",
        "finance_analytics",
      ],
      disabled: this.props.lockedOut || !this.userCanViewMenu("FINANCE"),
      show: true,
    },
    {
      name: "management",
      title: "Management",
      icon: <NavManagementIcon {...iconsProps} />,
      type: "sub-menu",
      routes: ["management_dashboard", "admin", "management_analytics"],
      disabled: this.props.lockedOut || !this.userCanViewMenu("MANAGEMENT"),
      show: this.hasCapabilities(['HAS-MANAGEMENT-SECTION']),
    },
    {
      name: "reports",
      title: "Reports",
      icon: <NavReportsIcon {...iconsProps} />,
      type: "link",
      disabled: this.props.lockedOut || !this.hasCapabilities(['FEATURE-FULL', 'FEATURE-ESSENTIAL', 'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'], true),
      show: true,
    },
    {
      name: "learn",
      title: "Resources",
      icon: <NavResourcesIcon {...iconsProps} />,
      type: "link",
      routes: ["skummunity", "support", "help_articles", "university", "blog"],
      disabled: this.props.lockedOut,
      show: true,
    },
  ];

  hasCapabilities(capabilities, any = false) {
    const { hasCapabilities } = getIdentityUtils(this.props.identity);
    return hasCapabilities(capabilities, any);
  }

  userCanViewMenu(menu) {
    return this.hasCapabilities("VIEW-" + menu + "-MENU");
  }

  menuDisplayControlByUserFlag(flag) {
    const { hasUserFlags } = getIdentityUtils(this.props.identity);
    return hasUserFlags(flag);
  }

  getUserRole() {
    return this.props.identity.user_role;
  }

  componentDidMount() {
    const curRoute = this.findRoute(this.props.scriptName);
    const found = curRoute
      ? curRoute === "report"
        ? this.routesCategories.filter(
            (v) => !v.disabled && v.name === "reports"
          )
        : this.routesCategories.filter(
            (v) =>
              !v.disabled &&
              v.name !== "reports" &&
              (v.routes.indexOf(curRoute) !== -1 ||
                v.routes.indexOf(this.routeMap[curRoute]) !== -1)
          )
      : [];

    if (found.length) {
      this.setState({ category: found[0].name });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.toggleMainMenu === false &&
      this.state.menuClicked === true
    ) {
      this.setState({ category: "", menuClicked: false });
    }
  }

  handleClickSubMenu(name, items) {
    if (this.state.category === name) {
      this.setState({ category: "", menuClicked: false });
    } else {
      this.setState({ category: name, menuClicked: true });
    }
  }

  renderCollapsed({ curRoute, isMobile, items, ulProps, toggleMainMenu }) {
    const { menuClicked, category } = this.state;

    return (
      <ul className="csku-menu-v2 vertical menu main-menu-v2-collapsed">
        {this.routesCategories
          .filter((rc) => rc.show)
          .map((c, j) => {
            const show = category === c.name && toggleMainMenu && menuClicked;
            const icon = category === c.name ? filledInIcon[c.name] : c.icon;
            const params = {
              category: c,
              className: "",
              curRoute,
              isMobile,
              items,
              ulProps: ulProps,
              linkClassName: "menu-link-v2-collapsed",
              show,
            };
            return (
              <li
                key={"menu-items-title-" + j}
                className={`menu-link-wrapper-v2-collapsed ${
                  c.disabled ? " disabled " : ""
                }`}
                onClick={(e) => {
                  if (c.name === "reports") {
                    window.location.href = "/report";
                  } else {
                    this.handleClickSubMenu(c.name, items);
                  }
                }}
              >
                <a
                  href="#"
                  className="menu-link-v2-collapsed"
                  style={{
                    ...(show ? { background: colors.primary20 } : {}),
                    userSelect: "none",
                  }}
                >
                  <span className="menu-link-v2-collapsed-icon">{icon}</span>
                  <span className="menu-link-v2-collapsed-text">{c.title}</span>
                </a>
                {c.name !== "reports" && this.renderCategoryMenuItems(params)}
              </li>
            );
          })}
      </ul>
    );
  }

  renderCategoryMenuItems({
    ulProps,
    isMobile,
    className,
    items,
    curRoute,
    category,
    show = true,
    linkClassName = "",
    hideLinkText = false,
  }) {
    return (
      <ul
        className={
          "vertical menu sub-menu " +
          className +
          (this.state.category === category.name && show
            ? " is-open "
            : " is-closed ") +
          (isMobile ? " mobile-menu " : "")
        }
        {...ulProps}
        style={{ ...(ulProps.style || {}) }}
        key="menu-v2-CategoryMenuItems"
      >
        {items
          .filter((v) => v.show && category.routes?.indexOf(v.route) !== -1)
          .sortBy([(v) => category.routes?.indexOf(v.route)])
          .map(({ liProps, to, title, route, children }, i) =>
            this.renderRoute({
              index: i,
              curRoute,
              liProps,
              to,
              title,
              route,
              linkClassName,
              children: !hideLinkText ? children : null,
            })
          )
          .value()}
      </ul>
    );
  }

  render() {
    const {
      identity,
      scriptName,
      className = "",
      isMobile = false,
      collapsed = false,
      toggleMainMenu = false,
      ...props
    } = this.props;
    const curRoute = this.findRoute(scriptName);
    const items = getNavItems({ identity, v2: true });
    const ulProps = _.omit(props, ["dispatch"]);

    return collapsed ? (
      this.renderCollapsed({
        className,
        curRoute,
        isMobile,
        items,
        ulProps,
        toggleMainMenu,
      })
    ) : (
      <div className="csku-menu-v2">
        {this.routesCategories
          .filter((rc) => rc.show)
          .map((c, j) => {
            const icon =
              this.state.category === c.name ? filledInIcon[c.name] : c.icon;
            return (
              <ul
                key={"menu-item-parent-" + j}
                className={
                  "vertical menu parent-menu " +
                  className +
                  (this.state.category === c.name ? " is-open" : "") +
                  (c.disabled ? " disabled " : "")
                }
                {...ulProps}
              >
                <li
                  key={"menu-items-title-" + j}
                  onClick={(e) => {
                    if (c.name === "reports") {
                      window.location.href = "/report";
                    } else {
                      this.handleClickSubMenu(c.name, items);
                    }
                  }}
                >
                  <a style={{ userSelect: "none" }}>
                    {icon} {c.title}
                  </a>
                </li>
                {c.name !== "reports" && (
                  <li key={"menu-items-" + j}>
                    {this.renderCategoryMenuItems({
                      category: c,
                      className,
                      curRoute,
                      isMobile,
                      items,
                      ulProps,
                    })}
                  </li>
                )}
              </ul>
            );
          })}
      </div>
    );
  }
}

export default CskuMenuV2;
