import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, SkubotSpinner, Popup, MailIcon, Typography, XIcon, AlertNotification, Button } from '@commonsku/styles';

import { download, oauth } from '../../utils';
import { createAddTemp, createDeleteTemp } from '../../actions/temp';
import { ExportReportTask } from '../../interfaces/ExportReportTask';

type ExportViewerPopupProps = {
  exportTask?: ExportReportTask;
  onClose: () => void;
  file?: Blob;
};
export function ExportViewerPopup({ exportTask, file, onClose }: Readonly<ExportViewerPopupProps>) {
  const [popupTitle, setPopupTitle] = useState('We are preparing your export');

  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    onClose();
    exportTask?.export_report_task_id && dispatch(createDeleteTemp('export_task_' + exportTask?.export_report_task_id));
  }, [onClose, exportTask?.export_report_task_id]);

  return (
    <Popup
      onClose={handleClose}
      className='export-viewer-popup'
      style={{
        maxWidth: '650px',
        maxHeight: '370px',
      }}
      header={<Row style={{ paddingBottom: '16px' }}>
        <Col xs={10} style={{ alignContent: 'center', textAlign: 'left' }}>
          <MailIcon size='huge' color='var(--color-neutrals-100)' style={{ verticalAlign: 'middle', }} />
          <Typography.Text as="span" bold noBottom
            color='var(--color-neutrals-100)'
            fontSize='24px'
            style={{ verticalAlign: 'middle', paddingLeft: '16px' }}
          >{popupTitle}</Typography.Text>
        </Col>
        <Col xs={2} style={{ alignContent: 'center', textAlign: 'right' }}>
          <XIcon size='large' onClick={handleClose} />
        </Col>
      </Row>}
    >
      <ExportTaskRow
        exportTask={exportTask}
        file={file}
        setPopupTitle={setPopupTitle}
        onClose={handleClose}
      />
    </Popup>
  );
}

type ExportTaskRowProps = {
  exportTask?: ExportReportTask;
  setPopupTitle: React.Dispatch<React.SetStateAction<string>>;
  onClose?: () => void;
  file?: 'Unable to generate PDF' | Blob | null;
};

export function ExportTaskRow({ exportTask, file, setPopupTitle, onClose }: Readonly<ExportTaskRowProps>) {
  const dispatch = useDispatch();
  const [data, setData] = useState<ExportReportTask>(exportTask || {
    export_report_task_id: '',
    company_id: '',
    request_data: null,
    result: null,
    date_created: null,
  });

  const getResult = useCallback(async () => {
    const id = exportTask?.export_report_task_id;
    if (!id || data.result) return;
    const result = await oauth('GET', `export-report-task/${id}`, {});
    if (result?.json?.export_report_task?.result ||
        result?.json?.export_report_task?.date_finished ||
        result?.json?.export_report_task?.error
    ) {
      setData(result.json.export_report_task);
      setPopupTitle(result?.json?.export_report_task?.result
        ? 'Check your inbox!'
        : 'Could not process export request'
      );
      dispatch(createDeleteTemp('export_task_' + id));
    } else {
      setData(exportTask);
    }
  }, [data.result, dispatch, exportTask?.export_report_task_id]);

  useEffect(() => {
    const id = exportTask?.export_report_task_id;
    let intervalId: ReturnType<typeof setInterval>;
    if (id) {
      dispatch(createAddTemp('export_task_' + id, 1));
      getResult();
      intervalId = setInterval(getResult, 10 * 1000);
    }
    return () => {
      if (id) {
        intervalId && clearInterval(intervalId);
        dispatch(createDeleteTemp('export_task_' + id));
      }
    };
  }, [dispatch, getResult, exportTask]);

  useEffect(() => {
    if (!exportTask?.export_report_task_id && file) {
      setPopupTitle(file === 'Unable to generate PDF' ? file : 'Your export is ready to download');
    }
  }, [exportTask, file]);

  const message = useMemo(() => {
    if (!data?.export_report_task_id) {
      if (file) {
        return file === 'Unable to generate PDF' ? file : 'Your bulk export is complete.';
      }
      return <>
        Your export is in progress. <strong>Please do not close this page</strong> until the process is complete. Once ready, your file will be available for download.
      </>;
    }
    if (data?.date_finished) {
      return 'Your bulk export is complete.';
    }
    if (data?.error || data?.date_failed) {
      return 'There was an issue processing your bulk export request. Please try again later or contact support if the problem persists.';
    }
    return <>
      Your export is being processed and may take a few moments. Since there are over 50 checkouts, <strong>the file will be sent to your email once complete.</strong>
    </>;
  }, [data, file]);

  const type = useMemo(() => {
    if (data?.date_finished || file) {
      return file === 'Unable to generate PDF' ? 'error' : 'success';
    }
    if (data?.error || data?.date_failed) {
      return 'error';
    }
    if (data?.export_report_task_id) {
      return 'warn';
    }
    return 'neutral';
  }, [data, file]);

  const onDownloadFile = () => {
    const file_name = 'export_partials.pdf';
    if (file && file !== 'Unable to generate PDF') {
      const file_content = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.setAttribute('download', file_name);
      a.setAttribute('href', file_content);
      a.addEventListener('click', () => {
        onClose();
      });
      a.click();
    } else if (data.result) {
      download('/artifact/'+data.export_report_task_id, file_name).then(() => {
        onClose();
      });
    }
  }

  return (
    <Row>
      <Col xs>
        <AlertNotification alertType={type}>
          <Typography.Text style={{ color: 'inherit' }}>{message}</Typography.Text>
        </AlertNotification>
      </Col>
      {(type === 'neutral' || type === 'warn') && <Col xs style={{ marginTop: '24px', textAlign: 'center', alignContent: 'center' }}>
        <SkubotSpinner size='medium' style={{width: '100%'}} />
      </Col>}
      {((file && file !== 'Unable to generate PDF') || data.result) && <Col xs style={{
        textAlign: 'center',
        alignContent: 'center',
        top: 'auto',
        left: 'auto',
        bottom: '8px',
        right: '8px',
        position: 'absolute',
      }}>
        <Button size='large' onClick={onDownloadFile}>
          Download & Close
        </Button>
      </Col>}
    </Row>
  );
}
