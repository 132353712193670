import moment from 'moment';
import React from 'react';

import LiveUpdate from '../LiveUpdate';

moment.updateLocale('en', {
  relativeTime : {
      m:  "%d minute",
      h:  "%d hour",
      d:  "%d day",
      M:  "%d month",
      y:  "%d year",
  }
});

export const FromNow = ({ value, interval }) => {
  const time = moment(value);
  if (!interval) {
    if (time.diff(moment(), 'hours') > 24) {
      interval = 5 * 60 * 1000;
    } else {
      interval = 5 * 1000;
    }
  }
  return <LiveUpdate interval={interval} render={() => time.fromNow()}/>;
};

export const Calendar = ({ value, interval=60 * 1000 }) => {
  return <LiveUpdate interval={interval} render={() => moment(value).calendar(null, {
    sameElse: 'MMM D, YYYY [at] hh:mmA'
  })}/>;
};