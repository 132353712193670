import React, { Component } from 'react';
import DropdownMenu, { MenuOptions, MenuTrigger } from './DropdownMenu';
import PS from './PromostandardsLabel';
import { getAvailableCharges, getUomValues } from '../promostandard_utils';
import { isValidExtLocationId } from '../helpers/ps_locations';

const ITEM_LOCATION_BADGE_STYLE = {
  background: '#394e59',
  color: 'white',
  fontWeight: 'bold',
  padding: '0.5rem',
  borderRadius: '3px',
  marginRight: '0.5rem'
};

const ItemLocationBadge = ({ index }) => (
  <span style={ITEM_LOCATION_BADGE_STYLE}>{index + 1}</span>
);

const ItemLocationLabel = ({ item_location }) => (
  <span>
    <ItemLocationBadge index={item_location.index} />
    <span>{item_location.item_location_title}</span>
  </span>
);

class PresentationItemChargeMenu extends Component {

  getChargeOptions(item_location) {
    const {
      charge_type,
      ps_locations,
      item_decorations,
      item_costs,
			quirks,
			division_id,
      onAdd,
    } = this.props;
    const ps_location = ps_locations.filter(l => l.ps_location_id === parseInt(item_location.ext_location_id, 10))[0];
    if (!ps_location) {
      return [];
    }
    const onAddCharge = (item_decoration_id, charge) => onAdd(
      item_location.item_id,
      item_location.item_location_id,
      'SETUP' === charge_type ? 1 : null,
      'RUN' === charge_type,
      charge.description,
      item_decoration_id,
      charge.ps_charge_id
    );
    const ext_decoration_ids = item_decorations.filter(
      id => id.item_location_id === item_location.item_location_id
    ).map(
      id => parseInt(id.ext_decoration_id, 10)
    );
    const ps_decoration = ps_location.decorations.filter(d => ext_decoration_ids.includes(d.ps_decoration_id))[0];
    if (!ps_decoration) {
      return [];
    }
    const ext_cost_ids = item_costs.filter(
      ic => ic.item_location_id === item_location.item_location_id
    ).map(
      ic => parseInt(ic.ext_cost_id, 10)
    );

    const chargeOptions = [];
		Object.values(ps_locations).forEach(
      loc => {
        if (loc.ps_location_id !== parseInt(item_location.ext_location_id, 10)) {
					return;
				}
				loc.decorations.forEach(
					dec => {
						if (!ext_decoration_ids.includes(dec.ps_decoration_id)) {
							return;
						}
						const charges = getAvailableCharges(
							dec.charges ?? [],
							quirks,
							division_id
						)(
							charge_type,
							item_location.display_order,
							getUomValues(item_decorations.filter(id => id.item_location_id === item_location.item_location_id)[0]),
							ext_cost_ids
						);
						charges.forEach(
							c => {
  							chargeOptions.push({
  								key: c.ps_charge_id,
  								value: !!c.is_divider ? c.description : <span><PS />{c.description || c.name}</span>,
  								divider: !!c.is_divider,
  								onClick: !c.is_divider ? () => {
                    this.menu.showDropdown(false);
                    if (this.submenu) {
                      this.submenu.showDropdown(false);
										}
                    item_decorations.filter(id => id.item_location_id === item_location.item_location_id).forEach(id => {
                      onAddCharge(id.item_decoration_id, c);
                    });
  								} : null
  							});
							}
						);
					}
			  );
			}
    );

    return chargeOptions;
  }

  render() {
    const { children, item_locations } = this.props;
    const possibleLocations = item_locations.filter(il => isValidExtLocationId(il.ext_location_id));

    const options = possibleLocations.length === 1 ?
      this.getChargeOptions(possibleLocations[0])
      : item_locations.filter(il => isValidExtLocationId(il.ext_location_id)).map(
      il => {
        const chargeOptions = this.getChargeOptions(il);
        if (!chargeOptions.length) {
          return false;
        }
        return {
          key: il.item_location_id,
          value: (
            <DropdownMenu ref={ref => this.submenu = ref}>
              <MenuTrigger style={{ textAlign: 'left' }}>
                <ItemLocationLabel item_location={il} />
              </MenuTrigger>
              <MenuOptions options={chargeOptions} style={{ maxHeight: '300px', overflowY: 'auto', textAlign: 'left' }} />
            </DropdownMenu>
          )
        };
      }
    ).filter(o => o);

    if (!options.length) {
      return null;
    }

    return (
      <DropdownMenu ref={ref => this.menu = ref} options={options}>
        <MenuTrigger>
          {children}
        </MenuTrigger>
      </DropdownMenu>
    );
  }
}

export default PresentationItemChargeMenu;
