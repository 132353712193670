import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { oauth } from '../utils';
import _ from 'lodash';
import NewProjectPopup from './project/NewProjectPopup';

const AutosuggestHighlightMatch = require('autosuggest-highlight/match');
const AutosuggestHighlightParse = require('autosuggest-highlight/parse');

const NEW_PROJECT_BUTTON_STYLE = {
  width: '100%',
  padding: '0.7rem 1.3rem 0.7rem 1.3rem',
  textAlign: 'center',
  backgroundColor: '#F1FCFE',
  color: '#5ca2b6',
  lineHeight: '1.2rem',
  fontWeight: 'bold'
};

function getSuggestionHref(suggestion) {
  let href;
  let order_type_param;
  let url_param;

  if (suggestion.form_number && suggestion.job_number) {
    if (!suggestion.purchase_order_id) {
      order_type_param = (suggestion.order_type == 'SALES ORDER')
        ? 'sales-order'
        : suggestion.order_type.toLowerCase();

      url_param = (suggestion.order_type != 'OPPORTUNITY')
        ? '/' + order_type_param + '/' + suggestion.form_number
        : '';

      href = '/project/' + suggestion.job_number + url_param;
    } else {
      href = '/project/' + suggestion.job_number + '/production';
    }

  } else if (suggestion.job_number && suggestion.job_name) {
    href = '/project/' + suggestion.job_number;
  } else if (suggestion.client_id) {
    href = '/client.php?id=' + suggestion.client_id;
  } else {
    href = '/global_search.php' + ((suggestion.value) ? ('?str=' + suggestion.value) : '');
  }

  return href;
}

function renderDate(suggestion) {
  if (!suggestion.client_id) {
    return (
      <span className="date">{suggestion.date_created.split(' ')[0]}</span>
    );
  }
}

function renderTitleHead(suggestion, queryisnum) {
  if (suggestion.form_number && !suggestion.purchase_order_id) {
    return (
      ' - Project #' + suggestion.job_number + ' - ' + suggestion.job_name
      + ' - ' + _.capitalize(suggestion.order_type) + ' #'
    );
  } else if (suggestion.job_number && suggestion.job_name && !suggestion.purchase_order_id) {
    if (queryisnum) {
      return (
        ' - Project #'
      );
    } else {
      return (
        ' - Project #' + suggestion.job_number + ' - '
      );
    }
  }
}

function renderTitleTail(suggestion, queryisnum) {
  if (suggestion.job_number && suggestion.job_name && queryisnum && !suggestion.form_number) {
    return (
      ' - ' + suggestion.job_name
    );
  }
}

function renderClientName(suggestion) {
  if (suggestion.form_number || (suggestion.job_number && suggestion.job_name)) {
    return (
      <span className="bold">{suggestion.client_name}</span>
    );
  }
}

function highlightQuery(suggestion, parts) {
  return (
    <span className={suggestion.client_id ? "bold" : ''}>
      {
        parts.map((part, index) => {
          const className = part.highlight ? 'highlight' : null;
          return (
            <span className={className} key={index}>{part.text}</span>
          );
        })
      }
    </span>
  );
}

function SuggestionItem(props) {
  const {
    suggestion, parts, queryisnum
  } = props;
  const href = getSuggestionHref(suggestion);

  return (
    <a href={href}>
      <div className="row suggestion-item">
        <div className="small-10 columns no-padd">
          {renderClientName(suggestion)}
          {renderTitleHead(suggestion, queryisnum)}
          {highlightQuery(suggestion, parts)}
          {renderTitleTail(suggestion, queryisnum)}
        </div>
        <div className="small-2 columns no-padd">
          {renderDate(suggestion)}
        </div>
      </div>
    </a>
  );
}

class GlobalSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      href: '',
      suggestions: [],
      recentProjects: [],
      show_suggestions: true,
      show_new_project_popup: false,
    };

    this.unmounted = React.createRef(false);

    _.bindAll(this, ['onSuggestionsClearRequested', 'onSuggestionsFetchRequested', 'onMouseLeave', 'onMouseEnter', 'renderSuggestion']);
    this.onSuggestionsFetchRequested = _.debounce(this.onSuggestionsFetchRequested, 400);
    this.suggestionsFetchRequestCount = 0;
  }

  componentWillUnmount() {
    this.unmounted.current = true;
  }

  loadRecentProjects() {
    if (this.state.recentProjectsLoaded) {
      return false;
    }
    oauth('GET', 'global-search/recent-projects-toc', {}).then(response => {
      let { results } = response.json || {};
      if (this.unmounted.current) return;
      this.setState({
        recentProjects: results || [],
        recentProjectsLoaded: true,
      });
    });
  }

  getSuggestionValue(suggestion) {
    if (suggestion.form_number && suggestion.job_number && !suggestion.purchase_order_id) {
      return (
        suggestion.client_name + ' - Project #' + suggestion.job_number + ' ' + suggestion.job_name
        + ' - ' + _.capitalize(suggestion.order_type) + ' #' + suggestion.form_number
      );
    } else if (suggestion.job_name) {
      return (
        suggestion.client_name + ' - Project #' + suggestion.job_number + ' ' + suggestion.job_name
      );
    } else if (suggestion.po_name || suggestion.form_number) {
      const po_name = (suggestion.po_name) ? suggestion.po_name : 'PO#' + suggestion.form_number;
      return po_name;
    } else if (suggestion.client_id) {
      return suggestion.client_name;
    } else {
      return suggestion.value;
    }
  }

  renderSuggestion(suggestion, { query }) {
    let matches = '';
    let parts = '';
    const href = getSuggestionHref(suggestion);

    if (suggestion.form_number && suggestion.job_number && !suggestion.purchase_order_id) {
      matches = AutosuggestHighlightMatch(suggestion.form_number, query);
      parts = AutosuggestHighlightParse(suggestion.form_number, matches);
      return <SuggestionItem suggestion={suggestion} parts={parts} />;
    } else if (suggestion.job_number && suggestion.job_name) {
      if (!isNaN(query)) {
        const queryisnum = true;
        matches = AutosuggestHighlightMatch(suggestion.job_number, query);
        parts = AutosuggestHighlightParse(suggestion.job_number, matches);
        return <SuggestionItem suggestion={suggestion} parts={parts} queryisnum={queryisnum} />;
      } else {
        matches = AutosuggestHighlightMatch(suggestion.job_name, query);
        parts = AutosuggestHighlightParse(suggestion.job_name, matches);
        return <SuggestionItem suggestion={suggestion} parts={parts} />;
      }
    } else if (suggestion.client_id) {
      matches = AutosuggestHighlightMatch(suggestion.client_name, query);
      parts = AutosuggestHighlightParse(suggestion.client_name, matches);
      return <SuggestionItem suggestion={suggestion} parts={parts} />;
    } else if (suggestion.purchase_order_id) {
      const po_name = (suggestion.po_name) ? suggestion.po_name : 'PO#' + suggestion.form_number;
      matches = AutosuggestHighlightMatch(po_name, query);
      parts = AutosuggestHighlightParse(po_name, matches);
      return <SuggestionItem suggestion={suggestion} parts={parts} />;
    } else if (suggestion.text === GlobalSearch.NewProjectButtonText) {
      return <div onClick={() => this.setState({ show_new_project_popup: true })} style={NEW_PROJECT_BUTTON_STYLE}>New Project</div>
    } else if (suggestion.text) {
      return (
        <a href={href}>
          <div className="suggestion-item bold see-all">
            {suggestion.text}
          </div>
        </a>
      );
    }
  }

  renderSectionTitle(section) {
    if (section.category) {
      return (
        <div className="bold">{section.category}</div>
      );
    }
  }

  getSectionSuggestions(section) {
    return (section && section.data) ? section.data : [];
  }

  handleKeyDown = (e, string, onSearch, search_type = 'new_search') => {
    if (e.key === 'Enter') {
      if (search_type == 'old_search') {
        onSearch(string);
      } else if (this.state.value.trim()) {
        const old_search_href = '/global_search.php' + ((this.state.value) ? ('?str=' + this.state.value) : '');
        window.location.href = (this.state.href) ? this.state.href : old_search_href;
      }
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      selectedProject: null
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const search_query = (value || '').trim().toLowerCase();

    if (search_query.length < 3) {
      return;
    }
    let suggestionsFetchRequestCount = this.suggestionsFetchRequestCount += 1;
    oauth('GET', 'global-search/search-project-toc', { value }).then(response => {
      if (suggestionsFetchRequestCount < this.suggestionsFetchRequestCount) {
        return;
      }
      let results = response.json.results;
      let see_all = { category: '', data: [{ text: 'See All Results', value: value }] };
      results.push(see_all);
      this.setState({
        suggestions: (results) ? results : []
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onMouseLeave() {
    if (this.autosuggest.props.shouldRenderSuggestions(this.state.value) && this.autosuggest.input !== document.activeElement) {
      setTimeout(() => { this.setState({ show_suggestions: false }); }, 250);
    }
  }

  onMouseEnter() {
    this.setState({ show_suggestions: true });
  }

  render() {
    const { value, suggestions, recentProjects } = this.state;
    const inputProps = {
      value,
      onChange: this.onChange,
      placeholder: "Enter project's name, client's name, project's number or PO# ",
      onKeyDown: this.handleKeyDown
    };

    return (
      <div>
        <div className="global-search-box" onMouseLeave={e => { e.preventDefault(); this.onMouseLeave(); }} onMouseEnter={e => { e.preventDefault(); this.onMouseEnter(); }}>
          <i className="fi-magnifying-glass search-icon"></i>
          <Autosuggest
            ref={(autosuggest) => { this.autosuggest = autosuggest; }}
            multiSection={true}
            suggestions={!value ? [
              { category: 'Recent', data: recentProjects },
              { category: '', data: [{ text: GlobalSearch.NewProjectButtonText, value: '' }] }
            ] : suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionSelected={(event, { suggestion }) => {
              const link = getSuggestionHref(suggestion);
              this.setState({ selectedProject: suggestion, href: link });
            }}
            shouldRenderSuggestions={(value) => {
              if (this.state.show_suggestions) {
                let length = (value || '').trim().length;
                if (length == 0) {
                  this.loadRecentProjects();
                  return true;
                } else if (length > 2) {
                  return true;
                }
              }

              return false;
            }}
            renderSuggestion={this.renderSuggestion}
            renderSectionTitle={this.renderSectionTitle}
            getSectionSuggestions={this.getSectionSuggestions}
            inputProps={inputProps}
          />
        </div>
        {this.state.show_new_project_popup && <NewProjectPopup
          onClose={() => {
            this.setState({ show_new_project_popup: false });
          }} />}
      </div>
    );
  }
}

GlobalSearch.NewProjectButtonText = 'New Project';

export default GlobalSearch;