import { oauth } from '../utils';

export const UPDATE_DISPLAY_ITEM_SUMMARY_VIEW = 'UPDATE_DISPLAY_ITEM_SUMMARY_VIEW';
export const UPDATE_DISPLAY_ITEM_SUMMARY_SHOW_ACTIVE = 'UPDATE_DISPLAY_ITEM_SUMMARY_SHOW_ACTIVE';
export const UPDATE_DISPLAY_ITEM_SUMMARY_TAB = 'UPDATE_DISPLAY_ITEM_SUMMARY_TAB';
export const UPDATE_FEEDBACK_EMAILS = 'UPDATE_FEEDBACK_EMAILS';
export const UPDATE_COLLAPSE_ORDER_INFO = 'UPDATE_COLLAPSE_ORDER_INFO';
export const UPDATE_DISPLAY_COMMENTING = 'UPDATE_DISPLAY_COMMENTING';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';

export const LOAD_TEAM_USER_IDS = 'LOAD_TEAM_USER_IDS';

export const createUpdateDisplayItemSummaryView = (order_id, view_type) => ({
  type: UPDATE_DISPLAY_ITEM_SUMMARY_VIEW,
  payload: {
    order_id,
    view_type
  }
});

export const createUpdateDisplayItemSummaryShowActive = (order_id, show_active) => ({
  type: UPDATE_DISPLAY_ITEM_SUMMARY_SHOW_ACTIVE,
  payload: {
    order_id,
    show_active
  }
});

export const createUpdateDisplayItemSummaryTab = (order_id, tab) => ({
  type: UPDATE_DISPLAY_ITEM_SUMMARY_TAB,
  payload: {
    order_id,
    tab
  }
});

export const createUpdateFeedbackEmails = (to_contact_id, to_extra) => ({
  type: UPDATE_FEEDBACK_EMAILS,
  payload: {
    to_contact_id,
    to_extra
  }
});

export const createUpdateCollapseOrderInfo = (order_id, collapsed) => ({
  type: UPDATE_COLLAPSE_ORDER_INFO,
  payload: {
    order_id,
    collapsed
  }
});

export const createUpdateDisplayCommenting = commenting => ({
  type: UPDATE_DISPLAY_COMMENTING,
  payload: {
    commenting
  }
});

export const createFinalizedBanner = application_setting => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: {
    application_setting
  }
});

export const createFetchBanner = () => dispatch => {
  oauth('GET', 'application-setting', {}).then(
    ({ json }) => {
      dispatch(createFinalizedBanner(json.application_setting));
    }
  );
};

export const createFinalizedLoadTeamUserIds = team_user_ids => ({
  type: LOAD_TEAM_USER_IDS,
  payload: {
    team_user_ids
  },
});
