import _ from "lodash";
import { Button } from "@commonsku/styles";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";
import MetaDataList from "./MetaDataList";
import { Metadata } from "../../../interfaces/Metadata";

const TD = styled.td`
  cursor: default !important;
`;

interface MetaDataProps {
  parent_type: string;
  metaDataList: { [K in string]: Metadata };
  addMetaDataList: (userInput: string) => void;
  updateMetaDataList: (
    metaDataId: string,
    key: string,
    value?: string | number
  ) => (value: string | number) => void;
  deleteMetaDataList: (metaDataId: string) => void;
  editMetaDataList: (metaDataId: string) => void;
}

const MetaData: FC<MetaDataProps> = ({
  parent_type,
  metaDataList,
  addMetaDataList,
  updateMetaDataList,
  deleteMetaDataList,
  editMetaDataList,
}) => {
  const [newQuestion, setNewQuestion] = useState<string>("");

  const tableBody = useMemo(() => {
    return Object.values(metaDataList || {}).map((m) => (
      <MetaDataList
        key={m.metadata_id}
        metaDataId={m.metadata_id}
        label={m.label}
        type={m.type}
        mandatory={m.mandatory}
        updateMetadataList={updateMetaDataList}
        deleteMetaDataList={deleteMetaDataList}
        editMetaDataList={editMetaDataList}
      />
    ));
  }, [metaDataList]);

  const handleAddQuestion = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (newQuestion.trim() === "") return;
    addMetaDataList(newQuestion.trim());
    setNewQuestion("");
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddQuestion(e);
    }
  };

  return (
    <div className="row">
      <div className="small-12 columns">
        {parent_type === "SHOP" && (
          <h5>
            Checkout Questions
            <span className="explanation" style={{ marginLeft: 8 }}>
              Allows you to request extra information from the customer
            </span>
          </h5>
        )}
        {parent_type === "ITEM" && <h2>Custom Product Questions</h2>}
        <div className="small-12 columns" style={{ padding: 0 }}>
          <div className="small-8 columns" style={{ padding: 0 }}>
            <input
              type="text"
              placeholder="Question"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="small-2 columns end">
            <Button
              size="small"
              variant={newQuestion.trim() ? undefined : "disabled"}
              style={{
                pointerEvents: newQuestion.trim() ? "visible" : "none",
              }}
              onClick={(e) => handleAddQuestion(e)}
            >
              Add
            </Button>
          </div>
        </div>
        <table>
          <thead style={{ fontSize: "0.875rem" }}>
            <tr>
              <TD>Question</TD>
              <TD>Type</TD>
              <TD>Mandatory</TD>
              <TD>Actions</TD>
            </tr>
          </thead>
          <tbody>
            {metaDataList && Object.values(metaDataList).length > 0 ? (
              tableBody
            ) : (
              <tr>
                <td>There are no questions to show here yet</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MetaData;
