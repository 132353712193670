import { get, isEqual } from 'lodash';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Theme, themeOptions, GlobalStyle, Csku, Label, Col, Toggle, ToggleLink, } from '@commonsku/styles';

import { updateShop } from '../../../actions/shop';
import { mentionTextareaStyles, SKUFontDefinitionStyles } from '../../helpers/createGlobalStyle';
import { setActiveImage } from '../../../redux/heroImage';
import { getPublicViewTemplateBackgroundDropdown, getPublicViewTemplateDropdown, getPublicViewTemplateOptions } from '../../../selectors/dropdowns';
import { getGalleryOrderByParent, getOrderByNumber } from '../../../selectors';
import { createLoadOrder } from '../../../actions/order';
import { createAddTemp } from '../../../actions/temp';

import { getImageSrc } from '../../../utils';
import { DEFAULT_COLOR, TEMPLATE_OPTIONS } from '../helpers';

import SelectThemePopup from '../popups/SelectThemePopup';
import { CUSTOMIZATION_SIDEBAR_WIDTH, getDefaultPublicViewTemplateTheme, getThemesByPublicViewTemplates, POPUP_TYPES } from './helpers';
import SelectCategoryPopup from '../popups/SelectCategoryPopup/SelectCategoryPopup';
import CustomizationsSidebar from './CustomizationsSidebar';
import 'swiper/swiper-bundle.css';
import SelectFeaturedProductPopup from '../popups/SelectFeaturedProductPopup/SelectFeaturedProductPopup';
import { parseOrderToPublicViewEntity, parseShopToPublicViewEntity } from '../../../hooks/usePublicViewEntity';
import CartProvider from '../../../context/CartProvider';
import PublicViewTemplateDataProvider from '../../../context/PublicViewTemplateDataProvider';
import PublicViewEntityProvider from '../../../context/PublicViewEntityProvider';
import Overlay from '../../Overlay';
import createPopup from '../../../popup-factory';

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
${mentionTextareaStyles(p)}
.csku-styles {
  font-family: ${(p.theme && p.theme.fontFamily) || "'skufont-regular', 'sans-serif', 'Roboto'"};
}
.popup.select-theme-popup .popup-content.select-theme-popup-content,
.select-featured-product-popup .popup-content.select-featured-product-popup-content.selected-featured-product {
  height: 100%;
}
.main-content.shop .labeled-color-picker-select > label,
.main-content.shop .product_list_style_container label[for="product_list_style"],
.main-content.shop .product_page_style_container label[for="product_page_style"] {
  font-weight: normal;
  font-family: var(--font-family-regular);
  font-size: 16px;
}

.popup.select-image-popup .popup-content.select-image-popup-content,
.popup.select-category-popup .popup-content.select-category-popup-content {
  overflow-y: unset;
}

.storefront-left-preview .image-gallery-slides {
  width: 100%;
}

.shop .storefront-right-sidebar > .customizations-sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.select-category-popup .popup-content.select-category-popup-content.select-category-upload-image {
  width: 100%;
}

.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control {
  border: none;
  border-color: unset;
  box-shadow: none;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control .commonsku-styles-select__value-container {
  padding-right: 0;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__control .commonsku-styles-select__single-value {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
  font-family: var(--font-family-bold);
  font-size: 17px;
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__menu {
  border: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top: 1px solid ${p.theme?.template_color || 'var(--color-primary)'};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    1px -1px 0px ${p.theme?.template_color || 'var(--color-primary)'},
    -1px 1px 0px ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select .commonsku-styles-select__menu
  .commonsku-styles-select__option.commonsku-styles-select__option--is-selected {
  background: ${p.theme?.template_color || 'var(--color-primary)'};
}
.shop .extra-categories-options .extra-categories-select
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator,
.shop .extra-categories-options .extra-categories-select
  .commonsku-styles-select__indicator.commonsku-styles-select__dropdown-indicator > svg {
  color: ${p.theme?.template_color || 'var(--color-primary)'};
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  #storefront-template-iframe {
    zoom: 1;
  }
}
`;

/**
 * Get Template Iframe element
 * @returns {null | HTMLIFrameElement}
 */
function getTemplateIframe() {
  return document.getElementById('storefront-template-iframe');
}

function sendIframeValue(elem) {
  elem.contentWindow.postMessage({
    call:'sendShopValue',
    value: {
      hideIntro: true,
      isPreviewing: true,
    },
  });
}

function sendIframeFilterChange(filter) {
  const elem = getTemplateIframe();
  if (elem) {
    elem.contentWindow.postMessage({
      call:'sendShopFilterChange',
      value: {
        filter,
      },
    });
  }
};

function onLoadIframe(ev, filter='') {
  const elem = ev.target;
  sendIframeValue(elem);
  filter && sendIframeFilterChange(filter);
}

class Storefront extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.state || {}),
      showPopup: '',
      customizationPanels: [],
      screenSize: 'current',
      popupConfig: {
        bannerOnHover: true,
      }
    };

    this.customizationRef = createRef();

    [
      'toggleCustomizationPanel',
      'onDeleteImage',
      'handleShowPopup',
      'handleIframeLoad',
    ].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });
  }

  componentDidMount() {
    const elem = getTemplateIframe();
    if (elem) {
      elem.addEventListener('load', this.handleIframeLoad);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const elem = getTemplateIframe();
    if (elem && (!isEqual(this.props.entity, prevProps.entity))) {
      elem.contentWindow.location.reload();
      elem.addEventListener('load', this.handleIframeLoad);
    }
  }

  componentWillUnmount() {
    const elem = getTemplateIframe();
    if (elem) {
      elem.removeEventListener('load', this.handleIframeLoad);
    }
  }

  UNSAFE_componentWillMount() {
    const { gallery: { loaded, order_id }, loadOrder } = this.props;
    if (!loaded) {
      loadOrder(order_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gallery: { loaded, order_id }, loadOrder } = nextProps;
    if (!loaded && order_id !== this.props.gallery.order_id) {
      loadOrder(order_id);
    }
  }

  handleIframeLoad(ev) {
    const currentPage = this.customizationRef?.current?.getCurrentPage();
    onLoadIframe(ev, currentPage);
  }

  toggleCustomizationPanel(panel) {
    let panels = this.state.customizationPanels;
    const foundIdx = this.state.customizationPanels.indexOf(panel);
    if (foundIdx > -1) {
      panels = [
        ...panels.slice(0, foundIdx),
        panels.slice(foundIdx + 1),
      ];
    } else {
      panels = panels.concat([panel]);
    }
    this.setState({ customizationPanels: panels });
  }

  onDeleteImage = (onDeleteTemplateField) => (key) => {
    if (!key || key.length === 0) { return; }
    if (typeof key === 'object' && Array.isArray(key)) {
      key = key.join(',');
    }
    if (key.trim().length === 0) { return; }
    return onDeleteTemplateField(key);
  };

  getThemeData(themeName, selectedTheme) {
    return {
      ...(TEMPLATE_OPTIONS[themeName] || {}),
      ...Object.values(get(selectedTheme, ['template_data']) || {})
        .reduce((acc, v) => ({ ...acc, [v.name]: v.value }), {}),
    };
  }

  handleShowPopup(showPopup, popupConfig={}) {
    this.setState({ showPopup });
    !!showPopup && this.setState({ popupConfig });
  }

  getHeaderContainerHeight() {
    const headerContainer = document.querySelector('.header-container');
    return headerContainer?.clientHeight || 315;
  }

  render() {
    const {
      shop,
      gallery,
      popups,
      public_view_templates_list,
    } = this.props;
    const { showPopup, customizationPanels } = this.state;
    const entityValue = shop ? parseShopToPublicViewEntity(shop, gallery) : parseOrderToPublicViewEntity(gallery);

    const publicViewTemplate = getDefaultPublicViewTemplateTheme(
      entityValue.entity, public_view_templates_list
    );

    const template_color = publicViewTemplate.templateData.template_color;
    const themes = getThemesByPublicViewTemplates(
      entityValue.entity, public_view_templates_list,
    );
    const selectedTheme = themes.find(v => v.public_view_template_id === entityValue.entityLayoutId);
    const themeName = get(selectedTheme, ['public_view_template_name']);
    const templateData = this.getThemeData(themeName, selectedTheme);
    const newThemeOptions = {...themeOptions, template_color};
    const isMobileView = this.state.screenSize === 'mobile';

    const headerContainerHeight = this.getHeaderContainerHeight();

    return (
      <Theme theme={newThemeOptions}>
        <GlobalStyle additionalStyles={additionalGlobalStyle} theme={newThemeOptions} />
        <CartProvider entityId={entityValue.entityId} isEntityFree={entityValue.entityIsFree}>
          <PublicViewTemplateDataProvider initialValue={publicViewTemplate}>
            <PublicViewEntityProvider initialValue={entityValue}>
              {showPopup === POPUP_TYPES.SELECT_THEME && <SelectThemePopup
                onClose={() => this.setState({ showPopup: '' })}
                themes={themes}
                templateData={templateData}
                selectedTheme={selectedTheme}
                onClickFavouriteTheme={(t, value) => {
                  // todo: implement
                }}
              />}
              {showPopup === POPUP_TYPES.SELECT_CATEGORIES_IMAGE && <SelectCategoryPopup
                onClose={() => this.setState({ showPopup: '' })}
                themeName={themeName}
                showCategoryTitle={+templateData.categorytitle === 1}
                popupConfig={this.state.popupConfig}
              />}
              {showPopup === POPUP_TYPES.SELECT_FEATURED_IMAGE && <SelectFeaturedProductPopup
                onClose={() => this.setState({ showPopup: '' })}
                templateData={templateData}
              />}
              <div className={'main-content shop shop-v3'} style={{ marginTop: 0 }}>
                <Row>
                  <Csku forceStyles className='storefront-left-preview'
                    style={{ padding: 14, }}
                    sx={{
                      xs: { width: '100%', },
                      sm: { width: `calc(100% - ${CUSTOMIZATION_SIDEBAR_WIDTH}px)`, },
                    }}
                  >
                    <Row>
                      <Col xs mdOffset={4} md={4} style={{ paddingBottom: 14, }}>
                        <Toggle stretch>
                          <ToggleLink stretch
                            selected={this.state.screenSize === 'current'}
                            onClick={() => this.setState({ screenSize: 'current' })}
                          >Desktop</ToggleLink>
                          <ToggleLink stretch
                            selected={isMobileView}
                            onClick={() => this.setState({ screenSize: 'mobile' })}
                          >Mobile</ToggleLink>
                        </Toggle>
                      </Col>
                      <Col xs style={{
                        textAlign: 'center',
                        ...(isMobileView ? {
                          background: '#F2F2F2',
                          padding: 24,
                        } : {}),
                      }}>
                        <iframe
                          id="storefront-template-iframe"
                          src={`${window.location.origin}/${entityValue.entityType.toLowerCase()}/${entityValue.entityId}?hideHelp=true&previewShop=true`}
                          title={entityValue.title}
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          auto_zoom="same"
                          auto_zoom_by_ratio="0.8|1000"
                          width={isMobileView ? '410px' : '100%'}
                          style={{
                            height: `calc(100vh - ${headerContainerHeight}px - ${isMobileView ? 140 : 100}px)`,
                            border: 'none',
                            ...(isMobileView ? {
                              boxShadow: 'rgb(220 216 216) 1px 3px 6px 2px',
                              borderRadius: 5,
                            } : {}),
                          }}
                        />
                      </Col>
                    </Row>
                  </Csku>
                  <Csku forceStyles className='storefront-right-sidebar'
                    sx={{
                      xs: { width: '100%', },
                      sm: { width: CUSTOMIZATION_SIDEBAR_WIDTH, },
                    }}
                  >
                    <CustomizationsSidebar
                      themeName={themeName}
                      selectedTheme={selectedTheme}
                      customizationPanels={customizationPanels}
                      toggleCustomizationPanel={this.toggleCustomizationPanel}
                      handleShowPopup={this.handleShowPopup}
                      onCategoryPageChange={sendIframeFilterChange}
                      ref={this.customizationRef}
                      isMobileView={isMobileView}
                    />
                  </Csku>
                </Row>
              </div>
              {popups ? popups.map((p, idx) => createPopup(p, idx, { project: this.props.project })) : null}
              <Overlay popups={popups || []} />
            </PublicViewEntityProvider>
          </PublicViewTemplateDataProvider>
        </CartProvider>
      </Theme>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const entity = ownProps.shop ? ownProps.shop : ownProps.order;
  const entityId = Boolean(ownProps.shop) ? ownProps.shop.shop_id : ownProps.order.order_id;
  const entityNumber = Boolean(ownProps.shop) ? ownProps.shop.shop_number : ownProps.order.form_number;
  const entityType = Boolean(ownProps.shop) ? 'SHOP' : 'ORDER';
  const orderType = Boolean(ownProps.shop) ? 'SHOP' : (ownProps.order.order_type || ownProps.order_type);

  return {
    heroImage: state.heroImage.activeImage ?? '',
    public_view_templates_list: getPublicViewTemplateDropdown(state),
    public_view_templates: getPublicViewTemplateOptions(state),
    gallery: getGalleryOrderByParent(state, { parent_type: entityType, parent_id: entityId }),
    order: getOrderByNumber(state, { form_number: entityNumber, order_type: orderType }),
    backgroundImages: getPublicViewTemplateBackgroundDropdown(state).map(f => getImageSrc(f, 'shop-background')),
    entity,
    entityId,
    entityNumber,
    entityType,
    entityTemplateId: entity.public_view_template_id,
    entityLayoutId: entity.public_view_layout_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  onSetActiveImage: (activeImage) => {
    dispatch(setActiveImage(activeImage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Storefront);
