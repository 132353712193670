import { get } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { memoizeOAuthGet, oauth } from '../../utils';
import ProductSearchFilter from './ProductSearchFilter';
import { SAGE_COUNTRY_OPTIONS } from './utils';
import { Button, Col, Csku, LabeledCheckbox, LabeledInput, Row } from '@commonsku/styles';
import { LabeledSelect } from '../helpers';

export const PAGE_SIZE = 72;

const SORT_OPTIONS = [
  { key: 'PRICE', value: 'Price: Low to high' },
  { key: 'PRICEHIGHLOW', value: 'Price: High to low' },
  { key: 'BESTMATCH', value: 'Best match: Based on the criteria' },
  { key: 'POPULARITY', value: 'Popularity: Most popular first' },
  { key: 'PREFGROUP', value: 'Preference groups' },
];

class SageConnectSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.state.has_sage_connect = props.has_sage_connect;
    [
      'onChangeSortOption',
      'onChangeCategoriesOption',
      'onChangeBooleanFilter',
      'handleToggleAdvance',
      'handleChangeProductionTime',
      'handleChangeMinimumRangeQuantity',
      'handleChangeLineName',
      'onChangeMadeInOption',
      'onChangeSpc',
      'handleChangeQuicksearch',
      'handleChangeColors',
      'handleChangeItemNumber',
      'handleChangeItemName',
      'onChangeThemesOption',
      'handleChangePrefGroups',

      'loadThemes',
      'loadCategories',
    ].forEach(f => this[f] = this[f].bind(this));
  }

  componentDidMount() {
    this.loadThemes();
    this.loadCategories();
    if (!this.state.has_sage_connect) {
      memoizeOAuthGet('tenant/has-sage-connect-service', {service: 'search'}).then((response) => {
        const has_sage_connect = response.json.has_sage_connect_service;
        this.setState({ has_sage_connect });
        if (!has_sage_connect) {
          toast.info('You do not have SAGE Connect Search Service enabled in admin setting...');
        }
      });
    }
  }

  loadThemes() {
    this.setState({ loading: true });
    try {
      oauth('GET', 'sage-theme', {sage_connect: 1}).then((response) => {
        this.setState({ theme_options: response.json.themes.map(v => ({key: v, value: v})) });
      });
    } catch(e) {
      console.error('Unable to load theme', e);
    }
    this.setState({ loading: false });
  }

  loadCategories() {
    this.setState({ loading: true });
    try {
      oauth('GET', 'sage-category', {sage_connect: 1}).then((response) => {
        this.setState({ category_options: response.json.categories.map(v => ({key: v, value: v})) });
      });
    } catch(e) {
      console.error('Unable to load categories', e);
    }
    this.setState({ loading: false });
  }

  getProductSource() {
    return 'sage-products';
  }

  isProductSageConnect() {
    return '1';
  }

  loadDivisions() {
    this.props.loadDivisions('sage');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        sage_connect: 1,
        search_term: '',
        division_id: '',
        net_min: '',
        net_max: '',
        sage_sort: '',
        suppliers_dimensions: null,
        timely: '',
        fresh: '',
        popular: '',
        line_name: '',
        made_in: '',
        spc: '',
        themes: '',
        quick_search: '',
        colors: '',
        item_name: '',
        item_num: '',
        production_time: '',
        quantity: '',
        categories: '',
        env_friendly: '',
        union_shop: '',
        verified: '',
        recyclable: '',
        all_audiences: '',
        is_new: '',
        pref_groups: '',
      };
    } else {
      return {
        sage_connect: 1,
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.sage_division_id || '',
        net_min: this.props.user_search.net_min || '',
        net_max: this.props.user_search.net_max || '',
        sage_sort: '',
        timely: '',
        fresh: '',
        popular: '',
        line_name: '',
        made_in: '',
        spc: '',
        themes: '',
        quick_search: '',
        colors: '',
        item_name: '',
        item_num: '',
        production_time: '',
        quantity: '',
        categories: '',
        env_friendly: '',
        union_shop: '',
        verified: '',
        recyclable: '',
        all_audiences: '',
        is_new: '',
        pref_groups: '',
      };
    }
  }

  getEndpoint() {
    return 'sage-product';
  }

  getSearchTerms(page) {
    let search_terms = {
      sage_connect: 1,
      keyword_sku: this.state.search.search_term,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE,
      production_time: this.state.search.production_time || '',
      category: this.state.search.selectedCategory || '',
      color: this.state.search.selectedColor || '',
      theme: this.state.search.selectedTheme || '',
      shape: this.state.search.selectedShape || '',
      quantity: this.state.search.quantity || '',
      has_rush_service: this.state.search.has_rush_service || '',
      recyclable: this.state.search.recyclable || '',
      env_friendly: this.state.search.env_friendly || '',
      union_shop: this.state.search.union_shop || '',
      all_audiences: this.state.search.all_audiences || '',
      is_new: this.state.search.is_new || '',
      verified: this.state.search.verified || '',
      market_segment: this.state.search.sage_market || '',
      timely: this.state.search.timely || '',
      fresh: this.state.search.fresh || '',
      popular: this.state.search.popular || '',
      line_name: this.state.search.line_name || '',
      categories: this.state.search.categories || [],
      themes: this.state.search.themes || [],
      made_in: this.state.search.made_in || '',
      spc: this.state.search.spc || '',
      quick_search: this.state.search.quick_search || '',
      colors: this.state.search.colors || '',
      item_name: this.state.search.item_name || '',
      item_num: this.state.search.item_num || '',
      pref_groups: this.state.search.pref_groups || '',
    };
    if (this.state.search.division_id) {
      search_terms.division_id = this.state.search.division_id;
    }
    if (this.state.division_id) {
      search_terms.division_id = this.state.division_id;
    }
    if (this.state.search.net_min) {
      search_terms.net_min = this.state.search.net_min;
    }
    if (this.state.search.net_max) {
      search_terms.net_max = this.state.search.net_max;
    }
    if (this.state.search.sage_sort) {
      search_terms.sage_sort = this.state.search.sage_sort;
    }
    return search_terms;
  }

  onSearchResult(page, response, search_terms, field, options) {
    if (response.error) {
      toast.error(response.error);
    } else if (response.local_results) {
      toast.warning('Please enable SAGE Connect Product Search in admin setting...');
    }
    super.onSearchResult(page, response, search_terms, field, options);
  }

  onErrorResponse(json) {
    if (json && json.message) {
      toast.error(json.message, {
        autoClose: 6000,
      });
      this.setState({ loading: false });
    }
  }

  handleToggleAdvance() {
    this.setState((prevState) => ({ show_advance: !prevState.show_advance }));
  }

  onChangeBooleanFilter(e, param) {
    e && e.preventDefault();
    this.setState(s => ({
      search: { ...s.search, [param]: +s.search[param] === 1 ? 0 : 1 }
    }));
  }

  onChangeSortOption(sage_sort) {
    if (this.state.previous_sage_sort && this.state.previous_sage_sort != sage_sort) {
      this.setState({ search: { ...this.state.search, sage_sort } });
    } else {
      this.setState({ search: { ...this.state.search, sage_sort } });
    }
  }

  onChangeCategoriesOption(categories) {
    this.setState({ search: { ...this.state.search, categories } });
  }

  onChangeMadeInOption(made_in) {
    this.setState({ search: { ...this.state.search, made_in } });
  }

  onChangeThemesOption(themes) {
    this.setState({ search: { ...this.state.search, themes } });
  }

  onChangeSpc(e) {
    const spc = e.target.value;
    this.setState({ search: { ...this.state.search, spc } });
  }

  handleChangeColors(e) {
    const colors = e.target.value;
    this.setState({ search: { ...this.state.search, colors } });
  }

  handleChangePrefGroups(e) {
    const pref_groups = e.target.value;
    this.setState({ search: { ...this.state.search, pref_groups } });
  }

  handleChangeLineName(e) {
    const line_name = e.target.value;
    this.setState({ search: { ...this.state.search, line_name } });
  }

  handleChangeProductionTime(e) {
    let production_time = e.target.value;
    this.setState({ search: { ...this.state.search, production_time } });
  }

  handleChangeQuicksearch(e) {
    let quick_search = e.target.value;
    this.setState({ search: { ...this.state.search, quick_search } });
  }

  handleChangeItemNumber(e) {
    let item_num = e.target.value;
    this.setState({ search: { ...this.state.search, item_num } });
  }

  handleChangeItemName(e) {
    let item_name = e.target.value;
    this.setState({ search: { ...this.state.search, item_name } });
  }

  handleChangeMinimumRangeQuantity(e) {
    let quantity = e.target.value;
    this.setState({ search: { ...this.state.search, quantity } });
  }

  renderSageConnect() {
    const {
      search,
      show_advance,
      category_options,
      theme_options,
    } = this.state;
    const { company_type, divisions } = this.props;

    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    const onChangeDivision = division_id => this.onChangeDivision(division_id, 'sage_division_id');

    const hidden = {
      display: 'none'
    };

    return (
      <React.Fragment>
        <div className="medium-3 columns" style={{
          padding: '0px 8px 0px 0px',
          paddingBottom: show_advance ? 0 : 8,
        }}>
          <LabeledSelect
            isMulti
            value={search.categories}
            isClearable={true}
            options={category_options || []}
            onChange={e => this.onChangeCategoriesOption(e ? e.map(v => v.value) : [])}
            placeholder="All Categories"
            label="Categories"
            name="sage_connect_categories"
            className="sage_connect_categories_select"
            style={{ marginBottom: 0 }}
          />
        </div>
        <div className="medium-3 columns" style={{
          padding: 8,
          paddingBottom: show_advance ? 0 : 8,
        }}>
          <LabeledInput
            label="Keywords"
            value={search.search_term}
            onChange={this.handleChangeSearchTerm}
            onKeyDown={this.handlePressEnter}
            style={{ marginBottom: 0 }}
          />
        </div>
        <div className="medium-2 columns" style={{
          padding: '8px 8px 0px 8px',
          paddingBottom: show_advance ? 0 : 8,
        }}>
          <LabeledInput
            label="Product name"
            value={search.item_name}
            onChange={this.handleChangeItemName}
            onKeyDown={this.handlePressEnter}
            style={{ marginBottom: 0 }}
          />
        </div>
        <div className="medium-4 columns" style={{
          padding: 8,
          paddingBottom: show_advance ? 0 : 8,
        }}>
          <Row style={{ marginTop: 21 }}>
            <Csku as={Col} xs md={4} forceStyles sx={{
              xs: { padding: 0 },
              md: { padding: '0px 2px 0px 0px' },
            }}>
              <Button size='medium' style={{ width: '100%', marginRight: 2 }} onClick={(...args) => {
                if (this.state.has_sage_connect) {
                  return this.handleClickSearch(...args);
                } else {
                  toast.info('You do not have SAGE Connect Search Service enabled in admin setting...');
                }
              }}>Search</Button>
            </Csku>
            <Csku as={Col} xs md={4} forceStyles sx={{
              xs: { padding: 0, marginTop: 8, marginBottom: 8 },
              md: { padding: '0px 2px 0px 0px', marginTop: 0, marginBottom: 0 },
              }}>
              <Button size='medium' style={{ width: '100%', marginRight: 2 }} onClick={this.handleClickReset}>Reset</Button>
            </Csku>
            <Csku as={Col} xs md={4} style={{ padding: 0 }}>
              <Button size='medium' style={{ width: '100%' }} onClick={this.handleToggleAdvance}>
                {show_advance ? <span>&#9650;</span> : <span>&#9660;</span>}
              </Button>
            </Csku>
          </Row>
        </div>
        <div className="medium-12 columns"></div>

        <div className="column"
          style={!show_advance ? hidden : { padding: 0 }}
        >
          <div className="medium-3 columns" style={{ padding: '8px 8px 0px 0px' }}>
            <LabeledInput
              label="Item number / SKU"
              value={search.item_num}
              onChange={this.handleChangeItemNumber}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>
          <div className="medium-3 columns" style={{ padding: '8px 8px 0px 8px' }}>
            <LabeledInput
              label="SPC"
              value={search.spc}
              onChange={this.onChangeSpc}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>
          <div style={{ position: 'relative', padding: '8px 8px 0px 8px' }} className="medium-2 columns">
            <LabeledInput
              label="Min. quantity"
              value={search.quantity}
              onChange={this.handleChangeMinimumRangeQuantity}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>
          <div style={{ position: 'relative', padding: '8px 8px 0px 8px' }} className="medium-4 columns">
            <div className="row">
              <div className="medium-6 columns" style={{ paddingLeft: 0, paddingRight: 4 }}>
                <LabeledInput
                  label="Net min"
                  value={search.net_min}
                  onChange={this.handleChangeNetMin}
                  onKeyDown={this.handlePressEnter}
                  style={{ marginBottom: 0 }}
                />
              </div>
              <div className="medium-6 columns" style={{ paddingLeft: 4, paddingRight: 0 }}>
                <LabeledInput
                  label="Net max"
                  value={search.net_max}
                  onChange={this.handleChangeNetMax}
                  onKeyDown={this.handlePressEnter}
                  style={{ marginBottom: 0 }}
                />
              </div>
            </div>
          </div>
          <div className="medium-12 columns"
            style={{ paddingBottom: show_advance ? 0 : 14 }}
          ></div>

          <div style={{ position: 'relative', padding: '0px 8px 0px 0px' }} className="medium-3 columns">
            <LabeledSelect
              isMulti
              value={search.themes}
              isClearable={true}
              options={theme_options || []}
              onChange={e => this.onChangeThemesOption(e ? e.map(v => v.value) : [])}
              placeholder="All Themes"
              label="Themes"
              name="sage_connect_themes"
              className="sage_connect_themes_select"
              style={{ marginBottom: 0 }}
            />
          </div>
          <div style={{ position: 'relative', padding: '8px 8px 0px 8px' }} className="medium-3 columns">
            <LabeledInput
              label="Colors"
              placeholder='comma separated'
              value={search.colors}
              onChange={this.handleChangeColors}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>

          <div style={{ position: 'relative', padding: '8px 0px 0px 8px' }} className="medium-3 columns">
            <LabeledInput
              label="Prod. time (days)"
              value={search.production_time}
              onChange={this.handleChangeProductionTime}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>
          <div style={{ position: 'relative', padding: '8px 8px 0px 8px' }} className="medium-3 columns">
            <LabeledInput
              label="Preference groups"
              placeholder='comma separated'
              value={search.pref_groups}
              onChange={this.handleChangePrefGroups}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>

          <div className="medium-12 columns"></div>
          <div className="medium-3 columns" style={{ padding: '0px 8px 0px 0px' }}>
            {divisions &&
              <LabeledSelect
                id="division"
                value={division_id}
                isSearchable={true}
                isClearable={'SUPPLIER' !== company_type}
                options={divisions}
                onChange={e => onChangeDivision(e ? e.value : '')}
                label="Supplier"
                name="sage_connect_supplier"
                style={{ marginBottom: 0 }}
              />}
          </div>
          <div style={{ position: 'relative', padding: '8px 8px 0px 8px' }} className="medium-3 columns">
            <LabeledInput
              label="Supplier's line name"
              value={search.line_name}
              onChange={this.handleChangeLineName}
              onKeyDown={this.handlePressEnter}
              style={{ marginBottom: 0 }}
            />
          </div>
          <div style={{ position: 'relative', padding: '0px 8px 0px 8px' }} className="medium-3 columns">
            <LabeledSelect
              value={search.made_in}
              isClearable={true}
              options={SAGE_COUNTRY_OPTIONS}
              onChange={e => this.onChangeMadeInOption(e ? e.value : '')}
              label="Made in"
              name="sage_connect_made_in"
              style={{ marginBottom: 0 }}
            />
          </div>
          <div className="medium-3 columns" style={{ padding: '0px 8px 0px 8px' }}>
            <LabeledSelect
              value={search.sage_sort}
              isClearable={true}
              options={SORT_OPTIONS}
              onChange={e => this.onChangeSortOption(e ? e.value : '')}
              label="Sort"
              name="sage_connect_sort"
              style={{ marginBottom: 0 }}
            />
          </div>
          <div className="medium-12 columns" style={{ paddingBottom: 14 }}></div>

          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="has_rush_service"
              checked={+search.has_rush_service === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'has_rush_service')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'has_rush_service') }}
              label="Include rush shipment"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="is_new"
              checked={+search.is_new === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'is_new')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'is_new') }}
              label="New products only"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="verified"
              checked={+search.verified === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'verified')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'verified') }}
              label="Verified"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="recyclable"
              checked={+search.recyclable === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'recyclable')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'recyclable') }}
              label="Recyclable"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div className="medium-12 columns"></div>

          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="env_friendly"
              checked={+search.env_friendly === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'env_friendly')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'env_friendly') }}
              label="Environmental friendly"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="union_shop"
              checked={+search.union_shop === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'union_shop')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'union_shop') }}
              label="Produced by a union shop"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="all_audiences"
              checked={+search.all_audiences === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'all_audiences')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'all_audiences') }}
              label="All audiences"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="timely"
              checked={+search.timely === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'timely')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'timely') }}
              label="Timely products"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div className="medium-12 columns"></div>

          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="fresh"
              checked={+search.fresh === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'fresh')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'fresh') }}
              label="Fresh ideas"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div style={{ position: 'relative', padding: 0, }} className="medium-3 columns">
            <LabeledCheckbox
              name="popular"
              checked={+search.popular === 1}
              onChange={e => this.onChangeBooleanFilter(e, 'popular')}
              labelProps={{ onClick: e => this.onChangeBooleanFilter(e, 'popular') }}
              label="Popular items only"
              labelStyle={{ marginBottom: 0, marginRight: 0, }}
            />
          </div>
          <div className="medium-12 columns" style={{ paddingBottom: 14 }}></div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          {this.renderSageConnect()}
        </Row>
        <Row style={{ overflow: 'auto', ...this.props.style }} onScroll={this.handleScroll}>
          {this.renderProducts('sage-connect')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    has_sage_connect: get(state, 'entities.has_sage_connect') || ownProps.has_sage_connect
  };
})(SageConnectSearchFilter);
