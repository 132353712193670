import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Csku, ImageIcon, Text } from '@commonsku/styles';
import Img from '../../../Img';
import DebouncedSlider from './DebouncedSlider';

const BannerImage = forwardRef(({ value, onClick, dimPercentage, onDimPercentageChange }, ref) => {
  const sliderRef = useRef(null);

  useImperativeHandle(ref, () => ({
    onReset: () => {
      sliderRef.current.onReset();
    },
    onUpdate: (newValue) => {
      sliderRef.current.onUpdate(newValue);
    }
  }));

  return (
    <>
      <Text
        as='p'
        style={{
          color: 'var(--color-neutrals-90)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 20,
          marginBottom: 4,
          fontWeight: 600,
        }}
      >
        Banner Image
      </Text>
      <Csku
        as='p'
        onClick={onClick}
        data-testid='banner_image_btn'
        style={{
          color: 'var(--color-primary1-main)',
          fontFamily: 'var(--font-family-bold)',
          fontSize: 16,
          marginBottom: 0,
          padding: 16,
          borderRadius: 3,
          cursor: 'pointer',
        }}
        sx={{
          background: 'var(--color-neutrals-40)',
          ':hover': {
            background: 'var(--color-neutrals-30)',
          },
        }}
      >
        {value ? (
          <Img src={value} />
        ) : (
          <>
            <ImageIcon style={{ verticalAlign: 'middle' }} />
            <span style={{ paddingLeft: 7 }}>Add Image</span>
          </>
        )}
      </Csku>
      <Text
        as='p'
        style={{
          color: 'var(--neutrals-70899-ca-9, #899CA9)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        Preferred size: 1456 x 1007
      </Text>
      <DebouncedSlider
        ref={sliderRef}
        labelText='Dim Banner'
        onSlide={onDimPercentageChange}
        initValue={dimPercentage}
        testIdPrefix='dim-banner'
      />
    </>
  );
});

export default BannerImage;
