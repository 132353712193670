import _ from 'lodash';
import { UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE, ADD_ORDER_SUCCESS, UPDATE_TAGS_SUCCESS, FETCH_PROJECT_SUCCESS } from '../actions/project';
import { ADD_MESSAGE_SUCCESS } from '../actions/message';
import { DELETE_PURCHASE_ORDER_SUCCESS, REGENERATE_PURCHASE_ORDER_SUCCESS } from '../actions/purchase_order';
import { LOAD_CONTACT_PROJECTS_SUCCESS } from '../actions/contact';
import { DELETE_ORDER_SUCCESS } from '../actions/order';
import { DELETE_ITEM_SUCCESS } from '../actions/item';

const deleteOrderSuccess = (state, action) => {
  let {
    order_id, order_type, job_id,
  } = action.payload.data;
  let patch = {};
  const project = state[job_id];

  if(order_type == 'PRESENTATION') {
    patch = {presentations: []};
  }else if(order_type == 'ESTIMATE') {
    patch = {estimates: []};
  }else if(order_type == 'SALES ORDER') {
    patch = {sales_orders: [], purchase_orders: []};
  }else if(order_type == 'INVOICE') {
    patch = {
      invoices: _.filter(project.invoices, (k) => k !== order_id),
    };
  } else if (order_type == 'OPPORTUNITY') {
    patch = {opportunities: []};
  }
  return {
    ...state,
    [job_id]: {
      ...project,
      ...patch,
    }
  };
};

const reduce = (state = {}, action) => {
  let project_id;
  let updated_project;
  let order_id;
  let job_id;
  let tags;
  switch (action.type) {
    case FETCH_PROJECT_SUCCESS:
      job_id = action.payload.job_id;
      return {
        ...state,
        [job_id]: {
          ...(state[job_id] || {}),
          ...action.payload.project,
        },
      };
    case UPDATE_PROJECT_REQUEST:
      // fall through
    case UPDATE_PROJECT_SUCCESS:
      // fall through
    case UPDATE_PROJECT_FAILURE:
      project_id = action.payload.id;
      updated_project = Object.assign({}, state[project_id], action.payload.data);
      return Object.assign({}, state, {[project_id]: updated_project});
    case ADD_MESSAGE_SUCCESS:
      if(action.payload.job_id) {
        project_id = action.payload.job_id;
      }else if (action.payload.message.details_parent_type == 'JOB'){
        project_id = action.payload.message.details_parent_id;
      }

      if(project_id) {
        updated_project = Object.assign({}, state[project_id]);
        let message_id = action.payload.message.message_id;
        updated_project.notes && updated_project.notes.unshift(message_id);
        return Object.assign({}, state, {[project_id]: updated_project});
      }else{
        return state;
      }
    case ADD_ORDER_SUCCESS:
      order_id = action.payload.data.order.order_id;
      job_id = action.payload.data.order.job_id;

      if(action.payload.data.order.order_type == 'PRESENTATION') {
        return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {presentations: [order_id] })});
      }else if(action.payload.data.order.order_type == 'ESTIMATE') {
        return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {estimates: [order_id] })});
      }else if(action.payload.data.order.order_type == 'SALES ORDER') {
        return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {sales_orders: [order_id] })});
      }else if(action.payload.data.order.order_type == 'INVOICE') {
        return Object.assign({}, state, { [job_id]: Object.assign({}, state[job_id], {invoices: state[job_id].invoices.concat([order_id]) })});
      }
      return state;
    case DELETE_ORDER_SUCCESS:
      return deleteOrderSuccess(state, action);
    case UPDATE_TAGS_SUCCESS:
      tags = action.payload.data.tags;
      job_id = action.payload.data.job_id;

      return Object.assign({}, state, { [job_id]: Object.assign({} , state[job_id], {tags: tags})});
    case REGENERATE_PURCHASE_ORDER_SUCCESS:
      return Object.assign({}, state, Object.values(state).map(
        j => Object.assign({}, j, {
          purchase_orders: Array.from(new Set(j.purchase_orders.concat(action.payload.purchase_orders.map(po => po.purchase_order_id)).filter(po => !(action.payload.deleted || []).includes(po))))
        })).reduce((o, j) => { o[j.job_id] = j; return o; }, {}));
    case DELETE_PURCHASE_ORDER_SUCCESS:
      return Object.assign({}, state, Object.values(state).map(j => Object.assign({}, j, { purchase_orders: j.purchase_orders.filter(k => k !== action.payload.id)})).reduce((o, j) => { o[j.job_id] = j; return o; }, {}));
    case LOAD_CONTACT_PROJECTS_SUCCESS:
      return Object.assign({}, state, action.payload.projects);
    case DELETE_ITEM_SUCCESS:
      return Object.values(state).reduce(
        (o, p) => ({
          ...o,
          [p.job_id]: {
            ...p,
            purchase_orders: p.purchase_orders.filter(po => !action.payload.deleted_pos.includes(po))
          }
        }),
        {}
      ); 
  }
  return state;
};

export default reduce;
