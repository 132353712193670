import { bindAll } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { updateOrder } from '../actions/order';
import { updateProject, finalizeUpdateProject, updateTags } from '../actions/project';
import { createUpdateDisplayCommenting } from '../actions/display';

import Publisher from './Publisher';
import FeedFilter from './FeedFilter';
import Feed from './Feed';
import ProjectStatus from './ProjectStatus';
import ProjectBriefing from './ProjectBriefing';

import { syncMessages } from '../actions/message';

import { oauth } from '../utils';

class ProjectNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedTab: 'activity',
    };

    this.unmounted = React.createRef(false);

    bindAll(this, ['loadMessagesFromServer']);
  }

  componentDidMount() {
    if (!this.props.commenting) {
      this.loadMessagesFromServer();
    }
  }

  componentWillUnmount() {
    this.props.onStopCommenting();
    this.unmounted.current = true;
  }

  loadMessagesFromServer() {
    const data = {
      job_id: this.props.project.job_id
    };
    return oauth('GET', 'newsfeed/project', data).then(({ json }) => {
      this.props.onSyncMessages(json.projectfeed);
      if (this.unmounted.current) return;
      this.setState({ loading: false });
    });
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' alt="loading" /><br /></div>
        </div>
      </div>
    );
  }

  renderActivity() {
    const { project } = this.props;
    const { loading } = this.state;

    return (
      <Fragment>
        <Publisher job_id={project.job_id} client_id={project.account_id} />
        {loading ?
          this.renderLoading()
          :
          <FeedFilter posts={project.messages} filters={[
            FeedFilter.UserFilter, FeedFilter.OrderTypeFilter, FeedFilter.SearchFilter,
          ]}>
            <Feed job_id={project.job_id} comment_allowed={true} />
          </FeedFilter>
        }
      </Fragment>
    );
  }

  render() {
    const {
      project, items, hasCapability,
      onChangeProject, onUpdateProject, onUpdateTags, onUpdateOrder
    } = this.props;

    return (
      <div className="main-content project-notes">
        <div className="medium-12 large-8 columns">
          {this.renderActivity()}
        </div>
        <div className="medium-12 large-4 columns">
          <div>
            <ProjectStatus project={project} items={items} />
            <ProjectBriefing project={project} onChangeProject={onChangeProject} onUpdateProject={onUpdateProject} onUpdateTags={onUpdateTags} hasCapability={hasCapability} onUpdateOrder={onUpdateOrder} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.entities.items,
    hasCapability: capability => state.identity.capabilities.includes(capability),
    commenting: state.display.commenting,
    newsfeed_refresh_interval: state.display.newsfeed_refresh_interval
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpdateProject: (project_id, field, previous_value) => value => dispatch(updateProject(project_id, field, previous_value, value)),
    onUpdateOrder: (order_id, field, previous_value) => value => dispatch(updateOrder(order_id, field, previous_value, value)),
    onChangeProject: (project_id, field, previous_value) => value => dispatch(finalizeUpdateProject(project_id, field, previous_value, value)),
    onUpdateTags: (newTags, order_id) => dispatch(updateTags(newTags, order_id)),
    onSyncMessages: messages => dispatch(syncMessages(messages)),
    onStopCommenting: () => dispatch(createUpdateDisplayCommenting(false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNotes);
