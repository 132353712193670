import _ from 'lodash';
import { oauth } from '../utils';

import { createAddItem } from './index';
import { createUploadFile } from './file';
import { DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE } from './order';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const UPDATE_TAGS_SUCCESS = 'UPDATE_TAGS_SUCCESS';
export const ADD_COLLABORATE_MESSAGE_SUCCESS = 'ADD_COLLABORATE_MESSAGE_SUCCESS';
export const UPDATE_SUGGESTIONS_SUCCESS = 'UPDATE_SUGGESTIONS_SUCCESS';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const SYNC_COLLABORATE_MESSAGE_SUCCESS = 'SYNC_COLLABORATE_MESSAGE_SUCCESS';

export const FETCH_DASHBOARD_PROJECTS_SUCCESS = 'FETCH_DASHBOARD_PROJECTS_SUCCESS';

export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';

const requestUpdateProject = (project_id, field, previous_value, value) => ({
  type: UPDATE_PROJECT_REQUEST,
  payload: {
    id: project_id,
    data: {
      [field]: value
    }
  }
});

export const finalizeUpdateProject = (project_id, field, previous_value, value, project=null) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: {
    id: project_id,
    data: {
      [field]: value
    },
    project
  }
});

const failedUpdateProject = (project_id, field, previous_value, value) => ({
  type: UPDATE_PROJECT_FAILURE,
  payload: {
    id: project_id,
    message: 'Unable to update project',
    data: {
      [field]: previous_value
    }
  }
});

export const updateProject = (project_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(requestUpdateProject(project_id, field, previous_value, value));

  return oauth('PUT', `project/${project_id}`, {[field]: value}).then(
    ({ json }) => dispatch(finalizeUpdateProject(project_id, field, previous_value, json.project[field], json.project)),
    ({ json }) => dispatch(failedUpdateProject(project_id, field, previous_value, value))
  );
};

export const AddOrderToProject = (order, taxes) => ({
  type: ADD_ORDER_SUCCESS,
  payload: {
    data: {
      order,
      taxes
    }
  }
});

export const createNewPresentation = (opportunity_id, client_id, event_type_id, job_name, billing_contact_id, billing_address_id, order_type, job_id, project_job_number) => dispatch => {
  let data = {
    client_id,
    event_type_id,
    job_name,
    billing_contact_id,
    billing_address_id,
    order_type,
    job_id
  };
  if (opportunity_id) {
    data.target_type = 'UPGRADE';
    data.parent_id = opportunity_id;
  }

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(AddOrderToProject(json.order, json.taxes));
      return json.order;
    }
  );
};

export const createNewEstimate = (opportunity_id, client_id, event_type_id, job_name, billing_contact_id, billing_address_id, shipping_contact_id, shipping_address_id, order_type, job_id, project_job_number) => dispatch => {
  let data = {
    client_id,
    event_type_id,
    job_name,
    billing_contact_id,
    billing_address_id,
    shipping_contact_id,
    shipping_address_id,
    order_type,
    job_id
  };
  if (opportunity_id) {
    data.target_type = 'UPGRADE-ESTIMATE';
    data.parent_id = opportunity_id;
  }

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(AddOrderToProject(json.order, json.taxes));
      return json.order;
    }
  );
};

export const createNewSalesOrder = (opportunity_id, client_id, event_type_id, job_name, billing_contact_id, billing_address_id, shipping_contact_id, shipping_address_id, order_type, job_id, project_job_number) => dispatch => {
  let data = {
    client_id,
    event_type_id,
    job_name,
    billing_contact_id,
    billing_address_id,
    shipping_contact_id,
    shipping_address_id,
    order_type,
    job_id
  };
  if (opportunity_id) {
    data.target_type = 'UPGRADE-SALES-ORDER';
    data.parent_id = opportunity_id;
  }

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(AddOrderToProject(json.order, json.taxes));
      return json.order;
    }
  );
};

const requestCreateProjectAndOrder = (order_type, job_name, client_id, event_type_id) => ({
  type: CREATE_PROJECT_REQUEST,
  payload: {
    order_type,
    job_name,
    client_id,
    event_type_id
  }
});

const finalizeCreateProjectAndOrder = order => ({
  type: CREATE_PROJECT_SUCCESS,
  payload: {
    order
  }
});

const failedCreateProjectAndOrder = (order_type, job_name, client_id, event_type_id) => ({
  type: CREATE_PROJECT_FAILURE,
  payload: {
    order_type,
    job_name,
    client_id,
    event_type_id
  }
});

export const createProjectAndOrder = ({ order_type, job_name, client_id, event_type_id }) => dispatch => {
  dispatch(requestCreateProjectAndOrder(order_type, job_name, client_id, event_type_id));
  const data = {
    order_type,
    job_name,
    client_id,
    event_type_id
  };
  return oauth('POST', 'order', data).then(
    ({ json }) => dispatch(finalizeCreateProjectAndOrder(json.order)),
    ({ json }) => dispatch(failedCreateProjectAndOrder(order_type, job_name, client_id, event_type_id))
  );
};

export const removeOrderFromProject = (order_id, order_type, job_id, parent_order_id) => ({
  type: DELETE_ORDER_SUCCESS,
  payload: {
    data: {
      order_id,
      order_type,
      job_id,
      parent_order_id
    }
  }
});

export const failedDeleteOrder = (order_id, message = 'Unable to delete order') => ({
  type: DELETE_ORDER_FAILURE,
  payload: {
    id: order_id,
    message
  }
});

export const deleteCollection = order_id => dispatch => {
  return oauth('DELETE', `order/${order_id}`, {}).then(
    ({ json }) => {
      window.location = '/collections.php';
    },
    ({ json }) => dispatch(failedDeleteOrder(order_id, json.error))
  );
};

export const copyCollection = (parent_id, job_name) => dispatch => {
  return oauth('POST', 'order', { parent_id, job_name, target_type: 'COPY' }).then(
    ({ json }) => {
      window.location = `/collection/${json.order.form_number}`;
    },
    ({ json }) => false
  );
};

export const copyOrder = (parent_id, job_name, client_id, order_type, items, shop_type = null) => dispatch => {
  const data = {
    parent_id,
    target_type: 'COPY',
    job_name,
    client_id,
    order_type,
    items
  };

  if(order_type === 'SHOP' && shop_type === 'popup') {
    data.aggregate = 1;
  }

  return oauth('POST', 'order', data).then(
    ({json}) => {
      if(json.shop) {
        window.location = `/shop/${json.shop.shop_number}`;
      } else {
        window.location = '/project/' + json.order.job_number + '/' + json.order.order_type.toLowerCase().replace(' ', '-') + '/' + json.order.form_number;
      }
      return true;
    },
    ({ json }) => false
  );
};

export const UpdateTagsToProject = (tags, job_id) => ({
  type: UPDATE_TAGS_SUCCESS,
  payload: {
    data: {
      tags,
      job_id
    }
  }
});

export const updateTags = (tags, id) => dispatch => {
  const order_tags = tags.toString();

  const data = {
    order_tags
  };

  oauth('PUT', `order/${id}`, data).then(
    ({json}) => {
      dispatch(UpdateTagsToProject(tags, json.order.job_id));
    }
  );
};

export const AddCollaborateMessageToProject = (event, tmpl, supplier, files) => ({
  type: ADD_COLLABORATE_MESSAGE_SUCCESS,
  payload: {
    event,
    tmpl,
    supplier,
    files
  }
});

export const createAddCollaborateInvitation = (job_id, action, client_id, order_id, order_type, contact_email, content, id, status, files) => dispatch => {
  const suppliers = [];

  let data = {
    action,
    client_id,
    order_id,
    order_type,
    content
  };

  if(action == 'invitation') {
    const supplier = {
      contact_email,
      content,
      id,
      status
    };

    suppliers.push(supplier);
    data.suppliers = suppliers;

    delete data['content'];
  }

  if (files && files.length > 0) {
    let event;
    oauth('POST', 'job', data).then(
      ({ json }) => {
        event = json;
        return dispatch(createUploadFile(json.eventsFull[0].event_id, 'EVENT', files[0]));
      }
    ).then(
      ({ payload }) => dispatch(AddCollaborateMessageToProject(event.eventsFull[0], event.tmpl, event.suppliers ? event.suppliers[0] : null, payload.data))
    );
  } else {
    oauth('POST', 'job', data).then(
      ({json}) => {
        if (json.suppliers) {
          dispatch(AddCollaborateMessageToProject(json.eventsFull[0], json.tmpl, json.suppliers[0]));
        }else{
          dispatch(AddCollaborateMessageToProject(json.eventsFull[0], json.tmpl, null));
        }
      }
    );
  }

};

export const createSearch = (string) => dispatch => {
  window.location = '/global_search.php?str=' + encodeURIComponent(string);
};

export const createFinalizedUpdateSuggestions = (suggestion, params) => ({
  type: UPDATE_SUGGESTIONS_SUCCESS,
  payload: {
    suggestion,
    params,
    data: {
      is_added: suggestion.is_added
    }
  }
});

export const createSuggestionsUpdate = (suggestion_id, event, params) => dispatch => {
  let data = {
    suggestion_id,
    event,
    action: 'suggestions-update',
    is_added: 1
  };

  oauth('POST', 'job', data).then(
    ({json}) => {
      dispatch(createFinalizedUpdateSuggestions(json.suggestion, params));
    }
  );
};

const createFinalizedAddProduct = (product) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: {
    product
  }
});

export const createAddProduct = (order_id, colors, division_id, imprints, pricing, product_additional_information, product_description, product_name, product_packaging, product_supplier_code, product_url, sizes, file, order_type, is_supplier) => dispatch => {
  let data = {
    colors,
    division_id,
    imprints,
    pricing,
    product_additional_information,
    product_description,
    product_name,
    product_packaging,
    product_supplier_code,
    product_url,
    sizes
  };

  let product_id;
  return oauth('POST', 'product', data).then(
    ({ json }) => {
      if(!is_supplier) {
        dispatch(createFinalizedAddProduct(json.product));
      }
      product_id = json.product.product_id;
      return dispatch(createUploadFile(json.product.product_id, 'PRODUCT', file));
    }
  ).then(
    ({ json }) => {
      if(product_id && order_id) {
        dispatch(createAddItem(order_id, ('PRESENTATION' === order_type || 'SHOP' === order_type) ? 'OPTION' : 'PRODUCT', product_id));
      }else{
        if(product_id) {
          window.top.location.href = '/product.php?id=' + product_id;
        }
      }
    }
  );
};

export const syncCollaborateMessages = (collaborate_messages) => ({
  type: SYNC_COLLABORATE_MESSAGE_SUCCESS,
  payload: {
    collaborate_messages
  }
});

const finalizeFetchDashboardProjects = (jobs, sales_targets) => ({
  type: FETCH_DASHBOARD_PROJECTS_SUCCESS,
  payload: {
    jobs,
    sales_targets
  }
});

export const createFetchDashboardProjects = (type, params={}) => (dispatch, getState) => {
  oauth('GET', 'page/project-dashboard', { type: type, entities_only: true, ...params, }).then(
    ({ json }) => dispatch(finalizeFetchDashboardProjects(json.dashboard.jobs, json.dashboard.sales_targets)),
  );
};

const finalizeFetchProject = (job_id, project) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload: {
    job_id,
    project
  }
});

export const createFetchProject = (jobId, params={}) => (dispatch, getState) => {
  return oauth('GET', `project/${jobId}`, params).then(
    ({ json }) => dispatch(finalizeFetchProject(jobId, json.project)),
  );
};
