import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Col, ConfirmAlertPopup, Row, Text, } from "@commonsku/styles";
import { get } from "lodash";
import {
  DimBackground,
  ShopTitle,
  ButtonText,
  BackgroundImage,
  AutoScrollCarousel,
  CarouselImages as CarouselImagesBlock,
  CategoryImages,
  PlaceholderLine,
  ResetToDefault,
  FillBanner,
  FeaturedProductsBlock,
  BannerImage,
} from "./theme-customizations";
import { PublicViewTemplate } from "../../../models/PublicViewTemplate";
import { LabeledSelect } from "../../helpers";
import { POPUP_TYPES } from './helpers';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';
import { usePublicViewTemplateDataContext } from '../../../context/PublicViewTemplateDataProvider';

const PageStylesPanelContent = (props, ref) => {
  const {
    themeName,
    carouselImages=[],
    onUpdateField,
    onDeleteField,
    onOpenImagesPopup,
    onOpenCategoriesPopup,
    onOpenFeatureProductsPopup,
    onCategoryPageChange
  } = props;
  const {
    title,
    entityOrder: gallery,
  } = usePublicViewEntityContext();
  const {
    templateBannerOnHover,
    templateData,
    applyBannerToAllCategories,
    getShopCategoryWithImages,
    getPublicViewTemplateDefaultProperty,
  } = usePublicViewTemplateDataContext();

  const [page, setPage] = React.useState('HOME');
  const [showConfirmPopup, setConfirmPopup] = useState(false);

  const isCarousel = themeName === PublicViewTemplate.CATEGORIES_CAROUSEL;
  const isBillboard = themeName === PublicViewTemplate.BILLBOARD;
  const isFeatured = themeName === PublicViewTemplate.FEATURED;
  const isSplit = themeName === PublicViewTemplate.SPLIT;
  const isPoster = themeName === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
  const onToggleField = (field) => onUpdateField(field, +templateData[field] === 1 ? 0 : 1);
  const bannerImgRef = useRef(null);
  // for theme with multiple pages like poseter, display 'Reset this page'
  const resetButtonText = isPoster ? 'Reset this page' : 'Reset Page Styles';

  const categories = useMemo(() => {
    const items = get(gallery, ['items']) || [];
    return [
      {label: 'Home', value: 'HOME'},
      {label: 'All Products', value: 'ALL_PRODUCTS'},
    ].concat(
      getShopCategoryWithImages(items).map(v => ({
        value: v.item_id,
        label: v.item_name,
      }))
    );
  }, [gallery, getShopCategoryWithImages]);

  useImperativeHandle(ref, () => ({ getPage: () => page }));

  const onReset = () => {
    const publicViewTemplateDefaultHeroImg =
      isCarousel &&
      getPublicViewTemplateDefaultProperty('hero1-image');

    Promise.all([
      onDeleteField('title', ''),
      isCarousel ? onUpdateField('fill', 1) : null,
      isCarousel ? onUpdateField('autoscroll', 1) : null,
      isCarousel ? onDeleteField('categorytitle', 1) : null,
      isBillboard ? onUpdateField('darken', 1) : null,
      (isBillboard || isFeatured || isSplit) ? onUpdateField('button_text', 'Browse all of our Products') : null,
      isFeatured ? onUpdateField('featured-product-0', '') : null,
      isFeatured ? onUpdateField('featured-product-1', '') : null,
      isFeatured ? onUpdateField('featured-product-2', '') : null,
      isFeatured ? onUpdateField('featured-product-3', '') : null,
      isCarousel ? onUpdateField('reset-category-images', 1) : null,
      isPoster ? onUpdateField('reset-poster-category', page) : null,
      isPoster && page === 'HOME' ? onDeleteField('categorytitle', 1) : null
    ].concat(
      !isCarousel ? [
        !isPoster && onDeleteField('background')
      ] : carouselImages.map(v => onDeleteField(`${v.key}-title,${v.key}-image`)) // images
    )).then(() => {
      if (isCarousel) {
        onUpdateField('hero1-image', publicViewTemplateDefaultHeroImg);
      }
      isPoster && bannerImgRef.current && bannerImgRef.current.onReset();
      setConfirmPopup(false);
    });
  };

  const onBannerDimPercentageChange = (value) => {
    const templateDataKey = page === 'ALL_PRODUCTS' ? 'ALL-PRODUCTS-BANNER-DIM' : `CATEGORY-${page}-BANNER-DIM`;
    onUpdateField(templateDataKey, value);
  };

  const defaultBannerDimPercentage = getPublicViewTemplateDefaultProperty('BANNER-DIM-PERCENTAGE', 0.5);

  const bannerDimPercentage = (page) => {
    switch (page) {
      case 'ALL_PRODUCTS':
        return templateData['ALL-PRODUCTS-BANNER-DIM'] || defaultBannerDimPercentage;
      default:
        return templateData[`CATEGORY-${page}-BANNER-DIM`] || defaultBannerDimPercentage;
    }
  };

  const PlaceholderLineCol = (
    <Col xs>
      <PlaceholderLine />
    </Col>
  );

  const DimBgCol = isBillboard ? (
    <Col xs>
      <DimBackground value={+templateData.darken} onChange={() => onToggleField('darken')} />
    </Col>
  ) : null;

  const TitleCol = (isBillboard || isFeatured || isSplit) ? (
    <Col xs style={{ paddingBottom: 16 }}>
      <ShopTitle
        title={(isBillboard || isFeatured || isSplit) ? 'Banner Text' : 'Title'}
        value={templateData.title || title}
        onChange={(value) => onUpdateField('title', value || title)}
      />
    </Col>
  ) : null;
  const ButtonTextCol = (isBillboard || isFeatured || isSplit) ? (
    <Col xs style={{ paddingBottom: 16 }}>
      <ButtonText
        value={templateData.button_text || 'Browse all of our Products'}
        onChange={(value) => onUpdateField('button_text', value || 'Browse all of our Products')}
      />
    </Col>
  ) : null;
  const BgImageCol = (isBillboard || isFeatured || isSplit) ? (
    <Col xs style={{ paddingBottom: 16 }}>
      <BackgroundImage
        value={templateData.background}
        onClick={() => onOpenImagesPopup(POPUP_TYPES.SELECT_BACKGROUND_IMAGE)}
        label={isFeatured ? 'Banner Image' : 'Background Image'}
      />
    </Col>
  ) : null;

  const FeaturedProductsCol = isFeatured ? (
    <Col xs style={{ paddingBottom: 16, }}>
      <FeaturedProductsBlock
        onClick={onOpenFeatureProductsPopup}
      />
      <PlaceholderLine />
    </Col>
  ) : null;

  const AutoScrollCarouselCol = isCarousel ? (
    <Col xs>
      <AutoScrollCarousel
        value={+templateData.autoscroll}
        onChange={() => onToggleField('autoscroll')}
      />
    </Col>
  ) : null;
  const FillBannerCol = isCarousel ? (
    <Col xs style={{ paddingBottom: 16 }}>
      <FillBanner
        value={+templateData.fill}
        onChange={() => onToggleField('fill')}
      />
    </Col>
  ) : null;
  const CarouselImagesCol = isCarousel ? (
    <Col xs style={{ paddingBottom: 16, }}>
      <CarouselImagesBlock
        value={carouselImages}
        onClick={() => onOpenImagesPopup(POPUP_TYPES.SELECT_CAROUSEL_IMAGE)}
      />
    </Col>
  ) : null;

  const CategoryPageCol = isPoster ? (
    <Col xs>
      <LabeledSelect
        label='Page'
        name='ProductPage'
        options={categories}
        value={page}
        data-testid='select_page_dropdown'
        onChange={(e) => {
          const newPage = e ? e.value : '';
          setPage(newPage);
          isPoster && onCategoryPageChange(newPage);
          isPoster && !!newPage &&
            bannerImgRef.current &&
            bannerImgRef.current.onUpdate(bannerDimPercentage(newPage));
        }}
      />
      <PlaceholderLine />
    </Col>
  ) : null;

  const PosterHomeBackgroundCol = isPoster && page === 'HOME' ? (
    <Col xs style={{ paddingBottom: 16 }}>
      <BackgroundImage
        value={templateData.background}
        onClick={() => onOpenImagesPopup(POPUP_TYPES.SELECT_BACKGROUND_IMAGE)}
        label='Background Image'
        testIdPrefix='poster_home_background'
      />
      <PlaceholderLine />
    </Col>
  ) : null;

  const CategoryImagesCol =
    isCarousel || (isPoster && page === "HOME") ? (
      <Col xs>
        <CategoryImages
          value={+templateData.categorytitle}
          onChange={() => onToggleField("categorytitle")}
          onClick={() =>
            onOpenCategoriesPopup({
              bannerOnHover: templateBannerOnHover,
              useProductImagesCheckBox: !isPoster,
              applyToAllCategoriesCheckBox: isPoster,
            })
          }
        />
      </Col>
    ) : null;

  const applyToAllCategories = isPoster && applyBannerToAllCategories;

  const posterCategoryBanner =
    isPoster && page && page !== "HOME" && applyToAllCategories
      ? templateData[`CATEGORY-ALL-IMAGE-BANNER`]
      : page === "ALL_PRODUCTS"
      ? templateData["ALL-PRODUCTS-IMAGE-MAIN"]
      : templateData[`CATEGORY-${page}-IMAGE-BANNER`];

  const CategoryBannerImage =
    isPoster && page && page !== "HOME" ? (
      <Col xs>
        <BannerImage
          ref={bannerImgRef}
          value={posterCategoryBanner}
          onClick={() =>
            onOpenImagesPopup(
              POPUP_TYPES.CATEGORY_BANNER,
              page === "ALL_PRODUCTS"
                ? "ALL-PRODUCTS-IMAGE-MAIN"
                : `CATEGORY-${page}-IMAGE-BANNER`,
              {
                applyToAllCategoriesCheckBox: true,
                applyToAllCategories,
                imageSrc: posterCategoryBanner,
              }
            )
          }
          dimPercentage={bannerDimPercentage(page)}
          onDimPercentageChange={onBannerDimPercentageChange}
        />
      </Col>
    ) : null;

  const pageName = (isPoster && (categories.find(v => v.value === page)?.label)) || 'this shop';

  return (
    <Row>
      {showConfirmPopup && <ConfirmAlertPopup
        title="Are you sure?"
        actionButtonContent="Reset Styles"
        onAction={onReset}
        onClose={() => setConfirmPopup(false)}
        maxWidth={500}
        width="100%"
      >
        <p style={{ display: 'block', fontFamily: 'var(--font-family-regular)', margin: 0, }}>
          You are about to reset Page Styles for {pageName}.
        </p>
        <p style={{ display: 'block', fontFamily: 'var(--font-family-bold)', margin: 0 }}>
          You cannot undo this action!
        </p>
      </ConfirmAlertPopup>}
      {isCarousel && <>
        <Col xs>
          <Text as="p" style={{
            color: 'var(--color-neutrals-90)',
            fontFamily: 'var(--font-family-bold)',
            fontSize: 24,
            marginBottom: 0,
          }}>Carousel</Text>
        </Col>
        {CarouselImagesCol}
        {FillBannerCol}
        {AutoScrollCarouselCol}
      </>}
      {CategoryPageCol}
      {PosterHomeBackgroundCol}
      {BgImageCol}
      {DimBgCol}
      {(isBillboard || isFeatured || isSplit) ? PlaceholderLineCol : null}
      {FeaturedProductsCol}
      {TitleCol}
      {ButtonTextCol}
      {!(isBillboard || isFeatured || isSplit || isPoster) ? PlaceholderLineCol : null}
      {CategoryImagesCol}
      {CategoryBannerImage}
      {PlaceholderLineCol}
      <Col xs style={{ textAlign: 'center' }}>
        <ResetToDefault onClick={() => setConfirmPopup(true)} testId='page_styles'>
          {resetButtonText}
        </ResetToDefault>
      </Col>
    </Row>
  );
};

export default forwardRef(PageStylesPanelContent);