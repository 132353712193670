import React from 'react';
import { CommentBubbleIcon } from '@commonsku/styles';
import { IItem } from '../../interfaces/Item';
import { PresentationComment } from '../../interfaces/PresentationComment';

type TileCommentsProps = {
  item: IItem;
  comments: PresentationComment[];
  showComments?: boolean;
  top?: number;
  left?: number;
  numberLeft?: number;
  numberTop?: number;
};

const TileComments = React.memo(({
  item,
  comments: itemComments,
  showComments = true,
  top = 10,
  left = 5,
  numberLeft = 8,
  numberTop,
}: Readonly<TileCommentsProps>) => {
  const comments = Object.values(itemComments || item.comments);

  if (!comments.length || !showComments) {
    return null;
  }

  const last_comment = comments[comments.length - 1];
  return (
    <span style={{
      position: 'absolute',
      background: 'linear-gradient(137.01deg, #FFFFFF 29.62%, transparent 50%)',
      width: 76,
      height: 76,
      left: left,
      top: top,
    }}>
      <CommentBubbleIcon
        number={comments.length}
        color={1 === +last_comment.rep_comment ? '#152836' : '#5ca3b6'}
        size='large'
        style={{ marginLeft: 9, marginTop: 5, }}
        numberStyle={{
          left: numberLeft,
          color: '#fff',
          ...(numberTop !== undefined ? { top: numberTop } : {}),
          ...(comments.length > 99 ? {
            fontSize: 'small',
            top: 15,
          } : {}),
        }}
      />
    </span>
  );
});

export default TileComments;
