import _ from 'lodash';
import React from 'react';
import Proptype from 'prop-types';
import { colors } from '@commonsku/styles';
import Img from '../Img';
import { getImageSrcByImgPaths } from '../../utils';

export const getNameInitials = (name='', count=2) => {
  const names = (name || '').split(' ');
  if (!names.length) { return ''; }

  if (names.length < 2 || count === 1) { return names[0].slice(0, 1).toUpperCase(); }

  return `${names[0].slice(0, 1).toUpperCase()}${
    count > 2 && names.length > 2
      ? names[names.length-2].slice(0, 1).toUpperCase()
      : ''
  }${names[names.length-1].slice(0, 1).toUpperCase()}`;
};

export const AvatarInitials = ({ name=null, initials=null, backgroundColor=colors.primary, style={}, ...props }) => {
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      width: 48,
      height: 48,
      fontSize: '1.25rem',
      lineHeight: 1,
      borderRadius: '50%',
      overflow: 'hidden',
      userSelect: 'none',
      color: '#fff',
      backgroundColor,
      fontWeight: 'bold',
      ...style
    }} {...props}>{initials || getNameInitials(name)}</div>
  );
};

function Avatar({ user_image_paths={}, src=null, name = '', children=null, backgroundColor=colors.primary, style={}, ...props }) {
  const hasImg = !!_.get(user_image_paths, 'small', '') || !!src;

  return (
    hasImg ? <Img alt={name || 'Avatar Image'}
      style={{borderRadius: '50%', paddingRight: 5, height: 48, width: 48, verticalAlign: 'middle', marginRight: 10, ...style}}
      src={src || getImageSrcByImgPaths(user_image_paths, '/images/user-avatar3-48x48.png')}
      {...props}
    /> : <AvatarInitials
      backgroundColor={backgroundColor}
      name={name}
      style={{marginRight: 10, ...style}}
      {...props}
    />
  );
}

Avatar.propTypes = {
  src: Proptype.string, // required if `user_image_paths` not provided
  user_image_paths: Proptype.shape({
    small: Proptype.string,
  }), // required if `src` not provided
  name: Proptype.string, // used if neither `user_image_paths` or `src` is provided
  backgroundColor: Proptype.string, // used for initials
};

export function AvatarsWrapper({ children, style={}, ...props }) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      ...style
    }} {...props}>{children}</div>
  );
}

export default Avatar;
