import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DropdownItem, useClickOutside  } from '@commonsku/styles';
import styled from 'styled-components';

const SearchDropdown = styled.div`
    display: block;
    background-color: #fff;
    min-width: 160px;
    max-height: 260px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    padding: 8px 8px;
    z-index: 10;
    border-radius: 5px;
    text-align: left;

    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    box-sizing: border-box;
`;

const PortalWrapper = styled.div`
    color: #2A383F;
    -webkit-font-smoothing: antialiased;
    position: fixed;
    z-index: 9999;
    box-sizing: border-box;

    left: 37.7031px;
    width: 338.594px;
    top: 365.5px;

    @media only screen and (max-width: 640px) {
        width: calc(100% - 100px);
        left: 40px;
    }
`;

const MenuPortal = styled.div`
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 5px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    border: none;
`;

const DropdownMenu = (props) => {
    const [open, setOpen] = useState(true);
    const ref = useRef(null);
    useClickOutside({
      ref,
      onClick: () => {
        setOpen(false);
      },
      onCleanup: () => {
        props.onClose && props.onClose();
      }
    });

    return (
        open && createPortal(<PortalWrapper style={{
            left: props.left ? props.left : undefined,
            width: props.width ? props.width : undefined,
            top: props.top ? props.top + 40 : 'calc(50% - 10px)',
        }}>
            <MenuPortal>
                <SearchDropdown ref={ref}>
                    {props.items.map((item, i) => {
                        return item && <DropdownItem key={'dropdown-item-'+i}
                            primary={props.primary}
                            underlined={item.props && item.props.underlined ? item.props.underlined : props.underlined}
                            onClick={() => {
                                setOpen(false);
                                item.onClick && item.onClick();
                            }}
                        >{item.content}</DropdownItem>;
                    })}
                </SearchDropdown>
            </MenuPortal>
        </PortalWrapper>, document.body)
    );
};

export default DropdownMenu;
