import { colors, MagicIcon } from '@commonsku/styles';
import React from 'react'
import styled from 'styled-components';

const ArrowContainer = styled.svg`
  position: absolute;
  left: ${props => props.productSmartEnabled ? "300px" : "80px"};
`;

const TextContainer = styled.div`
  &&& {
    display: flex;
    position: absolute;
    font-size: 18px;
    width: min(${props => props.productSmartEnabled ? "775px" : "429px"}, 90%);
    min-height: 121px;
    top: 100px;
    left: ${props => props.productSmartEnabled ? "280px" : "80px"};
    padding: 16px;
    gap: 18px;
    border-radius: 8px;
    background: ${colors.primary1['20']};
    align-items: center;
    justify-content: center;
    transition: width 0.5s ease;

    @media screen and (max-width: ${props => props.productSmartEnabled ? "1500px" : "800px"}) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

function ProductSearchCallout({ productSmartEnabled }) {
  return (
    <div style={{ position: 'relative', height: 300 }}>
      <ArrowContainer
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        productSmartEnabled={productSmartEnabled}
      >
        <defs>
          <linearGradient y2="0.19835" x2="0.08469" y1="0.01161" x1="0.0275" id="paint0_linear_2001_5747">
            <stop stop-color={colors.primary1.light} />
            <stop stop-opacity="0" stop-color={colors.navy.lightest} offset="1" />
          </linearGradient>
        </defs>
        <g>
          <path id="svg_1" fill={colors.primary1.light} d="m17.252,50.704l23.116,-9.846c0.59,-0.251 1.245,-0.307 1.869,-0.158l24.445,5.811c2.782,0.661 4.824,-2.575 3.03,-4.801l-28.874,-35.824c-1.334,-1.655 -3.919,-1.436 -4.955,0.42l-22.427,40.176c-1.393,2.497 1.165,5.343 3.796,4.222z" />
          <path stroke="null" id="svg_2" fill="url(#paint0_linear_2001_5747)" d="m361.18978,431.02601c-210.05401,-142.82596 -310.8768,-276.91578 -328.98678,-391.94028l16.75167,-2.65473c-1.99885,57.79447 186.77649,187.98841 395.10331,262.6005l-82.8682,131.99451z" />
        </g>
      </ArrowContainer>

      <TextContainer productSmartEnabled={productSmartEnabled}>
        {
          productSmartEnabled ? <>
            <MagicIcon size='huge' />
            <div style={{ color: colors.primary1['70'] }}>
              Explore these AI-driven product recommendations based on your past projects.
            </div>
          </>
            :
            <div style={{ color: colors.primary1['70'] }}>
              Start adding products
            </div>
        }

      </TextContainer>
    </div>
  )
}

export default ProductSearchCallout;