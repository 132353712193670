import React from 'react';

import { formatMoney } from '../utils';

const ItemMarginSummary = ({ item, order_currency_id, onUpdateItem, useMarginCurrency = false }) => {
  return (
    <div className="large-4 column" style={{ height: '100%', padding: '2px' }}>
      <table className="summary">
        <tbody>
	  {(item.currency_id !== order_currency_id && useMarginCurrency) &&
            <tr>
              <td>Currency Conversion</td>
              <td>1 {item.currency_id} = <input type="text" style={{ width: '50%', display: 'inline-block' }} readOnly={true} value={item.exchange_rate} /> {order_currency_id} <button className="button tiny" onClick={e => onUpdateItem(item.exchange_rate)}>Update</button></td>
            </tr>
          }
          <tr>
            <td>Margin</td>
            <td>{parseFloat(item.total_margin).toFixed(2)}%</td>
          </tr>
          <tr>
            <td>Margin Amount</td>
            <td>${formatMoney(item.total_margin_amount)}{item.currency_id !== order_currency_id && <span> {order_currency_id}</span>}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ItemMarginSummary;
