import React, { useState } from 'react';
import { Button, colors, LabeledInput, SharedStyleTypes, Typography } from '@commonsku/styles';
import { useArtworkLibraryContext } from './context';
import { isSafeUrl } from '../../utils';

export interface ImageFromWebProps {
    callback: any,
}

export const ImageFromWeb = ({ ...props }: ImageFromWebProps & SharedStyleTypes) => {
    const { setIsWeb, uploadFromWebError, setUploadFromWebError } = useArtworkLibraryContext();
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');

    return <>
        <LabeledInput
            placeholder='Paste image link here'
            onClick={(e) => { e.stopPropagation(); }}
            style={{ marginBottom: '0' }}
            size={50}
            error={error !== '' || uploadFromWebError}
            label={error !== '' || uploadFromWebError ? <Typography.Span style={{ color: colors.errors['main']}} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>Invalid image link</Typography.Span> : null}
            name='url'
            value={url}
            onChange={(e) => { setUrl(e.target.value); setError(''); }}
        />
        <Button style={{ alignSelf: 'flex-end', minHeight: '38px' }} size='small' disabled={'' === url} onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isSafeUrl(url)) {
                setError('Invalid Url');
                return;
            }

            const result = await props.callback(url);
            if (result) {
                setUrl('');
            }

        }}>Get Image</Button>
        <Button style={{ alignSelf: 'flex-end', minHeight: '38px' }} size='small' secondary onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsWeb(false);
            setUploadFromWebError(false);
        }}>Cancel</Button>
    </>;
};
