import React, { useState } from "react";

const loadedStyle = {
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out, transform 0.4s ease',
    cursor: 'pointer',
};

const loadingStyle = {
    opacity: 0.1,
};

const imageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0 1.5rem 1.5rem 0',
};

const hoverStyle = {
    transform: 'scale(1.1)',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
};

const linkHoverStyle = {
    color: '#333',
    textDecoration: 'underline',
};

const imageItemStyle = {
    width: '20rem',
    height: '11.25rem',
    objectFit: 'cover',
};

export const ImageItem = (props) => {
    const { image, onClick } = props;
    const { id, desc, author, authorUrl, rawUrl, thumbUrl } = image;
    const [ loaded, setLoaded ] = useState(false);
    const [ hover, setHover ] = useState(false);
    const [ linkHover, setLinkHover ] = useState(false);

    return (
        <div style={imageContainerStyle}>
            <img onLoad={() => setLoaded(true)}
                 src={thumbUrl}
                 onClick={() => onClick(id, rawUrl)}
                 alt={desc}
                 title={desc}
                 onMouseEnter={() => setHover(true)}
                 onMouseLeave={() => setHover(false)}
                 style={{...imageItemStyle, ...(hover ? hoverStyle : {}), ...(loaded ? loadedStyle : loadingStyle)}}
            />
            <a href={authorUrl} target="_blank" rel="noreferrer"
               onMouseEnter={() => setLinkHover(true)}
               onMouseLeave={() => setLinkHover(false)}
               style={{marginTop: '0.3rem'}}>
                <span style={linkHover ? linkHoverStyle : {color: "#B8C4CB"}}>{author}</span>
            </a>
        </div>
    );
};