import React from 'react';
import { ConnectedPlusIcon, colors } from '@commonsku/styles';

const psStyle = {
  marginRight: '0.5rem'
};

const STANDARD_IMAGE_SRC = '/images/promostandard-icon.png';

const PromostandardsLabel = ({ style, imageStyle={}, sent = false, iconColor= colors.teal.main }) => {
  return (
    <span style={{ ...psStyle,  ...{ verticalAlign: 'middle', }, ...style }}>
       <ConnectedPlusIcon color={iconColor}/>
    </span>
  );
};

export default PromostandardsLabel;
