import { createSelector } from 'reselect';
import { countries, states } from '../../../geo';
import { ACTIVITY_TYPE_MAP, ADDRESS_TYPE_MAP, ORDER_TYPE_MAP, PHONE_TYPE_MAP } from '../utils';

// ================
// From State
// ================
export const getDeptOptions = createSelector(
    s => s.dropdowns.departments,
    values => (values && Array.isArray(values) && values.map(v => ({label: v.department_name, value: v.department_id}))) || []
);

export const getTagOptions = createSelector(
    s => s.dropdowns.tags,
    values => (values && Array.isArray(values) && values.map(v => ({label: v.label, value: v.label}))) || []
);

export const getClientTagOptions = createSelector(
  s => s.entities.tags,
  s => s.entities.tagged_resources,
  (tags, tagged_resources) => Array.from(new Set(Object.values(tagged_resources).filter(
    tr => tr.resource_type === 'CLIENT'
  ).map(
    tr => tr.tag_id
  ))).map(
    tag_id => ({ label: tags[tag_id].label, value: tags[tag_id].label })
  ).sort(
    (a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0)
  )
);

export const getAllTagOptions = createSelector(
    s => s.entities.tags,
    s => s.entities.tagged_resources,
    (tags, tagged_resources) => Array.from(new Set(Object.values(tagged_resources).map(
      tr => tr.tag_id
    ))).map(
      tag_id => ({ label: tags[tag_id].label, value: tags[tag_id].label })
    ).sort(
      (a, b) => a.label < b.label ? -1 : (a.label > b.label ? 1 : 0)
    )
);

export const getAddressOptions = createSelector(
    s => Object.values(s.entities.addresses),
    values => (values && Array.isArray(values) && values.map(v => ({label: v.address_name, value: v.address_id}))) || []
);

export const getIndustryOptions = createSelector(
    s => s.dropdowns.industries,
    values => (values && Array.isArray(values) && values.map(v => ({label: v.industry_name, value: v.industry_id}))) || []
);

export const getCompanySearchClientOptions = createSelector(
    s => (s.entities.company_search || {}).companies,
    values => (values && Array.isArray(values) && values.map(v => ({label: v.client_name, value: v.client_id}))) || []
);

export const getArtworks = createSelector(
    s => Array.isArray(s.entities.files) ? s.entities.files : Object.values(s.entities.files),
    values => values
);

export const getArtworkFolders = createSelector(
    s => getArtworks(s),
    values => {
        const folders = [{label: 'All', value: ''}];
        values.forEach(v => {
            const folder_name = v && v.folder_name && v.folder_name.toLowerCase();
            if (folder_name && v.folder_id && !folders.filter(f => f.value && f.value === v.folder_id).length) {
              folders.push({label: folder_name, value: v.folder_id});
            }
        });
        return folders;
    }
);

// ================
// Without State
// ================
export const getPhoneTypeOptions = (data=null, props={}) => createSelector(
    s => Object.values(s), // PHONE_TYPE_MAP
    values => (values && Array.isArray(values) && values.map(v => ({label: v, value: v}))) || []
)(data || PHONE_TYPE_MAP, props);

export const getAddressTypeOptions = (props={}) => createSelector(
    () => Object.keys(ADDRESS_TYPE_MAP), // ADDRESS_TYPE_MAP
    keys => (keys && Array.isArray(keys) && keys.map(k => ({label: ADDRESS_TYPE_MAP[k], value: k}))) || []
)(props);

export const getCountryOptions = (props={}) => createSelector(
    s => s, // geo.countries
    values => (values && Array.isArray(values) && values.map(v => ({label: v, value: v}))) || []
)(countries, props);

export const getStateOptions = (country, props={}) => createSelector(
    s => s, // geo.states
    values => (values && Array.isArray(values) && values.map(v => ({label: v.name, value: v.abbr}))) || []
)(states[country] || [], props);

// ================
// Dynamic
// ================
function createOptions(values, idKey, valKey, append, prepend) {
    const result = [].concat(!prepend ? [] : prepend).concat((values && Array.isArray(values) && values.map(v => {
        let val = v[valKey];
        let id = v[idKey];

        if (typeof idKey === 'function') {
            id = idKey(v);
        }
        if (typeof valKey === 'function') {
            val = valKey(v);
        }

        return {key: id, value: id, label: val};
    })) || []).concat(!append ? [] : append);

    return result;
}

export const getOptions = (data, idKey, valKey, append, prepend) => {
    return createSelector(
        s => s,
        values => createOptions(values, idKey, valKey, append, prepend)
    )(data);
};

