import { find, findIndex, filter, get, isNaN, map, size, uniqBy, toString } from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Loading } from "@commonsku/styles";
import { createAddItemLocationPopup, createConfirmationForDeletePopup } from "../../actions/popup";
import { getItem, getProduct, getItemLocations } from "../../selectors/items";
import { getProductConfigurations } from "../../promostandards";

import BasicLocationDropdownMenu from "./BasicLocationDropdownMenu";
import SearchLocationPopup from "./SearchLocationPopup";
import PS from "../PromostandardsLabel";
import { createAddItemLocation } from "../../actions";
import { cleanupPcnaDuplicateLocations, hasCustomLocations } from '../../helpers/ps_locations';
import { createFetchProduct } from "../../actions/product";
import AddNewPSLocationPopup from '../AddNewPSLocationPopup';

const PromostandardsLocationMenu = ({
  locations,
  locked,
  division_id,
  item_id,
  onOptionClick,
  popupTitle,
  children,
  extraOptions,
  ...props
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [_productLocations, setProductLocations] = useState([]);

  const item = useSelector((state) => getItem(state, { item_id }));
  const item_locations = useSelector((state) => getItemLocations(state, { item_id }));
  const extLocationIds = item_locations.map((l) => parseInt(l.ext_location_id, 10));
  const product = useSelector((state) =>
    getProduct(state, { product_id: item.parent_id })
  );
  const quirks = useSelector((state) => {
    return get(state, `entities.promostandards_quirks.${division_id}`);
  });
  const maxLocationRank = get(quirks, "MAXIMUM-LOCATION-RANK");
  const rankedLocations = filter(locations, (l) => {
    return !maxLocationRank || l.location_rank < maxLocationRank;
  });
  const isShopApp = location.pathname.includes("/shop");

  useEffect(() => {
    let ignore = false;
    const loadPSProductConfigurations = async () => {
      const { locations } =
        (await getProductConfigurations({
          productId: product.ext_product_id,
        })) || {};
      if (!ignore) {
        setProductLocations(locations || []);
        setLoading(false);
      }
    };

    if (product?.ext_product_id) {
      loadPSProductConfigurations();
    } else {
      dispatch(createFetchProduct(item.parent_id));
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, product, item.parent_id]);

  const handleAddLocation = useCallback(
    (e) => {
      e.preventDefault();
      // only in shop app do we want to show the popup
      if (isShopApp) {
        dispatch(createAddItemLocationPopup(item_id));
      }
    },
    [dispatch, item_id, isShopApp]
  );

  const productLocations = cleanupPcnaDuplicateLocations(_productLocations, extLocationIds);
  const availableLocations = filter(
    productLocations,
    (l) => !find(extLocationIds, (id) => toString(id) === toString(l.ps_location_id))
  );

  const toOption = (location) => {
    return {
      key: location.ps_location_id,
      value: (
        <span>
          <PS />
          {location.location_name}
        </span>
      ),
      hideOnClick: true,
      onClick: () => {
        if (onOptionClick) {
          onOptionClick(location);
        } else {
          dispatch(createAddItemLocation(item_id, null, null, null, location));
        }
      },
    };
  };

  const options = hasCustomLocations(item_locations) ? [] : uniqBy(
    [
      ...filter(map(rankedLocations, toOption)),
      ...filter(map(availableLocations, toOption)),
      ...(size(rankedLocations) === size(locations)
        ? []
        : [
            {
              key: "view-all-artwork-locations",
              hideOnClick: true,
              value: (
                <div
                  onClick={() => {
                    setShowPopup('search-location');
                  }}
                >
                  View all artwork locations...
                </div>
              ),
            },
          ]),
    ],
    (o) => o.key
  );

  if (loading) {
    return <Loading />;
  }
  if (productLocations.length === 0) {
    if (!isShopApp) {
      options.push(...extraOptions);
    } else {
      return (
        <a
          style={{ padding: "1rem", fontWeight: "bold" }}
          onClick={handleAddLocation}
        >
          + Decoration Location
        </a>
      );
    }
  } else {
    options.push({
      key: 'new-custom-location', value :'+ New location',
      onClick: (e) => {
        setShowPopup('add-new-location');
      },
      style: {
        fontWeight: 'bold',
      },
    });
  }
  return (
    <>
      {showPopup === 'search-location' && (
        <SearchLocationPopup
          title={popupTitle}
          locations={locations}
          toOption={toOption}
          onClose={() => {
            setShowPopup(null);
          }}
        />
      )}
      {showPopup === 'add-new-location' && <AddNewPSLocationPopup item_id={item_id} onClose={() => {
        setShowPopup(null);
      }}/>}
      <BasicLocationDropdownMenu
        options={options}
        children={children}
        {...props}
      />
    </>
  );
};

export default PromostandardsLocationMenu;
