import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Select as BaseSelect,
  CreatableSelect as Creatable,
  AsyncSelect as Async,
  LabeledSelect,
  LabeledCreatableSelect as LabeledCreatable,
  LabeledAsyncSelect as LabeledAsync,
  createFilter,
} from '@commonsku/styles';
import { WindowedMenuList, } from './helpers/Select';

export const parseOptions = (options) => {
  if (!options) { return options; }
  if (options.length && 'key' in options[0]
    && 'value' in options[0]
    && !('label' in options[0])
  ) {
    return options.map(v => ({
      ...v,
      value: v.key,
      label: v.value,
      isDisabled: v.disabled || v.isDisabled,
    }));
  }
  return options;
};

const LARGE_DROPDOWN_CUTOFF = 0;

/**
 * @type {(
 *   typeof BaseSelect
 *    | typeof Creatable
 *    | typeof Async
 *    | typeof LabeledSelect
 *    | typeof LabeledCreatable
 *    | typeof LabeledAsync
 * )}
 */
const Select = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    style,
    filterOptions,
    filterOption,
    components,
    className,
    placeholder,
    defaultValue,
    classNamePrefix='',
    multi = false,
    isMulti = false,
    disabled = false,
    isDisabled = false,
    clearable = false,
    isClearable = false,
    searchable = true,
    isSearchable = true,
    withMarginBottom = false,
    change = _.identity,
    onChange = _.identity,
    onBlur = _.identity, // don't include in props since control input value resets value
    creatable = false,
    async = false,
    labeledCreatable = false,
    labeledAsync = false,
    containerStyles = {},
    value: initialValue,
    error: initialError,
    options: initialOptions,
    ...rest
  } = props;

  const [state, setState] = useState({
    value: initialValue,
    error: initialError,
  });

  useEffect(() => {
    setState({
      value: initialValue,
      error: initialError,
    });
  }, [initialValue, initialError]);

  const options = React.useMemo(
    () => parseOptions(initialOptions),
    [initialOptions]
  );

  const selectAttributes = useMemo(() => {
    const result = {
      id,
      label,
      containerStyles: {...style, ...containerStyles},
      isClearable: isClearable || clearable,
      isSearchable: isSearchable || searchable || (options ? options.length > LARGE_DROPDOWN_CUTOFF : true),
      classNamePrefix: `${classNamePrefix || ''} ${state.error ? 'select-error' : ''} commonsku-styles-select`.trim(),
      className: className,
      placeholder: placeholder,
      isMulti: isMulti || multi,
      isDisabled: isDisabled || disabled,
      error: state.error,
      ...(defaultValue ? { defaultValue } : {}),
      components: components || {},
    };
    if (options?.length >= 700) {
      result.components = {
        MenuList: WindowedMenuList,
        ...result.components,
      };
      result.filterOption = createFilter({ ignoreAccents: false });
    }
    if (withMarginBottom !== false && withMarginBottom !== null && withMarginBottom !== undefined) {
      if (withMarginBottom === true) {
        result.containerStyles = {
          marginBottom: '12px !important',
          ...result.containerStyles,
        };
      } else {
        result.containerStyles = {
          marginBottom: withMarginBottom,
          ...result.containerStyles,
        };
      }
    }
    return result;
  }, [
    state.error,
    label,
    options,
    id,
    style,
    className,
    placeholder,
    disabled,
    defaultValue,
    isDisabled,
    clearable,
    isClearable,
    searchable,
    isSearchable,
    components,
    multi,
    isMulti,
    classNamePrefix,
    containerStyles,
    withMarginBottom,
  ]);

  const handleChange = useCallback((e, actionMeta) => {
    const standIn = { label: selectAttributes.placeholder, value: '' };
    onChange(e ? e : standIn, actionMeta);
    if (change) {
      if (selectAttributes.isMulti) {
        return change(e ? e.map(v => v.value) : [standIn.value]);
      } else {
        return change(e ? e.value : standIn.value);
      }
    }
    let blurValue = e || standIn;
    if(selectAttributes.isMulti) {
      if (!e) {
        blurValue = [];
      } else {
        blurValue = { value: e.map(v => v.value) };
      }
    }
    onBlur?.(blurValue);
  }, [change, onChange, onBlur, selectAttributes.isMulti, selectAttributes.placeholder]);

  const value = useMemo(
    () => {
      if (state.value === null || state.value === undefined) {
        return state.value;
      }

      if (selectAttributes.isMulti) {
        return (options ?? []).filter(v => state.value.includes(v.value));
      } else {
        return (options ?? []).find(v => v.value === state.value) || null;
      }
    },
    [options, state.value, selectAttributes.isMulti]
  );

  if (creatable || labeledCreatable) {
    const Component = creatable ? Creatable : LabeledCreatable;
    const {
      isValidNewOption,
      getNewOptionData,
      formatCreateLabel,
      allowCreateWhileLoading,
      createOptionPosition,
    } = props;

    const onCreateOption = (inputValue) => {
      if (props.onCreateOption) {
        props.onCreateOption(inputValue);
      } else if (props.onNewOptionClick) {
        props.onNewOptionClick({ value: inputValue });
      }
    };

    return (
      <Component
        ref={ref}
        {...rest}
        {...selectAttributes}
        value={value}
        options={options}
        onChange={handleChange}
        filterOption={filterOptions || filterOption}
        createOptionPosition={createOptionPosition}
        allowCreateWhileLoading={allowCreateWhileLoading}
        isValidNewOption={isValidNewOption}
        getNewOptionData={getNewOptionData}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel || props.promptTextCreator}
      />
    );
  }

  if (async || labeledAsync) {
    const Component = creatable ? Async : LabeledAsync;
    const { loadOptions } = props;
    return (
      <Component
        ref={ref}
        {...rest}
        {...selectAttributes}
        value={value}
        loadOptions={loadOptions}
        filterOption={filterOptions || filterOption}
        onChange={handleChange}
      />
    );
  }

  const Component = label ? LabeledSelect : BaseSelect;
  return (
    <Component
      ref={ref}
      {...rest}
      {...selectAttributes}
      value={value}
      filterOption={filterOptions || filterOption}
      options={options}
      onFocus={props.onFocus}
      onMenuOpen={props.onMenuOpen || props.onOpen}
      onChange={handleChange}
    />
  );
});

export default Select;
