export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';

export const hideTooltip = () => {
  return {
    type: HIDE_TOOLTIP
  };
};

export const createItemTooltip = (item) => {
  return {
    type: SHOW_TOOLTIP,
    payload: {
      tooltip: {
        type: 'item',
        item
      }
    }
  };
};
