import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Row, Col, Button, SidePanel, H2 } from '@commonsku/styles';
import { Select } from './helpers';
import { isBrowser } from '../global';

import { createFetchDivision, createFetchSupplier } from '../actions/supplier';

import SupplierBenefits from './SupplierBenefits';

const BenefitsSidepanel = ({ division, divisions, onClose, division_id, supplier_id, dispatch, onChangeDivision, ...props }) => {
  useEffect(() => {
    if (!!supplier_id) {
      dispatch(createFetchSupplier(supplier_id));
    } else if (!!division_id) {
      dispatch(createFetchDivision(division_id));
    }
  }, [division_id, supplier_id]);
  useEffect(() => {
    if (isBrowser()) {
      ReactTooltip.rebuild();
    }
  }, []);
  return (
    <SidePanel
      style={{ padding: '1em', width: '600px', paddingBottom: 15 }}
      visible={!!division}
      header={
        <Row>
          <Col xs style={{textAlign: 'right',}}>
            <Button
              style={{lineHeight: 'normal'}}
              onClick={(e) => {
                onClose && onClose(e);
              }}>Close</Button>
                    </Col>
          <Col xs>
            <H2 style={{
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
            }}>{division && (divisions.length > 1 ? division.supplier_name : (division.division_name || division.supplier_name))}</H2>
              {(division && divisions.length > 1) &&
                <Select
                  options={divisions.map(d => ({ value: d.division_id, label: d.division_name }))}
                  value={division.division_id}
                  onChange={option => onChangeDivision(option.value)}
                />}
          </Col>
        </Row>
      }
    >
      {!!division && <SupplierBenefits supplier={division} />}
    </SidePanel>
  );
};

const mapStateToProps = (state, ownProps) => {
  const divisions = Object.values(state.entities.divisions).filter(d => d.supplier_id === ownProps.supplier_id);
  return {
    division: ownProps.division || state.entities.divisions[ownProps.division_id] || divisions[0],
    divisions
  };
};

export default connect(mapStateToProps)(BenefitsSidepanel);
