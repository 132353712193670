import { createContext, useContext, useEffect } from 'react';

import usePublicViewTemplateData from '../hooks/usePublicViewTemplateData';

/**
 * @type {React.Context<undefined | ReturnType<typeof usePublicViewTemplateData>>} publicViewTemplateDataContext
 */
const PublicViewTemplateDataContext = createContext({});

const PublicViewTemplateDataProvider = ({ children, initialValue }) => {
  const value = usePublicViewTemplateData(initialValue);

  const { setState } = value;
  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  return (
    <PublicViewTemplateDataContext.Provider value={value}>
      {children}
    </PublicViewTemplateDataContext.Provider>
  );
};

export const usePublicViewTemplateDataContext = () => {
  const value = useContext(PublicViewTemplateDataContext);

  return value;
};

export default PublicViewTemplateDataProvider;
