import _ from 'lodash';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { formatMoney } from '../utils';
import { registerESPClickImpression } from '../esp';


class Product extends Component {

  constructor(props) {
    super(props);

    let storage = window.localStorage.getItem('commonsku_supplier_products');
    let saved_items = [];
    if(storage) {
      saved_items = JSON.parse(storage);
    }

    this.state = {
      selected: saved_items.length > 0 ? _.indexOf(saved_items.map(p => p.product_id), props.product.product_id) !== -1 : false
    };

    this.handleAddLocalStorage = this.handleAddLocalStorage.bind(this);
  }

  handleAddLocalStorage(product) {
    if(!this.state.selected) {
      let storage = window.localStorage.getItem('commonsku_supplier_products');
      let saved_items = [];
      if(storage) {
        saved_items = JSON.parse(storage);
      }
      saved_items.push(product);

      let temp = window.localStorage.getItem('commonsku_add_product_ids');
      let product_ids = [];
      if(temp) {
        product_ids = JSON.parse(temp);
      }
      product_ids.push(product.product_id);

      this.setState({ selected: true }, function() {
        window.localStorage.setItem('commonsku_supplier_products', JSON.stringify(saved_items));
        window.localStorage.setItem('commonsku_add_product_ids', JSON.stringify(product_ids));
      });

      //selected state color is not passing into render
      let product_info = findDOMNode(this.refs.info);
      product_info.setAttribute('style', "background: #5CA3B6 !important; height: 100%;");
    }
  }

  buildProductUrl() {
    const { product } = this.props;
    const params = {
      id: product.product_id,
      source: product.source,
      sku: product.product_supplier_code,
      sage_id: product.sage_id || '',
      sage_connect: product.sage_connect || '',
      currency_id: product.currency_id || ''
    };
    const query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    return `/product.php?${query}`;
  }

  render() {
    const { product, onAddProduct, loading, loaded, type, className, isProductsPage, supplier_popup, removeSageProduct } = this.props;
    const url = loading ? '/images/gears.gif' :
               (loaded ? null :
               (product.product_img ? product.product_img.replace('files', '/files') : null));
    const image_style = {
      backgroundImage: `url('${url}')`,
      backgroundColor: loaded ? '#488ea1' : null,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: loading ? 'center' : 'top center',
      backgroundSize: loading ? '60px 60px' : 'cover',
      marginTop: '15px',
      marginBottom: '15px'
    };
    const link_style = {
      position: 'absolute',
      top: '5px',
      right: '5px'
    };
    const supplier_rating = ['rating-0', 'rating-1', 'rating-2', 'rating-3', 'rating-4', 'rating-5', 'rating-6', 'rating-7', 'rating-8', 'rating-9', 'rating-10'];
    const product_href = this.buildProductUrl();
    // `/product.php?id=${product.product_id}&source=${product.source}&sku=${product.product_supplier_code}${product.sage_id ? '&sage_id='+product.sage_id : ''}`;
    const handleClick = e => {
      e.stopPropagation();
      if (!loading) {
        if (isProductsPage) {
          if (supplier_popup) {
            this.handleAddLocalStorage(product);
          } else {
            window.open(product_href, '_blank');
          }
        } else {
          onAddProduct(product.product_id, product.product_supplier_code, product.division_id, product.currency_id);
        }
        if (product.esp_ad_index) {
          registerESPClickImpression(product.product_id, product.esp_ad_id, product.esp_ad_index, product.esp_account_id);
        }
      }
    };
    return (
      <div className={'large-2 medium-3 small-6 columns ' + className} style={{ position: 'relative' }}>
        <div className="search-result" style={image_style} onClick={handleClick}>
          {product.esp_ad_index ? <div className="sponsored-banner">ESP sponsored</div> : null}
          {product.supplier_preferred && product.supplier_preferred.Rank <= 5 ?
            <div className="preferred-banner">Rank {product.supplier_preferred.Rank}
              {product.supplier_preferred.Name ? `- ${product.supplier_preferred.Name}` : null}
            </div>
            : null}
          <div ref="info" className="product-info" data-heap-product-card style={this.state.selected ? { background: '#5CA3B6 !important', height: '100%' } : null}>
            <a className="vendor-page" href="#" target="_blank">{product.division_name}</a>
            <h4>{product.product_name}</h4>
            {product.product_discriminator ? <h4>{product.product_discriminator}</h4> : null}
            {product.cost1 ? <span className="price">${formatMoney(product.cost1)} ({product.currency_id})</span> : ''}
            <div className="sku">#{product.product_supplier_code}<br /></div>
            {product.supplier_rating && <div style={{ 'fontSize': '9px', 'lineHeight': '16px' }}>Supplier Rating:
              <div className={supplier_rating[product.supplier_rating]}></div>
            </div>}
            {product.sage_request_id ?
              <button className="button small" onClick={e => { e.stopPropagation(); removeSageProduct(product.product_id); }}>Remove</button>
              : null}
          </div>
        </div>
        {!isProductsPage ?
          <a href={product_href} target="_blank" aria-describedby="ui-tooltip-27" style={link_style}>
            <img src="/images/popup.png" />
          </a>
          : null}
      </div>
    );
  }
}

export default Product;
