import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@commonsku/styles';
import { addressById } from '../../selectors/addresses';

export default function PreviewAddress({ address_id, style={} }) {
    const address = useSelector(s => addressById(s, address_id));

    if (!address || !address_id) {
        return null;
    }

    return (
        <Text as="p" style={style}>
            <Text as="span" style={{ display: 'block' }}>{address.address_line_1}</Text>
            {address.address_line_2 ?
                <Text as="span" style={{ display: 'block' }}>{address.address_line_2}</Text>
            : null}
            {address.address_line_3 ?
                <Text as="span" style={{ display: 'block' }}>{address.address_line_3}</Text>
            : null}
            {address.address_city
                || address.address_state
                || address.address_postal
                || address.address_country ?
                <Text as="span" style={{ display: 'block' }}>
                    {address.address_city}
                    {address.address_city ? ', ' : ''}
                    {address.address_state}
                    {address.address_city
                        || address.address_state
                        || address.address_postal ? ', ' : ''}
                    {address.address_postal}
                    {address.address_city
                        || address.address_state
                        || address.address_postal
                        || address.address_country ? ', ' : ''}
                    {address.address_country}
                </Text>
            : null}
        </Text>
    );
}

