import React from 'react';

import { getAbsoluteUrl } from '../../utils';

const ProductPreviousOrder = ({ product, onAddProduct, loading, loaded, className }) => {
  const image_path = product.file_path.replace('small', 'medium');
  const url = loading ? '/images/gears.gif' : (loaded ? null : getAbsoluteUrl(image_path));
  const imageStyle = {
    backgroundImage: `url('${url}')`,
    backgroundColor: loaded ? '#488ea1' : null,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: loading ? 'center' : 'top center',
    backgroundSize: loading ? '60px 60px' : 'cover',
    marginTop: '15px',
    marginBottom: '15px'
  };

  const itemNameStyle = {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'italic'
  };

  const handleClickProduct = e => {
    if (!loading) {
      onAddProduct(product.item_id);
    }
  };

  const handleClickOrder = e => {
    e.stopPropagation();
    window.open(`/order.php?id=${product.order_id}`, '_blank');
  };

  return (
    <div className={'large-2 medium-3 small-6 columns ' + className} onClick={handleClickProduct}>
      <div className="search-result previous-order" style={imageStyle}>
        <div className="product-info">
          <a className="order" onClick={handleClickOrder}>
            {product.form_number} - {product.job_name.length > 10 ? `${product.job_name.substr(0, 10)}...` : product.job_name}
          </a>
          <div className="item-name" style={itemNameStyle}>{product.item_name}</div>
          {product.order_type !== 'PRESENTATION' &&
            <div>
              <span className="price">Total Price: ${product.total_total}</span>
              <span className="price">Total Units: {product.total_units}</span>
            </div>}
          <div className="sku">
            {product.date_orderdate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPreviousOrder;
