import { UPDATE_COMMISSION_SUCCESS, ADD_COMMISSION_SUCCESS, DELETE_COMMISSION_SUCCESS, LOAD_COMMISSION_SUCCESS, REMOVE_ESTIMATED_COMMISSION_SUCCESS } from '../actions/commission';
import { DELETE_ORDER_SUCCESS } from '../actions/order';

const reduce = (state = {}, action) => {
  switch (action.type) {
    case ADD_COMMISSION_SUCCESS:
      return Object.assign({}, state, {[action.payload.id]: action.payload.data});
    case UPDATE_COMMISSION_SUCCESS:
      const commission_id = action.payload.id;
      const updated_commission = Object.assign({}, state[commission_id], action.payload.data);
      return Object.assign({}, state, {[commission_id]: updated_commission});
    case DELETE_COMMISSION_SUCCESS:
      return Object.keys(state).filter(c => c !== action.payload.id).reduce((t, k) => { t[k] = state[k]; return t;}, {});
    case LOAD_COMMISSION_SUCCESS:
      return action.payload.commissions.reduce((o, c) => { if (c.estimated) { o[c.sales_rep_id] = c; } else { o[c.commission_id] = c; } return o; }, {});
    case REMOVE_ESTIMATED_COMMISSION_SUCCESS:
      return Object.values(state).filter(c => !c.estimated).reduce((o, c) => { o[c.commission_id] = c; return o; }, {});
    case DELETE_ORDER_SUCCESS:
      return Object.keys(state).filter(k => state[k].order_id !== action.payload.data.parent_order_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
  }
  return state;
};

export default reduce;
