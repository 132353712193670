import _ from 'lodash';
import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getUserOptions } from '../selectors/dropdowns';

import Select from './Select';
import { Input } from '@commonsku/styles';
import { isZeroDate } from '../utils';

const FILTER_DEFAULT_VALUE = 'ALL';

/* const TaskFilter = ({ posts, ...props }) => {
  return <Select options={[
    { key: FILTER_DEFAULT_VALUE, value: "Show all messages"},
    { key: 'REMINDER', value: "Show only tasks"},
  ]} {...props}/>
}
TaskFilter.filter = (posts, value) => {
  if (FILTER_DEFAULT_VALUE === value) {
    return posts;
  } else if ('REMINDER' === value) {
    return _.filter(posts, p => p.note && !isZeroDate(_.get(p, 'note.date_reminder')) && _.get(p, 'note.reminder_user_full_name'));
  }
} */

const ContactFilter = ({ posts, ...props }) => {
  return <Select options={[
    { key: FILTER_DEFAULT_VALUE, value: "Show all"},
    { key: 'NOTE', value: "Show notes"},
    { key: 'CALL', value: "Show calls"},
    { key: 'MEETING', value: "Show meetings"},
    { key: 'ACTIVITY', value: "Show activities"},
  ]} {...props}/>;
};
ContactFilter.filter = (posts, value) => {
  if (FILTER_DEFAULT_VALUE === value) {
    return posts;
  } else if (['NOTE', 'CALL', 'MEETING'].indexOf(value) > -1) {
    return _.filter(posts, p => _.get(p, 'note.note_type') === value);
  }else if('ACTIVITY' === value) {
    return _.filter(posts, p => p.event_id);
  }
};

export const searchPost = (post, value) => {
  const text = _.get(post, ['text']) || _.get(post, ['message_text']) || '';
  const actorFirstName = (_.get(post, ['actor', 'user_first_name']) || '');
  const actorLastName = (_.get(post, ['actor', 'user_last_name']) || '');
  const clientRepFName = (_.get(post, ['client_rep_first_name']) || '');
  const clientRepLName = (_.get(post, ['client_rep_last_name']) || '');

  return text.toLowerCase().includes(value)
    || (_.get(post, ['subject']) || '').toLowerCase().includes(value)
    || (_.get(post, ['details_type']) || '').toLowerCase().includes(value)
    || (_.get(post, ['details_parent_type']) || '').toLowerCase().includes(value)
    || (_.get(post, ['order_type']) || '').toLowerCase().includes(value)
    || (_.get(post, ['client_name']) || '').toLowerCase().includes(value)
    || clientRepFName.toLowerCase().includes(value)
    || clientRepLName.toLowerCase().includes(value)
    || actorFirstName.toLowerCase().includes(value)
    || actorLastName.toLowerCase().includes(value)
    || `${actorFirstName} ${actorLastName}`.toLowerCase().includes(value)
    || `${clientRepFName} ${clientRepLName}`.toLowerCase().includes(value)
    || (_.get(post, ['event_payload']) || '').toLowerCase().includes(value)
    || (_.get(post, ['job_name']) || '').toLowerCase().includes(value)
    || (_.get(post, ['comment']) || '').toLowerCase().includes(value);
};
const SearchFilter = ({ posts, ...props }) => {
  return (
    <Input placeholder='Search...' {...props} />
  );
};
SearchFilter.defaultValue = '';
SearchFilter.transformValue = (e) => e.target.value;
SearchFilter.filter = (posts, value) => {
  if ((value || '').length < 3) { return posts; }

  return posts.filter(post => {
    return searchPost(post, value.toLowerCase())
      || (
        post.replies && post.replies.length > 0
        && SearchFilter.filter(post.replies, value.toLowerCase()).length > 0
      );
  });
};

const getMentionedUsers = (posts) => {
  const mentioned_users = new Set();
  const getUsers = (p) => {
    if (!p) return [];

    mentioned_users.add(p.actor.user_id).add(_.get(p, 'note.reminder_user'));
    _.each(p.tags, t => mentioned_users.add(t.user_id));
    _.each(_.concat(p.replies, p.comments), (r) => {
      getUsers(r);
    });
  };

  _.each(posts, (p) => {
    getUsers(p);
  });
  _.each(['', null, void(0)], (invalid_id) => {
    mentioned_users.delete(invalid_id);
  });

  return Array.from(mentioned_users);
};

const UserFilter = connect((state) => {
  return {
    users: getUserOptions(state)
  };
})(({
  posts, users, ...props
}) => {
  const mentioned_users = getMentionedUsers(posts);
  const options = [
    { key: FILTER_DEFAULT_VALUE, value: "Show everyone's comments"},
  ].concat(users.filter(u => mentioned_users.indexOf(u.key) > -1));

  return <Select options={options} {...props}/>;
});
UserFilter.filter = (posts, value) => {
  return value === FILTER_DEFAULT_VALUE ? posts : _.filter(posts, (post) => {
    const users = getMentionedUsers([post]);
    return users.indexOf(value) > -1;
  });
};

const OrderTypes = {
  OPPORTUNITY: 'Opportunity',
  PRESENTATION: 'Presentation',
  ESTIMATE: 'Estimate',
  'SALES ORDER': 'Sales Order',
  'PURCHASE ORDER': 'Purchase Order',
  INVOICE: 'Invoice',
  REMINDER: 'Task',
};

const OrderTypeFilter = ({ posts, ...props }) => {

  const _addOrderType = (type, set) => {
    if (type in OrderTypes) {
      set.add(type);
    }
  };

  const types = new Set();
  _.each(posts, ({ details_parent_type, details_parent_order_type, note }) => {
    // deprecated type, use 'PURCHASE ORDER' instead
    if (details_parent_type === 'PURCHASE_ORDER') {
      details_parent_type = 'PURCHASE ORDER';
    }
    if (details_parent_type) {
      _addOrderType(details_parent_type, types);
      if (details_parent_type !== 'PURCHASE ORDER') {
        _addOrderType(details_parent_order_type, types);
      }
    }
    if (note && !isZeroDate(note.date_reminder) && note.reminder_user_full_name !== '') {
      _addOrderType('REMINDER', types);
    }
  });
  const options = [
    { key: FILTER_DEFAULT_VALUE, value: 'All', }
  ].concat(_.map(Array.from(types), (type) => {
    return { key: type, value: OrderTypes[type] };
  }));

  return <Select options={options} {...props}/>;
};
OrderTypeFilter.filter = (posts, value) => {
  if (value === FILTER_DEFAULT_VALUE) {
    return posts;
  }
  return _.filter(posts, ({ details_parent_type, details_parent_order_type, note }) => {
    if(value === 'REMINDER') {
      return note && !isZeroDate(note.date_reminder) && note.reminder_user_full_name !== '';
    }

    return (['PURCHASE_ORDER', 'PURCHASE ORDER'].includes(details_parent_type) && 'PURCHASE ORDER' === value) || (details_parent_order_type === value);
  });
};

class FeedFilter extends Component {
  constructor(props) {
    super(props);

    const filters = {};
    _.each(props.filters, (Filter, i) => {
      filters[i] = Filter.defaultValue !== undefined
        ? Filter.defaultValue
        : FILTER_DEFAULT_VALUE;
    });

    this.state = { filters };

    _.bindAll(this, ['setFilters']);
  }

  setFilters(nextFilters) {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        ...nextFilters,
      },
    });
  }

  filterPosts() {
    const { posts } = this.props;
    const { filters } = this.state;

    let filtered_posts = posts;
    _.each(this.props.filters, (Filter, i) => {
      filtered_posts = Filter.filter(filtered_posts, filters[i]);
    });
    return filtered_posts;
  }

  renderFilters() {
    const { posts } = this.props;
    const { filters } = this.state;
    const filtersCls = this.props.filters;

    return <div className="row">
      {_.map(filtersCls, (Filter, i) => {
        return <div key={i}
          className={`columns small-${Math.floor(12 / filtersCls.length)}`}
        >
          <Filter posts={posts} value={filters[i]} onChange={(e) => {
            let value = e.value;
            if (Filter.transformValue && typeof Filter.transformValue === 'function') {
              value = Filter.transformValue(e);
            }
            this.setFilters({ [i]: value });
          }}/>
        </div>;
      })}
    </div>;
  }

  renderFeed() {
    const { children } = this.props;
    const posts = this.filterPosts();
    if (!posts.length) {
      return <div className="row">
        <div className="columns small-12">
          Nothing to see here...  maybe you should post something.
        </div>
      </div>;
    }

    return React.cloneElement(Children.only(children), { posts });
  }

  render() {
    return <div>
      {this.renderFilters()}
      {this.renderFeed()}
    </div>;
  }
}

FeedFilter.propTypes = {
  posts: PropTypes.array,
  filters: PropTypes.array,
  filter: PropTypes.func,
  renderFilters: PropTypes.func,
};

FeedFilter.defaultProps = {
  posts: [],
  filters: [],
};

FeedFilter.UserFilter = UserFilter;
FeedFilter.OrderTypeFilter = OrderTypeFilter;
FeedFilter.ContactFilter = ContactFilter;
FeedFilter.SearchFilter = SearchFilter;
//FeedFilter.TaskFilter = TaskFilter;

export default FeedFilter;
