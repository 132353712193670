import _ from 'lodash';
import React from 'react';

import { formatMoney, getImageSrc } from '../utils';

import Img from './Img';

const ProductItemTooltip = ({item, position}) => {
  const default_image = item.item_images.map(ii => ii.image)[0];
  const division_name = _.get(item, 'division_name') || _.get(item, 'origin.division_name') || _.get('options[0].origin.division_name');
  const phone_number = _.get(item, 'product.division_phones', []).concat(_.get(item, 'origin.phones', [])).filter(p => p.phone_type === 'WORK').map(p => p.phone_number)[0];
  return (
    <div className="tooltip" role="tooltip" style={position}>
      <Img src={getImageSrc(default_image)} />
      <h5>{item.item_name}</h5>
      <p>{item.item_sku}</p>
      <p>{division_name}</p>
      <p>{phone_number}</p>
      <p>{item.item_description}</p>
    </div>
  );
};

export default ProductItemTooltip;
