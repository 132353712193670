import React from 'react';
import { DEFAULT_COLOR, hasInventory } from './utils';

export default function Inventory({
  item,
  showInventory = false,
  template_color = DEFAULT_COLOR,
}) {
  if (!hasInventory({ item, showInventory })) {
    return null;
  }

  const style = {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
    background: template_color,
    color: 'white',
    opacity: '0.8',
    borderRadius: '5rem',
    padding: '0.5rem',
    lineHeight: '1rem',
  };

  return (
    <span style={style}>
      Inventory
    </span>
  );
}
