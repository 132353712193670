import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getPart, getProduct } from '../promostandards';

export const usePsProduct = (productID) => {
  const [product, setProduct] = useState(null);
  const [part, setPart] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getProduct({ productId: productID })
    .then((product) => {
      setProduct(product);
      const partId = get(product, 'parts.0.id');
      return partId ? getPart({ partId }) : null;
    })
    .then((part) => {
      setPart(part);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, [productID]);

  return { product, part, loading };
};
