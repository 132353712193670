import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { botSelectors, botsLoading, botsReceived, botAdded, botUpdated } from '../../../redux/chatBots';
import { oauth } from '../../../utils';

export default function useChatbots(getBots) {
    const dispatch = useDispatch();
    const botsState = useSelector(state => state.chatBots);
    const bots = botsState ? botSelectors.selectAll(botsState) : [];

    const addNewBot = useCallback(() => {
        const addBotCall = async () => {
            const res = await oauth('POST', 'chat-bot', {
                name: 'New Bot Name',
                chat_welcome_message: 'Welcome message',
                chat_offline_message: 'Offline message',
                chat_notification_user: 'client_rep_role,sale_rep_role',
            });
            dispatch(botAdded(Object.assign(res.json.bot, {new: true})));
        };

        addBotCall();
    }, [dispatch]);

    const updateBot = useCallback(({ bot_id, name, chatOnlineMessage, chatOfflineMessage, chatNotificationUser }) => {
        if (name.length === 0 || chatOnlineMessage.length === 0 || chatOfflineMessage.length === 0 || chatNotificationUser.length === 0) {
            toast.error("Please fill in online/offline message and select a user to notify.");
            return;
        }
        const updateBotCall = async () => {
            const res = await oauth('PUT', 'chat-bot', {
                bot_id,
                name,
                chat_welcome_message: chatOnlineMessage,
                chat_offline_message: chatOfflineMessage,
                chat_notification_user: chatNotificationUser.join(','),
            });
            dispatch(botUpdated({ id: bot_id, changes: res.json.bot }));
            toast.success("Saved successfully");
        };

        updateBotCall();
    }, [dispatch]);

    useEffect(() => {
        if (!getBots) {
            return;
        }

        dispatch(botsLoading());
        const fetchData = async () => {
            const res = await oauth('GET', 'chat-bot', {});
            dispatch(botsReceived(res.json.bots));
        };

        fetchData();
    }, [dispatch, getBots]);

    return [
        bots,
        addNewBot,
        updateBot,
    ];
};
