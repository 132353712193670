import { filter, get, isEmpty, map, trim } from 'lodash';

import { Button, Popup, Row, H4 } from '@commonsku/styles';

const Address = ({ address }) => {
  const {line1, line2, line3, city, region, country, postalCode} = address || {};
  return <div>
    {map([line1, line2, line3], (line) => {
      return !!isEmpty(line) && <div>{line}</div>;
    })}
    <div>{filter([city, region, postalCode, country], trim).join(', ')}</div>
  </div>;
};

const AddressErrorPopup = ({ error, resolution, ignoreError, onClose }) => {
  return <Popup
    width='auto' height='auto'
    onClose={onClose}
    controls={<span
      style={{ fontSize: '1.6rem', opacity: 0.5, cursor: 'pointer', marginTop: '-10px' }}
      onClick={onClose}
    >&times;</span>}
  >
    {!isEmpty(error) && map(error.details, (detail, i) => {
      return <Row key={i}>
        <H4>{detail.severity}: {detail.message}</H4>
        <div>{detail.description}</div>
      </Row>;
    })}
    {!isEmpty(get(resolution, 'messages')) && [
      map(resolution.messages, ({ summary, details, severity }, i) => {
        return <Row key={i}>
          <H4>{severity}: {summary}</H4>
          <div>{details}</div>
        </Row>;
      }),
      !isEmpty(resolution.validatedAddresses) && <Row>
        <Address address={resolution.validatedAddresses}/>
      </Row>,
    ]}
    <Row>
      <Button onClick={() => {
        if (ignoreError) {
          ignoreError();
        }
        onClose();
      }}>Ignore Error</Button>
    </Row>
  </Popup>;
};

export default AddressErrorPopup;