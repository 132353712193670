import React from 'react';
import { connect } from 'react-redux';

import Callout from '../components/Callout';
import { createDismissCallout } from '../actions/callout';

const MessageCallout = ({message, onDismissCallout}) => {
  return <Callout key={message.key} type={message.type}
    onClose={onDismissCallout(message.key)}>
    <p>{message.message}</p>
  </Callout>;
};

const mapDispatchToProps = dispatch => ({
  onDismissCallout: key => () => {
    dispatch(createDismissCallout(key));
  },
});

export default connect(state => ({}), mapDispatchToProps)(MessageCallout);
