import { get, isFunction } from 'lodash';
import React from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { RenderChild } from '@commonsku/styles';
import useDraggableItem from '../hooks/useDraggableItem';

const dragSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      props: props,
    };
  },
  canDrag(props, monitor) {
    const canDrag = get(props, 'canDrag', true);
    if (isFunction(canDrag)) return canDrag(props, monitor);
    return canDrag;
  },
  endDrag(props, monitor, component) {
    if (isFunction(props.endDrag)) {
      return props.endDrag(props, monitor, component);
    }
  },
};

const dropTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const dragItemProps = monitor.getItem().props;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the item's height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    if (dragItemProps.display !== 'grid' || props.isTitle) {
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
    } else {
      // In grid mode, we should detect X, instead of
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Dragging right
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging left
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
    }

    // In grid, we will need to treat a special drag down event
    // Check we have a last item index in the title item we hover over
    // If yes, we should place the item to the last for the hover over title's items list
    const dragDown = dragIndex < hoverIndex;
    let targetIndex = hoverIndex;
    if (dragDown && props.lastItemIndex) {
      targetIndex = props.lastItemIndex;
    }

    props.onMove(dragIndex, targetIndex);

    monitor.getItem().index = targetIndex;
  },

  drop(props, monitor) {
    if (isFunction(props.onDrop)) {
      props.onDrop(monitor.getItem());
    }
  }
};

const DraggableItem = (type, DraggableComponent) => {
  const target = DropTarget(type, dropTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }));
  const source = DragSource(type, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }));
  return target(source(DraggableComponent));
};

const Containers = {};
const getDraggableContainer = (type) => {
  if (!Containers[type]) {
    Containers[type] = DraggableItem(type, ({ connectDragSource, connectDropTarget, children }) => {
      return connectDragSource(connectDropTarget(<div>{children}</div>));
    });
  }
  return Containers[type];
};

export const DraggableContainer = ({ type, children, ...props }) => {
  const Container = getDraggableContainer(type);
  return <Container {...props}>{children}</Container>;
};

export default DraggableItem;

export const DraggableItemContainer = ({
  type,
  index,
  onMove,
  onDrop,
  item,
  id,
  children,
  endDrag,
  canDrag = true,
  passDndProps = false,
  ...props
}) => {
  const canDragFn = (monitor) => {
    if (typeof canDrag === 'function') {
      return canDrag({ ...props, id, index, item }, monitor);
    } else {
      return canDrag;
    }
  };

  const {
    drag, drop, handlerId, isDragging, ref,
  } = useDraggableItem({
    type, id, index, item,
    onDrop, onMove,
    endDrag,
    canDrag: canDragFn,
  });

  drag(drop(ref));

  return (
    <RenderChild
      {...props}
      ref={ref}
      data-handler-id={handlerId}
      {...(passDndProps ? { handlerId, isDragging } : {})}
    >{children}</RenderChild>
  );
};

export const withDraggableItem = (type, Component) => ({
  id,
  index,
  item,
  onMove,
  onDrop,
  endDrag,
  canDrag = true,
  passDndProps = false,
  ...props
}) => {
  const canDragFn = (monitor) => {
    if (typeof canDrag === 'function') {
      return canDrag({ ...props, id, index, item }, monitor);
    } else {
      return canDrag;
    }
  };

  const {
    drag, drop, handlerId, isDragging, ref,
  } = useDraggableItem({
    type, id, index, item,
    onDrop, onMove,
    endDrag,
    canDrag: canDragFn,
  });

  drag(drop(ref));

  return (
    <Component
      {...props}
      rootRef={ref}
      data-handler-id={handlerId}
      handlerId={handlerId}
      isDragging={isDragging}
      item={item}
      index={index}
      onMove={onMove}
      onDrop={onDrop}
    />
  );
};
