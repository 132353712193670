import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import DragItemTypes from '../DragItemTypes';
import Img from './Img';

import { getImageSrc } from '../utils';

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const productArtworkImageSource = {
  canDrag(props) {
    // You can disallow drag based on props
    return true;
  },

  isDragging(props, monitor) {
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    return monitor.getItem().artwork_id === props.artwork.artwork_id;
  },

  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    const item = { artwork_id: props.artwork.artwork_id, item_location_id: props.artwork.item_location_id, item_decoration_id: props.artwork.item_decoration_id, ext_decoration_id: props.ext_decoration_id, artwork: props.artwork };
    return item;
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      // You can check whether the drop was successful
      // or if the drag ended but nobody handled the drop
      return;
    }

    // When dropped on a compatible target, do something.
    // Read the original dragged item from getItem():
    const item = monitor.getItem();

    // You may also read the drop result from the drop target
    // that handled the drop, if it returned an object from
    // its drop() method.
    const dropResult = monitor.getDropResult();
  }
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

class ProductArtworkImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOver && nextProps.isOver) {
      // You can use this as enter handler
    }

    if (this.props.isOver && !nextProps.isOver) {
      // You can use this as leave handler
    }

    if (this.props.isOverCurrent && !nextProps.isOverCurrent) {
      // You can be more specific and track enter/leave
      // shallowly, not including nested targets
    }
  }

  render() {
    const { artwork, onEditArtwork, locked, hasAssociatedCharges } = this.props;

    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const { isDragging, connectDragSource } = this.props;

    return connectDragSource(
      <div>
        <Img
          onClick={e => {
            e.stopPropagation();
            if (onEditArtwork != null) {
              onEditArtwork(artwork, locked, false, hasAssociatedCharges);
	    }
	  }}
          src={getImageSrc(artwork.image)}
        />
      </div>
    );
  }
}

export default DragSource(DragItemTypes.ARTWORK, productArtworkImageSource, collect)(ProductArtworkImage);
