export const CELL_WIDTH = 160; // all cells with
export const CELL_ROW_DEF_WIDTH = 200; // first column

export function isObj (value) {
    return value instanceof Object || typeof value === 'object';
}

export const createRow = (cols, index, value) => {
    const _cols = cols;
    const item = {id: index, row_num: index};
    const valIsObj = isObj(value);

    _cols.forEach((val, idx) => {
        const _val = idx==0 ? '-' : (val['key'] ? val['key'] : val);
        if (_val == '-') {
            item['-'] = valIsObj ? value.value : value;
        } else {
            item[_val] = (idx > 0) ? "" : index;
        }
    });

    if (valIsObj) {
        Object.keys(value).forEach(v => {
            if (v != 'value') {
                item[v] = value[v];
            }
        });
    }
    return item;
};

export const generateColDef = (val, _idx, until, units) => {
    const idx = val.idx ? val.idx : _idx;
    const label = (idx==0) ? '-' : val.label ? val.label : val.toString();
    const key = val.key ? val.key : (idx==0) ? '-' : idx.toString();
    const size = val.size ? val.size : CELL_WIDTH;
    const style = (idx==0) ? {color: 'white'} : {};

    const colDef = {
        label, key, size, style, idx,
        until: until ? until : (val['until'] ? val['until'] : ''),
        units: units ? units : '',
    };

    if (idx == 0) {
        colDef['size'] = CELL_ROW_DEF_WIDTH;
        colDef['input_component'] = {
            isHeader: false,
            idxKey: 'id',
            inputLabel: '',
            valueKey: '-',
            checkNumber: true,
            checkValOnUpdate: true,
            noInputLabel: true,
            inputStyles: {textAlign: 'left'},
            hasDel: true,
        };

        colDef['input_header'] = {
            isHeader: true,
            idxKey: 'idx',
            noInputLabel: true,
            valueKey: 'units',
            checkNumber: false,
            inputStyles: {textAlign: 'left'},
            checkValOnUpdate: false,
            parentStyles: {
                textAlign: 'left',
            },
            placeholder: "(colors, stitches, ...)",
        };

    } else {
        colDef['input_header'] = {
            isHeader: true,
            idxKey: 'idx',
            inputLabelKey: 'until',
            inputLabelPrefix: ' - ',
            inputLabelLastPrefix: ' + ',
            valueKey: 'label',
            checkNumber: true,
            checkValOnUpdate: true,
        };
    }

    return colDef;
};

export const getColDefsUntil = (cols) => {
    for (let i = cols.length-1; i > 0; i--) {
        const col = cols[i];
        cols[i-1]['until'] = parseInt(col['label']) - 1;
    }
    if (cols && cols[cols.length-1]) {
        cols[cols.length-1]['until'] = '';
    }
    return cols;
};

export const generateColDefs = (cols, units, update_until=true) => {
    const colDefs = cols.map((val, idx) => {
        const col = generateColDef(val, idx, idx+1, units);
        if (idx > 0) {
            if (!isNaN(cols[idx]['label']) && update_until) {
                cols[idx-1]['until'] = parseInt(cols[idx]['label']) - 1;
            }
        }
        return col;
    });

    if (cols && cols[cols.length-1] && update_until) {
        cols[cols.length-1]['until'] = '';
    }
    if (update_until) { return getColDefsUntil(colDefs); }
    return colDefs;
};

// colsNum =
export const initializeGrid = (cols_num, rows_num, units, charge_type) => {
    let cols = [];
    const data = [];
    if (cols_num == null && rows_num == null) {
        if (charge_type=='run') {
            cols_num = 5;
            rows_num = 2;
        } else {
            cols_num = 1;
            rows_num = 2;
        }
    }

    for (let i=0; i <= cols_num; i++) {
        cols.push(generateColDef(i, i, null, units));
        if (i > 0) {
            cols[i-1]['until'] = parseInt(cols[i]['label']) - 1;
        }
    }
    cols[cols.length-1]['until'] = '';

    for (let i=1; i < rows_num; i++) {
        data.push(createRow(cols, i, i));
    }

    return { cols, data, };
};

export const addRowAtIndex = (matrix, index, data={}) => {
    const lastRowVal = matrix.data[index]['-'] ? (isNaN(matrix.data[index]['-']) ? 0 : parseInt(matrix.data[index]['-'])) : '';
    let nextRows = matrix.data.slice(index+1, matrix.data.length);
    if (nextRows && nextRows.length) {
        nextRows.forEach((v, i) => {
            v['id'] = parseInt(v['id']) + 1;
            v['row_num'] = parseInt(v['row_num']) + 1;
            return v;
        });
    }

    return {
        data: [
            ...matrix.data.slice(0, index),
            matrix.data[index],
            createRow(matrix.cols, index+2, {...(isObj(data) ? data : {}), value: (lastRowVal ? lastRowVal+1 : '')}),
            ...nextRows,
        ],
    };
};

export const addRows = (matrix, add_rows, data={}) => {
    if (add_rows == 1 && matrix && matrix.cols && matrix.data && matrix.data.length > 1 && matrix.type == 'table') {
        if (matrix.data[matrix.data.length-1]['price_by_type'] == 'per_additional_unit') {
            return addRowAtIndex(matrix, matrix.data.length-2, data);
        }
    }

    const lastRow = matrix.data[matrix.data.length-1];
    const lastRowIdx = isNaN(lastRow['id']) ? 0 : parseInt(lastRow['id']);
    const lastRowVal = isNaN(lastRow['-']) ? 0 : parseInt(lastRow['-']);

    const rows = [];
    let j=1;
    for (let i = lastRowIdx+1; i <= lastRowIdx+add_rows; i++) {
        rows.push(createRow(matrix.cols, i, {...(isObj(data) ? data : {}), value: lastRowVal+j}));
        j++;
    }

    return {
        data: matrix.data.concat(rows),
    };
};

export const removeRows = (matrix, rows) => {
    if (!rows || rows<=0 || matrix.data.length <= 1) { return false; }

    return {
        data: matrix.data.slice(0, matrix.data.length-rows),
    };
};

// Remove row and fix Matrix by moving row index up
// cannot delete the first row
// idx = ${row_id} - 1
export const removeRowFixMatrix = (matrix, row_id) => {
    if (!row_id || matrix.data.length < 1) { return false; }

    const data = [];
    let j =0;
    for (let i = 0; i < matrix.data.length; i++) {
        const r = matrix.data[i];
        if (i != parseInt(row_id)-1) {
            r.id = j+1;
            r.row_num = j+1;
            data[j] = r;
            j++;
        }
    }

    return {
        data: data,
    };
};

export const addCols = (matrix, add_cols) => {
    const _cols = [];
    const _rows = [];

    const lastCol = matrix.cols[matrix.cols.length-1];
    const lastColIdx = isNaN(lastCol['idx']) ? -1 : parseInt(lastCol['idx']);
    const lastColVal = isNaN(lastCol['label']) ? 1 : parseInt(lastCol['label']);

    let j=1;
    for (let i = lastColIdx+1; i <= add_cols+lastColIdx; i++) {
        const key = i==0 ? '-' : i.toString();
        const exists = matrix.cols.filter(val => val.idx == i && val.key == key);
        if (exists.length == 0) {
            _cols.push(generateColDef(lastColVal+j, i));
            j++;
        }
    }

    const cols = matrix.cols.concat(_cols);
    for (let j = 0; j < matrix.data.length; j++) {
        const row = matrix.data[j];
        const new_row = createRow(cols, matrix.data.length + j);
        delete new_row['id'];
        delete new_row['-'];
        _rows.push({ ...new_row, ...row });
    }
    return { cols: [].concat(getColDefsUntil(cols)), data: [].concat(_rows), };
};

// remove last number of `cols`
export const removeCols = (matrix, cols) => {
    if (!cols || cols<=0 || matrix.cols.length <= 1) { return; }

    const rows = [].concat(matrix.data);
    for (let i=0; i<rows.length; i++) {
        for (let j=matrix.cols.length-1; j >= matrix.cols.length-cols; j--) {
            delete rows[i][matrix.cols[j].key];
        }
    }

    const cols_ = matrix.cols.slice(0, matrix.cols.length-cols);
    cols_[cols_.length-1]['until'] = '';

    return {
        cols: cols_,
        data: rows,
    };
};

export const updateColDef = (matrix, newVal, data) => {
    if (!data || (data && !data.key) || isNaN(newVal)) {return;}

    const cols = matrix.cols;
    const colIdx = cols.findIndex((val => val.key == data.key));

    let changeVal = true;
    const _newVal = parseInt(newVal);

    if (colIdx > -1) {
        // check if newe value is less than previous values, if so dont update
        if (colIdx !== 0 && _newVal < parseInt(cols[colIdx-1]['label'])) {
            changeVal = false;
        }

        if (changeVal) {
            // update new value
            cols[colIdx]['label'] = _newVal.toString();

            // update (increase) next values
            let prevVal = _newVal;
            for (let i = colIdx+1; i < cols.length; i++) {
                const label = parseInt(cols[i]['label']);
                if (label - prevVal < 1) {
                    cols[i]['label'] = (
                        Math.abs(label - prevVal) + label + 1
                    ) + '';
                }

                prevVal = parseInt(cols[i]['label']);
            }

            return {
                cols: [].concat(getColDefsUntil(cols)),
            };
        }
    }

    return false;
};

export const updateRowDef = (matrix, value) => {
    if (!value || !value.newValue || !value.key || !value.rowObj) {return;}
    if (isNaN(value.newValue)) {
        return;
    }

    const newValue = parseInt(value.newValue);
    const data = [...matrix.data];
    let changeVal = true;
    let shouldUpdateNextValues = true; // should check and update next values

    if (data[value.rowObj.id-1]) {
        if (typeof(newValue) !== 'undefined' && data[value.rowObj.id-1][value.key] !== newValue) {
            if (value.rowObj.price_by_type && value.rowObj.price_by_type == 'per_additional_unit') {
                changeVal = true;
                shouldUpdateNextValues = false;
            } else {
                if (value.rowObj.id > 1) {
                    const prevRow = data[value.rowObj.id-2];
                    if (newValue < parseInt(prevRow[value.key])) {
                        changeVal = false;
                    }
                }
            }

            if (changeVal) {
                // update current edited
                data[value.rowObj.id-1][value.key] = newValue;

                if (shouldUpdateNextValues) {
                    // update (increase) next values
                    let prevVal = newValue;
                    for (let i = value.rowObj.id; i < data.length; i++) {
                        const currentVal = parseInt(data[i][value.key]);
                        if (currentVal - prevVal < 1 && data[i]['price_by_type'] !== 'per_additional_unit') {
                            data[i][value.key] = (
                                Math.abs(currentVal - prevVal) + currentVal + 1
                            ) + '';
                        }

                        prevVal = parseInt(data[i][value.key]);
                    }
                }
            }
        }
    }

    return {
        data: [].concat(data),
    };
};

export const checkMatrixLeft = (matrix, value) => {
    let index = parseInt(value.rowObj.id)-1;
    let colIndex = parseInt(value.key);
    let leftCell = (colIndex-1)>0 ? matrix.data[index][colIndex-1] : true;
    leftCell = leftCell != '' && leftCell != null && leftCell != undefined;

    return leftCell;
};

export const checkMatrixRight = (matrix, value) => {
    let index = parseInt(value.rowObj.id)-1;
    let colIndex = parseInt(value.key);
    let rightCell = (colIndex-1)<(matrix.cols.length-1) ? matrix.data[index][colIndex+1] : false;

    return rightCell;
};

export const checkMatrixCell = (matrix, value) => {
    const newValue = value.newValue;

    return {
        data: matrix.data.map((val, idx) => {
            if (val.id == value.rowObj.id) {
                val[value.key] = newValue;
            }

            return val;
        }),
    };
};

// check if any cell has data
export const matrixIsEmpty = (matrix) => {
    if (!matrix
        || (matrix && Array.isArray(matrix.data) && matrix.data.length <= 0)
        || (matrix && !matrix.data)) {
        return true;
    }

    for (let i = 0; i < matrix.data.length; i++) {
        const row = matrix.data[i];
        const keys = Object.keys(row);
        if (row) {
            for (let j = 0; j < keys.length; j++) {
                if (keys[j] != '-' && keys[j] != 'id') {
                    const val = row[keys[j]];
                    if (val != '' && val != null && val != undefined) {
                        return false;
                    }
                }
            }
        }
    }

    return true;
};

export const generateCharge = (idx, unit, data={}) => {
    return {
        'charge_id': data.charge_id ? data.charge_id : 'temp_charge_' + idx,
        'cell_value': data.cell_value ? data.cell_value : 1,
        'qty': data.qty ? data.qty : 1,
        'unit': unit ? unit : '',
        'type': 'list-item',
        'label': data.label ? data.label : '',
        'row_num': idx+1,
    };
};

export const initializeChargesList = (rows_num, unit, options={}) => {
    if (rows_num == null) {
        rows_num = 1;
    }

    const data = [];
    for (let i = 0; i < rows_num; i++) {
        data.push(generateCharge(i, unit, options));
    }

    return {data, };
};

export const convertToFloat = (val) => {
    if (!val || val == '' || val == null || val == undefined) {
      return val;
    }

    return isNaN(val) || parseFloat(val) == 'NaN' ? val : parseFloat(val).toFixed(4);
};

