import { get, concat, isEmpty, last, pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';

import { oauth } from '../../utils';

const fetcher = (args) => {
  const [url, params] = args;
  return oauth('GET', url, pickBy(params), {}, { logout: false });
};

const PAGE_SIZE = 50;

const useInfinite = (url, params, options = []) => {
  const getter = get(options, 'getter') || (r => r);
  const { data, error, size, setSize, isLoading, isValidating, mutate } = useSWRInfinite(
    (index, prev) => {
      if (prev && prev.length === 0) return null; // reaching end
      return [url, { offset: index * PAGE_SIZE, limit: PAGE_SIZE, ...params }];
    },
    async (...args) => {
      return getter(await fetcher(...args));
    },
    { revalidateFirstPage: false },
  );
  const isReachingEnd = isEmpty(last(data));
  if (error) {
    console.error(error);
  }
  return {
    size, setSize, isLoading, isReachingEnd, mutate,
    results: concat(...(data || [])),
    error, isValidating
  };
};

export const useThemeProducts = ({ id, supplier_id = null }) => {
  return useInfinite(`theme/${id}`, { actionName: 'products', supplier_id }, {
    getter: (response) => get(response, 'json.products'),
  });
};

export const useThemeItems = ({ id, supplier_id = null }) => {
  return useInfinite(`theme/${id}`, { actionName: 'items', supplier_id }, {
    getter: (response) => {
      return get(response, 'json.items');
    },
  });
};

export const useIconVisibleSize = myRef => {
  const iconSize = 103
  const [size, setSize] = useState(6)

  useEffect(() => {
    const getWidth = () => myRef.current.offsetWidth

    const handleResize = () => {
      setSize(Math.floor(getWidth() / iconSize) - 1)
    }

    if (myRef.current) {
      setSize(Math.floor(getWidth() / iconSize) - 1)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])
  return size;
};
