import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useFolders from "../../hooks/useFolders";
import { createSearchFiles, createUpdateFile, createUploadFile } from "../../actions/file";
import useSortable from "../admin-new/hooks/useSortable";

const ArtworkLibraryContext = createContext(null);

function fileInProgressReducer(files, action) {
    switch (action.type) {
        case 'added': {
            if (files.filter(f => f.file_uuid === action.file_uuid).length > 0) {
                return files.map(f => {
                    if (f.file_uuid === action.file_uuid) {
                        return {
                            ...f, progress: action.progress
                        };
                    } else {
                        return f;
                    }
                });
            } else {
                return [...files, {
                    parent_type: action.parent_type,
                    file_uuid: action.file_uuid,
                    fileName: action.fileName,
                    progress: action.progress,
                }];
            }
        }
        case 'removed': {
            return files.filter(f => f.file_uuid !== action.file_uuid);
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export const ArtworkLibraryProvider = ({ initParents, initParent, isModal, modalOpen, setModalOpen, onFileSelect, children }) => {
    const fileRef = useRef(null);
    const [parents, setParents] = useState(initParents);

    const [activeParent, setActiveParent] = useState(initParent);
    const [activeFolder, setActiveFolder] = useState(null);
    const [uploadFromWebError, setUploadFromWebError] = useState(false);

    const [isWeb, setIsWeb] = useState(false);
    const [copyToClient, setCopyToClient] = useState(false);
    const [activeCopyFolder, setActiveCopyFolder] = useState(null);

    const [filesInProgress, reDispatch] = useReducer(
        fileInProgressReducer,
        []
    );

    const clientParent = initParents.find(p => p.parent_type === 'CLIENT');

    const [folders, folderLoading, saveFolder, removeFolder, updateItem] = useFolders(
        clientParent ? clientParent.parent_type : activeParent.parent_type,
        clientParent ? clientParent.parent_id : activeParent.parent_id
    );

    const [sortableFolders, onMove, onDrop] = useSortable(folders, updateItem);

    const files = useSelector((s: any) => Array.isArray(s.entities.files) ? s.entities.files : Object.values(s.entities.files));
    const dispatch = useDispatch();

    // Switch parent, we need to reset files and active folder.
    useEffect(() => {
        setActiveParent(initParent);
        setActiveFolder(null);
    }, [initParent.parent_id, initParent.parent_type]);

    useEffect(() => {
        dispatch(createSearchFiles(activeParent.parent_id, activeParent.parent_type, {}));
    }, [activeParent.parent_id, activeParent.parent_type, dispatch]);

    const activeFiles = files.sort(function compare(a, b) {
        var dateA = new Date(a.date_uploaded);
        var dateB = new Date(b.date_uploaded);
        return dateB.valueOf() - dateA.valueOf();
    }).filter(file =>
        file.parent_type === activeParent.parent_type &&
        file.parent_id === activeParent.parent_id &&
        (activeFolder && activeFolder.folder_id ? activeFolder.folder_id === file.folder_id : true)
    );

    const allFileIds = files.map(f => f.file_id);
    const activeFilesInProgress = filesInProgress.filter(f => f.parent_type === activeParent.parent_type && allFileIds.indexOf(f.file_uuid) === -1);

    const uploadFile = useCallback((file_blob, folder_id = null) => {
        try {
            // Use the progress callback to capture the temp file
            dispatch(createUploadFile(activeParent.parent_id, activeParent.parent_type, file_blob, folder_id,
                (percentCompleted, file_name, index, file_uuid) => {
                    reDispatch({
                        type: 'added',
                        parent_type: activeParent.parent_type,
                        file_uuid: file_uuid,
                        progress: percentCompleted,
                        fileName: file_name,
                    });
                }
            ));

            if (copyToClient && 'CLIENT' !== activeParent.parent_type) {
                const clientParent = parents.find(p => 'CLIENT' === p.parent_type);
                if (clientParent) {
                    // Copy to client as well
                    dispatch(createUploadFile(clientParent.parent_id, clientParent.parent_type, file_blob, activeCopyFolder ? activeCopyFolder.value : null));
                }
            }
        } catch (error) {
            console.log('UPLOAD ERROR: ', error);
        }
    }, [activeCopyFolder, activeParent.parent_id, activeParent.parent_type, copyToClient, dispatch, parents]);

    const uploadFileWeb = useCallback(async (url, folder_id = null) => {
        try {
            // Use the progress callback to capture the temp file
            const response: any = await dispatch(createUploadFile(activeParent.parent_id, activeParent.parent_type, url, folder_id));
            if (response.type === "UPLOAD_FILE_FAILURE") {
                setUploadFromWebError(true);
                return false;
            }
            setUploadFromWebError(false);

            if (copyToClient && 'CLIENT' !== activeParent.parent_type) {
                const clientParent = parents.find(p => 'CLIENT' === p.parent_type);
                if (clientParent) {
                    dispatch(createUploadFile(clientParent.parent_id, clientParent.parent_type, url, activeCopyFolder ? activeCopyFolder.value : null));
                }
            }
            return true;
        } catch (error) {
            setUploadFromWebError(true);
            return false;
        }
    }, [activeCopyFolder, activeParent.parent_id, activeParent.parent_type, copyToClient, dispatch, parents]);

    const moveFile = useCallback((file, folder) => {
        if (!folder.folder_id) return;
        const updated_file = Object.assign({}, file, { folder_id: folder.folder_id });
        dispatch(createUpdateFile(updated_file));
    }, [dispatch]);

    const updateFileName = useCallback((file, newName) => {
        const updated_file = Object.assign({}, file, { file_display_name: newName });
        dispatch(createUpdateFile(updated_file));
    }, [dispatch]);

    const value = useMemo(
        () => {
            return {
                fileRef,
                files,
                activeFiles,
                parents,
                setParents,
                activeParent,
                setActiveParent,
                activeFolder,
                setActiveFolder,
                folders,
                isModal,
                modalOpen,
                setModalOpen,
                uploadFile,
                uploadFileWeb,
                activeFilesInProgress,
                onFileSelect,
                saveFolder,
                removeFolder,
                reDispatch,
                folderLoading,
                moveFile,
                updateFileName,
                isWeb,
                setIsWeb,
                uploadFromWebError,
                setUploadFromWebError,
                copyToClient,
                setCopyToClient,
                activeCopyFolder,
                setActiveCopyFolder,
                sortableFolders,
                onMove,
                onDrop,
            };
        },
        [activeCopyFolder, activeFiles, activeFilesInProgress, activeFolder, activeParent, copyToClient, files, folderLoading, folders, isModal, isWeb, modalOpen, moveFile, onDrop, onFileSelect, onMove, parents, removeFolder, saveFolder, setModalOpen, sortableFolders, updateFileName, uploadFile, uploadFileWeb, uploadFromWebError],
    );

    return (
        <ArtworkLibraryContext.Provider value={value}>{children}</ArtworkLibraryContext.Provider>
    );
};

export const useArtworkLibraryContext = (): any => {
    const context = useContext(ArtworkLibraryContext);

    if (!context) {
        throw new Error("useReportContext must be used within a ReportProvider");
    }

    return context;
};
