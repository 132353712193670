import React from "react";
import { Loading } from "@commonsku/styles";
import { BASE_ZINDEX } from "../../popup-factory";
import { document } from "../../global";
import { createPortal } from "react-dom";

const containerStyle = (zIndex) => ({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: BASE_ZINDEX + zIndex,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const root =
  document.getElementById("root") || document.getElementById("resku");

const FullScreenLoading = ({ loading, message='Loading...', zIndex=9999 }) => {
  return createPortal(
    loading && (
      <div style={containerStyle(zIndex)}>
        <Loading />
        <h1 style={{ color: "#fff" }}>{message}</h1>
      </div>
    ),
    root
  );
};

export default FullScreenLoading;
