import React from 'react';
import { themeOptions, Datepicker } from '@commonsku/styles';
import { CSKUInput } from '../../DateInput';

const Field = React.memo(({ value, onChange }) => (
    <>
        <label style={{ fontFamily: themeOptions.fontFamilies.demibold }}>In-Hands Date *</label>
        <Datepicker
            customInput={<CSKUInput />}
            showMonthDropdown
            showYearDropdown
            placeholder="In-Hands Date"
            value={value}
            onChange={onChange}
            portalId="root-portal"
        />
    </>
));

export default Field;
