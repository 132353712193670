import React, { useState } from "react";
import { Col, Row, ConfirmAlertPopup } from "@commonsku/styles";
import {
  BackgroundImage,
  PlaceholderLine,
  ResetToDefault,
  HelpScreen,
} from "./theme-customizations";
import { LabeledSelect } from "../../helpers";
import { POPUP_TYPES } from "./helpers";
import { PublicViewTemplate } from "../../../models/PublicViewTemplate";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const styleOptions = [
  { label: "Text Only", value: "TEXT_ONLY" },
  { label: "Side Banner", value: "SIDE_BY_SIDE" },
];

export default function HelpScreenStylesPanelContent(props) {
  const { onUpdateField, onOpenImagesPopup, themeName } = props;
  const { templateData } = usePublicViewTemplateDataContext();

  const [showConfirmPopup, setConfirmPopup] = useState(false);
  const isPoster = themeName === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
  const defaultStyle = isPoster ? "SIDE_BY_SIDE" : "TEXT_ONLY";
  const options = isPoster
    ? styleOptions.filter((o) => o.value !== "TEXT_ONLY")
    : styleOptions;
  const helpScreenStyle = templateData.help_screen_style || defaultStyle;

  const onToggleField = (field) =>
    onUpdateField(field, +templateData[field] === 1 ? 0 : 1);

  const onReset = () => {
    Promise.all([
      onUpdateField("help_screen_visible", 1),
      onUpdateField("help_screen_style", defaultStyle),
      onUpdateField("help_screen_image", ""),
    ]).then(() => {
      setConfirmPopup(false);
    });
  };

  const PlaceholderLineCol = (
    <Col xs>
      <PlaceholderLine />
    </Col>
  );

  const HelpScreenCol = (
    <Col xs style={{ paddingBottom: 16 }}>
      <HelpScreen
        value={+(templateData.help_screen_visible ?? '1')}
        onChange={() => onToggleField("help_screen_visible")}
      />
    </Col>
  );

  const HelpScreenStyleCol = (
    <>
      <Col xs style={{ paddingBottom: 12 }}>
        <LabeledSelect
          label="Help Screen Style"
          name="HelpScreenStyleOption"
          options={options}
          value={helpScreenStyle}
          onChange={(e) =>
            onUpdateField("help_screen_style", e ? e.value : defaultStyle)
          }
          data-testid="select_help_screen_styles_dropdown"
        />
      </Col>
      {HelpScreenStylePreview(helpScreenStyle)}
    </>
  );

  const BgImageCol = (
    <Col xs>
      <BackgroundImage
        value={templateData.help_screen_image}
        label="Help Screen Image"
        title="Add Image"
        onClick={() =>
          onOpenImagesPopup(
            POPUP_TYPES.SELECT_HELP_SCREEN_IMAGE,
            "help_screen_image"
          )
        }
        testIdPrefix="help_screen"
      />
    </Col>
  );

  const withBgImage = helpScreenStyle !== "TEXT_ONLY";

  return (
    <Row>
      {showConfirmPopup && (
        <ConfirmAlertPopup
          title="Are you sure?"
          actionButtonContent="Reset Styles"
          onAction={onReset}
          onClose={() => setConfirmPopup(false)}
          maxWidth={500}
          width="100%"
        >
          <p
            style={{
              display: "block",
              fontFamily: "var(--font-family-regular)",
              margin: 0,
            }}
          >
            You are about to reset Help Screen Styles for this shop.
          </p>
          <p
            style={{
              display: "block",
              fontFamily: "var(--font-family-bold)",
              margin: 0,
            }}
          >
            You cannot undo this action!
          </p>
        </ConfirmAlertPopup>
      )}
      {HelpScreenCol}
      {HelpScreenStyleCol}
      {withBgImage && BgImageCol}
      {PlaceholderLineCol}
      <Col xs style={{ textAlign: "center" }}>
        <ResetToDefault onClick={() => setConfirmPopup(true)} testId='help_screen_styles'>
          Reset Help Screen Styles
        </ResetToDefault>
      </Col>
    </Row>
  );
}

const HelpScreenStylePreview = (style) => {
  let preview = null;

  switch (style) {
    case "TEXT_ONLY":
      preview = (
        <svg
          width="184"
          height="100"
          viewBox="0 0 184 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2841_21398)">
            <rect width="184" height="100" fill="#EDF4F7" />
            <rect x="8" y="8" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="14" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="20" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="30" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="36" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="42" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="52" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="58" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="68" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="74" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="8" y="80" width="106" height="2" rx="1" fill="#B8C4CB" />
            <rect x="130" y="8" width="46" height="8" rx="2" fill="#B8C4CB" />
            <rect x="130" y="24" width="46" height="2" rx="1" fill="#B8C4CB" />
            <rect x="130" y="30" width="46" height="2" rx="1" fill="#B8C4CB" />
            <rect x="130" y="36" width="46" height="2" rx="1" fill="#B8C4CB" />
            <rect x="130" y="46" width="46" height="2" rx="1" fill="#B8C4CB" />
            <rect x="130" y="52" width="46" height="2" rx="1" fill="#B8C4CB" />
          </g>
          <defs>
            <clipPath id="clip0_2841_21398">
              <rect width="184" height="100" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "SIDE_BY_SIDE":
    default:
      preview = (
        <svg
          width="200"
          height="100"
          viewBox="0 0 200 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2580_1632)">
            <rect width="200" height="100" fill="#EDF4F7" />
            <rect width="100" height="100" fill="#B8C4CB" />
            <g clipPath="url(#clip1_2580_1632)">
              <path
                d="M76.8333 23.1667V76.8333H23.1667V23.1667H76.8333ZM76.8333 15.5H23.1667C18.95 15.5 15.5 18.95 15.5 23.1667V76.8333C15.5 81.05 18.95 84.5 23.1667 84.5H76.8333C81.05 84.5 84.5 81.05 84.5 76.8333V23.1667C84.5 18.95 81.05 15.5 76.8333 15.5ZM58.2033 49.4633L46.7033 64.2983L38.5 54.37L27 69.1667H73L58.2033 49.4633Z"
                fill="white"
              />
            </g>
            <rect x="108" y="8" width="84" height="4" rx="2" fill="#B8C4CB" />
            <rect x="108" y="20" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="26" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="32" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="42" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="48" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="54" width="68" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="64" width="53" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="70" width="53" height="2" rx="1" fill="#B8C4CB" />
            <rect x="108" y="80" width="84" height="8" rx="2" fill="#B8C4CB" />
          </g>
          <defs>
            <clipPath id="clip0_2580_1632">
              <rect width="200" height="100" fill="white" />
            </clipPath>
            <clipPath id="clip1_2580_1632">
              <rect
                width="92"
                height="92"
                fill="white"
                transform="translate(4 4)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }

  return (
    <Col xs style={{ textAlign: "center", marginBottom: "12px" }}>
      {preview}
    </Col>
  );
};
