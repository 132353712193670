import React, { Component } from 'react';

import { getAbsoluteUrl, getImageSrc, formatFriendlyDate } from '../utils';

import Img from './Img';

const DEFAULT_COLOR = '#76bbd1';

class CollectionBadge extends Component {

  constructor(props) {
    super(props);

    this.renderImage = this.renderImage.bind(this);
  }

  renderImage(index, style) {
    const { collection, disabled, color = DEFAULT_COLOR } = this.props;
    const imageStyle = {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      right: 0,
      margin: '0 auto',
      maxWidth: '95%'
    };

    const isEditable = !disabled && this.props.onChangeImage;

    const image = collection.collection_images[index];
    return (
      <div className="small-6 columns" style={{ border: `0.25rem solid ${color}`, position: 'relative', background: 'white', padding: 0, borderRadius: '0.5rem', overflow: 'hidden', textAlign: 'center', cursor: isEditable && 'pointer' }} onClick={(!disabled && this.props.onChangeImage) ? this.props.onChangeImage(image ? image.file_id : null, index) : null}>
        <div style={{ marginTop: '100%' }}></div>
        {image ?
          <Img src={getImageSrc(image, 'medium')} style={imageStyle} /> :
          (isEditable && <span style={imageStyle}>select an image</span>)}
      </div>
    );
  }

  render() {
    const { collection, className, style, onClick = null, color = DEFAULT_COLOR, showSupplierDetails = false } = this.props;

    const companyIconHolderStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      borderRadius: '50%',
      width: '35%',
      height: '35%',
      background: 'white',
      border: `7px solid ${color}`,
      zIndex: 1,
      overflow: 'hidden'
    };

    const companyIconStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      maxWidth: '100%',
      maxHeight: '100%'
    };

    const titleStyle = {
      color: 'white',
      lineHeight: 1.25,
      fontSize: '1rem',
      textShadow: '0 2px 2px #3498b8',
      margin: '0.7rem 0',
      overflowWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    };

    const backgroundStyle = {
      background: color,
      width: '100%',
      marginBottom: '1.5rem',
      border: `0.25rem solid ${color}`,
      borderRadius: '0.25rem'
    };

    const detailsStyle = {
      color: 'white',
      textAlign: 'center',
      fontSize: 'small',
      lineHeight: 1,
      marginBottom: '0.5rem'
    };

    const created = formatFriendlyDate(collection.date_created);
    const published = formatFriendlyDate(collection.date_published);
    const expiry_date = new Date(collection.date_expiry);
    const title = collection.title || `Collection ${collection.form_number}`;
    const short_title = title.length > 50 ? title.substr(0, 50) + '...' : title;

    return (
      <div className={className} style={{ ...backgroundStyle, ...style }} onClick={onClick}>
        <div className="row" style={{ position: 'relative' }}>
          <div style={companyIconHolderStyle}>
            <Img style={companyIconStyle} src={collection.avatar ? (getAbsoluteUrl(collection.avatar) || '/images/favicons/favicon-96x96.png') : '/images/favicons/favicon-96x96.png'} />
          </div>
          {this.renderImage(0)}
          {this.renderImage(1)}
          {this.renderImage(2)}
          {this.renderImage(3)}
        </div>
        <div className="row small-12 columns" style={{ textAlign: 'center', padding: 0 }}>
          <h4 style={titleStyle} title={title}>{short_title}</h4>
          {showSupplierDetails ?
            [
              <p key="0" style={detailsStyle}>Created by {collection.creator} on {(new Date(collection.date_created)).toLocaleDateString()}</p>,
              <p key="1" style={detailsStyle}>{collection.status_name}{'Published' === collection.status_name && ` on ${(new Date(collection.date_published)).toLocaleDateString()}`}</p>,
              !isNaN(expiry_date) && <p key="2" style={detailsStyle}>Expires on {expiry_date.toLocaleDateString()}</p>
            ] :
            (published && <p key="0" style={detailsStyle}>Published {published}</p>)}
          {collection.stats &&
            <p style={detailsStyle}><i className="fi-eye" style={{ fontSize: 'x-large' }}></i>&nbsp;{collection.stats.views}&nbsp;&nbsp;<i className="fi-pricetag-multiple" style={{ fontSize: 'large' }}></i>&nbsp;{collection.stats.adds}</p>
          }
        </div>
      </div>
    );
  }
}

export default CollectionBadge;
