import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFilter } from "react-select";
import styled from "styled-components";
import { LabeledCreatableSelect } from "@commonsku/styles";
import { isString } from "../types/type_guards";
import Avatar from "./helpers/Avatar";
import { validateEmailExtensive } from "../utils";

import {
  getContactList,
  getCompanyContactsForEmailList,
  getUserContactsForEmailList  
} from "../selectors/dropdowns";
import { createLoadCompanyContactList } from "../actions/contact";

interface Option {
  value: string;
  label: React.ReactNode;
  type: "email" | "contact" | "user";
  email: string;
  name?: string;
  imageUrl?: string;
  __isNew__?: boolean;
}

type Options = Array<Option>;

const components = {
  DropdownIndicator: null,
};

const OptionLabel = styled.div`
  display: flex;
`;

function formatOptionLabel(option: Option): React.ReactNode {
  switch (option.type) {
    case "contact":
      return (
        <OptionLabel title={option.email}>
          {option.name?.trim() || option.email}
        </OptionLabel>
      );
    case "user":
      return (
        <OptionLabel title={option.email}>
          <Avatar
            name={option.name ?? ""}
            src={option.imageUrl}
            style={{ width: 20, height: 20, boxSizing: "content-box" }}
          />
          {option.name?.trim() || option.email}
        </OptionLabel>
      );
    default:
      return <OptionLabel>{option.label}</OptionLabel>;
  }
}

function formatCreateLabel(inputValue: string): React.ReactNode {
  return `Add email address "${inputValue}"`;
}

const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (value) => {
    const option: Option = value.data as Option;
    return `${option.name ?? ""} ${option.email}`.trim();
  },
  trim: true,
  matchFrom: "any"
});

function getNewOptionData(inputValue: string, optionLabel: React.ReactNode): Option {
  return {
    value: inputValue.trim(),
    label: optionLabel,
    type: "email",
    email: inputValue.trim(),
    __isNew__: true,
  };
}

function isValidNewOption(inputValue: string, value: Options, options: Options): boolean {
  return (
    validateEmailExtensive(inputValue) &&
    value.filter(v => v.email === inputValue).length === 0 &&
    options.filter(o => o.email === inputValue).length === 0
  );
}

export function getValueFromContactIdsAndExtraEmails(
  options: Options,
  contact_ids: string[],
  extra_emails: []
): Option[] {
  const value = options.filter(
    o => ["contact", "user"].includes(o.type) && contact_ids.includes(o.value)
  ).concat(
    extra_emails.map(
      e => ({
        value: e,
        label: e,
        type: "email",
        email: e
      })
    )
  );
  return value;
}

export function useCompanyContactEmails(parent_id, parent_type) {
  const dispatch = useDispatch();
  const selectorArgs = useMemo(() => ({ parent_id }), [parent_id]);
  const contactIds = useSelector((state) => getContactList(state, selectorArgs));
  useEffect(() => {
    if (typeof contactIds === "undefined" && typeof parent_id !== "undefined") {
      dispatch(createLoadCompanyContactList(parent_id, parent_type));
    }
  }, [contactIds, parent_id, parent_type]);
  const contacts = useSelector((state) => getCompanyContactsForEmailList(state, selectorArgs));
  return contacts;
}

export function useUserContactEmails() {
  const contacts = useSelector(getUserContactsForEmailList);
  return contacts;
}

export function getContactIds(value: Options): string[] {
  return value.filter(v => ["contact", "user"].includes(v.type)).map(v => v.value);
}
export function getExtraEmails(value: Options): string[] {
  return value.filter(v => v.type === "email").map(v => v.value);
}

interface EmailListProps {
  value?: Options;
  options?: Options;
  onChange?: (value: Options) => void;
  label?: string;
  placeholder?: React.ReactNode;
  disabled?: boolean;
};

function EmailList(
  {
    value,
    options,
    onChange,
    label,
    placeholder,
    disabled
  }: EmailListProps
) {

  const handleChange = (newValue: Options) => {
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <LabeledCreatableSelect
      label={label}
      components={components}
      isMulti
      isDisabled={disabled}
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      formatCreateLabel={formatCreateLabel}
      formatOptionLabel={formatOptionLabel}
      getNewOptionData={getNewOptionData}
      isValidNewOption={isValidNewOption}
    />
  );
}

export default EmailList;
