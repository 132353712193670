import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';

import Select from './Select';
import { getSupplierCollaborateOptions, getDivisionContactsOptions, getCategoryOptions } from '../selectors/dropdowns';

import { createLoadDivisionContacts } from '../actions/supplier';
import { createAddCollaborateInvitation } from '../actions/project';
import { createAddTemp, createDeleteTemp } from '../actions/temp';
import { createSelectFilePopup } from '../actions/popup';

const TEMP_FILE_REGISTER = 'collaborate_file_id';

class PublisherCollaborate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedSupplier: 'all-suppliers',
      selectedContactEmail: 'select-rep',
      selectedCategory: '',
      inviteText: '',
      inviteCategory: '',
      supplierContactsDropdown: '',
      disabled_select: true,
      files: []
    };

    this.handleAddInvite = this.handleAddInvite.bind(this);
    this.handleChangeSupplier = this.handleChangeSupplier.bind(this);
    this.handleChangeDivisionContact = this.handleChangeDivisionContact.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeInviteText = this.handleChangeInviteText.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.files.length) {
      this.setState({ files: nextProps.files });
    }
  }

  componentWillUnmount() {
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
  }

  handleAddInvite(job_id, client_id, order_id, collaborate_contact_email, collaborate_text, collaborate_supplier_id, onAddInvite) {
    const file_ids = this.state.files.map(f => f.file_id);
    if(collaborate_supplier_id == 'all-suppliers') {
      onAddInvite(job_id, 'invite-all', client_id, order_id, 'OPPORTUNITY', null, collaborate_text, collaborate_supplier_id, 'new', file_ids);
    }else{
      onAddInvite(job_id, 'invitation', client_id, order_id, 'OPPORTUNITY', collaborate_contact_email, collaborate_text, collaborate_supplier_id, 'new', file_ids);
    }

    this.setState({ selectedContactEmail: 'select-rep'});
    this.setState({ selectedSupplier: 'all-suppliers'});
    this.setState({ inviteText: ''});
    this.setState({ inviteCategory: ''});
    this.setState({ files: [] });
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
  }

  handleChangeSupplier(e, onChangeSupplier) {
    this.setState({ selectedSupplier: e });
    onChangeSupplier(e);
    if(e == '') {
      this.setState({ disabled_select: true });
    }else{
      this.setState({ disabled_select: false });
    }
  }

  handleChangeDivisionContact(e) {
    this.setState({ selectedContactEmail: e });
  }

  handleChangeCategory(e) {
    this.setState({ selectedCategory: e });
  }

  handleChangeInviteText(e) {
    this.setState({ inviteText: e.target.value });
  }

  render() {
    const { job_id, client_id, opportunity_id,
      suppliers, divisionContacts, categories,
      order_id, understood, onAddInvite,
      onChangeSupplier, onAddTemp, onCreateSelectFilePopup
    } = this.props;
    const selectedSupplier = this.state.selectedSupplier;
    const selectedContactEmail = this.state.selectedContactEmail;
    const selectedCategory = this.state.selectedCategory;
    const files = this.state.files;
    const allow_invite = selectedSupplier == 'all-suppliers' && this.state.inviteText != '' || selectedSupplier != 'select-supplier' && selectedContactEmail != 'select-rep' && this.state.inviteText != '' ? true : false;

    return (
      <div className="publisher">
        <div className="row no-maxWidth">
          {/* !understood ?
            <div>
              <div className="tip highlight">
                <div>
                  <div className="small-6 medium-8 columns" style={{paddingLeft: '0px'}}>
                    <h5>Collaborate with your suppliers</h5>
                  </div>
                  <div className="small-6 medium-4 columns" style={{paddingRight: '0px'}}>
                    <a className="button" onClick={onAddTemp}>Got it, thanks.</a>
                  </div>
                </div>
                <div style={{clear: 'both'}}>
                  <p>Select a supplier and its rep from the lists, tell them about your project and click Collaborate. Your supplier rep will be notified via email so they can work with you on your project. Suppliers that are part of commonsku collaborate will be able to suggest products which you can add to your project in 1 click.</p>
                  <p>You have full control over which product suggestions are added to your project and can see all requests and suggestions here in your project.</p>
                </div>
              </div>
              <br />
            </div>
          : null */}
          <div className="large-6 columns">
            <Select ref="collaborate_supplier_id" value={selectedSupplier} change={e => {this.handleChangeSupplier(e, onChangeSupplier);}} options={[{key: 'all-suppliers', value:'All Suppliers'}, ...suppliers]} withMarginBottom />
          </div>
          {this.state.selectedSupplier != 'all-suppliers' ?
            <div className="large-6 columns">
              <Select ref="collaborate_contact_email" value={selectedContactEmail} change={e => {this.handleChangeDivisionContact(e);}} options={[{key: 'select-rep', value:'Select Rep'}, ...divisionContacts]} disabled={this.state.disabled_select} withMarginBottom />
            </div>
          : null}
          <div className="medium-12 columns">
            <TextArea ref="collaborate_text" value={this.state.inviteText} placeholder="Please provide as much detail about your promotion as possible" onChange={e => this.handleChangeInviteText(e)}></TextArea>
          </div>
          <div className="medium-12 columns">
            {files[0] ?
              `${files[0].file_display_name} is selected.`
            : null}
          </div>
          <div className="row" style={{'display': 'none'}}>
            <div className="large-12 columns">
              <Select ref="collaborate_categories" value={selectedCategory} change={e => {this.handleChangeCategory(e);}} options={[{key: '', value:''}, ...categories]} withMarginBottom />
            </div>
          </div>
          <div className="large-3 columns">
            <a className="button" onClick={e => {e.preventDefault(); onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB', client_id);}}>
              <i className="fi-paperclip">&nbsp;&nbsp;</i> File
            </a>
          </div>
          <div className="large-3 columns">
            <a className="button alt" onClick={e => { e.preventDefault(); this.handleAddInvite(job_id, client_id, order_id, selectedContactEmail, this.state.inviteText, selectedSupplier, onAddInvite); }} disabled={allow_invite ? false : true}>Collaborate</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = _.find(state.entities.projects) || {}; // get first project
  let order = _.isEmpty(project) ? null :
    project.sales_orders.length > 0 ? 'sales_orders' :
    project.estimates.length > 0 ? 'estimates' :
    project.presentations.length > 0 ? 'presentations' :
    project.opportunities.length > 0 ? 'opportunities' :
    null;
  let understood = state.temp.UNDERSTOOD_COLLABORATE;

  return {
    suppliers: getSupplierCollaborateOptions(state),
    divisionContacts: getDivisionContactsOptions(state),
    categories: getCategoryOptions(state),
    order_id: _.get(project, [order, 0]),
    understood,
    files: state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddInvite: (job_id, action, client_id, order_id, order_type, supplier_contact_email, content, supplier_id, status, files) => {
      dispatch(createAddCollaborateInvitation(job_id, action, client_id, order_id, order_type, supplier_contact_email, content, supplier_id, status, files));
    },
    onChangeSupplier: (company_id) => {
      dispatch(createLoadDivisionContacts(company_id));
    },
    onAddTemp: () => {
      dispatch(createAddTemp('UNDERSTOOD_COLLABORATE', true));
    },
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id));
    },
    onCreateDeleteTemp: key => {
      dispatch(createDeleteTemp(key));
    }
  };
};

const ConnectedPublisherCollaborate = connect(mapStateToProps, mapDispatchToProps)(PublisherCollaborate);

export default ConnectedPublisherCollaborate;
