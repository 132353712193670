import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router";
import { getQueryParams } from "../utils";
import {
  DEFAULT_TILE_HEIGHT,
  DEFAULT_TILE_PADDING,
} from "../components/gallery/Tile";
import { useCartContext } from "./CartProvider";
import { usePublicViewEntityContext } from "./PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "./PublicViewTemplateDataProvider";
import { useInterval } from "../hooks";
import { window } from "../global";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProductItems } from "../actions/shop";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

export const screenChangeEvents = [
  "fullscreenchange",
  "webkitfullscreenchange",
  "mozfullscreenchange",
  "msfullscreenchange",
];

/**
 * @typedef {{
 *   playing: boolean;
 *   fullscreen: boolean;
 * }} GalleryControls
 */

/**
 * @typedef {{
 *   windowWidth: number;
 *   windowHeight: number;
 *   galleryWidth: number;
 * }} GalleryDimensions
 */

/**
 * @typedef {{
 *   width: number;
 *   height: number;
 * }} ItemSize
 */

/**
 * @typedef {{
 *   company_id: string;
 *   company_name: string;
 *   contact_first_name: string;
 *   contact_last_name: string;
 *   contact_email: string;
 * }} Contact
 */

/**
 * @typedef {{
 *   publicViewEntity: ReturnType<usePublicViewEntityContext>;
 *   galleryCart: ReturnType<useCartContext>;
 *   publicViewTemplate: ReturnType<usePublicViewTemplateDataContext>;
 *   galleryRef: React.MutableRefObject<HTMLDivElement>;
 *   fullscreenRoot: React.MutableRefObject<HTMLDivElement>;
 *   productPopupRoot: React.MutableRefObject<HTMLDivElement>;
 *   page: string;
 *   setPage: React.Dispatch<React.SetStateAction<string>>;
 *   loading: boolean;
 *   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
 *   selectedItemId: string | null;
 *   setSelectedItemId: React.Dispatch<React.SetStateAction<string | null>>;
 *   galleryControls: GalleryControls;
 *   setGalleryControls: React.Dispatch<React.SetStateAction<GalleryControls>>;
 *   galleryDimensions: GalleryDimensions;
 *   setGalleryDimensions: React.Dispatch<React.SetStateAction<GalleryDimensions>>;
 *   itemSizes: ItemSize[];
 *   setItemSizes: React.Dispatch<React.SetStateAction<ItemSize[]>>;
 *   extraImageId: object;
 *   setExtraImageId: React.Dispatch<React.SetStateAction<object>>;
 *   scrollToProductDropdown: (selected: any) => void;
 *   onImageLoad: (item_id: string) => (e: Event) => void;
 *   onSelectItem: (selected: any) => void;
 *   onSelectNextItem: () => void;
 *   onSelectPreviousItem: () => void;
 *   onSelectPage: (page: string) => void;
 *   onSelectImage: (image: any) => void;
 *   play: () => void;
 *   pause: () => void;
 *   handleResize: () => void;
 *   handleScreenchange: () => void;
 *   requestFullscreen: () => void;
 *   exitFullscreen: () => void;
 *   getDisplayItems: (item_id: any) => any;
 *   filter: string | null;
 *   editingCartItemId: string | null;
 *   showComments: boolean;
 *   showCart: boolean;
 *   isCartOpen: boolean;
 *   cartItemDetailsId: string | null;
 *   fullHeight: boolean;
 *   contact: Contact;
 *   onClickCart: (value?: boolean | null, cartItemDetailsId?: string | null) => void;
 * }} TPublicViewContext
 */

/**
 * @type {React.Context<undefined | TPublicViewContext>}
 */
export const publicViewContext = createContext();

const PublicViewProvider = ({
  children,
  cart,
  filter,
  selectedItemId,
  editingCartItemId,
  contact,
  showCart,
  onClickCart,
  fullHeight,
  cartItemDetailsId,

  showComments = true,
  wrapScrolling = true,
  slideDuration = 3000,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publicViewEntity = usePublicViewEntityContext();
  const galleryCart = useCartContext();
  const publicViewTemplate = usePublicViewTemplateDataContext();

  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const galleryRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const fullscreenRoot = useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const productPopupRoot = useRef(null);

  const [page, setPage] = useState("slide-show");
  const [loading, setLoading] = useState(false);
  const [galleryControls, setGalleryControls] = useState({
    playing: false,
    fullscreen: false,
  });
  const [galleryDimensions, setGalleryDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    galleryWidth: window.innerWidth - 2 * DEFAULT_TILE_PADDING,
  });
  const [itemSizes, setItemSizes] = useState(
    publicViewEntity.entityOrderItems
      .filter((i) => 1 !== +i.hidden && "OPTION" === i.parent_type)
      .reduce((s, i) => {
        s[i.item_id] = {
          width: DEFAULT_TILE_HEIGHT,
          height: DEFAULT_TILE_HEIGHT,
        };
        return s;
      }, {})
  );
  const [extraImageId, setExtraImageId] = useState({});

  const scrollToProductDropdown = useCallback(
    (selected) => {
      if (!publicViewTemplate.productInDropdown || !selected) {
        return;
      }
      const elem = document.getElementById("item-" + selected);
      const roolElem = document.getElementById("root");
      if (elem && roolElem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    [publicViewTemplate.productInDropdown]
  );

  const onImageLoad = useCallback(
    (item_id) => (e) => {
      const size = {
        width: e.target.naturalWidth,
        height: e.target.naturalHeight,
      };
      setItemSizes((s) => ({ ...s, [item_id]: size }));
    },
    []
  );

  const onNavigate = useCallback(
    (selected) => {
      const urlParams =
        +publicViewEntity.entityBuyInventory === 1 ? "?buy_inventory=true" : "";
      if (selected) {
        navigate(`${publicViewEntity.baseEntityUrl}/${selected}${urlParams}`);
      } else {
        navigate(`${publicViewEntity.baseEntityUrl}${urlParams}`);
      }
    },
    [publicViewEntity.baseEntityUrl, publicViewEntity.entityBuyInventory]
  );

  const onSelectItem = useCallback(
    (selected) => {
      setPage("slide-show");
      setLoading(true);
      onNavigate(selected);
    },
    [onNavigate]
  );

  const option_items = publicViewEntity.entityOrderItems.filter(
    (i) => 1 !== +i.hidden && "OPTION" === i.parent_type
  );

  const onSelectNextItem = useCallback(() => {
    const item_index = option_items
      .map((i) => i.item_id)
      .indexOf(selectedItemId);
    let nextItemId;

    if (item_index > option_items.length - 2) {
      if (wrapScrolling) {
        nextItemId = option_items[0].item_id;
      }
    } else {
      nextItemId = option_items[item_index + 1].item_id;
    }
    onNavigate(nextItemId);
  }, [selectedItemId, option_items, wrapScrolling, onNavigate]);

  const onSelectPreviousItem = useCallback(() => {
    const item_index = option_items
      .map((i) => i.item_id)
      .indexOf(selectedItemId);
    let previousItemId;

    if (item_index < 1) {
      if (wrapScrolling) {
        previousItemId = option_items[option_items.length - 1].item_id;
      }
    } else {
      previousItemId = option_items[item_index - 1].item_id;
    }
    onNavigate(previousItemId);
  }, [selectedItemId, option_items, wrapScrolling, onNavigate]);

  const onSelectPage = useCallback((page) => {
    setPage(page);
  }, []);

  const onSelectImage = useCallback(
    (image) => {
      setExtraImageId((state) => ({
        ...state,
        [selectedItemId]: image,
      }));
    },
    [selectedItemId]
  );

  const play = useCallback(() => {
    setGalleryControls((s) => ({ ...s, playing: true }));
  }, []);

  const pause = useCallback(() => {
    setGalleryControls((s) => ({ ...s, playing: false }));
  }, []);

  const handleResize = useCallback(() => {
    const galleryElem = galleryRef.current;
    setGalleryDimensions((s) => ({
      ...s,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      ...(galleryElem ? { galleryWidth: galleryElem.clientWidth } : {}),
    }));
  }, []);

  const handleScreenchange = useCallback(() => {
    const fullscreen = Boolean(
      document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement
    );
    setGalleryControls((s) => ({ ...s, fullscreen }));
  }, []);

  const requestFullscreen = useCallback(() => {
    const elem = fullscreenRoot.current;
    if (!elem) {
      return;
    }

    const requestFullscreenFn =
      elem.requestFullscreen ||
      elem.webkitRequestFullscreen ||
      elem.mozRequestFullScreen ||
      elem.msRequestFullscreen;
    setGalleryControls((s) => ({ ...s, fullscreen: true }));
    requestFullscreenFn?.bind(elem)();
  }, []);

  const exitFullscreen = useCallback(() => {
    const exitFullscreen =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozExitFullScreen ||
      document.msExitFullscreen;
    setGalleryControls((s) => ({ ...s, fullscreen: false }));
    exitFullscreen?.bind(document)();
  }, []);

  const getDisplayItems = useCallback(
    (item_id) => {
      const items = publicViewEntity.entityOrderItems.filter(
        (i) => 1 !== +i.hidden
      );
      if (!item_id) {
        return items.filter((i) => "OPTION" === i.parent_type);
      }
      const startIndex = items.map((i) => i.item_id).indexOf(item_id);
      if (-1 === startIndex) {
        return [];
      }
      const endIndex = items
        .slice(startIndex + 1)
        .map((i) => i.parent_type)
        .indexOf("SEPARATOR");
      if (-1 === endIndex) {
        return items
          .slice(startIndex)
          .filter((i) => "OPTION" === i.parent_type);
      }
      return items
        .slice(startIndex, startIndex + endIndex + 1)
        .filter((i) => "OPTION" === i.parent_type);
    },
    [publicViewEntity.entityOrderItems]
  );

  useInterval(
    () => onSelectNextItem(),
    galleryControls.playing ? slideDuration : null
  );

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const elem = galleryRef.current;
    if (elem) {
      setGalleryDimensions((s) => {
        if (elem?.clientWidth === s.galleryWidth) {
          return s;
        }
        return {
          ...s,
          galleryWidth: elem?.clientWidth,
        };
      });
    }
  }, [galleryRef.current?.clientWidth]);

  useEffect(() => {
    if (!selectedItemId) {
      pause();
    }
  }, [pause, selectedItemId]);

  const shouldLoadItem =
    !!selectedItemId &&
    publicViewEntity.entityOrderId &&
    publicViewEntity.entityOrderItems.length > 0 &&
    (publicViewEntity.entityOrderItems.find(
      (i) => i.item_id === selectedItemId && 1 !== +i.hidden
    )?.options?.length ?? 0) === 0;

  useEffect(() => {
    let ignore = false;
    if (shouldLoadItem) {
      setLoading(true);
      dispatch(
        fetchProductItems(selectedItemId, publicViewEntity.entityOrderId)
      ).then(() => {
        if (!ignore) {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
    return () => {
      ignore = true;
    };
  }, [selectedItemId, publicViewEntity.entityOrderId, shouldLoadItem]);

  useEffect(() => {
    scrollToProductDropdown(selectedItemId);
  }, [selectedItemId, scrollToProductDropdown]);

  const { onSetCart } = galleryCart;
  useDeepCompareEffect(() => {
    onSetCart(cart);
  }, [cart]);

  const value = useMemo(
    () => ({
      publicViewEntity,
      galleryCart,
      publicViewTemplate,
      galleryRef,
      fullscreenRoot,
      productPopupRoot,

      filter,
      editingCartItemId,

      page,
      setPage,
      loading,
      setLoading,
      selectedItemId,
      galleryControls,
      setGalleryControls,
      galleryDimensions,
      setGalleryDimensions,
      itemSizes,
      setItemSizes,
      extraImageId,
      setExtraImageId,

      showComments,

      showCart,
      isCartOpen: showCart,
      cartItemDetailsId,
      onClickCart,

      onImageLoad,
      scrollToProductDropdown,
      onSelectItem,
      onSelectNextItem,
      onSelectPreviousItem,
      onSelectPage,
      onSelectImage,
      play,
      pause,
      handleResize,
      handleScreenchange,
      requestFullscreen,
      exitFullscreen,
      getDisplayItems,
      fullHeight,
      contact,
    }),
    [
      publicViewEntity,
      galleryCart,
      publicViewTemplate,
      galleryRef,
      fullscreenRoot,
      productPopupRoot,

      filter,
      editingCartItemId,

      page,
      setPage,
      loading,
      setLoading,
      selectedItemId,
      galleryControls,
      setGalleryControls,
      galleryDimensions,
      setGalleryDimensions,
      itemSizes,
      setItemSizes,
      extraImageId,
      setExtraImageId,

      showComments,

      showCart,
      cartItemDetailsId,
      onClickCart,

      onImageLoad,
      scrollToProductDropdown,
      onSelectItem,
      onSelectNextItem,
      onSelectPreviousItem,
      onSelectPage,
      onSelectImage,
      play,
      pause,
      handleResize,
      handleScreenchange,
      requestFullscreen,
      exitFullscreen,
      getDisplayItems,
      fullHeight,
      contact,
    ]
  );

  return (
    <publicViewContext.Provider value={value}>
      {children}
    </publicViewContext.Provider>
  );
};

export const usePublicView = () => {
  const value = useContext(publicViewContext);
  const params = useParams();
  const location = useLocation();
  const queryParams = getQueryParams({ location });

  const getWidthGroups = useCallback((galleryWidth, widths) => {
    let offset = galleryWidth;
    let temp = [];
    let widthGroups = [];
    let i = 0;
    let added = 0;
    for (const w of widths) {
      if (offset === 0) {
        widthGroups.push(temp);
        added += temp.length;
        offset = galleryWidth;
        temp = [];
      }
      offset = offset - w;
      temp.push({ index: i, width: w });
      i += 1;
    }

    //  added any left out
    widthGroups.push(
      widths.slice(added).map((w, i) => ({ index: i + added, width: w }))
    );
    return widthGroups;
  }, []);

  const getWidths = useCallback(
    (items, fullWidth, fullHeight) => {
      return items
        .reduce(
          (rows, item) => {
            const lastRow = rows[rows.length - 1];
            const rowWidth = lastRow.reduce((t, w) => t + w, 0);
            const scale =
              DEFAULT_TILE_HEIGHT / value.itemSizes[item.item_id].height;
            const itemWidth =
              value.itemSizes[item.item_id].width * scale +
              DEFAULT_TILE_PADDING * 2;
            if (rowWidth + itemWidth > fullWidth + itemWidth / 2) {
              return rows.concat([[itemWidth]]);
            } else {
              return rows.slice(0, -1).concat([lastRow.concat(itemWidth)]);
            }
          },
          [[]]
        )
        .reduce((result, row, index, rows) => {
          const rowWidth = row.reduce((t, w) => t + w, 0);
          if (rowWidth < fullWidth && index === rows.length - 1) {
            return result.concat(row);
          }
          const allButLast = row
            .slice(0, -1)
            .map((w) => Math.floor((w / rowWidth) * fullWidth));
          return result.concat(
            allButLast.concat(fullWidth - allButLast.reduce((t, w) => t + w, 0))
          );
        }, []);
    },
    [value.itemSizes]
  );

  return useMemo(
    () => ({
      ...value,
      location,
      params: { ...params, queryParams },
      getWidthGroups,
      getWidths,
      showItemCount: true,
      showComments: false,
    }),
    [value, location, params, queryParams, getWidthGroups, getWidths]
  );
};

export default PublicViewProvider;
