import { oauth } from '../utils';
import { createFinalizeUpdateCompanyData } from './index';

export const connectAvalara = ({ avalara_account_id, avalara_license_key, avalara_environment }) => (dispatch) => {
  return oauth('POST', 'avalara', {
    action: 'connect',
    avalara_account_id, avalara_license_key, avalara_environment
  })
    .then(({ json }) => {
      return dispatch(createFinalizeUpdateCompanyData({ company_data: json.company_data, }));
    })
  ;
};

export const disconnectAvalara = () => (dispatch) => {
  return oauth('POST', 'avalara', {action: 'disconnect'})
    .then(({ json }) => {
      return dispatch(createFinalizeUpdateCompanyData({ company_data: json.company_data, }));
    })
  ;
};

export const CREATE_AVALARA_CATEGORY_SUCCESS = 'CREATE_AVALARA_CATEGORY_SUCCESS';
export const createAvalaraCategory = ({ category, avalara_tax_code }) => (dispatch) => {
  return oauth('POST', 'avalara-category', { category, avalara_tax_code })
    .then(({ json }) => {
      return dispatch({
        type: CREATE_AVALARA_CATEGORY_SUCCESS,
        payload: {
          avalara_category: json.avalara_category,
        },
      });
    })
  ;
};

export const UPDATE_AVALARA_CATEGORY_SUCCESS = 'UPDATE_AVALARA_CATEGORY_SUCCESS';
export const updateAvalaraCategory = ({
  avalara_category_id, category, avalara_tax_code
}) => (dispatch) => {
  return oauth('PUT', `avalara-category/${avalara_category_id}`, {
    category, avalara_tax_code
  })
    .then(({ json }) => {
      return dispatch({
        type: UPDATE_AVALARA_CATEGORY_SUCCESS,
        payload: {
          avalara_category: json.avalara_category,
        },
      });
    })
    ;
};

export const DELETE_AVALARA_CATEGORY_SUCCESS = 'DELETE_AVALARA_CATEGORY_SUCCESS';
export const deleteAvalaraCategory = (avalara_category_id) => (dispatch) => {
  return oauth('DELETE', `avalara-category/${avalara_category_id}`, {})
    .then(({ json }) => {
      return dispatch({
        type: DELETE_AVALARA_CATEGORY_SUCCESS,
        payload: {
          avalara_category: json.avalara_category,
        },
      });
    })
    ;
};