import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

const botsAdapter = createEntityAdapter({
    selectId: (bot) => bot.bot_id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const botsSlice = createSlice({
    name: 'bots',
    initialState: botsAdapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        botAdded: botsAdapter.addOne,
        botsLoading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        botsReceived(state, action) {
            if (state.loading === 'pending') {
                botsAdapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        botUpdated: botsAdapter.updateOne
    }
});

export const  {
    botAdded,
    botsLoading,
    botsReceived,
    botUpdated
} = botsSlice.actions;

export const botSelectors = botsAdapter.getSelectors();

export default botsSlice.reducer;

