import { drop, map, concat, take, compact } from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { TabBar, Tab, colors, EllipsisIcon, Box, Row, SearchIcon, LabeledInput } from '@commonsku/styles';
import SupplierAvatar from '../suppliers/SupplierAvatar';
import { useIconVisibleSize } from './hooks';

const MoreSuppliersContainer = styled.div`
  &&& {
    width: 550px;
    position: absolute;
    top: 100px;
    right: 0;
    border-radius: 5px 0 5px 5px;
    border-width: 3px;
    border-color: rgb(0, 160, 182);
    border-style: solid;
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    background-color: white;
    cursor: auto;
  }
`;

const SupplierListContainer = styled.div`
  &&& {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 14px;
    width: 100%;
    max-height: 400px;
    overflow: auto;
  }
`;

const SupplierItem = styled.div`
  &&& {
    flex: 0 1 calc(33% - 10px);
    color: rgb(0, 177, 200);
    border: 5px solid transparent;
    cursor: pointer;
    font-weight: 400;
    line-height: 24px;

    &.selected {
      border: 5px solid ${colors.pink.main};
    }
  }
`;

const KeywordsInput = ({ value, onChange, onKeyDown }) => {
  const height = '2.4375rem';
  const iconStyles = {
    height,
    lineHeight: height,
    borderRadius: 3,
    textAlign: 'center',
    position: 'absolute',
    fontSize: '1em',
    top: '0px',
    margin: '0px 5px 0px 8px',
  };
  return <div style={{ position: 'relative', width: '100%' }}>
    <div className="search-box" style={{
      display: 'inline-block', width: '100%'
    }}>
      <SearchIcon style={iconStyles} color='rgb(194, 219, 226)' />
      <LabeledInput
        style={{ paddingLeft: height, fontWeight: 400, marginBottom: 0 }}
        type='text'
        placeholder='Search'
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  </div>;
};

const SupplierIconContainer = ({
  handleClickTab,
  supplierList,
  previousScrollTopPosition,
  hideSupplier,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ index: 0, supplierId: null });
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const moreTabWrapperRef = useRef(null);
  const supplierItemRefs = useRef([]);
  supplierItemRefs.current = [];

  const columns = 3;

  const tabs = concat([{ label: 'All' }], map(supplierList, (supplier) => {
    return { label: <SupplierAvatar style={{ width: 50, height: 50, display: 'block', margin: 'auto' }} avatar={supplier.avatar} />, tab: supplier, name: supplier.supplier_name, title: supplier.supplier_name }
  }));
  const iconRef = useRef()
  const visibleSize = useIconVisibleSize(iconRef);
  const visibleTabs = take(tabs, visibleSize);
  const collapsedTabs = drop(tabs, visibleSize);
  const filteredCollapsedTabs = collapsedTabs.filter((supplier) => {
    return supplier.name?.toLowerCase().includes(searchTerm.toLowerCase())
  });

  const totalItems = filteredCollapsedTabs.length;

  useEffect(() => {
    if (showMore) {
      const handleClickOutside = (event) => {
        if (
          moreTabWrapperRef.current &&
          !moreTabWrapperRef.current.contains(event.target)
        ) {
          setShowMore(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [showMore]);

  useEffect(() => {
    if (!showMore) {
      setHighlightedIndex(-1);
      setSearchTerm('');
    }
  }, [showMore]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [searchTerm]);

  useEffect(() => {
    if (highlightedIndex >= 0 && supplierItemRefs.current[highlightedIndex]) {
      supplierItemRefs.current[highlightedIndex].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [highlightedIndex]);

  const handleChangeTab = (i, supplierId) => {
    setSelectedOption({ index: i, supplierId: supplierId });
    const selectedSupplierId = supplierId ?? tabs[i]?.tab?.supplier_id ?? null;
    handleClickTab(selectedSupplierId);
    setShowMore(false);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const navigationKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter', 'Escape'];

    if (!navigationKeys.includes(key)) {
      return;
    }

    e.preventDefault();

    if (key === 'Enter') {
      if (highlightedIndex >= 0 && highlightedIndex < totalItems) {
        const supplier = filteredCollapsedTabs[highlightedIndex];
        const i = highlightedIndex + visibleSize;
        const supplierId = supplier.tab?.supplier_id ?? null;
        handleChangeTab(i, supplierId);
      }
      setHighlightedIndex(-1);
      return;
    }

    if (key === 'Escape') {
      setShowMore(false);
      return;
    }

    setHighlightedIndex((prevIndex) => {
      let nextIndex;
      switch (key) {
        case 'ArrowDown':
          if (prevIndex === -1) {
            nextIndex = 0;
          } else {
            nextIndex = prevIndex + columns;
            if (nextIndex >= filteredCollapsedTabs.length) {
              nextIndex = filteredCollapsedTabs.length - 1;
            }
          }
          break;
        case 'ArrowUp':
          if (prevIndex === -1) {
            nextIndex = filteredCollapsedTabs.length - 1;
          } else {
            nextIndex = prevIndex - columns;
            if (nextIndex < 0) {
              nextIndex = 0;
            }
          }
          break;
        case 'ArrowLeft':
          if (prevIndex === -1) {
            nextIndex = filteredCollapsedTabs.length - 1;
          } else {
            nextIndex = prevIndex - 1;
            if (nextIndex < 0) {
              nextIndex = filteredCollapsedTabs.length - 1;
            }
          }
          break;
        case 'ArrowRight':
          if (prevIndex === -1) {
            nextIndex = 0;
          } else {
            nextIndex = prevIndex + 1;
            if (nextIndex >= filteredCollapsedTabs.length) {
              nextIndex = 0;
            }
          }
          break;
        default:
          nextIndex = prevIndex;
      }
      return nextIndex;
    });
  };

  return <Row className='full-width' style={{
    position: 'sticky',
    top: 0,
    visibility: hideSupplier ? 'hidden' : 'visible',
    transition: `all 200ms ${hideSupplier ? 'ease-out' : 'ease-in'}`,
    transform: hideSupplier ? 'translate(0, -100%)' : 'none',
    zIndex: 3,
    justifyContent: 'center'
  }} ref={iconRef}>
    <Box borderless style={{
      background: '#FFFFFF', boxShadow: previousScrollTopPosition == 0 ? 'none' : '0px 3px 23px rgba(0, 0, 0, 0.4)',
      borderRadius: '2000px'
    }}>
      <TabBar {...props} style={{ display: 'flex', position: 'relative', zIndex: 1, padding: 5 }} >
        {map(
          compact([
            ...visibleTabs,
            collapsedTabs.length && {
              label: <>
                <div style={{ display: 'flex' }}>{EllipsisIcon({ color: colors.black })}</div>
                {
                  showMore && <MoreSuppliersContainer onClick={e => e.stopPropagation()}>
                    <KeywordsInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={handleKeyDown} />
                    <SupplierListContainer >
                      {
                        filteredCollapsedTabs.length > 0 ?
                          map(filteredCollapsedTabs, (supplier, j) => {
                            const i = j + visibleSize;
                            const supplierId = supplier.tab?.supplier_id ?? null;
                            const isHighlighted = highlightedIndex === j;
                            return <SupplierItem
                              key={supplierId}
                              ref={el => supplierItemRefs.current[j] = el}
                              className={`${supplierId === selectedOption.supplierId ? 'selected' : ''} ${isHighlighted ? 'highlighted' : ''}`}
                              onClick={() => handleChangeTab(i, supplierId)}
                            >{supplier.name}</SupplierItem>;
                          })
                          :
                          <div style={{ padding: '0 16px' }}>No suppliers found</div>
                      }
                    </SupplierListContainer>
                  </MoreSuppliersContainer>
                }
              </>
              ,
              onClick: (e) => {
                e.stopPropagation();
                setShowMore(!showMore)
              },
            }
          ]),
          ({ label, ...tabProps }, i) => {
            const isMoreTab = i == visibleTabs.length && filteredCollapsedTabs.length;
            const selected = i == selectedOption.index || (i == visibleSize && selectedOption.index > visibleSize);
            return <Tab
              key={i}
              ref={isMoreTab ? moreTabWrapperRef : null}
              className={selected ? 'selected' : ''}
              selected={selected}
              onClick={() => handleChangeTab(i)}
              {...tabProps}
            >{label}</Tab>;
          }
        )}
      </TabBar>
    </Box>
  </Row>;
};

export default styled(SupplierIconContainer)`
  &&& {
    li {
      width: 88px;
      height: 88px;
      background: rgb(255, 255, 255);
      ${props => props.previousScrollTopPosition == 0 && 'filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 3px 5px);'}
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-family: 'skufont-demibold', sans-serif;
      font-weight: 700;
      opacity: 1;

      &:hover {
        transform: translateY(-5px);
        transition: .3s all;
      }
    }

    li:last-child {
      margin: 0;
    }
    
    li.selected, .dropdown-item.selected {
      border: 5px solid ${colors.pink.main};
    }
    
    .collapsible-tabs-dropdown {
      display: flex;
      button {
        padding: 0;
        font-size: 16px;
        border: none;
        background-color: inherit;
        font-family: 'skufont-demibold', sans-serif;
      }
      span {
        display: flex;
      }
      > div > div {
        right: -30px;
        top: 60px;
        width: 197px;
        text-align: center;
      }
    }

    div${SupplierItem}.highlighted {
      border: 5px solid ${colors.primary1.light};
    }
  }
`;
