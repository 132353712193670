import React from 'react';
import { connect } from 'react-redux';

import { formatMoney } from '../utils';
import BaseItemSummary, { ItemSummaryTaxTd } from './BaseItemSummary';
import { withDraggableItem } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';

class ArtworkPOItemSummary extends BaseItemSummary {

  constructor(props) {
    super(props);
  }

  renderActions() {
    const { has_bill } = this.props;

    return (
      <span className="actions">
        {this.renderToggleAction('hidden', 'hide', 'show')}
        {this.renderEditAction()}
        {this.renderCopyAction()}
        {!has_bill ? this.renderDeleteAction() : null}
      </span>
    );
  }

  renderItem() {
    const { item, detailed } = this.props;
    return (
      <tr>
        <td className="description" colSpan={detailed ? 2 : 1}>
          <span className="product-name">{item.item_name}</span>
          {this.renderActions()}
        </td>
        <td className="quantity">{parseInt(item.total_units, 10)}</td>
        {detailed ? <td className="amount">${formatMoney(item.unit_price)}</td> : null}
        <td className="margin">{null === item.total_margin ? 'N/A' : parseFloat(item.total_margin).toFixed(2) + '%'}</td>
        <ItemSummaryTaxTd
          item={item}
          renderTaxAmounts={(tax_amounts) => {
            return tax_amounts.map(t => t.label).reduce((taxes, tax) => `${taxes} ${tax}`, '');
          }}
        />
        <td className="amount">${formatMoney(item.total_subtotal)}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const has_bill = Object.values(state.entities.bills).filter(b => b.items.filter(i => i.item_id === ownProps.item.item_id)).length;

  return {
    has_bill
  };
};

export default connect(mapStateToProps, null)(withDraggableItem(DragItemTypes.ITEM, ArtworkPOItemSummary));
