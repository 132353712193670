import React from "react";
import { ChevronIcon, Col, Row } from "@commonsku/styles";

export default function CustomizationPanel(props) {
  const {
    children,
    isOpen = false,
    togglePanel,
    title,
    style={},
  } = props;

  return (
    <div style={{ background: 'var(--color-neutrals-white)', padding: '8px 16px', borderRadius: 5, ...style }}>
      <Row
        onClick={togglePanel}
        style={{
          cursor: 'pointer',
        }}
      >
        <Col xs={11}>
          {title}
        </Col>
        <Col xs={1} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <ChevronIcon color='var(--color-primary1-main)'
            direction={isOpen ? 'up' : 'down'}
          />
        </Col>
      </Row>
      <div style={{
        marginTop: 16,
        paddingTop: 16,
        borderTop: '1px solid var(--color-neutrals-40)',
        ...(!isOpen ? { display: 'none' } : {}),
      }}>
        {children}
      </div>
    </div>
  );
}
