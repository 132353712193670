import React from "react";
import { Button, Text, ToggleSwitchStyled, } from "@commonsku/styles";

export default function CategoryImages(props) {
  const {
    value,
    onChange,
    onClick,
  } = props;

  return (
    <>
      <Text as="p" style={{
        color: 'var(--color-neutrals-90)',
        fontFamily: 'var(--font-family-bold)',
        fontSize: 20,
        marginBottom: 0,
      }}>Categories</Text>
      <Button
        variant="primary"
        size="medium"
        style={{ width: '100%', marginBottom: 14, marginTop: 7, }}
        onClick={onClick}
        data-testid="edit_category_images_btn"
      >Edit Category Images</Button>
      <div>
        <div style={{ display: 'inline-block', width: '56%', verticalAlign: 'middle', }}>
          <Text as="p" style={{
            color: 'var(--color-neutrals-90)',
            fontFamily: 'var(--font-family-regular)',
            fontSize: 16,
            marginBottom: 4,
          }}>Show Category Title</Text>
        </div>
        <div style={{ display: 'inline-block', width: '30%', verticalAlign: 'middle', }}>
          <ToggleSwitchStyled
            size="medium"
            selected={+value === 1}
            onClick={() => onChange(+value !== 1 ? 1 : 0)}
            style={{
              minWidth: '100%',
            }}
            data-testid="show_category_title_toggle"
          />
        </div>
      </div>
    </>
  );
}
