import React from "react";
import { ArrowIcon, Button, Col, IconButton, Row, StarIcon, Text } from "@commonsku/styles";
import Img from "../../../Img";

export default function ThemeTile({ title, imgSrc='', isSelected=false, isFavourite=false, onClickFavourite, onClickPreview, onClickSelect, onClose }) {
  return (
    <Row style={{
      background: 'var(--color-neutrals-20)',
      padding: 16,
      borderRadius: 5,
      ...(isSelected ? { border: '2px solid var(--color-primary1-main)' } : {}),
    }}>
      <Col xs style={{ height: 282, textAlign: 'center' }}>
        <Img style={{ height: '100%' }} alt={title} src={imgSrc} />
      </Col>
      <Col xs style={{ paddingTop: 24 }}>
        <Text style={{ color: 'var(--color-neutrals-90)', fontFamily: 'var(--font-family-regular)', fontSize: 18 }}>
          {title} Theme {title === 'Featured' ? '(customized)' : ''}
        </Text>
      </Col>
      {/* <Col xs={3} style={{ paddingLeft: 8, paddingTop: 24, textAlign: 'right' }}>
        <div onClick={onClickFavourite}>
          <StarIcon filled={isFavourite} />
        </div>
      </Col> */}
      <Col xs={6} style={{ paddingRight: 8, paddingTop: 8 }}>
        <Button
          variant="secondary"
          size="medium"
          style={{ width: '100%' }}
          onClick={onClickPreview}
        >Preview</Button>
      </Col>
      <Col xs={6} style={{ paddingLeft: 8, textAlign: 'right', paddingTop: 8 }}>
        <IconButton
          variant="primary"
          size="medium"
          style={{ width: '100%', paddingLeft: '8px', paddingRight: '8px' }}
          onClick={isSelected ? onClose : onClickSelect}
          Icon={isSelected ? ArrowIcon : null}
          iconPosition="right"
          iconProps={{ direction: 'right', }}
        >{isSelected ? 'Customize' : 'Select'}</IconButton>
      </Col>
    </Row>
  );
}
