import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemMetaData } from "../interfaces/Metadata";
import { TNumber } from "../interfaces/common";
import { saveProductQuestionsToLocalstorage } from "./hooks/product_questions";

export type State = {
  data: { [key: string]: { [key: string]: ItemMetaData } };
  error?: { [key: string]: string } | null;
};

const initialState: State = {
  data: {},
  error: {},
};

const slice = createSlice({
  name: "productQuestions",
  initialState: initialState,
  reducers: {
    updateError(
      state,
      action: PayloadAction<{
        metadata_id?: string | null;
        errorMessage?: string;
      }>
    ) {
      if (!action.payload.metadata_id) {
        state.error = null;
        return;
      }
      state.error = {
        ...(state.error || {}),
        [action.payload.metadata_id]: action.payload.errorMessage,
      };
    },
    updateData(
      state,
      action: PayloadAction<{
        metadata_id: string;
        label: string;
        value: string;
        parent_id: string;
        type: string;
        mandatory: TNumber;
        options: {
          key: string;
          value: string;
        } | null;
        shop_id: string;
      }>
    ) {
      const metadata_id = action.payload.metadata_id;
      const parent_id = action.payload.parent_id;

      const newState = !action.payload.value
        ? (() => {
            const { [metadata_id]: _, ...newParentState } =
              state.data?.[parent_id] || {};
            if (Object.keys(newParentState).length === 0) {
              const { [parent_id]: __, ...newUpdatedState } = state.data || {};
              return { data: newUpdatedState };
            }
            return {
              data: { ...(state.data || {}), [parent_id]: newParentState },
            };
          })()
        : {
            data: {
              ...(state.data || {}),
              [parent_id]: {
                ...(state.data?.[parent_id] || {}),
                [metadata_id]: {
                  metadata_id: metadata_id,
                  date_created: "",
                  mandatory: action.payload.mandatory,
                  parent_id: parent_id,
                  parent_type: "ITEM",
                  label: action.payload.label,
                  value: action.payload.value,
                  type: action.payload.type,
                  options: action.payload.options,
                },
              },
            },
          };
      state.data = newState.data;
      saveProductQuestionsToLocalstorage(action.payload.shop_id, newState);
    },
    setData(state, action: PayloadAction<{ data: State["data"] }>) {
      state.data = action.payload.data || {};
    },
  },
});

export const {
  updateError: updateProductQuestionsError,
  updateData: updateProductQuestionsData,
  setData: setProductQuestionsData,
} = slice.actions;

export default slice.reducer;
