import React from 'react';
import { connect } from 'react-redux';
import { getItemColors, getItemSizes, getItemSkus } from '../../selectors/items';
import { createColorSizeSubsetPopup, createSelectSkuOptionsPopup } from '../../actions/popup';
import { sizeSort } from '../../utils';

const MAX_VARIANTS = 20;

const VARIANT_MAP = {
  color: {
    variant: 'color',
    title: 'Colors',
    button: 'Update Colors',
    getVariants: getItemColors,
    textTransform: 'capitalize',
    onEdit: createColorSizeSubsetPopup
  },
  size: {
    variant: 'size',
    title: 'Sizes',
    button: 'Update Sizes',
    getVariants: getItemSizes,
    textTransform: 'uppercase',
    onEdit: createColorSizeSubsetPopup
  },
  sku: {
    variant: 'sku',
    title: 'Options',
    button: 'Update Options',
    getVariants: getItemSkus,
    textTransform: 'none',
    onEdit: createSelectSkuOptionsPopup
  },
  dimension: {
    title: 'Dimensions',
    button: 'Update Options',
    textTransform: 'none',
    onEdit: createSelectSkuOptionsPopup
  }
};

const ItemVariants = ({ variants, title, button, textTransform, onEditVariants, locked = false }) => (
  <div className="large-6 columns color-select-column">
    <div className="row row-pb">
      <label style={{ float: 'left' }}>{title}</label>
      {!locked && <a onClick={onEditVariants} style={{ float: 'right' }}>{button}</a>}
    </div>
    <div className="select-btn-container">
      {variants.slice(0, MAX_VARIANTS).map(v => v.name).join(', ')}
      {variants.length > MAX_VARIANTS && <span>,&hellip;</span>}
    </div>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  variants: ownProps.getVariants(state, { item_id: ownProps.item_id })
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditVariants: () => dispatch(ownProps.onEdit(ownProps.item_id, ownProps.product_id, ownProps.variant))
});

const ConnectedItemVariants = connect(mapStateToProps, mapDispatchToProps)(ItemVariants);

export const ItemColors = props => (
  <ConnectedItemVariants {...VARIANT_MAP['color']} {...props} />
);

export const ItemSizes = props => (
  <ConnectedItemVariants {...VARIANT_MAP['size']} {...props} />
);

const BaseItemSkus = connect(mapStateToProps, mapDispatchToProps)(props => {
  const sku_options = props.variants.reduce(
    (t, item_sku) => ({
      ...t,
      ...item_sku.options.reduce(
        (o, option) => ({
          ...o,
          [option.option_axis]: Array.from(new Set((t[option.option_axis] || []).concat(option.option_name))).sort(
            'size' === option.option_axis ? sizeSort : undefined
          )
        }),
        {}
      )
    }),
    {}
  );
  const axes = Object.keys(sku_options).filter(a => 'dimension' !== a || (sku_options[a].length > 1 && !sku_options['size'])).sort();
  if (axes.length > 0) {
    return (
      <React.Fragment>
        {axes.map(
          (axis, index) => {
            const variants = sku_options[axis].map((v ) => ({ id: v, name: v }));
            const parameters = VARIANT_MAP[axis] ? VARIANT_MAP[axis] : VARIANT_MAP['sku'];
            return (
              <ItemVariants key={axis} {...props} {...parameters} button="Update Options" variants={variants} locked={index < axes.length - 1} />
            );
          }
        )}
      </React.Fragment>
    );
  }
  return (
    <ConnectedItemVariants {...VARIANT_MAP['sku']} {...props} />
  );
});

export const ItemSkus = props => (
  <BaseItemSkus {...VARIANT_MAP['sku']} {...props} />
);
