import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import usePublicViewEntity from '../hooks/usePublicViewEntity';
import { useDispatch } from 'react-redux';
import { createDeletePublicViewTemplateData, createUpdatePublicViewTemplateData, updateShop } from '../actions/shop';
import updateOrder from '../actions/order';
import { createUpdatePublicViewProperty } from '../actions/public_view';

/**
 * @type {React.Context<undefined | ReturnType<typeof usePublicViewEntity>>} publicViewEntityContext
 */
const publicViewEntityContext = createContext();

const PublicViewEntityProvider = ({ children, initialValue }) => {
  const value = usePublicViewEntity(initialValue);

  const { setViewEntity } = value;
  useEffect(() => {
    setViewEntity(initialValue);
  }, [initialValue]);

  return (
    <publicViewEntityContext.Provider value={value}>
      {children}
    </publicViewEntityContext.Provider>
  );
};

export const usePublicViewEntityContext = () => {
  const dispatch = useDispatch();
  const result = useContext(publicViewEntityContext);

  const onUpdateTemplateField = useCallback((field, value) => {
    return dispatch(
      createUpdatePublicViewTemplateData(
        result.entityId,
        result.entityType,
        result.entityLayoutId,
        field,
        value
      )
    );
  }, [result.entityId, result.entityType, result.entityLayoutId]);

  const onDeleteTemplateField = useCallback((field) => {
    return dispatch(
      createDeletePublicViewTemplateData(
        result.entityId,
        result.entityType,
        result.entityLayoutId,
        field
      )
    );
  }, [result.entityId, result.entityType, result.entityLayoutId]);

  const onUpdatePublicViewProperty = useCallback((field, value) => {
    return dispatch(
      createUpdatePublicViewProperty(
        result.entityId,
        result.entityType,
        field,
        value
      )
    );
  }, [result.entityId, result.entityType]);

  const onDeletePublicViewProperty = useCallback((field) => {
    return dispatch(
      createUpdatePublicViewProperty(
        result.entityId,
        result.entityType,
        field
      )
    );
  }, [result.entityId, result.entityType]);

  const onUpdateEntity = useCallback((field, previous_value) => value => {
    if (['template_color', 'product_list_style'].includes(field)) {
      return onUpdateTemplateField(field, value);
    }
    const fn = result.isShop ? updateShop : updateOrder;
    return dispatch(fn(result.entityId, field, previous_value, value));
  }, [result.entityId, result.isShop, onUpdateTemplateField]);

  return useMemo(() => ({
    ...result,
    onUpdateEntity,
    onUpdateTemplateField,
    onDeleteTemplateField,
    onUpdatePublicViewProperty,
    onDeletePublicViewProperty,
  }), [
    result,
    onUpdateEntity,
    onUpdateTemplateField,
    onDeleteTemplateField,
    onUpdatePublicViewProperty,
    onDeletePublicViewProperty,
  ]);
};

export default PublicViewEntityProvider;
