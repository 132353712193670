import { createSelector } from '@reduxjs/toolkit';

export const getClients = s => s.entities.clients;
export const getClientsList = createSelector(
    getClients,
    s => Object.values(s || {})
);

const trimLabel = (label) => {
    const result = `${label || ''}`.trim();
    if (result.length > 100) {
        return result.slice(0, 100) + '...';
    }

    return result;
};

const clientOptionMapFunc = v => ({
    label: trimLabel(v.client_name),
    value: v.client_id
});

export const clientOptions = createSelector(
    getClientsList,
    s => s.map(clientOptionMapFunc)
);
