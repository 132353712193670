import React from 'react';

import { oauth, makeCancelable } from '../../utils';

import Select from '../Select';
import CollectionBadge from '../CollectionBadge';
import Size from '../ScreenSize';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Col, Input, Label, LabeledSelect, Row, SearchIcon } from '@commonsku/styles';
import { find, findIndex, isEmpty, map, pick } from 'lodash';
import NewProduct from '../NewProduct';
import ProductChevronPopup from '../product/ProductChevronPopup';
import { getOrderItems } from '../../utils/order';
import { getOrderByNumber } from '../../selectors';
import { createLoadOrder } from '../../actions/order';
import { connect } from 'react-redux';

class CollectionProductSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searched: false,
      showProductPopup: false,
      selectedProduct: null,
      collections: [],
      items: []
    };

    this.onChangeSuppliers = this.onChangeSuppliers.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);
    this.onSearchResult = this.onSearchResult.bind(this);
    this.onSelectCollection = this.onSelectCollection.bind(this);
    this.onChangeProductKeyword = this.onChangeProductKeyword.bind(this);
    this.handleClickReset = this.handleClickReset.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.suppliers && !this.props.suppliers.length) {
      this.props.loadSuppliers('collection');
    }
  }

  componentDidMount() {
    this.handleOrder(this.props.full_order);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.full_order !== this.props.full_order) {
      this.handleOrder(this.props.full_order);
    }
  }

  handleOrder = (full_order) => {
    const { onLoadOrder } = this.props;

    if (full_order) {
      if (!full_order.loaded) {
        onLoadOrder(full_order.order_id);
      } else {
        this.setState({ addedProducts: getOrderItems(full_order) });
      }
    }
  };

  onSearchResult(page, response, search_terms, field, options) {
    let collections = [];
    let items = [];
    if (!isEmpty(search_terms.keyword)) {
      items = page > 0 ? this.state.items.concat(response.items.filter(i => i.order_id !== this.props.order_id)) :
        response.items.filter(i => i.order_id !== this.props.order_id);
    } else {
      collections = page > 0 ?
        this.state.collections.concat(response.collections.filter(c => c.order_id !== this.props.order_id)) :
        response.collections.filter(c => c.order_id !== this.props.order_id);
    }
    this.setState({
      page,
      collections,
      items,
      searched: true,
      loading: false
    });

  }

  handleLoadNextPage() {
    if (this.state.items && this.state.items.length === (this.state.page + 1) * PAGE_SIZE && !this.state.loading) {
      this.onSearch(this.state.page + 1);
    }
  }

  onSelectCollection(collection_id) {
    this.props.onCreateSpinner();
    const search = makeCancelable(oauth('GET', `collection/${collection_id}`, {}));
    this.active_searches.push(search);
    search.promise.then(
      ({ json }) => {
        this.props.onClosePopup();
        this.props.onCreateSelectCollectionItemPopup(json.collection, this.props.order_id, this.props.index);
      },
      ({ json }) => {
        console.log(json);
      }
    );
  }

  onChangeProductKeyword(e) {
    this.setState(state => ({ search: { ...state.search, product_keyword: e.target.value } }));
  }

  onChangeSuppliers(supplier_ids) {
    this.setState(state => ({ search: { ...state.search, supplier_ids } }));
  }

  onChangeTheme(themes) {
    this.setState(state => ({ search: { ...state.search, themes } }));
  }

  loadDivisions() {
    this.props.loadSuppliers('collection');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        product_keyword: '',
        supplier_ids: '',
        themes: '',
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        product_keyword: this.props.user_search.product_keyword || '',
        supplier_ids: '',
        themes: '',
      };
    }
  }

  getEndpoint() {
    return 'collection' + (isEmpty(this.state.search.product_keyword) ? '' : '/items');
  }

  getSearchTerms(page) {
    let search_terms = {
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.search_term) {
      search_terms.job_name = this.state.search.search_term;
    }
    if (this.state.search.product_keyword) {
      search_terms.keyword = this.state.search.product_keyword;
    }
    if (!isEmpty(this.state.search.supplier_ids)) {
      search_terms.supplier_ids = map(this.state.search.supplier_ids, 'value');
    }
    if (this.state.search.themes) {
      search_terms.themes = this.state.search.themes;
    }
    return search_terms;
  }

  handleClickReset(e) {
    e.preventDefault();
    this.setState({
      searched: false,
      search: this.getInitialSearch('reset'),
      collections: [],
      items: [],
      page: 0
    }, () => this.props.handleClearingSearch());
  }

  renderCollections() {
    if (this.state.collections.length) {
      const style = {
        position: 'absolute',
        top: 0,
        right: '15px',
        zIndex: 1,
        lineHeight: 0
      };
      return this.state.collections.map((c, index) => [
        index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null,
        index % 3 === 0 ? <Size.MediumOnly key={`clearmedium-${index}`} style={{ clear: 'both' }} /> : null,
        index % 4 === 0 ? <Size.LargeOnly key={`clearlarge-${index}`} style={{ clear: 'both' }} /> : null,
        <div key={c.order_id} onClick={() => this.onSelectCollection(c.order_id)} className="small-6 medium-4 large-3 columns end" style={{ position: 'relative' }}>
          <a href={`/collection/${c.order_id}`} target="_blank" style={style}>
            <img src="/images/popup.png" />
          </a>
          <CollectionBadge collection={c} style={{ cursor: 'pointer' }} />
        </div>
      ]);
    } else if (this.state.searched) {
      return <div className="small-12 columns">There are no collections matching your search criteria.</div>;
    } else {
      return (
        <div className="column tip">
          <p>Search for collections of products prepared by suppliers on commonsku.</p>
        </div>
      );
    }
  }

  findSelected(id, item_copied_from, item_item_sku) {
    return find(this.state.addedProducts, ({ ext_product_id, parent_id, copied_from, item_sku }) => {
      if (['esp-products', 'dc-products', 'sage-products'].includes(item_copied_from) && copied_from === "ps-products" && item_sku === item_item_sku) {
        return true;
      }
      return (ext_product_id && ext_product_id === id) || (parent_id && parent_id === id);
    });
  };

  renderItem(item) {
    const { loading } = this.props;
    const onLoading = loading.includes(item.ext_product_id);

    return <NewProduct
      key={item.item_id}
      product={item}
      title={item.item_name}
      description={item.division_name}
      subtitle={item.job_name ? item.job_name : `Collection ${item.job_number}`}
      loading={onLoading}
      loaded={!!this.findSelected(item.parent_id, item.copied_from, item.item_sku)}
      type="others"
      onSubTitleClick={() => window.open(`/collection/${item.order_id}`)}
      onClickProduct={() => this.onClickProduct(item)}
      onClickProductAction={() => {
        if (!onLoading) {
          this.handleClickProductAction(item);
        }
      }}
    />;
  }

  renderItems() {
    if (this.state.items.length) {
      return <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', padding: '0 10px' }}>
        {this.state.items.map((item) => this.renderItem(item))}
      </div>
    } else if (this.state.searched) {
      return <div className="small-12 columns">There are no products matching your search criteria.</div>;
    } else {
      return (
        <div className="column tip">
          <p>Search for products of products prepared by suppliers on commonsku.</p>
        </div>
      );
    }
  }

  onClickProduct(product) {
    this.setState({
      showProductPopup: true,
      selectedProduct: product
    })
  }

  onClose() {
    this.setState({
      showProductPopup: false,
      selectedProduct: null
    })
  }

  handleClickProductAction = async (item) => {
    const added = this.findSelected(item.ext_product_id || item.parent_id, item.copied_from, item.item_sku);
    if (!added) {
      this.props.onAddItem(item);
    } else {
      this.props.onDeleteItem(added.item_id);
    }
  };

  render() {
    const { suppliers, themes, style, isProductsPage } = this.props;
    const { search, showProductPopup } = this.state;
    const supplierOptions = suppliers.map(item => ({
      label: item.value,
      value: item.key
    }));
    return (
      <>
        <Row style={{ paddingTop: '1rem', gap: 8 }}>
          <Col totalCols={5}>
            <InputWithSearchIcon
              label='Product'
              placeholder="Search for product in a collection"
              onChange={this.onChangeProductKeyword}
              value={search.product_keyword}
              onKeyDown={this.handlePressEnter}
            />
          </Col>
          <Col totalCols={5}>
            <InputWithSearchIcon
              label='Collection Title'
              placeholder="Search for collection"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </Col>
          <Col totalCols={5}>
            <LabeledSelect
              label='Suppliers'
              placeholder='All Suppliers'
              isMulti
              isSearchable
              options={supplierOptions}
              onChange={this.onChangeSuppliers}
              value={search.supplier_ids}
            />
          </Col>
          <Col totalCols={5}>
            <Select
              label='Themes'
              value={search.themes}
              multi={true}
              removeSelected={false}
              searchable={true}
              clearable={true}
              options={themes || []}
              change={this.onChangeTheme}
              placeholder="Themes"
            />
          </Col>

          <Col totalCols={5} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className="button-group expanded" style={{ marginTop: 28 }}>
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </Col>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {
            !isEmpty(search.product_keyword) ? this.renderItems()
              : this.renderCollections()
          }
          {this.renderLoading()}
        </Row>
        {showProductPopup && <ProductChevronPopup
          products={this.state.items}
          productIndex={findIndex(this.state.items, this.state.selectedProduct)}
          onClose={this.onClose} handleLoadNextPage={this.handleLoadNextPage}
          onClickProductAction={() => this.handleClickProductAction(this.state.selectedProduct)}
          isSelected={(product) => {
            return !!this.findSelected(product.parent_id, product.copied_from, product.item_sku)
          }}
          isProductsPage={isProductsPage}
          isCollectionPage={false}
          isPS={true}
        />}
      </>
    );
  }
}

const InputWithSearchIcon = ({ value, onChange, label, placeholder, onKeyDown }) => {
  const height = '2.4375rem';
  const iconStyles = {
    height,
    lineHeight: height,
    borderRadius: 3,
    textAlign: 'center',
    position: 'absolute',
    fontSize: '1em',
    top: '29px',
    margin: '0px 5px 0px 8px',
  };
  return <div style={{ position: 'relative' }}>
    <div className="search-box" style={{
      display: 'inline-block',
      width: '100%'
    }}>
      <SearchIcon style={iconStyles} color='rgb(194, 219, 226)' />
      <Label>{label}</Label>
      <Input
        label={label}
        style={{ paddingLeft: height, fontWeight: 400 }}
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  </div>;
};

const mapStateToProps = (state, ownProps) => {
  const { order } = ownProps;
  return {
    full_order: getOrderByNumber(state, pick(order, ['form_number', 'order_type'])),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadOrder: (order_id) => {
    dispatch(createLoadOrder(order_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionProductSearchFilter);
