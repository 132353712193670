import React from 'react';
import { connect } from 'react-redux';

import { ItemColors, ItemSizes, ItemSkus } from './ItemVariants';

import { createCheckInventoryLevelPopup, createSelectBreakdownSkuPopup } from '../../actions/popup';
import { createUpdate } from '../../actions';
import * as actions from '../../actions/item';

import { getItem, getProduct, getOptionItems, getItemInventoryByItemId } from '../../selectors/items';

import ProductImage from './ProductImage';
import TextArea from '../TextArea';
import { getOrderById } from '../../selectors';
import { isAvalaraOrder } from '../../helpers/order';
import AvalaraCategorySelect from '../avalara/AvalaraCategorySelect';

const ItemName = ({ value, onChange, onUpdate, disabled }) => (
  <div>
    <label>Product Name</label>
    <input className="field1" type="text" value={value} onChange={onChange} disabled={disabled} onBlur={onUpdate} />
  </div>
);

const ItemDescription = ({ value, onChange, onUpdate, disabled }) => (
  <div className="row row-pb">
    <label>Description</label>
    <TextArea className="field1" value={value || ''} onChange={onChange} disabled={disabled} onBlur={onUpdate} minRows={3}></TextArea>
  </div>
);

const ProductPageButton = ({ product_id }) => (
  <a href={`/product.php?id=${product_id}`} target="_blank" className="medium-button secondary-button button under-img-btn">View Product Page</a>
);

const updateItem = createUpdate('item', {
  request: actions.SIMPLE_UPDATE_ITEM_REQUEST,
  success: actions.SIMPLE_UPDATE_ITEM_SUCCESS,
  failure: actions.SIMPLE_UPDATE_ITEM_FAILURE,
  failure_message: 'Update to update item'
});

class Details extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item_name: props.item_name,
      item_description: props.item_description,
      avalara_category_id: props.avalara_category_id,
    };
    [
      'handleChangeItemName',
      'handleUpdateItemName',
      'handleChangeItemDescription',
      'handleUpdateItemDescription',
      'handleCheckInventory',
      'handleUpdatePricingSku'
    ].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangeItemName(e) {
    this.setState({ item_name: e.target.value });
  }

  handleUpdateItemName(e) {
    if (this.props.item_name !== this.state.item_name) {
      this.props.dispatch(updateItem(this.props.item_id, 'item_name', this.props.item_name, this.state.item_name));
    }
  }

  handleChangeItemDescription(e) {
    this.setState({ item_description: e.target.value });
  }

  handleUpdateItemDescription(e) {
    if (this.props.item_description !== this.state.item_description) {
      this.props.dispatch(updateItem(this.props.item_id, 'item_description', this.props.item_description, this.state.item_description));
    }
  }

  handleCheckInventory(e) {
    e.preventDefault();
    const { dispatch, item_sku, promo_api_name, item_id } = this.props;
    dispatch(createCheckInventoryLevelPopup(item_sku, promo_api_name, item_id));
  }

  handleUpdatePricingSku(e) {
    e.preventDefault();
    const { dispatch, item_id, product_id, default_sku_id, isCostChanged } = this.props;
    dispatch(createSelectBreakdownSkuPopup(
      product_id,
      default_sku_id,
      product_sku_id => dispatch(updateItem(item_id, 'default_sku_id', default_sku_id, product_sku_id)),
      null,
      null,
      <span>
        Please select the SKU you wish to use for the displayed costs.
        {isCostChanged && <span><br/><i style={{ color: '#BF3F69' }} className="fi-info" />&nbsp;Updating the pricing SKU will override the base cost you have entered.</span>}
      </span>
    ));
  }

  render() {
    const {
      order, item_id, product_id, parent_type, division_name, item_sku, pricing_sku, promo_api_name, locked, isPS,
      hasInventory, inventory_items, dispatch
    } = this.props;
    const { item_name, item_description, avalara_category_id } = this.state;
    const disabled = locked;
    return (
      <React.Fragment>
        <div className="large-9 columns">
          <div className="row row-pb">
            <div className={isPS ? "large-3 columns" : "large-4 columns"} style={{ paddingLeft: '0px' }}>
              <ItemName
                value={item_name}
                onChange={this.handleChangeItemName}
                onUpdate={this.handleUpdateItemName}
                disabled={disabled}
              />
            </div>
            <div className={isPS ? "large-3 columns" : "large-4 columns"}>
              <label>Supplier</label>
              <p>{division_name}</p>
            </div>
            <div className={isPS ? "large-3 columns" : "large-4 columns"}>
              <label>SKU</label>
              <p>{item_sku}</p>
            </div>
            {isPS && <div className="large-3 columns">
              <label>Pricing SKU</label>
              <p>{pricing_sku}&nbsp;{!locked && <a onClick={this.handleUpdatePricingSku}>Update</a>}</p>
            </div>}
          </div>
          {(isAvalaraOrder(order) || this.props.isAvalaraOrder) && <div className="row row-pb">
            <div className="large-4 columns">
              <label>Avalara Item Code</label>
              <AvalaraCategorySelect
                value={avalara_category_id}
                onChange={(value) => {
                  this.setState({ avalara_category_id: value });
                  dispatch(updateItem(item_id, 'avalara_category_id', avalara_category_id, value));
                }}
              />
            </div>
          </div>}
          <ItemDescription
            value={item_description}
            onChange={this.handleChangeItemDescription}
            onUpdate={this.handleUpdateItemDescription}
            disabled={disabled}
          />
          {'OPTION' === parent_type && <div className="row">
            {isPS && <ItemSkus item_id={item_id} product_id={product_id} locked={locked} />}
            {!isPS && <ItemColors item_id={item_id} product_id={product_id} locked={locked} />}
            {!isPS && <ItemSizes item_id={item_id} product_id={product_id} locked={locked} />}
          </div>}
          {hasInventory && <div className="row">
            <div className="large-6 columns color-select-column">
              <div className="row row-pb">
                <label style={{ float: 'left' }}>Inventory</label>
              </div>
              <div className="select-btn-container">
                {inventory_items.map(ii => (ii.sku_description || `${ii.size_name}/${ii.color_name}`) + ` (${ii.available})`).join(', ')}
              </div>
            </div>
          </div>}
        </div>

        <div className="large-3 columns" style={{ textAlign: 'center' }}>
          <ProductImage item_id={item_id} />
          <div className="row columns">
            <ProductPageButton product_id={product_id} />
            {promo_api_name && <button className="medium-button secondary-button button under-img-btn" onClick={this.handleCheckInventory}>Check Inventory</button>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, ownProps);
  const order = getOrderById(state, { order_id: item.order_id });
  const product = getProduct(state, { product_id: item.parent_id });
  const options = getOptionItems(state, ownProps);
  const isCostChanged = item.isPS && options.reduce((t, o) => t || 1 == o.costs_changed, false);
  return {
    order,
    product_id: item.parent_id,
    parent_type: item.parent_type,
    item_name: item.item_name,
    item_description: item.item_description,
    item_sku: item.item_sku,
    default_sku_id: item.default_sku_id,
    pricing_sku: (product || { skus: [] }).skus.filter(s => s.product_sku_id === item.default_sku_id).map(s => s.sku)[0],
    promo_api_name: item.promo_api_name,
    division_name: item.division_name,
    isPS: item.isPS,
    avalara_category_id: item.avalara_category_id || order.default_avalara_category_id,
    hasInventory: (item || { hasInventory: false }).hasInventory,
    inventory_items: getItemInventoryByItemId(state, item),
    isCostChanged
  };
};

export default connect(mapStateToProps)(Details);

