import React, { useCallback, useEffect, useState } from 'react';
import { oauth } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import { document } from '../../global';
import { Row, Col, Button, Loading } from '@commonsku/styles';
import DownloadArtifact from '../DownloadArtifact';
import { createAddTemp, createDeleteTemp } from '../../actions/temp';
import { useDispatch } from 'react-redux';

export function ExportViewerPortal({ children }) {
    const root = document.getElementById('root');
    return root ? ReactDOM.createPortal(
        children,
        root
    ) : null;
}

export function ExportViewer({ exportTaskIds }) {
    const [open, setOpen] = useState(true);
    const containerClass = 'sticky-chat' + (!open ? ' minimize' : '');

    useEffect(() => {
        if (exportTaskIds.length > 0) {
            setOpen(true);
        }
    }, [exportTaskIds]);

    return <>
        <div className={containerClass}>
            <div className='chat-block'>
                <div className='chat-messages' style={{ height: 'calc(70vh - 30px)' }}>
                    <Row>
                        <Col md={12} style={{ textAlign: 'right' }}>
                            <Button onClick={() => { setOpen(false); }} size='small'>Close</Button>
                        </Col>
                    </Row>
                    <Row style={{ margin: '1em 0em' }}>
                        <Col md={12}>
                            {exportTaskIds.map(i => <ExportTaskRow key={i} id={i} />)}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>;
        <div className='sticky-export-block'>
            <div className='button' style={{ marginBottom: '0' }} onClick={() => {
                setOpen((open) => setOpen(!open));
            }}>
                {open ? 'Hide Exports' : 'Show Exports'}
            </div>
        </div>
        <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
        />
    </>;
}

export function ExportTaskRow({ id }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const getResult = useCallback(async () => {
        if (data.result) return; // Have the artifact ready
        const result = await oauth('GET', `export-report-task/${id}`, {});
        if (result.json.export_report_task.result) {
            setData(result.json.export_report_task);
            setLoading(false);
            toast.success("Export file is ready.");
            dispatch(createDeleteTemp('export_task_' + id));
        }
    }, [data.result, dispatch, id]);

    useEffect(() => {
        dispatch(createAddTemp('export_task_' + id, 1));
        getResult();
        const intervalId = setInterval(getResult, 10 * 1000);
        return () => {
            clearInterval(intervalId);
            dispatch(createDeleteTemp('export_task_' + id));
        };
    }, [dispatch, getResult, id]);

    return <div style={{ borderBottom: '1px solid #efefef', paddingBottom: '0.7em' }}>
        {loading ?
            <Row>
                <Col md={8} style={{ display: "table" }}>
                    <span style={{ display: "table-cell", verticalAlign: "bottom" }}>Generating file {data.date_created || ''}, and it could take a while. Please stay put and don't leave this page...</span>
                </Col>
                <Col md={4}>
                    <Loading size='small' />
                </Col>
            </Row> :
            <Row>
                <Col md={8} style={{ display: "table" }}>
                    <span style={{ display: "table-cell", verticalAlign: "bottom" }}>Export File {data.date_created || ''} is ready.</span>
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}><DownloadArtifact
                    artifact_id={id}
                    filename={`exports-${id}.csv`}
                    style={{ marginTop: '1rem', float: 'right' }}
                >
                    Download
                </DownloadArtifact>
                </Col>
            </Row>
        }
    </div>;
}