import { find, first, isEmpty, toLower, map, get, toString, sortBy } from 'lodash';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { injectStyle } from "react-toastify/dist/inject-style";
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
  Theme, Button, Loading, Row, H3, Popup, Col, Table, TBody, TR, H5, TD, colors, Select,
} from '@commonsku/styles';
import Product from '../Product';
import { useProducts, useAllSuppliers, usePromodataStatus, usePromodataBetaUser } from '../promo-data/hooks';
import { getPriceQtyCols } from '../promo-data/utils';
import { formatMoney } from '../../utils';
import { document } from '../../global';

const StyledTBody = styled.tbody`
  &&& {
    &::after, &::before {
      content: '';
      display: block;
      height: 15px;
    }
    
    tr:first-child {
      td {
        padding-top: 1.5rem;
      }
      
      td:first-child {
        border-top-left-radius: 1rem;
      }   
      
      td:last-child {
        border-top-right-radius: 1rem;
      }
    }
    
    tr {
      background: ${colors.teal['20']};
    
      td:first-child {
        padding-left: 1.5rem;
        width: 480px;
      }
    }
    
    tr:last-child {
      td {
        padding-bottom: 1.5rem;
      }
    
      td:first-child {
        border-bottom-left-radius: 1rem;
      }   
      
      td:last-child {
        border-bottom-right-radius: 1rem;
      }
    }
    
    &.selected {
      tr:first-child {
        td {
          border-top: 2px solid ${colors.primary1.main};
        }
      }
      
      tr {
        background: #FFF;
      
        td:first-child {
          border-left: 2px solid ${colors.primary1.main};
        }
        
        td:last-child {
          border-right: 2px solid ${colors.primary1.main};
        }
      }
      
      tr:last-child {
        td {
          border-bottom: 2px solid ${colors.primary1.main};
        }
      }
    }
  }
`;

const StyledTable = styled(Table)`
  &&& {
    border-collapse: separate;
    padding: 10px;
    
    .Select {
      margin: 0;
    }
  }
`;

// const BreakdownDecorationToggle = ({ type: initType, onChange }) => {
//   const [type, setType] = useState(initType);
//   useEffect(() => {
//     setType(initType);
//   }, [initType]);
//
//   return <Row style={{ flexDirection: 'row', alignItems: 'center' }}>
//     {map(['breakdown', 'decoration'], (label) => {
//       return <LabeledCheckbox
//         labelStyle={{ marginBottom: 0 }}
//         label={upperFirst(label)} checked={type === label}
//         onChange={() => {
//           const newType = type === label ? '' : label;
//           setType(newType);
//           onChange(newType);
//         }}
//       />;
//     })}
//   </Row>;
// };
//
// const ChargeTypeToggle = ({ onChange }) => {
//   const options = {RUN: 'Run Charge', SETUP: 'Fixed Charge'};
//   const [type, setType] = useState('RUN');
//
//   const handleClick = (type) => {
//     return () => {
//       setType(type);
//       onChange(type);
//     };
//   };
//
//   return <Toggle style={{ maxHeight: 32, width: 'auto', marginRight: '1rem' }} size="small">
//     {map(options, (label, key) => {
//       return <ToggleLink key={key} onClick={handleClick(key)} selected={key === type}>{label}</ToggleLink>;
//     })}
//   </Toggle>;
// };

const PriceGroup = ({ price_group, qty_cols, ...props }) => {
  const { base_price = {}, additions = [] }= price_group;

  return <>
    <StyledTBody {...props}>
      <TR>
        <TD><H5>Base Price - {base_price.type}:</H5></TD>
        <TD/>
        {map(qty_cols, (qty, j) => {
          return <TD key={j}/>;
        })}
      </TR>
      <TR>
        <TD>{base_price.description}</TD>
        <TD>{base_price.setup}</TD>
        {map(qty_cols, (qty, j) => {
          const price_break = find(base_price.price_breaks, {qty});
          return <TD key={j}>{price_break ? formatMoney(price_break.price) : ''}</TD>;
        })}
      </TR>
    </StyledTBody>
    {/*map(additions, (addition, j) => {
      const option = get(options, addition.key);
      const { type, charge_type } = option || {};
      return <StyledTBody className={option ? 'selected' : ''}>
        <TR>
          <TD colSpan={qty_cols.length + 1}>
            <Row style={{ alignItems: 'center', height: 40 }}>
              <BreakdownDecorationToggle type={type} onChange={(newType) => {
                setOptions(
                  newType
                  ? {
                    ...options,
                    [addition.key]: {
                      ...option,
                      type: newType,
                      charge_type: 'RUN',
                    },
                  }
                  : pickBy(options, (v, k) => {
                    return k != addition.key;
                  })
                );
              }}/>
              {!type ? null : <>
                <ChargeTypeToggle onChange={(newChargeType) => {
                  setOptions({
                    ...options,
                    [addition.key]: {
                      ...option,
                      charge_type: newChargeType,
                      qty: newChargeType === 'SETUP' ? option.qty : null,
                    },
                  });
                }}/>
                {charge_type !== 'SETUP' ? null : <InputStepper
                  initialValue={1} min={1} labelStyle={{ margin: 0 }}
                  onChange={(qty) => {
                    setOptions({
                      ...options,
                      [addition.key]: {
                        ...option,
                        qty,
                      },
                    });
                  }}/>
                }
              </>}
            </Row>
          </TD>
        </TR>
        <TR key={j}>
          <TD><H5>Additions - {addition.type}: </H5></TD>
          {map(qty_cols, (qty, j) => {
            return <TD key={j}/>;
          })}
        </TR>
        <TR key={`${j}-description`}>
          <TD>{addition.description}</TD>
          {map(qty_cols, (qty, k) => {
            const price_break = find(addition.price_breaks, {qty});
            return <TD key={k}>{price_break ? price_break.price : ''}</TD>;
          })}
        </TR>
      </StyledTBody>;
    })*/}
  </>;
};

const AddProductPopup = ({ product, onClose, onAddProduct, addProductArgs }) => {
  const price_groups = get(product, 'prices.price_groups');
  const qty_cols = getPriceQtyCols(price_groups);
  // const [options, setOptions] = useState({});
  // const selected = pickBy(options);
  const [priceGroupKey, setPriceGroupKey] = useState(get(price_groups, '0.base_price.key') || '');

  // const priceGroupOption = map(price_groups, ({ base_price }) => {
  //   const { key, type, description } = base_price;
  //   return { label: `${type} - ${description}`, value: key };
  // });
  return <Popup
    height="80%"
    onClose={onClose}
    header={<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Col style={{alignSelf: 'center', flex: '0 1 auto' }}><H3>Add Product</H3></Col>
      <Col style={{alignSelf: 'center', flex: '0 1 auto', textAlign: 'right'}}>
        <Button style={{ marginRight: 20 }} variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant={isEmpty(priceGroupKey) ? 'disabled' : 'primary'} onClick={() => {
          const [product_id,  product_supplier_code, division_id, currency_id] = addProductArgs;
          onAddProduct(product_id,  product_supplier_code, division_id, currency_id, {
            price_groups: {
              base_price: priceGroupKey,
              // additions: options
            }
          });
          onClose();
        }}>Add</Button>
      </Col>
    </Row>}
  >
    <StyledTable>
      <TBody>
        <TR>
          <TD style={{width: 480, }}/>
          <TD style={{ fontWeight: 'bold' }}>setup</TD>
          {map(qty_cols, (qty, j) => {
            return <TD key={j} style={{ fontWeight: 'bold' }}>{qty}</TD>;
          })}
        </TR>
      </TBody>
      {map(price_groups, (price_group, i) => {
        const key = get(price_group, 'base_price.key');
        return <PriceGroup
          className={priceGroupKey === key ? 'selected' : ''}
          key={i} price_group={price_group} qty_cols={qty_cols}
          onClick={() => {
            setPriceGroupKey(key);
          }}
        />;
      })}
    </StyledTable>
  </Popup>;
};

const ProductCell = ({ product: p, onAddProduct, ...props }) => {
  const [showPopupArgs, setShowPopupArgs] = useState(null);
  const handleClosePopup = useCallback(() => {
    setShowPopupArgs(null);
  }, []);
  const { id, overview = {}, product = {}, supplier = {}, meta = {} } = p || {};
  const currencies = meta.price_currencies || [];

  return <Theme>
    <Product
      {...props}
      product={{
        source: 'promodata-products',
        product_id: id,
        product_name: product.name,
        product_supplier_code: product.code,
        division_name: supplier.supplier,
        ext_product_id: id,
        currencies,
        currency_id: first(currencies) || 'AUD',
        product_img: overview.hero_image || first(product.images),
      }}
      onAddProduct={(...args) => {
        setShowPopupArgs(args);
      }}
    />
    {(showPopupArgs !== null) && <AddProductPopup
      product={product} onClose={handleClosePopup}
      onAddProduct={onAddProduct}
      addProductArgs={showPopupArgs}
    />}
  </Theme>;
};

const PromoDataSearchFilter = ({
  loaded, loading, isProductsPage, supplier_popup, onAddProduct, order_id, order_type, onSearch, onClear,
}) => {
  injectStyle();
  const searchBarRef = useRef(null);
  const promodataStatus = usePromodataStatus();
  const isBetaUser = usePromodataBetaUser();
  const { suppliers } = useAllSuppliers();
  const [state, setState] = useState({});
  const [search, setSearch] = useState({stopFetch: true});
  const { products, size, setSize, isLoading: isLoadingProducts, isValidating, mutate } = useProducts(search);
  const warningTransitionText = "Beta is ending on April 30th. You need to get an API key from Promodata or you will no longer have access to this tab. Please contact hello@promodata.com.au for assistance.";
  const handleSearch = useCallback(() => {
    mutate();
    setSearch(state);
    onSearch && onSearch();
  }, [state, mutate, onSearch]);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleSearch]);

  const supplierOptions = [
    {label: '----All----', value: ''},
    ...sortBy(map(suppliers, (supplier) => {
      return {label: supplier.name, value: toString(supplier.id)};
    }), 'label'),
  ];

  return <>
    <div ref={searchBarRef} className="row full-width mega-modal-content" style={{ flex: '0 1 auto' }}>
      <div className="medium-4 columns">
        <input
          type="text"
          placeholder="Search term"
          onChange={(e) => {
            setState({...state, search: toLower(e.target.value)});
          }}
          value={state.search || ''}
        />
      </div>
      <div className="medium-4 columns">
        <Select
          placeholder="Supplier"
          searchable={true}
          options={supplierOptions}
          value={find(supplierOptions, { value: state.supplier_id || '' })}
          onChange={({ value }) => {
            setState({...state, supplier_id: value});
          }}
        />
      </div>
      <div className="medium-4 columns">
        <div className="button-group expanded">
          <a className="button" onClick={() => {
            if (promodataStatus === 'ACTIVE' || isBetaUser) {
              handleSearch();
            } else {
              toast.info(
                ({
                  NOT_CONFIGURED: 'You do not have PromoData token added. ',
                  EXPIRED: 'The saved token has expired. ',
                }[promodataStatus] || '') + 'Search Integrations in admin settings to add Promodata token.'
              );
            }
          }}>Search</a>
          <a className="button" onClick={() => {
            setState({});
            setSearch({stopFetch: true});
            onClear && onClear();
          }}>Reset</a>
        </div>
      </div>
    </div>
    {isBetaUser && promodataStatus !== 'ACTIVE' && <div>
      <div className="column">
        <div className="column tip">
          {warningTransitionText}
        </div>
      </div>
    </div>}
    <div
      className="row full-width mega-modal-content"
      style={{ flex: '1 1 auto', overflowY: 'auto' }}
      onScroll={(event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        if (!isValidating && (scrollHeight - scrollTop - clientHeight < 300 * 2)) {
          setSize(size + 1);
        }
      }}
    >
      {
        isLoadingProducts ? <Loading/> : map(products, (product, i) => {
          return <ProductCell
            key={i}
            product={product}
            loading={loading.includes(product.id)}
            loaded={loaded.includes(product.id)}
            isProductsPage={isProductsPage}
            supplier_popup={supplier_popup}
            onAddProduct={onAddProduct(order_id, order_type, 'promodata-products', null, null, false)}
          />;
        })
      }
    </div>
  </>;
};

export default PromoDataSearchFilter;
