import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/item';
import { createAddItemWarning, createDeleteItemWarning, createUpdate, createFetchProductWarnings } from '../../actions';
import { getItem, getItemWarnings } from '../../selectors/items';

import { toTimestamp, formatShortDate, formatShortTime } from '../../utils';

const updateItemWarning = createUpdate('item-warning', {
  request: actions.UPDATE_ITEM_WARNING_REQUEST,
  success: actions.UPDATE_ITEM_WARNING_SUCCESS,
  failure: actions.UPDATE_ITEM_WARNING_FAILURE,
  failure_message: 'Unable to update item warning'
});

const ProductWarning = ({ warning }) => (
  <div className="row warning-message">
    <img src="/images/warning.svg" />
    <p dangerouslySetInnerHTML={{ __html: warning.description}} />
  </div>
);

class BaseItemWarning extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      message: props.warning.warning
    };

    ['handleChangeWarning', 'handleUpdateWarning', 'handleDeleteWarning'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangeWarning(e) {
    this.setState({ message: e.target.value });
  }

  handleUpdateWarning(e) {
    this.setState({
      editing: false
    }, () => {
      if (this.state.message !== this.props.warning.warning) {
        this.props.dispatch(updateItemWarning(this.props.warning.item_warning_id, 'warning', this.props.warning.warning, this.state.message));
      }
    });
  }

  handleDeleteWarning(e) {
    e.preventDefault();
    this.props.dispatch(createDeleteItemWarning(this.props.warning.item_warning_id));
  }

  render() {
    const { warning, locked } = this.props;
    const { editing } = this.state;
    const onClick = !locked && (() => this.setState({ editing: true }));
    return (
      <div className="row warning-message">
        <img src="/images/warning.svg" />
        {editing && <a style={{ padding: '0.5rem' }} onClick={this.handleDeleteWarning}>&times;</a>}
        {editing ?
          <input type="text" value={warning.warning} onChange={this.handleChangeWarning} onBlur={this.handleUpdateWarning} /> :
          <p onClick={onClick} dangerouslySetInnerHTML={{ __html: warning.warning}} />
        }
      </div>
    );
  }
}

const ItemWarning = connect()(BaseItemWarning);

class Warnings extends React.Component {

  constructor(props) {
    super(props);

    ['handleAddItemWarning', 'handleFetchProductWarnings'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleAddItemWarning(e) {
    e.preventDefault();
    this.props.dispatch(createAddItemWarning(this.props.item_id));
  }

  handleFetchProductWarnings(e) {
    e.preventDefault();
    this.props.dispatch(createFetchProductWarnings(this.props.item_id, this.props.product_id));
  }

  render() {
    const { product_warnings, item_warnings, last_updated, locked } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="large-9 columns warning-box-hdr">
            <h2>Warnings</h2>
            <p className="last-updated">Last Updated {formatShortDate(toTimestamp(last_updated))}<br/>{formatShortTime(toTimestamp(last_updated))}</p>
          </div>
          <div className="large-3 columns">
            <button className="medium-button secondary-button button under-img-btn" onClick={this.handleFetchProductWarnings}>Check Prop65 Warnings</button>
          </div>
        </div>
        {product_warnings.map(w => <ProductWarning key={w.product_warning_id} warning={w} />)}
        {item_warnings.map(w => <ItemWarning key={w.item_warning_id} warning={w} locked={locked} />)}
        {!locked && <a style={{ marginLeft: '1rem' }} onClick={this.handleAddItemWarning}>+ add warning</a>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, { item_id: ownProps.item_id });
  return {
    product_id: item.parent_id,
    item_warnings: getItemWarnings(state, { item_id: ownProps.item_id }),
    product_warnings: item.product_warnings || [],
    last_updated: item.date_warnings_updated
  };
};

export default connect(mapStateToProps)(Warnings);
