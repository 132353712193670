import { get, filter } from 'lodash';

import {
  UPLOAD_PO_PROOF_SUCCESS,
  DELETE_PO_PROOF_SUCCESS,
  UPDATE_PO_PROOF_SUCCESS,
} from '../actions/purchase_order_proof';

const reduce = (state = {}, action) => {
  const { purchase_order_proof } = get(action, 'payload') || {};

  switch (action.type) {
    // case UPLOAD_PO_PROOF_REQUEST:
    // case UPLOAD_PO_PROOF_FAILURE:
    // TODO: should add optimistic updates here
    case UPLOAD_PO_PROOF_SUCCESS:
      return {
        [purchase_order_proof.purchase_order_proof_id]: purchase_order_proof,
        ...state,
      };
    case UPDATE_PO_PROOF_SUCCESS:
      const proof = {
        ...state[purchase_order_proof.purchase_order_proof_id],
        ...purchase_order_proof,
      };
      return {
        ...state,
        [proof.purchase_order_proof_id]: proof,
      };
    case DELETE_PO_PROOF_SUCCESS:
      return filter(state, (proof) => {
        return proof.purchase_order_proof_id !== purchase_order_proof.purchase_order_proof_id;
      });
  }
  return state;
};

export default reduce;
