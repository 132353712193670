import React, { useState } from "react";
import { Row, Col, ConfirmAlertPopup, Text } from "@commonsku/styles";
import { LabeledSelect, } from "../../helpers";
import { DEFAULT_COLOR, getShopHeaderTitle, PRODUCT_LIST_OPTION, PRODUCT_PAGE_OPTION } from "../helpers";
import CustomizationPanel from "../helpers/CustomizationPanel";
import LabelledColorPicker from "../../LabelledColorPicker";
import { PlaceholderLine, ResetToDefault, StoreName } from "./theme-customizations";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function GlobalStylesPanel(props) {
  const {
    togglePanel,
    onOpenImagesPopup,
    onUpdateField,
    isOpen = false,
    isProductListStyleEditable = true,
    isMobileView = false,
  } = props;
  const { title, labelTitle, onUpdateEntity } = usePublicViewEntityContext();
  const {
    templateColor,
    template_data,
    productListStyle,
    productPageStyle,
  } = usePublicViewTemplateDataContext();

  const [showConfirmPopup, setConfirmPopup] = useState(false);
  const { headerTitle, headerTitleType } = getShopHeaderTitle(template_data, title);
  const shopLogo = headerTitleType === 'image' ? headerTitle : '';
  const shopTitle = headerTitleType !== 'image' ? headerTitle : '';
  const onReset = () => {
    Promise.all([
      Promise.resolve(onUpdateField('header-title', title))
        .then(() => onUpdateField('header-title-type', 'text')),
      onUpdateEntity('template_color', templateColor)(DEFAULT_COLOR),
      onUpdateEntity('product_list_style', productListStyle)('TILE'),
      onUpdateField('product_page_style', 'CAROUSEL'),
    ]).then(() => {
      setConfirmPopup(false);
    });
  };

  const PlaceholderLineCol = (
    <Col xs>
      <PlaceholderLine />
    </Col>
  );

  const StoreNameCol = (
    <Col xs style={{ marginBottom: 14, }}>
      <StoreName
        shopTitle={shopTitle}
        shopLogo={shopLogo}
        onChangeName={value => {
          Promise.resolve(onUpdateField('header-title', value))
            .then(() => onUpdateField('header-title-type', 'text'));
        }}
        onClickImage={onOpenImagesPopup}
      />
    </Col>
  );

  const ShopColorCol = (
    <Col xs className='labeled_color_picker_container'>
      <LabelledColorPicker
        showColorPlaceholder
        placeholderStyle={{ textAlign: 'left', paddingLeft: 10 }}
        className="labeled-color-picker-select"
        label={labelTitle + " Color"}
        value={templateColor}
        onChange={onUpdateEntity('template_color', templateColor)}
        width={'100%'}
      />
    </Col>
  );

  const ProductListStyleCol = isProductListStyleEditable ? (
    <Col xs className='product_list_style_container'>
      <LabeledSelect
        name="product_list_style"
        className="product_list_style_select"
        label="Product List Style"
        value={productListStyle}
        options={PRODUCT_LIST_OPTION}
        onChange={e => onUpdateEntity('product_list_style', productListStyle)(e ? e.value : '')}
      />
    </Col>
  ) : null;

  return (
    <CustomizationPanel
      title={
        <Text as="p" style={{
          color: 'var(--color-neutrals-90)',
          fontFamily: 'var(--font-family-bold)',
          fontSize: 20,
          marginBottom: 0,
        }}>
          Global Styles
        </Text>
      }
      isOpen={isOpen}
      togglePanel={togglePanel}
    >
      {showConfirmPopup && <ConfirmAlertPopup
        title="Are you sure?"
        actionButtonContent="Reset Styles"
        onAction={onReset}
        onClose={() => setConfirmPopup(false)}
        maxWidth={500}
        width="100%"
      >
        <p style={{ display: 'block', fontFamily: 'var(--font-family-regular)', margin: 0, }}>
          You are about to reset Global Styles for this shop.
        </p>
        <p style={{ display: 'block', fontFamily: 'var(--font-family-bold)', margin: 0 }}>
          You cannot undo this action!
        </p>
      </ConfirmAlertPopup>}
      <Row>
        {StoreNameCol}
        {ShopColorCol}
        {ProductListStyleCol}
        <Col xs className='product_page_style_container' style={{ paddingTop: 8 }}>
          <LabeledSelect
            name="product_page_style"
            className="product_page_style_select"
            label="Product Page Style"
            value={isMobileView ? 'CAROUSEL' : (productPageStyle || 'CAROUSEL')}
            isDisabled={isMobileView}
            options={PRODUCT_PAGE_OPTION}
            onChange={e => onUpdateField('product_page_style', e.value)}
          />
        </Col>
        <Col xs style={{ paddingTop: 14, textAlign: 'center' }}>
          {productPageStyle === 'DROPDOWN'
            ? <ProductPageDropdownPreview />
            : productPageStyle === 'POPUP'
            ? <ProductPagePopupPreview />
            : <ProductPageCarouselPreview />}
        </Col>
        {PlaceholderLineCol}
        <Col xs style={{ textAlign: 'center' }}>
          <ResetToDefault onClick={() => setConfirmPopup(true)} testId='shop_global'>
            Reset Global Styles
          </ResetToDefault>
        </Col>
      </Row>
    </CustomizationPanel>
  );
}

const ProductPageCarouselPreview = () => (
  <svg width="200" height="100" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2303_57721)">
      <rect width="200" height="100" fill="#EDF4F7"/>
      <g clipPath="url(#clip1_2303_57721)">
        <path d="M65.867 78.4993H33.5963C33.0315 78.4993 32.5878 78.0556 32.5878 77.4909V48.2455H25.2462C24.8025 48.2455 24.3991 47.943 24.2781 47.5194L20.0426 32.9573C19.9014 32.5136 20.1233 32.0093 20.5267 31.7875L38.1545 22.1467C38.4571 21.9853 38.8403 21.9853 39.1428 22.1467C39.4453 22.3282 39.647 22.6509 39.647 23.0139C39.647 27.9554 45.1129 31.3841 49.9939 31.3841C54.8748 31.3841 59.8162 28.0159 59.8162 23.0139C59.8162 22.6509 59.9977 22.3282 60.3003 22.1467C60.6028 21.9651 60.986 21.9449 61.2886 22.1265L79.461 31.7673C79.8846 31.9891 80.0863 32.4732 79.9653 32.9371L75.7298 47.4992C75.6088 47.9228 75.2054 48.2254 74.7617 48.2254H66.8755V77.4708C66.8755 78.0355 66.4318 78.4792 65.867 78.4792V78.4993ZM34.6047 76.4824H64.8586V47.237C64.8586 46.6723 65.3023 46.2286 65.867 46.2286H74.0154L77.8073 33.1792L61.6921 24.6476C60.7441 29.9723 55.2177 33.4414 49.9939 33.4414C44.77 33.4414 38.7998 29.9118 37.7712 24.6678L22.1804 33.1792L25.9723 46.2488H33.5761C34.1408 46.2488 34.5845 46.6925 34.5845 47.2572V76.5026L34.6047 76.4824Z" fill="#B8C4CB"/>
        <path d="M60.8245 24.0426H38.6383C38.0736 24.0426 37.6299 23.5988 37.6299 23.0341C37.6299 22.4694 38.0736 22.0256 38.6383 22.0256H60.8245C61.3892 22.0256 61.8329 22.4694 61.8329 23.0341C61.8329 23.5988 61.3892 24.0426 60.8245 24.0426Z" fill="#B8C4CB"/>
      </g>
      <rect x="108" y="8" width="84" height="4" rx="2" fill="#B8C4CB"/>
      <rect x="108.5" y="20.5" width="19" height="7" rx="1.5" stroke="#B8C4CB"/>
      <rect x="132.5" y="20.5" width="19" height="7" rx="1.5" stroke="#B8C4CB"/>
      <rect x="156.5" y="20.5" width="19" height="7" rx="1.5" stroke="#B8C4CB"/>
      <rect x="108" y="36" width="84" height="8" rx="2" fill="#B8C4CB"/>
      <rect x="108" y="52" width="68" height="2" rx="1" fill="#B8C4CB"/>
      <rect x="108" y="58" width="68" height="2" rx="1" fill="#B8C4CB"/>
      <rect x="108" y="64" width="68" height="2" rx="1" fill="#B8C4CB"/>
      <rect x="108" y="74" width="53" height="2" rx="1" fill="#B8C4CB"/>
      <rect x="108" y="80" width="53" height="2" rx="1" fill="#B8C4CB"/>
    </g>
    <defs>
      <clipPath id="clip0_2303_57721">
        <rect width="200" height="100" fill="white"/>
      </clipPath>
      <clipPath id="clip1_2303_57721">
        <rect width="100" height="100" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const ProductPageDropdownPreview = () => (
  <svg width="184" height="100" viewBox="0 0 184 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2303_58605)">
      <rect width="184" height="100" fill="#EDF4F7"/>
      <rect width="184" height="38.67" transform="translate(0 11.3301)" fill="#EDF4F7"/>
      <rect x="8" y="19.3301" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="52" y="19.3301" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="96" y="19.3301" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="140" y="19.3301" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect width="184" height="38.67" transform="translate(0 50)" fill="#EDF4F7"/>
      <rect x="8" y="58" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="52" y="58" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="96" y="58" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
      <rect x="140" y="58" width="36" height="22.67" rx="2" fill="#B8C4CB"/>
    </g>
    <defs>
      <clipPath id="clip0_2303_58605">
        <rect width="184" height="100" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const ProductPagePopupPreview = () => (
  <svg width="200" height="100" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2303_59772)">
      <rect width="200" height="100" fill="#EDF4F7"/>
      <rect x="8" y="8" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="56" y="8" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="104" y="8" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="152" y="8" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="8" y="38.6667" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="56" y="38.6667" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="104" y="38.6667" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="152" y="38.6667" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="8" y="69.3333" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="56" y="69.3333" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="104" y="69.3333" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
      <rect x="152" y="69.3333" width="40" height="22.6667" rx="2" fill="#B8C4CB"/>
    </g>
    <defs>
      <clipPath id="clip0_2303_59772">
        <rect width="200" height="100" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

