import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { createUpdateItemDecoration } from '../actions';
import PSDecorationSelect from './PSDecorationSelect';
import TextInput from './TextInput';
import { getUoms, getUomName, getUomValues } from '../promostandard_utils';

class ItemDecoration extends Component {

  constructor(props) {
    super(props);
    const uoms = getUomValues(props.item_decoration);
    this.state = {
      uoms
    };
  }

  componentDidUpdate(prevProps) {
    const oldUoms = getUomValues(prevProps.item_decoration);
    const newUoms = getUomValues(this.props.item_decoration);
    const uoms = Object.keys(newUoms).filter(
      k => oldUoms[k] != newUoms[k]
    );

    if (uoms.length > 0) {
      this.setState(state => ({
        uoms: uoms.reduce(
          (o, k) => ({
            ...o,
            [k]: newUoms[k]
          }),
          state.uoms
        )
      }));
    }
  }

  render() {
    const {
      decorations,
      item_decoration,
      uoms,
      maxUnits,
    } = this.props;

    const validYUoM = ['Colors', 'Inches', 'Stitches', 'SquareInches'];

    return (
      <tr>
        <td>&nbsp;</td>
        <td colSpan={2} style={{ paddingTop: '2rem', paddingBottom: 0 }}>
          <PSDecorationSelect item_decoration={item_decoration} decorations={decorations}/>
          {decorations.filter(({ps_decoration_id, decoration_units_included_uom}) => {
            return `${ps_decoration_id}` == item_decoration.ext_decoration_id && validYUoM.includes(decoration_units_included_uom);
          }).map(
             d => {
               const maxValue = Math.max(maxUnits, d.decoration_units_max, uoms[d.decoration_units_included_uom] && uoms[d.decoration_units_included_uom].length > 0 ? uoms[d.decoration_units_included_uom][uoms[d.decoration_units_included_uom].length - 1] : maxUnits);
               const hasYUom = (uoms[d.decoration_units_included_uom] || []).length > 0;
               const maxYUom = `Max ${maxValue} ${getUomName(d.decoration_units_max, d.decoration_units_included_uom)}`;
               const uomText = hasYUom ? `${d.decoration_units_included} ${getUomName(d.decoration_units_included, d.decoration_units_included_uom)} Included (${maxYUom})` : maxYUom;
               return (
                 <span key={d.ps_decoration_id} style={{ fontSize: 'small' }}>
                   {uomText}
                 </span>
               );
             }
           )}
        </td>
        <td colSpan={7} style={{ paddingTop: 0 }}>
          {Object.keys(uoms).filter(u => uoms[u].length > 0 && uoms[u][uoms[u].length - 1] > 0).map(u =>
            <div key={`decoration_uom-${u}`} style={{ textAlign: 'left' }}>
                <span style={{ fontWeight: 'bold' }}>{`# ${u}`}</span>
                <TextInput
                  type="number"
                  style={{ maxWidth: '5rem' }}
                  min={0}
                  max={Math.max(maxUnits, uoms[u] && uoms[u].length > 0 ? uoms[u][uoms[u].length - 1] : maxUnits)}
                  value={this.state.uoms[u] || ''}
                  onChange={this.handleChangeItemDecorationUom.bind(this, u)}
                  onBlur={this.handleUpdateItemDecorationUom.bind(this, u)}
                  selectOnFocus={true}
                />
            </div>
          )}
        </td>
      </tr>
    );
  }

  handleChangeItemDecorationUom(uom, e) {
    const value = e.target.value;
    this.setState(state => ({
      uoms: ({
        ...state.uoms,
        [uom]: value
      })
    }));
  }

  handleUpdateItemDecorationUom(uom, e) {
    const { product, item_decoration, uoms, maxUnits, onUpdateItemDecoration } = this.props;
    const value = e.target.value;
    const maxValue = Math.max(maxUnits, !!uoms[uom] && uoms[uom].length > 0 ? uoms[uom][uoms[uom].length - 1] : maxUnits);
    if (value < 0 || (value > maxValue && !(product.division_id === 'cada690e-8a53-44ba-80bb-3cff2463f458' && maxUnits === null))) {
      this.setState(state => ({
        uoms: ({
          ...state.uoms,
          [uom]: item_decoration[`decoration_uom.${uom}`]
        })
      }));
    } else {
      onUpdateItemDecoration(item_decoration.item_decoration_id, 'decoration_uom', uoms)({[uom]: e.target.value});
    }
  }
}

const mapStateToProps = (state, ownProps) => {
    const { decorations, item_decoration } = ownProps;
    const decoration = find(decorations, ({ ps_decoration_id }) => {
      return `${ps_decoration_id}` == item_decoration.ext_decoration_id;
    });
    const uoms = getUoms(decoration);
    return {
      uoms,
      decoration,
      maxUnits: decoration && decoration.decoration_units_max
    };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateItemDecoration: (item_decoration_id, field, previous_value) =>
    value =>
      dispatch(createUpdateItemDecoration(item_decoration_id, field, previous_value, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemDecoration);
