import { groupBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import MenuItem from './MenuItem';

const groups = {
  'bold': 'group1',
  'italic': 'group1',
  'underline': 'group1',
  'paragraph': 'group2',
  'h1': 'group2',
  'h2': 'group2',
  'bullet list': 'group2',
  'ordered list': 'group2',
  'format_align_left': 'group3',
  'format_align_center': 'group3',
  'format_align_right': 'group3',
  'format_align_justify': 'group3',
  'link': 'group4',
  'link_off': 'group4',
  'code': 'group5',
  'code_block': 'group5',
};

const MenuBar = ({
  editor,
  items=[
    'bold',
    'italic',
    'underline',
    'paragraph',
    'h1',
    'h2',
    'format_align_left',
    'format_align_center',
    'format_align_right',
    'format_align_justify',
    'bullet list',
    'ordered list',
    'link',
    'link_off',
  ],
}) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);
    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run();
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run();
  }, [editor]);

  const itemsByGroup = useMemo(
    () => {
      const result = items.map(it => ({ groupId: groups[it] || 'group', item: it }));
      return groupBy(result, 'groupId');
    },
    [items]
  );

  if (!editor) {
    return null;
  }

  return (
    <div className='editor-menu-bar'>
      {Object.keys(itemsByGroup).map((groupId) => (
        <div key={groupId} className={'items-container'}>
          {itemsByGroup[groupId].map(it => (
            <MenuItem key={`${groupId}-${it.item}`}
              setLink={setLink}
              editor={editor}
              groupId={groupId}
              item_type={it.item}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MenuBar;
