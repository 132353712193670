import { CREATE_DISCOUNT_SUCCESS, UPDATE_DISCOUNT_SUCCESS, DELETE_DISCOUNT_SUCCESS } from '../actions/discount';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_DISCOUNT_SUCCESS:
      return Object.assign({}, state, {[action.payload.discount.discount_id]: action.payload.discount});
    case UPDATE_DISCOUNT_SUCCESS:
      return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload.data } };
    case DELETE_DISCOUNT_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.discount_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
  }
  return state;
};

export default reducer;
