import React, { useCallback } from 'react';
import { DownloadIcon, LinkWithIcon } from '@commonsku/styles';

import { download } from '../utils';

export default function DownloadArtifact({ artifact_id, filename, children, ...props }) {
  const onClick = useCallback(async e => {
    e.preventDefault();
    const href = `/artifact/${artifact_id}`;
    download(href, filename);
  }, [artifact_id, filename]);
  return (
    <LinkWithIcon {...props} Icon={DownloadIcon} onClick={onClick}>{children}</LinkWithIcon>
  );
}

