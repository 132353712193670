import React from "react";
import { Csku, Text, ToggleSwitchStyled } from "@commonsku/styles";

export default function HelpScreen({ value, onChange }) {
  return (
    <Csku
      as="div"
      forceStyles
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text
        as="p"
        style={{
          color: "var(--color-neutrals-90)",
          fontFamily: "var(--font-family-regular)",
          fontSize: 16,
          marginBottom: 0,
          width: 144,
        }}
      >
        Pop up help screen on first load
      </Text>
      <ToggleSwitchStyled
        selected={+value === 1}
        onClick={() => onChange(+value !== 1 ? 1 : 0)}
        style={{ width: 60 }}
        data-testid="show_help_screen_toggle"
      />
    </Csku>
  );
}
