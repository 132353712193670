import { oauth, memoizeOAuthGet } from '../utils';

export const GET_THEME_SUCCESS = 'GET_THEME_SUCCESS';

export const ADD_THEME_REQUEST = 'ADD_THEME_REQUEST';
export const ADD_THEME_SUCCESS = 'ADD_THEME_SUCCESS';
export const ADD_THEME_FAILURE = 'ADD_THEME_FAILURE';

export const UPDATE_THEME_REQUEST = 'UPDATE_THEME_REQUEST';
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS';
export const UPDATE_THEME_FAILURE = 'UPDATE_THEME_FAILURE';

export const DELETE_THEME_REQUEST = 'DELETE_THEME_REQUEST';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_FAILURE = 'DELETE_THEME_FAILURE';

export const LOAD_THEMES_REQUEST = 'LOAD_THEMES_REQUEST';
export const LOAD_THEMES_SUCCESS = 'LOAD_THEMES_SUCCESS';
export const LOAD_THEMES_FAILURE = 'LOAD_THEMES_FAILURE';

const createLoadThemesRequest = () => ({
  type: LOAD_THEMES_REQUEST,
  payload: {}
});

const createFinalizeLoadThemes = themes => ({
  type: LOAD_THEMES_SUCCESS,
  payload: {
    themes
  }
});

const createFailedLoadThemes = () => ({
  type: LOAD_THEMES_FAILURE,
  payload: {}
});

export const createLoadThemes = () => dispatch => {
  dispatch(createLoadThemesRequest());

  return memoizeOAuthGet('theme', {}).then(
    ({ json }) => { dispatch(createFinalizeLoadThemes(json.themes)) },
    ({ json }) => dispatch(createFailedLoadThemes())
  );
};

const createAddThemeRequest = theme => ({
  type: ADD_THEME_REQUEST,
  payload: {
    data: theme
  }
});

const createFinalizeAddTheme = (theme) => ({
  type: ADD_THEME_SUCCESS,
  payload: {
    id: theme.theme_id,
    data: theme
  }
});

const createFailedAddTheme = theme => ({
  type: ADD_THEME_FAILURE,
  payload: {
    data: theme
  }
});

const createUpdateThemeRequest = theme => ({
  type: UPDATE_THEME_REQUEST,
  payload: {
    id: theme.theme_id,
    data: theme
  }
});

export const createFinalizeUpdateTheme = (theme, imprint, theme_costs) => ({
  type: UPDATE_THEME_SUCCESS,
  payload: {
    id: theme.theme_id,
    data: theme,
  }
});

const createFailedUpdateTheme = theme_id => ({
  type: UPDATE_THEME_FAILURE,
  payload: {
    id: theme_id
  }
});

export const createAddTheme = theme => dispatch => {
  dispatch(createAddThemeRequest(theme));

  return oauth('POST', 'theme', theme).then(
    ({ json }) => dispatch(createFinalizeAddTheme(json.theme)),
    ({ json }) => dispatch(createFailedAddTheme(theme))
  );
};

export const createUpdateTheme = theme => dispatch => {
  dispatch(createUpdateThemeRequest(theme));

  return oauth('PUT', `theme/${theme.theme_id}`, theme).then(
    ({ json }) => dispatch(createFinalizeUpdateTheme(json.theme)),
    ({ json }) => dispatch(createFailedUpdateTheme(theme.theme_id))
  );
};

const createDeleteThemeRequest = theme_id => ({
  type: DELETE_THEME_REQUEST,
  payload: {
    theme_id
  }
});

const createFinalizeDeleteTheme = theme_id => ({
  type: DELETE_THEME_SUCCESS,
  payload: {
    id: theme_id
  }
});

const createFailedDeleteTheme = theme_id => ({
  type: DELETE_THEME_FAILURE,
  payload: {
    theme_id
  }
});

export const createDeleteTheme = theme_id => dispatch => {
  dispatch(createDeleteThemeRequest(theme_id));

  oauth('DELETE', `theme/${theme_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteTheme(theme_id)),
    ({ json }) => dispatch(createFailedDeleteTheme(theme_id))
  );
};