import _ from 'lodash';
import {
  DELETE_BOOKMARK_SUCCESS, FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_REMINDERS_SUCCESS, UPDATE_REMINDER_SUCCESS,
  FETCH_RECENT_ORDERS_SUCCESS,
} from '../actions/header';

_.mixin({
  toObject: (list, key) => {
    return _.fromPairs(_.map(list, (item) => [item[key], item]));
  }
});

export const bookmarksReducer = (state = {}, action) => {
  const payload = action.payload || [];
  switch (action.type) {
    case DELETE_BOOKMARK_SUCCESS:
      return _.omit(state, payload);
  }
  return state;
};

export const notificationsReducer = (state = {}, action) => {
  const payload = action.payload || {};
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return _.toObject(payload.notifications, 'notification_id');
  }
  return state;
};

export const notificationCountReducer = (state = 0, action) => {
  const payload = action.payload || {};
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      state = payload.active;
  }
  return _.parseInt(state) || 0;
};

export const recentOrdersReducer = (state = {}, action) => {
  const payload = action.payload || [];
  switch (action.type) {
    case FETCH_RECENT_ORDERS_SUCCESS:
      return _.toObject(payload, 'order_id');
  }
  return state;
};

export const remindersReducer = (state = {}, action = {}) => {
  const payload = action.payload || {};
  switch (action.type) {
    case FETCH_REMINDERS_SUCCESS:
      if (action.payload.id) {
        return state;
      }

      return _.toObject(payload, 'note_id');
    case UPDATE_REMINDER_SUCCESS:
      if (action.payload.id) {
        return state;
      }

      return {
        ...state,
        [payload.note_id]: payload,
      };
  }
  return state;
};
