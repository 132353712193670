import { getImageSrc } from '../utils';

export const Entity = {
  PROJECT: 'projects',
  ACCOUNT: 'accounts',
  ADDRESS: 'addresses',
  COMMISSION: 'commissions',
  CONTACT: 'contacts',
  CURRENCY: 'currencies',
  EVENT_TYPE: 'event_types',
  FEEDBACK: 'feedback',
  FILE: 'files',
  ORDER: 'orders',
  STATUS: 'statuses',
  TAG: 'tags',
  TAX: 'taxes',
  TERMS: 'terms',
  USER: 'users'
};

export const getEntityBy = (state, entity_type, value, key) => {
  const entities = state.entities[entity_type];
  if (entities === undefined) {
    return undefined;
  }
  if (key === undefined) {
    return entities[value];
  }
  return Object.keys(entities).map(k => entities[k]).filter(v => v[key] === value).pop();
};

export const getProject = (state, project_id) => (
  getEntityBy(state, Entity.PROJECT, project_id)
);

export const getProjectByNumber = (state, job_number) => (
  getEntityBy(state, Entity.PROJECT, job_number, 'job_number')
);

export const getAccount = (state, account_id) => (
  getEntityBy(state, Entity.ACCOUNT, account_id)
);

export const getAddress = (state, address_id) => (
  getEntityBy(state, Entity.ADDRESS, address_id)
);

export const getContact = (state, contact_id) => (
  getEntityBy(state, Entity.CONTACT, contact_id)
);

export const getOrder = (state, order_id) => (
  getEntityBy(state, Entity.ORDER, order_id)
);

export const getOrderByNumber = (state, form_number) => (
  getEntityBy(state, Entity.ORDER, form_number, 'form_number')
);

export const getFileURL = (state, file_id, size) => {
  const file = getEntityBy(state, Entity.FILE, file_id);
  return getImageSrc(file, size);
};
