import React from 'react';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Col, IconButton, Row } from '@commonsku/styles';
import styled from 'styled-components';

const CurrencyIconButton = styled.button`
  && {
  width: 38px;
  height: 38px;
  border-radius: 20px;
  padding: 5px;
  line-height: 1.5rem;
  cursor: pointer;
  background: #E1F7FA;
  mix-blend-mode: normal;

  &.selected {
    border: 3px solid #00A0B6;
    background: #FFFFFF;

  }
}`;

class CommonskuProductSearchFilter extends ProductSearchFilter {

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.new_product) {
      this.setState({ total_products: 1, products: [nextProps.new_product] });
    }

    if (nextProps.user_search.search_term && nextProps.user_search.search_term !== this.state.search.search_term && nextProps.divisions.length > 0 && this.props.divisions.length == 0) {
      this.setState({ search: Object.assign({}, this.state.search, { search_term: nextProps.user_search.search_term }) }, function () {
        this.onSearch(0, true, null, null, null, null);
      });
    }
  }

  loadDivisions() {
    this.props.loadDivisions('supplier-direct');
  }

  getEndpoint() {
    return 'product';
  }

  getSearchTerms(page) {
    const divisions = this.props.divisions;
    const division_id = this.state.division_id || this.state.search.division_id || (this.props.company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    let search_terms = {
      keyword_sku: this.state.search.search_term,
      owner_id: this.props.company_id,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (division_id) {
      search_terms.division_id = division_id;
    }
    if (this.state.search.net_min) {
      search_terms.net_min = this.state.search.net_min;
    }
    if (this.state.search.net_max) {
      search_terms.net_max = this.state.search.net_max;
    }
    return search_terms;
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: '',
        net_min: '',
        net_max: ''
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.commonsku_division_id || '',
        net_min: this.props.user_search.net_min || '',
        net_max: this.props.user_search.net_max || ''
      };
    }
  }

  onChangeCurrency(currency) {
    this.setState({ search: { ...this.state.search, currency } });
  }

  render() {
    const { company_type, divisions, style } = this.props;
    const { search } = this.state;
    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);

    const onChangeDivision = division_id => this.onChangeDivision(division_id, 'commonsku_division_id');

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <Col xs={4} padded>
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyPress={this.handlePressEnter}
            />
          </Col>
          <Col xs={2} lg={1} padded>
            {divisions ?
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={'SUPPLIER' !== company_type}
                options={divisions}
                change={onChangeDivision}
                placeholder="Supplier"
              /> : <span>&nbsp;</span>}
          </Col>
          <Col xs={2} lg={1} padded>
            <input
              type="text"
              placeholder="Min"
              onChange={this.handleChangeNetMin}
              value={search.net_min}
              onKeyPress={this.handlePressEnter}
            />
          </Col>
          <Col xs={2} lg={1} padded>
            <input
              type="text"
              placeholder="Max"
              onChange={this.handleChangeNetMax}
              value={search.net_max}
              onKeyPress={this.handlePressEnter}
            />
          </Col>
          <Col xs={1} padded flex style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', minWidth: 100 }}>
            <CurrencyIconButton className={this.state.search.currency == 'USD' && 'selected'} style={{ marginRight: 5 }} onClick={() => this.onChangeCurrency('USD')}>
              <div style={{ opacity: this.state.search.currency == 'USD' ? 1 : 0.4 }} >🇺🇸</div>
            </CurrencyIconButton>
            <CurrencyIconButton className={this.state.search.currency == 'CAD' && 'selected'} onClick={() => this.onChangeCurrency('CAD')}>
              <div style={{ opacity: this.state.search.currency == 'CAD' ? 1 : 0.4 }}>🇨🇦</div>
            </CurrencyIconButton>
          </Col>
          <Col xs={1} padded flex style={{ flexDirection: 'row', minWidth: 210 }}>
            <IconButton onClick={this.handleClickReset} variant="secondary" mr={18} >Clear</IconButton>
            <IconButton onClick={this.handleClickSearch} >Search</IconButton>
          </Col>
        </Row>
        {/* <div className="medium-3 columns">
          <input
            type="text"
            placeholder="Search term"
            onChange={this.handleChangeSearchTerm}
            value={search.search_term}
            onKeyDown={this.handlePressEnter}
          />
        </div>
        <div className="medium-2 columns">
          {divisions ?
            <Select
              id="division"
              value={division_id}
              searchable={true}
              clearable={'SUPPLIER' !== company_type}
              options={divisions}
              change={onChangeDivision}
              placeholder="Supplier"
            /> : <span>&nbsp;</span>}
        </div>
        <div className="medium-4 columns">
          <div className="row">
            <div className="medium-5 columns">
              <input
                type="text"
                placeholder="Net min"
                onChange={this.handleChangeNetMin}
                value={search.net_min}
                onKeyDown={this.handlePressEnter}
              />
            </div>
            <div className="medium-2 columns">
              <span>&mdash;</span>
            </div>
            <div className="medium-5 columns">
              <input
                type="text"
                placeholder="Net max"
                onChange={this.handleChangeNetMax}
                value={search.net_max}
                onKeyDown={this.handlePressEnter}
              />
            </div>
          </div>
        </div>

        <div className="medium-3 columns">
          <div className="button-group expanded">
            <a className="button" onClick={this.handleClickSearch}>Search</a>
            <a className="button" onClick={this.handleClickReset}>Reset</a>
          </div>
        </div> */}
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('company')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default CommonskuProductSearchFilter;
