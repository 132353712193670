import React from 'react';
import {Row, Col, Button} from '@commonsku/styles';

export function Pagination({
    data,
    start_index=0,
    max_results=20,
    onUpdate,
    ...props
}) {
    return (
        <Row>
            <Col padded>
            <Button size='small'
                disabled={start_index <= 0}
                onClick={(e) => {
                    if (start_index <= 0) { return; }
                    onUpdate({ 'start-index': start_index - max_results });
                }}>Prev</Button>
            </Col>
            <Col padded style={{textAlign: 'right'}} >
            <Button size='small'
                disabled={!data || !data.length || data.length < max_results}
                onClick={(e) => {
                    if (!data || !data.length || data.length < max_results) { return; }
                    onUpdate({ 'start-index': max_results + start_index });
                }}>Next</Button>
            </Col>
        </Row>
    );
}
