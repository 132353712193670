import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseCskuMenu from './BaseCskuMenu';
import CskuMenuV2 from './CskuMenuV2';
import { window } from '../global';
import { getIdentityUtils } from '../utils';

class CskuMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { identity, scriptName, fromPage, className = '', ...props } = this.props;
    const commonsProps = {
      identity: identity,
      scriptName: scriptName,
      className: className,
      ...props,
    };
    const { hasCapabilities } = getIdentityUtils(identity);
    const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');
    return (
      v2 ? <CskuMenuV2 {...commonsProps} />
         : <BaseCskuMenu {...commonsProps} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    // eslint-disable-next-line no-undef
    scriptName: state.fromPage
      || (typeof window.fromPage !== 'undefined' ? window.fromPage : '')
      || state.scriptName
      || (typeof window.scriptName !== 'undefined' ? window.scriptName : ''),
    collections_published_today: state.display.collections_published_today
  };
};

export default connect(mapStateToProps)(CskuMenu);
