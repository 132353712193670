import { setWith, clone, isEqual } from 'lodash';
import { useState, useCallback } from 'react';

// Immutable Set for setting state
function setIn(state, name, value) {
    return setWith(clone(state), name, value, clone);
}

function useForm(initialState, options={onSubmit: null, isFormValid: null}) {
    const [form, setForm] = useState(initialState);

    // Handle Chnage on input with given params
    const _handleChange = useCallback(name => event => {
        event.persist();
        setForm(_form => setIn(
                {..._form},
                name,
                event.target.type === 'checkbox' ? event.target.checked : event.target.value
            )
        );
    }, []);

    const handleChange = useCallback(event => {
        event.persist();
        setForm(_form => setIn(
                {..._form},
                event.target.name,
                event.target.type === 'checkbox' ? event.target.checked : event.target.value
            )
        );
    }, []);

    const handleSelectChange = useCallback((name, isMulti=false) => value => {
        setForm(
            _form => setIn(
                {..._form},
                name,
                isMulti ? (value?.map(item => item.value) ?? []) : (value?.value ?? '')
            )
        );
    }, []);

    const resetForm = useCallback(() => {
        if(!isEqual(initialState, form)) {
            setForm(initialState);
        }
    }, [form, initialState]);

    const setInForm = useCallback((name, value) => {
        setForm(_form => setIn(_form, name, value));
    }, []);

    const handleSubmit = useCallback(e => {
        if (e) { e.preventDefault(); }

        if (options && options.onSubmit && options.isFormValid !== false) {
            options.onSubmit();
        }
    }, [options]);

    return {
        form,
        _handleChange,
        handleChange,
        handleSelectChange,
        handleSubmit,
        resetForm,
        setForm,
        setInForm,
    };
}

export default useForm;
