import { oauth } from '../utils';

export const ADD_BILL_REQUEST = 'ADD_BILL_REQUEST';
export const ADD_BILL_SUCCESS = 'ADD_BILL_SUCCESS';
export const ADD_BILL_FAILURE = 'ADD_BILL_FAILURE';

export const UPDATE_BILL_REQUEST = 'UPDATE_BILL_REQUEST';
export const UPDATE_BILL_SUCCESS = 'UPDATE_BILL_SUCCESS';
export const UPDATE_BILL_FAILURE = 'UPDATE_BILL_FAILURE';

const createAddBillRequest = bill => ({
  type: ADD_BILL_REQUEST,
  payload: {
    bill
  }
});

const createFinalizeAddBill = (order_id, bill, purchase_orders, orders) => ({
  type: ADD_BILL_SUCCESS,
  payload: {
    order_id,
    bill,
    purchase_orders,
    orders
  }
});

const createFailedAddBill = (order_id, bill, error) => ({
  type: ADD_BILL_FAILURE,
  payload: {
    order_id,
    bill,
    error
  }
});

export const createAddBill = (order_id, bill) => dispatch => {
  dispatch(createAddBillRequest(bill));

  const bill_items = bill.purchase_orders.reduce((bi, po) => {
    return bi.concat(po.items.filter(i => i.selected).reduce((bi, i) => {
      return bi.concat(i.breakdowns.map(b => ({
        purchase_order_item_id: b.purchase_order_item_id,
        quantity: b.quantity,
        unit_cost: b.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      }))).concat(i.costs.map(c => ({
        purchase_order_item_id: c.purchase_order_item_id,
        quantity: c.quantity,
        unit_cost: c.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      })));
    }, [])).concat(po.extra_items.filter(i => i.selected && !i.extra).reduce((bi, i) => ({
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      item_code: i.item_code,
      item_name: i.item_name,
      bill_item_type_id: i.bill_item_type_id,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    }), []));
  }, []).concat(bill.orders.reduce((bi, o) => {
    return bi.concat(o.items.filter(i => i.selected).reduce((bi, i) => {
      return bi.concat(i.breakdowns.map(b => ({
        purchase_order_item_id: b.purchase_order_item_id,
        quantity: b.quantity,
        unit_cost: b.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      }))).concat(i.costs.map(c => ({
        purchase_order_item_id: c.purchase_order_item_id,
        quantity: c.quantity,
        unit_cost: c.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      })));
    }, [])).concat(o.extra_items.filter(i => i.selected && !i.extra).reduce((bi, i) => ({
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      item_code: i.item_code,
      item_name: i.item_name,
      bill_item_type_id: i.bill_item_type_id,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    }), []));
  }, []));

  const data = {
    supplier_id: bill.division_id,
    bill_reference_number: bill.bill_reference_number,
    bill_date_due: bill.bill_date_due,
    bill_date_billdate: bill.bill_date_billdate,
    bill_memo: bill.bill_memo,
    bill_items,
    extra_bill_items: bill.items.filter(i => i.selected && i.extra).map(i => ({
      purchase_order_id: i.purchase_order_id,
      order_id: i.order_id,
      order_form_number: i.order_form_number,
      item_code: i.item_code,
      item_name: i.item_name,
      bill_item_type_id: i.bill_item_type_id,
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    }))
  };
  return oauth('POST', 'bill', data).then(
    ({ json }) => dispatch(createFinalizeAddBill(order_id, json.bill, json.bill_purchase_orders, json.orders)),
    ({ json }) => Promise.reject(dispatch(createFailedAddBill(order_id, bill, json.error)))
  );
};

const createUpdateBillRequest = bill => ({
  type: UPDATE_BILL_REQUEST,
  payload: {
    bill
  }
});

const createFinalizeUpdateBill = (bill, purchase_orders, orders) => ({
  type: UPDATE_BILL_SUCCESS,
  payload: {
    bill,
    purchase_orders,
    orders
  }
});

const createFailedUpdateBill = (bill, error) => ({
  type: UPDATE_BILL_FAILURE,
  payload: {
    bill,
    error
  }
});

export const createUpdateBill = bill => dispatch => {
  dispatch(createUpdateBillRequest(bill));

  const bill_items = bill.purchase_orders.reduce((bi, po) => {
    return bi.concat(po.items.filter(i => i.selected).reduce((bi, i) => {
      return bi.concat(i.breakdowns.map(b => ({
        bill_item_id: b.bill_item_id,
        purchase_order_item_id: b.purchase_order_item_id,
        quantity: b.quantity,
        unit_cost: b.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      }))).concat(i.costs.map(c => ({
        bill_item_id: c.bill_item_id,
        purchase_order_item_id: c.purchase_order_item_id,
        quantity: c.quantity,
        unit_cost: c.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      })));
    }, [])).concat(po.extra_items.filter(i => i.selected && !i.extra).reduce((bi, i) => bi.concat({
      bill_item_id: i.bill_item_id,
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      supplier_code: i.item_code,
      description: i.item_name,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    }), []));
  }, []).concat(bill.orders.reduce((bi, o) => {
    return bi.concat(o.items.filter(i => i.selected).reduce((bi, i) => {
      return bi.concat(i.breakdowns.map(b => ({
        bill_item_id: b.bill_item_id,
        purchase_order_item_id: b.purchase_order_item_id,
        quantity: b.quantity,
        unit_cost: b.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      }))).concat(i.costs.map(c => ({
        bill_item_id: c.bill_item_id,
        purchase_order_item_id: c.purchase_order_item_id,
        quantity: c.quantity,
        unit_cost: c.unit_cost,
        tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
      })));
    }, [])).concat(o.extra_items.filter(i => i.selected && !i.extra).reduce((bi, i) => bi.concat({
      bill_item_id: i.bill_item_id,
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      supplier_code: i.item_code,
      description: i.item_name,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    }), []));
  }, []));

  const remove_bill_items = bill.purchase_orders.reduce((bi, po) =>
    bi.concat(
      po.items.filter(i => !i.selected).reduce((bis, i) =>
        bis.concat(
          i.breakdowns.map(b => b.bill_item_id)
        ).concat(
          i.costs.map(c => c.bill_item_id)
        ), [])
    ).concat(
      po.extra_items.filter(i => !i.selected && !/ID\d+/.test(i.bill_item_id)).map(i => i.bill_item_id)
    ), []
  ).concat(bill.orders.reduce((bi, o) =>
    bi.concat(
      o.items.filter(i => !i.selected).reduce((bis, i) =>
        bis.concat(
          i.breakdowns.map(b => b.bill_item_id)
        ).concat(
          i.costs.map(c => c.bill_item_id)
        ), [])
    ).concat(
      o.extra_items.filter(i => !i.selected && !/ID\d+/.test(i.bill_item_id)).map(i => i.bill_item_id)
    ), []
  ));

  const data = {
    supplier_id: bill.division_id,
    bill_reference_number: bill.bill_reference_number,
    bill_date_due: bill.bill_date_due,
    bill_date_billdate: bill.bill_date_billdate,
    bill_memo: bill.bill_memo,
    bill_items,
    extra_bill_items: bill.items.filter(i => i.selected && i.extra).map(i => ({
      purchase_order_id: i.purchase_order_id,
      order_id: i.order_id,
      item_code: i.item_code,
      item_name: i.item_name,
      bill_item_type_id: i.bill_item_type_id,
      quantity: i.quantity,
      unit_cost: i.unit_cost,
      tax_amounts: i.taxes.map(t => ({ tax_id: t.tax_id }))
    })),
    remove_bill_items
  };
  return oauth('PUT', `bill/${bill.bill_id}`, data).then(
    ({ json }) => dispatch(createFinalizeUpdateBill(Object.assign({}, bill, json.bill), json.bill_purchase_orders, json.orders)),
    ({ json }) => Promise.reject(dispatch(createFailedUpdateBill(bill, json.error)))
  );
};

export const createUpdateBillExchangeRate = (bill_id) => dispatch => {
  return oauth('PUT', `bill/${bill_id}`, { exchange_rate: true }).then(
    ({ json }) => dispatch(createFinalizeUpdateBill(json.bill, json.bill_purchase_orders, json.orders)),
    ({ json }) => Promise.reject(dispatch(createFailedUpdateBill(null, json.error)))
  );
};
