import React from 'react';
import { window } from '../global';

const TruncatedText = ({ html, limit }) => {
  const tempDiv = window.document.createElement('div');
  tempDiv.innerHTML = html;
  const innerText = tempDiv.innerText.substring(0, limit);
  return (
    <div style={{ wordWrap: 'break-word' }}>
      {innerText}
      {innerText.length === limit && '...'}
    </div>
  );
};

export default TruncatedText;
