import React, { Component } from 'react';
import { connect } from 'react-redux';
import { slideInDown, slideOutUp } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';

import { createDeleteTemp } from '../../actions/temp';
import { createCreateShopPopup, createCopyOrderPopup } from '../../actions/popup';

import { BASE_ZINDEX } from '../../popup-factory';

import { getAbsoluteUrl } from '../../utils';

const styles = StyleSheet.create({
  slideInDown: {
    animationName: slideInDown,
    animationDuration: '1s'
  },
  slideOutUp: {
    animationName: slideOutUp,
    animationDuration: '1s'
  }
});

class CreateShop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };

    this.handleHideCreateShop = this.handleHideCreateShop.bind(this);
  }

  handleHideCreateShop() {
    const _this = this;
    this.setState({ show: false }, function () {
      window.setTimeout(function () {
        _this.props.onHideCreateShop();
      }, 1000);
    });
  }

  render() {
    const { onCreateShopPopup, onCreateCopyOrderPopup, create_shop } = this.props;
    const style = {
      display: 'block',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: BASE_ZINDEX,
      backgroundColor: '#FFF',
      textAlign: 'center',
      backgroundColor: '#E6EFF2'
    };
    const redirect = create_shop === 'no_redirect' ? false : true; //exception case at collection, most of the time should be redirecting to the shop

    return (
      <div className={this.state.show ? css(styles.slideInDown) : css(styles.slideOutUp)} tabIndex="-1" aria-hidden="true" style={style}>
        <div className="create-shop">
          <div className="">
            <a href="" className="small radius button close-btn" id="fixedbutton"
              onClick={e => { e.preventDefault(); this.handleHideCreateShop(); }}>Close</a>
            <div className="shops-popup">
              <div className="shops-popup-inner">
                <div className="row">
                  <div className="row large-12 title-row">
                    <h1 style={{ fontSize: '2em' }}>Which Shop would you like to create?</h1>
                    <p>Not sure what shop is right for your client? Click <a a target="_blank" rel="noopener noreferrer" href={getAbsoluteUrl('shop_resources.php')}>here</a>!</p>
                  </div>
                  <div className="row shops-category-container">
                    <div className="column large-4 shops-category">
                      <a href="#"><img src="/images/marketing-shop1.png" alt="Marketing shop graphic" /></a>
                      <h3>Marketing Shop</h3><br />
                      <div className="create-shop-container">
                        <a className="medium radius button small-only-expanded create-shop-btn"
                          onClick={e => {
                            e.preventDefault(); create_shop === true || create_shop === 'no_redirect'
                              ? onCreateShopPopup('marketing', redirect)
                              : onCreateCopyOrderPopup(create_shop, 'marketing');
                          }}>Create Shop</a>
                      </div>
                      <p className="shop-description">Curate a set of products to market to many companies, clients can upload artwork at checkout.  Each order creates an individual Sales Order.
                                        </p>
                      <h5>Use Cases:</h5>
                      <ul>
                        <li>Seasonal ideas - golf tournaments, Earth day, holiday etc.</li>
                        <li>Embed on your website to offer a frequently changing curated set of ideas</li>
                      </ul>
                    </div>
                    <div className="column large-4 shops-category">
                      <a href=""><img src="/images/popup-shop2.png" alt="Popup shop graphic" /></a>
                      <h3>Pop Up Shop</h3><br />
                      <div className="create-shop-container">
                        <a className="medium radius button small-only-expanded create-shop-btn"
                          onClick={e => {
                            e.preventDefault(); create_shop === true || create_shop === 'no_redirect'
                              ? onCreateShopPopup('popup', redirect)
                              : onCreateCopyOrderPopup(create_shop, 'popup');
                          }}>Create Shop</a>
                      </div>
                      <p className="shop-description">Open a shop for a defined period of time and aggregate the orders into a single Sales Order.
                                        </p>
                      <h5>Use Cases:</h5>
                      <ul>
                        <li>Offer employees a holiday gift of jackets where they can choose their size</li>
                        <li>Uniform programs / Hospital scrubs</li>
                      </ul>
                    </div>
                    <div className="column large-4 shops-category">
                      <a href="#"><img src="/images/company-shop2.png" alt="Company shop graphic" /></a>
                      <h3>Company Shop</h3><br />
                      <div className="create-shop-container">
                        <a className="medium radius button medium-down-expanded create-shop-btn"
                          onClick={e => {
                            e.preventDefault(); create_shop === true || create_shop === 'no_redirect'
                              ? onCreateShopPopup('company', redirect)
                              : onCreateCopyOrderPopup(create_shop, 'company');
                          }}>Create Shop</a>
                      </div>
                      <p className="shop-description">Create for a single company, artwork pre-defined, each order creates an individual Sales Order for just in time production.</p>
                      <h5>Use Cases:</h5>
                      <ul>
                        <li>Marketing pre-approves a set of products for different departments/locations to order</li>
                        <li>Easy reorders for frequently ordered products</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p className="bottom-link">To learn more about our different types of Shops, <a href="https://help.commonsku.com/knowledge/shops-overview" target="_blank">check out this help article</a>.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    create_shop: state.temp.create_shop
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onHideCreateShop: () => dispatch(createDeleteTemp('create_shop')),
  onCreateShopPopup: (type, redirect) => dispatch(createCreateShopPopup(type, redirect)),
  onCreateCopyOrderPopup: (order, shop_type) => dispatch(createCopyOrderPopup(order, 'SHOP', shop_type))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateShop);