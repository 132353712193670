import React from 'react';
import { useStore } from 'react-redux';

const withReducers = (Component, reducers, forceReplace=false) => (props) => {
  const store = useStore();
  if (store.attachReducers && reducers) {
    store.attachReducers(reducers, forceReplace);
  }
  return <Component {...props}/>;
};

export default withReducers;