import { filter, get, find, map, startsWith, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoSuggest from 'react-autosuggest';

import { H4, Col, Row, Button, LabeledInput, Popup, Tabs, Select, Loading, ShowPopup } from '@commonsku/styles';
import { useIdentity } from '../hooks';
import {
  assumeLoginName,
  getBaseDomain,
  getIdentityUtils,
  memoizeDebouncedOAuthGet,
  memoizeOAuthGet,
  oauth,
  parseDistributorUrl
} from '../utils';
import { window } from '../global';
import config from '../config';

const StyledAutoSuggest = styled(({className, ...props}) => {
  return <div className={className}>
    <AutoSuggest {...props}/>
  </div>;
})`
  &&& {
    .suggestion-item {
      padding: 0.5rem;
    }

    .react-autosuggest__container {
      display: inline-block;
      width: 100%;
      height: 45px;
      overflow: visible;
    }

    .react-autosuggest__input {
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 2.4375rem;
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      border-radius: 3px;
      box-sizing: border-box;
    }

    .react-autosuggest__suggestions-container {
      position: relative;
      float: left;
      width: 100%;
    }

    .react-autosuggest__suggestion {
      margin: 0;
      list-style: none;
      cursor: pointer;
    }

    .react-autosuggest__suggestions-list {
      max-height: 350px;
      padding: 0;
      overflow-y: auto;
      background-color: #FFF;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    }

    .react-autosuggest__suggestion--focused {
      background-color: rgba(0, 0, 0, 0.14);
    }
  }
`;

const findCommonskuUser = (users) => {
  return find(users, ({ login_name }) => {
    return startsWith(login_name, 'commonsku@');
  });
};

const ByURL = () => {
  const [href, setHref] = useState('');
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [tenantId, setTenantId] = useState(null);
  const { url, tenant_domain_key } = parseDistributorUrl(href);
  const tenantOptions = map(tenants, ({ tenant_id, tenant_name }) => {
    return { label: tenant_name, value: tenant_id };
  });
  const assumeUser = findCommonskuUser(get(find(tenants, { tenant_id: tenantId }), 'users'));

  useEffect(() => {
    if (assumeUser && tenants.length === 1) {
      setLoading(true);
      assumeLoginName(assumeUser.login_name, url.href.replace(url.origin, ''));
    }
  }, [tenants, assumeUser]);

  return loading ? <Loading/> : <Col style={{ flexDirection: 'column', padding: 10 }}>
    <LabeledInput
      label="Switch to URL:" value={href}
      placeholder={`https://vandelaypromotions.${getBaseDomain()}/project/1234`}
      onChange={(e) => {
        setHref(e.target.value);
        setTenants([]);
        setTenantId(null);
      }}
    />
    {tenants.length > 1 && <div>
      <div>Found more than one distributors with domain: {tenant_domain_key}</div>
      <Select value={find(tenantOptions, { value: tenantId })} options={tenantOptions} onChange={(option) => {
        setTenantId(option.value);
      }}/>
    </div>}
    {tenants.length === 1 && !assumeUser ? <div>No commonsku user found</div> : null}
    <Row style={{ gap: 10 }}>
      {!url || (tenants.length > 0 && !assumeUser) ? null : <Button onClick={async () => {
        let confirmedTenant = find(tenants, { tenant_id: tenantId });
        if (!confirmedTenant) {
          assumeLoginName('', null);
          const response = await oauth(
            'GET', 'login',
            { actionName: 'assume_logins', tenant_domain_key },
            { logout: false }
          );
          const results = get(response, 'json.tenants') || [];
          setTenants(results);
          setTenantId(get(results, '0.tenant_id'));
        }
      }}>{tenants.length > 1 ? 'Confirm' : ''} Connect</Button>}
      {!isEmpty(tenantId) && <Button onClick={() => {
        assumeLoginName('', null);
        window.location.replace(`/distributor_information.php?id=${tenantId}`);
        setLoading(true);
      }}>
        Go to Company Page As Team
      </Button>}
    </Row>
  </Col>;
};

const searchCompanies = memoizeDebouncedOAuthGet('company/company-commonsku-user-toc', 700);

const ByName = ({ className }) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [company, setCompany] = useState(null);

  return <Col className={className} style={{ padding: 10 }}>
    <StyledAutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        const target = (value || '').trim().toLowerCase();
        if (target.length < 3) {
          return;
        }
        setLoading(true);
        searchCompanies({ search: target })
        .then((r) => {
          setSuggestions(get(r, 'json.companies'));
        })
        .finally(() => {
          setLoading(false);
        })
        ;
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      getSuggestionValue={suggestion => suggestion.company_name}
      onSuggestionSelected={(event, { suggestion }) => {
        setCompany(suggestion);
      }}
      renderSuggestion={(suggestion)=> {
        return <div className="suggestion-item">{suggestion.company_name}</div>;
      }}
      renderSuggestionsContainer={({ containerProps, children, query }) => {
        return <div {...containerProps}>
          {loading ? <Loading/> : children}
        </div>;
      }}
      inputProps={{
        value,
        autoFocus:true,
        placeholder: 'Company Name',
        onChange: (event, {newValue}) => {
          setValue(newValue);
        },
      }}
    />
    <Row style={{ gap: 10 }}>
      {!get(company, 'csku_login_name') ? null : <Button onClick={() => {
        assumeLoginName(company.csku_login_name);
      }}>Connect</Button>}
      {!company ? null :
        <>
          <Button onClick={() => {
            assumeLoginName('', null);
            window.location.replace(
              (company.company_type === 'TENANT' ? '/distributor_information.php?id=' : '/vendor.php?id=') +
              company.company_id
            );
          }}>
            Go to Company Page As Team
          </Button>
          {company.company_type === 'TENANT' &&
          <Button onClick={() => {
            assumeLoginName('', null);
            window.location.replace(
              '/manage_distributor.php?id=' + company.company_id
            );
          }}>
            Manage account
          </Button>
          }
        </>
      }
    </Row>
  </Col>;
};

const SwitchUser = () => {
  const identity = useIdentity();
  const { company_id, company_type } = identity || {};
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [user, setUser] = useState([]);

  useEffect(() => {
    setLoading(true);
    memoizeOAuthGet('user', {search_type: 'company-users', company_id, company_type})
    .then((response) => {
      setUsers(get(response, 'json.companyUsers', []));
    })
    .finally(() => {
      setLoading(false);
    })
    ;
  }, [identity]);

  return loading ? <Loading/> : <Col style={{ padding: 10 }}>
    <StyledAutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        const target = (value || '').trim().toLowerCase();
        setSuggestions(filter(users, ({ login_name }) => {
          return login_name && login_name.toLowerCase().indexOf(target) > -1;
        }));
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      getSuggestionValue={suggestion => suggestion.login_name}
      onSuggestionSelected={(event, { suggestion }) => {
        setUser(suggestion);
      }}
      renderSuggestion={({ login_name })=> {
        return <div className="suggestion-item">{login_name}</div>;
      }}
      shouldRenderSuggestions={() => true}
      inputProps={{
        value,
        placeholder: 'Login Name',
        onChange: (event, {newValue}) => {
          setValue(newValue);
        },
      }}
    />
    {!user ? null : <Button onClick={() => {
      assumeLoginName(user.login_name, null);
      window.location.reload();
    }}>Connect</Button>}
  </Col>;
};

const AssumeIdentityPopup = ({ assumed_by, onClose }) => {
  return <Popup onClose={onClose} style={{ width: '50%' }} header={
    <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
      <H4>Switch Identity</H4>
      <div style={{ display: 'flex', gap: 10 }}>
        {!assumed_by ? null : <Button onClick={() => {
          assumeLoginName('', '/home.php');
        }}>Disconnect</Button>}
        <Button onClick={onClose}>Close</Button>
      </div>
    </Row>
  }>
    <Tabs tabs={filter([
      {label: 'By Distributor URL', content: <ByURL/>},
      {label: 'By Name', content: <ByName/>},
      config.canAssumeAnyUser(assumed_by) ? {label: 'Switch User', content: <SwitchUser/>} : null,
    ])}/>
  </Popup>;
};

const AssumeIdentity = () => {
  const identity = useIdentity();
  const { hasCapabilities, isAdmin, } = getIdentityUtils(identity);
  const { assumed_by } = identity || {};

  return !(isAdmin() && hasCapabilities(['ASSUME-DISTRIBUTOR', 'ASSUME-SUPPLIER'], true)) && !assumed_by ? null
    : <div className="dropdown-menu"><div className="dropdown-menu-trigger">
      <ShowPopup popup={AssumeIdentityPopup} assumed_by={assumed_by} render={({ onClick }) => {
        return assumed_by
          ? <a
            className="button warning"
            style={{ margin: 0, padding: '0.85em 0.45em', fontSize: 12}}
            onClick={(e) => onClick(e)}
          >Disconnect</a>
          : <i className="fi-torsos-all" onClick={(e) => {
            onClick(e);
          }}/>
          ;
      }}/>
    </div></div>
    ;
};

export default AssumeIdentity;