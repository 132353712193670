import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Csku, Text } from '@commonsku/styles';

const DebouncedSlider = forwardRef(
  ({ labelText, initValue, onSlide, debounceInterval = 300, testIdPrefix='debounced'}, ref) => {
    const [value, setValue] = useState(initValue);
    const [trackBackground, setTrackBackground] = useState(
      `linear-gradient(to right, #00A0B6 0%, #00A0B6 ${value * 100}%, #B8C4CB ${
        value * 100
      }%, #B8C4CB 100%)`,
    );

    const debouncedSlide = useMemo(() => {
      return debounce(onSlide, debounceInterval);
    }, [debounceInterval, onSlide]);

    const setBackground = (newValue) => {
      setTrackBackground(
        `linear-gradient(to right, #00A0B6 0%, #00A0B6 ${newValue * 100}%, #B8C4CB ${
          newValue * 100
        }%, #B8C4CB 100%)`,
      );
    };

    const handleChange = (e) => {
      const newValue = parseFloat(e.target.value);
      setValue(newValue);
      debouncedSlide(newValue);
      setBackground(newValue);
    };

    useImperativeHandle(ref, () => ({
      onReset: () => {
        setValue(initValue);
        setBackground(initValue);
      },
      onUpdate: (newValue) => {
        setValue(newValue);
        setBackground(newValue);
      }
    }));

    return (
      <Csku>
        <Text
          as='p'
          style={{
            color: 'var(--neutrals-90-body-text-2-a-4-d-63, #2A4D63)',
            fontSize: 16,
          }}
        >
          {labelText}
        </Text>
        <input
          style={{ width: '100%', background: trackBackground }}
          type='range'
          min='0'
          max='1'
          step='0.01'
          value={value}
          onChange={handleChange}
          data-testid={testIdPrefix + '-slider'}
        />
      </Csku>
    );
  },
);

export default DebouncedSlider;