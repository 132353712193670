import { useEffect, useState } from 'react';
import { window } from '../global';

function useMediaQuery(query) {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    return matches;
}

export default useMediaQuery;
