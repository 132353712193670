import React from 'react';

const NoMatch = ({ location }) => {
    const { wrapper, row, speech, bot } = style;

    return (
        <div style={wrapper}>
            <div className="row" style={row}>
                <div className="columns small-1"><br /></div>
                <div className="columns medium-6" style={speech}><code>{location.pathname}</code> is not a valid path, please check your web address to make sure you have no typo<br /><br /><a href="/">Back to home</a></div>
                <div className="columns medium-4 end">
                    <img src="https://login.commonsku.com/images/repairbot.png" style={bot} />
                </div>
            </div>
        </div>
    );
};

const style = {
    wrapper: {
        background: '#E2EBF2',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    row: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    },
    speech: {
        background: 'white',
        maxWidth: '450px',
        textAlign: 'center',
        padding: '100px 50px',
        borderRadius: '10px',
        fontSize: '2em'
    },
    bot: {
        marginTop: '20px'
    }
};

export default NoMatch;
