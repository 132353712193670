import React from 'react';
import axios from 'axios';
import _ from 'lodash';

import Select from './Select';

import { window } from '../global';

class PSCategorySelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.promostandards_id != this.props.promostandards_id) {
      this.loadCategories();
    }
  }

  loadCategories() {
    const { promostandards_id } = this.props;
    axios.get(`${window.PS_API_URL}/companies/${promostandards_id}/categories`).then(({ data }) => {
      this.setState({
        categories: _.chain(data)
          .map(({ category }) => { return {value: category, key: category}; })
          .uniqBy('value')
          .value()
        ,
      });
    });
  }

  render() {
    const { change } = this.props;
    const { categories = [], value = '', } = this.state;

    return <Select
      value={value}
      searchable={true}
      clearable={true}
      options={categories}
      change={(value) => {
        this.setState({ value });
        change(value);
      }}
      placeholder="Category"
    />;
  }
}

export default PSCategorySelect;
