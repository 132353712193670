import { useCallback, useState } from 'react';

function useChatUsers(initUsers = []) {
    const [users, setUsers] = useState(initUsers);

    const addChatUser = useCallback((userId) => {
        setUsers((users) => {
            const newUsers = users.filter(u => u.userId !== userId);
            newUsers.push({
                userId,
                status: 'connected',
                lastUpdate: Math.floor(Date.now() / 1000),
            });
            return newUsers;
        });

    }, []);

    const updateChatUser = useCallback((userId) => {
        setUsers((users) => {
            const updatedUsers = users.map(u => {
                if (u.userId === userId) {
                    u.lastUpdate = Math.floor(Date.now() / 1000);
                }
                return u;
            });
            return updatedUsers.filter(u => u.lastUpdate > Math.floor(Date.now() / 1000) - 25);
        });
    }, []);

    const setChatUserCallStatus = useCallback((userId, status) => {
        setUsers((users) => users.map(u => {
            if (u.userId === userId) {
                u.status = status;
            }
            return u;
        }));
    }, []);

    return [
        users,
        addChatUser,
        updateChatUser,
        setChatUserCallStatus,
    ];
}

export default useChatUsers;