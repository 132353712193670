import { UPDATE_CONTACT_REQUEST, UPDATE_CONTACT_SUCCESS, UPDATE_CONTACT_FAILURE, UPDATE_CONTACT_PHONE_SUCCESS, ADD_CONTACT_PHONE_SUCCESS, DELETE_CONTACT_PHONE_SUCCESS } from '../actions/contact';

const reduce = (state = {}, action) => {
  let contact_id;
  let updated_contact;
  let phones_obj;
  let phone_id;
  switch (action.type) {
    case UPDATE_CONTACT_REQUEST:
      // fall through
    case UPDATE_CONTACT_SUCCESS:
      // fall through
    case UPDATE_CONTACT_FAILURE:
      contact_id = action.payload.id;
      updated_contact = Object.assign({}, state[contact_id], action.payload.data);
      return Object.assign({}, state, {[contact_id]: updated_contact});
    case UPDATE_CONTACT_PHONE_SUCCESS:
      contact_id = action.payload.id;
      phones_obj = action.payload.data.phones.reduce((o, phone) => {o[phone.phone_id] = phone; return o;}, {});

      return Object.assign({}, state, {[contact_id]: Object.assign({}, state[contact_id], { phones: phones_obj })});
	case ADD_CONTACT_PHONE_SUCCESS:
	   contact_id = action.payload.id;
	   phone_id = action.payload.phone_id;
	   phones_obj = action.payload.data.phones.reduce((o, phone) => {o[phone.phone_id] = phone; return o;}, {});
	   for(let i = 0; i < phone_id.length; i++) {
	   	if((Object.keys(phones_obj)).indexOf(phone_id[i]) > -1) {
	   		delete phones_obj[phone_id[i]];
	   	}
	   }

	   return Object.assign({}, state, {[contact_id]: Object.assign({}, state[contact_id], { phones: Object.assign({}, state[contact_id].phones, { [Object.keys(phones_obj)[0]]: phones_obj[Object.keys(phones_obj)[0]] } ) })});
    case DELETE_CONTACT_PHONE_SUCCESS:
      contact_id = action.payload.id;
      phone_id = action.payload.data.phone_id;
      let new_phone_obj = Object.assign({}, state[contact_id].phones);
      delete new_phone_obj[phone_id];

      return Object.assign({}, state, {[contact_id]: Object.assign({}, state[contact_id], { phones: new_phone_obj })});
  }
  return state;
};

export default reduce;
