import React from "react";
import { Text } from "@commonsku/styles";

export default function ResetToDefault(props) {
  const { children = "Reset to Default", onClick, testId = "shop" } = props;
  return (
    <Text
      onClick={onClick}
      as="p"
      style={{
        color: "var(--color-primary1-main)",
        fontFamily: "var(--font-family-bold)",
        fontSize: 14,
        marginBottom: 8,
        cursor: "pointer",
      }}
      data-testid={`reset_${testId}_styles_btn`}
    >
      {children}
    </Text>
  );
}
