import { map, filter, first, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import withReducers from '../../store/withReducers';
import { getIdentityUtils, setPageInterval, clearPageInterval, askNotificationPermission, } from '../../utils';
import communityEventsSlice, { fetchUpcomingEvents } from './communityEventsSlice';
import { useIdentity } from '../../hooks';
import { useUpcomingPastCommunityEvents } from './hooks';
import { Theme, Button, ShowPopup, Row, colors } from '@commonsku/styles';
import DetailPopup from './DetailPopup';
import HomeCommunityEvents from './HomeCommunityEvents';
import ErrorBoundary from '../ErrorBoundary';
import LiveUpdate from '../LiveUpdate';
import { FromNow } from '../moment';
import { document } from '../../global';
import config from '../../config';

let intervalID = null;
const EventsReminder = () => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const { isAdmin, hasUserFlags, hasCapabilities } = getIdentityUtils(identity);
  const [upcoming, past] = useUpcomingPastCommunityEvents();
  const [loading, setLoading] = useState(true);
  const homeCommunityEvents = document.getElementById('home-community-events');
  const nextEvent = first(upcoming);
  const { scheduled_time, start_time: inProgress } = nextEvent || {};
  const unmountedRef = useRef(false);

  useEffect(() => {
    dispatch(fetchUpcomingEvents()).then(() => {
      if (unmountedRef.current) return;
      setLoading(false);
    });
    askNotificationPermission();
  }, [dispatch]);

  useEffect(() => {
    const diff = scheduled_time ? moment(scheduled_time).diff(moment(), 'minute') : 60 * 24;
    let interval = 1000 * 60 * 2;
    if (diff >= 60 * 24) { // no events today
      interval = 1000 * 60 * 60;
    } else if (diff > 60) {
      interval = 1000 * 60 * 10;
    } else if (diff > 30) {
      interval = 1000 * 60 * 5;
    }
    clearPageInterval(intervalID);
    intervalID = setPageInterval(() => {
      dispatch(fetchUpcomingEvents());
    }, interval);

    return () => {
      clearPageInterval(intervalID);
      unmountedRef.current = true;
    };
  }, [dispatch, scheduled_time]);

  return <ErrorBoundary>
    <Theme>
      <Button bg={colors.special2} size="tiny" style={{ borderColor: colors.special2 }}>
        <a href="/community_events.php" target="_blank" style={{ color: colors.white }}>
          <Row justify="center" style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {inProgress ? 'Event In Progress' : (nextEvent ? 'Webinar' : 'Webinars')}
          </Row>
          {nextEvent && <Row justify="center" style={{ fontSize: '0.7rem' }}>
            {inProgress ? 'Started ' : ''}<FromNow value={nextEvent.scheduled_time}/>
          </Row>}
        </a>
      </Button>
      {!loading && homeCommunityEvents && ReactDOM.createPortal(<HomeCommunityEvents/>, homeCommunityEvents)}
      {(hasUserFlags(['SKUMMUNITY_ENABLED']) || hasCapabilities(['ACCESS-SKUMMUNITY-FORUM'])) &&
        <Button color={colors.special1} size="tiny" style={{ marginLeft: 8 }}>
          <a href={`${config.skummunity_base_url}/session/sso?return_path=${config.skummunity_return_path}`}
            target="_blank"
            style={{ color: colors.white }}
            rel="noreferrer"
          >
            <Row justify="center" style={{ fontWeight: 'bold', fontSize: '0.8rem'}}>
              skummunity<br/>Forum
            </Row>
          </a>
        </Button>}
      <LiveUpdate interval={60 * 1000} events={upcoming} render={({ events }) => {
        return map(
          filter(events, ({ scheduled_time, user_id, community_event_user_id }) => {
            const interested = !isEmpty(community_event_user_id);
            return moment(scheduled_time).isBetween(moment().add(-1, 'minutes'), moment().add(5, 'minutes'))
              && (user_id === identity.user_id || interested || isAdmin())
              ;
          }),
          (event, i) => {
            return <ShowPopup key={i} autoOpen={true} popup={DetailPopup} event={event}/>;
          }
        );
      }}/>
    </Theme>
  </ErrorBoundary>;
};

export default withReducers(EventsReminder, {
  community_events: communityEventsSlice.reducer
});
