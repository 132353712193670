import React from 'react';

import DepositInvoice from './DepositInvoice';
import LabelledCheckbox from './LabelledCheckbox';
import AvalaraOrderTaxButton from './AvalaraOrderTaxButton';

import { formatMoney } from '../utils';
import { isAvalaraOrder } from '../helpers/order';

const OrderSummary = ({ order, onUpdateOrder, onAddDepositInvoice, onUpdateDepositInvoice, onDeleteDepositInvoice, deposit_invoice, identity, credit_card_payable, stripe_cc_payable, stripe_ach_payable }) => {
  const locked = 1 == order.locked;
  return (
    <div className="large-4 column" style={{ padding: '2px' }}>
      <table className="summary">
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td>${formatMoney(order.total_subtotal)}</td>
          </tr>
          {order.tax_amounts.map((ta, i) => <tr key={i}>
            <td>{ta.label}</td>
            <td>${formatMoney(ta.amount)}</td>
          </tr>)}
          {isAvalaraOrder(order) && <AvalaraOrderTaxButton order={order}/>}
          <tr>
            <td>Total</td>
            <td>${formatMoney(order.total_total)}</td>
          </tr>
          {order.order_type === 'ESTIMATE' ?
            <tr>
              <td>
                <LabelledCheckbox label="Hide Totals" disabled={locked} value={order.hide_totals} onChange={onUpdateOrder(order.order_id, 'hide_totals', order.hide_totals)} />
              </td>
              <td>&nbsp;</td>
            </tr>
          : null}
          {order.order_type === 'SALES ORDER' ?
            <tr>
              <td>
                <input type="checkbox" disabled={locked} checked={deposit_invoice ? true : false} onChange={e => {e.target.checked ? onAddDepositInvoice(order.job_id, order.order_id) : onDeleteDepositInvoice(deposit_invoice.deposit_id); }} />
                Require a deposit
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
          : null }
        </tbody>
      </table>
      {order.order_type === 'SALES ORDER' ?
      <div className="row collapse">
        {deposit_invoice ?
          <DepositInvoice deposit_invoice={deposit_invoice} onUpdateDepositInvoice={onUpdateDepositInvoice} locked={locked} identity={identity}  stripe_cc_payable={stripe_cc_payable} stripe_ach_payable={stripe_ach_payable} credit_card_payable={credit_card_payable} />
        : null }
      </div>
      : null}
    </div>
  );
};

export default OrderSummary;
