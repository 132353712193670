import React from 'react';

import { getImageSrc } from '../utils';
import { BaseProductItemSummary, INVENTORY_BADGE_STYLE } from './ProductItemSummary';
import { withDraggableItem } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import Img from './Img';

class OptionItemSummary extends BaseProductItemSummary {

  constructor(props) {
    super(props);
  }

  renderItem() {
    const { item, display } = this.props;
    const visible_options = item.options.filter(o => 0 == o.hidden);
    const product_url = `/product.php?id=${item.parent_id}`;
    const sku = item.item_sku ? item.item_sku : (item.product ? item.product.product_supplier_code : '** Unknown **');
    const vendor_url = `/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`;
    const division_name = item.product ? item.product.division_name : '** Unknown **';
    const phone = (item.division_phones || [])[0] ? item.division_phones[0] : null;
    const default_image = item.item_images.map(ii => ii.image)[0];
    let image = (
      <div className="image">
        <Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOver('item')} onMouseOut={this.handleMouseOut('item')} />
      </div>);
    if (display === 'grid') {
      image = (
        <div className="image"
          style={{background:"url(" + getImageSrc(default_image) +")", backgroundSize:"cover", marginBottom: "8px"}}
          onMouseOver={this.handleMouseOver('item')}
          onMouseOut={this.handleMouseOut('item')}
        >
        </div>
      );
    }

    return (
      <tr>
        <td className="description" onMouseLeave={(e) => {this.setState({showActions: false });}}>
          {image}
          <a className="product-sku" href={product_url} target="_blank" onClick={e => e.stopPropagation()}>{sku}</a>
          {item.hasInventory && <span style={INVENTORY_BADGE_STYLE}>Inventory</span>}
          <div className="product-vendor-container"><a className="product-vendor" href={vendor_url} target="_blank" onClick={e => e.stopPropagation()}>{item.division_name}</a>{phone && phone.phone_number ? ` (${phone.phone_number}${phone.phone_extension ? ' x' + phone.phone_extension : ''})` : null}</div>
          <span className="product-name">{item.item_name}</span>
          {this.renderActions()}
        </td>
        <td className="breakdown-count">{visible_options.length}</td>
      </tr>
    );
  }
}

export default withDraggableItem(DragItemTypes.ITEM, OptionItemSummary);
