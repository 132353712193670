import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createFetchReminders, createUpdateReminder } from '../actions/header';

import DropdownMenu, {
  MenuTrigger, MenuOptions
} from '../components/DropdownMenu';
import { isZeroDate, getMonthFull, getDayFull } from '../utils';

const str2DisplayDate = (d) => {
  const date = new Date(d);
  return getDayFull(date.getDay()) + ', ' +
    getMonthFull(date.getMonth()) + ' ' + date.getDate() + ', ' +
    date.getFullYear();
};

const getFormatDate = () => {
  const d = new Date();
  const f = (n) => {
    const s = n.toString();
    return (s.length === 1 ? '0' : '') + s;
  };
  return d.getFullYear() + '-' + f(d.getMonth()) + '-' + f(d.getDate()) +
    ' ' + f(d.getHours()) + ':' + f(d.getMinutes()) + ':' + f(d.getSeconds());
};

class ReminderNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: _.get(props, 'reminder.reminder_complete') == 1,
    };
    this.onToggleComplete = this.onToggleComplete.bind(this);
  }

  onToggleComplete() {
    const complete = !this.state.complete;
    this.setState({complete: complete});
    this.props.toggleComplete(this.props.reminder, complete);
  }

  render() {
    const {
      date_reminder,
      date_created,
      created_by,
      creator_user_full_name,
      note_file_id,
      parent_type,
      parent_name,
      message_text = '',
      file_display_name,
      parent_id,
    } = this.props.reminder;

    const {
      complete,
    } = this.state;

    const time = {
      JOB: {
        href: 'project.php?id=' + parent_id,
        value: 'View Project',
      },
      ORDER: {
        href: 'order.php?id=' + parent_id,
        value: 'View Order',
      },
      CLIENT: {
        href: 'client.php?id=' + parent_id,
        value: 'View ' + parent_name,
      },
      'PURCHASE ORDER': {
        href: 'purchase_order.php?id=' + parent_id,
        value: 'View Purchase Order',
      },
      SUPPLIER: {
        href: 'vendor.php?id=' + parent_id,
        value: 'View ' + parent_name,
      },
      'SUPPLIER-ACCOUNT': {
        href: 'vendor.php?account_id=' + parent_id,
        value: 'View ' + parent_name,
      },
      'TENANT-ACCOUNT': {
        href: 'distributor.php?account_id=' + parent_id,
        value: 'View ' + parent_name,
      },
      'OPPORTUNITY': {
        href: 'opportunity.php?id=' + parent_id,
        value: 'View Opportunity',
      },
      'PRESENTATION': {
        href: 'presentation.php?id=' + parent_id,
        value: 'View Presentation',
      }
    }[parent_type];

    return <li className="note">
      <p className={complete ? 'strikethrough' : ''}>
        {!isZeroDate(date_reminder) ?
          <input type="checkbox" value="1"
            onChange={this.onToggleComplete}
            checked={complete}/> :
          null}
          <span dangerouslySetInnerHTML={{
            __html: message_text.replace('/<i>/g', '')
          }}/>
        <time>
          Posted {str2DisplayDate(date_created, "DD, MM d, yy")} by
          <a target="_blank" rel="noreferrer" href={'/user.php?id=' + created_by}>
            {' ' + creator_user_full_name}
          </a>.
          {note_file_id != null ?
            <a href="#" data-fileid="{note_file_id}"
              data-type="{file_type}" data-hash="${hash}"
              data-filename="{file_name}"
              data-displayfilename="{file_display_name}"
              className="download">Download "{file_display_name}"</a> :
            null}
        </time>
        {_.isEmpty(time) ? null :
          <time>
            <a href={'/' + time.href} target="_blank" rel="noreferrer">{time.value}</a>
          </time>}
      </p>
    </li>;
  }
}

class ReminderDropdownMenu extends Component {
  render() {
    const {
      reminders, toggleComplete,
    } = this.props;

    return <DropdownMenu className="reminder"
      onDropdownShow={this.props.onDropdownShow}>
      <MenuTrigger>
        <i className="fi-checkbox"></i>
      </MenuTrigger>
      <MenuOptions align="right">
        {_.isEmpty(reminders) ?
          <div style={{textAlign:'center', color: '#aaa'}}>
            You don't currently have any reminders.
          </div> :
            _.map(reminders, (reminder, key) =>  {
              return <ReminderNote key={key} reminder={reminder}
                toggleComplete={toggleComplete}/>;
            })}
      </MenuOptions>
    </DropdownMenu>;
  }
}

const mapStateToProps = (state) => {
  return {
    reminders: _.get(state, 'entities.reminders', {}),
  };
};

const mapDispatchToProps = dispatch => ({
  onDropdownShow: () => {
    dispatch(createFetchReminders());
  },
  toggleComplete: (reminder, complete) => {
    dispatch(createUpdateReminder(reminder, {
      reminder_complete: complete ? '1' : '0',
      date_complete: complete ? getFormatDate() : '0000-00-00 00:00:00',
    }));
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(ReminderDropdownMenu);

