import { oauth } from '../utils';

export const CALL_API = 'commonsku_api';

const API_ROOT = '/v1/';

// Add method, end
const callApi = (method, endpoint, payload) => {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;

  return oauth(method, fullUrl, payload).then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    });
};

const middleware = store => next => action => {
  const callAPI = action[CALL_API];

  if (callAPI === undefined) {
    return next(action);
  }

  const { types, payload } = callAPI;

  let { endpoint, method } = callAPI;

  if (!method) {
    method = 'GET';
  }

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [ requestType, successType, failureType ] = types;
  next(actionWith({type: requestType}));

  return callApi(method, endpoint, payload).then(
    response => next(actionWith({
      payload: {
        response,
        data: payload
      },
      type: successType,
    })),
    error => next(actionWith({
      type: failureType,
      error: true,
      payload: {
        error: error.message || 'Something bad happened',
        data: payload
      }
    }))
  );
};
export default middleware;
