import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { reducers } from '../store/configureCskuStore';
import withReducers from '../store/withReducers';
import Header from '../containers/Header';
import Sidebar from '../components/Sidebar';
import SupplierBenefits from '../components/SupplierBenefits';
import Overlay from '../components/Overlay';
import createPopup from '../popup-factory';
import { createSelectVariantPopup, createSelectFobPopup, createSupplierBenefitsPopup } from '../actions/popup';
import BenefitsSidepanel from '../components/BenefitsSidepanel';
import { window, document } from '../global';

import '../../scss/css/csku.css';

class CskuApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show_benefits_division_id: '',
      show_benefits_supplier_id: ''
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const _this = this;
    window.__CSKU__ = {
      onAddAmbiguousProduct(order_id, parent_type, product, success_callback = null) {
        if (product.variants) {
          dispatch(createSelectVariantPopup(product.variants, order_id, parent_type, product.copied_from, null, null, null, null, success_callback));
          return true;
        } else if (product.fobs.length > 0) {
          dispatch(createSelectFobPopup(product, order_id, product.currency_id, parent_type, product.copied_from, null, null, null, null, success_callback));
          return true;
        }
        return false;
      },
      onShowBenefits(show_benefits_division_id, show_benefits_supplier_id='') {
        _this.setState({ show_benefits_division_id, show_benefits_supplier_id });
      }
    };

    _.each(document.getElementsByClassName('csku-react'), (el) => {
      el.style.display = 'block';
    });
  }

  render() {
    const {
      identity, popups,
    } = this.props;

    const {
      show_benefits_division_id,
      show_benefits_supplier_id
    } = this.state;

    const root = document.getElementById('root');
    return (
      <div>
        <Header/>
        <Sidebar identity={identity}/>
        {popups.map((p, idx) => createPopup(p, idx))}
        <Overlay popups={popups} />
        {(show_benefits_division_id || show_benefits_supplier_id) && !!root && ReactDOM.createPortal(
          <BenefitsSidepanel division_id={show_benefits_division_id} supplier_id={show_benefits_supplier_id} onClose={() => this.setState({ show_benefits_division_id: '', show_benefits_supplier_id: '' })} onChangeDivision={division_id => this.setState({ show_benefits_division_id: division_id})} />
        , root)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    popups: _.get(state, 'display.popups', []),
  };
};

export default withReducers(connect(mapStateToProps)(CskuApp), reducers);
