import { oauth } from '../utils';

export const ADD_TAX_SUCCESS = 'ADD_TAX_SUCCESS';

export const createFinalizeAddTax = tax => ({
  type: ADD_TAX_SUCCESS,
  payload: {
    tax
  }
});

export const CALCULATE_AVALARA_TAX = 'CALCULATE_AVALARA_TAX';
export const calculateAvalaraTax = (order_id) => async (dispatch) => {
  const { json } = await oauth('POST', 'avalara', { action: 'calculate', order_id });
  return dispatch({
    type: CALCULATE_AVALARA_TAX,
    payload: {
      order: json.order,
    },
  });
};

export const COMMIT_AVALARA_TAX = 'COMMIT_AVALARA_TAX';
export const commitAvalaraTax = (order_id) => async (dispatch) => {
  const { json } = await oauth('POST', 'avalara', { action: 'commit', order_id });
  return dispatch({
    type: COMMIT_AVALARA_TAX,
    payload: {
      order: json.order,
    },
  });
};
