import React, { Component } from 'react';
import { Dropzone } from '@commonsku/styles';

import { connect } from 'react-redux';
import { createUploadFile } from '../actions/file';
import { createAddEmail } from '../actions/email';
import { createAddTemplatePopup } from '../actions/popup';
import { createLoadOrder } from '../actions/order';
import { formatFriendlyList, getIdentityUtils, getImageSrc, oauth } from '../utils';

import { getFeedbackByOrderId } from '../selectors';
import { getUserContactOptions, getUserContactImages, getUserContactSignatures, getEmailTemplateOptions, getEmailTemplateTexts, getContactEmailOptions, getCompanyContactsForEmailList } from '../selectors/dropdowns';
import Select from './Select';
import EmailList, { getValueFromContactIdsAndExtraEmails, getContactIds, getExtraEmails } from './EmailList';
import RichTextEditor from './LazyRichTextEditorTinymce';
import Img from './Img';
import { window } from '../global';

class ProjectFeedback extends Component {

  checkClientPortal(client_id) {
    return oauth('GET', 'client-portal/check-portal-by-client-id', { client_id }).then(
      ({ json }) => {
        return json.portal && Object.keys(json.portal).length > 0;
      }
    ).catch(err => {
      return false;
    });

  }

  constructor(props) {
    super(props);

    const message = this.buildFeedBackLink();
    const portal_message = this.buildPortalLink();
    const signature = this.buildSignature();
    const originalEmailBody = !this.props.hasUserFlag('EMAIL-OPTION-ATTACH-PORTAL')
      ? message + portal_message + '<p><br/></p>' + signature
      : message + '<p><br/></p>' + signature;

    this.state = {
      request_feedback: !this.props.feedback_emails && this.props.order.feedback_received != 1,
      subject: 'We welcome your feedback!',
      template: '',
      body: originalEmailBody,
      originalEmailBody,
      signature,
      from_contact_id: this.props.default_from_contact_id,
      to_contacts: getValueFromContactIdsAndExtraEmails(
        this.props.contact_emails,
        [this.props.default_to_contact_id],
        []
      ),
      cc_contacts: [],
      bcc_contacts: [],
      email_template_id: '',
      attachments: [],
      bcc_me: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC1'),
      bcc_order_rep: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC2'),
      bcc_client_rep: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC3'),
      request_receipt: !this.props.hasUserFlag('EMAIL-OPTION-NO-REQUEST-RECEIPT'),
      attach_portal: !this.props.hasUserFlag('EMAIL-OPTION-ATTACH-PORTAL'),
      attach_client_portal: !this.props.hasUserFlag('EMAIL-OPTION-ATTACH-CLIENT-PORTAL'),
      portal_message: portal_message,
      client_portal_message: '',
      has_client_portal: false,
    };

    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTemplate = this.onChangeTemplate.bind(this);
    this.onChangeFrom = this.onChangeFrom.bind(this);
    this.onAddAttachment = this.onAddAttachment.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);

    this.handleClickSend = this.handleClickSend.bind(this);
    this.handleClickSendAnother = this.handleClickSendAnother.bind(this);
  }

  componentDidMount() {
    this.checkClientPortal(this.props.order.client_id).then(hasPortal => {
      if (hasPortal) {
        const client_portal_message = this.buildPortalLink(true);
        const portal_message = this.state.portal_message;
        const message = this.buildFeedBackLink();
        const signature = this.buildSignature();

        let body = message;
        if (this.state.attach_portal) {
          body += portal_message;
        }

        if (this.state.attach_client_portal) {
          body += client_portal_message;
        }

        body += '<p><br/></p>' + signature;
        this.setState({ has_client_portal: true, client_portal_message, originalEmailBody: body, });
        this.refreshBody();
      }
    });
  }

  UNSAFE_componentWillMount() {
    if (1 == this.props.order.feedback_received && !this.props.order.loaded) {
      this.props.loadOrder(this.props.order.order_id);
    }
  }

  buildPortalLink(client_link = false) {
    const portal_url = client_link
      ? `${window.location.origin}/client/${this.props.order.client_id}/portal/`
      : `${window.location.origin}/portal/${this.props.order.job_id}`;
    return `<p>Visit ${client_link ? 'Client' : 'Project'} Portal: <a href="${portal_url}" target="_blank">${portal_url}</a></p>`;
  }

  buildFeedBackLink() {
    const feedback_url = `${window.location.origin}/feedback.php?id=${this.props.order.order_id}`;
    return `<p>Please go to <a href="${feedback_url}">${feedback_url}</a> to provide us with feedback on your order.</p>`;
  }

  buildSignature() {
    const signature = this.props.getUserContactSignature(this.props.default_from_contact_id);
    return signature ? '<p>' + signature + '</p>' : '';
  }

  handleClickSendAnother(e) {
    const feedback_url = `${window.location.origin}/feedback.php?id=${this.props.order.order_id}`;
    const message = `Please go to <a href="${feedback_url}">${feedback_url}</a> to provide us with feedback on your order.`;
    const portal_message = this.buildPortalLink();
    const client_portal_message = this.state.has_client_portal ? this.buildPortalLink(true) : '';

    const signature = this.buildSignature();
    this.setState({
      request_feedback: true,
      subject: 'We welcome your feedback!',
      template: '',
      body: message + '<p><br/></p>' + signature,
      originalEmailBody: message + '<p><br/></p>' + signature,
      signature,
      from_contact_id: this.props.default_from_contact_id,
      email_template_id: '',
      attachments: [],
      bcc_me: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC1'),
      bcc_order_rep: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC2'),
      bcc_client_rep: !this.props.hasUserFlag('EMAIL-OPTION-NO-BCC3'),
      request_receipt: !this.props.hasUserFlag('EMAIL-OPTION-NO-REQUEST-RECEIPT'),
      attach_portal: !this.props.hasUserFlag('EMAIL-OPTION-ATTACH-PORTAL'),
      attach_client_portal: !this.props.hasUserFlag('EMAIL-OPTION-ATTACH-CLIENT-PORTAL'),
      portal_message: portal_message,
      client_portal_message: client_portal_message,
    });
  }

  onAddAttachment(files) {
    this.setState({ attachments: this.state.attachments.concat(files) });
  }

  handleRemoveAttachment(index) {
    return e => {
      this.setState({ attachments: this.state.attachments.filter((a, i) => i !== index) });
    };
  }

  handleChangeCheckbox(name) {
    return e => {
      this.setState({ [name]: e.target.checked });
      if(name === 'attach_portal') {
        if(e.target.checked) {
          this.setState({ originalEmailBody: `${this.state.originalEmailBody} ${this.state.portal_message}`});
        }else{
          this.setState({ originalEmailBody: this.state.originalEmailBody.replace(this.state.portal_message, '') });
        }
      } else if(name === 'attach_client_portal' && this.state.has_client_portal) {
        if(e.target.checked) {
          this.setState({ originalEmailBody: `${this.state.originalEmailBody} ${this.state.client_portal_message}`});
        }else{
          this.setState({ originalEmailBody: this.state.originalEmailBody.replace(this.state.client_portal_message, '') });
        }
      }
      this.refreshBody();
    };
  }

  refreshBody() {
    setTimeout(() => {
      this.onChangeTemplate(this.state.email_template_id);
    }, 200);
  }

  handleChange(name) {
    return e => {
      this.setState({ [name]: e.target.value });
    };
  }

  onChange(name) {
    return value => {
      this.setState({ [name]: value });
    };
  }

  onChangeTemplate(value) {
    const template = this.props.getEmailTemplateText(value) || '';
    const original = this.state.originalEmailBody;
    const body = `${template}<p><br/></p>${original}`;
    this.setState({ body, template, email_template_id: value });
  }

  onChangeFrom(value) {
    const signature = this.buildSignature();

    const body_html = this.state.originalEmailBody;
    const body = body_html.indexOf(this.state.signature) > -1
      ? body_html.replace(this.state.signature, signature)
      : `${body_html}<p><br/></p>${signature}`
    ;
    this.setState({ originalEmailBody: body, signature, from_contact_id: value });
    this.refreshBody();
  }

  handleClickSend(e) {
    const from_contact_id = this.state.from_contact_id;
    const to_contact_id = getContactIds(this.state.to_contacts);
    const to_extra = getExtraEmails(this.state.to_contacts);
    const cc_contact_id = getContactIds(this.state.cc_contacts);
    const cc_extra = getExtraEmails(this.state.cc_contacts);
    const bcc_contact_id = getContactIds(this.state.bcc_contacts).concat(
      this.state.bcc_me ? [this.props.bcc_me_contact_id] : []
    ).concat(
      this.state.bcc_order_rep ? [this.props.bcc_order_rep_contact_id] : []
    ).concat(
      this.state.bcc_client_rep ? [this.props.bcc_client_rep_contact_id] : []
    );
    const bcc_extra = getExtraEmails(this.state.bcc_contacts);
    const subject = this.state.subject;
    const message_text = this.state.body;
    const request_receipt = this.state.request_receipt;

    Promise.all(this.state.attachments.map(a => this.props.upload(a))).then(files => {
      return this.props.createAddEmail(this.props.order.order_id, from_contact_id, to_contact_id, to_extra, cc_contact_id, cc_extra, bcc_contact_id, bcc_extra, subject, message_text, request_receipt, files.map(f => f.payload.id));
    }).then(action => {
      if (!action.payload.error) {
        this.setState({ request_feedback: false });
      }
    });
  }

  renderRequestFeedback() {
    const { hasCapabilities } = getIdentityUtils(this.props.identity);
    const { user_contacts, contact_emails, email_templates, onAddTemplateClick } = this.props;
    const feedback_url = `${window.location.origin}/feedback.php?id=${this.props.order.order_id}`;
    return (
      <div className="small-12 large-8 columns">
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>From:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <div className="user-select">
              {/* <img src={getUserContactImage(this.state.from_contact_id)} /> */}
              <Select options={[{key:'', value: ''}, ...user_contacts]} value={this.state.from_contact_id} change={this.onChangeFrom} withMarginBottom />
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '1rem' }}>
          <div className="small-12 medium-4 columns">
            <label>To:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <EmailList options={contact_emails} value={this.state.to_contacts} onChange={to_contacts => this.setState({ to_contacts })}  />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '1rem' }}>
          <div className="small-12 medium-4 columns">
            <label>CC:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <EmailList options={contact_emails} value={this.state.cc_contacts} onChange={cc_contacts => this.setState({ cc_contacts })} />
          </div>
        </div>
        <div className="row" style={{ marginBottom: '1rem' }}>
          <div className="small-12 medium-4 columns">
            <label>BCC:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <EmailList options={contact_emails} value={this.state.bcc_contacts} onChange={bcc_contacts => this.setState({ bcc_contacts })} />
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>Options:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <div className="row">
              <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.bcc_me} onChange={this.handleChangeCheckbox('bcc_me')} />&nbsp;&nbsp;BCC Me
              </div>
              <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.bcc_client_rep} onChange={this.handleChangeCheckbox('bcc_client_rep')} />&nbsp;&nbsp;BCC Client Rep
              </div>
              <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.bcc_order_rep} onChange={this.handleChangeCheckbox('bcc_order_rep')} />&nbsp;&nbsp;BCC Rep
              </div>
              <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.request_receipt} onChange={this.handleChangeCheckbox('request_receipt')} />&nbsp;&nbsp;Request Read Receipt
              </div>
              <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.attach_portal} onChange={this.handleChangeCheckbox('attach_portal')} />&nbsp;&nbsp;Attach Portal Link
              </div>
              {(hasCapabilities(['HAS-CLIENT-PORTALS']) && this.state.has_client_portal) ? <div className="small-12 large-6 columns">
                <input type="checkbox" checked={this.state.attach_client_portal} onChange={this.handleChangeCheckbox('attach_client_portal')} />&nbsp;&nbsp;Attach Client Portal Link
              </div> : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>Attachments:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <a className="button">
              <Dropzone onDrop={this.onAddAttachment} style={{border: 'none'}} multiple={true}>
                <><i className="fi-upload"></i>&nbsp;&nbsp;Upload File</>
              </Dropzone>
            </a>
            {this.state.attachments.map((a, index) =>
              <div key={index}>
                {a.name}&nbsp;
                <button onClick={this.handleRemoveAttachment(index)}>&times;</button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>Subject:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <input type="text" value={this.state.subject} onChange={this.handleChange('subject')}></input>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>Template:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <Select options={[{ key: '', value: ''}, ...email_templates]} menuStyle={{ backgroundColor: 'white' }} menuContainerStyle={{zIndex: 20}} value={this.state.email_template_id} change={this.onChangeTemplate} />
            <a onClick={e => { e.preventDefault(); onAddTemplateClick(); }}>Add Template</a><br /><br />
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <label>Email Body:</label>
          </div>
          <div className="small-12 medium-8 columns">
            <RichTextEditor value={this.state.body} onChange={this.onChange('body')} />
            <a className="button alt" onClick={this.handleClickSend}><i className="fi-mail"></i>&nbsp;&nbsp;Send</a>
          </div>
        </div>
        <div className="small-12 columns" style={{padding: 0}}>
          <iframe src={feedback_url} style={{width: '100%', height: '300px'}} />
        </div>
      </div>
    );
  }

  renderFeedbackSent() {
    const { to_contact_id = '', to_extra = [] } = this.props.feedback_emails || {};
    const emails = this.props.contact_emails.filter(e => to_contact_id.includes(e.key)).map(e => e.value).concat(to_extra);
    const account_url = `/client.php?id=${this.props.project.account_id}`;
    const account_name = this.props.project.account.company_name;
    return (
      <div className="small-12 medium-8 columns">
       <p><strong>{`You've sent a feedback form to ${formatFriendlyList(emails, 'nobody')}.`}</strong></p>
        <p>When they reply, you will see the results here as well as on the <a href={account_url} target="_blank" rel="noreferrer">{account_name} client page</a>.</p>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}} >
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  renderFeedbackReceived() {
    const { feedback, company_name, order } = this.props;
    if (!order.loaded) {
      return this.renderLoading();
    }
    return feedback.map(f =>
      <div key={f.feedback_id} className="small-12 medium-8 columns">
        <div className="row">
          <div className="small-8 large-9 columns">
            <p><strong>Please rate your experience working with {company_name}:</strong></p>
            <p className="rating">{'★'.repeat(f.rep_rating)}{'☆'.repeat(5 - f.rep_rating)}</p>
          </div>
          <div className="small-4 large-3 columns">
          </div>
        </div>
        <div className="row">
          <div className="small-8 large-9 columns">
            <p><strong>Have any comments regarding your experience with our Customer Care Team?</strong></p>
            <p>{f.comment}</p>
          </div>
          <div className="small-4 large-3 columns">
          </div>
        </div>
        {f.product_feedback.map(pf =>
          <div key={pf.product_feedback_id} className="row" style={{paddingTop: '1em', borderBottom: '1px solid #CCD5DA'}}>
            <div className="small-4 large-3 columns">
              <Img src={getImageSrc(pf.product_image)} />
            </div>
            <div className="small-8 large-9 columns">
              <p><strong>{pf.product_name}</strong></p>
              <p className="rating">{'★'.repeat(pf.product_rating)}{'☆'.repeat(5 - pf.product_rating)}</p>
              <p>{pf.comment}</p>
            </div>
          </div>
        )}

        {f.files.length ?
          <div className="row">
            <div className="small-12 columns">
              <p><strong>Uploaded files:</strong></p>
            </div>
            {f.files.map(fi =>
              <div key={fi.file_id} className="small-6 medium-4 large-2 columns">
                <Img src={getImageSrc(fi)} />
              </div>
            )}
          </div>
        : null}
      </div>
    );
  }

  render() {
    const { order, user_contacts, contact_emails, getUserContactImage, email_templates } = this.props;
    return (
      <div className="main-content">
        <div className="small-12 columns">
          <h2>Feedback</h2>
        </div>
        {this.props.order.feedback_received == 1 ?  this.renderFeedbackReceived() : (this.state.request_feedback ?  this.renderRequestFeedback() : this.renderFeedbackSent()) }

        <div className="small-12 large-4 columns">
          <div className="tip highlight">
            <h4>How this works</h4>
            <p>Feedback is one of the most valuable tools for your business. You can use it to improve customer satisfaction and boost sales. Feedback Forms have everything you need to get started.</p>
            <p><a href="https://help.commonsku.com/knowledge/feedback" target="_blank" rel="noreferrer">Learn more about feedback</a></p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = ownProps.project.sales_orders[0];
  const feedback = getFeedbackByOrderId(state, { order_id: order.order_id });
  return {
    order,
    bcc_me_contact_id: state.identity.contact_id,
    bcc_order_rep_contact_id: ownProps.project.sales_rep.default_contact_id,
    bcc_client_rep_contact_id: ownProps.project.client_rep.default_contact_id,
    default_to_contact_id: order.billing_contact_id,
    feedback,
    company_name: state.identity.company_name,
    getUserContactImage: contact_id => getUserContactImages(state)[contact_id],
    getUserContactSignature: contact_id => getUserContactSignatures(state)[contact_id],
    getEmailTemplateText: email_template_id => getEmailTemplateTexts(state)[email_template_id],
    email_templates: getEmailTemplateOptions(state),
    user_contacts: getUserContactOptions(state),
    contact_emails: getCompanyContactsForEmailList(state, { parent_id: order.client_id }),
    default_from_contact_id: state.identity.contact_id,
    hasUserFlag: flag_name => state.identity.user_flags.includes(flag_name),
    feedback_emails: state.display.feedback_emails
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    upload: file => dispatch(createUploadFile(ownProps.project.account_id, 'CLIENT', file)),
    createAddEmail: (order_id, from_contact_id, to_contact_id, to_extra, cc_contact_id, cc_extra, bcc_contact_id, bcc_extra, subject, message_text, request_receipt, attachment_file_ids) => dispatch(createAddEmail(order_id, 'feedback', from_contact_id, to_contact_id, to_extra, cc_contact_id, cc_extra, bcc_contact_id, bcc_extra, subject, message_text, request_receipt, attachment_file_ids)),
    onAddTemplateClick: () => dispatch(createAddTemplatePopup())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFeedback);
