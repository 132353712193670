import type { CSSProperties, FC } from 'react';
import React, { memo, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

const style: CSSProperties = {
    cursor: 'move',
};

export interface DraggableFileProps {
    index: any
    file: any
    type: string
    children: any
}

export const DraggableFile: FC<DraggableFileProps> = memo(function DraggableFile({ index, file, type, children }) {
    const [draggingProps, drag, preview] = useDrag(
        () => ({
            type,
            item: file,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
                isDragging: monitor.isDragging(),
            }),
        }),
        [file, type],
    );

    //Use the following to hide the native dragging image
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
        <div ref={drag} style={{ ...style, opacity: draggingProps.isDragging ? 0.4 : 1 }} data-testid="DraggableFile">
            {children}
        </div>
    );
});
