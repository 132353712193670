import { isEmpty } from 'lodash';
import React from 'react';

import { Link } from '@commonsku/styles';

const CompanyLink = ({ company, ...props }) => {

  return isEmpty(company) ? null : <a
    href={`/${{SUPPLIER: 'vendor', DISTRIBUTOR: 'distributor'}[company.company_type]}.php?id=${company.company_id}`}
    target="_blank" rel="noopener noreferrer"
    style={{ textDecoration: 'none', cursor: 'pointer' }}
  ><Link {...props}>{company.company_name}</Link></a>;
};

export default CompanyLink;