import { oauth } from '../utils';

export const CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_REQUEST = 'UPDATE_DISCOUNT_REQUEST';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_FAILURE = 'UPDATE_DISCOUNT_FAILURE';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_FAILURE = 'DELETE_DISCOUNT_FAILURE';

const finalizeCreateDiscount = discount => ({
  type: CREATE_DISCOUNT_SUCCESS,
  payload: {
    discount
  }
});

export const createDiscount = (parent_id, parent_type) => dispatch => {
  return oauth('POST', 'discount', { parent_id, parent_type }).then(
    ({ json }) => {
      dispatch(finalizeCreateDiscount(json.discount));
    },
    ({ json }) => false
  );
};

const requestUpdateDiscount = (discount_id, field, previous_value, value) => ({
  type: UPDATE_DISCOUNT_REQUEST,
  payload: {
    id: discount_id,
    data: {
      [field]: value
    }
  }
});

export const finalizeUpdateDiscount = (discount_id, field, previous_value, value) => ({
  type: UPDATE_DISCOUNT_SUCCESS,
  payload: {
    id: discount_id,
    data: {
      [field]: value
    }
  }
});

const failedUpdateDiscount = (discount_id, field, previous_value, value) => ({
  type: UPDATE_DISCOUNT_FAILURE,
  payload: {
    id: discount_id,
    message: 'Unable to update discount',
    data: {
      [field]: previous_value
    }
  }
});

export const updateDiscount = (discount_id, field, previous_value, value) => dispatch => {
  dispatch(requestUpdateDiscount(discount_id, field, previous_value, value));

  return oauth('PUT', `discount/${discount_id}`, { [field]: value }).then(
    ({ json }) => {
      dispatch(finalizeUpdateDiscount(discount_id, field, previous_value, json.discount[field]));
    },
    ({ json }) => dispatch(failedUpdateDiscount(discount_id, field, previous_value, value))
  );
};

const failedDeleteDiscount = (discount_id, error) => ({
  type: DELETE_DISCOUNT_FAILURE,
  payload: {
    discount_id,
    error
  },
  error: true
});

const finalizeDeleteDiscount = (discount_id) => ({
  type: DELETE_DISCOUNT_SUCCESS,
  payload: {
    discount_id
  }
});

export const deleteDiscount = discount_id => dispatch => {
  return oauth('DELETE', `discount/${discount_id}`, {}).then(
    ({ json }) => {
      dispatch(finalizeDeleteDiscount(discount_id));
    },
    ({ json }) => dispatch(failedDeleteDiscount(discount_id, json.error))
  );
};