import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import WebsocketContext from '../../context/websocket-context';
import { chatSessionTimedOut, oauth } from '../../utils';
import { Button, ChatIcon } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { window } from '../../global';

export default function ChatSupportSessions({ identity, minimal = false }) {
    const [loaded, setLoaded] = useState(false);
    const { updateSupportSessions, setUpdateSupportSessions } = useContext(WebsocketContext);
    const [loading, setLoading] = useState(true);
    const [supportSessions, setSupportSessions] = useState([]);
    const [notifiedSessions, setNotifiedSessions] = useState([]);
    const shops = useSelector(state => state.dropdowns.shops);
    const getUrlShop = useCallback((url) => {
        if (!shops) return;
        const shopId = url.split('/')[2];
        const shop = shops.filter(s => s.shop_id === shopId);
        return shop[0];
    }, [shops]);

    const unmountedRef = useRef(false);

    const getSupportSessions = useCallback(async () => {
        setLoading(true);
        const data = await oauth('GET', 'chat-support-session', {
            'tenant_id': identity.tenant_id,
        });
        if (unmountedRef.current) return;

        setSupportSessions(data.json.sessions);
        setLoading(false);
        setLoaded(true);
        setUpdateSupportSessions(false);
    }, [identity.tenant_id, setUpdateSupportSessions]);

    useEffect(() => {
        if (!loaded || updateSupportSessions) {
            getSupportSessions();
        }
    }, [getSupportSessions, loaded, minimal, updateSupportSessions]);

    // Update timestamp
    useEffect(() => {
        // We don't need to update timestamp in minimal
        // This will greatly reduce the calls to our backend.
        if (minimal) return;

        const intervalId = setInterval(getSupportSessions, 60 * 1000);
        return () => {
            clearInterval(intervalId);
            unmountedRef.current = true;
        };
    }, [getSupportSessions, minimal]);

    const notifyNewSession = useCallback((session) => {
        if (loading || notifiedSessions.includes(session.session_id) || session.agent_id) return;
        if (minimal && window.location.pathname.substr(1) === 'chat.php') return;

        const location = session.url.replace(window.location.origin, '');
        const shop = getUrlShop(location);

        const { timedOut } = chatSessionTimedOut(session.date_created);
        if (timedOut) return;

        setNotifiedSessions([...notifiedSessions, session.session_id]);
        const url = session.url + (session.url.indexOf('?') === -1 ? '?' : '&') + 'session=' + session.session_id;
        const locationName = shop ? ('shop ' + shop.shop_name) : location;

        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            const notification = new Notification(
                'New Chat Requested',
                { body: `${session.guest_name} (${session.guest_email}) in ${locationName}`, icon: '/images/favicons/favicon.ico', requireInteraction: true }
            );
            notification.onclick = () => {
                window.open(url);
            };
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification(
                        'New Chat Requested',
                        { body: `${session.guest_name} in ${locationName}`, icon: '/images/favicons/favicon.ico', requireInteraction: true }
                    );
                    notification.onclick = () => {
                        window.open(url);
                    };
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }, [getUrlShop, loading, minimal, notifiedSessions]);

    useEffect(() => {
        if (loading || supportSessions.length === 0) return;

        // Notify recently received
        notifyNewSession(supportSessions[0]);
    }, [loading, notifyNewSession, supportSessions]);

    if (minimal) {
        return (
            <div className="dropdown-menu" style={{ marginLeft: '10px' }}><div className="dropdown-menu-trigger chat-bot-mini-container">
                <a href='/chat.php'>
                    <ChatIcon color={"#41A5B9"} width={"1.5rem"} notifs={supportSessions.filter(session => {
                        const answered = session.agent_id;
                        const { timedOut } = chatSessionTimedOut(session.date_created);
                        return !answered && !timedOut;
                    }).length } />
                    <b>{' '}Chat</b>
                </a>
            </div></div>
        );
    }

    return (
        <div className="chat-sessions">
            {supportSessions.length === 0 && <p>No active support session...</p>}
            {supportSessions.map((session) => {
                const answered = session.agent_id;
                const { created, timedOut } = chatSessionTimedOut(session.date_created);
                const location = session.url.replace(window.location.origin, '');
                const shop = getUrlShop(location);
                const link = session.url + (session.url.indexOf('?') === -1 ? '?' : '&') + 'session=' + session.session_id;
                let clientName = shop?.type === 'Marketing' ? null : <span> for <b>{shop?.client_name}</b></span>;
                if (!shop) clientName = '';

                return (
                    <div key={session.session_id} className={(answered || timedOut ? 'stale-session' : 'active-session') + ' chat-session'}>
                        <div className='main'>
                            <div style={{ marginRight: '1em' }}>
                                <b>{session.guest_name}</b> ({session.guest_email}) in{' '}
                                <b>{shop ? shop.shop_name : location}</b>
                                {clientName}
                            </div>
                            {answered && <div>Answered by {session.contact_first_name}</div>}
                            {!answered && timedOut && <div>Not Answered</div>}
                        </div>
                        <div className='action'>
                            {!answered && !timedOut && <div><Button onClick={() => window.open(link)}>Answer</Button></div>}
                            <div className="timestamp">{created.fromNow()}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
