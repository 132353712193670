import { SyntheticEvent, useEffect, useState } from 'react';
import { rawOAuth } from '../../utils';

export function loadImage(image: HTMLImageElement, src: string) {
  return new Promise((resolve, reject) => {
    const initSRC = image.src;
    const img = image;
    img.onload = resolve;
    img.onerror = err => {
      img.src = initSRC;
      reject(err);
    };
    img.src = src;
  });
}

export function useImage(file?: File): [HTMLImageElement, boolean] {
  const [image] = useState(new Image());
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (file === undefined) {
      setIsLoaded(false);
      return;
    }
    image.onload = () => {
      setIsLoaded(true);
    };
    setIsLoaded(false);
    image.src = URL.createObjectURL(file);
    return () => {
      image.onload = null;
    };
  }, [file, image]);

  return [image, isLoaded];
}

export function isRightClick(ev: SyntheticEvent) {
  const mouseEvent = ev.nativeEvent as MouseEvent;
  return mouseEvent.button === 2;
}

export function isMidClick(ev: SyntheticEvent) {
  const mouseEvent = ev.nativeEvent as MouseEvent;
  return mouseEvent.button === 1;
}

export function srcToFile(src: string, fileName: string, mimeType: string) {
  const data = {
    action: 'fetch',
    image_url: src,
  };

  return rawOAuth('GET', 'image-editor', data, null, { 'Accept': 'image/png' })
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], fileName, { type: mimeType }));
}

export async function canvasToBlob(canvas: HTMLCanvasElement, mime: string): Promise<any> {
  return new Promise((resolve, reject) =>
    canvas.toBlob(async d => {
      if (d) {
        resolve(d);
      } else {
        reject(new Error('Expected toBlob() to be defined'));
      }
    }, mime)
  );
}

export async function imageToBlob(image: HTMLImageElement, mime: string, width?: number, height?: number): Promise<any> {
  const canvas = document.createElement('canvas');
  canvas.width = width ?? image.naturalWidth;
  canvas.height = height ?? image.naturalHeight;
  const context = canvas.getContext('2d');

  if (context == null) return null;

  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  return canvasToBlob(canvas, mime);
}
