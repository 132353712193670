import _ from 'lodash';
import React, { Component } from 'react';

import { formatMoney, isNumeric } from '../utils';
import { itemCostReducer } from '../reducers/item';
import { createUpdateItemCostRequest } from '../actions';
import { UPDATE_ITEM_COST_REQUEST } from '../actions/item';

import { DraggableItemContainer } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import TextInput from './TextInput';
import PS from './PromostandardsLabel';
import { TableIcon } from '@commonsku/styles';

const reduceSingleCost = (item_cost, action) => {
  const old_state = {[item_cost.item_cost_id] : item_cost};
  const new_state = itemCostReducer(old_state, action);
  return new_state[item_cost.item_cost_id];
};

const changeItemCost = (item_cost, breakdown_cost, field, value, field_lock = 'unit_cost') => {
  return createUpdateItemCostRequest(item_cost.item_cost_id, breakdown_cost, field, item_cost[field], value, field_lock);
};

class ItemCost extends Component {

  constructor(props) {
    super(props);

    const item_cost = this.formatCost(props.item_cost);

    this.state = {
      item_cost,
      initial_item_cost: item_cost
    };

    _.bindAll(this, ['isValueValid', 'formatValue', 'formatCost', 'onChange', 'onUpdate']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const initial_item_cost = this.formatCost(nextProps.item_cost);
    const item_cost = Object.assign({}, initial_item_cost, Object.keys(this.state.item_cost).reduce((o, k) => {
      if (this.state.item_cost[k] !== this.state.initial_item_cost[k]) {
        o[k] = this.state.item_cost[k];
      }
      return o;
    }, {}));

    this.setState({
      item_cost,
      initial_item_cost
    });
  }

  formatCost(cost) {
    return Object.keys(cost).reduce((o, k) => {
      o[k] = this.formatValue(k, cost[k]);
      return o;
    }, {});
  }

  isValueValid(field, value) {
    switch (field) { case 'quantity':
        return null === value || isNumeric(value) && parseFloat(value) === parseInt(value, 10);
      case 'total_margin':
      case 'unit_cost':
      case 'unit_price':
        return isNumeric(value);
      case 'percent':
        return null === value || isNumeric(value);
    }
    return true;
  }

  formatValue(field, value) {
    switch (field) {
      case 'quantity':
        return null === value ? null : parseFloat(value).toFixed(0);
      case 'unit_cost':
        return parseFloat(value).toFixed(4);
      case 'total_margin':
      case 'unit_price':
        return parseFloat(value).toFixed(2);
      case 'percent':
        return null === value ? null : parseFloat(value).toFixed(2);
    }
    return value;
  }

  onChange(field, value) {
    if (this.isValueValid(field, value)) {
      const item_cost = this.formatCost(reduceSingleCost(
        this.state.initial_item_cost,
        changeItemCost(this.state.initial_item_cost, this.props.breakdown_cost, field, value, this.props.field_lock)
      ));
      this.setState({
        item_cost: Object.assign({}, item_cost, { [field]: value })
      });
    } else {
      this.setState({ item_cost: Object.assign({}, this.state.item_cost, { [field]: value })});
    }
  }

  onUpdate(field, value) {
    const { field_lock } = this.props;
    if (this.isValueValid(field, value)) {
      const formattedValue = this.formatValue(field, value);
      if (formattedValue === this.state.initial_item_cost[field]) {
        this.setState({
          item_cost: Object.assign({}, this.state.initial_item_cost)
        });
      } else {
        this.setState({
          item_cost: Object.assign({}, this.state.item_cost, { [field]: formattedValue }),
          initial_item_cost: Object.assign({}, this.state.initial_item_cost, { [field]: formattedValue })
        }, () => {
          this.props.onUpdate(this.props.item_cost.item_cost_id, field, this.state.initial_item_cost[field], field_lock)(this.formatValue(field, value));
        });
      }
    } else {
      this.setState({ item_cost: this.state.initial_item_cost });
    }
  }

  render() {
    const {
      breakdown_quantity, onDelete, locked,
      editPS,
      mandatory = false,
      editPSTitle = true,
      onCreateApplyMatrixToItemPopup,
      exchange_rate = 1.0,
      ...props
    } = this.props;
    const {
      item_cost, disableDrag,
    } = this.state;
    const is_run_cost = item_cost.quantity === null && item_cost.percent === null;
    const quantity = (item_cost.quantity === null) ? breakdown_quantity : item_cost.quantity;
    const total_price = item_cost.unit_price * quantity;

    const dndProps = {
      selectOnFocus: true,
      onMouseEnter: () => this.setState({ disableDrag: true }),
      onMouseLeave: () => this.setState({ disableDrag: false }),
    };
    let percentField, quantityField;
    let itemCostTitleSpan = 2;
    let totalPriceField = '$' + formatMoney(total_price);
    let includePriceOption = 'Include in price';

    let totalMarginField = (<td>
      <TextInput disabled={locked || exchange_rate !== 1} className="text-right indent-percent"
        {...dndProps}
        value={item_cost.total_margin}
        onChange={e => this.onChange('total_margin', e.target.value)}
        onBlur={e => this.onUpdate('total_margin', e.target.value)} />
      <span className="percent">%</span>
    </td>);
    let unitPriceField = (<td>
      <span className="dollar">$</span>
      <TextInput disabled={locked || 'unit_price' === this.props.field_lock} className="text-right"
        {...dndProps}
        value={item_cost.unit_price}
        onChange={e => this.onChange('unit_price', e.target.value)}
        onBlur={e => this.onUpdate('unit_price', e.target.value)} />
      </td>
    );

    if (1 == item_cost.hidden) {
      totalPriceField = '';
    }

    const titleStyle = item_cost.ext_cost_id ? {
      paddingLeft: '2.5rem'
    } : {};

    if (item_cost.percent !== null) {
      if (1 == item_cost.hidden) {
        totalMarginField = <td>&nbsp;</td>;
        unitPriceField = <td>&nbsp;</td>;
      }

      itemCostTitleSpan = 1;
      percentField = (<td>
        <TextInput disabled={locked} className="text-right indent-percent"
          {...dndProps}
          value={item_cost.percent}
          onChange={e => this.onChange('percent', e.target.value)}
          onBlur={e => this.onUpdate('percent', e.target.value)} />
        <span className="percent">%</span>
      </td>);
      quantityField = <td>&nbsp;</td>;
    } else {
      if (!is_run_cost) {
        if (1 == item_cost.hidden) {
          totalMarginField = <td>&nbsp;</td>;
          unitPriceField = <td>&nbsp;</td>;
        }
        includePriceOption = 'Subtract from margin';

        quantityField = (<td>
          <TextInput disabled={locked} value={item_cost.quantity}
            {...dndProps}
            onChange={e => this.onChange('quantity', e.target.value)}
            onBlur={e => this.onUpdate('quantity', e.target.value)} />
        </td>);
      } else {
        quantityField = <td>{quantity}</td>;
      }
    }
    const titleEditable = !locked && (!item_cost.ext_cost_id || editPSTitle && editPS);

    const rowProps = Object.keys(props).filter(k => ![
      'onUpdate', 'isDragging',
      'onDrop', 'onMove',
    ].includes(k)).reduce((o, k) => ({ ...o, [k]: props[k] }), {});

    return (
      <DraggableItemContainer
        type={DragItemTypes.COST}
        index={this.props.index}
        onDrop={this.props.onDrop}
        onMove={this.props.onMove}
        item={item_cost}
        canDrag={!(locked || disableDrag)}
        id={item_cost.item_cost_id}
      >
        <tr {...rowProps}>
          <td>{(!locked && (!mandatory || editPS)) ?
            <a className="delete"
              onClick={
                e => {e.preventDefault(); onDelete(item_cost.item_cost_id);}
              }>
              &times;
            </a> :
            null
          }</td>
          <td colSpan={itemCostTitleSpan}>
            <div style={{ position: 'relative' }}>
              {item_cost.ext_cost_id && <PS style={{ position: 'absolute', top: '0.5rem', left: '0.5rem' }} />}
              <TextInput disabled={!titleEditable} maxLength = "255"  value={item_cost.item_cost_title} style={titleStyle}
                {...dndProps}
                onChange={e => this.onChange('item_cost_title', e.target.value)}
                onBlur={e => this.onUpdate('item_cost_title', e.target.value)}
                hasIcon={!(locked || item_cost.ext_cost_id) && onCreateApplyMatrixToItemPopup}
                iconRight={true}
                IconElem={TableIcon}
                iconStyles={{width: 30, height: 30, cursor: 'pointer'}}
                iconProps={{
                  onClick: (e) => {
                    e.preventDefault(); onCreateApplyMatrixToItemPopup({
                      qty: item_cost.quantity == null ? breakdown_quantity : item_cost.quantity,
                      charge_type: is_run_cost ? 'run' : 'fixed',
                      onAddItemCost: this.onUpdate,
                      onUpdateItem: this.props.onUpdate,
                      formatValue: this.formatValue,
                      unit_cost_field: 'unit_cost',
                      qty_field: is_run_cost ? null : 'quantity',
                      label_field: 'item_cost_title',
                      item_location_id: this.props.item_cost.item_location_id,
                      item_cost_id: this.props.item_cost.item_cost_id,
                    });
                  }
                }}
              />
            </div>
          </td>
          {percentField}
          {quantityField}
          <td>
            <span className="dollar">$</span>
            <TextInput disabled={locked || (!!item_cost.ext_cost_id && !editPS)} className="text-right"
              {...dndProps}
              value={item_cost.unit_cost}
              onChange={e => this.onChange('unit_cost', e.target.value)}
              onBlur={e => this.onUpdate('unit_cost', e.target.value)} />
          </td>
          {totalMarginField}
          {unitPriceField}
          <td><select disabled={locked} value={item_cost.hidden}
            onChange={e => this.onUpdate('hidden', e.target.value)}>
            <option value="1">{includePriceOption}</option>
            <option value="0">Display to client</option>
          </select></td>
          <td className="text-right">{totalPriceField}</td>
          <td>&nbsp;</td>
        </tr>
      </DraggableItemContainer>
    );
  }
}

export default ItemCost;
