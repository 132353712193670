import React from 'react';
import { NavLink } from 'react-router-dom';

const ProjectMenuItem = ({order_type, job_number, form_number, children, onClick, end }) => {
  return (
    <li>
      <NavLink
        to={`/project/${job_number}/${order_type.replace(' ', '-').toLowerCase()}/${form_number}`}
        onClick={onClick}
        className={({ isActive }) => isActive ? "active" : ""}
        end={end}
      >
        {children}
      </NavLink>
    </li>
  );
};

export default ProjectMenuItem;
