import React from 'react';

import PromostandardsSearchFilter from './PromostandardsSearchFilter';

class ConnectedPlusSearchFilter extends PromostandardsSearchFilter {

  getProductSource() {
    return 'ps-products';
  }

  loadDivisions() {
    this.props.loadDivisions('connected-plus');
  }
}

export default ConnectedPlusSearchFilter;

