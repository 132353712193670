import React from 'react';

import Img from './Img';
import ProofType from '../models/ProofType';

const ArtworkProofRequired = ({ artworks }) => (
  <td colSpan={2}>
    {artworks.some(a => a.proof_id !== ProofType.None) &&
      <div>
        <Img src="/images/jq.toast.notice.png" style={{ height: 15, marginRight: 5 }} />
        <span>Proof Required</span>
      </div>
    }
  </td>
);

export default ArtworkProofRequired;
