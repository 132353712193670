import { Button, CircleProgressIcon, colors, Dropdown, SharedStyleTypes } from '@commonsku/styles';
import React from 'react';
import styled from 'styled-components';
import { useArtworkLibraryContext } from './context';
import useFileActionModal from './useFileActionModal';
import { download, getImageSrc } from '../../utils';

const FileControlsAction = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
`;

const FileControlsWrapper = styled.div`
  position: relative;

  &:hover ${FileControlsAction} {
    opacity: 1;
  }
`;

export type FileControlsProps = {
  children: any,
  file: any,
};

export const FileControls = ({ ...props }: FileControlsProps & SharedStyleTypes) => {
  const { onFileSelect, isModal, setModalOpen, folders } = useArtworkLibraryContext();
  const [popup, setOpen, setItem, setType] = useFileActionModal();
  let actionItems: any = [
    {
      onClick: () => { download(getImageSrc(props.file, 'original'), props.file.file_display_name); },
      content: "Download",
      active: true,
    },
    {
      onClick: () => { setItem(props.file); setType('rename'); setOpen(true); },
      content: "Rename",
      active: true,
    },
  ];

  if (folders.length > 0) {
    actionItems.push({
      onClick: () => { setItem(props.file); setType('move'); setOpen(true); },
      content: "Move",
      active: true,
    });
  }

  actionItems.push({
    onClick: () => { setItem(props.file); setType('delete'); setOpen(true); },
    content: (
      <span style={{ color: colors.errors['main'] }}>Delete</span>
    ),
    active: true,
  });

  return <FileControlsWrapper>
    <div onClick={(e) => {
      if (onFileSelect) {
        onFileSelect(props.file);
      } else {
        window.open(getImageSrc(props.file, 'original'), '_blank');
      }
      if (isModal) {
        setModalOpen(false);
      }
    }}>
      {props.children}
    </div>
    <FileControlsAction>
      <Dropdown size='small' items={actionItems} />
    </FileControlsAction>
    {popup}
  </FileControlsWrapper>;
};
