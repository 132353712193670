import ssr, { isBrowser } from './ssr';

export { isBrowser };
export const window = ssr.window;
export const document = ssr.document;

const global = {};
export const getInitialState = () => {
    if (!global.initialState) {
        // eslint-disable-next-line no-undef
        global.initialState = typeof(initialState) === 'undefined' ? (window.initialState || {}) : initialState;
    }
    return global.initialState;
};