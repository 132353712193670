import { useCallback, useState } from 'react';

function useChat(initChats = []) {
    const [chats, setChats] = useState(initChats);
    const addChat = useCallback((message, dateTime = false) => {
        const chatParts = message.split(':');
        const chatUserParts = chatParts[0].split('%%');
        setChats((chats) => {
            const newChats = [...chats, {
                fullMessage: message,
                message: chatParts.slice(1).join(':'),
                userName: chatUserParts[0],
                userId: chatUserParts[1],
                time: dateTime ? new Date(dateTime) : new Date(),
                notify: dateTime === false,
            }];
            return newChats;
        });
    }, []);

    return [
        chats,
        addChat,
    ];
}

export default useChat;