import _ from 'lodash';
import React from 'react';

import { formatFriendlyDateTime, getUserImageSrc } from '../utils';

const order_type_map = {
  'PRESENTATION': {
    full: 'Presentation',
    short: 'P'
  },
  'ESTIMATE': {
    full: 'Estimate',
    short: 'E'
  },
  'SALES ORDER': {
    full: 'Sales Order',
    short: 'SO'
  },
  'INVOICE': {
    full: 'Invoice',
    short: 'I'
  },
  'PURCHASE ORDER': {
    full: 'Purchase Order',
    short: 'PO'
  },
  'BILL': {
    full: 'Bill',
    short: 'B'
  },
  'OPPORTUNITY': {
    full: 'Opportunity',
    short: 'O',
  }
};

const OrderStatus = ({ order, items }) => {
  let arr = [];
  if(items) {
    let order_items = Object.values(items).filter(i => i.order_id === order.order_id);
    for(var i = 0; i < order_items.length; i++) {
      if(arr.indexOf(order_items[i].parent_id) == -1){
        arr.push(order_items[i].parent_id);
      }
    }
  }
  let product_count = arr.length;
  const order_type = order.order_type;

  return (
    <div className="row order-status">
      <div className="small-3 medium-2 large-3 columns">
        <div className="order-icon">{order_type_map[order_type].short}</div>
      </div>
      <div className="small-9 medium-10 large-9 columns">
        <div className="order-name">
          <strong>{
            order_type === 'PURCHASE ORDER' ? `PO #${order.form_number}` :
            order_type === 'BILL' ? `Bill #${order.bill_reference_number}` :
            order_type_map[order_type].full
          }</strong>
        </div>
        <div className={"status-badge status-" + _.kebabCase(order.status_name)}
        >{order.status_name}</div>
      </div>
    </div>
  );
};

const ProjectHistory = ({ message }) => {
  const avatar = getUserImageSrc(message.actor);
  const time = formatFriendlyDateTime(message.date);
  return (
    <div className="row history">
      <div className="small-3 medium-2 large-3 columns">
        <img src={avatar} className="avatar" />
      </div>
      <div className="small-9 medium-10 large-9 columns">
        <p dangerouslySetInnerHTML={{__html: message.text}} />
        <div className="timestamp">{time}</div>
      </div>
    </div>
  );
};

class ProjectStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      project, items
    } = this.props;
    const has_presentations = !!(project.presentations.length);
    const has_estimates = !!(project.estimates.length);
    const has_sales_orders = !!(project.sales_orders.length);
    const has_invoices = !!(project.invoices.length);
    const opportunity = _.first(project.opportunities) || {};

    return (
      <div className="row">
        <div className="small-12 columns">
          <h4 className="status-title">Project Status</h4>
        </div>
        <div className="small-12 columns">
          {has_invoices ?
            project.invoices.map(o =>
              <OrderStatus key={o.order_id} order={o} />
            )
          : has_sales_orders ?
            project.sales_orders.map(o =>
              <OrderStatus key={o.order_id} order={o} />
            )
          : has_estimates ?
            project.estimates.map(o =>
              <OrderStatus key={o.order_id} order={o} items={items} />
            )
          : has_presentations ?
            project.presentations.map(o =>
              <OrderStatus key={o.order_id} order={o} items={items} />
            )
          : opportunity && !has_presentations && !has_estimates && !has_sales_orders && !has_invoices ?
            project.opportunities.map(o =>
              <OrderStatus key={o.order_id} order={o} />
            )
          : null}
        </div>
      </div>
    );
  }
}

export default ProjectStatus;
