import React, { Component } from 'react';

import LabelledCheckbox from './LabelledCheckbox';

import { formatShortDate, parseDate, formatMoney, isZeroDate } from '../utils';
import DateInput from './DateInput';

class DepositInvoice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deposit_percent: this.props.deposit_invoice.deposit_percent,
      deposit_amount: this.formatMoney(this.props.deposit_invoice.deposit_amount),
      deposit_received: this.formatDate(this.props.deposit_invoice.deposit_received),
      deposit_amount_received: this.formatMoney(this.props.deposit_invoice.deposit_amount_received),
      deposit_confirmation: this.props.deposit_invoice.deposit_confirmation,
      received: this.props.deposit_invoice.deposit_amount_received > 0 ? 'Deposit Received' : 'Deposit NOT Received'
    };

    this.handleChangePercent = this.handleChangePercent.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeReceived = this.handleChangeReceived.bind(this);
    this.handleUpdateReceived = this.handleUpdateReceived.bind(this);
    this.handleChangeAmountReceived = this.handleChangeAmountReceived.bind(this);
    this.handleChangeDepositConfirmation = this.handleChangeDepositConfirmation.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const next_amount_received = nextProps.deposit_invoice.deposit_amount_received;
    const next_deposit_amount = nextProps.deposit_invoice.deposit_amount;
    this.setState({deposit_amount: formatMoney(next_deposit_amount) });
    if(next_amount_received > 0) {
      this.setState({received: 'Deposit Received' });
    }else{
      this.setState({received: 'Deposit NOT Received' });
    }

  }

  formatDate(date) {
    if (isZeroDate(date)) {
      return null;
    } else {
      return formatShortDate((date ? parseDate(date) : Date.now()) / 1000);
    }
  }

  formatMoney(amount) {
    return formatMoney(amount);
  }

  handleChangePercent(e) {
    this.setState({deposit_percent: e.target.value});
  }

  handleChangeAmount(e) {
    this.setState({deposit_amount: e.target.value});
  }

  handleChangeReceived(value) {
    this.setState({deposit_received: value});
  }

  handleUpdateReceived(value) {
    const { deposit_invoice, onUpdateDepositInvoice } = this.props;
    onUpdateDepositInvoice(
      deposit_invoice.deposit_id,
      'deposit_received',
      deposit_invoice.deposit_received
    )(value);
  }

  handleChangeAmountReceived(e) {
    this.setState({deposit_amount_received: e.target.value});
  }

  handleChangeDepositConfirmation(e) {
    this.setState({deposit_confirmation: e.target.value});
  }

  render() {
    const { deposit_invoice, onUpdateDepositInvoice, locked, identity, stripe_cc_payable, stripe_ach_payable, credit_card_payable } = this.props;
    const deposit_url = identity.resku_form == 1 ? `/public_deposit.php?id=${deposit_invoice.deposit_id}` : `/deposit_view.php?id=${deposit_invoice.deposit_id}`;
    const email_deposit_url = `/send_email.php?parent_type=deposit&parent_id=${deposit_invoice.deposit_id}`;

    return (
      <div className="column">
        <table className="summary" key={deposit_invoice.deposit_id}>
          <tbody>
            <tr>
              <td>{this.state.received}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>Deposit Percent</td>
              <td><input type="text" name="deposit_percent" disabled={locked} style={{ textAlign: 'right'}} value={this.state.deposit_percent} onChange={e => this.handleChangePercent(e)} onBlur={e => {e.preventDefault(); onUpdateDepositInvoice(deposit_invoice.deposit_id, 'deposit_percent', deposit_invoice.deposit_percent)(this.state.deposit_percent);} }/></td>
            </tr>
            <tr>
              <td>Deposit Amount</td>
              <td><input type="text" name="deposit_amount" disabled={locked} style={{ textAlign: 'right' }} value={this.state.deposit_amount} onChange={e => this.handleChangeAmount(e)} onBlur={e => {e.preventDefault(); onUpdateDepositInvoice(deposit_invoice.deposit_id, 'deposit_amount', deposit_invoice.deposit_amount)(this.state.deposit_amount);} } /></td>
            </tr>
            <tr>
              <td>Date Received</td>
              <td>
                <DateInput name="deposit_received" disabled={locked}
                  align="right"
                  value={this.state.deposit_received}
                  onChange={this.handleChangeReceived}
                  onBlur={this.handleUpdateReceived}
                />
	    </td>
            </tr>
            <tr>
              <td>Amount Received</td>
              <td><input type="text" name="deposit_amount_received" disabled={locked} style={{ textAlign: 'right' }} value={this.state.deposit_amount_received} onChange={e => this.handleChangeAmountReceived(e)} onBlur={e => {e.preventDefault(); onUpdateDepositInvoice(deposit_invoice.deposit_id, 'deposit_amount_received', deposit_invoice.deposit_amount_received)(this.state.deposit_amount_received);} }/></td>
            </tr>
            <tr>
              <td>Confirmation #</td>
              <td><input type="text" name="deposit_confirmation" disabled={locked} value={this.state.deposit_confirmation} onChange={e => this.handleChangeDepositConfirmation(e)} onBlur={e => {e.preventDefault(); onUpdateDepositInvoice(deposit_invoice.deposit_id, 'deposit_confirmation', deposit_invoice.deposit_confirmation)(this.state.deposit_confirmation);} }/></td>
            </tr>
            <tr>
              <td>

              </td>
              <td style={{ textAlign: 'left' }}>
                {(credit_card_payable === 'CARDCONNECT' || (credit_card_payable === 'STRIPE' && stripe_cc_payable))  && <LabelledCheckbox label="Allow credit card" disabled={locked} value={deposit_invoice.allow_cc_payment} onChange={onUpdateDepositInvoice(deposit_invoice.deposit_id, 'allow_cc_payment', deposit_invoice.allow_cc_payment)} />}
                {credit_card_payable === 'STRIPE' && stripe_ach_payable && <LabelledCheckbox label="Allow ACH" disabled={locked} value={deposit_invoice.allow_ach_payment} onChange={onUpdateDepositInvoice(deposit_invoice.deposit_id, 'allow_ach_payment', deposit_invoice.allow_ach_payment)} />}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <a type="tiny button" className="button" href={deposit_url} target="_blank" style={{marginRight: '5px'}}>View deposit invoice</a>
                <a type="tiny button" className="button" rel="opener" href={email_deposit_url} target="_blank">Email deposit invoice</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default DepositInvoice;
