import { map, isEmpty, pick, pickBy } from 'lodash';
import React from 'react';
import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { registerESPDisplayImpression } from '../../esp';
import { states } from '../../geo';
import { Row } from '@commonsku/styles';

const RATINGS = [
  { key: '10', value: '5 stars' },
  { key: '9', value: '4.5 stars' },
  { key: '8', value: '4 stars' },
  { key: '7', value: '3.5 stars' },
  { key: '6', value: '3 stars' },
  { key: '5', value: '2.5 stars' },
  { key: '4', value: '2 stars' },
  { key: '3', value: '1.5 stars' },
  { key: '2', value: '1 star' },
  { key: '1', value: '0.5 star' }
];

const FILTERS = [
  { dimension: 'Categories', field: 'Category' },
  { dimension: 'Colors', field: 'Color' },
  { dimension: 'Materials', field: 'Material' },
  { dimension: 'Prices', field: 'Price' },
  { dimension: 'Sizes', field: 'Size' },
  { dimension: 'Themes', field: 'Theme' },
  { dimension: 'Shapes', field: 'Shape' },
];

const SORT_OPTIONS = [
  { key: 'CSLH', value: 'Cost: Low to High' },
  { key: 'CSHL', value: 'Cost: High to Low' },
  { key: 'SPRT', value: 'Supplier Rating' },
  { key: 'PRNW', value: 'New Product' },
  { key: 'CAPR', value: 'Canadian Priced' },
  { key: 'CASP', value: 'Canadian Suppliers' },
  { key: 'PRLH', value: 'Price: Low to High' },
  { key: 'PRHL', value: 'Price: High to Low' },
  { key: 'PRCF', value: 'Confirmed Product' },
  { key: 'PVRN', value: 'Preferred Rank' }
];

const PREFERRED_RANK_OPTIONS = [
  { key: '*', value: 'All' },
  { key: 1, value: 'Rank 1' },
  { key: 2, value: 'Rank 2' },
  { key: 3, value: 'Rank 3' },
  { key: 4, value: 'Rank 4' },
  { key: 5, value: 'Rank 5' },
];

const MARKET_OPTIONS = [
  { key: 'USAALL', value: 'USA' },
  { key: 'CANALL', value: 'CAN' }
];

class EspSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    [
      'onChangeSortOption',
      'onChangeCategoryOption',
      'onChangeColorOption',
      'onChangeMaterialOption',
      'onChangePriceOption',
      'onChangeSizeOption',
      'onChangeThemeOption',
      'onChangeSupplierState',
      'onChangeShapeOption',
      'onChangeRatingOption',
      'onChangeLineNameOption',
      'onChangeDivisionOption',
      'onChangeBooleanFilter',
      'handleToggleAdvance',
      'onChangePreferredRank',
      'handleChangeProductionTime',
      'handleChangeMinimumRangeQuantity',
      'onChangeProductionTime',
      'onChangeMinimumRangeQuantity',
      'onChangeMarketOption'
    ].forEach(f => this[f] = this[f].bind(this));
  }

  getAccountDefaultMarket(identity) {
    return identity.esp_default_market && identity.esp_default_market !== '' ? identity.esp_default_market : '';
  }

  getProductSource() {
    return 'esp-products';
  }

  loadDivisions() {
    this.props.loadDivisions('esp');
  }

  onChangeSortOption(esp_sort) {
    if (this.state.previous_esp_sort && this.state.previous_esp_sort != esp_sort) {
      this.setState({ search: { ...this.state.search, esp_sort } }, () => this.handleFilterSearch(null, null, null));
    } else {
      this.setState({ search: { ...this.state.search, esp_sort } });
    }
  }

  onChangeMarketOption(esp_market) {
    if (this.state.search.esp_market && this.state.search.esp_market != esp_market) {
      this.setState({ search: { ...this.state.search, esp_market } }, () => this.handleFilterSearch(null, null, null));
    } else {
      this.setState({ search: { ...this.state.search, esp_market } });
    }
  }

  getSearchTerms(page) {
    let search_terms = {
      ...pickBy(pick(this.state.search, ['division_id', 'net_min', 'net_max', 'esp_sort']), v => v),
      keyword_sku: this.state.search.search_term,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE,
      category: this.state.selectedCategory || '',
      color: this.state.selectedColor || '',
      size: this.state.selectedSize || '',
      price: this.state.selectedPrice || '',
      material: this.state.selectedMaterial || '',
      theme: this.state.selectedTheme || '',
      supplier_state: this.state.supplier_state || '',
      shape: this.state.selectedShape || '',
      supplier_rating: this.state.selectedRating || '',
      line_name: this.state.selectedLineName || '',
      preferred: this.state.preferred || '',
      production_time: this.state.production_time || '',
      quantity: this.state.quantity || '',
      has_rush_service: this.state.has_rush_service || '',
      is_canadian_priced: this.state.is_canadian_priced || '',
      is_canadian_friendly: this.state.is_canadian_friendly || '',
      is_new: this.state.is_new || '',
      is_made_in_usa: this.state.is_made_in_usa || '',
      is_full_color_process: this.state.is_full_color_process || '',
      market_segment: this.state.search.esp_market,
    };
    if (!isEmpty(this.state.division_id)) {
      search_terms.division_id = this.state.division_id;
    }
    return search_terms;
  }

  getEndpoint() {
    return 'esp-product';
  }

  getInitialSearch(params) {
    const esp_market = this.getAccountDefaultMarket(this.props.identity);

    if (params == 'reset') {
      return {
        search_term: '',
        division_id: '',
        net_min: '',
        net_max: '',
        esp_sort: '',
        suppliers_dimensions: null,
        esp_market,
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.user_search.esp_division_id || '',
        net_min: this.props.user_search.net_min || '',
        net_max: this.props.user_search.net_max || '',
        esp_sort: '',
        esp_market,
      };
    }
  }

  onChangeCategoryOption(selectedCategory, category) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedCategoryName = dimensions.Categories ? (dimensions.Categories.filter(c => c.ContextPath == selectedCategory)[0] || {}).Name : null;

    this.setState({ selectedCategory, selectedCategoryName }, () => this.handleFilterSearch('category', category, suppliers_dimensions));
  }

  onChangeColorOption(selectedColor, color) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedColorName = dimensions.Colors ? (dimensions.Colors.filter(c => c.ContextPath == selectedColor)[0] || {}).Name : null;

    this.setState({ selectedColor, selectedColorName }, () => this.handleFilterSearch('color', color, suppliers_dimensions));
  }

  onChangeMaterialOption(selectedMaterial, material) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedMaterialName = dimensions.Materials ? (dimensions.Materials.filter(m => m.ContextPath == selectedMaterial)[0] || {}).Name : null;

    this.setState({ selectedMaterial, selectedMaterialName }, () => this.handleFilterSearch('material', material, suppliers_dimensions));
  }

  onChangePriceOption(selectedPrice, price) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedPriceName = dimensions.Prices ? (dimensions.Prices.filter(p => p.ContextPath == selectedPrice)[0] || {}).Name : null;

    this.setState({ selectedPrice, selectedPriceName }, () => this.handleFilterSearch('price', price, suppliers_dimensions));
  }

  onChangeSizeOption(selectedSize, size) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedSizeName = dimensions.Sizes ? (dimensions.Sizes.filter(c => c.ContextPath == selectedSize)[0] || {}).Name : null;

    this.setState({ selectedSize, selectedSizeName }, () => this.handleFilterSearch('size', size, suppliers_dimensions));
  }

  onChangeThemeOption(selectedTheme, theme) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedThemeName = dimensions.Themes ? (dimensions.Themes.filter(c => c.ContextPath == selectedTheme)[0] || {}).Name : null;

    this.setState({ selectedTheme, selectedThemeName }, () => this.handleFilterSearch('theme', theme, suppliers_dimensions));
  }

  onChangeSupplierState(supplier_state) {
    this.setState({ supplier_state }, () => this.handleFilterSearch('supplier_state', supplier_state, this.state.suppliers_dimensions));
  }

  onChangeShapeOption(selectedShape, shape) {
    const { dimensions, suppliers_dimensions } = this.state;
    const selectedShapeName = dimensions.Shapes ? (dimensions.Shapes.filter(c => c.ContextPath == selectedShape)[0] || {}).Name : null;

    this.setState({ selectedShape, selectedShapeName }, () => this.handleFilterSearch('shape', shape, suppliers_dimensions));
  }

  onChangeRatingOption(selectedRating) {
    this.setState({ selectedRating }, () => this.handleFilterSearch('', '', this.state.suppliers_dimensions));
  }

  onChangeLineNameOption(selectedLineName) {
    this.setState({ selectedLineName }, () => this.handleFilterSearch('', '', this.state.suppliers_dimensions));
  }

  onChangeDivisionOption(division_id) {
    const { suppliers_dimensions } = this.state;
    if (suppliers_dimensions) {
      this.setState({
        selectedCategory: null,
        selectedColor: null,
        selectedMaterial: null,
        selectedPrice: null,
        selectedSize: null,
        selectedTheme: null,
        selectedShape: null,
        selectedRating: null,
        selectedLineName: null,
        division_id
      }, () => this.handleFilterSearch('division_id', division_id, suppliers_dimensions));
    } else {
      this.setState({ division_id });
    }
  }

  onChangeBooleanFilter(e, param) {
    const { suppliers_dimensions } = this.state;
    this.setState({ [param]: e.target.checked ? 1 : 0 }, () => this.handleFilterSearch(param, this.state[param], suppliers_dimensions));
  }

  onChangePreferredRank(preferred) {
    this.setState({ preferred }, () => this.handleFilterSearch('preferred', preferred, this.state.suppliers_dimensions));
  }

  handleChangeProductionTime(e) {
    let production_time = e.target.value;
    this.setState({ production_time });
  }

  onChangeProductionTime(e) {
    let production_time = e.target.value;
    this.setState({ production_time }, () => this.handleFilterSearch('production_time', production_time, this.state.suppliers_dimensions));
  }

  handleChangeMinimumRangeQuantity(e) {
    let quantity = e.target.value;
    this.setState({ quantity });
  }

  onChangeMinimumRangeQuantity(e) {
    let quantity = e.target.value;
    this.setState({ quantity }, () => this.handleFilterSearch('quantity', quantity, this.state.suppliers_dimensions));
  }

  handleToggleAdvance() {
    this.setState((prevState) => ({ show_advance: !prevState.show_advance }));
  }

  onSearchResult(page, response, search_terms, field, options) {
    const esp_account_id = response.account_details ? response.account_details.Id : null;
    const products = response.products.map(p => ({ ...p, esp_account_id }));
    super.onSearchResult(page, { ...response, products }, search_terms, field, options);
    response.products.forEach(product => registerESPDisplayImpression(product.product_id, product.esp_ad_id, product.esp_ad_index, esp_account_id));
  }

  renderEspFilter() {
    if (!this.state.dimensions) {
      return null;
    }

    const { dimensions } = this.state;

    const getOptions = (dimension, field) => {
      const base_options = ((dimensions || {})[dimension] || []).map(o => ({ key: o.ContextPath, value: `(${o.Products}) ${o.Name}` }));
      const selected_key = this.state['selected' + field];
      return selected_key ?
        [{ key: selected_key, value: this.state[`selected${field}Name`] }].concat(base_options) :
        base_options.length ?
          base_options : null;
    };

    return FILTERS.map((f, idx) => {
      const options = getOptions(f.dimension, f.field);
      const state_value = this.state[f.field.toLowerCase()];
      if (options || state_value) {
        const onChange = value => {
          this['onChange' + f.field + 'Option'](value, options);
        };
        return (
          <div key={idx} style={{ position: 'relative' }} className="medium-4 large-2 columns">
            <Select
              value={this.state['selected' + f.field]}
              options={options || state_value}
              change={onChange}
              placeholder={f.field}
              clearable={true}
            />
          </div>
        );
      }
    }).concat(
      <div key={FILTERS.length} style={{ position: 'relative' }} className="medium-4 large-2 columns">
        <Select
          value={this.state.selectedRating || ''}
          clearable={true}
          options={RATINGS}
          change={this.onChangeRatingOption}
          placeholder="Rating"
        />
      </div>,
      <div key={FILTERS.length + 1} style={{ position: 'relative' }} className="medium-4 large-2 columns">
        <Select
          value={this.state.selectedLineName || ''}
          clearable={true}
          options={map(dimensions.LineNames, (v) => {
            return { key: v, value: v };
          }) || []}
          change={this.onChangeLineNameOption}
          placeholder="Line Name"
        />
      </div>
    );
  }

  render() {
    const { company_type, divisions, style } = this.props;
    const {
      has_rush_service,
      is_canadian_friendly,
      is_canadian_priced,
      is_full_color_process,
      is_made_in_usa,
      is_new,
      preferred,
      supplier_state,
      production_time,
      quantity,
      search,
      show_advance,
      suppliers_dimensions
    } = this.state;
    const division_id = this.state.division_id || search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);
    const hidden = {
      display: 'none'
    };
    const base_states = [...states['United States'], ...states['Canada']];
    const state_options = base_states.map(s => ({ key: s.abbr, value: s.name }));

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-4 large-2 columns">
            <input
              type="text"
              placeholder="Search term"
              onChange={e => this.handleChangeSearchTerm(e, 'esp')}
              value={search.search_term}
              onKeyDown={this.handlePressEnter}
            />
          </div>
          <div className="medium-4 large-2 columns">
            {(suppliers_dimensions || !(division_id || search.division_id) || divisions) && <Select
              id="division"
              value={division_id || ""}
              searchable={true}
              clearable={'SUPPLIER' !== company_type}
              options={suppliers_dimensions ? [{ key: '', value: 'Suppliers' }].concat(suppliers_dimensions) : divisions}
              change={this.onChangeDivisionOption}
              placeholder="Supplier"
            />}
          </div>
          <div className="medium-12 large-2 columns">
            <div className="row">
              <div className="medium-6 columns">
                <input
                  type="text"
                  placeholder="Net min"
                  onChange={this.handleChangeNetMin}
                  value={search.net_min}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
              <div className="medium-6 columns">
                <input
                  type="text"
                  placeholder="Net max"
                  onChange={this.handleChangeNetMax}
                  value={search.net_max}
                  onKeyDown={this.handlePressEnter}
                />
              </div>
            </div>
          </div>
          <div className="medium-4 large-1 columns">
            <Select
              value={search.esp_market}
              options={MARKET_OPTIONS}
              change={this.onChangeMarketOption}
              placeholder="Market"
            />
          </div>
          <div className="medium-4 large-2 columns">
            <Select
              value={search.esp_sort}
              clearable={true}
              options={SORT_OPTIONS}
              change={this.onChangeSortOption}
              placeholder="Sort"
            />
          </div>
          <div className="medium-8 large-3 columns">
            <div className="expanded button-group">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
              <a className="button" onClick={this.handleToggleAdvance}>
                {show_advance ? <span>&#9650;</span> : <span>&#9660;</span>}
              </a>
            </div>
          </div>
          <div className="column" style={!show_advance ? hidden : { 'padding': 0 }}>
            <div style={{ position: 'relative' }} className="medium-4 large-2 columns">
              <Select
                id="preferred"
                value={preferred}
                clearable={true}
                options={PREFERRED_RANK_OPTIONS}
                change={this.onChangePreferredRank}
                placeholder="Preferred"
              />
            </div>
            <div style={{ position: 'relative' }} className="medium-4 large-2 columns">
              <input
                type="text"
                value={production_time}
                clearable="clearable"
                onChange={this.handleChangeProductionTime}
                //onBlur={this.onChangeProductionTime}
                placeholder="Prod. Time (days)"
              />
            </div>
            <div style={{ position: 'relative' }} className="medium-4 large-2 columns">
              <input
                type="text"
                value={quantity}
                clearable="clearable"
                onChange={this.handleChangeMinimumRangeQuantity}
                //onBlur={this.onChangeMinimumRangeQuantity}
                placeholder="Min. Quantity"
              />
            </div>
            <div style={{ position: 'relative' }} className="medium-4 large-2 columns">
              <Select
                id="supplier_state"
                value={supplier_state}
                clearable={true}
                options={state_options}
                change={this.onChangeSupplierState}
                placeholder="Supplier State/Province"
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>Has Rush Service</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="has_rush_service"
                checked={has_rush_service}
                onChange={e => this.onChangeBooleanFilter(e, 'has_rush_service')}
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>New</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="is_new"
                checked={is_new}
                onChange={e => this.onChangeBooleanFilter(e, 'is_new')}
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>Full Color Process</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="is_full_color_process"
                checked={is_full_color_process}
                onChange={e => this.onChangeBooleanFilter(e, 'is_full_color_process')}
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>Made in USA</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="is_made_in_usa"
                checked={is_made_in_usa}
                onChange={e => this.onChangeBooleanFilter(e, 'is_made_in_usa')}
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center', float: 'right' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>Canadian Priced</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="is_canadian_priced"
                checked={is_canadian_priced}
                onChange={e => this.onChangeBooleanFilter(e, 'is_canadian_priced')}
              />
            </div>
            <div style={{ position: 'relative', padding: 0, textAlign: 'center' }} className="medium-2 large-2 columns">
              <label style={{ display: 'inline-block' }}>Canadian Friendly</label>
              <input
                style={{ display: 'inline-block', marginLeft: '5px', marginTop: '5px' }}
                type="checkbox"
                name="is_canadian_friendly"
                checked={is_canadian_friendly}
                onChange={e => this.onChangeBooleanFilter(e, 'is_canadian_friendly')}
              />
            </div>
          </div>
          {this.renderEspFilter()}
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('esp')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default EspSearchFilter;
