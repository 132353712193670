const messages = {
  'bill.supplier_id': 'Unable to create bill.  Please contact commonsku support.',
  'bill.bill_reference_number': 'Pleas provide a supplier invoice number.',
  'bill.bill_date_due': 'Please provide a due date.',
  'bill.bill_date_billdate': 'Please provide an invoice date.',
  'bill.bill_exists': 'A bill with the given invoice number has already been created for this supplier.',
  'bill.empty_bill': 'Please include at least one item on the bill.',
  'bill.purchase_order_billed': '',
  'bill.invalid_order': 'A sales order referenced in your extra bill items does not exist.',
  'bill.invalid_po': 'Attempted to associate bill with a PO that does not exist. Please contact support.'
};

const default_error_message = 'An error has occurred.';

const getErrorMessage = key => messages[key] || default_error_message;

export default getErrorMessage;
