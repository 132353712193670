import _ from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from '@commonsku/styles';

import { createNotifyShippingChangesPopup, createSubmitPOPopup } from '../../actions/popup';

import PS from '../PromostandardsLabel';

export default function ActionMenu({ selectedTab, order_id, purchase_order_id }) {
  const dispatch = useDispatch();

  const purchase_order = useSelector(state => {
	  if (!state.entities.purchase_orders[purchase_order_id]) {
	  	console.log(purchase_order_id, state);
	  }
    return state.entities.purchase_orders[purchase_order_id];
  }) || {};
  const defaultMenuOptions = [
    {
      onClick: () => window.open(`/project.php?id=${purchase_order.job_id}`, '_blank'),
      content: 'View Project',
      active: purchase_order.job_id,
    },
    {
      onClick: () => window.open(`/vendor.php?id=${purchase_order.supplier_id}&division_id=${purchase_order.division_id}`, '_blank'),
      content: 'View Supplier',
      active: purchase_order.supplier_id && purchase_order.division_id,
    },
    {
      onClick: () => window.open(`/send_email.php?parent_type=purchase_order&parent_id=${purchase_order_id}`, '_blank'),
      content: 'Email supplier',
      active: !purchase_order.ext_po_id && 0 === Number(purchase_order.can_send_electronically)
    },
    {
      onClick: () => dispatch(createSubmitPOPopup(purchase_order_id)),
      content: <><PS /><span>Send ePO to supplier</span></>,
      active: !purchase_order.ext_po_id && 1 === Number(purchase_order.can_send_electronically)
    },
    {
      onClick: () => window.open(`/send_email.php?parent_type=order&parent_id=${order_id}&source_id=${purchase_order_id}`, '_blank'),
      content: 'Email SO',
      active: true
    },
    {
      onClick: () => dispatch(createNotifyShippingChangesPopup(purchase_order_id)),
      content: 'Notify shipping changes',
      active: true
    }
  ].filter(o => o.active);
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);

  useEffect(() => {
    if(selectedTab === 'proof') {
      const newMenuOptions = defaultMenuOptions.concat([
        {
          onClick: () => window.open(`/public_proof_approval.php?order_id=${order_id}`, '_blank'),
          content: 'Preview Proof Approval'
        },
        {
          onClick: () => window.open(`/send_email.php?parent_type=proof-approval&parent_id=${order_id}`, '_blank'),
          content: 'Email Proof'
        }
      ]);
      setMenuOptions(newMenuOptions);
    }else{
      setMenuOptions(defaultMenuOptions);
    }
  }, [selectedTab, purchase_order_id]);

  return (
    <Dropdown sm={'width: 100%; margin-top: 10px;'} style={{marginRight: '10px', zIndex: 105}} items={menuOptions} />
  );
}
