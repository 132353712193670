import { oauth } from '../utils';

export const LOAD_CLIENT_LIST_REQUEST = 'LOAD_CLIENT_LIST_REQUEST';
export const LOAD_CLIENT_LIST_SUCCESS = 'LOAD_CLIENT_LIST_SUCCESS';
export const LOAD_CLIENT_LIST_FAILURE = 'LOAD_CLIENT_LIST_FAILURE';

export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const UPDATE_CLIENT_PHONE_REQUEST = 'UPDATE_CLIENT_PHONE_REQUEST';
export const UPDATE_CLIENT_PHONE_SUCCESS = 'UPDATE_CLIENT_PHONE_SUCCESS';
export const UPDATE_CLIENT_PHONE_FAILURE = 'UPDATE_CLIENT_PHONE_FAILURE';

export const createFinalizeLoadClientList = clients => ({
  type: LOAD_CLIENT_LIST_SUCCESS,
  payload: {
    clients
  }
});

export const createLoadClientList = (params={}) => dispatch => {
  return oauth('GET', 'client', { ...params, parent_list: true }).then(
    ({ json }) => dispatch(createFinalizeLoadClientList(json.clients))
  );
};

const createFinalizeAddClient = client => ({
  type: ADD_CLIENT_SUCCESS,
  payload: {
    client
  }
});

export const createAddClient = (client_name, industry_id, sales_rep_id) => (dispatch, getState) => {
  return oauth('POST', 'account', { company_type: 'CLIENT', company_name: client_name, industry_id, sales_rep_id }).then(
    ({ json }) => dispatch(createFinalizeAddClient(json.account))
  );
};

const createFinalizeGetClient = client => ({
  type: GET_CLIENT_SUCCESS,
  payload: {
    client
  }
});

export const createGetClient = (client_id, params={}) => dispatch => {
  return oauth('GET', 'client', { id: client_id, ...params }).then(
    ({ json }) => dispatch(createFinalizeGetClient({
      ...json.client,
      client_tags: json.client.client_tags
                    && !Array.isArray(json.client.client_tags)
                    && json.client.client_tags.length
                      ? json.client.client_tags.split(',')
                      : Array.isArray(json.client.client_tags)
                      ? json.client.client_tags
                      : [],
    }))
  );
};

const createUpdateClientRequest = () => ({
  type: UPDATE_CLIENT_REQUEST,
  payload: {}
});

const createFinalizeUpdateClient = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: {
    client
  }
});

const createFinalizeUpdatePhone = phone => ({
  type: UPDATE_CLIENT_PHONE_SUCCESS,
  payload: {
    phone
  }
});

export const createUpdateClient = (client_id, data={}) => (dispatch, getState) => {
  dispatch(createUpdateClientRequest);

  if (data.update_remote === false) {
    return dispatch(createFinalizeUpdateClient(data));
  }

  if (data.client_tags && data.client_tags.length) {
    data.client_tags = [...(getState.client_tags || []), ...(data.client_tags || []), ];
  }

  const params = { id: client_id, ...data };
  if (data.client_tags) { params.client_tags = data.client_tags.join(','); }
  delete params.update_remote;

  return oauth('PUT', 'client', params).then(
    ({ json }) => dispatch(createFinalizeUpdateClient({
      ...json.client,
      client_tags: json.client.client_tags
                    && !Array.isArray(json.client.client_tags)
                    && json.client.client_tags.length
                      ? json.client.client_tags.split(',')
                      : Array.isArray(json.client.client_tags)
                      ? json.client.client_tags
                      : [],
    }))
  );
};

export const createUpdateClientPhone = (phone_id, phone_type, phone_number, phone_extension, active=1) => (dispatch, getState) =>{
  const data = {
    id: phone_id,
    phone_type,
    phone_number,
    phone_extension,
    active,
  };

  return oauth('PUT', 'phone', data).then(
    ({ json }) => {
      dispatch(createFinalizeUpdatePhone(json.phone));
    },
  );
};

export const createAddClientPhone = (client_id, created_by, phone_type, phone_number, phone_extension) => (dispatch, getState) => {
  const data = {
    parent_type: 'CLIENT',
    parent_id: client_id,
    created_by,
    phone_type,
    phone_number,
    phone_extension,
  };

  return oauth('POST', 'phone', data).then(
    ({ json }) => {
      dispatch(createFinalizeUpdatePhone(json.phone));
    },
  );
};

export const createUpdateClientAccount = (client_id, data={}) => (dispatch, getState) => {
  dispatch(createUpdateClientRequest);

  if (data.update_remote === false) {
    delete data.update_remote;
    return dispatch(createFinalizeUpdateClient(data));
  }

  data = {...data};
  if (data.client_tags && Array.isArray(data.client_tags) && data.client_tags.length) {
    data.client_tags = [...(getState.client_tags || []), ...(data.client_tags || []), ];
  }

  if (data.client_tenant_account_number) {
    data.account_number = data.client_tenant_account_number;
  }
  if (data.client_name) {
    data.company_name = data.client_name;
  }

  if (data.client_order_margin_minimum) {
    data.account_order_margin_minimum = data.client_order_margin_minimum;
  }

  if (data.client_profile) {
    data.account_profile = data.client_profile;
  }

  if (data.parent_client_id) {
    data.parent_account_id = data.parent_client_id;
  }

  const params = { id: client_id, ...data };

  if (data.client_tags && Array.isArray(data.client_tags)) {
    params.client_tags = data.client_tags.join(',');
    params.account_tags = data.client_tags.join(',');
  } else if (Array.isArray(data.client_tags) && data.client_tags.length === 0) {
    params.client_tags = '';
    params.account_tags = '';
  }
  delete params.update_remote;

  return oauth('PUT', `account/${client_id}`, params).then(
    ({ json }) => dispatch(createFinalizeUpdateClient({
      ...json.account,
      client_tags: json.account.client_tags
                    && !Array.isArray(json.account.client_tags)
                    && json.account.client_tags.length
                      ? json.account.client_tags.split(',')
                      : Array.isArray(json.account.client_tags)
                      ? json.account.client_tags
                      : [],
    }))
  );
};
