import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State, updateProductQuestionsData, updateProductQuestionsError } from "../productQuestions";
import { TNumber } from "../../interfaces/common";

type Store = {temp: {productQuestions: State}};

export const saveProductQuestionsToLocalstorage = (
  shop_id: string,
  state: Partial<State>
) => {
  localStorage.setItem(
    `shop.${shop_id}.productQuestions`,
    JSON.stringify({ ...state, data: state.data || {} })
  );
};

export const initializeProductQuestions = (
  shop_id: string,
  item_id: string
) => {
  const localCartString = localStorage.getItem(
    `shop.${shop_id}.productQuestions`
  );
  const localCartState = localCartString ? JSON.parse(localCartString) : {};

  let avaliableQuestion = {};
  if (localCartString && localCartState.data) {
    const parent_id = Object.keys(localCartState.data).find(
      (parent_id) => item_id === parent_id
    );

    if (parent_id) {
      avaliableQuestion[parent_id] = localCartState.data[parent_id];
    } else {
      Object.keys(localCartState.data).forEach((parent_id) => {
        if (parent_id !== item_id) {
          removeItemFromProductQuestionsLocalStorage(shop_id, parent_id);
        }
      });
    }
  }

  return avaliableQuestion;
};

export const cleanProductQuestionsFromLocalStorage = (shop_id: string) => {
  if (!shop_id) {
    return;
  }
  localStorage.removeItem(`shop.${shop_id}.productQuestions`);
};

export const removeItemFromProductQuestionsLocalStorage = (
  shop_id: string,
  item_id: string
) => {
  const currentLocalState = JSON.parse(
    localStorage.getItem(`shop.${shop_id}.productQuestions`)
  );

  if (
    !currentLocalState &&
    !currentLocalState?.data &&
    !currentLocalState?.data[item_id]
  ) {
    return;
  }

  const updatedLocalState = {
    data: Object.keys(currentLocalState.data)
      .filter((id) => id !== item_id)
      .reduce((acc, id) => {
        acc[id] = currentLocalState.data[id];
        return acc;
      }, {}),
  };

  if (Object.keys(updatedLocalState.data).length === 0) {
    cleanProductQuestionsFromLocalStorage(shop_id);
  } else {
    saveProductQuestionsToLocalstorage(shop_id, updatedLocalState);
  }
};

export const selectState = (s: Store) => s.temp?.productQuestions || { data: {}, error: {} };
const selectStateData = (s: Store) => s.temp?.productQuestions?.data || {};
const selectStateError = (s: Store) => s.temp?.productQuestions?.error || {};

export const useProductQuestionsState = () => {
  const value = useSelector<Store, State>(selectState);
  return value;
};

export const useProductQuestionsData = () => {
  const value = useSelector<Store, State['data']>(selectStateData);
  return value;
};

export const useProductQuestionsError = () => {
  const value = useSelector<Store, State['error']>(selectStateError);
  return value;
};

export const useUpdateProductQuestionsError = () => {
  const dispatch = useDispatch();
  const onUpdate = useCallback(
    (metadata_id: string | null, errorMessage?: string) => {
      dispatch(updateProductQuestionsError({ metadata_id, errorMessage }));
    },
    []
  );

  return onUpdate;
};

export const useUpdateProductQuestion = () => {
  const dispatch = useDispatch();

  const onUpdate = useCallback(
    (
      metadata_id: string,
      label: string,
      value: string,
      parent_id: string,
      type: string,
      mandatory: TNumber,
      options: {
        key: string;
        value: string;
      } | null = null,
      shop_id: string
    ) => {
      dispatch(updateProductQuestionsData({
        metadata_id,
        label,
        value,
        parent_id,
        type,
        mandatory,
        options,
        shop_id,
      }));
    },
    []
  );

  return onUpdate;
};

