import React from 'react';

import { formatMoney, getImageSrc } from '../utils';
import { BaseProductItemSummary, INVENTORY_BADGE_STYLE } from './ProductItemSummary';
import { ItemSummaryTaxTd } from './BaseItemSummary';
import { withDraggableItem } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import Img from './Img';

const ProductItemBreakdownRow = ({
  item,
  breakdown,
  handleMouseOver,
  handleMouseOut,
  actions
}) => {
  const size = item.sizes.filter(s => s.size_id === breakdown.size_id)[0];
  const color = item.colors.filter(s => s.color_id === breakdown.color_id)[0];
  const sku = item.skus.filter(s => s.product_sku_id === breakdown.product_sku_id)[0];
  const size_name = size ? size.size_name : 'TBD';
  const color_name = color ? color.color_name : 'TBD';
  const first_row = breakdown.breakdown_id === item.breakdowns[0].breakdown_id;

  const client_price = parseFloat(breakdown.unit_price) + parseFloat(item.hidden_costs);
  const total_subtotal = client_price * parseFloat(breakdown.quantity);

  return (
    <tr>
      {first_row && <ProductItemDescription item={item} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} actions={actions} />}
      <td className="title" >{sku ? sku.sku_description : `${color_name}/${size_name}`}</td>
      <td className="quantity">{parseInt(breakdown.quantity, 10)}</td>
      <td className="amount">${formatMoney(client_price)}</td>
      <td className="margin">&nbsp;</td>
      <td className="tax"></td>
      <td className="amount">${formatMoney(total_subtotal)}</td>
    </tr>
  );
};

const ProductItemDescription = ({
  item,
  handleMouseOver,
  handleMouseOut,
  actions
}) => {
  const product_url = `/product.php?id=${item.parent_id}`;
  const vendor_url = `/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`;
  const phone = (item.division_phones || [])[0] ? item.division_phones[0] : null;
  const default_image = item.item_images.map(ii => ii.image)[0];

  return (
    <td className="description" rowSpan={item.breakdowns.length + item.item_costs.length + item.item_locations.length + 3}>
      <div className="image">
        <Img src={getImageSrc(default_image)} onMouseOver={handleMouseOver('item')} onMouseOut={handleMouseOut('item')} />
      </div>
      <a className="product-sku" href={product_url} target="_blank" onClick={e => e.stopPropagation()}>{item.item_sku}</a>
      {item.hasInventoryBreakdowns && <span style={INVENTORY_BADGE_STYLE}>Inventory</span>}
      <div className="product-vendor-container"><a className="product-vendor" href={vendor_url} target="_blank" onClick={e => e.stopPropagation()}>{item.division_name}</a>{phone && phone.phone_number ? ` (${phone.phone_number}${phone.phone_extension ? ' x' + phone.phone_extension : ''})` : null}</div>
      <span className="product-name">{item.item_name}</span>
      {actions}
      <div>{item.item_description}</div>
    </td>
  );
};

const ProductItemCostRow = ({item, item_cost}) => {
  const quantity = (item_cost.quantity === null) ? parseInt(item.total_units, 10) : parseInt(item_cost.quantity, 10);
  return (
    <tr>
      <td className="title">{item_cost.item_cost_title}</td>
      <td className="quantity">{quantity}</td>
      <td className="amount">${formatMoney(item_cost.unit_price)}</td>
      <td className="margin">&nbsp;</td>
      <td className="tax"></td>
      <td className="amount">${formatMoney(item_cost.total_price)}</td>
    </tr>
  );
};

const ProductItemLocationRow = ({item, item_location}) => {
  return (
    <tr>
      <td colSpan="6"><i>{item_location.item_location_title.replace(/:: /, ': ')}</i></td>
    </tr>
  );
};

const ProductItemTotalUnitsRow = ({ item }) => (
  <tr className="total-units">
    <td className="title">Total Units</td>
    <td className="quantity">{parseInt(item.total_units, 10)}</td>
    <td className="amount"></td>
    <td className="margin"></td>
    <td className="tax"></td>
    <td className="amount"></td>
  </tr>
);

const ProductItemTotalBreakdownRow = ({ item }) => (
  <tr className="total-breakdown">
    <td className="title">Total ({item.currency_id})</td>
    <td className="quantity"></td>
    <td className="amount"></td>
    <td className="margin">{parseFloat(item.total_margin, 10).toFixed(2)}%</td>
    <ItemSummaryTaxTd item={item}/>
    <td className="amount">${formatMoney(item.total_subtotal)}</td>
  </tr>
);

class ProductItemDetailedSummary extends BaseProductItemSummary {

  constructor(props) {
    super(props);
  }

  renderLocation(il) {
    const { item } = this.props;
    return [
      <ProductItemLocationRow key={il.item_location_id} item={item} item_location={il} />
    ].concat(item.item_costs.filter(ic => ic.item_location_id === il.item_location_id && ic.hidden == 0).map(ic =>
      <ProductItemCostRow key={il.item_location_id + ic.item_cost_id} item={item} item_cost={ic} />
    ));
  }

  renderItem() {
    const { item } = this.props;

    let breakdown_props = {
      item,
      handleMouseOver: this.handleMouseOver,
      handleMouseOut: this.handleMouseOut,
      actions: this.renderActions()
    };

    return (
      0 === item.breakdowns.length ?
      [<tr key={item.item_id}><ProductItemDescription {...breakdown_props} /><td colSpan={6}>&nbsp;</td></tr>] :
      []
    ).concat(item.breakdowns.map(b =>
      <ProductItemBreakdownRow key={b.breakdown_id} {...breakdown_props} breakdown={b} />
    )).concat(
      <ProductItemTotalUnitsRow key="total_units" item={item} />
    ).concat(item.item_costs.filter(ic => ic.item_location_id === null && ic.hidden == 0).map(ic =>
      <ProductItemCostRow key={ic.item_cost_id} {...breakdown_props} item_cost={ic} />
    )).concat(item.item_locations.map(il =>
      this.renderLocation(il)
    )).concat(
      <ProductItemTotalBreakdownRow key="total" item={item} />
    ).concat(
      <tr key="filler" style={{ height: '100%' }}><td colSpan={7}>&nbsp;</td></tr>
    );
  }
}

export default withDraggableItem(DragItemTypes.ITEM, ProductItemDetailedSummary);
