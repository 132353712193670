import React from 'react';

const DEFAULT_COLOR = 'var(--color-neutrals-90)';
type TypographyProps = React.PropsWithChildren<{
  style?: React.CSSProperties;
  color?: string;
  noBottom?: boolean;
}>;

export const H1 = ({ style={}, color=DEFAULT_COLOR, noBottom=false, ...props }: TypographyProps) => (
  <h1
    style={{
      fontSize: '34px',
      fontFamily: 'var(--skufont-demibold)',
      color: color,
      ...(noBottom ? { marginBottom: '0px' } : {}),
      ...style
    }}
    {...props}
  />
);

export const H2 = ({ style={}, color=DEFAULT_COLOR, noBottom=false, ...props }: TypographyProps) => (
  <h1
    style={{
      fontSize: '28px',
      fontFamily: 'var(--skufont-demibold)',
      color: color,
      ...(noBottom ? { marginBottom: '0px' } : {}),
      ...style
    }}
    {...props}
  />
);

export const H5 = ({ style={}, color=DEFAULT_COLOR, noBottom=false, ...props }: TypographyProps) => (
  <h5
    style={{
      fontSize: '20px',
      fontFamily: 'var(--skufont-demibold)',
      color: color,
      ...(noBottom ? { marginBottom: '0px' } : {}),
      ...style
    }}
    {...props}
  />
);

type TextProps = TypographyProps & {
  bold?: boolean;
};

export const Text = ({ style={}, bold=false, color=DEFAULT_COLOR, noBottom=false, ...props }: TextProps) => (
  <p
    style={{
      fontFamily: bold ? 'var(--skufont-bold)' : 'var(--skufont-regular)',
      color: color,
      ...(noBottom ? { marginBottom: '0px' } : {}),
      ...style
    }}
    {...props}
  />
);

export const Span = ({ style={}, bold=false, color=DEFAULT_COLOR, noBottom=false, ...props }: TextProps) => (
  <span
    style={{
      fontFamily: bold ? 'var(--skufont-bold)' : 'var(--skufont-regular)',
      color: color,
      ...(noBottom ? { marginBottom: '0px' } : {}),
      ...style
    }}
    {...props}
  />
);

