import {
  createSlice,
} from '@reduxjs/toolkit';

import { oauth } from '../utils';

const slice = createSlice({
  name: 'psst_addresses',
  initialState: {
    data: [],
    loading: 'idle',
  },
  reducers: {
    loading(state) {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    received(state, action) {
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    },
  }
});

export const {
  loading,
  received,
} = slice.actions;

const baseUrl = 'address';

/**
* The action (thunk)
*/
export const getPsstAddresses = () => async (dispatch) => {
  dispatch(loading());
  const res = await oauth('GET', `${baseUrl}/psst`, {});
  dispatch(received(res.json.psst_addresses));
  return res.json;
};

export default slice.reducer;
