import React from 'react';

import { Link, colors } from '@commonsku/styles';

const CommunityEventLink = ({ event }) => {
  return <a target="_blank" rel="noopener noreferrer" style={{ color: colors.white }}
    href={`/community_events.php?community_event_id=${event.community_event_id}`}
  >
      <Link style={{ fontWeight: 'bold' }}>{event.name}</Link>
  </a>;
};

export default CommunityEventLink;