import { ADD_DEPOSIT_INVOICE_SUCCESS, DELETE_DEPOSIT_INVOICE_SUCCESS, UPDATE_DEPOSIT_INVOICE_REQUEST, UPDATE_DEPOSIT_INVOICE_SUCCESS, UPDATE_DEPOSIT_INVOICE_FAILURE } from '../actions/deposit_invoice';

const reduce = (state = {}, action) => {
  let deposit_id, new_deposit;
  let updated_deposit;
  switch (action.type) {
    case ADD_DEPOSIT_INVOICE_SUCCESS:
      deposit_id = action.payload.id;
      new_deposit = Object.assign({}, state[deposit_id], action.payload.data.deposit);
      return Object.assign({}, state, {[deposit_id]: new_deposit});
    case DELETE_DEPOSIT_INVOICE_SUCCESS:
      return Object.values(state).filter(d => d.deposit_id !== action.payload.id).reduce((o, d) => { o[d.deposit_id] = d; return o; }, {});
    case UPDATE_DEPOSIT_INVOICE_REQUEST:
      // fall through
    case UPDATE_DEPOSIT_INVOICE_SUCCESS:
      deposit_id = action.payload.id;
      updated_deposit = Object.assign({}, state[deposit_id], action.payload.data.deposit);
      return Object.assign({}, state, {[deposit_id]: updated_deposit});
    case UPDATE_DEPOSIT_INVOICE_FAILURE:
      deposit_id = action.payload.id;
      updated_deposit = Object.assign({}, state[deposit_id], action.payload.data);
      return Object.assign({}, state, {[deposit_id]: updated_deposit});
  }
  return state;
};

export default reduce;
