import { map } from 'lodash';
import { useLayoutEffect, useRef, useState } from 'react';

export const useSize = () => {
  const ref = useRef(null);
  const [size, setSize] = useState({});

  useLayoutEffect(() => {
    if (ref.current) {
      try {
        const resizeObserver = new ResizeObserver((entries) => {
          map(entries, (e) => {
            const { offsetWidth, offsetHeight }  = e.target || {};
            setSize({ offsetWidth, offsetHeight });
          });
        });
        const observed = ref.current;
        resizeObserver.observe(observed);
        return () => {
          resizeObserver.unobserve(observed);
        };
      } catch (error) {
        // for browser that doesn't support ResizeObserver
        setTimeout(() => {
          const { offsetWidth, offsetHeight }  = ref.current || {};
          setSize({ offsetWidth, offsetHeight });
        }, 5);
      }
    }
  }, []);

  return [ref, size];
};
