import React from 'react';
import Icon from './Icon';

const MenuItem = ({
  editor,
  item_type,
  groupId,
  setLink,
}) => {
  if (!editor || !item_type) {
    return null;
  }

  switch (item_type) {
    case 'bold':
      return (
        <Icon
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          isActive={editor.isActive('bold')}
          className={`${groupId} ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          bold
        </Icon>
      );
      case 'italic':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleItalic()
                .run()
            }
            isActive={editor.isActive('italic')}
            className={`${groupId} ${editor.isActive('italic') ? 'is-active' : ''}`}
          >
            italic
          </Icon>
        );
      case 'strike':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleStrike()
                .run()
            }
            isActive={editor.isActive('strike')}
            className={`${groupId} ${editor.isActive('strike') ? 'is-active' : ''}`}
          >
            strike
          </Icon>
        );
      case 'code':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleCode().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleCode()
                .run()
            }
            isActive={editor.isActive('code')}
            className={`${groupId} ${editor.isActive('code') ? 'is-active' : ''}`}
          >
            code
          </Icon>
        );
      case 'clear nodes':
        return (
          <Icon onClick={() => editor.chain().focus().clearNodes().run()} className={groupId}>
            clear nodes
          </Icon>
        );
      case 'paragraph':
        return (
          <Icon
            onClick={() => editor.chain().focus().setParagraph().run()}
            isActive={editor.isActive('paragraph')}
            className={`${groupId} ${editor.isActive('paragraph') ? 'is-active' : ''}`}
          >
            paragraph
          </Icon>
        );
      case 'h1':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            isActive={editor.isActive('heading', { level: 1 })}
            className={`${groupId} ${editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}`}
          >
            h1
          </Icon>
        );
      case 'h2':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            isActive={editor.isActive('heading', { level: 2 })}
            className={`${groupId} ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}`}
          >
            h2
          </Icon>
        );
      case 'format_align_left':
        return (
          <Icon
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            isActive={editor.isActive({ textAlign: 'left' })}
            className={`${groupId} ${editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}`}
          >
            format_align_left
          </Icon>
        );
      case 'format_align_center':
        return (
          <Icon
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            isActive={editor.isActive({ textAlign: 'center' })}
            className={`${groupId} ${editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}`}
          >
            format_align_center
          </Icon>
        );
      case 'format_align_right':
        return (
          <Icon
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            isActive={editor.isActive({ textAlign: 'right' })}
            className={`${groupId} ${editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}`}
          >
            format_align_right
          </Icon>
        );
      case 'format_align_justify':
        return (
          <Icon
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            isActive={editor.isActive({ textAlign: 'justify' })}
            className={`${groupId} ${editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}`}
          >
            format_align_justify
          </Icon>
        );
      case 'bullet list':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
            className={`${groupId} ${editor.isActive('bulletList') ? 'is-active' : ''}`}
          >
            bullet list
          </Icon>
        );
      case 'ordered list':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
            className={`${groupId} ${editor.isActive('orderedList') ? 'is-active' : ''}`}
          >
            ordered list
          </Icon>
        );
      case 'code block':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            isActive={editor.isActive('codeBlock')}
            className={`${groupId} ${editor.isActive('codeBlock') ? 'is-active' : ''}`}
          >
            code block
          </Icon>
        );
      case 'blockquote':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive('blockquote')}
            className={`${groupId} ${editor.isActive('blockquote') ? 'is-active' : ''}`}
          >
            blockquote
          </Icon>
        );
      case 'horizontal rule':
        return (
          <Icon onClick={() => editor.chain().focus().setHorizontalRule().run()} className={groupId}>
            horizontal rule
          </Icon>
        );
      case 'link':
        return (
          <Icon
            onClick={setLink}
            isActive={editor.isActive('link')}
            className={`${groupId} ${editor.isActive('link') ? 'is-active' : ''}`}
          >
            link
          </Icon>
        );
      case 'link_off':
        return (
          <Icon
            onClick={() => editor.chain().focus().unsetLink().run()}
            className={groupId}
            disabled={!editor.isActive('link')}
          >
            link_off
          </Icon>
        );
      case 'underline':
        return (
          <Icon
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive('underline')}
            className={`${groupId} ${editor.isActive('underline') ? 'is-active' : ''}`}
          >
            underline
          </Icon>
        );
    default:
      return null;
  }
};

export default MenuItem;
