import React from "react";
import PromostandardsLocationMenu from "./PromostandardsLocationMenu";
import NonPromostandardsLocationMenu from "./NonPromostandardsLocationMenu";

const LocationDropdown = ({
  isPS,
  item_id,
  locked,
  is3rdPartyDecorator,
  locations,
  division_id,
  onOptionClick,
  children,
  onClickNewDecoration,
  onClickCopySameOrder,
  popupTitle,
  ...props
}) => {
  const options = [
    {
      key: "new-decoration",
      value: "Add new decoration location",
      hideOnClick: true,
      onClick: (e) => {
        e.preventDefault();
        onClickNewDecoration();
      },
    },
    {
      key: "copy-same-order",
      value: "Copy from another product on this order",
      hideOnClick: true,
      onClick: (e) => {
        e.preventDefault();
        onClickCopySameOrder();
      },
    },
  ];

  return isPS && !JSON.parse(is3rdPartyDecorator) ? (
    <PromostandardsLocationMenu
      locations={locations}
      locked={locked}
      item_id={item_id}
      division_id={division_id}
      onOptionClick={onOptionClick}
      popupTitle={popupTitle}
      children={children}
      extraOptions={options}
      {...props}
    />
  ) : (
    <NonPromostandardsLocationMenu
      children={children}
      options={options}
      {...props}
    />
  );
};

export default LocationDropdown;
