import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { formatMoney } from '../utils';
import BaseItemSummary, { ItemSummaryTaxTd } from './BaseItemSummary';
import DragItemTypes from '../DragItemTypes';
import { withDraggableItem } from './DraggableItem';

class ServiceItemSummary extends BaseItemSummary {

  constructor(props) {
    super(props);
  }

  renderActions() {
    const { hasCapability } = this.props;

    return <span className="actions">
        {hasCapability('MODIFY-COMMISSION') ?
          this.renderToggleAction('exclude_from_margin', 'exclude from margin', 'include in margin') :
          null
        }
      {this.renderEditAction()}
      {this.renderCopyAction()}
      {this.renderDeleteAction()}
    </span>;
  }

  renderItem() {
    const { item, detailed, onEditItem } = this.props;
    const item_name = '' === item.item_name ? 'Other Service' : item.item_name;
    return (
      <tr>
        <td className="description" colSpan={detailed ? 2 : 1}>
          <span className="product-name">{item_name}{1 == item.exclude_from_margin ? ' (excluded from margin)' : null}</span>
          {this.renderActions()}
          <br />
          {detailed ? item.item_description : null}
        </td>
        <td className="quantity">{parseInt(item.total_units, 10)}</td>
        {detailed ? <td className="amount">${formatMoney(item.unit_price)}</td> : null}
        <td className="margin">{null === item.total_margin ? 'N/A' : parseFloat(item.total_margin).toFixed(2) + '%'}</td>
        <ItemSummaryTaxTd item={item}/>
        <td className="amount">${formatMoney(item.total_subtotal)}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order_id = get(ownProps, 'item.order_id');

  return {
    avalara_status: get(state.entities.orders, [order_id, 'avalara_status']) || '',
  };
};

export default withDraggableItem(DragItemTypes.ITEM, connect(mapStateToProps)(ServiceItemSummary));
