import React, { useEffect } from 'react';
import { Row, Col, Button, Popup, PopupHeader } from '@commonsku/styles';
import Chart from './DynamicChart';

function ChartPopup({onClosePopup, chartOptions, title="", }) {
    const [isMounted, setIsMounted] = React.useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    return (
        <Popup style={{height: 'fit-content'}}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                  <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                      <span className="title">{title}</span>
                  </Col>
                  <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                    <Button onClick={onClosePopup}>Close</Button>
                  </Col>
              </PopupHeader>
            }
            onClose={() => onClosePopup()}
        >
            <Row>
                <Col>{isMounted && <Chart {...chartOptions} />}</Col>
            </Row>
        </Popup>
    );
}

export default ChartPopup;
