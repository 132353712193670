/**
 * Temporary Slider component.
 * 
 * This should be replaced by a more general component
 * from @commonsku-styles eventually.
 */

import React from 'react';
import './Slider.css';

interface SliderProps {
  valueType?: 'int' | 'float';
  value?: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  onClick?: () => void;
}

export const Slider = ({
  valueType,
  value,
  onChange,
  onClick,
  min,
  max,
}: SliderProps) => {
  const type = valueType ?? 'int';
  const step = ((max || 100) - (min || 0)) / 100;

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget?.blur();
  };

  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'int') {
      onChange(parseInt(e.currentTarget.value));
    } else {
      onChange(parseFloat(e.currentTarget.value));
    }
  }

  return (
    <div className="editor-brush-slider">
      <input
        type="range"
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onSliderChange}
        onClick={onClick}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};
