import * as actions from '../actions/company_search';
import * as cActions from '../actions/client';

export const company_search_reducer = (state = {}, action) => {
    switch (action.type) {
        case cActions.GET_CLIENT_SUCCESS:
            return {...state, client: action.payload.client };
        case cActions.UPDATE_CLIENT_SUCCESS:
            return {...state, client: {...state.client, ...action.payload.client} };
        case actions.CLEAR_CLIENT_STATE:
            return {...state, client: null };
        case actions.LOAD_COMPANY_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                companies: ((action.payload.companies || []).reduce((o, c) => {
                    o[c.client_id] = c;
                    return o;
                }, {}))
            };
        case actions.UPDATE_COMPANY_CLIENT_STATE_REQUEST:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.payload.client_id]: {
                        ...state.companies[action.payload.client_id],
                        ...action.payload.client,
                    }
                }
            };
        case actions.LOAD_TEAM_LIST_SUCCESS:
            return {
                ...state,
                teams: ((action.payload.teams || []).reduce((o, c) => {
                    o[c.team_id] = c;
                    return o;
                }, {}))
            };
        case actions.LOAD_COMPANY_USER_LIST_SUCCESS:
            return {
                ...state,
                company_users: ((action.payload.company_users || []).reduce((o, c) => {
                    o[c.user_id] = c;
                    return o;
                }, {}))
            };
        case actions.LOAD_PARENT_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                parent_clients: ((action.payload.parent_clients || []).reduce((o, c) => {
                    o[c.client_id] = c;
                    return o;
                }, {}))
            };
    }

    return state;
};

export default company_search_reducer;
