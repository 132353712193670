import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, colors, Row, StatusDropdown, Text } from '@commonsku/styles';
import { LabeledSelect } from '../helpers';

import DateInput, { CSKUInput } from '../DateInput';

import PS from '../PromostandardsLabel';

import { updatePurchaseOrder } from '../../actions/purchase_order.js';

import { getNextActionTypeOptions, getStatusOptions } from '../../selectors/dropdowns';
import { getCommonskuStyleDropdownOptions, formatProductionMoney, parseMysqlDate } from '../../utils';

export default function Details({ purchase_order_id }) {
  const dispatch = useDispatch();

  const po = useSelector(state => state.entities.purchase_orders[purchase_order_id] || {});
  const statuses = useSelector(state => getCommonskuStyleDropdownOptions(getStatusOptions(state, { order_type: 'PURCHASE ORDER' })) || []);
  const supplier_account = useSelector(state => Object.values(state.entities.supplier_accounts).filter(sa => sa.purchase_order_id === purchase_order_id)[0] || {});
  const next_action_types = useSelector(
    state => ([
      { value: 'none', label: 'None Set' },
    ].concat(
      getCommonskuStyleDropdownOptions(getNextActionTypeOptions(state))
    )),
    _.isEqual
  );

  const tableStandings = [
    {
      value: 'OK',
      content: 'OK',
      color: "#00D374",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
      }
    },
    {
      value: 'PROBLEM',
      content: 'Problem',
      color: "#FF2674",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
      }
    },
    {
      value: 'FOLLOWUP',
      content: 'Follow Up',
      color: "#FFAE00",
      onClick: (item, row) => {
        dispatch(updatePurchaseOrder(row.purchase_order_id, 'standing', row.standing, item.value));
      }
    }
  ];

  return (
    <Row>
      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Client</label>
        <p style={styles.textField}>
          {po.client_id
            ? <a href={`/client.php?id=${po.client_id}`}>{_.get(po, ['client_name'], '')}</a>
            : _.get(po, ['client_name'], '')}
        </p>
      </Col>

      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Project</label>
        <p style={styles.textField}>
          {po.job_id ?
            <a href={`project.php?id=${po.job_id}`} target="_blank" rel="noopener noreferrer">
              {po.job_name} #{po.job_number}
            </a>
          : `${po.job_name} #${po.job_number}`}
        </p>
      </Col>

      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Prod. Rep</label>
        <p style={styles.textField}>{po.purchase_order_rep_full_name}</p>
      </Col>
      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Total</label>
        <p style={styles.textField}>{formatProductionMoney(po.total)}</p>
      </Col>

      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Client Rep</label>
        <p style={styles.textField}>{po.client_rep_full_name}</p>
      </Col>
      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Order Rep</label>
        <p style={styles.textField}>{po.order_rep}</p>
      </Col>

      <Col padded xl={6} sm={6} xs>
        <LabeledSelect label="Stage" name="stage" noMargin
          value={po.status_id}
          options={statuses}
          onChange={e => dispatch(updatePurchaseOrder(purchase_order_id, 'status_id', po.status_id, e.value))}
        />
       {!!po.ext_po_id && 
       <span style={{ display: 'inline-block', marginTop: '0.50rem'}}>
        <PS sent imageStyle={{ width: '20px' }}/><a href=" https://help.commonsku.com/knowledge/electronic-purchase-order-epo">Sent to supplier</a></span>}
      </Col>
      <Col padded xl={6} sm={6} xs>
        <Text style={{ textAlign: 'center', display: 'block' }}>Status</Text>
        <StatusDropdown
          items={tableStandings}
          value={_.find(tableStandings, { value: po.standing }) || {}}
          row={po}
          background={colors.primary1['20']}
        />
      </Col>

      <Col padded xl={6} sm={6} xs>
        <LabeledSelect label="Next Action" name="po_next_action" noMargin
          value={!po.next_action_type_id ? 'none' : po.next_action_type_id}
          options={next_action_types}
          onChange={e => dispatch(updatePurchaseOrder(purchase_order_id, 'next_action_type_id', po.next_action_type_id, e.value))}
        />
      </Col>
      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>Next Action Date</label>
        <DateInput
          customInput={<CSKUInput />}
          showMonthDropdown
          showYearDropdown
          placeholder="Next Action Date"
          value={po.date_nextaction}
          onChange={date => dispatch(updatePurchaseOrder(purchase_order_id, 'date_nextaction', po.date_nextaction, date))} />
      </Col>

      <Col padded xl={6} sm={6} xs>
        <label style={styles.label}>In Hands Date</label>
        <p style={styles.textField}>{parseMysqlDate(po.po_or_so_in_hands_date) === '0000-00-00' ? 'n/a' : parseMysqlDate(po.po_or_so_in_hands_date)}</p>
      </Col>

      <Col padded xl={6} sm={6} xs></Col>
      {supplier_account.supplier_user_full_name && supplier_account.supplier_user_full_name !== '' ?
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Supplier Contact Name</label>
          <p style={styles.textField}>{supplier_account.supplier_user_full_name}</p>
        </Col>
      : null}
      {supplier_account.supplier_user_email && supplier_account.supplier_user_email !== '' ?
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Supplier Contact Email</label>
          <p style={styles.textField}>{supplier_account.supplier_user_email}</p>
        </Col>
      : null}
      {supplier_account.work_phone_number  && supplier_account.work_phone_number !== '' ?
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Supplier Contact Phone</label>
          <p style={styles.textField}>{supplier_account.work_phone_number} {supplier_account.work_phone_extension ? <span>{`x${supplier_account.work_phone_extension}`}</span> : null}</p>
        </Col>
      : null}
      {supplier_account.fax_number && supplier_account.fax_number !== '' ?
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Supplier Contact Fax</label>
          <p style={styles.textField}>{supplier_account.fax_number} {supplier_account.fax_extension ? <span>{`x${supplier_account.fax_extension}`}</span> : null}</p>
        </Col>
      : null}
    </Row>
  );
}

const styles = {
  label: {
    fontFamily: "'skufont-medium',sans-serif",
    color: '#123952',
    fontSize: '1rem',
    fontWeight: 400,
    width: '100%',
    boxSizing: 'border-box',
  },
  textField: {
    marginTop: 0
  }
};
