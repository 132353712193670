import React from 'react';
import { connect } from 'react-redux';

import { createDeleteComment, createAddItemComment } from '../../actions';

import { getItemComments } from '../../selectors/items';

import { formatFriendlyDate } from '../../utils';

import TextArea from '../TextArea';

class Comment extends React.Component {

  constructor(props) {
    super(props);

    this.handleDeleteComment = this.handleDeleteComment.bind(this);
  }

  handleDeleteComment(e) {
    e.preventDefault();
    const { dispatch, presentation_comment_id } = this.props;
    dispatch(createDeleteComment(presentation_comment_id));
  }

  render() {
    const { commenter_name, comment, image, date_created, locked } = this.props;

    return (
      <div className="row-pb flex-comment">
        <div className="flex-author">
          <img className="comment-img" src={image || '/images/404.png'} />
          <div className="comment-text">
            <h5>{commenter_name}</h5>
            <p>{comment}</p>
          </div>
        </div>
        <div className="comment-info">
          <p>{formatFriendlyDate(date_created)}</p>
          {!locked && <a onClick={this.handleDeleteComment}>delete</a>}
        </div>
      </div>
    );
  }
}

class PersonalComments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      comment: ''
    };

    ['handleChangeComment', 'handleAddComment'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  handleAddComment(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    const { comment } = this.state;
    if (!comment) {
      return;
    }
    this.setState({
      comment: ''
    }, () => dispatch(createAddItemComment(item_id, comment)));
  }

  render() {
    const { comments, dispatch, locked } = this.props;
    const { comment } = this.state;
    return (
      <React.Fragment>
        <div className="large-12 columns flex-hdr-row">
          <h2>Personal Comments</h2>
        </div>

        {!locked && <div className="row">
          <div className="large-8 columns">
            <TextArea style={{ height: '45px' }} value={comment || ''} onChange={this.handleChangeComment} />
          </div>
          <div className="large-4 columns">
            <a className="small radius button post-btn" style={{ height: '100%' }} onClick={this.handleAddComment}>Post</a>
          </div>
        </div>}

        <div className="row">
          <div className="large-12 columns">
            {comments.map(c => <Comment key={c.presentation_comment_id} {...c} dispatch={dispatch} locked={locked} />)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const comments = getItemComments(state, { item_id: ownProps.item_id });
  return {
    comments
  };
};

export default connect(mapStateToProps)(PersonalComments);
