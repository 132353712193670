import React from 'react';
import unsplashLogoSvg from '../../../../images/unsplash-logo.svg';

const imgStyle = {
    height: '1rem',
    lineHeight: '1.625rem',
    marginRight: '0.25rem',
};

const UnsplashLogo = () => {
    return <div>
        <img src={unsplashLogoSvg} alt='' style={imgStyle}/>
        <span>Unsplash</span>
    </div>;
};

export default UnsplashLogo;