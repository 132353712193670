import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

function HubspotForm({
    id,
    onSubmit,
    onReady,
    loading,
    ...props
}) {
    const [loaded, setLoaded] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (!window.hbspt && !props.noScript) {
            loadScript();
        } else {
            createForm();
            findFormElement();
        }
    }, []);

    function createForm() {
        if (window.hbspt) {
            if (!formRef.current) {
                return;
            }

            const options = {
                ...props,
                target: `#${formRef.current.getAttribute('id')}`,
                onFormSubmit: ($form) => {
                    // ref: https://developers.hubspot.com/docs/methods/forms/advanced_form_options
                    var formData = $form.serializeArray();
                    onSubmit(formData);
                }
            };
            window.hbspt.forms.create(options);
            return true;
        } else {
            setTimeout(createForm, 100);
        }
    }

    function findFormElement() {
        if (!formRef.current) { return; }

        const form = formRef.current.querySelector('iframe');
        if (form) {
            setLoaded(true);
            if (onReady) {
                onReady(form);
            }
        }
        else {
            setTimeout(findFormElement, 100);
        }
    }

    function loadScript() {
        const script = document.createElement('script');
        script.defer = true;
        script.onload = () => {
            createForm();
            findFormElement();
        };
        script.src = '//js.hsforms.net/forms/v2.js';
        document.head.appendChild(script);
    }

    return (
        <div>
            <div
                ref={formRef}
                id={id}
                style={{ display: loaded ? 'block' : 'none' }}
            />
            {!loaded && loading}
        </div>
    );
}

HubspotForm.propTypes = {
    id: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onReady: PropTypes.func,
    loading: PropTypes.element,

    // hubspot fields
    region: PropTypes.string,
    portalId: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
};

export default HubspotForm;
