import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col, Row, LabeledSelect, LabeledInput, LabeledCheckbox,
  Button, Theme, H5, Box, LabeledTextarea, Artwork, ShowPopup,
  Dropzone,
} from '@commonsku/styles';

import SelectFilePopup from '../../components/SelectFilePopup';

import { updatePurchaseOrder } from '../../actions/purchase_order';
import { updateProofStatus, replaceProof, deletePurchaseOrderProof, addProof } from '../../actions/purchase_order_proof';
import { addProofComment } from '../../actions/comment';
import { createValidateConfirmationPopup } from '../../actions/popup';

import ProofStatus from '../../models/ProofStatus';

import { getStatusOptions } from '../../selectors/dropdowns';

import { formatDate, getImageSrc, parseMysqlDate, getCommonskuStyleDropdownOptions } from '../../utils';
import { ArtworkLibraryProvider } from '../artwork-library/context';
import ArtworkLibrary from '../artwork-library/ArtworkLibrary';

export default function Proofing({ purchase_order_id, onShowPopup, onClosePopup }) {
  const po = useSelector(state => state.entities.purchase_orders[purchase_order_id] || {});
  const po_proofs = useSelector(state => state.entities.po_proofs[purchase_order_id] ? Object.values(state.entities.po_proofs[purchase_order_id].po_proofs) : []);

  const proof_statuses = useSelector(state => getCommonskuStyleDropdownOptions(getStatusOptions(state, { order_type: 'PROOF' })) || []);
  const identity = useSelector(state => state.identity || {});

  const [publisherOpen, setPublisherOpen] = useState(false);
  const [text, setText] = useState('');
  const [showFilesPopup, setShowFilesPopup] = useState(false);
  const [artworkLibrary, setArtworkLibrary] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (po.client_id) {
      const artworkLibrary = <ArtworkLibraryProvider
        initParents={[
          {
            parent_type: 'JOB',
            parent_id: po.job_id,
          },
          {
            parent_type: 'CLIENT',
            parent_id: po.client_id,
          },
        ]}
        initParent={{
          parent_type: 'JOB',
          parent_id: po.job_id,
        }}
        isModal={true}
        modalOpen={showFilesPopup}
        setModalOpen={setShowFilesPopup}
        onFileSelect={(f) => {
          dispatch(addProof(purchase_order_id, f));
        }}
      >
        <ArtworkLibrary />
      </ArtworkLibraryProvider>;
      setArtworkLibrary(artworkLibrary);
    }
  }, [dispatch, po.client_id, po.job_id, purchase_order_id, showFilesPopup]);

  function renderComment(c) {
    return (
      <Row key={'proof-comment-' + (c.message_id || c.comment_id) + c.parent_id} style={{ width: '100%', marginBottom: '1rem' }}>
        <Col xs={9}>
          <span dangerouslySetInnerHTML={{ __html: c.text && c.text !== '' ? c.text : c.status_name }} />
          <br />
          <small><b>by {c.commenter_name}</b></small>
        </Col>
        <Col xs={3}>
          <div>{parseMysqlDate(c.date_created)}</div>
        </Col>
      </Row>
    );
  }

  function renderProof(p) {

    function handleClickDownload(e, url, file_name) {
      e && e.preventDefault();
      e && e.stopImmediatePropagation && e.stopImmediatePropagation();

      //force file download
      fetch(url, { mode: 'cors' }).then(function (response) {
        return response.blob();
      }).then(function (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
      });
    }

    function deleteProof(p) {
      dispatch(deletePurchaseOrderProof(p));
    }

    return (
      <Row key={'purchase_order_proof-' + p.purchase_order_proof_id} style={{ borderTop: '1px solid #ccc', marginTop: '10px', padding: '10px' }}>
        <Col xs={8}>
          <Artwork
            picture={getImageSrc(p.file, 'medium')}
            onDownload={e => handleClickDownload(e, getImageSrc(p.file, 'original'), p.file.file_display_name)}
          />
        </Col>
        <Col xs={4} padded>
          <p style={{ marginTop: 0, wordBreak: 'break-all' }}>{p.file.file_display_name}</p>
          <p>{formatDate(p.file.date_created)}</p>
          <a onClick={(e) => handleClickDownload(e, getImageSrc(p.file, 'original'), p.file.file_display_name)} style={{ cursor: 'pointer' }}>Download</a>
          {(p.status.status_id === ProofStatus.Approved || p.status.status_id === ProofStatus.ProofingComplete) ? null :
            <Dropzone style={{ border: 'none' }} multiple={false}
              onDrop={(files) => {
                dispatch(replaceProof(p, files));
              }}
            >
              <a style={{ cursor: 'pointer' }}>Replace</a>
            </Dropzone>
          }
          {(p.status.status_id === ProofStatus.Approved || p.status.status_id === ProofStatus.ProofingComplete) ? null :
            <a onClick={() => {
              dispatch(createValidateConfirmationPopup(deleteProof, [p]));
            }} style={{ cursor: 'pointer' }}>Delete</a>
          }
        </Col>
        <Col xs={12}>
          <LabeledSelect name="proof_status" noMargin
            value={_.find(proof_statuses, { value: p.status.status_id })}
            options={proof_statuses}
            onChange={e =>
              dispatch(updateProofStatus(p, e.value, { commenter_name: `${identity.user_first_name} ${identity.user_last_name}` }))
            }
          />
        </Col>
        <Col xs={12}>
          {publisherOpen === p.purchase_order_proof_id ?
            <>
              <Col xs={12}>
                <LabeledTextarea label="Comment" rows={10}
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
              </Col>
              {text !== '' ?
                <Col xs={12}>
                  <Button onClick={() => {
                    dispatch(addProofComment({
                      parent_id: p.purchase_order_proof_id,
                      commenter_id: identity.user_id,
                      text,
                    }));
                    setText('');
                    setPublisherOpen(false);
                  }}>Publish</Button>
                </Col>
                : null}
            </>
            :
            <Box padded style={styles.boxStyle} onClick={() => {
              if (publisherOpen !== p.purchase_order_proof_id) {
                setText('');
              }
              setPublisherOpen(p.purchase_order_proof_id);
            }}>
              <div>+ Comment For Client</div>
            </Box>
          }
        </Col>
        <Col xs={12}>
          {Object.values(p.comments || []).map(c =>
            renderComment(c)
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      {artworkLibrary}
      {(po.po_type === 'ARTWORK' || po.proof_required == 0) ?
        <p>No proof required.</p>
        :
        <Col xs={12}>
          <Col xs={8}>
            <LabeledCheckbox
              label="All Proofs Uploaded"
              checked={po.all_proof_uploaded == 1}
              onChange={() =>
                dispatch(updatePurchaseOrder(
                  po.purchase_order_id,
                  'all_proof_uploaded',
                  po.all_proof_uploaded,
                  po.all_proof_uploaded == 0 ? 1 : 0
                ))
              }
            />
          </Col>
          <Col xs={4}>
            {po.all_proof_uploaded == 0 ?
              <Button onClick={() => {
                onShowPopup && onShowPopup();
                setShowFilesPopup(true);
              }}>Select Files</Button>
              : null}
          </Col>
          {po_proofs.map(p =>
            renderProof(p)
          )}
        </Col>
      }
    </Row>
  );
}

const styles = {
  boxStyle: {
    borderBottom: '1px solid #E0E7EB',
    color: '#9A9A9A',
    padding: '10px',
    marginBottom: '16px',
    background: '#EDF2F4',
    borderRadius: '4px'
  }
};
