import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { H6, Col, Link } from '@commonsku/styles';
import { useUpcomingPastCommunityEvents } from './hooks';
import Briefing from './Briefing';
import { fetchOnceCommunityEvents } from './communityEventsSlice';

const HomeCommunityEvents = () => {
  const dispatch = useDispatch();
  const [upcoming, past] = useUpcomingPastCommunityEvents();

  useEffect(() => {
    if (isEmpty(upcoming)) {
      dispatch(fetchOnceCommunityEvents());
    }
  }, []);

  return <>
    <Col justify='space-between'>
      <H6>Webinars</H6>
      <Briefing events={isEmpty(upcoming) ? past : upcoming}/>
      <a href="/community_events.php" target="_blank">
        <Link block mt={10}>All Webinars</Link>
      </a>
    </Col>
  </>;
};

export default HomeCommunityEvents;
