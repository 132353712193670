import React from 'react';

export function RestrictedAccess({style={}, message=''}) {
    return (
        <div style={{
            textAlign: 'center',
            color: '#c0392b',
            fontSize: '1.1em',
            fontWeight: 500,
            ...(style||{})
        }}>
            <p>{message || "You do not have permission to view the details..."}</p>
        </div>
    );
}
