import React from 'react';
import Proptype from 'prop-types';
import { colors } from '@commonsku/styles';

function Alert({ type = 'success', children, ...props }) {
  const bgColor = type === 'danger'
      ? '#FFEDED'
    : type === 'alert'
      ? '#f6e58d'
    : type === 'info'
      ? '#dff9fb'
    : type === 'success'
      ? colors.special2Bg
    : colors.primaryBg;

  const textColor = type === 'danger'
      ? 'red'
    : type === 'alert'
      ? '#e58e26'
    : type === 'info'
      ? '#22a6b3'
    : type === 'success'
      ? colors.special2
    : colors.primary;

  return (
    <div style={{
      padding: '0.5rem',
      textAlign: 'center',
      background: bgColor,
      width: '100%',
      boxSizing: 'border-box',
    }}>
      <p style={{
        color: textColor,
        fontWeight: 500,
      }}>{children}</p>
    </div>
  );
}

Alert.propTypes = {
  type: Proptype.oneOf(['success', 'primary', 'info', 'alert', 'danger'])
};

export default Alert;