import React, { useCallback } from 'react';
import { ArtworkLibraryProvider } from './artwork-library/context';
import ArtworkLibrary from './artwork-library/ArtworkLibrary';
import { useDispatch } from 'react-redux';
import { createDisplayArtworkPopup } from '../actions/popup';

const ProjectFiles = ({ project }) => {
    const dispatch = useDispatch();
    const createFilePreview = useCallback((f) => {
        dispatch(createDisplayArtworkPopup(f));
    }, [dispatch]);

    const artwork = <ArtworkLibraryProvider
        initParents={[
            {
                parent_type: 'JOB',
                parent_id: project.job_id,
            },
            {
                parent_type: 'CLIENT',
                parent_id: project.account_id,
            },
        ]}
        initParent={{
            parent_type: 'JOB',
            parent_id: project.job_id,
        }}
        isModal={false}
        modalOpen={false}
        setModalOpen={false}
        onFileSelect={(f) => { createFilePreview(f); }}
    >
        <ArtworkLibrary />
    </ArtworkLibraryProvider>;

    return <div className="main-content">{artwork}</div>;
};

export default ProjectFiles;