import _ from 'lodash';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { UPDATE_COMPANY_AVATAR_SUCCESS } from '../actions';
import { LOAD_CLIENT_LIST_SUCCESS } from '../actions/client';
import { createReducerManager }  from './reducerManager';

import {
  bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
  notificationCountReducer,
} from '../reducers/header';
import displayReducer from '../reducers/display';

const toObject = (list, key) => {
  return _.fromPairs(_.map(list, (item) => [item[key], item]));
};

const cleanInitialState = (state) => {
  const entities = state.entities || {};
  return {
    ...state,
    display: state.display || {
      popups: [],
      loading: {},
    },
    dropdowns: {
      clients: []
    },
    entities: {
      ...entities,
      bookmarks: toObject(entities.bookmarks, 'bookmark_id'),
      notifications: toObject(entities.notifications, 'notification_id'),
      recent_orders: toObject(entities.recent_orders, 'order_id'),
      reminders: toObject(entities.reminders, 'note_id'),
    }
  };
};

const identityReducer = (state={}, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_AVATAR_SUCCESS:
      return {
        ...state,
        company_avatar: action.payload,
      };
  }
  return state;
};

const dropdownReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clients: [
          ...(new Set((state.clients || []).concat(action.payload.clients.map(c => c.client_id))))
        ]
      };
  }
  return state;
};

const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        ...(action.payload.clients.reduce((o, c) => {
          o[c.client_id] = c;
          return o;
        }, {}))
      };
  }
  return state;
};

const entityReducer = combineReducers({
  bookmarks: bookmarksReducer,
  notifications: notificationsReducer,
  notification_count: notificationCountReducer,
  recent_orders: recentOrdersReducer,
  reminders: remindersReducer,
  clients: clientReducer,
});

export const reducers = {
  identity: identityReducer,
  display: displayReducer,
  dropdowns: dropdownReducer,
  entities: entityReducer,
};

export default function configureStore(initialState) {
  initialState = cleanInitialState(initialState || {});
  const reducerManager = createReducerManager({
    scriptName: (state = "", action) => state,
    ...reducers,
  });
  const rootReducer = (state=initialState, action) => {
    return {
      ...state,
      ...(reducerManager.reduce(state, action)),
    };
  };

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
  store.attachReducers = (newReducers) => {
    const changed = reducerManager.attachReducers(newReducers);
    if (changed.length > 0) {
      store.replaceReducer(rootReducer);
    }
  };

  return store;
}

