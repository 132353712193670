import { oauth } from '../utils';
import { createFinalizedBanner } from './display';
import { createUploadFile } from './file';

export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAILURE = 'ADD_MESSAGE_FAILURE';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const UPDATE_NOTE_REQUEST = 'UPDATE_NOTE_REQUEST';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';
export const ADD_COLLABORATE_COMMENT_SUCCESS = 'ADD_COLLABORATE_COMMENT_SUCCESS';
export const SYNC_MESSAGE_SUCCESS = 'SYNC_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

export const UPDATE_MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE';

export const FETCH_REMINDERS_SUCCESS = 'FETCH_REMINDERS_SUCCESS';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';

const createAddMessageRequest = (job_id, text, reminder_user_id, reminder_date) => ({
  type: ADD_MESSAGE_REQUEST,
  payload: {
    job_id,
    message_text: text,
    reminder_user: reminder_user_id,
    date_reminder: reminder_date
  }
});

export const createFinalizeAddMessage = (message, files, job_id, details_parent_id, details_parent_type) => ({
  type: ADD_MESSAGE_SUCCESS,
  payload: {
    message,
    files,
    job_id,
    details_parent_id,
    details_parent_type
  }
});

const  createFailedAddMessage = job_id => ({
  type: ADD_MESSAGE_FAILURE,
  payload: {
    job_id
  }
});

export const createAddMessage = (job_id, message_text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, product_ids, note_type = 'NOTE') => dispatch => {
  let data = {
    job_id,
    details_parent_type: 'JOB',
    details_parent_id: job_id,
    details_type: 'NOTE',
    message_text,
    date_reminder: reminder_date,
    reminder_user: reminder_user_id,
    tagged_users,
    note_type,
  };

  if (product_ids && product_ids.length != 0) {
    data['product_ids'] = product_ids;
    data['details_type'] = 'PRODUCT SUGGEST';
  }

  if(!details_parent_id && !details_parent_type) {
    dispatch(createAddMessageRequest(job_id, message_text, reminder_user_id, reminder_date));
  }else{
    data.details_parent_type = details_parent_type;
    data.details_parent_id = details_parent_id;

    delete data.job_id;
    delete data.tagged_users;
    if(details_parent_type !== 'PURCHASE ORDER' && details_parent_type !== 'CLIENT') {
      delete data.date_reminder;
      delete data.reminder_user;
    }
  }

  let message;
  if (files.length > 0 && details_parent_type == 'PURCHASE ORDER') {
    return oauth('POST', 'message', data).then(
      ({ json }) => {
        message = json.message;
        return dispatch(createUploadFile(json.message.details_id, 'NOTE', files[0]));
      }
    ).then(
      ({ payload: { data} }) => dispatch(createFinalizeAddMessage(message, data, job_id, details_parent_id, details_parent_type))
    );
  }else if(details_parent_type == 'PURCHASE ORDER') {
    oauth('POST', 'message', data).then(
      ({ json }) => dispatch(createFinalizeAddMessage(json.message, null, job_id, details_parent_id, details_parent_type)),
      ({ json }) => dispatch(createFailedAddMessage(job_id))
    );
  }else if(files.length > 0 && details_parent_type != 'PURCHASE ORDER') {
    oauth('POST', 'message', data).then(
      ({ json }) => {
        message = json.message;
        return dispatch(createUploadFile(json.message.details_id, 'NOTE', files[0]));
      }
    ).then(
      ({ payload: { data} }) => dispatch(createFinalizeAddMessage(message, data, job_id))
    );
  }else{
    oauth('POST', 'message', data).then(
      ({ json }) => dispatch(createFinalizeAddMessage(json.message, null, job_id)),
      ({ json }) => dispatch(createFailedAddMessage(job_id))
    );
  }

};

const createFinalizeAddComment = (message,params) => ({
  type: ADD_COMMENT_SUCCESS,
  payload: {
    message,
    params
  }
});

const createFinalizedUpdateMessage = (message_id,newValue,job_id) =>({
type: UPDATE_MESSAGE_SUCCESS,
payload:{
  parent_id:message_id,
  pinned:newValue,
  job_id:job_id,
}
});

const finalizedDeleteMessage =(message_id,job_id)=>({
  type:DELETE_MESSAGE_SUCCESS,
  payload:{
    id:message_id,
    job_id:job_id
  }
});

const createFailedAddComment = () => ({
  type: ADD_COMMENT_FAILURE,
  payload: {
  }
});

const createFinalizeAddCollborateComment = message => ({
  type: ADD_COLLABORATE_COMMENT_SUCCESS,
  payload: {
    message
  }
});

export const createAddComment = (message_text, parent_id, parent_type, recipient_id, tagged_users, params) => dispatch => {
  let data;
  if(parent_type === 'event') {
    data = {
      details_type: 'NEWSFEED MESSAGE',
      message_text,
      parent_id: parent_id,
      parent_type: parent_type,
      recipient_id: recipient_id,
      reminder_complete: 0
    };

    if(params === 'contact') {
      data.recipient_type = 'USER';
    }else{
      data.recipient_type = 'MARKETPLACE';
    }
  }else{
    data = {
      details_type: 'NEWSFEED MESSAGE',
      message_text,
      parent_id: parent_id,
      parent_type: parent_type,
      recipient_id: recipient_id,
      recipient_type: "USER",
      reminder_complete: 0,
      tagged_users
    };
  }

  if(parent_type === 'event' && !params) {
    oauth('POST', 'message', data).then(
      ({ json }) => dispatch(createFinalizeAddCollborateComment(json.message))
    );
  }else {
    oauth('POST', 'message', data).then(
      ({ json }) => {dispatch(createFinalizeAddComment(json.message,params));},
      ({ json }) => dispatch(createFailedAddComment())
    );
  }
};

const createUpdateNoteRequest = (id, field, previous_value, value) => ({
  type: UPDATE_NOTE_REQUEST,
  payload: {
    id,
    data: {
      [field]: value
    }
  }
});

const createFinalizeUpdateNote = (id, field, previous_value, value, parent_id) => ({
  type: UPDATE_NOTE_SUCCESS,
  payload: {
    id,
    parent_id,
    data: {
      [field]: value
    }
  }
});

const createFailedUpdateNote = (id, field, previous_value, value, parent_id) => ({
  type: UPDATE_NOTE_FAILURE,
  payload: {
    id,
    parent_id,
    message: 'Unable to update note',
    data: {
      [field]: value
    }
  }
});

export const createUpdateNote = (message_id, note_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(createUpdateNoteRequest(message_id, field, previous_value, value));

  oauth('PUT', `note/${note_id}`, {[field]: value}).then(
    ({ json }) => dispatch(createFinalizeUpdateNote(message_id, field, previous_value, json.note[field], json.note.parent_id)),
    ({ json }) => dispatch(createFailedUpdateNote(message_id, field, previous_value, value, json.note.parent_id))
  );
};
export const createUpdateMessage=(message_id,field,value,job_id)=>(dispatch) => {
  oauth('PUT', `message/${message_id}`, {[field]: value}).then(
  ({json}) => {dispatch(createFinalizedUpdateMessage(message_id,value,job_id));
  },
  );
};
export const deleteMessage=(message_id,job_id) =>(dispatch) => {
oauth('DELETE', `message/${message_id}`).then(
// update state
({json})=>{dispatch(finalizedDeleteMessage(message_id,job_id));
},
);
};
export const syncMessages = (messages) => ({
  type: SYNC_MESSAGE_SUCCESS,
  payload: {
    messages
  }
});

export const updateNotesFeed = (job_id) => (dispatch) => {
  const data = {
    job_id: job_id
  };

  oauth('GET', 'newsfeed/project', data).then(
    ({ json }) => dispatch(syncMessages(json.projectfeed))
  );
};

export const createAddContactMessage = (contact_id, message_text, reminder_user, date_reminder, files, tagged_users, details_parent_id, details_parent_type, details_type, note_type, date_complete) => (dispatch, getState) => {
  const data = {
    contact_id,
    message_text,
    reminder_user,
    date_reminder,
    tagged_users,
    details_parent_id,
    details_parent_type: 'CLIENT',
    details_type: 'NOTE',
    note_type,
    date_complete
  };

  if(note_type === 'NOTE') {
    delete data.date_complete;
  }

  if(files.length > 0) {
    let message;
    return oauth('POST', 'message', data).then(
      ({ json }) => {
        message = json.message;
        return dispatch(createUploadFile(json.message.details_id, 'NOTE', files[0]));
      }
    ).then(
      ({ payload: { data } }) => dispatch(createFinalizeAddMessage(message, data))
    );
  }else{
    oauth('POST', 'message', data).then(
      ({ json }) => dispatch(createFinalizeAddMessage(json.message))
    );
  }
};

export const createAddContactComment = (contact_id, message_text, parent_id, parent_type, recipient_id, tagged_users) => dispatch => {
  const data = {
    details_type: 'NEWSFEED MESSAGE',
    message_text,
    parent_id: parent_id,
    parent_type: parent_type,
    recipient_id: recipient_id,
    recipient_type: "USER",
    tagged_users
  };

  oauth('POST', 'message', data).then(
    ({ json }) => dispatch(createFinalizeAddComment(json.message))
  );
};

const createFinalizeUpdateReminder = (id, note) => ({
  type: UPDATE_REMINDER_SUCCESS,
  payload: {
    id,
    note
  }
});

export const createUpdateReminder = (message_id, note_id, message_text, reminder_user, date_reminder, reminder_complete) => dispatch => {
  let data = {
    message_id,
    message_text,
    reminder_user,
    date_reminder,
    reminder_complete
  };

  oauth('PUT', `note/${note_id}`, data).then(
    ({ json }) => dispatch(createFinalizeUpdateReminder(message_id, json.note))
  );
};

const createFinalizeFetchReminders = (id, notes) => ({
  type: FETCH_REMINDERS_SUCCESS,
  payload: {
    id,
    notes
  }
});

export const createFetchReminders = (parent_id, parent_type) => dispatch => {
  let data = {
    parent_id,
    parent_type,
    note_type: 'ALL REMINDERS',
    include_complete: true
  };

  if(parent_type === 'PURCHASE ORDER') {
    data.note_type = 'NOTE';
  }

  oauth('GET', 'note', data).then(
    ({ json }) => dispatch(createFinalizeFetchReminders(parent_id, json.notes))
  );
};

export const createFetchMessages = (details_id, details_type) => dispatch => {
  let data = {
    details_id,
    details_type
  };

  oauth('GET', 'message', data).then(
    ({ json }) => dispatch(createFinalizeFetchReminders(details_id, json.messages))
  );
};
