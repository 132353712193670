import React, { useEffect } from "react";
import { Input, LabeledInput } from '@commonsku/styles';
import { debounce } from "lodash";

const DebouncedInput = (props) => {
  const { value, onChange, timeout = 1000, labeled = false, ...rest } = props;
  const [state, setState] = React.useState(value);

  const debouncedChange = debounce((value) => {
    onChange(value);
  }, timeout);

  const onChangeHandler = React.useCallback((e) => {
    const value = e.target.value;
    setState(value);
    debouncedChange(value);
  }, [onChange]); // don't include debouncedChange in dep array

  useEffect(() => {
    setState(value);
  }, [value]);

  if (labeled) {
    return <LabeledInput value={state} onChange={onChangeHandler} {...rest} />;
  }
  return <Input value={state} onChange={onChangeHandler} {...rest} />;
};

export default DebouncedInput;
