import React from "react";
import { Csku, ImageIcon, Text } from "@commonsku/styles";
import { Tab, Tabs } from "./StoreNameTabs";
import { DebouncedInput } from "../../../helpers";
import Img from "../../../Img";
import { usePublicViewEntityContext } from "../../../../context/PublicViewEntityProvider";

export default function StoreName(props) {
  const {
    shopTitle,
    shopLogo,
    onChangeName,
    onClickImage,
  } = props;
  const { labelTitle, } = usePublicViewEntityContext();

  return (
    <>
      <Text as="p" style={{
        color: 'var(--color-neutrals-90)',
        fontFamily: 'var(--font-family-regular)',
        fontSize: 16,
        marginBottom: 0,
        paddingBottom: 7,
      }}>{labelTitle} Name</Text>
      <Tabs initialIndex={!!shopTitle ? 0 : 1}>
        <Tab title="Text">
          <DebouncedInput
            placeholder={labelTitle + ' Name'}
            value={shopTitle}
            onChange={value => onChangeName(value)}
            style={{ marginBottom: 0 }}
          />
        </Tab>
        <Tab title="Logo">
          <Csku as="p"
            onClick={onClickImage}
            style={{
              color: 'var(--color-primary1-main)',
              fontFamily: 'var(--font-family-bold)',
              fontSize: 16,
              marginBottom: 0,
              padding: shopLogo ? 0 : 8,
              borderRadius: 3,
              cursor: 'pointer',
              textAlign: 'center',
            }}
            sx={{
              ':hover': {
                ...(shopLogo ? {} : {
                  background: 'var(--color-neutrals-30)',
                }),
              },
            }}
          >
            {shopLogo ? <Img src={shopLogo} style={{ maxHeight: 80, maxWidth: '100%', }} /> :
              <>
                <ImageIcon style={{ verticalAlign: 'middle' }} />
                <span style={{ paddingLeft: 7 }}>Add Logo Image</span>
              </>}
          </Csku>
        </Tab>
      </Tabs>
    </>
  );
}
