import type { CSSProperties, FC } from 'react';
import React, { memo, useEffect, useState } from 'react';
import { useArtworkLibraryContext } from './context';
import { getImageSrc } from '../../utils';

export interface BoxDragPreviewProps {
    item: any,
}

export interface BoxDragPreviewState {
    tickTock: any
}

export const BoxDragPreview: FC<BoxDragPreviewProps> = memo(
    function BoxDragPreview({ item }) {
        const { fileRef } = useArtworkLibraryContext();
        const [tickTock, setTickTock] = useState(false);

        useEffect(
            function subscribeToIntervalTick() {
                const interval = setInterval(() => setTickTock(!tickTock), 500);
                return () => clearInterval(interval);
            },
            [tickTock],
        );

        if (!item.file_display_name) {
            return null;
        }

        const dynamicStyles = {
            width: fileRef.current?.offsetWidth || '100px',
            height: fileRef.current?.offsetHeight || '40px',
            display: 'flex',
            alignItems: 'center',
            // background: 'red',
        } as CSSProperties;

        //const imageUrl = `url('${getImageSrc(item, 'small')}')`;
        return (
            <div style={{ ...dynamicStyles }}>
                <div style={{
                    textAlign: 'center',
                    width: '150px',
                    background: 'rgb(225, 247, 250)',
                    padding: '1rem',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0px',
                    borderRadius: '0.5rem',
                    textOverflow: 'ellipsis',
                }}>
                    <div style={{ display: 'flex', verticalAlign: 'middle', height: '30px', maxWidth: '100%', alignItems: 'top', gap: '10px' }}>
                        <img style={{ height: '30px' }} src={getImageSrc(item, 'medium')} alt={item.file_display_name} />
                        <div style={{ maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.file_display_name}</div>
                    </div>
                </div>
            </div>
        );
    },
);
