import React, { FC } from "react";
import { TNumber } from "../../../interfaces/common";

interface MetaDataList {
  metaDataId: string;
  label: string;
  type: string;
  mandatory: TNumber;
  updateMetadataList: (
    metaDataId: string,
    key: string,
    value?: string | number
  ) => (value: string | number) => void;
  deleteMetaDataList: (metaDataId: string) => void;
  editMetaDataList: (metaDataId: string) => void;
}

const MetaDataList: FC<MetaDataList> = ({
  metaDataId,
  label,
  type,
  mandatory,
  updateMetadataList,
  deleteMetaDataList,
  editMetaDataList,
}) => {
  return (
    <tr
      style={{ marginTop: 4, marginBottom: 4 }}
      data-test-id={`metadata-list-item-${metaDataId}`}
    >
      <td>{label}</td>
      <td>
        <select
          value={type}
          style={{ margin: 0 }}
          onChange={(e) => {
            updateMetadataList(metaDataId, "type", type)(e.target.value);
            e.target.value === "SELECT" && editMetaDataList(metaDataId);
          }}
          data-test-id={`metadata-type-${metaDataId}`}
        >
          <option value="TEXT">Text Field</option>
          <option value="SELECT">Select Field</option>
        </select>
      </td>
      <td>
        <input
          type="checkbox"
          checked={mandatory == 1 ? true : false}
          style={{ margin: 0 }}
          onChange={(e) =>
            updateMetadataList(
              metaDataId,
              "mandatory",
              mandatory
            )(e.target.checked ? 1 : 0)
          }
          data-test-id={`metadata-mandatory-checkbox-${metaDataId}`}
        />
      </td>
      <td>
        <a
          onClick={(e) => {
            e.preventDefault();
            deleteMetaDataList(metaDataId);
          }}
          data-test-id={`metadata-delete-button-${metaDataId}`}
        >
          Delete
        </a>
        <br />
        {type === "SELECT" && (
          <a
            onClick={(e) => {
              e.preventDefault();
              editMetaDataList(metaDataId);
            }}
            data-test-id={`metadata-edit-button-${metaDataId}`}
          >
            Edit
          </a>
        )}
      </td>
    </tr>
  );
};

export default MetaDataList;
