import React from 'react';
import { connect } from 'react-redux';

import { createUpdate } from '../../actions';
import * as actions from '../../actions/item';

import { getItem } from '../../selectors/items';

import TextArea from '../TextArea';

const updateItem = createUpdate('item', {
  request: actions.SIMPLE_UPDATE_ITEM_REQUEST,
  success: actions.SIMPLE_UPDATE_ITEM_SUCCESS,
  failure: actions.SIMPLE_UPDATE_ITEM_FAILURE,
  failure_message: 'Update to update item'
});

class PrivateNotes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      private_notes: props.private_notes
    };
    ['handleChangePrivateNotes', 'handleUpdatePrivateNotes'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangePrivateNotes(e) {
    this.setState({ private_notes: e.target.value });
  }

  handleUpdatePrivateNotes(e) {
    if (this.props.private_notes !== this.state.private_notes) {
      this.props.dispatch(updateItem(this.props.item_id, 'private_notes', this.props.private_notes, this.state.private_notes));
    }
  }

  render() {
    const { locked } = this.props;
    return (
      <div className="row">
        <div className="large-12 columns">
          <h2 style={{ marginBottom: '30px' }}>Private Notes</h2>
          <TextArea
            style={{ width: '100%' }}
            value={this.state.private_notes || ''}
            onChange={this.handleChangePrivateNotes}
            onBlur={this.handleUpdatePrivateNotes}
            disabled={locked}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getItem(state, { item_id: ownProps.item_id });
  return {
    private_notes: item.private_notes
  };
};

export default connect(mapStateToProps)(PrivateNotes);
