import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AddIcon, ArrowIcon, Button, Col, Loading, Popup, Row, Text } from "@commonsku/styles";
import { getImageSrc } from "../../../../utils";
import { useContainerScroll } from "../../../../hooks";
import { createUpdatePublicViewTemplateData } from "../../../../actions/shop";
import { createLoadOrder } from "../../../../actions/order";
import Img from "../../../Img";
import { ScrollContainer } from "../../../../hooks/useContainerScroll";
import { usePublicViewEntityContext } from "../../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../../context/PublicViewTemplateDataProvider";
import { window } from "../../../../global";

export default function SelectFeaturedProductPopup({ templateData, onClose }) {
  const {
    entityId,
    entityType,
    entityOrder: gallery,
    currencySymbol,
    isFree,
  } = usePublicViewEntityContext();
  const {
    templateId,
    templateColor,
  } = usePublicViewTemplateDataContext();

  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [elemHeight, setElemHeight] = useState(250);

  const ref = useRef();
  const popupBodyHeight = elemHeight - 100 - 60;

  const orderItems = useMemo(
    () => gallery.items.filter(ii => +ii.hidden !== 1),
    [gallery.items]
  );

  const featuredProductsCount = useMemo(() => {
    const items = orderItems.filter(i => {
      return [
        templateData['featured-product-0'],
        templateData['featured-product-1'],
        templateData['featured-product-2'],
        templateData['featured-product-3'],
      ].includes(i.item_id);
    });
    return items.length;
  }, [orderItems, templateData]);

  function getFeaturedProduct(index) {
    const item = orderItems.filter(i => i.item_id === (templateData[`featured-product-${index}`] || {}))[0];
    const url = item ? getImageSrc(item.images[0], 'large') : '';
    const currency_symbol = currencySymbol || '$';
    let item_prices = [];
    if (item && item.options && item.options.length) {
      item_prices = item.options.filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
    } else if(item && item.min_price && item.max_price) {
      item_prices = [item.min_price, item.max_price];
    }

    const minimum_price = Math.min.apply(null, item_prices);
    const maximum_price = Math.max.apply(null, item_prices);
    const displayPrice = item_prices.length ? (minimum_price === maximum_price
      ? `${currency_symbol}${minimum_price.toFixed(2)}`
      : `${currency_symbol}${minimum_price.toFixed(2)} - ${currency_symbol}${maximum_price.toFixed(2)}`
    ) : null;

    return {
      item,
      url,
      item_prices,
      minimum_price,
      maximum_price,
      displayPrice,
    };
  }

  const onSelectItem = (item_id) => {
    return Promise.resolve(
      dispatch(
        createUpdatePublicViewTemplateData(
          entityId, entityType, templateId,
          `featured-product-${selectedIndex}`,
          item_id
        )
      )
    ).then(() => {
      setSelectedIndex(-1);
    });
  };

  const loadOrder = useCallback(
    (order_id) => order_id ? dispatch(createLoadOrder(order_id)) : null,
    [dispatch]
  );

  useEffect(() => {
    function updateElemInfo(elem, enteries) {
      let element = elem || ref.current;
      if (elem?.target && elem?.type) {
        element = elem?.target;
      }

      if (!element) { return; }
      setElemHeight(element.getBoundingClientRect().height);
    };

    const elem = ref.current;
    if (!elem) { return; }

    const observer = new ResizeObserver((entries) => {
      updateElemInfo(elem, entries[0]);
    });
    observer.observe(elem);
    elem.addEventListener('scroll', updateElemInfo);
    elem.addEventListener('resize', updateElemInfo);

    updateElemInfo(elem);

    return () => {
      elem.removeEventListener('scroll', updateElemInfo);
      elem.removeEventListener('resize', updateElemInfo);
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (!gallery.loaded) {
      loadOrder(gallery.order_id);
    }
  }, [gallery.loaded, gallery.order_id, loadOrder]);

  const isSelected = selectedIndex !== -1;
  const FeaturedProductsGrid = !isSelected ? (
    <Row
      style={{
        height: popupBodyHeight < 150 ? 250 : popupBodyHeight,
        overflowY: 'auto',
      }}
    >
      <Col xs>
        <Text as="p" style={{
          textAlign: 'left',
          fontFamily: 'var(--font-family-regular)',
          color: 'var(--color-neutrals-90)',
          fontSize: 16,
          marginBottom: 0,
        }}>
          {featuredProductsCount > 0
            ? 'You can replace a featured product by clicking the product image.'
            : 'Add your featured products below.'}
        </Text>
      </Col>

      <Col xs sm={6} md={3} style={{ padding: 14, }}>
        <FeaturedProductCard
          {...getFeaturedProduct(0)}
          template_color={templateColor}
          isShopFree={isFree}
          onClick={() => setSelectedIndex(0)}
        />
      </Col>
      <Col xs sm={6} md={3} style={{ padding: 14, }}>
        <FeaturedProductCard
          {...getFeaturedProduct(1)}
          template_color={templateColor}
          isShopFree={isFree}
          onClick={() => setSelectedIndex(1)}
        />
      </Col>
      <Col xs sm={6} md={3} style={{ padding: 14, }}>
        <FeaturedProductCard
          {...getFeaturedProduct(2)}
          template_color={templateColor}
          isShopFree={isFree}
          onClick={() => setSelectedIndex(2)}
        />
      </Col>
      <Col xs sm={6} md={3} style={{ padding: 14, }}>
        <FeaturedProductCard
          {...getFeaturedProduct(3)}
          template_color={templateColor}
          isShopFree={isFree}
          onClick={() => setSelectedIndex(3)}
        />
      </Col>
    </Row>
  ) : null;

  const SelectedFeaturedProduct = isSelected ? (
    <SelectFeaturedProduct
      height={popupBodyHeight+65 < 150 ? 250 : popupBodyHeight+65}
      gallery={gallery}
      orderItems={orderItems}
      onSelectItem={onSelectItem}
    />
  ) : null;

  return (
    <>
      <Popup ref={ref} noHeader closeOnEsc
        className={"shop popup select-featured-product-popup"}
        contentClassName={`select-featured-product-popup-content ${isSelected ? 'selected-featured-product' : ''}`}
        style={{ maxWidth: 980, padding: '24px 24px 0px 24px' }}
        popupContentStyle={{ height: '100%' }}
        onClose={onClose}
      >
        <Row style={{ position: 'relative', height: '100%' }}>
          <Col xs>
            <Text as="p" style={{
              textAlign: 'left',
              fontFamily: 'var(--font-family-bold)',
              color: 'var(--color-neutrals-90)',
              fontSize: 24,
              marginBottom: 0,
              lineHeight: '1.3'
            }}>
              {isSelected ? <ArrowIcon
                direction="left"
                style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                onClick={() => setSelectedIndex(-1)}
              /> : null}
              <span style={{ paddingLeft: isSelected ? 14 : 0 }}>
                {!isSelected ? 'Featured products' : `Featured Product ${selectedIndex+1}: select your product`}
              </span>
            </Text>
          </Col>
          <Col xs>
            <div style={{ paddingTop: 14, }}>
              {FeaturedProductsGrid}
              {SelectedFeaturedProduct}
            </div>
          </Col>
          {!isSelected ? <Col xs style={{
            position: 'absolute',
            top: 'auto',
            bottom: 0,
            marginTop: 14,
            marginBottom: window.innerHeight >= 840 ? 24 : 5,
            paddingRight: 7,
            width: 'calc(100% - 30px)',
            textAlign: 'right'
          }}>
            <Button size="medium" onClick={onClose}>Done</Button>
          </Col> : null}
        </Row>
      </Popup>
    </>
  );
}

const FeaturedProductCard = (props) => {
  const {
    onClick,
    template_color,
    url,
    item,
    displayPrice,
    isShopFree,
  } = props;

  const ChooseProduct = !url ? (
    <div style={{
      background: 'var(--color-neutrals-20)',
      height: 'calc(275px + 56px + 20px + 5px + 20px)',
      textAlign: 'center',
      paddingTop: '50%',
    }}>
      <div style={{ display: 'block' }}>
        <AddIcon
          viewBox={"0 0 24 24"}
          color="var(--color-primary1-main)"
          size="huge"
          width={96}
          height={96}
        />
      </div>
      <div style={{ display: 'block' }}>
        <span style={{fontWeight: 600, color: 'var(--color-primary1-main)', fontSize: '1rem'}}> Choose a </span>
        <br/>
        <span style={{fontWeight: 600, color: 'var(--color-primary1-main)', fontSize: '1rem'}}>featured product</span>
      </div>
    </div>
  ) : null;

  const SelectedProduct = url ? (
    <>
      <div className="prod-img"
        style={{
          backgroundImage: `url('${url}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          textAlign: 'center',
        }}
      />
      <h4>{item && item.item_name}</h4>
      {!isShopFree && <p style={{ color: template_color }}>{displayPrice}</p>}
    </>
  ) : null;

  return (
    <div id="feat-prod" onClick={onClick} style={{
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      boxShadow: 'none',
      border: 'none',
    }}>
      {ChooseProduct}
      {SelectedProduct}
    </div>
  );
};

const SelectFeaturedProduct = (props) => {
  const {
    height,
    gallery,
    orderItems,
    template_color,
    onSelectItem,
  } = props;

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const ref = useRef(null);
  const {
    elemRect,
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [
    height,
    gallery?.loaded,
  ]);

  return (
    <Row ref={ref} style={{ height, overflowY: 'auto', }}>
      {!gallery.loaded ? <Col xs style={{ textAlign: 'center' }}>
        <Loading height={40} />
      </Col> : null}
      {gallery.loaded ? <Col xs>
        <ProductImages
          orderItems={orderItems}
          onSelectItem={onSelectItem}
        />
      </Col> : null}
      {ref.current  ? <>
        <ScrollContainer
          isUp
          onClick={scrollUp}
          canScroll={canScrollUp()}
          color={template_color}
          width={elemRect.width}
          zIndex={99999}
          top={ref.current.offsetTop - 21}
          left={25}
        />

        <ScrollContainer
          isUp={false}
          onClick={scrollDown}
          canScroll={canScrollDown()}
          color={template_color}
          width={elemRect.width}
          zIndex={99999}
          top={ref.current.clientHeight + ref.current.offsetTop - 44 - 10}
          left={25}
        />
      </> : null}
    </Row>
  );
};

function ProductImages({ orderItems, onSelectItem }) {
  return (
    <Row>
      {orderItems.filter(i => 'OPTION' === i.parent_type).map(i => (
        <Col key={i.item_id}
          xs={12} sm={6} md={3} lg={2}
          style={{ cursor: 'pointer' }}
          onClick={e => onSelectItem(i.item_id)}
        >
          <Img src={getImageSrc(i.images[0], 'medium')} />
        </Col>
      ))}
    </Row>
  );
}
