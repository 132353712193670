import { useState, useEffect, useCallback } from 'react';
import { oauth } from '../utils';

function useChatSupportSession({connected, supportSessionCreated, identity, generatedId, tenantId, locationUrl, agentIds, sendMessage, supportUserName, supportUserEmail}) {
    const [session, setSession] = useState();
    const [sessionData, setSessionData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isAgent, setIsAgent] = useState(false);

    const createSession = useCallback(async () => {
        if (!supportSessionCreated) return false;
        setLoading(true);
        const data = await oauth('POST', 'chat-support-session', {
            'tenant_id': tenantId,
            'guest_id': generatedId,
            'guest_name': supportUserName,
            'guest_email': supportUserEmail,
            'url': locationUrl,
            'agent_ids': agentIds,
        });
        setSessionData(data.json.session);
        setSession(data.json.session.session_id);
        setLoading(false);

        // Place into proper chat channel for user
        sendMessage('setchannel:' + data.json.session.session_id);

        for (const user of agentIds) {
            const message = `${user}`;
            sendMessage(`sessionrequest:${message}`);
        }
    }, [agentIds, generatedId, locationUrl, sendMessage, supportSessionCreated, supportUserEmail, supportUserName, tenantId]);

    const lockAgentSession = useCallback(async () => {
        if (!supportSessionCreated) return false;
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session');

        if (!sessionId) {
            setSession(false);
            return false;
        }

        setLoading(true);
        let data = null;
        try {
            data = await oauth('PUT', 'chat-support-session', {
                'session_id': sessionId
            });
            setSessionData(data.json.session);
            setSession(data.json.session.session_id ?? false);
        } catch (e) {
            setSession(false); // Session is invalid or some other agent is already taking it.
        }

        setLoading(false);

        // Place into proper chat channel for agent
        sendMessage('setchannel:' + data.json.session.session_id);
        sendMessage('agentonline:' + data.json.session.session_id + ':' + identity.user_first_name);

        for (const user of agentIds) {
            const message = `${user}`;
            sendMessage(`sessionrequest:${message}`);
        }
    }, [agentIds, identity, sendMessage, supportSessionCreated]);

    useEffect(() => {
        if (!supportSessionCreated) return;
        if (session) return;
        if (!connected) return;

        if (identity && agentIds.includes(identity.user_id)) {
            setIsAgent(true);
            lockAgentSession();
        } else {
            setIsAgent(false);
            createSession();
        }
    }, [agentIds, connected, createSession, identity, lockAgentSession, session, supportSessionCreated]);

    return [
        session,
        sessionData,
        loading,
        isAgent,
    ];
}

export default useChatSupportSession;