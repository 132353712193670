import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Row, Col, Label, LabeledCheckbox,
} from '@commonsku/styles';
import PresalesFields from './PresalesFields';
import PreviewAddress from '../PreviewAddress';
import { contactsByCompanyIdOptions } from '../../../selectors/contacts';
import { addressByParentOptions, addressByTypeAndParentOptions } from '../../../selectors/addresses';
import { AddressSelect, ContactSelect } from '../Fields';

const EstimateFields = React.memo(({
    form,
    updateField,
    SelectMenuList,
    disableBillingContactSelect = false,
}) => {
    const contact_options = useSelector(s => contactsByCompanyIdOptions(s, form.client_id));
    const address_options = useSelector(s => addressByParentOptions(s, 'CLIENT', form.client_id));
    const shipping_address_options = useSelector(s =>
        addressByTypeAndParentOptions(s, 'SHIPPING', 'CLIENT', form.client_id)
    );
    const billing_address_options = useSelector(s =>
        addressByTypeAndParentOptions(s, 'BILLING', 'CLIENT', form.client_id)
    );

    useEffect(() => {
        if (form.sameAsBilling && (
            (form.shipping_contact_id !== form.billing_contact_id) ||
            (form.shipping_address_id !== form.billing_address_id)
        )) {
            updateField('shipping_contact_id', form.billing_contact_id);
            updateField('shipping_address_id', form.billing_address_id);
        }
    }, [
        updateField,
        form.sameAsBilling,
        form.shipping_contact_id,
        form.shipping_address_id,
        form.billing_contact_id,
        form.billing_address_id,
    ]);

    return (
        <>
            <PresalesFields
                form={form}
                updateField={updateField}
            />
            <Col xs sm={6} padded>
                <Label>Billing</Label>
                <Row>
                    <Col xs pb={10}>
                        <ContactSelect
                            isBilling
                            options={contact_options}
                            value={form.billing_contact_id}
                            onChange={value => updateField('billing_contact_id', value)}
                            components={{ MenuList: SelectMenuList }}
                            isDisabled={disableBillingContactSelect}
                        />
                    </Col>
                    <Col xs>
                        <AddressSelect
                            isBilling
                            options={billing_address_options}
                            value={form.billing_address_id}
                            onChange={value => updateField('billing_address_id', value)}
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs sm={6} padded>
                <Label>Shipping</Label>
                <Row>
                    <Col xs pb={10}>
                        <ContactSelect
                            isShipping
                            options={contact_options}
                            value={form.shipping_contact_id}
                            onChange={value => updateField('shipping_contact_id', value)}
                            isDisabled={form.sameAsBilling}
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                    <Col xs>
                        <AddressSelect
                            isShipping
                            options={shipping_address_options}
                            value={form.shipping_address_id}
                            onChange={value => updateField('shipping_address_id', value)}
                            isDisabled={form.sameAsBilling}
                            components={{ MenuList: SelectMenuList }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs padded end={1}>
                <LabeledCheckbox
                    label="Same as Billing"
                    checked={form.sameAsBilling}
                    onChange={e => updateField('sameAsBilling', !form.sameAsBilling)}
                />
            </Col>
            <Col xs sm={6} padded>
                <PreviewAddress address_id={form.billing_address_id} />
            </Col>
            <Col xs sm={6} padded>
                <PreviewAddress address_id={form.shipping_address_id} />
            </Col>
        </>
    );
});

export default EstimateFields;
