import React from 'react';
import { Select, LabeledSelect } from '../../helpers';

const AddressSelect = React.memo(({
    value,
    onChange,
    options,
    isDisabled = false,
    isBilling = false,
    isShipping = false,
    label = null,
    placeholder = 'Select Address',
    name = 'select_project_address',
    components={},
}) => {
    const SelectField = React.useMemo(() => {
        if (label) {
            return LabeledSelect;
        }
        return Select;
    }, [label]);

    return (
        <SelectField
            inPopup
            isClearable
            label={label}
            placeholder={placeholder}
            name={name}
            options={options}
            value={value}
            onChange={e => onChange(e ? e.value : '')}
            isAddress
            isBilling={isBilling}
            isShipping={isShipping}
            isDisabled={isDisabled}
            components={components}
        />
    );
});

export default AddressSelect;
