import { createContext, useContext } from 'react';
import useCart from '../hooks/useCart';

/**
 * @type {React.Context<undefined | ReturnType<typeof useCart>>} cartContext
 */
const cartContext = createContext();

const CartProvider = ({ children, entityId, isEntityFree=0 }) => {
  const value = useCart({ entityId, isEntityFree });
  return (
    <cartContext.Provider value={value}>
      {children}
    </cartContext.Provider>
  );
};

export const useCartContext = () => {
  const value = useContext(cartContext);
  return value;
};

export default CartProvider;
