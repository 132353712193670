import { dateStr, getImageSrc, round } from "../utils";

export const CHART_TYPE ={
    'SUPPLIER_PO_FILTER': {
      route:'supplier-po-chart',
      state_placeholder: 'supplier_po_results'
    },
    'SALES_BY_CLIENT_FILTER' : {
      route:'sales-by-client-chart',
      state_placeholder: 'client_sales_results'
    },
    'SO_BY_REP_FILTER' : {
      route:'so-by-rep-chart',
      state_placeholder: 'sales_orders_per_rep_results'
    },
    'MNGMNT_CHART_TARGETS' :{
      route:'update-chart-targets',
      state_placeholder: 'sales_target_results'
    }
};

export const timeOptions =[
    { value: 'TM', label: 'This month'},
    { value: 'TY', label: 'This year'},
    { value: 'LY', label: 'Last year'},
];

export const sales_by_rep_time_options =[
  {value:'week', label:'Week'},
  {value:'month', label:'Month'},
  {value:'year', label:'Year'},

];
var date = new Date();
export function getTimeStamp (code) {
    switch(code) {
      case 'TM':
        return {
          startStamp:dateStr(new Date(date.getFullYear(),date.getMonth(),1)),
          endStamp:dateStr(new Date(date.getFullYear(),date.getMonth()+1,1)),
          name: month_names[date.getMonth()]
      };
      case 'LM':
        return {
          startStamp:dateStr(new Date(date.getFullYear(),date.getMonth()-1,1)),
          endStamp:dateStr(new Date(date.getFullYear(),date.getMonth(),1)),
          name: month_names[date.getMonth()-1]
      };
      case 'TY':
        return {
          startStamp:dateStr(new Date(date.getFullYear(),0,1)),
          endStamp:dateStr(new Date(date.getFullYear()+1,0,1)),
          name:date.getFullYear()
        };
      case 'LY':
        return {
          startStamp:dateStr(new Date(date.getFullYear()-1,0,1)),
          endStamp:dateStr(new Date(date.getFullYear(),0,1)),
          name: date.getFullYear()-1
        };
      case 'TW':
        var mon_of_current_week = date.getDate() - (date.getDay() + 6) % 7;
        return {
          startStamp:dateStr(new Date(date.getFullYear(),date.getMonth(),parseInt(mon_of_current_week))),
          endStamp:dateStr(new Date(date.getFullYear(), date.getMonth(), date.getDate()+7)),
          name: "This week"
        };
      case 'LW' :
        var mon_of_current_week = date.getDate() - (date.getDay() + 6) % 7;
        var mon_of_previous_week = mon_of_current_week - 7;
        return {
          startStamp:dateStr(new Date(date.getFullYear(),date.getMonth(),parseInt(mon_of_previous_week))),
          endStamp:dateStr(new Date(date.getFullYear(),date.getMonth(),parseInt(mon_of_current_week))),
          name: "Previous week"
        };
      case 'month':
        return {
          startStamp: dateStr(new Date(date.getFullYear(),date.getMonth()-1,1)),
          endStamp: dateStr(new Date(date.getFullYear(), date.getMonth()+1),1),
          current_name: month_names[date.getMonth()],
          previous_name: month_names[date.getMonth()-1]
        };
      case 'year':
        return {
          startStamp: dateStr(new Date(date.getFullYear()-1,0,1)),
          endStamp: dateStr(new Date(date.getFullYear()+1,0,1)),
          current_name:date.getFullYear(),
          previous_name:date.getFullYear()-1
        };
      case 'week':
        var mon_of_current_week = date.getDate() - (date.getDay() + 6) % 7;
        var mon_of_previous_week = mon_of_current_week - 7;
        return {
          startStamp: dateStr(new Date(date.getFullYear(),date.getMonth(),parseInt(mon_of_previous_week))),
          endStamp: dateStr(new Date(date.getFullYear(), date.getMonth(), date.getDate()+1)),
          current_name: 'This week',
          previous_name: 'Previous week'
        };
    }
}

export const formatTargetMoney = (amount) => {
  if (+amount === 0) {
    return '0';
  }
  return Math.abs(parseInt(round(amount, 0).toFixed(0))).toLocaleString();
};
export const MNGMNT_TARGETS_POPUP = 'MNGMNT_TARGETS_POPUP';
export const month_names= ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const consecutive_years = [
  {label : date.getFullYear()-1, value: date.getFullYear()-1},
  {label : date.getFullYear(), value: date.getFullYear()},
  {label : date.getFullYear()+1, value: date.getFullYear()+1}
];

export function getCombinedTargetData(actual,target) {
  let  combinedData=[];
  if (actual) {
    for(let i=0; i< actual.length;i++) {
       combinedData.push({
        ...actual[i],
        ...(target.find((obj)=>obj.month == actual[i].month))
      });
    }
  }
  return  combinedData;
}

export const ANALYTICS_REPORT_TYPES = {
  'SALES_BY_CLIENT' : {
    route: 'sales-by-client-popup',
    title: 'Sales - ',
    type: {
      mngmnt : 'MNGMNT_SALES_BY_CLIENT',
      finance : 'FINANCE_SALES_BY_CLIENT',
      sales: 'SALES_SALES_BY_CLIENT'
    }
  },
  'INVOICES_VS_TARGET' : {
    route :'invoices-vs-target-popup' ,
    title : 'Invoices - ',
    type: {
      mngmnt : 'MNGMNT_INVOICES_VS_TARGET',
      finance : 'FINANCE_INVOICES_VS_TARGET',
      sales: 'SALES_INVOICES_VS_TARGET'
    }
  },
  'MARGIN_BY_CLIENT' : {
    route : 'margin-by-client-popup',
    title: 'Margin - ',
    type: {
      mngmnt : 'MNGMNT_MARGIN_BY_CLIENT',
      finance : 'FINANCE_MARGIN_BY_CLIENT',
      sales: 'SALES_MARGIN_BY_CLIENT'
    }
  },
  'SALES_ACTIVITY' : {
      route: 'sales-activity-popup',
      title: 'Sales Activity - ',
      type: {
        mngmnt : 'MNGMNT_SALES_ACTIVITY',
        finance : 'FINANCE_SALES_ACTIVITY',
        sales: 'SALES_SALES_ACTIVITY'
      }
  },
  'PROJECTION' : {
    route: 'projection-popup',
    title: 'Projection - ',
    type:{
      finance: 'FINANCE_PROJECTION'
    }
  },
  'SALES_ORDERS_IN_PRODUCTION' : {
    route : 'sales-orders-in-production-popup',
    title : 'Sales Orders - ',
    type: {
      mngmnt : 'MNGMNT_SALES_ORDERS_IN_PRODUCTION',
      sales: 'SALES_SALES_ORDERS_IN_PRODUCTION',
    }
  },
  'CHART_BY_REP': {
    route : 'chart-by-rep-popup',
    title: '',
    type: {
      mngmnt : 'MNGMNT_CHART_BY_REP'
    }
  }
};

export const analytics_report_names =  (Object.values(ANALYTICS_REPORT_TYPES).map(x=>Object.values(x.type))).flat();

