import { UPDATE_PRODUCT_ITEMS_SUCCESS } from '../actions/shop';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_ITEMS_SUCCESS:
      return { ...state, ...action.payload.item_costs };
  }
  return state;
};

export default reducer;
