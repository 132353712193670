import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { each } from 'lodash';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';

import ReskuApp from './containers/ReskuApp';

const RESKU_APP_PROPS = window.RESKU_APP_PROPS || {};

if (typeof(window.initialState) === 'undefined') {
  window.initialState = RESKU_APP_PROPS.initialState;
}

loadableReady(() => {
  const elem = document.getElementById(RESKU_APP_PROPS.id || 'root');
  if (module.hot) {
    const root = createRoot(elem);
    root.render(<ReskuApp {...RESKU_APP_PROPS}/>);
  } else {
    hydrateRoot(elem, <ReskuApp {...RESKU_APP_PROPS}/>);
  }
});

each(RESKU_APP_PROPS.extra_apps, (props) => {
  console.log('extra app', props);
  loadableReady(() => {
    const elem = document.getElementById(props.id);
    if (module.hot) {
      const root = createRoot(elem);
      root.render(<ReskuApp {...props}/>);
    } else {
      hydrateRoot(elem, <ReskuApp {...props}/>);
    }
  });
});
