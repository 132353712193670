import { createSlice } from '@reduxjs/toolkit';

const initialState = { filter: '' };

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = gallerySlice.actions;
export default gallerySlice.reducer;