import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Button, SidePanel, TabBar, Tab, Text, themeOptions } from '@commonsku/styles';

import Tasks from './Tasks';
import Shipping from './Shipping';
import Details from './Details';
import Proofing from './Proofing';
import ActionMenu from './ActionMenu';
import Loading from '../Loading';

import { createFetchJobForProduction } from '../../actions';
import { createFetchMessages } from '../../actions/message';
import { fetchProofs } from '../../actions/purchase_order_proof';
import { fetchSupplierContact, fetchShipments } from '../../actions/purchase_order';

const PO_SIDEBAR_TABS = [
  {
    key: 'notes',
    label: 'Overview'
  },
  {
    key: 'proof',
    label: 'Proofing'
  },
  {
    key: 'shipping',
    label: 'Shipping'
  },
  {
    key: 'details',
    label: 'Details'
  }
];

function POSidePanelTabs({ selectedTab, setSelectedTab }) {
  return (
    <Row>
      <Col xs={12}>
        <TabBar padded>
          {PO_SIDEBAR_TABS.map(tab => <Tab key={tab.key}
            selected={tab.key == selectedTab}
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.label}
          </Tab>)}
        </TabBar>
      </Col>
    </Row>
  );
}

function POSidePanelHeader({
  clientName,
  supplierName,
  poNumber,
  order_id,
  purchase_order_id,
  hasActionMenu,
  selectedTab,
  onClose,
}) {
  const menu = purchase_order_id && hasActionMenu &&
    <ActionMenu
      selectedTab={selectedTab}
      order_id={order_id}
      purchase_order_id={purchase_order_id}
    />;

  return (
    <Row>
      <Col xs md={6}>
        <Text
          style={{
            fontSize: '24px',
            lineHeight: '40px',
            fontWeight: 'bold',
            textDecoration: 'none',
            fontFamily: themeOptions.fontFamilies.demibold,
            color: '#000',
          }}
        >PO Tasks & Notes</Text>
      </Col>
      <Col xs md={6} style={{textAlign: 'right'}}>
        {menu}
        <Button onClick={onClose}>Close</Button>
      </Col>
      <Col xs style={{paddingTop: 10, paddingBottom: 10,}}>
        <Text as="span"
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 'bold',
            display: 'block',
            fontFamily: themeOptions.fontFamilies.demibold,
            color: '#000',
          }}
        >
          {clientName} - {supplierName} - PO #{poNumber}
        </Text>
      </Col>
    </Row>
  );
}

function POSidePanelBody({ loading, selectedTab, purchase_order_id, job_id, setZIndex }) {
  if (loading) {
    return <Loading style={{ position: 'absolute', top: '53%', left: '45%' }} />;
  }
  switch(selectedTab) {
    case 'proof':
      return <Proofing
        purchase_order_id={purchase_order_id}
        onShowPopup={() => setZIndex(999)}
        onClosePopup={() => setZIndex(1000)}
      />;
    case 'shipping':
      return <Shipping purchase_order_id={purchase_order_id} />;
    case 'details':
      return <Details purchase_order_id={purchase_order_id} />;
    default:
      return <Tasks
        purchase_order_id={purchase_order_id}
        job_id={job_id}
        onShowPopup={() => setZIndex(999)}
        onClosePopup={() => setZIndex(1000)}
      />;
  }
}

export default function POSidePanel({ sidePanelRow, setSidePanelRow, selectedTab, setSelectedTab, sidepanelZIndex=-1, actionMenu = true}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [zIndex, setZIndex] = useState(sidepanelZIndex);
  useEffect(() => {
    setZIndex(sidepanelZIndex);
  }, [sidepanelZIndex]);

  const onClose = React.useCallback(() => setSidePanelRow(null), [setSidePanelRow]);
  const clientName = sidePanelRow?.client_name || sidePanelRow?.order?.client_name || '';
  const supplierName = sidePanelRow?.supplier_name || sidePanelRow?.division_name || '';
  const poNumber = sidePanelRow?.form_number || '';

  const purchase_order_id = sidePanelRow?.purchase_order_id;
  const order_id = sidePanelRow?.order_id;
  const job_id = sidePanelRow?.job_id;

  useEffect(() => {
    if (purchase_order_id && job_id) {
      setLoading(true);
      Promise.all([
        dispatch(createFetchMessages(purchase_order_id, 'PURCHASE ORDER')),
        dispatch(fetchProofs(purchase_order_id)),
        dispatch(createFetchJobForProduction(job_id)),
        dispatch(fetchSupplierContact(purchase_order_id)),
        dispatch(fetchShipments(purchase_order_id)),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [purchase_order_id, job_id]);

  return (
    <SidePanel
      visible={sidePanelRow} // show the panel
      animationDuration={300} // duration of the animation of the slide
      from="right" // specify the location to slide from
      height={100} // height of the panel. Most likely used when `from` given with "top" or "bottom" value
      backdrop // add backdrop outside the panel
      header={
        <POSidePanelHeader
          clientName={clientName}
          supplierName={supplierName}
          poNumber={poNumber}
          order_id={order_id}
          purchase_order_id={purchase_order_id}
          hasActionMenu={actionMenu}
          selectedTab={selectedTab}
          onClose={onClose}
        />
      }
      style={{
        padding:'1em',
        fontFamily: 'skufont-regular, sans-serif, Roboto',
        ...(zIndex === -1 || zIndex === null ? {} : { zIndex: zIndex, }),
      }}
    >
      {setSelectedTab && <POSidePanelTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
      <POSidePanelBody
        loading={loading}
        selectedTab={selectedTab}
        purchase_order_id={purchase_order_id}
        job_id={job_id}
        setZIndex={setZIndex}
      />
    </SidePanel>
  );
}
