import React from 'react';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class TaggedProductSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.onChangeTag = this.onChangeTag.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  onChangeTag(tag_id) {
    let state;
    if (tag_id) {
      this.setState({ search: { ...this.state.search, tag_id }, error: false });
    } else {
      this.setState({ search: { ...this.state.search, tag_id }});
    }
  }

  loadDivisions() {
    this.props.loadDivisions('tagged-product');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        tag_id: '',
        division_id: ''
      };
    } else {
      return {
        tag_id: '',
        division_id: this.props.user_search.tag_division_id || ''
      };
    }
  }

  getEndpoint() {
    return 'product';
  }

  handleError() {
    this.setState({ error: true });
  }

  getSearchTerms(page) {
    let search_terms = {
      tag_id: this.state.search.tag_id,
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.division_id) {
      search_terms.division_id = this.state.search.division_id;
    }
    if (search_terms.tag_id == '') {
      this.handleError();
    } else {
      return search_terms;
    }
  }

  render() {
    const { company_type, divisions, tags, style } = this.props;
    const { error, search } = this.state;

    const division_id = search.division_id || (company_type === 'SUPPLIER' && divisions && divisions.length ? divisions[0].key : null);

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-4 columns">
            {tags &&
              <Select
                value={search.tag_id}
                searchable={true}
                clearable={true}
                options={tags}
                change={this.onChangeTag}
                error={error ? true : false}
                placeholder="Tag"
              />}
            {error && 'Please select a tag'}
          </div>
          <div className="medium-4 columns">
            {divisions &&
              <Select
                id="division"
                value={division_id}
                searchable={true}
                clearable={'SUPPLIER' !== company_type}
                options={divisions}
                change={e => this.onChangeDivision(e, 'tag_division_id')}
                placeholder="Supplier"
              />}
          </div>
          <div className="medium-4 columns">
            <div className="button-group expanded">
              <a className="button" onClick={this.handleClickSearch}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('tagged')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default TaggedProductSearchFilter;
