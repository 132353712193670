import React from 'react';

import { formatMoney } from '../utils';

const OrderMarginSummary = ({ order }) => {
  return (
    <div className="large-4 column" style={{ height: '100%', padding: '2px' }}>
      <table className="summary">
        <tbody>
          <tr>
            <td>Booked Margin Amount</td>
            <td>${formatMoney(order.total_gross_margin_amount)}</td>
          </tr>
          <tr>
            <td>Booked Margin</td>
            <td>{parseFloat(order.order_margin).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderMarginSummary;
