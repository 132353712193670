import React from 'react';

import Select from '../Select';
import ProductSearchFilter, { PAGE_SIZE } from './ProductSearchFilter';
import { Row } from '@commonsku/styles';

class PreviousOrderProductSearchFilter extends ProductSearchFilter {

  constructor(props) {
    super(props);

    this.onChangeOrder = this.onChangeOrder.bind(this);
    this.onChangeDivision = this.onChangeDivision.bind(this);
  }

  componentDidMount() {
    this.onChangeDivision(this.props.client_id);
  }

  onChangeDivision(division_id) {
    this.props.loadOrders(division_id, this.props.order_id, this.props.order_type);
    this.setState({ search: { ...this.state.search, order_id: '', division_id } });
  }

  onChangeOrder(order_id) {
    this.setState({ search: { ...this.state.search, order_id } });
  }

  loadDivisions() {
    this.props.loadDivisions('personal');
  }

  getInitialSearch(params) {
    if (params == 'reset') {
      return {
        search_term: '',
        division_id: this.props.client_id,
        order_id: ''
      };
    } else {
      return {
        search_term: this.props.user_search.search_term || '',
        division_id: this.props.client_id,
        order_id: ''
      };
    }
  }

  getEndpoint() {
    return 'item';
  }

  getSearchTerms(page) {
    let search_terms = {
      'max-results': PAGE_SIZE,
      ignore_id: this.props.order_id,
      order_type: this.props.order_type,
      'start-index': page * PAGE_SIZE
    };
    if (this.state.search.search_term) {
      search_terms.product_name = this.state.search.search_term;
    }
    if (this.state.search.division_id) {
      search_terms.client_id = this.state.search.division_id;
    }
    if (this.state.search.order_id) {
      search_terms.order_id = this.state.search.order_id;
    }
    return search_terms;
  }

  render() {
    const { divisions, order_id, order_type, getOrders, style } = this.props;
    const { search } = this.state;
    const orders = search.division_id ? getOrders(search.division_id) || [] : [];

    return (
      <>
        <Row style={{ paddingTop: '1rem' }}>
          <div className="medium-3 columns">
            <input
              type="text"
              placeholder="Search term"
              onChange={this.handleChangeSearchTerm}
              value={search.search_term}
              onKeyDown={e => this.handlePressEnter(e, order_type, order_id)}
            />
          </div>
          <div className="medium-3 columns">
            {divisions &&
              <Select
                id="division"
                value={search.division_id}
                searchable={true}
                clearable={true}
                options={divisions}
                change={this.onChangeDivision}
                placeholder="Client"
              />}
          </div>
          <div className="medium-3 columns">
            {orders &&
              <Select
                value={search.order_id}
                searchable={true}
                clearable={true}
                options={orders}
                change={this.onChangeOrder}
                placeholder="Order"
              />}
          </div>

          <div className="medium-3 columns">
            <div className="button-group expanded">
              <a className="button" onClick={e => this.handleClickSearch(e, order_type, order_id)}>Search</a>
              <a className="button" onClick={this.handleClickReset}>Reset</a>
            </div>
          </div>
        </Row>
        <Row style={{ overflow: 'auto', ...style }} onScroll={this.handleScroll}>
          {this.renderProducts('previous_order')}
          {this.renderLoading()}
        </Row>
      </>
    );
  }
}

export default PreviousOrderProductSearchFilter;
