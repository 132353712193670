import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

/**
 * The entity adapter
 */
const portalsAdapter = createEntityAdapter({
    selectId: (portal) => portal.client_portal_id,
    sortComparer: (a, b) => {
        // List default at top
        if (a.target === 'default') return -1;
        if (b.target === 'default') return 1;

        // category > tag > client
        if (a.target !== b.target) {
            if (a.target === 'category') {
                return -1;
            } else if (a.target === 'tag') {
                if (b.target === 'category') {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                return 1;
            }
        } else {
            return a.target_value.localeCompare(b.target_value);
        }
    }
});

/**
 * The slice/reducers
 */
const portalsSlice = createSlice({
    name: 'clientportals',
    initialState: portalsAdapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        portalAdded: portalsAdapter.addOne,
        portalDeleted: portalsAdapter.removeOne,
        portalsLoading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        portalsReceived(state, action) {
            if (state.loading === 'pending') {
                portalsAdapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
        portalUpdated: portalsAdapter.updateOne
    }
});

export const {
    portalAdded,
    portalDeleted,
    portalsLoading,
    portalsReceived,
    portalUpdated
} = portalsSlice.actions;

/**
 * The actions (thunk)
 */
export const getPortals = () => async (dispatch, getState) => {
    dispatch(portalsLoading());
    const res = await oauth('GET', 'client-portal-admin', {});
    dispatch(portalsReceived(res.json.portals));
    dispatch(portalsLoading('idle'));
    return res.json.portals;
};

export const addPortal = (data) => async (dispatch, getState) => {
    dispatch(portalsLoading());
    const res = await oauth('POST', 'client-portal-admin', data);
    dispatch(portalAdded(Object.assign(res.json.portal, {new: true})));
    dispatch(portalsLoading('idle'));
    return res.json.portal;
};

export const updatePortal = (data) => async (dispatch, getState) => {
    dispatch(portalsLoading());
    const res = await oauth('PUT', 'client-portal-admin', data);
    dispatch(portalUpdated({ id: data.client_portal_id, changes: res.json.portal }));
    dispatch(portalsLoading('idle'));
    return res.json.portal;
};

export const deletePortal = (portal) => async (dispatch, getState) => {
    dispatch(portalsLoading());
    try {
        await oauth('DELETE', 'client-portal-admin/' + portal.client_portal_id, {});
    } catch (err) {
        console.log('Failed to delete portal');
        return false;
    }
    dispatch(portalDeleted(portal.client_portal_id));
    dispatch(portalsLoading('idle'));
    return true;
};

/**
 * The selectors
 */
export const portalSelectors = portalsAdapter.getSelectors();

export function defaultPortalData(isDefault) {
    return {
        client_portal_id: null,
        tenant_id: null,
        simple: "0",
        theme_color: '',
        show_client_specific: "1",
        restrict_to_user_projects: "0",
        target: isDefault ? 'default': 'client',
        target_value: null,
        label: null,
        shops: '',
        images: '',
        start_project_message: '',
        reorder_message: '',
        portal_logo: '',
    };
};

export default portalsSlice.reducer;

