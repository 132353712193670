import React from 'react';
import { connect } from 'react-redux';

import SideBar from '../components/Sidebar';

import createPopup from '../popup-factory';

const MainSection = ({ style, children, popups, identity, menu, no_sidebar, lockedOut, ...props }) => (
  <div className="row full-width content" style={style}>
    {!no_sidebar ? <SideBar identity={identity} menu={menu} lockedOut={lockedOut} /> : null}
    {children}
    {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
  </div>
);

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
  };
};

export default connect(mapStateToProps)(MainSection);
