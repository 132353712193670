import _ from 'lodash';
import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Row, Col, colors,SkubotLogo } from '@commonsku/styles';
import CskuMenu from '../containers/CskuMenu';
import About from '../containers/About';
import { getIdentityUtils } from '../utils';

export default class Sidebar extends Component {
  toggleTimeoutId = null;

  constructor(props) {
    super(props);
    const canToggleMenu = !!props.menu;
    this.state = {
      canToggleMenu,
      showCskuMenu: !canToggleMenu,
      toggleMainMenu: false,
    };

    this.toggleMenu = this._debounceToggleMenu();
    this.toggleMainMenu = this.toggleMainMenu.bind(this);
  }

  _debounceToggleMenu() {
    const debounce = _.debounce((show) => {
      this.setState({showCskuMenu: show});
    }, 300);

    return (show, flash) => {
      if (!this.state.canToggleMenu) {
        return;
      }
      debounce(show);
      if (flash) {
        debounce.flush();
      }
    };
  }

  toggleMainMenu(show) {
    if (show) {
      this.toggleTimeoutId = setTimeout(() => {
        this.setState({ toggleMainMenu: show });
      }, 1000);
    } else {
      if (this.toggleTimeoutId) {
        clearTimeout(this.toggleTimeoutId);
      }
      this.setState({ toggleMainMenu: show });
    }
  }

  renderV2BaseMenu() {
    const { menu, identity, } = this.props;
    const { showCskuMenu } = this.state;

    return (
      <div
        className="menu-container"
        style={{position: 'absolute', bottom: 'auto',}}
      >
        {menu ?
          <Row>
            <Col
              className={"vertical-csku-main-menu-v2-collapsed-wrapper" + (this.state.toggleMainMenu ? ' is-hover ' : '')}
              xs={3.5}
              style={{background: colors.primary2, height: '100vh', paddingTop: '10px', boxShadow: '5px 0px 4px rgba(0, 0, 0, 0.25)'}}
              onMouseEnter={() => this.toggleMainMenu(true)}
              onMouseLeave={() => this.toggleMainMenu(false)}
            >
              <About identity={identity} style={{padding: '0 5px', }} />
              {this.renderCskuMenu(true, { collapsed: true, toggleMainMenu: this.state.toggleMainMenu })}
            </Col>
            <Col
              className="vertical-csku-sub-menu-v2-wrapper"
              xs={8.5}
              style={{
                background: colors.primary20,
                height: '100vh',
                paddingTop: '10px',
                boxShadow: '-2px 0px 10px rgba(0, 0, 0, 0.1)',
                ...(this.state.toggleMainMenu ? {
                  position: 'absolute',
                  zIndex: -4,
                  left: 51,
                  width: '100%',
                } : {}),
              }}
            >
              {menu}
              <div className="powered-by powered-by-v2" style={{position: "fixed", bottom: "0px", left: "80px"}}>
                <SkubotLogo color='white' width="64" height="32" />
             </div>
            </Col>
          </Row> : null}
        {showCskuMenu && !menu ?
          <CSSTransition
            key="csku-menu"
            classNames="csku-menu"
            timeout={300}>
            {this.renderCskuMenu()}
          </CSSTransition>
        : null}
      </div>
    );
  }

  renderBaseMenu() {
    const { menu, identity } = this.props;
    const { showCskuMenu } = this.state;
    const { hasCapabilities } = getIdentityUtils(identity);
    const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');
    if (v2) {
      return this.renderV2BaseMenu();
    }

    return (
      <TransitionGroup
        className="menu-container">
        {menu ?
          <CSSTransition
            key="menu"
            classNames="csku-menu"
            timeout={300}>
            {menu}
          </CSSTransition>
        : null}
        {showCskuMenu ?
          <CSSTransition
            key="csku-menu"
            classNames="csku-menu"
            timeout={300}>
            {this.renderCskuMenu()}
          </CSSTransition>
        : null}
      </TransitionGroup>
    );
  }

  renderCskuMenu(skipCheck=false, props={}) {
    const { showCskuMenu } = this.state;
    if (!showCskuMenu && !skipCheck) {
      return null;
    }
    return (
      <CskuMenu className="csku-menu"
        onMouseEnter={() => {this.toggleMenu(true);}}
        onMouseLeave={() => {this.toggleMenu(false);}}
        {...props}
      />
    );
  }

  render() {
    const {
      identity, menu
    } = this.props;
    const {
      showCskuMenu,
    } = this.state;

    const { hasCapabilities } = getIdentityUtils(identity);
    const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');
    const classNames = [
      'sidebar',
      'show-for-medium',
      v2 ? 'sidebar-v2' : '',
      v2 && menu ? 'sidebar-v2-with-menu' : '',
    ].join(' ');
    return <div className={classNames}>
      {(!menu && v2) || !v2 ? <About identity={identity} /> : null}
      {menu && !v2 &&
        <div className="button menu-toggle-button"
          onClick={() => {this.toggleMenu(!showCskuMenu, true);}}
          onMouseLeave={() => {this.toggleMenu(false);}}>
          <span>Menu </span>
          <span className={showCskuMenu ? 'up-arrow' : 'down-arrow'}></span>
        </div>}
      {!menu ? this.renderCskuMenu(false, { lockedOut: this.props.lockedOut }) : this.renderBaseMenu() }
      {(!menu && v2) || !v2 ? <div
        className={'powered-by ' + (menu ? '' : 'sidebar-600') + (v2 ? ' powered-by-v2 ' : '')}
        style={v2 ? {position: 'absolute', left: "50%", transform: "translateX(-50%)", bottom: 0, zIndex: -1} : {position: 'absolute', left: "50%", transform: "translateX(-50%)"}}
      >
        <img src="/images/commonsku-main-logo.svg"  style={{width: '120px', opacity: v2 ? 1 : 0.7}}/>
      </div> : null}
    </div>;
  }
}
