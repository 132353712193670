import { get } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ArrowIcon, Button, Col, Csku, Grid, GridItem, LabeledCheckbox, Popup, Row, Text } from "@commonsku/styles";
import { createUploadFile } from "../../../../actions/file";
import { getImageSrc } from "../../../../utils";
import { getShopCategoryUrl } from '../../helpers';
import FullScreenLoading from "../../../helpers/FullScreenLoading";
import UploadImagesTabs from "../../image/UploadImagesTabs";
import { useContainerScroll } from "../../../../hooks";
import { createDeletePublicViewTemplateData, createUpdatePublicViewTemplateData } from "../../../../actions/shop";
import { PublicViewTemplate } from '../../../../models/PublicViewTemplate';
import { usePublicViewEntityContext } from "../../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../../context/PublicViewTemplateDataProvider";

const CATEGORY_IMAGE_TYPES = {
  MAIN: 'MAIN',
  HOVER: 'HOVER',
};

const POSTER_THEME_CATEGORY_TITLE_STYLE = {
  background: "transparent",
  fontSize: "60px",
  fontFamily: "Bebas Neue",
  color: "#fff",
  width: "100%",
  height: "100%",
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

export default function SelectCategoryPopup(props) {
  const {
    showCategoryTitle,
    onClose,
    popupConfig,
    themeName
  } = props;
  const dispatch = useDispatch();

  const {
    entityId,
    entityType,
    entityOrder: gallery,
  } = usePublicViewEntityContext();
  const {
    templateId,
    templateColor,
    template_data,
    getPosterCategories,
    getShopCategoryWithImages,
  } = usePublicViewTemplateDataContext();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);
  const [selectedItemId, setItemId] = useState(false);
  const { bannerOnHover } = popupConfig;
  const isPoster = themeName === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
  const useProductImages = +(template_data.useProductImages?.value ?? '1') === 1;

  const categories = useMemo(() => {
    const items = get(gallery, ['items']) || [];
    if (isPoster) {
      return getPosterCategories(items).filter(it => it.item_id !== 'ALL_PRODUCTS');
    }
    return getShopCategoryWithImages(items);
  }, [gallery, isPoster, getPosterCategories, getShopCategoryWithImages,]);

  const ref = useRef();
  const { elemRect } = useContainerScroll(ref, [
    showCategoryTitle,
    selectedItemId,
  ]);
  const popupBodyHeight = (elemRect.height || 250) - (100 + 75);

  const selectedCategoryUrls = useMemo(
    () => {
      const result = { url: '', hoverUrl: false };
      if (!selectedItemId) { return result; }

      const category = categories.find(it => it.item_id === selectedItemId.item_id);
      if (!category) { return result; }

      return getShopCategoryUrl(
        categories.find(it => it.item_id === selectedItemId.item_id),
        hoverImage,
        useProductImages
      );
    },
    [categories, selectedItemId, hoverImage, useProductImages]
  );

  const onUpdateTemplateField = (field, value) => {
    return dispatch(
      createUpdatePublicViewTemplateData(
        entityId,
        entityType,
        templateId,
        field,
        value
      )
    );
  };

  const onChangeMainImage = (item_id, item_name) => {
    setItemId({ item_id: item_id, type: CATEGORY_IMAGE_TYPES.MAIN, item_name });
  };
  const onChangeHoverImage = (item_id, item_name) => {
    setItemId({ item_id: item_id, type: CATEGORY_IMAGE_TYPES.HOVER, item_name });
  };
  const onDeleteHoverImage = async () => {
    if (!selectedItemId) {
      return;
    }
    const { item_id, type } = selectedItemId;
    const fieldName = `CATEGORY-${item_id}-IMAGE-${type}`;
    await dispatch(createDeletePublicViewTemplateData(
      entityId, entityType, templateId, fieldName
    ));
    setItemId(false);
  };

  const onChangeBackground = useCallback(async (image_src) => {
    if (!selectedItemId) {
      return;
    }
    const { item_id, type } = selectedItemId;
    const fieldName = `CATEGORY-${item_id}-IMAGE-${type}`;
    setUpdateLoading(true);
    await dispatch(createUpdatePublicViewTemplateData(
      entityId, entityType, templateId, fieldName, image_src
    ));
    setUpdateLoading(false);
    setItemId(false);
  }, [dispatch, selectedItemId, entityId, entityType, templateId]);

  const onUploadFile = useCallback(async (files) => {
    if (!selectedItemId || !entityId) { return; }
    setUpdateLoading(true);
    await dispatch(createUploadFile(entityId, entityType, files[0])).then(
      action => onChangeBackground(getImageSrc(action.payload.data, 'headers'))
    );
    setUpdateLoading(false);
  }, [dispatch, entityId, entityType, selectedItemId, onChangeBackground]);

  const UploadImage = selectedItemId ? (
    <>
      {selectedItemId.type === CATEGORY_IMAGE_TYPES.HOVER && selectedCategoryUrls.hoverUrl ?
        <div style={{ display: 'right', float: 'right' }}>
          <Button size="medium" variant="error" onClick={onDeleteHoverImage}>Delete Hover Image</Button>
        </div> : null}
      <UploadImagesTabs
        height={popupBodyHeight}
        imgSearchBoxHeight={popupBodyHeight-140}
        dropzoneHeight={popupBodyHeight-60}
        isCarousel={false}
        showCarousel={false}
        CarouselImagesGrid={null}
        onUploadFile={onUploadFile}
        onChangeBackground={onChangeBackground}
        showRecommendedSize={false}
      />
    </>
  ) : null;

  const CategoriesGrid = !selectedItemId ? (
    <Grid gap={2} columns={2} style={{ height: popupBodyHeight-30, overflowY: 'auto' }}>
      {categories.map(it => (
        <GridItem key={`category-${it.item_id}`} colSpan={{ xs: 2, sm: 1 }} style={{ padding: 14, }}>
          <CategoryCard
            category={it}
            template_color={templateColor}
            showCategoryTitle={showCategoryTitle}
            useProductImages={useProductImages}
            hoverImage={hoverImage}
            setHoverImage={setHoverImage}
            onChangeMainImage={() => onChangeMainImage(it.item_id, it.item_name)}
            onChangeHoverImage={() => onChangeHoverImage(it.item_id, it.item_name)}
            withHover={bannerOnHover}
            isPoster={isPoster}
          />
        </GridItem>
      ))}
    </Grid>
  ) : null;

  const hasExistingImg = (
    (selectedItemId?.type === 'MAIN' && selectedCategoryUrls?.url) ||
    (selectedItemId?.type === 'HOVER' && selectedCategoryUrls?.hoverUrl)
  );
  const action = hasExistingImg ? 'Change' : 'Add';

  return (
    <>
      <FullScreenLoading loading={updateLoading} message="Updating...Please Wait" zIndex={110} />
      <Popup ref={ref} noHeader closeOnEsc
        className={"popup select-category-popup"}
        contentClassName={`select-category-popup-content ${selectedItemId ? 'select-category-upload-image' : ''}`}
        style={{ maxWidth: 980, padding: 24 }}
        onClose={onClose}
      >
        <Row style={{ position: 'relative' }}>
          <Col xs>
            <Text as="p" style={{
              textAlign: 'left',
              fontFamily: 'var(--font-family-bold)',
              color: 'var(--color-neutrals-90)',
              fontSize: 24,
              marginBottom: 0,
              lineHeight: '1.3'
            }}>
              {selectedItemId ? <ArrowIcon
                direction="left"
                style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                onClick={() => setItemId(false)}
              /> : null}
              <span style={{ paddingLeft: 14 }}>
                {!selectedItemId ? 'Select a category to edit' : `${selectedItemId.item_name}: ${action} your ${selectedItemId.type.toLowerCase()} image`}
              </span>
            </Text>
            <Text as="p" style={{
              color: 'var(--color-neutrals-90)',
              fontSize: 16,
              fontFamily: 'var(--font-family-regular)',
              paddingTop: 14,
            }}>
              {!selectedItemId
                ? '** Please note that Categories titles and products need to be edited in the Products tab of this Shop.'
                : 'Select an image below or upload your own. Preferred size is 1400x whatever.'}
            </Text>
            {!selectedItemId ? <div>
              <LabeledCheckbox
                label="Use product Images for category images"
                checked={useProductImages}
                onChange={() => onUpdateTemplateField(
                  'useProductImages',
                  useProductImages ? 0 : 1
                )}
              />
            </div> : null}
          </Col>
          <Col xs>
            {categories.length === 0 ?
              <p style={{textAlign: 'center', fontWeight: 'bold'}}>You do not have any categories yet. Create them by making titles in the products tab.</p>
            : null}
            <div style={{ height: popupBodyHeight, }}>
              {UploadImage}
              {CategoriesGrid}
            </div>
          </Col>
          {!selectedItemId ? <Col xs style={{
            position: 'absolute',
            top: 'auto',
            bottom: -20,
            width: 'calc(100% - 30px)',
            textAlign: 'center'
          }}>
            <Button size="medium" onClick={onClose}>Done</Button>
          </Col> : null}
        </Row>
      </Popup>
    </>
  );
}

const CategoryCard = (props) => {
  const {
    category,
    template_color,
    hoverImage,
    useProductImages,
    showCategoryTitle,
    setHoverImage,
    onChangeMainImage,
    onChangeHoverImage,
    withHover=true,
    isPoster=false,
  } = props;

  const { url, hoverUrl } = useMemo(
    () => getShopCategoryUrl(category, hoverImage, useProductImages, +isPoster),
    [category, hoverImage, isPoster, useProductImages]
  );

  return (
    <div>
      <div
        style={{
          position: "relative",
          height: 274,
          ...(hoverImage === category.item_id
            ? {
                outlineColor: template_color,
                outlineStyle: "solid",
                outlineWidth: 2,
              }
            : {}),
        }}
      >
        <div
          style={{
            height: 274,
            backgroundImage: `url('${url}')`,
            backgroundRepeat: "no-repeat",
            textAlign: "center",
            backgroundSize: "cover",
            transition: "background 500ms",
            borderBottom: "none",
          }}
          onMouseOver={(e) => setHoverImage(category.item_id)}
          onMouseOut={(e) => setHoverImage("")}
        ></div>
        {showCategoryTitle && (
          <div
            className="label"
            style={{
              color: template_color,
              background: "#fff",
              position: "absolute",
              bottom: 0,
              textAlign: "center",
              whiteSpace: "unset",
              padding: "1em",
              borderRadius: 0,
              marginBottom: "-2px",
              fontSize: "1rem",
              ...(isPoster ? POSTER_THEME_CATEGORY_TITLE_STYLE : {})
            }}
          >
            {category && category.item_name}
          </div>
        )}
      </div>
      <div>
        <Csku
          as={Button}
          forceStyles
          disabled={useProductImages}
          variant={useProductImages ? "disabled" : "secondary"}
          sx={{
            xs: {
              margin: "14px 0px 7px 0px",
              width: "100%",
              textAlign: "center",
            },
            sm: { margin: "14px 7px 7px 0px", width: withHover ? "48%" : "100%" },
          }}
          onClick={onChangeMainImage}
        >
          Change Main Image
        </Csku>
        {withHover && (
          <Csku
            as={Button}
            forceStyles
            disabled={useProductImages}
            variant={useProductImages ? "disabled" : "secondary"}
            sx={{
              xs: {
                margin: "14px 0px 7px 0px",
                width: "100%",
                textAlign: "center",
                padding: "12px 12px",
              },
              sm: { margin: "14px 0px 7px 7px", width: "48%" },
            }}
            onClick={onChangeHoverImage}
          >
            {hoverUrl ? "Change" : "Add"} Hover Image
          </Csku>
        )}
      </div>
    </div>
  );
};
