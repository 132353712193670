import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { getImageSrc } from '../utils';

export function getCategoryItems(items, categoryId) {
  items = orderBy(items, [(i) => parseInt(i.display_order)], 'asc');

  if (!categoryId) {
    return items.filter(i => 'OPTION' === i.parent_type);
  }
  const startIndex = items.map(i => i.item_id).indexOf(categoryId);
  if (-1 === startIndex) {
    return [];
  }
  const endIndex = items.slice(startIndex + 1).map(i => i.parent_type).indexOf('SEPARATOR');
  if (-1 === endIndex) {
    return items.slice(startIndex).filter(i => 'OPTION' === i.parent_type);
  }
  return items.slice(startIndex, startIndex + endIndex + 1).filter(i => 'OPTION' === i.parent_type);
}

/**
 * validate Shop Options
 *
 * @param {unknown} shop_options shop_options
 * @returns {shop_options is import('../interfaces/Shop').ShopOptions}
 */
function validateShopOptions(shop_options) {
  return shop_options && typeof shop_options === 'object'
    && (typeof shop_options.ship_address_countries === 'undefined'
        || Array.isArray(shop_options.ship_address_countries))
    && (['undefined', 'boolean'].includes(typeof shop_options.use_zip2tax_default));
}

export const getShopOptions = (shop_options = {}) => {
  /**
   * @type {import('../interfaces/Shop').ShopOptions} options
   */
  let options = {};
  try {
    if (typeof shop_options === 'string') {
      options = JSON.parse(shop_options || '{}');
    } else {
      options = shop_options || {};
    }
  } catch (error) {
    options = {};
  }
  if (!validateShopOptions(options)) {
    throw new Error("Invalid shop options");
  }
  return options;
};

export function getCarouselImagesFromShop(shop, limit = 1) {
  return orderBy(
    getHeroImagesFromTemplateData(
      get(shop, ['template_data', shop.public_view_layout_id]) || {},
      limit
    ).map((image) => ({
      ...image,
      original: !image.image || image.image.length === 0 ? '/images/template3.jpg' : image.image,
    })),
    [v => parseInt(v.key.replace('-image', '').replace('-title', '').replace('hero', ''))],
    ['asc']
  );
}

export function getCarouselImagesLastPosFromShop(shop, limit = 1) {
  const carouselImages = getCarouselImagesFromShop(shop, limit);
  let heroImagesLen = carouselImages.length;
  if (heroImagesLen > 0) {
    const heroImages = orderBy(
      carouselImages.map(v => v.key.replace('-image', '').replace('-title', '').replace('hero', '')),
      [v => parseInt(v)],
      ['asc']
    );
    heroImagesLen = parseInt(heroImages[heroImages.length-1]);
    if (isNaN(heroImagesLen)) {
      heroImagesLen = carouselImages.length;
    }
  }
  return heroImagesLen;
}

export function getHeroImagesFromTemplateData(templateData, limit = 1) {
  if (!templateData) return [];

  let index = 0;
  const existing = [];
  let largeIndex = 0;
  for (const data in templateData) {
    if (data.indexOf('hero') === 0) {
      const index = parseInt(data.split('-')[0].replace('hero', ''));
      if (index > largeIndex) largeIndex = index;
    }
  }
  while (index <= largeIndex) {
    if (templateData[`hero${index}-image`] || templateData[`hero${index}-title`]) {
      existing.push({
        key: `hero${index}`,
        image: templateData[`hero${index}-image`] ? templateData[`hero${index}-image`].value : '',
        title: templateData[`hero${index}-title`] ? templateData[`hero${index}-title`].value : '',
      });
    }
    index++;
  }

  while (existing.length < limit) {
    largeIndex++;
    existing.push({
      key: `hero${largeIndex}`,
      image: '',
      title: '',
    });
  }

  return existing;
}

export function getCategoriesFromSingleShop(singleShopData, shopId) {
  let categories = [];
  if (singleShopData) {
      const shop = singleShopData.shops[shopId];
      if (shop) {
          const items = Object.values(singleShopData.items);
          const allItemImages = Object.values(singleShopData.item_images);
          const categoriesFromItems = items.filter(i => 'SEPARATOR' === i.parent_type);
          for (const category of categoriesFromItems) {
              const categoryItemImages = allItemImages.filter(i => i.item_id === category.item_id);
              let itemImages = [];
              if (categoryItemImages.length > 0) {
                  itemImages = categoryItemImages.map(i => getImageSrc(i, 'medium'));
              } else {
                  const categoryItems = getCategoryItems(items, category.item_id).filter(i => i.hidden != 1);
                  for (const categoryItem of categoryItems) {
                      const image = allItemImages.find(i => i.item_id === categoryItem.item_id);
                      itemImages.push(getImageSrc(image, 'medium'));
                  }
              }

              categories.push({
                  id: category.item_id,
                  shopId: shopId,
                  image: itemImages[0],
                  name: category.item_name,
              });
          }
      }
  }

  return categories;
};

export function getImagesFromSingleShop(singleShopData, shopId) {
  let images = [];
      if (singleShopData) {
          const shop = singleShopData.shops[shopId];
          if (shop) {
              const template_data = shop.template_data[shop.public_view_layout_id];
              images = getHeroImagesFromTemplateData(template_data, 1).map((image) => ({
                  original: image.image.length === 0 ? '/images/template3.jpg' : image.image,
                  title: image.title,
              }));
          }
      }
  return images;
};

const getShopPreviewImage = (shop) => {
  if (shop.portal_image && shop.portal_image !== '') return shop.portal_image;
  if (shop.item_preview_image) return getImageSrc(shop.item_preview_image, 'medium');

  return '/images/template3.jpg';
};

export function getCategoriesFromShops(shopIds, allShops, singleShop, clientName = false, showClientSpecific) {
  const categories = [];
  if (!singleShop) {
        const openShops = allShops.filter(s => {
          if (s.status_name === 'Closed') return false;
          if (s.date_expiry) {
              const expired = Date.parse(s.date_expiry);
              if (Date.now() > expired ) return false;
          }
          return true;
      });

      for (const shopId of shopIds) {
          const shop = openShops.find(s => s.shop_id === shopId);
          if (!shop) continue;
          categories.push({
              id: shop.shop_id,
              image: getShopPreviewImage(shop),
              name: shop.shop_name,
              shopData: shop,
          });
      }

      // Add client specific shop
      if (clientName && showClientSpecific == 1) {
          for (const shop of openShops) {
              if (shop.type !== 'Marketing' && shop.client_name === clientName && categories.filter(c => c.id === shop.shop_id).length === 0) {
                  categories.push({
                      id: shop.shop_id,
                      image: getShopPreviewImage(shop),
                      name: shop.shop_name,
                      shopData: shop,
                  });
              }
          }
      }
  }
  return categories;
};