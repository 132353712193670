import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSelect from './Select';

import { createFinalizeAddTax } from '../actions/tax';
import { getTaxOptions } from '../selectors/dropdowns';
import { oauth } from '../utils';
import { getCompanyData } from '../selectors';
import Tax from '../models/Tax';
import { window } from '../global';
import { uniqueOptions } from '../helpers';

const DEFAULT_TAXES = ['Exempt (0%)', 'Tax Included', 'Avalara'];

class TaxSelect extends Component {

  constructor(props) {
    super(props);

    this.state = { tax_results: [] };

    this.loadTaxes = this.loadTaxes.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  /*
  componentDidMount() {
    if(this.props.value && this.props.zip2tax) {
      this.onChange({ value: this.props.value })
    }
  }
  */

  /* UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.base_taxes !== nextProps.base_taxes) {
      this.forceUpdate()
    }
  } */

  loadTaxes(input, callback) {
    const { base_taxes, value } = this.props;
    const { tax_results } = this.state;
    const taxes = base_taxes.concat(tax_results.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` })));
    if (!input || !input.length) {
      callback(taxes.filter(t => t.value === value || DEFAULT_TAXES.indexOf(t.label) > -1));
      return;
    }
    oauth('GET', 'tax', { zip2tax: input }).then(
      ({ json }) => {
        this.setState({ tax_results: json.taxes });
        callback(base_taxes.filter(t => DEFAULT_TAXES.indexOf(t.label) > -1)
          .concat(json.taxes.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` }))));
      }
    );
  }

  onChange(e) {
    const { value, company_data, onAddTax, onChange, shouldAlert = true } = this.props;
    if (value !== e.value && e.value === Tax.avalara_tax_id && shouldAlert) {
      let msg = '';
      if (1 == company_data.avalara_testing) {
        msg = 'You are in Avalara testing mode, you are responsible to clean up related records';
      } else {
        msg = 'You are switching a non-avalara order to avalara, this will remove all existing tax records on this order';
      }
      if (!window.confirm(msg)) {
        return;
      }
    }
    const tax_details = this.state.tax_results.filter(t => e.value === t.tax_id)[0];
    if (tax_details) {
      return onAddTax(tax_details).then(() => onChange(e.value));
    } else {
      onChange(e.value);
    }
  }

  render() {
    const {
      value,
      disabled,
      base_taxes,
      zip2tax = false
    } = this.props;

    if (zip2tax) {
      const taxes = base_taxes.concat(this.state.tax_results.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` })));
      const options = uniqueOptions(
        taxes.filter(t => {
          return t.value === value || DEFAULT_TAXES.indexOf(t.label) > -1;
        })
      );
      return (
        <BaseSelect
          async
          value={value}
          cacheOptions={false}
          clearable={false}
          options={options}
          disabled={disabled}
          loadOptions={this.loadTaxes}
          onChange={this.onChange}
          defaultOptions={options}
        />
      );
    }
    return (
      <BaseSelect
        value={value}
        clearable={false}
        options={base_taxes}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const company_data = getCompanyData(state) || {};
  let base_taxes = getTaxOptions(state).map(t => ({ value: t.key, label: t.value }));
  if (company_data.avalara_enable && 0 == company_data.avalara_testing && ownProps.avalara) {
    base_taxes = base_taxes.filter(t => t.value === ownProps.value);
  }
  if (ownProps.tax_included) {
    base_taxes = [{ value: '', label: 'Tax Included' }].concat(base_taxes);
  }
  if (company_data.avalara_enable && ownProps.avalara) {
    base_taxes = [{ value: Tax.avalara_tax_id, label: 'Avalara' }].concat(base_taxes.filter(t => t.value !== Tax.avalara_tax_id));
  }
  return { base_taxes, company_data };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddTax: tax => Promise.resolve(dispatch(createFinalizeAddTax(tax)))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxSelect);
