import { get } from 'lodash';
import React from 'react';

import { getImageSrc } from '../../utils';
import styled from 'styled-components';

const DEFAULT_SUPPLIER_AVATAR = '/images/supplier-avatar-64x64.png';
const SupplierAvatar = ({ style, avatar, className, size = 'small' }) => {
  const imageSize = get({ middle: 'large' }, size, size);
  return <img style={style} className={`${className} ${size}`} src={getImageSrc(avatar, imageSize, { defaultImage: DEFAULT_SUPPLIER_AVATAR })} />;
};

export default styled(SupplierAvatar)`
  &&& {
    height: auto;
    display: block;
    margin: auto;
    cursor: pointer;
    &.large {
      width: 200px
    }
    &.medium {
      width: 150px
    }
  }
`;
