import {
    LOAD_DECORATIONS_REQUEST, LOAD_DECORATIONS_SUCCESS, LOAD_DECORATIONS_FAILURE,
    LOAD_DECORATORS_REQUEST, LOAD_DECORATORS_SUCCESS, LOAD_DECORATORS_FAILURE,
    ADD_DECORATOR_STATE_SUCCESS, ADD_DECORATOR_STATE_FAILURE,
    UPDATE_DECORATOR_SUCCESS, UPDATE_DECORATOR_FAILURE,
    DELETE_DECORATOR_STATE_SUCCESS, DELETE_DECORATOR_STATE_FAILURE,
    SET_DECORATOR_ID_SUCCESS, SET_DECORATOR_ID_FAILURE,
    CREATE_DECORATION_SUCCESS, ADD_DECORATION_REQUEST, ADD_DECORATION_SUCCESS, ADD_DECORATION_FAILURE,
    UPDATE_DECORATION_REQUEST, UPDATE_DECORATION_SUCCESS, UPDATE_DECORATION_FAILURE,
    DELETE_DECORATION_REQUEST, DELETE_DECORATION_SUCCESS, DELETE_DECORATION_FAILURE,
    COPY_DECORATION_REQUEST, COPY_DECORATION_SUCCESS, COPY_DECORATION_FAILURE,
    UPDATE_MATRIX_CELL_REQUEST, UPDATE_MATRIX_CELL_SUCCESS, UPDATE_MATRIX_CELL_FAILURE,
    UPDATE_MATRIX_CELL_STATE,
    UPDATE_MATRIX_SUCCESS, UPDATE_DECORATOR_STATE_SUCCESS,
    ADD_MATRIX_ROW_REQUEST, ADD_MATRIX_ROW_SUCCESS, ADD_MATRIX_ROW_FAILURE,
    // UPDATE_MATRIX_ROW_REQUEST, UPDATE_MATRIX_ROW_SUCCESS, UPDATE_MATRIX_ROW_FAILURE,
    REMOVE_MATRIX_ROW_REQUEST, REMOVE_MATRIX_ROW_SUCCESS, REMOVE_MATRIX_ROW_FAILURE,
    ADD_MATRIX_COL_REQUEST, ADD_MATRIX_COL_SUCCESS, ADD_MATRIX_COL_FAILURE,
    // UPDATE_MATRIX_COL_REQUEST, UPDATE_MATRIX_COL_SUCCESS, UPDATE_MATRIX_COL_FAILURE,
    REMOVE_MATRIX_COL_REQUEST, REMOVE_MATRIX_COL_SUCCESS, REMOVE_MATRIX_COL_FAILURE,
    UPDATE_MATRIX_COLS_DEF_REQUEST, UPDATE_MATRIX_COLS_DEF_SUCCESS, UPDATE_MATRIX_COLS_DEF_FAILURE,
    UPDATE_MATRIX_ROWS_DEF_REQUEST, UPDATE_MATRIX_ROWS_DEF_SUCCESS, UPDATE_MATRIX_ROWS_DEF_FAILURE,
    UPDATE_MATRIX_LIST, UPDATE_LIST_CHARGES_STATE,
    ADD_LIST_CHARGE_STATE, UPDATE_LIST_CHARGE_STATE, DELETE_DECORATOR_SUCCESS, DELETE_DECORATOR_FAILURE,
    ADD_LIST_CHARGE_REQUEST, ADD_LIST_CHARGE_SUCCESS,
    UPDATE_LIST_CHARGE_SUCCESS,
    LOAD_DECORATIONS_BY_DATA_REQUEST, LOAD_DECORATIONS_BY_DATA_SUCCESS, LOAD_DECORATIONS_BY_DATA_FAILURE,
    LOAD_UNITS_BY_DATA_REQUEST, LOAD_UNITS_BY_DATA_SUCCESS, LOAD_UNITS_BY_DATA_FAILURE,
    LOAD_DECORATION_CHARGES_BY_DATA_REQUEST, LOAD_DECORATION_CHARGES_BY_DATA_SUCCESS, LOAD_DECORATION_CHARGES_BY_DATA_FAILURE,
    UPDATE_DECORATION_CHARGE_ITEM_DATA_REQUEST, UPDATE_DECORATION_CHARGE_ITEM_DATA_SUCCESS, UPDATE_DECORATION_CHARGE_ITEM_DATA_FAILURE,
    UPDATE_DECORATION_CHARGE_ITEM_LIST_CHARGES_SUCCESS, REMOVE_DECORATION_CHARGE_ITEM_SUCCESS,
    REMOVE_FIX_MATRIX_ROW_SUCCESS, REMOVE_DECORATIONS_SUCCESS
} from '../actions/admin_decoration_charges';

import { generateColDefs, generateCharge, checkMatrixCell, addCols, addRows,
    removeCols, removeRows, updateColDef,
    updateRowDef, initializeGrid,
    removeRowFixMatrix,
} from '../admin-dec-charges-utils';

export const decoratorIdReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_DECORATOR_ID_SUCCESS:
            return action.payload.decorator_id;
        case SET_DECORATOR_ID_FAILURE:
            return state;
    }

    return state;
};

// state given is decorators array
export const decoratorReducer = (state = {}, action) => {
    let decorators = Array.isArray(state) && state.length ? state : [];
    let decorator_idx = -1;

    if (action && action.payload && action.payload.decorator_id) {
        decorator_idx = decorators.findIndex(d => d.decorator_id == action.payload.decorator_id);
    }

    switch (action.type) {
        case LOAD_DECORATORS_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case LOAD_DECORATORS_SUCCESS:
            return action.payload.decorators;
        case LOAD_DECORATORS_FAILURE:
            return Object.assign({}, state, {loading: false, error: action.payload.error});
//
        case ADD_DECORATOR_STATE_SUCCESS:
            if (decorator_idx >= 0) {
                return state;
            }
            decorators = [].concat(state);
            decorators.push({decorator_id: action.payload.decorator_id, decorator_name: action.payload.decorator_name});
            return decorators;
        case ADD_DECORATOR_STATE_FAILURE:
            return state;
//
        case DELETE_DECORATOR_STATE_SUCCESS:
            if (decorator_idx < 0) {
                return state;
            }
            decorators = [].concat(state);
            decorators.splice(decorator_idx, 1);
            return decorators;
        case DELETE_DECORATOR_STATE_FAILURE:
            return state;
//
        case DELETE_DECORATOR_SUCCESS:
            if (decorator_idx < 0) { return state; }
            return [
                ...decorators.slice(0, decorator_idx),
                ...decorators.slice(decorator_idx+1),
            ];
        case DELETE_DECORATOR_FAILURE:
            return state;
//
        case UPDATE_DECORATOR_STATE_SUCCESS:
        case UPDATE_DECORATOR_SUCCESS:
            if (decorator_idx < 0) { return state; }

            return [
                ...decorators.slice(0, decorator_idx),
                {
                    ...decorators[decorator_idx],
                    ...action.payload.value,
                },
                ...decorators.slice(decorator_idx+1),
            ];
        case UPDATE_DECORATOR_FAILURE:
            return state;
    }

    return state;
};

// load 'multiple' decorations and others
export const decorationsReducer = (state = [], action) => {
    switch (action.type) {
        case LOAD_DECORATIONS_BY_DATA_REQUEST:
            return state;
        case LOAD_DECORATIONS_BY_DATA_SUCCESS:
            return action.payload.decorations;
        case LOAD_DECORATIONS_BY_DATA_FAILURE:
            return state;
    }

    return state;
};

// load 'multiple' decoration units
export const decorationUnitsReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_UNITS_BY_DATA_REQUEST:
            return state;
        case LOAD_UNITS_BY_DATA_SUCCESS:
            return action.payload.units;
        case LOAD_UNITS_BY_DATA_FAILURE:
            return state;
    }

    return state;
};

// load decoration charges by given data
export const decorationChargesReducer = (state = {}, action) => {
    const charges_data = {};
    switch (action.type) {
        case LOAD_DECORATION_CHARGES_BY_DATA_REQUEST:
            return state;
        case LOAD_DECORATION_CHARGES_BY_DATA_SUCCESS:
            charges_data['data'] = action.payload.charges.data;
            charges_data['type'] = action.payload.charges.type;
            if (action.payload.max_unit) {
                charges_data['max_unit'] = action.payload.max_unit;
            }
            if (action.payload.min_unit) {
                charges_data['min_unit'] = action.payload.min_unit;
            }

            if (action.payload.charges.type == 'table') {
                charges_data['cols'] = action.payload.charges.cols;
            }

            return charges_data;
        case LOAD_DECORATION_CHARGES_BY_DATA_FAILURE:
            return state;
    }

    return state;
};

// store decoration charge data
export const decorationChargeItemReducer = (state = {}, action) => {
    let c_idx = null;
    let d_idx = null;

    if (action && action.payload) {
        if (action.payload.decoration_id && action.payload.charge_id) {
            d_idx = state.deocrations.findIndex(v => v.decoration_id == action.payload.decoration_id);
            if (d_idx >= 0) {
                c_idx = state.deocrations[d_idx].findIndex(v => v.charge_id == action.payload.charge_id);
            }
        }
        else if (action.payload.charge_id) {
            c_idx = state.list_charges.findIndex(v => v.charge_id == action.payload.charge_id);
        }
    }

    switch (action.type) {
        case UPDATE_DECORATION_CHARGE_ITEM_DATA_REQUEST:
            return state;
        case UPDATE_DECORATION_CHARGE_ITEM_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload.data,
            };
        case UPDATE_DECORATION_CHARGE_ITEM_LIST_CHARGES_SUCCESS:
            if (c_idx == null || c_idx < 0) { return state; }

            return {
                ...state,
                list_charges: [
                    ...state.list_charges.slice(0, c_idx),
                    {
                        ...state.list_charges[c_idx],
                        ...action.payload.data,
                    },
                    ...state.list_charges.slice(c_idx+1),
                ],
            };
        case UPDATE_DECORATION_CHARGE_ITEM_DATA_FAILURE:
            return state;
    }

    return state;
};

// decoration charges matrix reducers
// state given is decorations array
export const decorationReducer = (state = {}, action) => {
    let decoration = null;
    let decoration_idx = null;
    let decorations = null;
    let charges = null;
    let matrix_data = false;
    let list_charge_idx = null;
    let list_charge_item = false;
    let child_charge_idx = null;
    let error = '';
    let temp = null;

    if (action && action.payload) {
        if (action.payload.decoration_idx && !isNaN(action.payload.decoration_idx)) {
            decoration_idx = action.payload.decoration_idx;
        }
        else if (action.payload.temp_decoration_id) {
            decoration_idx = state.data.findIndex(val => val.decoration_id == action.payload.decoration_id);
            decoration = state.data.filter(val => val.decoration_id == action.payload.temp_decoration_id)[0];
        }
        else if (action.payload.decoration_id) {
            decoration_idx = state.data.findIndex(val => val.decoration_id == action.payload.decoration_id);
        }

        if (decoration_idx != null && decoration_idx !== undefined && decoration_idx >= 0 && !decoration && state) {
            decoration = state.data[decoration_idx];
        }

        if (action.payload.matrix_data) {
            matrix_data = action.payload.matrix_data;
        }

        if (decoration && action.payload.value && action.payload.value.charge_id && (action.payload.value.type == 'list' || action.payload.value.type == 'list-item')) {
            list_charge_idx = decoration.matrix.data.findIndex(val => val.charge_id == action.payload.value.charge_id);
            if (list_charge_idx >= 0) {
                list_charge_item = decoration.matrix.data[list_charge_idx];
            }
        }
    }

    switch (action.type) {
        // load decoration based on selected decorator
        case LOAD_DECORATIONS_REQUEST:
            return Object.assign({}, state, {loading: false, error: '',});
        case LOAD_DECORATIONS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                error: '',
                data: action.payload.decorations.map(val => {
                    const type = val.matrix.type;

                    if (type == 'table') {
                        val.matrix.cols = generateColDefs(val.matrix.cols, val.units);
                        if (!val.matrix.data.length) {
                            val.matrix = {
                                ...val.matrix,
                                ...initializeGrid(null, null, val.units, val.charge_type),
                            };
                        }
                    } else {
                        if (val.matrix.data && !val.matrix.data.length) {
                            val.matrix = {
                                ...val.matrix,
                                // ...initializeChargesList(null, val.units),
                            };
                        }
                    }

                    if (!val.charge_desc) {
                        val.charge_desc = '';
                    }
                    return val;
                }),
            });
        case LOAD_DECORATIONS_FAILURE:
            return Object.assign({}, state, {loading: false, error: action.payload.error});

//
        // Create empty decoration and add to state
        case CREATE_DECORATION_SUCCESS:
            decoration = action.payload.decoration;
            decorations = [].concat(state.data);
            decorations.push(action.payload.decoration);

            return Object.assign({}, state, {data: decorations, });
//
        case ADD_DECORATION_REQUEST:
            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case ADD_DECORATION_SUCCESS:
            decoration = {
                ...decoration,
                decoration_id: action.payload.new_decoration_id,
                is_new: false,
                loading: false,
                error: '',
                matrix: {
                    ...decoration.matrix,
                    type: action.payload.value.matrix_type ?
                        action.payload.value.matrix_type : decoration.matrix.type,
                },
            };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);

            return Object.assign({}, state, {data: decorations});
        case ADD_DECORATION_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case UPDATE_DECORATION_REQUEST:
            decoration = {
                ...decoration,
                ...action.payload.value,
                loading: false,
                error: '',
            };

            if (decoration.is_new && (decoration.units !== '' || decoration.units !== null || decoration.units !== undefined)) {

                if (action.payload.value.matrix_type == 'list') {
                    // if (decoration.matrix.data.length) {
                        // matrix_data = initializeChargesList(null, decoration.units);
                        matrix_data={data: []};
                        temp = 'list';
                    // }
                } else if (action.payload.value.matrix_type == 'table') {
                    decoration.matrix.cols = generateColDefs(decoration.matrix.cols, decoration.units);
                    matrix_data = initializeGrid(null, null, decoration.units, decoration.charge_type);
                    temp = 'table';
                } else {
                    if (decoration.matrix.type == 'list') {
                        // matrix_data = initializeChargesList(null, decoration.units);
                        matrix_data={data: []};
                        temp = 'list';
                    } else {
                        decoration.matrix.cols = generateColDefs(decoration.matrix.cols, decoration.units);
                        matrix_data = initializeGrid(null, null, decoration.units, decoration.charge_type);
                        temp = 'table';
                    }
                }

                // if (!decoration.matrix.data.length || matrixIsEmpty(decoration.matrix)) {
                    decoration.matrix = {
                        ...decoration.matrix,
                        ...matrix_data,
                        type: temp,
                    };
                // }
            }

            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case UPDATE_DECORATION_SUCCESS:
            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...state.data[decoration_idx],
                    ...action.payload.value,
                    loading: false,
                    error: '',
                },
                ...state.data.slice(decoration_idx+1),
            ]});
        case UPDATE_DECORATION_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case DELETE_DECORATION_REQUEST:
            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case DELETE_DECORATION_SUCCESS:
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1);

            return Object.assign({}, state, {data: decorations});
        case DELETE_DECORATION_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case COPY_DECORATION_REQUEST:
            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case COPY_DECORATION_SUCCESS:
            decoration = action.payload.decoration;
            decoration_idx = state.data.findIndex(v => v.decoration_id == decoration.origin_id);
            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx+1),
                {
                    ...decoration,
                    matrix: {
                        ...decoration.matrix,
                        cols: decoration.matrix.type == 'table' ?
                            generateColDefs(decoration.matrix.cols, decoration.units) : undefined,
                        ...(decoration.matrix.data.length ? {} :
                            decoration.matrix.type == 'table' ?
                                initializeGrid(null, null, decoration.units, decoration.charge_type)
                            : decoration.matrix.type == 'list' ?
                                // initializeChargesList(null, decoration.units) : {}
                                {} : {}
                        ),
                    },
                    loading: false,
                    error: '',
                },
                ...state.data.slice(decoration_idx+1),
            ]});
        case COPY_DECORATION_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case UPDATE_MATRIX_CELL_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case UPDATE_MATRIX_CELL_SUCCESS:
            if (!matrix_data) {
                matrix_data = checkMatrixCell(decoration.matrix, action.payload.value);
            }

            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
                error = '';
            }

            decoration = {...decoration, loading: false, error: error, };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case UPDATE_MATRIX_CELL_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;
//
        case UPDATE_MATRIX_CELL_STATE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;
//
        case ADD_MATRIX_ROW_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case ADD_MATRIX_ROW_SUCCESS:
            matrix_data = addRows(decoration.matrix, action.payload.value, action.payload.data);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: error, };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case ADD_MATRIX_ROW_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;
//
        case REMOVE_MATRIX_ROW_REQUEST:
            decoration = {...decoration, loading: true, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case REMOVE_MATRIX_ROW_SUCCESS:
            matrix_data = removeRows(decoration.matrix, action.payload.value);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case REMOVE_MATRIX_ROW_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;
//
        case ADD_MATRIX_COL_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case ADD_MATRIX_COL_SUCCESS:

            matrix_data = addCols(decoration.matrix, action.payload.value);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case ADD_MATRIX_COL_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case REMOVE_MATRIX_COL_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case REMOVE_MATRIX_COL_SUCCESS:
            matrix_data = removeCols(decoration.matrix, action.payload.value);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case REMOVE_MATRIX_COL_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case UPDATE_MATRIX_COLS_DEF_REQUEST:
            matrix_data = updateColDef(decoration.matrix, action.payload.value.newValue, action.payload.value.colObj);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case UPDATE_MATRIX_COLS_DEF_SUCCESS:
            if (decoration != null) {
                // matrix_data = updateColDef(decoration.matrix, action.payload.value.newValue, action.payload.value.colObj);
                // if (matrix_data !== false) {
                //     decoration.matrix = {...decoration.matrix, ...matrix_data};
                //     decoration = {...decoration, loading: false, error: '', };
                // }
                decoration = {...decoration, loading: false, error: '', };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }

            return state;
        case UPDATE_MATRIX_COLS_DEF_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case UPDATE_MATRIX_ROWS_DEF_REQUEST:
            matrix_data = updateRowDef(decoration.matrix, action.payload.value);
            if (matrix_data !== false) {
                decoration.matrix = {...decoration.matrix, ...matrix_data};
            }

            decoration = {...decoration, loading: false, error: '', };
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});
        case UPDATE_MATRIX_ROWS_DEF_SUCCESS:
            return state;
        case UPDATE_MATRIX_ROWS_DEF_FAILURE:
            if (decoration != null) {
                decoration = {...decoration, loading: false, error: action.payload.error, };
                decorations = [].concat(state.data);
                decorations.splice(decoration_idx, 1, decoration);
                return Object.assign({}, state, {data: decorations});
            }
            return state;

//
        case UPDATE_MATRIX_LIST:
            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                        data: action.payload.value,
                        cols: generateColDefs(decoration.matrix.cols, decoration.units),
                    },
                },
                ...state.data.slice(decoration_idx),
            ]});
//
        case UPDATE_MATRIX_SUCCESS:
            if (action.payload.value.units) {
                decoration.matrix = {
                    ...decoration.matrix,
                    cols: generateColDefs(decoration.matrix.cols, action.payload.value.units),
                };
            } else if (action.payload.value.type) {
                if (action.payload.value.type == 'table') {
                    decoration.matrix = {
                        ...decoration.matrix,
                        // ...action.payload.value,
                        type: action.payload.value.type,
                        ...initializeGrid(null, null, decoration.units, decoration.charge_type),
                    };
                } else {
                    decoration.matrix = {
                        ...decoration.matrix,
                        // ...action.payload.value,
                        cols: [],
                        type: action.payload.value.type,
                        // ...initializeChargesList(1, ''),
                        data: [],
                    };
                }
            }

            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                    }
                },
                ...state.data.slice(decoration_idx+1),
            ]});

        case ADD_LIST_CHARGE_STATE:
            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                        data: [
                            ...decoration.matrix.data,
                            action.payload.value,
                        ]
                    }
                },
                ...state.data.slice(decoration_idx + 1),
            ]});
        case ADD_LIST_CHARGE_REQUEST:
            return state;
        case ADD_LIST_CHARGE_SUCCESS:
            if (action.payload.value.charge_id && action.payload.value.charge_id.indexOf('temp_charge_') != -1)
            {
                if (action.payload.value.parent_id) {
                    list_charge_idx = decoration.matrix.data.findIndex(val => val.charge_id == action.payload.value.parent_id);
                    matrix_data = [
                        ...decoration.matrix.data.slice(0, list_charge_idx),
                        {
                            ...decoration.matrix.data[list_charge_idx],
                            children: [
                                ...decoration.matrix.data[list_charge_idx].children,
                                {
                                    ...action.payload.value,
                                    charge_id: (action.payload.value.new_charge_id) ?
                                        action.payload.value.new_charge_id :
                                        action.payload.value.decoration_charge_id,
                                },
                            ],
                            ...list_charge_item,
                            ...action.payload.value,
                            charge_id: (action.payload.value.new_charge_id) ?
                                action.payload.value.new_charge_id :
                                list_charge_item.charge_id,
                        },
                        ...decoration.matrix.data.slice(list_charge_idx+1),
                    ];
                } else {
                    if (list_charge_idx !== false && action.payload.value.charge_id) {
                        if (action.payload.value.child_id) {
                            matrix_data = [
                                ...decoration.matrix.data.slice(0, list_charge_idx),
                                {
                                    ...list_charge_item,
                                    ...action.payload.value,
                                    charge_id: (action.payload.value.new_charge_id) ?
                                        action.payload.value.new_charge_id :
                                        list_charge_item.charge_id,
                                    child_id: undefined,
                                    new_charge_id: undefined,
                                    children: [
                                        generateCharge(0, '', {
                                            charge_id: action.payload.value.child_id,
                                            parent_id: (action.payload.value.new_charge_id) ?
                                                action.payload.value.new_charge_id :
                                                list_charge_item.charge_id
                                        }),
                                    ],
                                },
                                ...decoration.matrix.data.slice(list_charge_idx+1),
                            ];
                        } else {
                            matrix_data = [
                                ...decoration.matrix.data.slice(0, list_charge_idx),
                                {
                                    ...list_charge_item,
                                    ...action.payload.value,
                                    charge_id: (action.payload.value.new_charge_id) ?
                                        action.payload.value.new_charge_id :
                                        list_charge_item.charge_id,
                                    children: action.payload.value.children ? action.payload.value.children :
                                        (action.payload.value.is_parent ? [] : undefined),
                                },
                                ...decoration.matrix.data.slice(list_charge_idx+1),
                            ];
                        }
                    }
                }
            } else {
                if (action.payload.value.parent_id) {
                    list_charge_idx = decoration.matrix.data.findIndex(v => v.charge_id == action.payload.value.parent_id);
                    matrix_data = [
                        ...decoration.matrix.data.slice(0, list_charge_idx),
                        {
                            ...decoration.matrix.data[list_charge_idx],
                            children: [
                                ...(decoration.matrix.data[list_charge_idx].children ? decoration.matrix.data[list_charge_idx].children : []),
                                {
                                    ...action.payload.value,
                                    charge_id: (action.payload.value.new_charge_id) ?
                                        action.payload.value.new_charge_id :
                                        action.payload.value.decoration_charge_id,
                                },
                            ],
                        },
                        ...decoration.matrix.data.slice(list_charge_idx+1),
                    ];
                } else {
                    if (action.payload.value.child_id) {
                        matrix_data = [
                            ...decoration.matrix.data,
                            {
                                ...action.payload.value,
                                charge_id: (action.payload.value.new_charge_id) ?
                                    action.payload.value.new_charge_id :
                                    action.payload.value.decoration_charge_id,
                                child_id: undefined,
                                new_charge_id: undefined,
                                children: [
                                    generateCharge(0, '', {
                                        charge_id: action.payload.value.child_id,
                                        parent_id: (action.payload.value.new_charge_id) ?
                                            action.payload.value.new_charge_id :
                                            list_charge_item.charge_id
                                    }),
                                ],
                            },
                        ];
                    } else {
                        matrix_data = [
                            ...decoration.matrix.data,
                            {
                                ...action.payload.value,
                                charge_id: (action.payload.value.new_charge_id) ?
                                    action.payload.value.new_charge_id :
                                    action.payload.value.decoration_charge_id,
                                children: action.payload.value.children ? action.payload.value.children :
                                    (action.payload.value.is_parent ? [] : undefined),
                            },
                        ];
                    }
                }
            }
            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                        data: matrix_data,
                    },
                },
                ...state.data.slice(decoration_idx + 1),
            ]});

        case UPDATE_LIST_CHARGES_STATE:

            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                        data: decoration.matrix.data.map(v => {
                            const _v = v;
                            _v['unit'] = action.payload.value.unit ? action.payload.value.unit : v['unit'];
                            _v['qty'] = action.payload.value.qty ? action.payload.value.qty : v['qty'];
                            return _v;
                        }),
                    },
                },
                ...state.data.slice(decoration_idx + 1),
            ]});

        case UPDATE_LIST_CHARGE_STATE:
            if (action.payload.value.parent_id) {
                list_charge_idx = decoration.matrix.data.findIndex(v => v.charge_id == action.payload.value.parent_id);
                child_charge_idx = decoration.matrix.data[list_charge_idx].children.findIndex(v => v.charge_id == action.payload.value.charge_id);
                list_charge_item = {
                    ...decoration.matrix.data[list_charge_idx],
                };
            } else {
                list_charge_item = {
                    ...list_charge_item,
                    ...action.payload.value,
                };
            }

            charges = [].concat(decoration.matrix.data);
            charges.splice(list_charge_idx, 1, list_charge_item);

            decoration.matrix = {...decoration.matrix, data: charges};
            decoration['loading'] = false;
            decoration['error'] = '';
            decorations = [].concat(state.data);
            decorations.splice(decoration_idx, 1, decoration);
            return Object.assign({}, state, {data: decorations});

        case UPDATE_LIST_CHARGE_SUCCESS:
            if (action.payload.value.parent_id) {
                list_charge_idx = decoration.matrix.data.findIndex(v => v.charge_id == action.payload.value.parent_id);
                child_charge_idx = decoration.matrix.data[list_charge_idx].children.findIndex(v => v.charge_id == action.payload.value.charge_id);
                list_charge_item = {
                    ...decoration.matrix.data[list_charge_idx],
                    children: [
                        ...decoration.matrix.data[list_charge_idx].children.slice(0, child_charge_idx),
                        {
                            ...decoration.matrix.data[list_charge_idx].children[child_charge_idx],
                            ...action.payload.value,
                        },
                        ...decoration.matrix.data[list_charge_idx].children.slice(child_charge_idx + 1),
                    ],
                };
            } else {
                list_charge_item = {
                    ...list_charge_item,
                    ...action.payload.value,
                };
            }

            return Object.assign({}, state, {data: [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    loading: false,
                    error: '',
                    matrix: {
                        ...decoration.matrix,
                        data: [
                            ...decoration.matrix.data.slice(0, list_charge_idx),
                            list_charge_item,
                            ...decoration.matrix.data.slice(list_charge_idx+1),
                        ],
                    },
                },
                ...state.data.slice(decoration_idx+1),
            ]});

        case REMOVE_DECORATION_CHARGE_ITEM_SUCCESS:
            if (action.payload.value.parent_id) {
                list_charge_idx = decoration.matrix.data.findIndex(v => v.charge_id == action.payload.value.parent_id);
                decorations = [
                    ...state.data.slice(0, decoration_idx),
                    {
                        ...decoration,
                        matrix: {
                            ...decoration.matrix,
                            data: [
                                ...decoration.matrix.data.slice(0, list_charge_idx),
                                {
                                    ...decoration.matrix.data[list_charge_idx],
                                    children: decoration.matrix.data[list_charge_idx].children
                                        .filter(c => c.charge_id !== action.payload.value.charge_id),
                                },
                                ...decoration.matrix.data.slice(list_charge_idx+1),
                            ],
                        },
                    },
                    ...state.data.slice(decoration_idx + 1),
                ];
            } else {
                decorations = [
                    ...state.data.slice(0, decoration_idx),
                    {
                        ...decoration,
                        matrix: {
                            ...decoration.matrix,
                            data: decoration.matrix.data.filter(
                                v => v.charge_id !== action.payload.value.charge_id
                            )
                        },
                    },
                    ...state.data.slice(decoration_idx + 1),
                ];
            }

            return Object.assign({}, state, {data: decorations});

        case REMOVE_FIX_MATRIX_ROW_SUCCESS:
            matrix_data = removeRowFixMatrix(decoration.matrix, action.payload.value);
            if (matrix_data == false) { return state; }

            decorations = [
                ...state.data.slice(0, decoration_idx),
                {
                    ...decoration,
                    matrix: {
                        ...decoration.matrix,
                        ...matrix_data,
                    },
                },
                ...state.data.slice(decoration_idx + 1),
            ];

            return Object.assign({}, state, {data: decorations});

        case REMOVE_DECORATIONS_SUCCESS:
            return Object.assign({}, state, {data: []});
    }

    return state;
};
