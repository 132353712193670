import { oauth } from '../utils';

export const ADD_DEPOSIT_INVOICE_SUCCESS = 'ADD_DEPOSIT_INVOICE_SUCCESS';
export const DELETE_DEPOSIT_INVOICE_SUCCESS = 'DELETE_DEPOSIT_INVOICE_SUCCESS';
export const UPDATE_DEPOSIT_INVOICE_REQUEST = 'UPDATE_DEPOSIT_INVOICE_REQUEST';
export const UPDATE_DEPOSIT_INVOICE_SUCCESS = 'UPDATE_DEPOSIT_INVOICE_SUCCESS';
export const UPDATE_DEPOSIT_INVOICE_FAILURE = 'UPDATE_DEPOSIT_INVOICE_FAILURE';

const finalizeAddDepositInvoice = (deposit) => ({
  type: ADD_DEPOSIT_INVOICE_SUCCESS,
  payload: {
    id: deposit.deposit_id,
    data: {
      deposit
    }
  }
});

export const createAddDepositInvoice = (job_id, order_id) => dispatch => {
	let data = {
		job_id: job_id,
		order_id: order_id
	};

	oauth('POST', 'deposit', data).then(
    	({ json }) => dispatch(finalizeAddDepositInvoice(json.deposit))
  	);
};

const finalizeDeleteDepositInvoice = (deposit_id) => ({
  type: DELETE_DEPOSIT_INVOICE_SUCCESS,
  payload: {
    id: deposit_id
  }
});

export const createDeleteDepositInvoice = (deposit_id) => dispatch => {
	oauth('DELETE', `deposit/${deposit_id}`, {}).then(
    	({ json }) => dispatch(finalizeDeleteDepositInvoice(deposit_id))
  	);
};

const requestUpdateDepositInvoice = (deposit_id, field, previous_value, value) => ({
  type: UPDATE_DEPOSIT_INVOICE_REQUEST,
  payload: {
    id: deposit_id,
    data: {
      [field]: value
    }
  }
});

const finalizeUpdateDepositInvoice = (deposit_id, deposit) => ({
  type: UPDATE_DEPOSIT_INVOICE_SUCCESS,
  payload: {
    id: deposit_id,
    data: {
      deposit
    }
  }
});

const failedUpdateDepositInvoice = (deposit_id, field, previous_value, value) => ({
  type: UPDATE_DEPOSIT_INVOICE_FAILURE,
  payload: {
    id: deposit_id,
    message: 'Unable to update deposit',
    data: {
      [field]: previous_value
    }
  }
});

export const createUpdateDepositInvoice = (deposit_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(requestUpdateDepositInvoice(deposit_id, field, previous_value, value));

  oauth('PUT', `deposit/${deposit_id}`, {[field]: value}).then(
    ({ json }) => dispatch(finalizeUpdateDepositInvoice(deposit_id, json.deposit)),
    ({ json }) => dispatch(failedUpdateDepositInvoice(deposit_id, field, previous_value, value))
  );
};