import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { getProductConfigurations } from '../../promostandards';
import { createUpdateItemDecoration, createUpdateItemDecorationRequest } from '../../actions';

import { getItemDecoration, getItem, getProduct } from '../../selectors/items';

import Select from '../Select';
import { getUoms, getUomName } from '../../promostandard_utils';
import { cleanupPcnaDuplicateLocations } from '../../helpers/ps_locations';

class SelectPromostandardsDecoration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uoms: props.uoms,
      product_locations: []
    };

    this.onChangeDecoration = this.onChangeDecoration.bind(this);
    this.onChangeYUom = this.onChangeYUom.bind(this);
    this.onUpdateYUom = this.onUpdateYUom.bind(this);
  }

  componentDidMount() {
    this.loadPSProductConfigurations();
  }

  async loadPSProductConfigurations() {
    const { locations } = await getProductConfigurations({
      productId: this.props.ext_product_id,
    }) || {};
    this.setState({ product_locations: locations || [] });
  }

  getCleanLocation() {
    const { ext_location_id } = this.props;
    const { product_locations } = this.state;
    return find(cleanupPcnaDuplicateLocations(product_locations, [ext_location_id]), {ps_location_id: ext_location_id});
  }

  onChangeDecoration(value) {
    const { dispatch, item_decoration_id, imprint_id } = this.props;
    const location = this.getCleanLocation();
    const decoration = (location || { decorations: [] }).decorations.filter(d => d.ps_decoration_id === value)[0];

    dispatch(createUpdateItemDecoration(item_decoration_id, 'ps_imprint', imprint_id, {
      ext_decoration_id: decoration.ps_decoration_id,
      ext_location_id: decoration.ps_location_id,
      imprint_name: decoration.decoration_name
    })).then(action => {
      if (action.payload.item_decoration) {
        const uoms = Object.keys(action.payload.item_decoration).filter(k => k.startsWith('decoration_uom.')).reduce((o, k) => ({ ...o, [k.slice(15)]: action.payload.item_decoration[k] }), {});
        this.setState({ uoms });
      }
    });
    dispatch(createUpdateItemDecorationRequest(item_decoration_id, 'ext_decoration_id', value));
  }

  onChangeYUom(uom, newValue) {
    this.setState((state) => ({ ...state, uoms: { ...state.uoms, [uom]: newValue } }));
  }

  onUpdateYUom(uom) {
    const { dispatch, item_decoration_id } = this.props;
    const { product_locations } = this.state;
    const uoms = {};
    (product_locations ?? []).forEach(
			l => l.decorations.filter(
				d => d.decoration_id === item_decoration_id.ext_decoration_id
			).forEach(
  			d => {
					uoms[d.decoration_units_included_uom] = (uoms[d.decoration_units_included_uom] ?? []).concat(d.decoration_units_max);
					d.charges.forEach(
	          c => c.prices.forEach(
				  		p => {
                uoms[p.y_uom] = (uoms[p.y_uom] ?? []).concat(p.y_min_qty);
              }
				  	)
				  );
				}
			)
		);
    Object.keys(uoms).forEach(y => {
      if (y !== 'Other') {
        uoms[y] = Array.from(new Set(uoms[y])).sort((a, b) => a - b);
      } else {
        delete uoms[y];
      }
    });

    const value = this.state.uoms[uom] || 0;
    if (uoms[uom][0] > value || uoms[uom][uoms[uom].length - 1] < value) {
      this.setState(state => ({
        uoms: ({
          ...state.uoms,
          [uom]: this.props.uoms[uom]
        })
      }));
    } else {
      dispatch(createUpdateItemDecoration(item_decoration_id, 'decoration_uom', uoms, { [uom]: value }));
    }
  }

  render() {
    const { ext_decoration_id } = this.props;
    const location = this.getCleanLocation();
    const decorations = (location || { decorations: [] }).decorations;

    if (!decorations) {
      return null;
    }
    const options = decorations.map(
      d => ({
        key: d.ps_decoration_id,
        value: d.decoration_name
      })
    );

    const decoration = decorations.filter(d => d.ps_decoration_id == ext_decoration_id)[0];
    const maxUnits = decoration && decoration.decoration_units_max;
    const uoms = getUoms(decoration);

    return (
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <Select
          placeholder="Select an imprint type..."
          options={options}
          value={ext_decoration_id}
          change={this.onChangeDecoration}
          style={{ width: '100%', marginBottom: 0 }}
        />
        {decorations.filter(
          d => d.ps_decoration_id === ext_decoration_id && d.decoration_units_included_uom !== 'Other'
        ).map(
          d => {
            const maxValue = Math.max(maxUnits, d.decoration_units_max, uoms[d.decoration_units_included_uom] && uoms[d.decoration_units_included_uom].length > 0 ? uoms[d.decoration_units_included_uom][uoms[d.decoration_units_included_uom].length - 1] : maxUnits);

            const hasYUom = (uoms[d.decoration_units_included_uom] || []).length > 0;
            const maxYUom = `Max ${maxValue} ${getUomName(d.decoration_units_max, d.decoration_units_included_uom)}`;
            const uomText = hasYUom ? `${d.decoration_units_included} ${getUomName(d.decoration_units_included, d.decoration_units_included_uom)} Included (${maxYUom})` : maxYUom;
            return (
              <span key={d.ps_decoration_id} style={{ fontSize: 'small' }}>
                {uomText}
              </span>
            );
          }
        )}
        {Object.keys(uoms).filter(u => uoms[u].length > 1).map(
          u => <div key={`decoration-uom-${u}`} style={{ maxWidth: '33%' }}>
            <label># {u}</label>
            <input
              type="text"
              className="field1"
              min={0}
              max={Math.max(maxUnits, uoms[u] && uoms[u].length > 0 ? uoms[u][uoms[u].length - 1] : maxUnits)}
              placeholder={uoms[u][0]}
              value={this.state.uoms[u]}
              onChange={e => this.onChangeYUom(u, e.target.value)}
              onBlur={() => this.onUpdateYUom(u)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const decoration = getItemDecoration(state, { item_decoration_id: ownProps.item_decoration_id });
  if (!decoration) {
    return {};
  }
  const item = getItem(state, { item_id: decoration.item_id });
  const product = getProduct(state, { product_id: item.parent_id });
  const uoms = Object.keys(decoration).filter(k => k.startsWith('decoration_uom.')).reduce((o, k) => ({ ...o, [k.slice(15)]: decoration[k] }), {});
  return {
    ext_product_id: product.ext_product_id,
    ext_location_id: parseInt(decoration.ext_location_id, 10),
    ext_decoration_id: parseInt(decoration.ext_decoration_id, 10),
    imprint_id: decoration.imprint_id,
    uoms
  };
};

export default connect(mapStateToProps)(SelectPromostandardsDecoration);
