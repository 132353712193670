import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { useState, useCallback } from 'react';

import { oauth } from '../utils';
import { useIdentity } from '.';
import useOnMount from './useOnMount';

const cookies = new Cookies();
const updateSanmarRequests = (data,identity) => {
  return oauth('PUT', 'sanmar-requests', data, null, {logout: false}).then((response) => {
    const value = get(response, 'json.status', '') || get(response, 'json.sanmar_request.status', '');
    // only cache none DISPLAYED status, we don't want their popup keep showing because of this cache
    const maxAge = get({
      DISPLAYED: 5,
      SUBMITTED: 7 * 24 * 3600,
    }, value) || 24 * 3600;
    cookies.set('sanmar_request_status_'+ identity.user_id, value, {maxAge, path:'/'});
    return value;
  });
};

const useSanmarRequest = ({ requireSync = (status) => status !== 'SUBMITTED' } = {}) => {
  const identity = useIdentity();
  const [status, setStatus] = useState(cookies.get('sanmar_request_status_'+ identity.user_id));
  const update = useCallback((data) => {
    return updateSanmarRequests(data,identity).then(setStatus);
  }, [identity]);

  useOnMount(() => {
    if (requireSync(status)) {
      updateSanmarRequests({ actionName: 'init'}, identity).then(setStatus);
    }
  });

  return { status, update };
};

export default useSanmarRequest;
