import React from "react";
import { Tabs } from "@commonsku/styles";

import ImageSearchBox from "./ImageSearchBox";
import UnsplashLogo from "./UnsplashLogo";
import UploadDropZone from "./UploadDropZone";

export default function UploadImagesTabs(props) {
  const {
    height,
    imgSearchBoxHeight,
    dropzoneHeight,
    showCarousel,
    CarouselImagesGrid,
    setShowCarousel,
    onUploadFile,
    onChangeBackground,
    showUnsplash = true,
    recommendedSize = '1456 x 1007 px',
    showRecommendedSize = true,
  } = props;

  const tabs = [
    { label: 'Upload',
      content: <UploadDropZone
        height={dropzoneHeight}
        onUploadFile={onUploadFile}
        recommendedSize={recommendedSize}
        showRecommendedSize={showRecommendedSize}
      /> },
    { label: <UnsplashLogo />,
      content: <ImageSearchBox onChangeBackground={onChangeBackground} bodyHeight={imgSearchBoxHeight || height} />
    },
  ];

  const NewCarouselImagesGrid = CarouselImagesGrid ? React.cloneElement(
    CarouselImagesGrid, { setShowCarousel: setShowCarousel, }
  ) : null;

  if (!showUnsplash && !showCarousel) {
    return (
      <UploadDropZone
        onUploadFile={onUploadFile}
        height={dropzoneHeight}
        showRecommendedSize={false}
      />
    );
  }

  return (
    !showCarousel
      ? <Tabs tabs={tabs} variant={'secondary'} padded={true} />
      : <div style={{
          overflowY: 'auto',
          height: height,
          paddingTop: 7,
          paddingRight: 14,
        }}>
          {NewCarouselImagesGrid}
        </div>
  );
};
