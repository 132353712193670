import { map, keyBy, groupBy } from 'lodash';
import { oauth } from '../utils';

export const UPDATE_SHIPPING_REQUEST = 'UPDATE_SHIPPING_REQUEST';
export const UPDATE_SHIPPING_SUCCESS = 'UPDATE_SHIPPING_SUCCESS';
export const UPDATE_SHIPPING_FAILURE = 'UPDATE_SHIPPING_FAILURE';

export const LOAD_SHIP_METHODS_REQUEST = 'LOAD_SHIP_METHODS_REQUEST';
export const LOAD_SHIP_METHODS_SUCCESS = 'LOAD_SHIP_METHODS_SUCCESS';
export const LOAD_SHIP_METHODS_FAILURE = 'LOAD_SHIP_METHODS_FAILURE';

export const ADD_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST = 'ADD_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST';
export const ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS = 'ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS';
export const ADD_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE = 'ADD_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE';

export const UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST = 'UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST';
export const UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS = 'UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS';
export const UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE = 'UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE';

export const DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST = 'DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST';
export const DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS = 'DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS';
export const DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE = 'DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE';

const createUpdateShippingRequest = (item_ids, vendor_details, decorator_details) => ({
  type: UPDATE_SHIPPING_REQUEST,
  payload: {
    item_ids,
    vendor_details,
    decorator_details
  }
});

const createFinalizeUpdateShipping = (item_ids, shipping) => {
  return {
    type: UPDATE_SHIPPING_SUCCESS,
    payload: {
      item_ids,
      shipping
    }
  };
};

const createFailedUpdateShipping = (item_ids, vendor_details, decorator_details) => ({
  type: UPDATE_SHIPPING_FAILURE,
  payload: {
    item_ids,
    vendor_details,
    decorator_details
  }
});

export const createUpdateShipping = (item_ids, vendor_details, decorator_details) => dispatch => {
  dispatch(createUpdateShippingRequest(item_ids, vendor_details, decorator_details));

  let shipping = [vendor_details];
  if (decorator_details) {
    shipping.push(decorator_details);
  }

  const data = {
    item_ids,
    shipping
  };

  oauth('POST', 'shipping', data).then(
    ({ json }) => {
      return Promise.all(map(groupBy(json.shipping, 'item_ids'), (shipping_group) => {
        return dispatch(createFinalizeUpdateShipping(shipping_group[0].item_ids, keyBy(shipping_group, 'shipping_id')));
      }));
    },
    ({ json }) => dispatch(createFailedUpdateShipping(item_ids, vendor_details, decorator_details))
  );
};

const createLoadShipMethodsRequest = division_id => ({
  type: LOAD_SHIP_METHODS_REQUEST,
  payload: {
    division_id
  }
});

const createFinalizeLoadShipMethods = ship_methods => ({
  type: LOAD_SHIP_METHODS_SUCCESS,
  payload: {
    ship_methods
  }
});

const createFailedLoadShipMethods = division_id => ({
  type: LOAD_SHIP_METHODS_FAILURE,
  payload: {
    division_id
  }
});

export const createLoadShipMethods = division_id => dispatch => {
  dispatch(createLoadShipMethodsRequest(division_id));

  return oauth('GET', 'ship-method', { division_id }).then(
    ({ json }) => dispatch(createFinalizeLoadShipMethods(json.ship_methods)),
    ({ json }) => dispatch(createFailedLoadShipMethods(division_id))
  );
};

const createAddThirdPartyShippingAccountRequest = (client_id, job_id, courier_id, account_name, account_number, account_postal) => ({
  type: ADD_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST,
  payload: {
    client_id,
    job_id,
    courier_id,
    account_name,
    account_number,
    account_postal
  }
});

const createFinalizeAddThirdPartyShippingAccount = (third_party_shipping_account) => ({
  type: ADD_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
  payload: {
    third_party_shipping_account
  }
});

const createFailedAddThirdPartyShippingAccount = (client_id, courier_id, account_name, account_number, account_postal) => ({
  type: ADD_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST,
  payload: {
    client_id,
    courier_id,
    account_name,
    account_number,
    account_postal
  }
});

export const createAddThirdPartyShippingAccount = (client_id, courier_id, account_name, account_number, account_postal) => dispatch => {
  dispatch(createAddThirdPartyShippingAccountRequest(client_id, null, courier_id, account_name, account_number, account_postal));
  const data = {
    client_id,
    courier_id,
    account_name,
    account_number,
    account_postal
  };

  return oauth('POST', 'third-party-shipping-account', data).then(
    ({ json }) => dispatch(createFinalizeAddThirdPartyShippingAccount(json.third_party_shipping_account)),
    ({ json }) => dispatch(createFailedAddThirdPartyShippingAccount(client_id, null, courier_id, account_name, account_number, account_postal))
  );
};

export const createAddShippingQuote = (job_id, courier_id, account_number) => dispatch => {
  dispatch(createAddThirdPartyShippingAccountRequest(null, job_id, courier_id, null, account_number, null));
  const data = {
    job_id,
    courier_id,
    account_number
  };

  return oauth('POST', 'third-party-shipping-account', data).then(
    ({ json }) => dispatch(createFinalizeAddThirdPartyShippingAccount(json.third_party_shipping_account)),
    ({ json }) => dispatch(createFailedAddThirdPartyShippingAccount(null, job_id, courier_id, null, account_number, null))
  );
};

const createUpdateThirdPartyShippingAccountRequest = (third_party_shipping_account_id, { account_number, courier_id }) => ({
  type: UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST,
  payload: {
    third_party_shipping_account_id,
    account_number, courier_id
  }
});

const createDeleteThirdPartyShippingAccountRequest = (third_party_shipping_account_id) => ({
  type: DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_REQUEST,
  payload: { third_party_shipping_account_id, }
});

const createFinalizeUpdateThirdPartyShippingAccount = (third_party_shipping_account) => ({
  type: UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
  payload: {
    third_party_shipping_account
  }
});

const createFinalizeDeleteThirdPartyShippingAccount = (third_party_shipping_account_id) => ({
  type: DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_SUCCESS,
  payload: { third_party_shipping_account_id }
});

const createFailedUpdateThirdPartyShippingAccount = (third_party_shipping_account_id, { account_number, courier_id }) => ({
  type: UPDATE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE,
  payload: {
    third_party_shipping_account_id,
    account_number, courier_id
  }
});

const createFailedDeleteThirdPartyShippingAccount = (third_party_shipping_account_id) => ({
  type: DELETE_THIRD_PARTY_SHIPPING_ACCOUNT_FAILURE,
  payload: { third_party_shipping_account_id, }
});

export const createUpdateShippingQuote = (third_party_shipping_quote_id, { account_number, courier_id }) => dispatch => {
  dispatch(createUpdateThirdPartyShippingAccountRequest(third_party_shipping_quote_id, { account_number, courier_id }));
  return oauth('PUT', `third-party-shipping-account/${third_party_shipping_quote_id}`, { account_number, courier_id }).then(
    ({ json }) => dispatch(createFinalizeUpdateThirdPartyShippingAccount(json.third_party_shipping_account)),
    ({ json }) => dispatch(createFailedUpdateThirdPartyShippingAccount(third_party_shipping_quote_id, { account_number, courier_id }))
  );
};

export const createDeleteShippingQuote = (third_party_shipping_quote_id) => dispatch => {
  dispatch(createDeleteThirdPartyShippingAccountRequest(third_party_shipping_quote_id));
  return oauth('DELETE', `third-party-shipping-account/${third_party_shipping_quote_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteThirdPartyShippingAccount(third_party_shipping_quote_id)),
    ({ json }) => dispatch(createFailedDeleteThirdPartyShippingAccount(third_party_shipping_quote_id))
  );
};

