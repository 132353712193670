import React, { Component } from 'react';

class Callout extends Component {
  render() {
    const type = this.props.type || 'primary';
    return (
      <div className={type + ' callout'}>
        {this.props.children}
        <button className="close-button" aria-label="Dismiss alert" type="button"
          onClick={this.props.onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

export default Callout;
