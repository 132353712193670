import { concat, map, sortedUniq, uniq } from 'lodash';

export const getPriceQtyCols = (price_groups) => {
  return sortedUniq(uniq(concat(...map(
    price_groups,
    ({ base_price = {}, additions = [] }) => {
      return map(concat(base_price.price_breaks, ...map(additions, 'price_breaks')), 'qty');
    }
  ))));
};
