import moment from 'moment-timezone';
import { get, map, find, isFunction, isEmpty, unionBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { oauth } from '../../utils';

const communityEventsSlice = createSlice({
  name: 'community_events',
  initialState: {
    user: null,
    entities: {},
  },
  reducers: {
    setInterested(state, action) {
      const { community_event_user, interested } = action.payload;
      const community_event = find(state.entities.community_events, {
        community_event_id: community_event_user.community_event_id
      });
      if (!isEmpty(community_event)) {
        community_event.community_event_user_id = interested ? community_event_user.community_event_user_id : null;
        community_event.join_url = community_event_user.join_url;
      }
    },
    addEntities(state, action) {
      map(action.payload, ({ entities, id_field }, entity_name) => {
        if (!(entity_name in state.entities)) {
          state.entities[entity_name] = {};
        }
        map(entities, (entity) => {
          const key = isFunction(id_field) ? id_field(entity) : entity[id_field];
          state.entities[entity_name][key] = {
            ...get(state.entities, [entity_name, key], {}),
            ...entity,
          };
        });
      });
    },
    deleteEntities(state, action) {
      map(action.payload, (ids, entity_name) => {
        if (!(entity_name in state.entities)) {
          state.entities[entity_name] = {};
        }
        map(ids, (id) => {
          delete state.entities[entity_name][id];
        });
      });
    },
  },
});

export const fetchCompanies = () => async (dispatch, getState) => {
  const responses = await Promise.all([
    oauth('GET', 'company/csku-suppliers', {
      feature_name: [
        'COLLABORATE', 'INSIGHTS', 'CONNECTED', 'COMMUNITYBASIC', 'COMMUNITYPLUS', 'CONNECTEDBASIC', 'CONNECTEDPLUS'
      ]
    }),
    oauth('GET', 'company/csku-distributors', {include_hidden: true, feature_name: 'ADMIN'}),
  ]);

  const companies = unionBy(...map(responses, ({ json }) => get(json, 'companies')), 'company_id');
  dispatch(communityEventsSlice.actions.addEntities({
    companies: {
      entities: companies,
      id_field: 'company_id',
    }
  }));
  return { companies };
};

export const fetchCollections = (supplier_id) => async (dispatch, getState) => {
  const { json } = await oauth('GET', 'collection', {
    supplier_id, status_name: 'Published'
  });
  dispatch(communityEventsSlice.actions.addEntities({
    collections: {
      entities: get(json, 'collections', []),
      id_field: 'order_id',
    }
  }));
  return json;
};

export const fetchCompanyUsers = (company) => async (dispatch, getState) => {
  const { company_id, company_type } = company || {};
  if (!company_id) {
    return null;
  }
  const { json } = await oauth('GET', 'user', {
    company_id, company_type, search_type: 'company-users',
    exclude_default_company_users: true, exclude_hidden: true, include_user: true
  });
  const users = get(json, 'companyUsers', []);
  dispatch(communityEventsSlice.actions.addEntities({
    users: {
      entities: users,
      id_field: 'user_id',
    }
  }));
  return json;
};

const SERVER_TIMEZONE = 'America/Toronto';
const mapCommunityEvents = (community_events) => {
  return map(community_events, (event) => {
    return {
      ...event,
      scheduled_time: moment.tz(event.scheduled_time, SERVER_TIMEZONE).toJSON(),
    };
  });
};
export const fetchCommunityEvents = (data) => async (dispatch, getState) => {
  const { json } = await oauth('GET', 'community-event', data || {}, {}, {logout: false});
  const community_events = mapCommunityEvents(get(json, 'community_events', []));
  dispatch(communityEventsSlice.actions.addEntities({
    community_events: {
      entities: community_events,
      id_field: 'community_event_id',
    },
    companies: {
      entities: json.companies,
      id_field: 'company_id',
    }
  }));
  return json;
};

let fetchedCommunityEvents = false;
export const fetchOnceCommunityEvents = () => async (dispatch, getState) => {
  if (!fetchedCommunityEvents) {
    dispatch(fetchCommunityEvents()).then(() => {
      fetchedCommunityEvents = true;
    });
  }
};

export const fetchUpcomingEvents = () => async (dispatch, getState) => {
  const { json } = await oauth('GET', 'community-event/upcoming-events', {}, {}, {logout: false}) || {};
  const community_events = mapCommunityEvents(get(json, 'community_events', []));
  dispatch(communityEventsSlice.actions.addEntities({
    community_events: {
      entities: community_events,
      id_field: 'community_event_id',
    },
  }));
  return json;
};

export const createCommunityEvent = ({ community_event_id, ...data }) => async (dispatch, getState) => {
  if (data.scheduled_time) {
    data.scheduled_time = moment(data.scheduled_time).tz(SERVER_TIMEZONE).format('YYYY-MM-DD HH:mm:ss');
  }
  const { json } = community_event_id
    ? await oauth('PUT', `community-event/${community_event_id}`, data)
    : await oauth('POST', 'community-event', data)
  ;
  dispatch(communityEventsSlice.actions.addEntities({
    community_events: {
      entities: [json.community_event],
      id_field: 'community_event_id',
    },
    companies: {
      entities: json.companies,
      id_field: 'company_id',
    }
  }));
  return json;
};

export const createCommunityEventUser = ({ community_event_id }) => async (dispatch, getState) => {
  const { json } = await oauth('POST', 'community-event-user', { community_event_id });
  dispatch(communityEventsSlice.actions.setInterested({
    community_event_user: json.community_event_user, interested: true
  }));
  return json;
};

export const deleteCommunityEventUser = (community_event_user_id) => async (dispatch, getState) => {
  const { json } = await oauth('DELETE', `community-event-user/${community_event_user_id}`, {});
  dispatch(communityEventsSlice.actions.setInterested({
    community_event_user: json.community_event_user, interested: false
  }));
  return json;
};

export default communityEventsSlice;
