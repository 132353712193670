import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createAddSuggestionItem } from '../actions';
import { createAddProductToOrderPopup } from '../actions/popup';
import { getMostRecentUnlockedOrder } from '../selectors';

import { toTitleCase, getAbsoluteUrl } from '../utils';

const SuggestionProduct = ({ suggestion, onCreateAddItem, onCreateAddProductToOrderPopup, loading, has_orders, add_to_order, message_id }) => {
  const url = loading ? '/images/gears.gif' : getAbsoluteUrl(suggestion.product_img);
  const image_style = {
    backgroundImage: `url('${url}')`,
    backgroundSize: loading ? '60px 60px' : 'cover',
  };
  const no_float = {
    float: 'left'
  };
  const handleClickAddToOrder = e => {
    e.preventDefault();
    const parent_type = 'PRESENTATION' === add_to_order.order_type ? 'OPTION': 'PRODUCT';
    if(
      ['esp-products', 'dc-products', 'sage-products', 'ps-products', 'promodata-products'].indexOf(suggestion.copied_from) > -1
    ) {
      if(suggestion.event_id) {
        onCreateAddItem(add_to_order.order_id, parent_type, suggestion.ext_product_id, suggestion.copied_from, 'collaborate', suggestion.suggestion_id, message_id);
      }else{
        onCreateAddItem(add_to_order.order_id, parent_type, suggestion.ext_product_id, suggestion.copied_from, 'message-suggest', suggestion.suggestion_id, message_id);
      }
    }else{
      if(suggestion.event_id) {
        onCreateAddItem(add_to_order.order_id, parent_type, suggestion.product_id, suggestion.copied_from, 'collaborate', suggestion.suggestion_id, message_id);
      }else{
        onCreateAddItem(add_to_order.order_id, parent_type, suggestion.product_id, suggestion.copied_from, 'message-suggest', suggestion.suggestion_id, message_id);
      }
    }
  };
  const handleClickSelectOrderToAddTo = e => {
    e.preventDefault();
    onCreateAddProductToOrderPopup(null, null, suggestion.product_id, suggestion.suggestion_id, message_id);
  };
  return (
    <div className="medium-6 xlarge-4 column" style={no_float}>
      <div className="collaborate-product" style={image_style}>
        <div className="product-info">
          <div className="sku">#{suggestion.product_supplier_code}</div>
          <h4>{suggestion.product_name}</h4>
          <span className="vendor-page">{suggestion.division_name}</span>
        </div>
        <div className="suggestion-actions">
          {add_to_order ?
            <button className="tiny button add-product" onClick={handleClickAddToOrder}>Add to {toTitleCase(add_to_order.order_type)}</button>
          : has_orders && add_to_order == false ?
            <button className="tiny button add-product" onClick={handleClickSelectOrderToAddTo}>Add to an Order</button>
          : null}
          <a target="_blank" href={`/product.php?id=${suggestion.product_id}`} className="tiny button view-product">View Product</a>
        </div>
        <div className="presented">{suggestion.is_added && suggestion.is_added == 1 ? 'Presented' : null}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const has_orders = Object.values(state.entities.orders).length > 0;
  const add_to_order = getMostRecentUnlockedOrder(state, { project_id: ownProps.job_id });
  const loading = state.display.loading.add_item.includes(ownProps.suggestion.product_id);

  return {
    loading,
    has_orders,
    add_to_order
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateAddProductToOrderPopup: (order_id, parent_type, parent_id, suggestion_id, event) => {
      dispatch(createAddProductToOrderPopup(order_id, parent_type, parent_id, suggestion_id, event));
    },
    onCreateAddItem: (order_id, parent_type, parent_id, product_source, suggestion_type, suggestion_id, message_id) => {
      dispatch(createAddSuggestionItem(order_id, parent_type, parent_id, product_source, suggestion_type, suggestion_id, message_id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionProduct);
