import React from 'react';

const ITEM_LOCATION_BADGE_STYLE = {
  textAlign: 'center',
  background: '#394e59',
  color: 'white',
  fontWeight: 'bold',
  padding: '0.5rem',
  borderRadius: '3px'
};

const PresentationItemLocationBadge = ({ index, className = '', style = {} }) => (
  <span className={className} style={{ ...ITEM_LOCATION_BADGE_STYLE, ...style }}>{index + 1}</span>
);

export default PresentationItemLocationBadge;
