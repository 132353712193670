import { createSlice } from "@reduxjs/toolkit";

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: "isLoading",
  initialState,
  reducers: {
    setIsLoadingTrue: (state) => {
      state.isLoading = true;
    },
    setIsLoadingFalse: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      (action) => action.type.startsWith("@@router/LOCATION_CHANGE"),
      (state) => {
        state.isLoading = false;
      }
    );
  },
});

export const { setIsLoadingTrue, setIsLoadingFalse } = loadingSlice.actions;

export const selectIsLoading = (state: { isLoading: { isLoading: any } }) =>
  state.isLoading.isLoading;

export default loadingSlice.reducer;
