import { CircleProgressIcon, colors, SharedStyleTypes } from '@commonsku/styles';
import React from 'react';
import styled from 'styled-components';

const ArtworkName = styled.div`
  font-size: .9rem;
  font-weight: bold;
`;

const ArtworkInfo = styled.div<{ withPicture?: boolean }>`
  padding-left: ${props => props.withPicture ? 0 : "4vw !important"};
  width: 100%;
  position: absolute;
  height: 3rem;
  left: 0;
  ${props => props.withPicture ? "bottom" : "top"} : 0;
  opacity: 1;
  color: black;
  font-size: 14px;
  box-sizing: border-box;
  word-wrap: break-word;
  -moz-box-sizing: border-box;
  -webkit-transition: height .2s ease;
  -moz-transition: height .2s ease;
  transition: height .2s ease;
  z-index: 1;
`;

const ArtworkWrapper = styled.div<{ cssHeight: number } & SharedStyleTypes>`
  width: 100%;
  height: ${props => props.cssHeight > 0 ? props.cssHeight + "vw" : "auto"};
  min-height: 4rem;
  position: relative;
  line-height: 1.5em;
  cursor: pointer;
`;

const ArtworkPicture = styled.div<{ cssHeight: number } >`
  width: 100%;
  height: calc(${props => props.cssHeight}vw - 3.5rem);
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.teal[20]};
`;

export type FileInProgressProps = {
    cssHeight?: number,
    fileName: string,
    progress?: number,
    isError?: boolean,
};

export const FileInProgress = ({ ...props }: FileInProgressProps & SharedStyleTypes) => {
    const cssHeight = props.cssHeight ? props.cssHeight : 17;
    return <ArtworkWrapper cssHeight={cssHeight}>
        <ArtworkPicture cssHeight={cssHeight}>
            <CircleProgressIcon percentage={props.progress || 0} size='large' />
        </ArtworkPicture>
        <ArtworkInfo withPicture={true} >
            <ArtworkName>{props.fileName}</ArtworkName>
        </ArtworkInfo>
    </ArtworkWrapper>;
};
