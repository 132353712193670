import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUserOptions } from '../selectors/dropdowns';
import { getUserImageSrc } from '../utils';
import { findDOMNode } from "react-dom";

class Mention extends Component {

  constructor(props) {
    super(props);
    this.state = {
      indexed_user_id: this.getIndexedUserId(this.props),
      username: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      indexed_user_id: this.getIndexedUserId(nextProps)
    });
  }

  getIndexedUserId(props) {
    const {
      mention_index, mention_users = [],
    } = props;
    const orig = _.get(this.state, 'indexed_user_id', '');
    return _.get(mention_users, mention_index + '.user_id', orig);
  }

  handleClick(e, username) {
    this.props.handleAddTargetToText(username);
  }

  getMentionDOMNode() {
    return this.mentions ? findDOMNode(this.mentions) : null;
  }

  render() {
    const { mention_users, mention_index, } = this.props;
    const chosen = {
      'backgroundColor' : '#EDF2F4'
    };
    return (
      <ul className="tag-results" ref={(mentions) => this.mentions = mentions}>
        {mention_users.map(u =>
          <li className="tag-user" key={u.user_id}
            onClick={e => {
              e.preventDefault(); this.handleClick(this, u.mask);
            }}
            style={this.state.indexed_user_id == u.user_id ? chosen : null}>
            <img src={getUserImageSrc(u)}
              width="48" height="48" />
            <a href="#" className="tag-select">
              {u.contact_first_name} {u.contact_last_name}
              <br />
              <span className="username">@{u.mask}</span>
            </a>
          </li>
        )}
      </ul>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({});

const ConnectedMention = connect(mapStateToProps, mapDispatchToProps)(Mention);

export default ConnectedMention;
