import { get, pick } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finalizeUpdatePurchaseOrder } from '../actions/purchase_order';
import { finalizeUpdateOrder } from '../actions/order';
import { PresentationStatus, EstimateStatus, SalesOrderStatus, InvoiceStatus } from '../models/Status';

const SendEmailCallback = () => {
  const { purchase_orders, orders, statuses } = useSelector((state) => {
    return pick(state.entities, ['purchase_orders', 'orders', 'statuses']);
  });
  const dispatch = useDispatch();

  useEffect(() => {
    window.send_email_callback = (parent_type, parent_id, source_id) => {
      if ('purchase_order' === parent_type) {
        const purchase_order = get(purchase_orders, [parent_id]);
        if (!purchase_order) {
          return;
        }
        const submitted_status_id = Object.values(statuses || {}).filter(s => s.parent_type === 'PURCHASE ORDER' && s.status_name === 'Submitted')[0].status_id;
        dispatch(finalizeUpdatePurchaseOrder(parent_id, 'status_id', purchase_order.status_id, submitted_status_id));
      } else if ('order' === parent_type) {
        const order = get(orders, [parent_id]);
        if (!order) {
          return;
        }
        let new_status_id = null;
        if ('SALES ORDER' === order.order_type) {
          if ([SalesOrderStatus.New, SalesOrderStatus.ClientChangeRequested].includes(order.status_id)) {
            new_status_id = SalesOrderStatus.PendingClientApproval;
          }
	} else if ('INVOICE' === order.order_type) {
          if (InvoiceStatus.Open === order.status_id) {
            new_status_id = InvoiceStatus.SentToClient;
	  }
	} else if ('ESTIMATE' === order.order_type) {
          if (EstimateStatus.Open === order.status_id) {
            new_status_id = EstimateStatus.ClientReview;
	  }
	} else if ('PRESENTATION' === order.order_type) {
          if (PresentationStatus.Open === order.status_id) {
            new_status_id = PresentationStatus.ClientReview;
          }
	}
        if (!!new_status_id) {
          if (statuses[new_status_id]) {
            dispatch(finalizeUpdateOrder(
              order.order_id,
              'status_name',
              null,
              statuses[new_status_id].status_name,
              order.job_id
            ));
	  }
          dispatch(finalizeUpdateOrder(
            order.order_id,
            'status_id',
            order.status_id,
            new_status_id,
            order.job_id
          ));
	}
      }
    };
  }, [dispatch, purchase_orders, orders, statuses]);

  return null;
};
export default SendEmailCallback;
