import Code from '@tiptap/extension-code';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React from 'react';
import { Theme } from '@commonsku/styles';
import MenuBar from './MenuBar';

const additionalGlobalStyles = `
.csku-rich-text-editor .editor-menu-bar {
  font-size: inherit;
  font-family: inherit;
  color: #000;
  accent-color: black;

  min-height: 40px;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 8px 0px 0px 8px;
  background: var(--color-neutrals-20);
  border: 1px solid var(--color-neutrals-60);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 0;
}

.csku-rich-text-editor .editor-menu-bar .items-container {
  display: inline-block;
  margin-right: 16px;
}

.csku-rich-text-editor .editor-menu-bar .material-symbols-outlined:not(.is-active):hover {
  background: var(--color-teal-70);

  svg {
    fill: #fff;
  }
}

.csku-rich-text-editor .ProseMirror {
  outline-color: transparent;
  background: #fff;
  padding: 10px;
  min-height: 80px;
}

.csku-rich-text-editor .ProseMirror.ProseMirror-focused,
.csku-rich-text-editor .ProseMirror {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--color-neutrals-60);
  border-top: 0;
  outline: none;
}

.csku-rich-text-editor .ProseMirror {
  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
`;

const RichTextEditor = ({
  value,
  onChange,
  onBlur,
}) => {
  const editor = useEditor({
    extensions: [
      Code,
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: false,
      }),
      Underline,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: true,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: true,
        },
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const value = editor.getHTML();
      onChange?.(value);
    },
    onBlur: ({ editor }) => {
      const value = editor.getHTML();
      onBlur?.(value);
    },
  });

  return (
    <Theme globalStyles additionalGlobalStyles={additionalGlobalStyles}>
      <div className='csku-rich-text-editor'>
        <MenuBar editor={editor} />
        <EditorContent
          editor={editor}
        />
      </div>
    </Theme>
  );
};

export default RichTextEditor;
