import React from 'react';
import { useSelector } from 'react-redux';
import BotForm from './BotForm';
import { Box } from '@commonsku/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useChatbots from './hooks/useChatbots';

export default function ChatBots() {
    const [bots, addNewBot, updateBot] = useChatbots();
    const companyUsers = useSelector(state => state.dropdowns.company_users);
    const shops = useSelector(state => state.dropdowns.shops);

    const botList = bots.map((bot) => {
        return (
            <Box key={bot.bot_id}>
                <BotForm key={bot.bot_id} bot={bot} users={companyUsers} shops={shops} saveBot={updateBot} />
            </Box>
        );
    });
    return (
        <>
            {botList}
            <div className="row botRow"><a onClick={addNewBot}><b>+ New Bot</b></a></div>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    );
}
