import React from "react";
import { Text, ToggleSwitchStyled } from "@commonsku/styles";

export default function AutoScrollCarousel(props) {
  const {
    value,
    onChange,
  } = props;

  return (
    <div>
      <div style={{ display: 'inline-block', width: '70%', verticalAlign: 'middle', }}>
        <Text as="p" style={{
          color: 'var(--color-neutrals-90)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 16,
          marginBottom: 4,
        }}>Auto Scroll Carousel</Text>
      </div>
      <div style={{ display: 'inline-block', width: '30%', verticalAlign: 'middle', }}>
        <ToggleSwitchStyled
          size="medium"
          selected={+value === 1}
          onClick={() => onChange(+value !== 1 ? 1 : 0)}
          style={{
            minWidth: '100%',
          }}
        />
      </div>
    </div>
  );
}
