import { useCallback, useEffect, useState } from 'react';
import { oauth } from '../utils';

export default function useUserFlags(identity) {
    const [userFlags, setUserFlags] = useState(identity.user_flags || []);
    const [error, setError] = useState(null);

    const hasUserFlag = useCallback((flag_name) => {
        return userFlags.indexOf(flag_name) >= 0;
    }, [userFlags]);

    const onAddFlag = useCallback((flag_name) => {
        setError(null);

        return oauth('POST', 'user-flag/', { user_id: identity.user_id, flag_name }).then(
            ({ json }) => {
                setUserFlags(s =>
                    Array.from(new Set(...s.concat([flag_name])))
                );
                return true;
            },
            ({ json }) => {
                setError(json.error);
                return false;
            }
        );
    }, [identity.user_id]);

    const onDeleteFlag = useCallback((flag_name) => {
        setError(null);

        return oauth('DELETE', 'user-flag/', { user_id: identity.user_id, flag_name }).then(
            ({ json }) => {
                setUserFlags(s => {
                    const idx = s.findIndex(v => v === flag_name);
                    if (idx === -1) { return s; }

                    return [
                        ...s.slice(0, idx),
                        ...s.slice(idx + 1),
                    ];
                });
                return true;
            },
            ({ json }) => {
                setError(json.error);
                return false;
            }
        );
    }, [identity.user_id]);

    const onDeleteAddFlag = useCallback((delete_flag_name, add_flag_name) => {
        onDeleteFlag(delete_flag_name)
            .then(v => {
                if (!v) { return; }
                onAddFlag(add_flag_name);
            });
    }, [onDeleteFlag, onAddFlag]);

    useEffect(() => {
        setUserFlags(identity.user_flags || []);
        setError(null);
    }, [identity]);

    return {
        userFlags,
        error,
        setUserFlags,
        onAddFlag,
        onDeleteFlag,
        onDeleteAddFlag,
        hasUserFlag,
    };
}
