import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Loading } from '@commonsku/styles';
import DebouncedSearchBox from "../../DebouncedSearchBox";
import webImageReducer, {
    resetSearch,
    searchImages,
    setKeyword,
    setNextPage,
    setLoading,
    notifyImageDownload,
} from "../../../redux/webImage";
import withReducers from "../../../store/withReducers";
import { ImageItem } from "./ImageItem";
import config from '../../../config';

const bottomStyle = {
    height: '20rem',
    width: '100%',
    display: 'flex',
    padding: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
};

const containerStyle = {
    overflowY: 'scroll',
    height: '53vh',
    width: '100%',
    paddingBottom: '3rem',
};

const exampleContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
};

const exampleKeywordStyle = {
    color: "#00B1C8",
    cursor: "pointer"
};

const examlpeKeywords = ["Nature", "Abstract", "Wellness", "Pastel"];

const ExampleSearch = (props) => {
    const { onClick, } = props;
    const len = examlpeKeywords.length;
    return examlpeKeywords.map((item, index) => {
        const withComma = index < len - 1;
        return (
            <span
                key={item + (withComma ? 'yes' : 'no')}
                onClick={() => onClick(item)}
                style={exampleKeywordStyle}
            >{withComma ? `${item}, ` : item}</span>
        );
    });
};

const ImageSearchBox = (props) => {
    const dispatch = useDispatch();
    const { onChangeBackground, bodyHeight = '53vh' } = props;
    const bottomRef = useRef(null);
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const images = useSelector(state => state.webImage.images);
    const keyword = useSelector(state => state.webImage.keyword);
    const loading = useSelector(state => state.webImage.loading);
    const endOfImages = useSelector(state => state.webImage.endOfImages);

    function handleSearch(text) {
        dispatch(resetSearch());
        dispatch(setKeyword(text));
        dispatch(searchImages());
    }

    function handleImageClick(imageId, imageSrc) {
        dispatch(notifyImageDownload(imageId));
        onChangeBackground(imageSrc);
    }

    useEffect(() => {
        if (images.length === 0 && !keyword) {
            dispatch(searchImages());
        }
        return () => {
            dispatch(resetSearch());
        };
    }, []);

    const handleScroll =  useCallback(() => {
        if (bottomRef.current && containerRef.current) {
            const bottomRefRect = bottomRef.current.getBoundingClientRect();
            const containerRect = containerRef.current.getBoundingClientRect();
            const triggerDistance = 20 * 16;
            if (bottomRefRect.top + triggerDistance <= containerRect.bottom) {
                dispatch(setLoading(true));
                if (!loading && !endOfImages) {
                    dispatch(setNextPage());
                    dispatch(searchImages(keyword));
                }
            }
        }
    },[dispatch, endOfImages, keyword, loading]);

    const handleExampleSearch = (text) => {
        inputRef.current.setInputValue(text);
    };

    return (
        <Row>
            <Col xs={12} xl={12}>
                <DebouncedSearchBox
                    cRef={inputRef}
                    handleSearch={handleSearch}
                    debounceInterval={1000}
                    placeholder={'Search Unsplash for an image'} />
            </Col>

            <div style={exampleContainerStyle}>
                <a href={`https://unsplash.com/?utm_source=${config.unsplashAppName}&utm_medium=referral`} target="_blank" rel="noreferrer">
                    <span>Search Unsplash's massive free image library</span>
                </a>
                <div>
                    Example searches: <ExampleSearch onClick={handleExampleSearch} />
                </div>
            </div>

            <Row ref={containerRef}
                 style={{ ...containerStyle, height: bodyHeight }}
                 onScroll={handleScroll}>
                {images.map(i =>
                    <Col key={i.id} xs={3} s={2}>
                        <ImageItem image={i} onClick={handleImageClick} />
                    </Col>
                )}
                <div style={bottomStyle} ref={bottomRef}>
                    {loading && !endOfImages && <Loading />}
                    {endOfImages && <span>No more images</span>}
                </div>
            </Row>
        </Row>
    );
};

export default withReducers(ImageSearchBox, { webImage: webImageReducer });