import React from 'react';
import { useSwipeable } from 'react-swipeable';

const Swipeable = ({
  handleOnSwipedTo,
  handleSwiping,
  onSwiped,
  children,
}) => {
  const handlers = useSwipeable({
    delta: 1,
    onSwipedLeft: () => handleOnSwipedTo(1),
    onSwipedRight: () => handleOnSwipedTo(-1),
    onSwiping: (eventData) => {
      if (eventData.dir === 'Left') {
        handleSwiping(-1, eventData.absX, eventData.deltaX);
      } else if (eventData.dir === 'Right') {
        handleSwiping(1, eventData.absX, eventData.deltaX);
      }
    },
    onSwiped: onSwiped,
  });

  return (
    <div {...handlers} className='image-gallery-swipe'>
      {children}
    </div>
  );
};

export default Swipeable;
