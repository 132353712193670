import { createSlice } from '@reduxjs/toolkit';

const initialState = { activeImage: '' };

const heroImageSlice = createSlice({
  name: 'heroImage',
  initialState,
  reducers: {
    setActiveImage(state, action) {
      state.activeImage = action.payload;
    },
  },
});

export const { setActiveImage } = heroImageSlice.actions;
export default heroImageSlice.reducer;