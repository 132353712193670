export class ShopStatus {
  static Open = '61847d8c-1715-11e8-90c7-ac2b6e37e335';
  static Closed = '6ae090be-1715-11e8-90c7-ac2b6e37e335';
}

export class PresentationStatus {
  static Open = '2a704778-5658-11e1-9a89-e811324a6a81';
  static ClientReview = 'b1a09b0e-e806-4926-8969-b0d808cc1ff3';
  static Closed = '32a4d42c-5658-11e1-9a89-e811324a6a81';
  static Converted = '6b404491-40a8-11e3-8da1-74867a3068c9';
}

export class EstimateStatus {
  static Open = '8f7aae13-a861-44d2-a8d8-d7068e7510a7';
  static ClientReview = '9b2d6b83-d23b-42a6-9fdf-2cdcf0729142';
  static Closed = '405334c8-0b68-4c77-966c-9e8bb592c243';
  static Converted = '7c290644-40a8-11e3-8da1-74867a3068c9';
}

export class SalesOrderStatus {
  static New = '4c9d3727-a5cb-4d62-ab42-82ae9b5238fb';
  static PendingClientApproval = '07f49459-99d7-40c2-ba68-7066a11d76cc';
  static ClientChangeRequested = '53b40d53-3dda-4e7e-8a64-6f44c136f96c';
  static ClientApproved = '16ee0574-75ae-47ca-b2c7-d441bc8f19a1';
  static InProduction = '8224599d-d6d6-40b4-be96-84403ea6b2ee';
  static Shipped = '3d1be487-7195-41b6-8c57-82e4663c36d2';
  static ReadyToBeInvoiced = '0328ee19-22a8-4774-a75a-75fd66f08044';
  static Invoiced = '1e4731d9-85f6-47b1-bc1b-b03be041ed85';
  static Closed = 'b05b061a-2463-11e1-83be-1231392503bd';
}

export class InvoiceStatus {
  static Open = '11a8be26-95a3-4572-ac02-798d5b52f246';
  static SentToClient = '6c41260f-df3a-11eb-8989-0a51afecc89b';
  static Exported = '413824bd-85b2-4e69-87b5-72ce1d9964c0';
  static Paid = '502140ba-f44e-447d-ae7a-2889492872d7';
}

export class CollectionStatus {
  static Draft = '589913c2-d063-11e7-be38-f48e38e80e9c';
  static Published = '5ee34e74-d063-11e7-be38-f48e38e80e9c';
  static Closed = '63c6c2af-d063-11e7-be38-f48e38e80e9c';
}
