let localConfig;
try {
  localConfig = require('./config.local').default;
} catch (e) {
  localConfig = {};
}

const config = {
  // also used in web/js/distributor_promostandards.js
  websocketServer: 'wss://wss.commonskudev.com/websocket/',
  peerjsServer: 'wss.commonskudev.com',
  promostandards_api_url: 'https://promo.commonsku.com/api',
  inboundEmail: {
    integrationDomain: 'commonskudev.com',
    whitelist: [
      //'vandelay', 'rightsleeve', 'metronbranding',
    ],
  },
  promodata_url: 'https://proxy.commonskudemo.com/promodata/v1',
  unsplashAppName: 'commonsku-dev',
  canAssumeAnyUser: (assume_by) => {
    return config.environment !== 'production';
  },
  googleMapsApiKey: '',
  addressAutocompleteCountries: ["us", "ca", "au", "uk", "nz"],
  ps_sort_options: [
    { key: 'min_net_price', value: 'Cost: Low to High' },
    { key: '-max_net_price', value: 'Cost: High to Low' },
    { key: '-popularity', value: 'Popularity' },
  ],
  po_financials_test_prefix: '***test***',
  skummunity_base_url: 'https://skummunity.commonsku.com',
  skummunity_return_path: '/categories',
  sendElectronicPoInBackground: (division_id) => {
    // only disable background send for PCNA
    return ![
      '030ecb4e-4e2d-4240-8b9c-75493f85e10a', // polyconcept north america
      '16ac8e08-2681-4cd9-93d2-1d0c45b51268', // leed's (us)
      '289f05f5-263b-4e91-972e-7fabd1cead3c', // bullet
      '67627a89-8c82-4795-96da-8d1b0e49b200', // trimark powered by leed's
      '9f455ad7-5e8f-4e6d-aef1-96a4e690df9a', // trimark
      'cdf58e5a-a30d-11e2-80a6-1231392238ea', // bullet line (us)
      '0076fe2c-1f3c-4c65-8f35-1ab92896fbba', // leed's (can)
      '9ccff4b2-f0a6-4f2d-ab05-3a60300e366a', // bullet line (canada)
      '056886d1-e960-11ed-983b-0ad1fa230847', // pcna canada (leeds, trimark, bullet)
    ].includes(division_id);
  },
  algolia: {
    applicationID: '',
    searchKey: '',
    index: '',
    cadDivisions: [],
  },
  ...localConfig,
};

export default config;
