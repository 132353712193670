import {
  ENABLE_INVENTORY_SUCCESS,
  LOAD_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_WAREHOUSE_SUCCESS,
  CREATE_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_ITEM_SUCCESS,
  DELETE_INVENTORY_ITEM_SUCCESS
} from '../actions/inventory';

export const inventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_INVENTORY_SUCCESS:
      return { ...state, [action.payload.inventory.inventory_id]: Object.keys(action.payload.inventory).filter(
        k => !['warehouse', 'contacts', 'items'].includes(k)
      ).reduce(
        (o, k) => ({
          ...o,
         [k]: action.payload.inventory[k]
        }),
        {}
      )};
    case LOAD_INVENTORY_SUCCESS:
      return { ...state, [action.payload.inventory.inventory_id]: Object.keys(action.payload.inventory).filter(
        k => !['warehouse', 'contacts', 'items'].includes(k)
      ).reduce(
        (o, k) => ({
          ...o,
         [k]: action.payload.inventory[k]
        }),
        {}
      )};
    case UPDATE_INVENTORY_SUCCESS:
        return { ...state, [action.payload.inventory.inventory_id]: action.payload.inventory };
  }
  return state;
};

export const warehouseReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_INVENTORY_SUCCESS:
        return { ...state, [action.payload.inventory.warehouse_id]: action.payload.inventory.warehouse };
    case LOAD_INVENTORY_SUCCESS:
        return { ...state, [action.payload.inventory.warehouse_id]: action.payload.inventory.warehouse };
    case UPDATE_INVENTORY_SUCCESS:
        return { ...state, [action.payload.inventory.warehouse_id]: action.payload.inventory.warehouse };
    case UPDATE_WAREHOUSE_SUCCESS:
        return { ...state, [action.payload.warehouse.warehouse_id]: action.payload.warehouse };
  }
  return state;
};

export const inventoryItemReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_INVENTORY_SUCCESS:
      return { ...state, ...action.payload.inventory.items.reduce((o, i) => ({ ...o, [i.inventory_item_id]: i }), {}) };
    case LOAD_INVENTORY_SUCCESS:
      return { ...state, ...action.payload.inventory.items.reduce((o, i) => ({ ...o, [i.inventory_item_id]: i }), {}) };
    case UPDATE_INVENTORY_SUCCESS:
      return { ...state, ...action.payload.inventory.items.reduce((o, i) => ({ ...o, [i.inventory_item_id]: i }), {}) };
    case CREATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, [action.payload.inventory_item.inventory_item_id]: action.payload.inventory_item };
    case UPDATE_INVENTORY_ITEM_SUCCESS:
      return { ...state, [action.payload.inventory_item.inventory_item_id]: action.payload.inventory_item };
    case DELETE_INVENTORY_ITEM_SUCCESS:
      return Object.keys(state).filter(ii => ii !== action.payload.inventory_item_id).reduce((o, ii) => ({ ...o, [ii]: state[ii] }), {});
  }
  return state;
};

export default inventoryReducer;
