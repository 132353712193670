import { get, trim } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Popup, Col, Row, colors, H3, ShowPopup, Loading } from '@commonsku/styles';
import LabelledInput from './LabelledInput';
import useSanmarRequest from '../hooks/useSanmarRequest';

const SuccessPopup = ({ onClose }) => {
  return <Popup
    noHeader={true}
    noCloseButton={true}
    style={{ width: '1000px', height: '350px', paddingBottom: '0px' }}
  >
    <div style={{textAlign: 'center', transform: 'translate(0%,+35%)'}}>
      <H3 style={{marginBottom : '1rem'}}>Request Sent</H3>
      <p style={{marginBottom : '1rem'}}> Your request has been sent to SanMar. Please check your inbox for details.</p>
      <Button onClick={onClose}>Done</Button>
    </div>
  </Popup>;
};

function SanmarPopup({ status, update, onClose }) {
  const [accountId, setAccountId] = useState('');
  const [inputError, setInputError] = useState('');
  const [loading, setLoading] = useState(false);
  const sendEmailToSanmar = () => {
    if (accountId === '') {
      setInputError('Account number cannot be empty.');
    } else {
      setInputError('');
      setLoading(true);
      update({ account_number: accountId, status: 'SUBMITTED'})
        .then((response) => {
          if (get(response, 'json.sanmar_request.status', '') !== 'SUBMITTED' ) {
            setInputError('Some error occured. Please try again.');
          }
        })
        .finally(() => {
          setLoading(false);
        })
      ;
    }
  };

  const handleChange = (value) => {
    setAccountId(trim(value));
  };

  const dismissModal = () => {
    update({ status: 'DISMISSED' }).finally((response) => {
      onClose();
    });
  };

  return status === 'SUBMITTED' ? <SuccessPopup onClose={onClose}/> : <Popup
    noHeader={true}
    noCloseButton={true}
    style={{
      width: '1000px' , height: '350px', paddingBottom: '0px',
      backgroundImage: 'linear-gradient(to right, #E1F7FA 58%, #fff 38%)',
    }}
  >
    <Row>
      <Col xs={12} sm ={7} md ={7} style={{paddingLeft: '15px', paddingRight: '15px'}} >
        <h2 style={{ display: 'block',fontSize: '1.5em',marginBlockStart: '0.83em', marginBlockEnd: '0.83em',marginInlineStart: '0px',marginInlineEnd: '0px',fontWeight: 'bold'}}>Request SanMar US Connected+ Access</h2>
        <p>
          Connect to SanMar US with commonsku Connected+ to get inventory, pricing, order status updates, and all <a href = "https://commonsku.com/connectedworkflow" style={{color: colors.primary}}> Connected+ workflow</a> benefits for Sanmar products and purchase orders.
        </p>
        <br />
        <p>
          By submitting this form, you will send a request via email to SanMar to configure Connected+ for your company’s commonsku account. A SanMar representative may follow up via email to confirm your details.
        </p>
      </Col>
      <Col xs={12} sm={5} md={5} style={{paddingLeft : '15px', paddingRight: '15px'}} >
        <img src="/images/logos/sanmar-logo.png" alt="SanMar" style={{width : '70%', marginLeft: '20px', marginTop: '20px'}} />
        <br />
        <br />
        <b style={{marginLeft: '20px'}}>SanMar Account #</b>
        <LabelledInput type="text" style={{height:"30px" ,marginLeft : '20px', width:"320px"}} value={accountId} placeholder="Type Here" onChange={handleChange}/>
        {inputError && <p style={{color: colors.errors.main, marginLeft : '20px'}}> {inputError}</p> }
        {loading ? <Loading/> : <Row mr={10} mt={30}>
          <Button size="medium" ml={20} mt={10} onClick={sendEmailToSanmar}>Request Connected+</Button>
          <Button size="medium" variant="secondary" ml={20} mt={10} onClick={dismissModal}>Maybe later</Button>
        </Row>}
      </Col>
    </Row>
  </Popup>;
}

export const SanmarNotificationBar = () => {
  const { status, update } = useSanmarRequest({
    requireSync: (status) => !(['SUBMITTED', 'NA'].includes(status))
  });

  return (status === 'DISMISSED' || status === 'NA') && <Row style={{ backgroundColor: colors.navy[80], marginBottom: '20px'}}>
    <Col md={2} lg={2}>
      <img src="/images/logos/sanmar-notification-logo.png" alt="SanMar" style={{width : '70%', marginLeft: '20px', marginTop: '20px'}} />
    </Col>
    <Col md={8} lg={8} style={{marginTop: '15px'}}>
      <p style={{color:'white'}}><b>This project includes SanMar products </b><br/>
        Connect to SanMar to get the full benefits of the connected workflow.
      </p>
    </Col>
    <Col md={2} lg={2} style={{marginTop:'15px'}}>
      <ShowPopup popup={SanmarPopup} status={status} update={update} render={({ onClick }) => {
        return <Button
          size='large'
          style={{ color: 'white' ,backgroundColor: '#F6FEFF1A', border: 'none', padding:'15px', float: 'right', marginRight: '10px' }}
          onClick={onClick}
        ><b>Connect to SanMar</b></Button>;
      }}/>
    </Col>
  </Row>;
};

const SanmarModal = () => {
  const { status, update } = useSanmarRequest({
    requireSync: (status) => !(['DISMISSED', 'SUBMITTED', 'NA'].includes(status))
  });
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (status === 'DISPLAYED') {
      setShow(true);
    }
  }, [status]);

  return show && <SanmarPopup status={status} update={update} onClose={() => {
    setShow(false);
  }}/>;
};

export default SanmarModal;
