import React, { useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import { isBool, isNumber, isString } from "../types/type_guards";
import { rebuildTooltip } from "../components/helpers";

interface TooltipAttributes {
  "data-for": string;
  "data-tip": string;
  "data-html": boolean;
}

export default function useTooltipAttributes(
  tooltipText: React.ReactNode,
  targetId: string
): TooltipAttributes | null {
  const [formattedTooltipText, setFormattedTooltipText] = useState('');
  const [isHtml, setIsHtml] = useState(false);
  useEffect(() => {
    if (tooltipText === undefined || tooltipText === null || isBool(tooltipText)) {
      setFormattedTooltipText("");
      setIsHtml(false);
      return;
    }

    if (isString(tooltipText) || isNumber(tooltipText)) {
      const lines = String(tooltipText).split("\n").map((line) => line.trim()).filter((line) => line !== "");
      setFormattedTooltipText(lines.join("<br/>"));
      setIsHtml(lines.length > 1);
      return;
    }

    let mounted = true;

    function update() {
      const div = document.createElement("div");
      const root = createRoot(div);
      flushSync(() => {
        root.render(tooltipText);
      });
      if (mounted) {
        setFormattedTooltipText(div.innerHTML);
        setIsHtml(true);
	rebuildTooltip();
      }
    }
    setTimeout(update);
    return () => { mounted = false; }
  }, [tooltipText]);

  const tooltipAttributes = useMemo<TooltipAttributes | null>(() => {
    if (formattedTooltipText) {
      return {
        "data-for": targetId,
        "data-tip": formattedTooltipText,
        "data-html": isHtml,
      };
    }
    return null;
  }, [targetId, formattedTooltipText, isHtml]);

  return tooltipAttributes;
}
