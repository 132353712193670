import React from 'react';

export default function MarketingTile({
    link,
    title,
    imgSrc,
    maxHeight=170,
    boxShadow='rgb(72 78 86 / 10%) 0px 4px 5px',
}) {
    if (!imgSrc) {
        return null;
    }

    return (
        <a
            href={link || "https://www.skucon.com/"}
            target={'_blank'}
            rel="noreferrer"
        >
            <img
            alt={title || 'skucon 2022'}
            style={{
                borderRadius: 5,
                maxWidth: '100%',
                maxHeight: maxHeight,
                boxShadow: boxShadow,
            }}
            src={imgSrc || "/images/dashboard_v2/SKUCon2022-horizontal.png"}
            />
        </a>
    );
}
