import { createSelector } from '@reduxjs/toolkit';

export const getAddresses = s => s.entities.addresses;
export const getAddressesList = createSelector(
    getAddresses,
    s => Object.values(s || {})
);

export const addressById = createSelector(
    [getAddresses, (s, addressId) => addressId],
    (s, addressId) => s[addressId]
);

const addressOptionMapFunc = v => ({
    label: `${v.address_name}`.trim(),
    value: v.address_id,
    type: v.address_type,
});

export const addressOptions = createSelector(
    getAddressesList,
    s => s.map(addressOptionMapFunc)
);

export const addressesByType = createSelector(
    [getAddressesList, (s, addressType) => addressType],
    (s, addressType) => s.filter(
        v => v.address_type === addressType || v.address_type === 'BOTH'
    )
);
export const addressByTypeOptions = createSelector(
    addressesByType,
    s => s.map(addressOptionMapFunc)
);

export const addressesByTypeAndParent = createSelector(
    [
        getAddressesList,
        (s, addressType) => addressType,
        (s, addressType, parentType) => parentType,
        (s, addressType, parentType, parentId) => parentId,
    ],
    (s, addressType, parentType, parentId) => s.filter(
        v => (v.address_type === addressType || v.address_type === 'BOTH')
            && v.parent_type === parentType
            && v.parent_id === parentId
    )
);
export const addressByTypeAndParentOptions = createSelector(
    addressesByTypeAndParent,
    s => s.map(addressOptionMapFunc)
);

export const addressesByParent = createSelector(
    [
        getAddressesList,
        (s, parentType) => parentType,
        (s, parentType, parentId) => parentId
    ],
    (s, parentType, parentId) => s.filter(
        v => v.parent_type === parentType && v.parent_id === parentId
    )
);
export const addressByParentOptions = createSelector(
    addressesByParent,
    s => s.map(addressOptionMapFunc)
);
