import React, { useEffect, useState } from 'react';

import Select from './Select';

let unique_id = 0;
const id_prefix = 'LabelledSelect';

export default function LabelledSelect(props) {
  const {
    id,
    className,
    value,
    label,
    onChange,
    searchable,
    isSearchable,
    multi,
    isMulti,
    async,
    creatable,
    ...rest
  } = props;

  const [labelId, setLabelId] = useState(id || (id_prefix + unique_id));
  useEffect(() => {
    ++unique_id;
    if (!id) {
      setLabelId(id_prefix + unique_id);
    }
  }, [id]);

  return (
    <div className={className}>
      <Select
        id={labelId}
        name={labelId}
        value={value}
        label={label}
        change={onChange}
        isMulti={isMulti || multi}
        isSearchable={isSearchable || searchable}
        {...(async ? { labeledAsync: true }: {})}
        {...(creatable ? { labeledCreatable: true }: {})}
        {...rest}
      />
    </div>
  );
}
