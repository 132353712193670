import React from 'react';

import { formatMoney, getImageSrc } from '../utils';
import { BaseProductItemSummary, INVENTORY_BADGE_STYLE } from './ProductItemSummary';
import { withDraggableItem } from './DraggableItem';
import DragItemTypes from '../DragItemTypes';
import Img from './Img';

const OptionItemDescription = ({
  base_item,
  handleMouseOver,
  handleMouseOut,
  actions,
  product_url,
  convert = false
}) => {
  const sku = base_item.item_sku ? base_item.item_sku : (base_item.product ? base_item.product.product_supplier_code : '** Unknown **');
  const vendor_url = `/vendor.php?id=${base_item.supplier_id}&division_id=${base_item.division_id}`;
  const phone = (base_item.division_phones || [])[0] ? base_item.division_phones[0] : null;
  const default_image = base_item.item_images.map(ii => ii.image)[0];

  return (
    <td className="description" rowSpan={Math.max(base_item.options.filter(i => 0 == i.hidden).length + base_item.item_costs.length, 1) + 1}>
      <div className="image">
        <Img src={getImageSrc(default_image)} onMouseOver={handleMouseOver ? handleMouseOver('item') : null} onMouseOut={handleMouseOut ? handleMouseOut('item') : null} />
      </div>
      <a className="product-sku" href={product_url} target="_blank" onClick={e => e.stopPropagation()}>{sku}</a>
      {base_item.hasInventory && <span style={INVENTORY_BADGE_STYLE}>Inventory</span>}
      <div className="product-vendor-container"><a className="product-vendor" href={vendor_url} target="_blank" onClick={e => e.stopPropagation()}>{base_item.division_name}</a>{phone && phone.phone_number ? ` (${phone.phone_number}${phone.phone_extension ? ' x' + phone.phone_extension : ''})` : null}</div>
      <span className="product-name">{base_item.item_name}</span>
      {actions}
      {convert ? null : <div>{base_item.item_description}</div>}
    </td>
  );
};

export const OptionItemOptionRow = props => {
  const { base_item, option_item, convert = false, handleChangeQuantity, style, index = 0 } = props;
  const visible_options = base_item.options.filter(o => o.hidden == 0);
  const first_row = !visible_options.length || visible_options[0].item_id === option_item.item_id;
  const product_url = `/product.php?id=${base_item.parent_id}`;

  return (
    <tr style={style}>
      {convert && first_row ?
        <td rowSpan={Math.max(visible_options.length, 1) + 1}>
          <input
            type="number" tabIndex={index} name={base_item.item_id}
            onChange={handleChangeQuantity}
            onWheel={(e) => e.target.blur()}
          />
        </td> : null}
      {first_row ?
        <OptionItemDescription {...props} product_url={product_url} />
      : null }
      <td className="title"></td>
      <td className="quantity">{option_item ? parseInt(option_item.total_units, 10) : null}</td>
      <td className="margin">{option_item ? parseFloat(option_item.total_margin).toFixed(2) + '%' : null}</td>
      <td className="amount">{option_item ? '$' + formatMoney(option_item.unit_price) : null}</td>
    </tr>
  );
};

export const OptionItemCostRow = ({ item_cost, style, convert = false }) => {
  return (
    <tr style={style}>
      {convert ? <td>&nbsp;</td> : null}
      <td className="title">{item_cost.item_cost_title}</td>
      <td className="quantity"></td>
      <td className="margin">{parseFloat(item_cost.total_margin).toFixed(2)}%</td>
      <td className="amount">${formatMoney(item_cost.total_price)}</td>
    </tr>
  );
};

class OptionItemDetailedSummary extends BaseProductItemSummary {

  constructor(props) {
    super(props);
  }

  renderItem() {
    const { item } = this.props;
    const option_props = {
      base_item: item,
      handleMouseOver: this.handleMouseOver,
      handleMouseOut: this.handleMouseOut,
      actions: this.renderActions()
    };

    const visible_options = item.options.filter(o => o.hidden == 0);

    return item.options.map(o =>
      0 == o.hidden ? <OptionItemOptionRow key={o.item_id} {...option_props} option_item={o} /> : null
    ).concat(!visible_options.length ?
      <tr key="empty">
        <OptionItemDescription {...option_props} />
        <td className="title"></td>
        <td className="quantity"></td>
        <td className="margin"></td>
        <td className="amount"></td>
      </tr>
      : null
    ).concat(item.item_costs.map(ic =>
      <OptionItemCostRow key={ic.item_cost_id} {...option_props} item_cost={ic} />
    )).concat(
      <tr key="filler"><td colSpan={4} style={{ height: '100%' }}>&nbsp;</td></tr>
    );
  }
}

export default withDraggableItem(DragItemTypes.ITEM, OptionItemDetailedSummary);
