import { get } from 'lodash';
import React from 'react';
import { CollaborateIcon, EPOIcon, InventoryIcon, OrderStatusIcon } from '@commonsku/styles';

const iconSize = {
  tiny: {
    width: 14,
    height: 14,
  },
  small: {
    width: 16,
    height: 16,
  },
  medium: {
    width: 24,
    height:24,
  },
  large: {
    width: 32,
    height:32,
  },
  huge: {
    width: 48,
    height:48,
  },
  default: {
    height: 24,
    width: 24,
  },
};

const ImgIcon = ({ image, tooltip, service_level, style, size, altText }) => {
  return <img
    src={`/images/badges/${image}`}
    alt={altText || tooltip}
    className="level-badge"
    style={{
      ...get(iconSize, size, iconSize.default) || {},
      opacity: 'unavailable' === service_level ? 0.5 : 1,
      ...style,
    }}
  />;
};

const BADGE_TYPES = {
  collaborate: {
    Icon: CollaborateIcon,
    tooltip: 'Collaborate',
    link: 'https://help.commonsku.com/knowledge/what-is-collaborate'
  },
  inventory: {
    Icon: InventoryIcon,
    tooltip: 'Inventory',
    link: 'https://help.commonsku.com/knowledge/inventory',
  },
  order_status: {
    Icon: OrderStatusIcon,
    tooltip: 'Order Status',
    link: 'https://help.commonsku.com/knowledge/order-status-and-shipment'
  },
  epo: {
    Icon: EPOIcon,
    tooltip: 'ePO',
    link: 'https://help.commonsku.com/knowledge/electronic-purchase-order-epo'
  },
  connected: {
    Icon: ImgIcon, image: "connected.png", tooltip: "This supplier supports a connected workflow using PromoStandards."
  },
  benefits: {
    Icon: ImgIcon, image: "benefits.png", tooltip: "This supplier offers special benefits to commonsku users."
  }
};

const Badge = ({ style, type, service_level = 'yes', iconStyle = {}, ...props }) => {
  if (!type || !BADGE_TYPES[type] || service_level === 'no') {
    return null;
  }
  const { Icon, ...iconProps } = BADGE_TYPES[type];

  return <a style={style} target="_blank" href={BADGE_TYPES[type].link} title={BADGE_TYPES[type].tooltip}>
    <Icon style={iconStyle} service_level={service_level} {...iconProps} {...props}/>
  </a>;
};
export default Badge;
