import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

export const getDropdownContacts = s => s.dropdowns.contacts;
export const getContacts = s => s.entities.contacts;

export const getContactsList = createSelector(
    getContacts,
    s => Object.values(s || {})
);

const contactOptionMapFunc = v => ({
    label: `${v.contact_first_name} ${v.contact_last_name}`.trim(),
    value: v.contact_id
});

export const contactOptions = createSelector(
    getContactsList,
    s => s.map(contactOptionMapFunc)
);

// returns { [contact_company_id: [{...contact1}, {...contact2}, ...], ...] }
export const getCompanyContacts = createSelector(
    [
        getContacts,
        getDropdownContacts
    ],
    (s, companyContacts) => {
        return _.keys(companyContacts).reduce(
            (acc, k) => ({
                ...acc,
                [k]: companyContacts[k].map(cId => s[cId]),
            }),
            {}
        );
    }
);

export const getContactsByCompanyId = createSelector(
    [
        getContacts,
        getDropdownContacts,
        (s, companyId) => companyId
    ],
    (s, companyContacts, companyId) => {
        const contactIds = _.get(companyContacts, [companyId], []);
        return contactIds.map(cId => s[cId]);
    }
);

export const contactsByCompanyIdOptions = createSelector(
    getContactsByCompanyId,
    s => s.map(contactOptionMapFunc)
);
