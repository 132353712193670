import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toggle, ToggleLink } from "@commonsku/styles";
import { createUpdate } from "../../actions";
import {
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
} from "../../actions/item";
import ConfirmSwitchPsDecoratorPopup from "./ConfirmSwitchPsDecoratorPopup";
import FullScreenLoading from "../helpers/FullScreenLoading";
import { getProductConfigurations } from "../../promostandards";
import { getProduct } from "../../selectors/items";

const labels = ["Supplier Decorator", "3rd Party Decorator"];

const updateItem = createUpdate("item", {
  request: UPDATE_ITEM_REQUEST,
  success: UPDATE_ITEM_SUCCESS,
  failure: UPDATE_ITEM_FAILURE,
  failure_message: "Unable to update item",
});


const DecoratorSelector = ({
  itemId,
  defaultIndex,
  isPS,
  style,
  itemLocationNum,
}) => {
  const index = parseInt(defaultIndex || "0", 10);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const item = useSelector((state) => state.entities.items[itemId]);
  const product = useSelector((state) =>
    getProduct(state, { product_id: item.parent_id })
  );

  const updateItemDecorator = useCallback(
    async (newVal) => {
      setLoading(true);
      try {
        await dispatch(
          updateItem(itemId, "is_3rd_party_decorator", index, newVal)
        );
        setLoading(false);
      } catch (error) {
        console.error("update item failed: ", error);
        setLoading(false);
      }
    },
    [dispatch, index, itemId]
  );


  const handleClick = useCallback(
    async (i) => {
      if (i !== index) {
        if (isPS) {
          const { locations } =
            (await getProductConfigurations({
              productId: product?.ext_product_id,
            })) || {};
          const isPSLocationConfigured = !!locations?.length;

          if (isPSLocationConfigured && itemLocationNum > 0) {
            setShowPopup(true);
            return;
          }
        }
        updateItemDecorator(i);
      }
    },
    [index, isPS, itemLocationNum, product?.ext_product_id, updateItemDecorator]
  );

  const handleYes = useCallback(() => {
    updateItemDecorator(!index);
  }, [index, updateItemDecorator]);

  const renderLinks = () => {
    return labels.map((label, i) => {
      return (
        <ToggleLink
          key={i}
          onClick={() => handleClick(i)}
          style={{ marginLeft: "0" }}
          selected={i === index}
        >
          <span style={{ fontSize: "12px", fontWeight: 600 }}>{label}</span>
        </ToggleLink>
      );
    });
  };

  return (
    <>
      {showPopup && (
        <ConfirmSwitchPsDecoratorPopup
          onClose={() => setShowPopup(false)}
          onClickYes={handleYes}
          is3rdPartyDecorator={index === 1}
          zIndex={1}
        />
      )}
      <FullScreenLoading
        loading={loading}
        message="Updating...Please Wait"
        zIndex={9999}
      />
      <Toggle style={style}>{renderLinks()}</Toggle>
    </>
  );
};

export default DecoratorSelector;
