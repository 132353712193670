import _, { split } from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Artwork, Theme } from '@commonsku/styles';

import { createDisplayArtworkPopup, createValidateConfirmationPopup } from '../actions/popup';
import { createDeleteFile, createUpdateFile } from '../actions/file';

import { getImageSrc, formatDate, isImageByFileExt, download } from '../utils';
import Toggle from './Toggle';

const TBD_FILE_ID = '00000000-0000-0000-0000-000000000000';

class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    _.bindAll(this, ['handleEditFile', 'handleSaveFile']);
  }
  isImage(file_type, file_name) {
    const ext = file_name.split('.').pop().toLowerCase();
    switch (file_type) {
      case 'application/octet-stream':
        return ext !== 'dst';
      case 'application/pdf':
      case 'image/jpeg':
      case 'binary/octet-stream':
      case 'image/png':
      case 'application/postscript':
      case 'image/gif':
      case 'image/vnd.adobe.photoshop':
      case 'image/x-ms-bmp':
      case 'image/tiff':
      case 'image/bmp':
      case 'image/pjpeg':
      case 'image/x-eps':
      case 'application/mac-binhex40':
      case 'application/binary':
      case 'application/illustrator':
      case '':
        return true;
    }
    return false;
  }

  handleClickDownload(file) {
    return (e) => {
      //e.preventDefault();
      download(getImageSrc(file, 'original'), file.file_display_name);
    };
  }

  handleEditFile(file) {
    this.setState({
      [file.file_id]: file.file_display_name
    });
  }

  handleSaveFile(file) {
    const { onCreateUpdateFile } = this.props;

    const updated_file = Object.assign({}, file, { file_display_name: this.state[file.file_id] });
    onCreateUpdateFile(updated_file);
    this.setState({ [file.file_id]: false });
  }

  renderImageFile(file) {
    const { onCreateDisplayArtworkPopup, onSelectFile, onCreateDeleteFile, noTruncate, autoHideOnError } = this.props;
    const parts = split(file.file_display_name, '?');
    const name = parts[0] + (parts.length > 1 ? '.png' : '');

    return <Toggle key={file.file_id}
      render={(setToggle) => {
        return <Col padded xs={6} sm={4} style={{ padding: '0.5rem' }}>
          <Artwork
            key={file.file_id}
            noTruncate={noTruncate}
            date={formatDate(file.date_created)}
            name={name}
            picture={getImageSrc(file, 'medium')}
            onDownload={this.handleClickDownload(file)}
            onClick={() => {onSelectFile(file);}}
            {...(autoHideOnError ? { onError: () => setToggle(false) } : {})}
            {...(onSelectFile ? {} : {
              name: this.state[file.file_id] || name,
              onClick: e => onCreateDisplayArtworkPopup(file),
              onDelete: e => onCreateDeleteFile(file.file_id),
              onEdit: e => this.handleEditFile(file),
              edit: !!this.state[file.file_id],
              onSave: e => this.handleSaveFile(file),
              inputProps: {
                onChange: (e) => { this.setState({ [file.file_id]: e.target.value }); }
              },
            })}
          />
          <br/>
        </Col>;
      }}
    />;
  }

  renderOtherFile(file, index) {
    const { onSelectFile, onCreateDeleteFile, noTruncate } = this.props;

    if(onSelectFile) {
      return (
        <Col padded key={file.file_id} xs={6} sm={4} style={{ padding: '0.5rem' }}>
          <Artwork
            noTruncate={noTruncate}
            date={formatDate(file.date_created)}
            name={file.file_display_name}
            onDownload={this.handleClickDownload(file)}
            onClick={() => {onSelectFile(file);}}
          />
          <br />
        </Col>
      );
    }

    return (
      <Col padded key={file.file_id} xs={6} sm={4} style={{ padding: '0.5rem' }}>
        <Artwork
          noTruncate={noTruncate}
          date={formatDate(file.date_created)}
          name={this.state[file.file_id] ? this.state[file.file_id] : file.file_display_name }
          onDownload={this.handleClickDownload(file)}
          onDelete={e => onCreateDeleteFile(file.file_id)}
          onEdit={e => this.handleEditFile(file)}
          edit={this.state[file.file_id] ? true : false}
          onSave={e => this.handleSaveFile(file)}
          inputProps={{
            onChange: (e) => { this.setState({ [file.file_id]: e.target.value }); }
          }}
        />
        <br />
      </Col>
    );
  }

  render() {
    const { files } = this.props;

    return (
      <Theme>
        <Row>
          {files.filter(f => isImageByFileExt(f.file_display_name) || f.is_image || this.isImage(f.file_type, f.file_display_name)).map((f, i) =>
            this.renderImageFile(f, i)
          )}
        </Row>
        <br />
        <Row>
          {files.filter(f => !isImageByFileExt(f.file_display_name) && !f.is_image && !this.isImage(f.file_type, f.file_display_name)).map((f, i) =>
            this.renderOtherFile(f, i)
          )}
        </Row>
      </Theme>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let files = [];
  if(!ownProps.exclude_file_ids) {
    ownProps.exclude_file_ids= [];
  }
  if(!ownProps.files) {
    switch(ownProps.type) {
      case 'project':
        files = files.concat(Object.values(state.entities.files).filter(f => f.parent_id === ownProps.job_id && f.active == 1));
      break;
      case 'shop':
        files = files.concat(Object.values(state.entities.files).filter(f => f.parent_id === ownProps.shop_id && f.active == 1));
      break;
      case 'client':
        files = Object.values(state.entities.files).filter(f => f.parent_id === ownProps.client_id);
        if(ownProps.folder_id) {
      files = files.filter(f => f.folder_id === ownProps.folder_id);
        }
      break;
    }
  }else{
    files = ownProps.files;
  }
  if(ownProps.exclude_file_ids) {
      files = files.filter(f => !ownProps.exclude_file_ids.includes(f.file_id));
     }
  files.sort((a, b) => (b.date_created > a.date_created) ? 1 : -1);

  let TBD = [];
  if(ownProps.tbd) {
    TBD = [{
      file_id: TBD_FILE_ID,
      file_display_name: 'TBD.png',
      date_created: '2005-01-01 00:00:00',
      original_url: '/images/404.png',
      is_image: true,
      file_type: "image/png"
    }];
  }

  return {
    files: TBD.concat(files)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateDisplayArtworkPopup: (file) => {
      dispatch(createDisplayArtworkPopup(file));
    },
    onCreateDeleteFile: (file_id) => dispatch(createValidateConfirmationPopup(() => dispatch(createDeleteFile(file_id)), [])),
    onCreateUpdateFile: file => dispatch(createUpdateFile(file))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
