import React from 'react';

const ProductWarnings = ({ warnings, show, loading }) => {
    if(loading) {
        return(
            <img src="/images/gears.gif" />
        );
    }

    return (
        <div style={show == 0 ? {'opacity': 0.5} : null}>
            {warnings.map(w =>
                <p key={w.product_warning_id}><img style={{ 'width': '20px', 'height': '18px', 'float': 'left' }} src="/images/warning.svg" /><b>WARNING:</b> <span dangerouslySetInnerHTML={{__html: w.description}}></span></p>
            )}
        </div>
    );
};

export default ProductWarnings;
