import { createSelector } from '@reduxjs/toolkit';

export const getSuppliers = s => s.entities.suppliers;
export const getSuppliersList = createSelector(
    getSuppliers,
    s => Object.values(s || {})
);

const supplierOptionMapFunc = v => ({
    label: `${v.supplier_name}`.trim(),
    value: v.supplier_id
});

export const supplierOptions = createSelector(
    getSuppliersList,
    s => s.map(supplierOptionMapFunc)
);
