import { useRef, } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const useDraggableItem = ({
  type,
  index,
  onMove,
  onDrop,
  item,
  id,
  endDrag,
  canDrag = true,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      onDrop(item, monitor);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type,
    canDrag: canDrag,
    item: () => ({ id, index, item }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (draggedItem, monitor) => {
      if (endDrag && typeof endDrag === 'function') {
        return endDrag(draggedItem, monitor);
      }
    },
  });

  return {
    ref,
    handlerId,
    isDragging,
    drag,
    drop,
  };
};

export default useDraggableItem;
