import { createSelectVariantPopup } from './popup';
import { createAddTemp } from './temp';

import { oauth } from '../utils';

export const FETCH_PRODUCT_DETAIL_REQUEST = 'FETCH_PRODUCT_DETAIL_REQUEST';
export const FETCH_PRODUCT_DETAIL_SUCCESS = 'FETCH_PRODUCT_DETAIL_SUCCESS';
export const FETCH_PRODUCT_DETAIL_FAILURE = 'FETCH_PRODUCT_DETAIL_FAILURE';

const createFetchProductDetailsRequest = (product_id, source) => ({
  type: FETCH_PRODUCT_DETAIL_REQUEST,
  payload: {
    product_id,
    source
  }
});

const createFailedFetchProductDetails = (product_id, source) => ({
  type: FETCH_PRODUCT_DETAIL_FAILURE,
  payload: {
    product_id,
    source
  }
});

const createFinalizeFetchProductDetails = (product_id, source, product) => ({
  type: FETCH_PRODUCT_DETAIL_SUCCESS,
  payload: {
    product_id,
    source,
    product
  }
});

export const createFetchProductDetails = (product_id, source, destination) => dispatch => {
  dispatch(createFetchProductDetailsRequest(product_id, source));

  return oauth('GET', `product/${product_id}`, { source }).then(
    ({ json }) => {
      const product = json.product;
      if (product.variants) {
        dispatch(createSelectVariantPopup(product.variants, destination, 'PRODUCT', source));
      } else {
        dispatch(createAddTemp(`product.${product.product_id}`, product));
      }
      dispatch(createFinalizeFetchProductDetails(product_id, source, product));
    }, ({ json }) => dispatch(createFailedFetchProductDetails(product_id, source))
  );
};

export const createFetchProduct = (product_id, data = {}) => dispatch => {
  const { source = null } = data || {};
  dispatch(createFetchProductDetailsRequest(product_id, source));
  return oauth('GET', `product/${product_id}`, data).then(
    ({ json }) => dispatch(createFinalizeFetchProductDetails(product_id, source, json.product)),
    ({ json }) => dispatch(createFailedFetchProductDetails(product_id, source))
  );
};

