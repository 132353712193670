import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  createAddItem, createUpdate, createDeleteItem, createCopyItem, createDeleteItemImage
} from '../actions';
import * as actions from '../actions/item';
import {
  createEditServicePopup, createEditArtworkPOPopup,
  createValidateConfirmationPopup, createCopyItemPopup,
  createUploadProductImagePopup, createEditInventoryBreakdownQuantityPopup
} from '../actions/popup';

import ProductItemSummary from './ProductItemSummary';
import ProductArtworkSummary from './ProductArtworkSummary';
import ProductCommentSummary from './ProductCommentSummary';
import ProductItemDetailedSummary from './ProductItemDetailedSummary';
import TitleItemSummary from './TitleItemSummary';
import ServiceItemSummary from './ServiceItemSummary';
import OptionItemSummary from './OptionItemSummary';
import OptionItemDetailedSummary from './OptionItemDetailedSummary';
import CollectionItemSummary from './CollectionItemSummary';
import ArtworkPOItemSummary from './ArtworkPOItemSummary';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';

import { getMostRecentUnlockedOrder } from '../selectors';
import { withRouter } from './helpers';
import { getOrderItems } from '../utils/order';
import { AddIcon, ConnectedPlusIcon, IconButton } from '@commonsku/styles';
import { getIdentityUtils } from '../utils';
import ProductSearchCallout from './ProductSearchCallout';
import { ItemListTableHeader, ItemListTHDescription } from './ItemListTableHeader';

const BASE_ADD_INDEX = 'BASE_ADD_INDEX';
const SUMMARY_CLASS_MAP = {
  'artwork': {
    'PRODUCT': ProductArtworkSummary,
    'OPTION': ProductArtworkSummary
  },
  'comment': {
    'OPTION': ProductCommentSummary
  },
  'compact': {
    'PRODUCT': ProductItemSummary,
    'SERVICE': ServiceItemSummary,
    'SEPARATOR': TitleItemSummary,
    'OPTION': OptionItemSummary,
    'ARTWORK_PO': ArtworkPOItemSummary
  },
  'grid': {
    'PRODUCT': ProductItemSummary,
    'SERVICE': ServiceItemSummary,
    'SEPARATOR': TitleItemSummary,
    'OPTION': OptionItemSummary,
    'ARTWORK_PO': ArtworkPOItemSummary
  },
  'detailed': {
    'PRODUCT': ProductItemDetailedSummary,
    'SERVICE': ServiceItemSummary,
    'SEPARATOR': TitleItemSummary,
    'OPTION': OptionItemDetailedSummary,
    'ARTWORK_PO': ArtworkPOItemSummary
  },
  'collection-compact': {
    'OPTION': OptionItemSummary,
    'SEPARATOR': TitleItemSummary
  },
  'collection-detailed': {
    'OPTION': CollectionItemSummary,
    'SEPARATOR': TitleItemSummary
  },
  'collection-grid': {
    'OPTION': OptionItemSummary,
    'SEPARATOR': TitleItemSummary
  },
};

class ItemList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: this.getStateItemsfromProps(props),
      dragItem: null,
      hoverIndex: -1
    };
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.onMoveItem = this.onMoveItem.bind(this);
    this.onDropItem = this.onDropItem.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
    this.onCopyItem = this.onCopyItem.bind(this);
    this.onAddProductItem = this.onAddProductItem.bind(this);
    this.hasItem = this.hasItem.bind(this);
  }

  componentDidMount() {
    window.addEventListener('dragend', this.onDragEnd);
    this.onAddItem(BASE_ADD_INDEX);
  }

  componentWillUnmount() {
    window.removeEventListener('dragend', this.onDragEnd);
  }

  onEditItem(item_id) {
    const { dispatch, navigate } = this.props;
    const item = this.props.items.filter(i => i.item_id === item_id)[0] || {};
    if (item.hasInventoryBreakdowns) {
      dispatch(createEditInventoryBreakdownQuantityPopup(item, this.props.locked));
    } else if (this.props.is_aggregate_so && !this.props.locked) {
      dispatch(createValidateConfirmationPopup(() => {
        return navigate(this.props.location.pathname.replace(/\/+$/, '') + '/' + item_id);
      }, [], 'Are You Sure?', 'If you edit the quantity the count will not match the orders placed on the store. To increase the quantity, it is best to place an order through the store.'));
    } else {
      navigate(this.props.location.pathname.replace(/\/+$/, '') + '/' + item_id);
    }
  }

  onCopyItem(item_id, index, target_order, quantity, job_number) {
    const { dispatch, navigate } = this.props;
    if (target_order) {
      dispatch(createCopyItem(item_id, index, target_order.order_id, quantity));
      if (target_order.order_type === 'SALES ORDER') {
        navigate('/project/' + job_number + '/sales-order/' + target_order.form_number);
      } else {
        navigate('/project/' + job_number + '/' + target_order.order_type.toLowerCase() + '/' + target_order.form_number);
      }
    } else {
      dispatch(createCopyItem(item_id, index));
    }
  }

  onAddProductItem(index, suggest = false) {
    const { dispatch, navigate } = this.props;
    const query = index ? `?index=${index}` : null;
    if (suggest) {
      navigate(`${this.props.location.pathname.replace(/\/+$/, '')}/add-product-suggestion`);
    } else if (Number.isInteger(parseInt(index, 10))) {
      navigate(`${this.props.location.pathname.replace(/\/+$/, '')}/add-product?index=${index}`);
    } else {
      navigate(`${this.props.location.pathname.replace(/\/+$/, '')}/add-product`);
    }
  }

  onDragEnd() {
    if (this.state.dragItem !== null && this.state.hoverIndex != -1) {
      let drag_obj = {
        id: this.state.dragItem,
        index: this.state.hoverIndex
      };
      this.onDropItem(drag_obj);
    }
  }

  onAddItem(index) {
    this.setState({
      dropdownOptions: this.getHeaderOptions(index),
    });
  }

  handleContextMenu(index) {
    return e => {
      e.preventDefault();
      this.setState({
        dropdownOptions: this.getHeaderOptions(index, true),
      });
      this.dropdown.showDropdown(true, {
        top: e.clientY,
        left: e.clientX,
      });
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // temp hack to prevent redundant re-render, should achieve this by
    // clean unnecessary new object/function/array in props
    return nextState !== this.state || !(
      nextProps === this.props || _.every(nextProps, (v, k) => {
        return _.isEqual(this.props[k], v);
      })
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const items = this.getStateItemsfromProps(nextProps);
    if (!_.isEqual(items, this.state.items)) {
      this.setState({
        items,
      });
    }
  }

  getStateItemsfromProps({ items, order_type }) {
    this.filteredItems = getOrderItems({ items, order_type });
    return _.map(this.filteredItems, (i) => i.item_id);
  }

  getHeaderOptions(index, is_context_menu = false) {
    const { order_id, order_type, services, onAddTitleItem, onAddServiceItem, onAddArtworkPO, productSmartEnabled } = this.props;
    const idx = BASE_ADD_INDEX !== index ? (index + (is_context_menu ? 0 : 1)) : null;
    const baseHandler = e => {
      this.dropdown.showDropdown(false);
    };
    let result = [];
    if (is_context_menu) {
      const item_id = this.state.items[index];
      result = [
        { key: 'COPY', value: 'Copy', onClick: e => { baseHandler(e); this.onCopyItem(item_id, idx + 1); } }
      ];
    }
    if (order_type === 'SALES ORDER') {
      return result.concat([
        { key: 'PRODUCT', value: 'Product From Database', onClick: e => { baseHandler(e); this.onAddProductItem(idx); } },
        productSmartEnabled && { key: 'PRODUCT-SMART', value: 'AI Recommendations', onClick: e => { baseHandler(e); this.onAddProductItem(idx, true); } },
        { key: 'SEPARATOR', value: 'Title', onClick: e => { baseHandler(e); onAddTitleItem(order_id, idx); } },
        ...services.map(s => Object.assign({}, s, { onClick: e => { baseHandler(e); onAddServiceItem(order_id, s.key, idx); } })),
        { key: 'ARTWORK_PO', value: 'Service PO', onClick: e => { baseHandler(e); onAddArtworkPO(order_id, idx); } },
        { key: 'SERVICE', value: 'Other Service', onClick: e => { baseHandler(e); onAddServiceItem(order_id, null, idx); } }
      ].filter(Boolean));
    } else if (order_type === 'PRESENTATION') {
      return result.concat([
        { key: 'PRODUCT', value: 'Product From Database', onClick: e => { baseHandler(e); this.onAddProductItem(idx); } },
        productSmartEnabled && { key: 'PRODUCT-SMART', value: 'AI Recommendations', onClick: e => { baseHandler(e); this.onAddProductItem(idx, true); } },
        { key: 'SEPARATOR', value: 'Title', onClick: e => { baseHandler(e); onAddTitleItem(order_id, idx); } },
      ].filter(Boolean));
    } else if (order_type === 'COLLECTION') {
      return result.concat([
        { key: 'PRODUCT', value: 'Product', onClick: e => { baseHandler(e); this.onAddProductItem(idx); } },
        { key: 'SEPARATOR', value: 'Category', onClick: e => { baseHandler(e); onAddTitleItem(order_id, idx); } },
      ]);
    } else if (order_type === 'SHOP') {
      return result.concat([
        { key: 'PRODUCT', value: 'Product From Database', onClick: e => { baseHandler(e); this.onAddProductItem(idx); } },
        productSmartEnabled && { key: 'PRODUCT-SMART', value: 'AI Recommendations', onClick: e => { baseHandler(e); this.onAddProductItem(idx, true); } },
        { key: 'SEPARATOR', value: 'Title', onClick: e => { baseHandler(e); onAddTitleItem(order_id, idx); } },
      ].filter(Boolean));
    } else {
      return result.concat([
        { key: 'PRODUCT', value: 'Product From Database', onClick: e => { baseHandler(e); this.onAddProductItem(idx); } },
        productSmartEnabled && { key: 'PRODUCT-SMART', value: 'AI Recommendations', onClick: e => { baseHandler(e); this.onAddProductItem(idx, true); } },
        { key: 'SEPARATOR', value: 'Title', onClick: e => { baseHandler(e); onAddTitleItem(order_id, idx); } },
        ...services.map(s => Object.assign({}, s, { onClick: e => { baseHandler(e); onAddServiceItem(order_id, s.key, idx); } })),
        { key: 'SERVICE', value: 'Other Service', onClick: e => { baseHandler(e); onAddServiceItem(order_id, null, idx); } }
      ].filter(Boolean));
    }
  }

  hasItem() {
    return 0 < this.state.items.length;
  }

  renderHeader(display) {
    if ('PRESENTATION' === this.props.order_type || 'SHOP' === this.props.order_type) {
      switch (display) {
        case 'compact':
          return this.renderCompactPresentationHeader();
        case 'grid':
          return this.renderGridPresentationHeader();
        case 'detailed':
          return this.renderDetailedPresentationHeader();
        case 'artwork':
          return this.renderArtworkHeader();
        case 'comment':
          return this.renderEmptyHeader();
      }
    }
    switch (display) {
      case 'compact':
        return this.renderCompactHeader();
      case 'detailed':
        return this.renderDetailedHeader();
      case 'artwork':
        return this.renderArtworkHeader();
      case 'collection-compact':
        return this.renderCompactPresentationHeader();
      case 'collection-detailed':
        return this.renderCollectionHeader();
    }
  }

  renderAddItem(showTrigger = true) {
    const {
      dropdownOptions,
    } = this.state;
    const {
      identity,
    } = this.props;
    const isTenant = 'TENANT' === identity.company_type;

    let product = null;
    let productSmart = null;
    let separator = null;
    const rest = [];

    if (isTenant) {
      _.forEach(dropdownOptions, item => {
        if ('PRODUCT' === item.key) {
          product = item;
        } else if ('PRODUCT-SMART' === item.key) {
          productSmart = item;
        } else if ('SEPARATOR' === item.key) {
          separator = item;
        } else if ('COPY' !== item.key) {
          rest.push(item);
        }
      });
    }

    return <div className="dropdown-holder" style={{ 'marginLeft': '0px' }}>
      {
        product && <IconButton Icon={AddIcon} onClick={product.onClick}>{product.value}</IconButton>
      }
      {
        productSmart && <IconButton variant='text' style={{ borderColor: 'transparent', background: 'transparent' }} Icon={ConnectedPlusIcon} onClick={productSmart.onClick}>{productSmart.value}</IconButton>
      }
      {
        separator && <IconButton variant='text' style={{ borderColor: 'transparent', background: 'transparent' }} Icon={AddIcon} onClick={separator.onClick}>{separator.value}</IconButton>
      }
      {
        !_.isEmpty(rest) && <DropdownMenu
          options={rest} >
          {showTrigger && !this.props.locked ?
            <MenuTrigger>
              <IconButton variant='text' style={{ borderColor: 'transparent', background: 'transparent' }} Icon={AddIcon} >Service</IconButton>
            </MenuTrigger> : null}
        </DropdownMenu>
      }
      {
        !_.isEmpty(dropdownOptions) && <DropdownMenu
          ref={(dropdown) => { this.dropdown = dropdown; }}
          onDropdownHide={() => this.onAddItem(BASE_ADD_INDEX)}
          options={dropdownOptions} >
          {!isTenant && showTrigger && !this.props.locked ?
            <MenuTrigger>
              <a className="button small"
                style={{ marginBottom: '5px' }}
                onClick={(e) => {
                  e.preventDefault();
                  this.onAddItem(BASE_ADD_INDEX);
                }}>
                <i className="fi-plus" />&nbsp;&nbsp;Add
              </a>
            </MenuTrigger> : null}
        </DropdownMenu>
      }
    </div>;
  }

  renderEmptyHeader() {
    return (
      <thead style={{ height: 0 }}>
        <tr>
          <th>{this.renderAddItem(false)}</th>
        </tr>
      </thead>
    );
  }

  renderCompactPresentationHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="description">{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <th className="breakdown-count"># of Breakdowns</th>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  renderGridPresentationHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="description">{this.renderAddItem()}</ItemListTHDescription>
        </tr>
      </ItemListTableHeader>
    );
  }

  renderDetailedPresentationHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="description">{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <>
              <th className="title"></th>
              <th className="quantity">Units</th>
              <th className="margin">Margin</th>
              <th className="amount">Amount</th>
            </>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  renderCollectionHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="description">{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <>
              <th className="title"></th>
              <th className="quantity">Units</th>
              <th className="amount">Amount</th>
            </>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  renderCompactHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="description">{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <>
              <th className="quantity">Units</th>
              <th className="margin">Margin</th>
              <th className="tax">Tax</th>
              <th className="amount">Amount</th>
            </>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  renderDetailedHeader() {
    return (
      <ItemListTableHeader hasItem={this.hasItem()} className="item-summary detailed">
        <tr>
          <ItemListTHDescription hasItem={this.hasItem()} className="descripton">{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <>
              <th className="title">Item</th>
              <th className="quantity">Units</th>
              <th className="amount">Price</th>
              <th className="margin">Margin</th>
              <th className="tax">Tax</th>
              <th className="amount">Amount</th>
            </>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  renderArtworkHeader() {
    return (
      <ItemListTableHeader isArtwork={true} hasItem={this.hasItem()} className="item-summary">
        <tr>
          <ItemListTHDescription isArtwork={true} hasItem={this.hasItem()}>{this.renderAddItem()}</ItemListTHDescription>
          {
            this.hasItem() && <th style={{ textAlign: 'left' }}>Artwork</th>
          }
        </tr>
      </ItemListTableHeader>
    );
  }

  onMoveItem(dragIndex, hoverIndex) {
    const { items } = this.state;
    const dragItem = items[dragIndex];
    const item_copy = items.slice();
    item_copy.splice(dragIndex, 1);
    item_copy.splice(hoverIndex, 0, dragItem);
    this.setState({
      hoverIndex,
      items: item_copy,
      dragItem: dragItem
    });
  }

  onDropItem(item) {
    const origIndex = _.findIndex(this.filteredItems, { item_id: item.id });
    if (_.isInteger(origIndex) && _.isInteger(item.index) && origIndex != item.index) {
      const origItem = this.filteredItems[origIndex];
      this.props.onReorderItem(item.id, origItem.display_order)(item.index);
    }
    this.setState({ hoverIndex: -1, dragItem: null });
  }

  getLastItemIndexForTitle(titleIndex, allItems) {
    const laterItems = allItems.slice(titleIndex + 1).filter(i => typeof i !== 'undefined').filter(i => i.parent_type === 'SEPARATOR');
    const lastItem = laterItems.length > 0 ? laterItems[0] : allItems[allItems.length - 1];
    let lastItemIndex = null;
    let offset = laterItems.length > 0 ? 1 : 0;
    for (let index = 0; index < allItems.length; index++) {
      if (allItems[index].item_id === lastItem.item_id) {
        lastItemIndex = index - offset;
        break;
      }
    }
    return lastItemIndex;
  }

  render() {
    const {
      order_type,
      items,
      onUpdateItem,
      onDeleteItem,
      onUpdateItemThumb,
      onDeleteItemThumb,
      display,
      hidden_filter_status,
      onCreateEditServicePopup,
      onCreateEditArtworkPOPopup,
      onCreateCopyItemPopup,
      locked,
      identity,
      add_to_order,
      job_number,
      productSmartEnabled,
    } = this.props;
    const item_ids = this.state.items;
    const hoverIndex = this.state.hoverIndex;
    const visible_items = item_ids.map(item_id => items.filter(o => o.item_id === item_id)[0]);

    const hasCapability = capability => identity.capabilities.includes(capability);
    const base_item_props = {
      onMove: this.onMoveItem,
      onDrop: this.onDropItem,
      onAddItem: this.onAddItem,
      onEditItem: this.onEditItem,
      onDeleteItem,
      onCopyItem: this.onCopyItem,
      onUpdateItem,
      hasCapability,
      order_type,
      locked,
      detailed: 'detailed' === display || 'collection-detailed' === display,
      display,
      add_to_order,
      job_number,
      canDrag: !(locked || ['artwork', 'comment'].includes(display)),
    };

    const class_map = SUMMARY_CLASS_MAP[display];

    const content = (
      <>
        {this.renderHeader(display)}
        {visible_items.length ? visible_items.map((i, idx) => {
          if (i.hidden == 1 && 'active' === hidden_filter_status) {
            return null;
          }
          const item_props = Object.assign({
            className: (idx === hoverIndex ? 'hover' : '') +
              (hoverIndex !== -1 ? ' dragging' : '') +
              ('SEPARATOR' === i.parent_type ? ' title-item' : ''),
            id: i.item_id,
            index: idx,
            key: i.item_id,
            item: i,
            display: display,
            isTitle: 'SEPARATOR' === i.parent_type,
            lastItemIndex: 'SEPARATOR' !== i.parent_type ? null : this.getLastItemIndexForTitle(idx, visible_items),
            handleContextMenu: this.handleContextMenu(idx)
          }, base_item_props);
          const extra_props =
            'SERVICE' === i.parent_type ?
              { onEditItem: onCreateEditServicePopup } :
              'SEPARATOR' === i.parent_type ?
                { onEditItem: null, dropdownOptions: this.getHeaderOptions(idx), onUpdateItemThumb, onDeleteItemThumb, } :
                'ARTWORK_PO' === i.parent_type ?
                  { onEditItem: onCreateEditArtworkPOPopup } :
                  'OPTION' === i.parent_type ?
                    { onCopyOptionItem: onCreateCopyItemPopup } :
                    {};

          const ItemSummaryComponent = class_map[i.parent_type];
          if (ItemSummaryComponent) {
            return <ItemSummaryComponent {...item_props} {...extra_props} />;
          }
        }) : <ProductSearchCallout productSmartEnabled={productSmartEnabled} />}
      </>
    );
    return (
      'grid' !== display ? (
        <table className={'products-table full-breakdowns'}>
          {content}
        </table>) : (
        <div className={`products-table display-${display}`}>
          {content}
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const add_to_order = state.entities.projects && Object.values(state.entities.projects).length > 0 ? getMostRecentUnlockedOrder(state, { project_id: Object.values(state.entities.projects)[0].job_id }) : null;
  const job_number = state.entities.projects && Object.values(state.entities.projects).length > 0 ? Object.values(state.entities.projects)[0].job_number : null;
  const { hasUserFlags } = getIdentityUtils(state.identity);
  const productSmartEnabled = hasUserFlags('PRODUCT-SMART-ENABLED');
  return {
    identity: state.identity,
    add_to_order,
    job_number,
    productSmartEnabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  const reorderItem = createUpdate('item', {
    request: actions.REORDER_ITEM_REQUEST,
    success: actions.REORDER_ITEM_SUCCESS,
    failure: actions.REORDER_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });

  return {
    dispatch,
    onUpdateItem: (item_id, field, previous_value, field_lock = 'unit_cost') => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }));
    },
    onUpdateItemThumb: (item_id) => {
      dispatch(createUploadProductImagePopup(item_id));
    },
    onDeleteItemThumb: (itemImage) => {
      dispatch(createDeleteItemImage(itemImage.item_image_id));
    },
    onDeleteItem: item_id => {
      if (ownProps.is_aggregate_so && !ownProps.locked) {
        dispatch(createValidateConfirmationPopup(() => {
          return dispatch(createDeleteItem(item_id));
        }, [], 'You Sure?', 'Sales order items created from checkouts should not be deleted.'));
      } else {
        dispatch(createValidateConfirmationPopup(() => {
          return dispatch(createDeleteItem(item_id));
        }, []));
      }
    },
    onAddTitleItem: (order_id, index, shop_id = null) => {
      const kwargs = {};
      if (shop_id) {
        kwargs['with_shop_id'] = shop_id;
      }
      dispatch(createAddItem(
        order_id, 'SEPARATOR', undefined, undefined, undefined, index,
        null, null, null, null, null, 'all', '0', kwargs));
    },
    onAddServiceItem: (order_id, service_id, index) => {
      dispatch(createAddItem(order_id, 'SERVICE', service_id, undefined, undefined, index));
    },
    onReorderItem: (item_id, previous_value) => value => {
      dispatch(reorderItem(item_id, 'display_order', previous_value, value));
    },
    onCreateEditServicePopup: (item_id) => {
      dispatch(createEditServicePopup(item_id));
    },
    onAddArtworkPO: (order_id, index) => {
      dispatch(createAddItem(
        order_id, 'ARTWORK_PO', undefined, undefined, undefined, index));
    },
    onCreateEditArtworkPOPopup: (item_id, parent_id) => {
      dispatch(createEditArtworkPOPopup(item_id, parent_id));
    },
    onCreateCopyItemPopup: (item, add_to_order) => {
      dispatch(createCopyItemPopup(item, add_to_order));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemList));

