import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCompanyAvatar } from '../actions';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onCompanyAvatarChange = this.onCompanyAvatarChange.bind(this);
  }

  componentDidMount() {
    const {
      handleCompanyAvatarChange,
    } = this.props;

    window.addEventListener('company-avatar-update', this.onCompanyAvatarChange);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'company-avatar-update', this.onCompanyAvatarChange);
  }

  onCompanyAvatarChange(e) {
    this.props.handleCompanyAvatarChange(e.detail);
  }

  render() {
    const {
      identity,
      style={},
    } = this.props;

    const companyAvatar = _.get(identity, 'company_avatar.medium');
    return (
      <div className="about-project" style={style}>
	<a href={identity.company_type === 'TENANT' ? `/distributor.php?id=${identity.tenant_id}` : `/vendor.php?id=${identity.company_id}`}>
          {companyAvatar ?
            <img className="company-avatar" src={companyAvatar.startsWith('http') ? companyAvatar : '/' + companyAvatar} /> :
            <img className="company-avatar" src="/images/favicons/favicon-96x96-company-avatar.png" />
          }
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
  };
};

const mapDispatchToProps = dispatch => ({
  handleCompanyAvatarChange: (avatar) => {
    dispatch(updateCompanyAvatar(avatar));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(About);

