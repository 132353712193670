import React from "react";
import { Theme } from '@commonsku/styles';
import DropdownMenu, { MenuTrigger } from '../DropdownMenu';

const BasicLocationDropdownMenu = ({ options, children, ...props }) => {
  return (
    <Theme>
      <DropdownMenu {...props} options={options}>
        <MenuTrigger>{children}</MenuTrigger>
      </DropdownMenu>
    </Theme>
  );
};

export default BasicLocationDropdownMenu;
