export default class ProofStatus {
  static NoProofRequired = '238d151a-e1aa-11e7-a19e-572fe4eaead6';
  static ProofRequired = 'c28cb530-e1aa-11e7-a19e-572fe4eaead6';
  static ProofReceived = '3a4aa628-f0c8-11e7-a19e-572fe4eaead6';
  static PendingApproval = '4adea610-f0c8-11e7-a19e-572fe4eaead6';
  static ChangeRequested = '5499bb40-f0c8-11e7-a19e-572fe4eaead6';
  static Approved = '6ca49796-f0c8-11e7-a19e-572fe4eaead6';
  static ProofingComplete = '217d1250-1816-11e8-abd1-df5c2ed39984';

  // valid po status: [
  //  NoProofRequired, ProofRequired, ProofReceived,
  //  PendingApproval, ChangeRequested, Approved
  // ]

  // valid proof status: [
  //  ProofReceived, PendingApproval, ChangeRequested, Approved
  // ]
}
