import { oauth, } from '../utils';
import { createUploadFile } from './file';
import { finalizeAddProofComment } from './comment';

export const UPLOAD_PO_PROOF_REQUEST = 'UPLOAD_PO_PROOF_REQUEST';
export const UPLOAD_PO_PROOF_SUCCESS = 'UPLOAD_PO_PROOF_SUCCESS';
export const UPLOAD_PO_PROOF_FAILURE = 'UPLOAD_PO_PROOF_FAILURE';

export const APPROVE_PO_PROOF_REQUEST = 'APPROVE_PO_PROOF_REQUEST';
export const APPROVE_PO_PROOF_FAILURE = 'APPROVE_PO_PROOF_FAILURE';
export const REPLACE_PO_PROOF_REQUEST = 'REPLACE_PO_PROOF_REQUEST';
export const REPLACE_PO_PROOF_FAILURE = 'REPLACE_PO_PROOF_FAILURE';
export const UPDATE_PO_PROOF_SUCCESS = 'UPDATE_PO_PROOF_SUCCESS';

export const DELETE_PO_PROOF_REQUEST = 'DELETE_PO_PROOF_REQUEST';
export const DELETE_PO_PROOF_SUCCESS = 'DELETE_PO_PROOF_SUCCESS';
export const DELETE_PO_PROOF_FAILURE = 'DELETE_PO_PROOF_FAILURE';

export const FETCH_PO_PROOF_SUCCESS = 'FETCH_PO_PROOF_SUCCESS';

export const uploadProof = (purchase_order_id, files) => dispatch => {
  return dispatch(createUploadFile(purchase_order_id, 'PURCHASE-ORDER', files[0])).then(({ payload: { data} }) => {
    dispatch(requestUploadProof(purchase_order_id, files));
    return oauth('POST', 'purchase-order-proof', {
      purchase_order_id, file_id: data.file_id
    })
      .then(({ json }) => {
        return dispatch(finalizeUploadProof({
          ...(json.purchase_order_proof || {}),
          ...data,
        }, json.purchase_order));
      })
      .catch((err) => {
        return dispatch(failedUploadProof(err));
      })
    ;
  });
};

const finalizeFetchProof = (purchase_order_proofs, purchase_order_id) => ({
  type: FETCH_PO_PROOF_SUCCESS,
  payload: {
    purchase_order_proofs,
    purchase_order_id,
  }
});

export const fetchProofs = (purchase_order_id) => dispatch => {
  return oauth('GET', 'purchase-order-proof', {
    purchase_order_id
  })
    .then(({ json }) => {
      return dispatch(finalizeFetchProof({
        ...(json.purchase_order_proofs || {}),
      }, purchase_order_id));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addProof = (purchase_order_id, file) => dispatch => {
    return oauth('POST', 'purchase-order-proof', {
      purchase_order_id, file_id: file.file_id
    })
      .then(({ json }) => {
        return dispatch(finalizeUploadProof({
          ...(json.purchase_order_proof || {}),
          ...file,
        }, json.purchase_order));
      })
      .catch((err) => {
        return dispatch(failedUploadProof(err));
      });
};

const requestUploadProof = (purchase_order_id, files) => ({
  type: UPLOAD_PO_PROOF_REQUEST,
  payload: {
    purchase_order_id, files,
  }
});

const finalizeUploadProof = (purchase_order_proof, purchase_order) => ({
  type: UPLOAD_PO_PROOF_SUCCESS,
  payload: {
    purchase_order_proof,
    purchase_order,
  }
});

const failedUploadProof = (errors, purchase_order_proof) => ({
  type: UPLOAD_PO_PROOF_FAILURE,
  payload: {
    errors,
    purchase_order_proof,
  }
});

export const updateProofStatus = (proof, status_id, options) => dispatch => {
  const {
    isPublic,
    commenter_name,
    commenter_email,
    text,
  } = options || {};

  dispatch(requestApproveProof(proof, status_id));
  const data = { status_id, commenter_name, commenter_email, text };
  if (isPublic) {
    data.public = true;
  }
  return oauth('PUT', `purchase-order-proof/${proof.purchase_order_proof_id}`, data)
    .then(({ json }) => {
      const proof = json.purchase_order_proof || {};
      const comment = proof.comments[proof.comment_id];
      if (comment) {
        dispatch(finalizeAddProofComment(comment));
      }
      return dispatch(finalizeUpdateProof(proof, json.purchase_order));
    })
    .catch((err) => {
      console.log(err);
      return dispatch(failedApproveProof(err, proof, status_id));
    })
  ;
};

const requestApproveProof = (purchase_order_proof, status_id, comment_id) => ({
  type: APPROVE_PO_PROOF_REQUEST,
  payload: { purchase_order_proof, status_id, comment_id }
});

const finalizeUpdateProof = (purchase_order_proof, purchase_order) => {
  return {
    type: UPDATE_PO_PROOF_SUCCESS,
    payload: { purchase_order_proof, purchase_order }
  };
};

const failedApproveProof = (errors, purchase_order_proof, status_id, comment_id) => ({
  type: APPROVE_PO_PROOF_FAILURE,
  payload: {
    errors,
    purchase_order_proof, status_id, comment_id
  }
});

const requestReplaceProof = (purchase_order_proof, files) => ({
  type: REPLACE_PO_PROOF_REQUEST,
  payload: { purchase_order_proof, files}
});

const failedReplaceProof = (errors, purchase_order_proof, files) => ({
  type: REPLACE_PO_PROOF_FAILURE,
  payload: {
    errors,
    purchase_order_proof, files
  }
});

export const replaceProof = (purchase_order_proof, files) => dispatch => {
  const { purchase_order_id, purchase_order_proof_id }= purchase_order_proof;

  dispatch(requestReplaceProof(purchase_order_proof, files));
  return dispatch(createUploadFile(purchase_order_id, 'PURCHASE-ORDER', files[0]))
    .then(({ payload: { data } }) => {
      return oauth('PUT', `purchase-order-proof/${purchase_order_proof_id}`, {
        file_id: data.file_id,
      })
        .then(({ json }) => {
          const proof = json.purchase_order_proof || {};
          return dispatch(finalizeUpdateProof({
            ...proof,
            ...(proof.file || {}),
            ...data,
          }, json.purchase_order));
        })
        .catch((err) => {
          return dispatch(failedReplaceProof(err, purchase_order_proof, files));
        })
      ;
    });
};

const requestDeleteProof = (purchase_order_proof) => ({
  type: DELETE_PO_PROOF_REQUEST,
  payload: { purchase_order_proof, }
});

const finalizeDeleteProof = (purchase_order_proof, purchase_order) => ({
  type: DELETE_PO_PROOF_SUCCESS,
  payload: {
    purchase_order_proof,
    purchase_order
  }
});

const failedDeleteProof = (errors, purchase_order_proof) => ({
  type: DELETE_PO_PROOF_FAILURE,
  payload: {
    errors,
    purchase_order_proof,
  }
});

export const deletePurchaseOrderProof = (purchase_order_proof) => dispatch => {
  dispatch(requestDeleteProof(purchase_order_proof));

  oauth('DELETE', `purchase-order-proof/${purchase_order_proof.purchase_order_proof_id}`, {}).then(
    ({ json }) => dispatch(finalizeDeleteProof(purchase_order_proof, json.purchase_order)),
    ({ json }) => dispatch(failedDeleteProof(purchase_order_proof, json.error))
  );
};
