import React, { useState } from 'react';
import { filter, find, includes, isEmpty, map, orderBy, toLower } from 'lodash';
import { Button, LabeledIconInput, Popup, PromostandardsIcon, SearchIcon , ConnectedPlusIcon} from '@commonsku/styles';

const SearchLocationPopup = ({ title, onClose, locations, toOption }) => {
  const [search, setSearch] = useState('');
  locations = orderBy(locations, 'location_rank');

  return (
    <Popup
      onClose={onClose}
      title={title || 'Add Decoration Location'}
      controls={<Button onClick={onClose}>Cancel</Button>}
    >
      <div style={{ position: 'sticky', top: 5 }}>
        <LabeledIconInput
          Icon={<SearchIcon />}
          placeholder="Search for a location"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {map(
        isEmpty(search)
          ? locations
          : filter(locations, ({ ps_location_id, location_name }) => {
              const parts = toLower(search).split();
              return find(map([location_name, ps_location_id], toLower), (target) => {
                return find(parts, (part) => {
                  return includes(target, part);
                });
              });
            }),
        (location) => {
          const option = toOption(location);
          return isEmpty(option) ? null : (
            <div
              key={option.key}
              style={{ color: '#5CA3B6', cursor: 'pointer' }}
              onClick={() => {
                option.onClick();
                onClose();
              }}
            >
              <ConnectedPlusIcon size="huge" />
              <span style={{ lineHeight: '48px', verticalAlign: 'top', fontSize: '1.2rem' }}>
                {location.location_name}
              </span>
            </div>
          );
        },
      )}
    </Popup>
  );
};

export default SearchLocationPopup;
