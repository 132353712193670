import { oauth } from '../utils';
import { createUpdateFeedbackEmails } from './display';

export const ADD_EMAIL_REQUEST = 'ADD_EMAIL_REQUEST';
export const ADD_EMAIL_SUCCESS = 'ADD_EMAIL_SUCCESS';
export const ADD_EMAIL_FAILURE = 'ADD_EMAIL_FAILURE';

export const ADD_EMAIL_TEMPLATE_REQUEST = 'ADD_EMAIL_TEMPLATE_REQUEST';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE';

const createAddEmailRequest = (details_parent_id, details_parent_type, message_text) => ({
  type: ADD_EMAIL_REQUEST,
  payload: {
    details_parent_id,
    details_parent_type,
    message_text
  }
});

export const createFinalizeAddEmail = (details_parent_id, details_parent_type, message_text) => ({
  type: ADD_EMAIL_SUCCESS,
  payload: {
    details_parent_id,
    details_parent_type,
    message_text
  }
});

const  createFailedAddEmail = (error) => ({
  type: ADD_EMAIL_FAILURE,
  payload: {
    error
  }
});

export const createAddEmail = (
  details_parent_id,
  details_parent_type,
  from_contact_id,
  to_contact_id,
  to_extra,
  cc_contact_id,
  cc_extra,
  bcc_contact_id,
  bcc_extra,
  subject,
  message_text,
  request_receipt = false,
  attachment_file_ids = []
) => dispatch => {
  dispatch(createAddEmailRequest(details_parent_id, details_parent_type, message_text));
  const data = {
    details_parent_id,
    details_parent_type,
    from_contact_id,
    to_contact_id,
    to_extra: to_extra.join(','),
    cc_contact_id,
    cc_extra: cc_extra.join(','),
    bcc_contact_id,
    bcc_extra: bcc_extra.join(','),
    subject,
    message_text,
    request_receipt,
    attachment_file_ids: attachment_file_ids.join(',')
  };
  return oauth('POST', 'email', data).then(
    ({ json }) => {
      if ('FEEDBACK' === details_parent_type.toUpperCase()) {
        dispatch(createUpdateFeedbackEmails(to_contact_id, to_extra));
      }
      return dispatch(createFinalizeAddEmail(details_parent_id, details_parent_type, message_text));
    },
    ({ json }) => dispatch(createFailedAddEmail(json.error))
  );
};

const createAddEmailTemplateRequest = email_template => ({
  type: ADD_EMAIL_TEMPLATE_REQUEST,
  payload: {
    data: email_template
  }
});

const createFinalizeAddEmailTemplate = email_template => ({
  type: ADD_EMAIL_TEMPLATE_SUCCESS,
  payload: {
    id: email_template.email_template_id,
    data: email_template
  }
});

const createFailedAddEmailTemplate = email_template => ({
  type: ADD_EMAIL_TEMPLATE_FAILURE,
  payload: {
    data: email_template
  }
});

export const createAddEmailTemplate = email_template => dispatch => {
  dispatch(createAddEmailTemplateRequest(email_template));

  return oauth('POST', 'email-template', email_template).then(
    ({ json }) => dispatch(createFinalizeAddEmailTemplate(json.email_template)),
    ({ json }) => dispatch(createFailedAddEmailTemplate(email_template))
  );
};
