import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Avatar, Button, Col, H4, Popup, Row, Link } from '@commonsku/styles';
import { getImageSrc, getIdentityUtils, getAbsoluteUrl } from '../../utils';
import { useIdentity } from '../../hooks';

import { FromNow } from '../moment';
import { deleteCommunityEventUser } from './communityEventsSlice';

const DetailPopup = ({ event, onClose, ...props }) => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const { isAdmin } = getIdentityUtils(identity);
  const interested = !isEmpty(event.community_event_user_id);

  const style = {
    title: { fontWeight: 'bold', fontSize: '1.2rem', maxWidth: 460 },
    text: { fontSize: '1rem', maxWidth: 460 },
  };
  const company = event.company || {};
  const joinUrl = isAdmin() ? event.zoom_link : event.join_url;

  useEffect(() => {
    const notification = new Notification(
      event.name, {body: event.description, icon: '/images/favicons/favicon.ico', requireInteraction: true}
    );
    notification.onclick = () => {
      window.open(getAbsoluteUrl(`community_events.php?community_event_id=${event.community_event_id}`));
    };
  }, []);

  return <Popup style={{ padding: 20, minWidth: 800 }} width='auto' height='auto' header={
    <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
      <H4>{event.name}</H4>
      <Button onClick={onClose}>Close</Button>
    </Row>
  }>
    <Row style={{ padding: '15px 0 30px', alignItems: 'flex-start' }} justify="flex-start">
      <Col style={{ flexDirection: 'column', display: 'flex', flex: '0 0 180px' }}>
        {!event.recording_url
          ? <Button  style={{ margin: 5 }} onClick={(e) => {
            window.open(`/video.html?src=${encodeURIComponent(event.recording_url)}`);
          }}>Watch Recording</Button>
          : <Button cta onClick={(e) => {
            window.open(joinUrl);
            onClose(e);
          }}>
            <Col>
              <div>Join on Zoom</div>
              <div style={{ fontSize: '0.8rem' }}>
                Starting <FromNow value={event.scheduled_time}/>
              </div>
            </Col>
          </Button>
        }
        {interested && <Link style={{ fontSize: '0.8rem'}} onClick={() => {
          dispatch(deleteCommunityEventUser(event.community_event_user_id));
        }}>Remove me from attendee list</Link>}
      </Col>

      <Col style={{ marginLeft: 50, flexDirection: 'column', display: 'flex' }}>
        <Row justify={'flex-start'} style={{ alignItems: 'center' }}>
          <Avatar style={{ marginRight: 15 }} pic={getImageSrc(company)}/>
          <Row style={style.title}>Hosted by {company.company_name}</Row>
        </Row>
        <Row mt={10} justify={'flex-start'} style={{ alignItems: 'center' }}>
          <Avatar mr={15} pic={getImageSrc(event.host)}/>
          <Row style={style.text}>{`${event.host.contact_first_name} ${event.host.contact_last_name}`}</Row>
        </Row>
        <Row>For {event.audience}, {moment(event.scheduled_time).format('MMM D, YYYY')}</Row>
      </Col>
    </Row>
  </Popup>;
};

export default DetailPopup;

