import { useEffect, useState } from 'react';
import React from 'react';
import { CustomizationPanel } from '../shop/helpers';
import { Button, GalleryIcon, Label, MagicEraserIcon, MagicIcon, Row, Text, colors } from '@commonsku/styles';
import { Slider } from './Slider';
import './ImageEditorToolkit.css';

const MIN_BRUSH_SIZE = 10;
const MAX_BRUSH_SIZE = 200;

const MIN_LOGO_SCALE = 0.1;
const MAX_LOGO_SCALE = 10;

const MIN_LOGO_ROTATION = -180;
const MAX_LOGO_ROTATION = 180;

const COMMON_ROTATIONS = [30, 45];
const STICKY_ROTATION_DEGREES = 3;

export type ActivePanelState = 'erase' | 'logo' | null;

interface PanelProps {
  isOpen: boolean;
  togglePanel: () => void;
}

export type ImageEditorToolkitProps = Omit<
  {
    onActivePanelChange: (activePanel: ActivePanelState) => void
  }
  & AIEraseProps
  & LogoControlProps
  & BackgroundControlProps,
  keyof PanelProps
>

interface AIEraseProps extends PanelProps {
  brushSize: number;
  setBrushSize: (size: number) => void;
  showRefBrush: boolean;
  setShowRefBrush: (showBrush: boolean) => void;
  onErase: () => Promise<void>;
  eraseDisabled: boolean;
}

interface LogoControlProps extends PanelProps {
  hasLogo: boolean;
  onAddLogo?: () => void;
  onRemoveLogo?: () => void;
  logoControlDisabled: boolean;
  logoScale: number;
  setLogoScale: (scale: number) => void;
  logoRotation: number;
  setLogoRotation: (angle: number) => void;
}

interface BackgroundControlProps {
  onRemoveBackground: () => Promise<void>;
  removeBackgroundDisabled: boolean;
}

interface PanelButtonProps {
  title: string;
  Icon: (props: React.SVGAttributes<SVGElement>) => JSX.Element;
  onClick: () => void;
  disabled: boolean;
}

const PanelButton = ({title, Icon, onClick, disabled}: PanelButtonProps) => {
  return (
    <div className={"toolkit-panel-button" + (disabled ? " disabled" : '')}>
      <Row
        className="toolkit-title-row"
        onClick={disabled ? undefined : onClick}
      >
        <Icon color={disabled ? 'rgb(137, 156, 169)' : colors.neutrals['90']} />
        <Text as="p" className="panel-button-title">{title}</Text>
      </Row>
    </div>
  );
}

const AIEraseControl = ({
  isOpen,
  togglePanel,
  brushSize,
  setBrushSize,
  showRefBrush,
  setShowRefBrush,
  onErase,
  eraseDisabled,
}: AIEraseProps) => {
  const handleBrushSizeChange = (value: number) => {
    setBrushSize(value);

    if (!showRefBrush) {
      setShowRefBrush(true);
      window.setTimeout(() => {
        setShowRefBrush(false);
      }, 10000);
    }
  };

  return (
    <CustomizationPanel
      title={
        <Row className="toolkit-title-row">
          <MagicEraserIcon color={colors.neutrals['90']} />
          <Text>AI Erase</Text>
        </Row>
      }
      isOpen={isOpen}
      togglePanel={togglePanel}
    >
      <div className="editor-toolkit-panel">
        <Row className="toolkit-panel-row">
          <Label>
            Draw on the image to select the area you want to erase (for example, a logo).
          </Label>
        </Row>
        <Row className="toolkit-panel-row">
          <Text bold={true} style={{ color: colors.neutrals['90'] }}>
            Eraser Size
          </Text>
        </Row>
        <Row className="toolkit-panel-row">
          <Slider
            min={MIN_BRUSH_SIZE}
            max={MAX_BRUSH_SIZE}
            value={brushSize}
            onChange={handleBrushSizeChange}
            onClick={() => setShowRefBrush(false)}
          />
        </Row>
        <Row className="toolkit-panel-row">
          <Button
            disabled={eraseDisabled}
            onClick={onErase}
          >
            Erase Selected
          </Button>
        </Row>
      </div>
    </CustomizationPanel>
  );
};

const LogoControl = ({
  isOpen,
  togglePanel,
  hasLogo,
  onAddLogo,
  onRemoveLogo,
  logoControlDisabled,
  logoScale,
  setLogoScale,
  logoRotation,
  setLogoRotation,
}: LogoControlProps) => {
  if (!hasLogo && onAddLogo == null) return;

  const onClickAdd = () => {
    onAddLogo();
    togglePanel();
  }

  const onClickRemove = () => {
    if (!logoControlDisabled) {
      onRemoveLogo();
      togglePanel();
    }
  }

  const onRotate = (angle: number) => {
    // Rotations should be "sticky" to allow clean input of common angles
    COMMON_ROTATIONS.forEach(rotation => {
      if (Math.abs(angle % rotation) <= STICKY_ROTATION_DEGREES) {
        angle = Math.round(angle / rotation) * rotation;
      }
    })

    // A negative angle matches user expectation (slider moves right => logo rotates CW)
    angle *= -1;

    setLogoRotation(angle);
  }

  if (!hasLogo) {
    return (
      <PanelButton
        Icon={GalleryIcon}
        title="Add Logo"
        onClick={onClickAdd}
        disabled={logoControlDisabled}
      />
    );
  }

  return (
    <CustomizationPanel
      title={
        <Row className="toolkit-title-row">
          <GalleryIcon color={colors.neutrals['90']} />
          <Text>Add Logo</Text>
        </Row>
      }
      isOpen={isOpen}
      togglePanel={togglePanel}
    >
      <div className="editor-toolkit-panel">
        <Row className="toolkit-panel-row">
          <Label>
            <ul>
              <li>Drag the logo to reposition it</li>
              <li>Drag the corners to skew</li>
            </ul>
          </Label>
        </Row>
        <Row className="toolkit-panel-row">
          <Text bold={true} style={{ color: colors.neutrals['90'] }}>
            Scale
          </Text>
        </Row>
        <Row className="toolkit-panel-row">
          <Slider
            valueType="float"
            min={MIN_LOGO_SCALE}
            max={MAX_LOGO_SCALE}
            value={logoScale}
            onChange={setLogoScale}
          />
        </Row>
        <Row className="toolkit-panel-row">
          <Text bold={true} style={{ color: colors.neutrals['90'] }}>
            Rotate
          </Text>
        </Row>
        <Row className="toolkit-panel-row">
          <Slider
            valueType="float"
            min={MIN_LOGO_ROTATION}
            max={MAX_LOGO_ROTATION}
            value={-logoRotation}
            onChange={onRotate}
          />
        </Row>
        <Row className="toolkit-panel-row">
          <Text
            className="remove-logo-btn"
            onClick={onClickRemove}
          >
            Remove Logo
          </Text>
        </Row>
      </div>
    </CustomizationPanel>
  );
};

const BackgroundControl = ({
  onRemoveBackground,
  removeBackgroundDisabled,
}: BackgroundControlProps) => {
  return (
    <PanelButton
      Icon={MagicIcon}
      title="Remove Background"
      onClick={onRemoveBackground}
      disabled={removeBackgroundDisabled}
  />
  );
};

export const ImageEditorToolkit = (props: ImageEditorToolkitProps) => {
  const [activePanel, setActivePanel] = useState<ActivePanelState>();
  const { onActivePanelChange } = props;

  useEffect(() => onActivePanelChange(activePanel), [activePanel]);

  const togglePanel = (toggledPanel: ActivePanelState) => 
    () => (activePanel === toggledPanel)
      ? setActivePanel(null)
      : setActivePanel(toggledPanel);

  return (
    <div className="editor-toolkit">
      <AIEraseControl
        isOpen={activePanel === 'erase'}
        togglePanel={togglePanel('erase')} 
        {...props} 
      />
      <LogoControl
        isOpen={activePanel === 'logo'}
        togglePanel={togglePanel('logo')} 
        {...props} 
      />
      <BackgroundControl {...props} />
    </div>
  );
};
