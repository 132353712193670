import React, { useCallback, useEffect, useState } from 'react';
import config from '../config';
import useChat from './useChat';
import useChatUsers from './useChatUsers';
import usePeer from './usePeer';
import { generateUUID } from '../utils';
import { oauth } from '../utils';
import useOnlineAgents from './useOnlineAgents';
import useChatSupportSession from './useChatSupportSession';
import { window } from '../global';
import useChatbots from '../components/chat/hooks/useChatbots';

const WebsocketContext = React.createContext();
let ws;
const generatedId = generateUUID();
const locationUrl = window.location.href;

export const WebsocketContextProvider = ({
    identity,
    children,
    channel,
    notifyUser,
    canVoiceCall,
    canRemoteControl,
    tenantOptions,
    defaultColor,
    useChatSession = true,
    tenantId,
    getBots = true
}) => {
    const [doNotRetry, setDoNotRetry] = useState(false);
    const [bots, ,] = useChatbots(getBots);
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [connectionInited, setConnectionInited] = useState(false);
    const callEnabled = canVoiceCall ?? false;
    const [remoteControlEnabled, setRemoteControlEnabled] = useState(canRemoteControl ?? false);
    const [present, setPresent] = useState(true);
    const [followPresent, setFollowPresent] = useState(true);
    const [chats, addChat] = useChat([]);
    const [users, addChatUser, updateChatUser, setChatUserCallStatus] = useChatUsers([]);
    const [rootComponent, setRootComponent] = useState(null);
    const [agents] = useOnlineAgents(notifyUser);

    const [supportSessionCreated, setSupportSessionCreated] = useState(false);
    const [supportAgentOnline, setSupportAgentOnline] = useState(false);

    const [updateSupportSessions, setUpdateSupportSessions] = useState(false);

    const [supportUserName, setSupportUserName] = useState('');
    const [supportUserEmail, setSupportUserEmail] = useState('');

    const [newMessageCount, setNewMessageCount] = useState(0);

    const identityUser = identity ? `${identity.user_first_name}` : null;
    let chatUsername = identityUser ?? (supportUserName ? supportUserName : 'Anonymous');
    const callId = generatedId + '-' + chatUsername.trim();
    const identityId = identity ? `${identity.user_id}` : generatedId;
    chatUsername = chatUsername + '%%' + identityId;

    const sendMessage = useCallback((data, checkPresent = true) => {
        if (checkPresent && !present) {
            return false;
        }

        try {
            ws.send(data); //send data to the server
        } catch (error) {
            console.log(error); // catch error
        }
    }, [present]);

    const saveActivity = useCallback((chat, userId = false) => {
        oauth('POST', 'chat-user-activity', { 'user_id': userId ? userId : identity.user_id, 'activity': chat });
    }, [identity]);

    const [supportSession, supportSessionData, supportSessionLoading, supportSessionIsAgent] = useChatSupportSession({
        connected, supportSessionCreated, identity, generatedId, tenantId, locationUrl, agentIds: notifyUser, sendMessage, supportUserName, supportUserEmail
    });

    let shouldUsePeer = canVoiceCall && tenantOptions && tenantOptions.chat_welcome_message;
    const [callUser, handleAnswerCall, handleEndCall,] = usePeer(callId, setChatUserCallStatus, sendMessage, !shouldUsePeer);

    const receiveMessage = useCallback((evt) => {
        // Not in session yet, ignore everything
        if (useChatSession && !supportSession) {
            return false;
        }

        if (!followPresent) {
            return false;
        }
        const data = evt.data;
        const remoteCommand = data.split(':');

        if (remoteCommand[0] === 'chat') {
            setNewMessageCount(oldCount => oldCount + 1);
            const message = data.replace('chat:', '');
            addChat(message);

            // We update the chat user list as well
            const chatParts = message.split(':');
            const chatUserParts = chatParts[0].split('%%');
            updateChatUser(chatUserParts[1]);
            return;
        }

        if (remoteCommand[0] === 'new user') {
            addChatUser(data.replace('new user:', ''));
            return;
        }

        // The following method is not scalable.
        // We have disabled it.
        if (remoteCommand[0] === 'update user') {
            updateChatUser(data.replace('update user:', ''));
            return;
        }

        if (remoteCommand[0] === 'endcall') {
            handleEndCall(data.replace('endcall:', ''), false);
            return;
        }

        if (remoteCommand[0] === 'newclient') {
            addChat(data.replace('newclient:', ''));
            return;
        }

        if (remoteCommand[0] === 'updatesessions') {
            setUpdateSupportSessions(true);
            return;
        }

        if (remoteCommand[0] === 'agentonline') {
            setSupportAgentOnline(remoteCommand[1]);
            return;
        }

        if (remoteCommand[0] === 'disconnect') {
            setDoNotRetry(true);
        }

        if (!remoteControlEnabled) return;

        let target = rootComponent;

        let method = remoteCommand[0];
        let args = remoteCommand.slice(1);
        if (remoteCommand[0] === 'childGallery') {
            target = target.childGallery.current;
            method = remoteCommand[1];
            args = remoteCommand.slice(2);
        }
        target[method](...args, false);

    }, [addChat, addChatUser, followPresent, handleEndCall, remoteControlEnabled, rootComponent, supportSession, updateChatUser, useChatSession]);

    const handleAddChat = useCallback((chatMessage) => {
        if (chatMessage.trim().length === 0) return false;
        const message = `${chatUsername}: ${chatMessage}`;
        addChat(message);
        const socketMessage = `broadcast:chat:${message}`;
        sendMessage(socketMessage);
    }, [addChat, chatUsername, sendMessage]);

    useEffect(() => {
        if (!tenantOptions || !tenantOptions.chat_welcome_message) {
            // We shouldn't close this for password protected shop
            // ws.close();
        }
    }, [tenantOptions]);

    const setInitConnected = useCallback(() => {
        let chatChannel = channel ?? locationUrl;
        if (supportSession) chatChannel = supportSession;
        setConnected(true);
        setConnecting(false);
        setConnectionInited(true);
        // on connecting, do nothing but log it to the console
        sendMessage('setuser:' + callId);
        sendMessage('setchannel:' + chatChannel);

        // Notify client representive if notifyUser is set
        /*if (notifyUser) {
            for (const user of notifyUser) {
                if (!identity || user !== identity.user_id) {
                    const message = `${user}:${callId}:${channel ?? locationUrl}`;
                    sendMessage(`notify:${message}`);
                }
            }
        }*/
    }, [callId, channel, sendMessage, supportSession]);

    const setInitDisconnected = useCallback(() => {
        setConnected(false);
    }, []);

    const connectWebsocket = useCallback(() => {
        if (connected || connecting) return false;
        if (doNotRetry) return false;

        // Do not connect to avoid overload on the server if there is no bots
        if (getBots && (!bots || bots.length === 0)) {
            console.log('no bot is setup. No need to connect');
            return false;
        }

        setConnecting(true);
        ws = new WebSocket(config.websocketServer);
        // If we haven't got a connection yet, set it
        ws.onopen = () => {
            setInitConnected();
        };

        ws.onclose = () => {
            setInitDisconnected();
            setTimeout(() => setConnecting(false), 10000); // to avoid exccessive retry
        };

        // In case we already has a connection
        if (ws.readyState === 1) {
            setInitConnected();
        }

    }, [bots, connected, connecting, doNotRetry, getBots, setInitConnected, setInitDisconnected]);

    // Periodly check whether we have a disconnection
    // Reset websocket if needed
    useEffect(() => {
        connectWebsocket();
        const intervalId = setInterval(connectWebsocket, 10 * 1000); // Check connection every 10 sec
        return () => clearInterval(intervalId);
    }, [connectWebsocket]);

    if (ws && connected) {
        // Always set up a new version of callback
        // Important
        ws.onmessage = receiveMessage;
    }

    const wsContext = {
        chats,
        addChat,
        users,
        addChatUser,
        updateChatUser,
        setChatUserCallStatus,
        setPresent,
        setFollowPresent,
        sendMessage,
        handleAddChat,
        callUser,
        handleAnswerCall,
        handleEndCall,
        setRootComponent,
        saveActivity,
        callEnabled,
        remoteControlEnabled,
        setRemoteControlEnabled,
        agents,
        tenantOptions,
        connected,
        connecting,
        connectionInited,
        identityUser,
        identityId,
        defaultColor,
        supportSession,
        supportSessionData,
        supportSessionLoading,
        supportSessionIsAgent,
        supportAgentOnline,
        supportSessionCreated,
        setSupportSessionCreated,
        updateSupportSessions,
        setUpdateSupportSessions,
        supportUserName,
        setSupportUserName,
        supportUserEmail,
        setSupportUserEmail,
        newMessageCount,
        setNewMessageCount,
    };

    return (
        <WebsocketContext.Provider value={wsContext}>
            {children}
        </WebsocketContext.Provider>
    );
};

export default WebsocketContext;
