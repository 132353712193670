import { oauth } from '../utils';
import { getInventoryItemById } from '../selectors';

export const ENABLE_INVENTORY_REQUEST = 'ENABLE_INVENTORY_REQUEST';
export const ENABLE_INVENTORY_SUCCESS = 'ENABLE_INVENTORY_SUCCESS';
export const ENABLE_INVENTORY_FAILURE = 'ENABLE_INVENTORY_FAILURE';

const requestEnableInventory = (shop_id) => ({
  type: ENABLE_INVENTORY_REQUEST,
  payload: {
    shop_id
  }
});

const finalizeEnableInventory = (inventory) => ({
  type: ENABLE_INVENTORY_SUCCESS,
  payload: {
    inventory
  }
});

const failedEnableInventory = (shop_id, error) => ({
  type: ENABLE_INVENTORY_FAILURE,
  payload: {
    shop_id,
    error
  }
});

export const enableInventory = (shop_id) => dispatch => {
  dispatch(requestEnableInventory(shop_id));
  return oauth('POST', 'inventory', { shop_id }).then(
    ({ json }) => dispatch(finalizeEnableInventory(json.inventory)),
    ({ json }) => dispatch(failedEnableInventory(shop_id, json.error))
  );
};

export const LOAD_INVENTORY_REQUEST = 'LOAD_INVENTORY_REQUEST';
export const LOAD_INVENTORY_SUCCESS = 'LOAD_INVENTORY_SUCCESS';
export const LOAD_INVENTORY_FAILURE = 'LOAD_INVENTORY_FAILURE';

const requestLoadInventory = (inventory_id) => ({
  type: LOAD_INVENTORY_REQUEST,
  payload: {
    inventory_id
  }
});

const finalizeLoadInventory = (inventory) => ({
  type: LOAD_INVENTORY_SUCCESS,
  payload: {
    inventory
  }
});

const failedLoadInventory = (inventory_id, error) => ({
  type: LOAD_INVENTORY_FAILURE,
  payload: {
    inventory_id,
    error
  }
});

export const loadInventory = (inventory_id) => dispatch => {
  dispatch(requestLoadInventory(inventory_id));
  return oauth('GET', `inventory/${inventory_id}`, {}).then(
    ({ json }) => dispatch(finalizeLoadInventory(json.inventory)),
    ({ json }) => dispatch(failedLoadInventory(inventory_id, json.error))
  );
};

export const UPDATE_INVENTORY_REQUEST = 'UPDATE_INVENTORY_REQUEST';
export const UPDATE_INVENTORY_SUCCESS = 'UPDATE_INVENTORY_SUCCESS';
export const UPDATE_INVENTORY_FAILURE = 'UPDATE_INVENTORY_FAILURE';

const requestUpdateInventory = (inventory_id, data) => ({
  type: UPDATE_INVENTORY_REQUEST,
  payload: {
    inventory_id,
    data
  }
});

const finalizeUpdateInventory = (inventory) => ({
  type: UPDATE_INVENTORY_SUCCESS,
  payload: {
    inventory
  }
});

const failedUpdateInventory = (inventory_id, data, error) => ({
  type: UPDATE_INVENTORY_FAILURE,
  payload: {
    inventory_id,
    data,
    error
  }
});

export const updateInventory = (inventory_id, field, value) => dispatch => {
  const data = {[field]: value};
  dispatch(requestUpdateInventory(inventory_id, data));
  return oauth('PUT', `inventory/${inventory_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateInventory(json.inventory)),
    ({ json }) => dispatch(failedUpdateInventory(inventory_id, data, json.error))
  );
};

export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE';

const requestUpdateWarehouse = (warehouse_id, data) => ({
  type: UPDATE_WAREHOUSE_REQUEST,
  payload: {
    warehouse_id,
    data
  }
});

const finalizeUpdateWarehouse = (warehouse) => ({
  type: UPDATE_WAREHOUSE_SUCCESS,
  payload: {
    warehouse
  }
});

const failedUpdateWarehouse = (warehouse_id, data, error) => ({
  type: UPDATE_WAREHOUSE_FAILURE,
  payload: {
    warehouse_id,
    data,
    error
  }
});

export const updateWarehouse = (warehouse_id, field, value) => dispatch => {
  const data = {[field]: value};
  dispatch(requestUpdateWarehouse(warehouse_id, data));
  return oauth('PUT', `inventory-warehouse/${warehouse_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateWarehouse(json.warehouse)),
    ({ json }) => dispatch(failedUpdateWarehouse(warehouse_id, data, json.error))
  );
};

export const CREATE_INVENTORY_ITEM_REQUEST = 'CREATE_INVENTORY_ITEM_REQUEST';
export const CREATE_INVENTORY_ITEM_SUCCESS = 'CREATE_INVENTORY_ITEM_SUCCESS';
export const CREATE_INVENTORY_ITEM_FAILURE = 'CREATE_INVENTORY_ITEM_FAILURE';

const requestCreateInventoryItem = ({ inventory_id, source_item_id, product_sku_id, size_id, color_id }) => ({
  type: CREATE_INVENTORY_ITEM_REQUEST,
  payload: {
    inventory_id,
    source_item_id,
    product_sku_id,
    size_id,
    color_id
  }
});

const finalizeCreateInventoryItem = (inventory_item) => ({
  type: CREATE_INVENTORY_ITEM_SUCCESS,
  payload: {
    inventory_item
  }
});

const failedCreateInventoryItem = ({ inventory_id, source_item_id, product_sku_id, size_id, color_id }, error) => ({
  type: CREATE_INVENTORY_ITEM_FAILURE,
  payload: {
    inventory_id,
    source_item_id,
    product_sku_id,
    size_id,
    color_id,
    error
  }
});

export const createInventoryItems = (inventory_items) => dispatch => {
  inventory_items.forEach(ii => dispatch(requestCreateInventoryItem(ii)));
  return Promise.all(
    inventory_items.map(ii => oauth('POST', 'inventory-item', ii).then(
      ({ json }) => dispatch(finalizeCreateInventoryItem(json.inventory_item)),
      ({ json }) => dispatch(failedCreateInventoryItem(ii, json.error))
    ))
  );
};

export const UPDATE_INVENTORY_ITEM_REQUEST = 'UPDATE_INVENTORY_ITEM_REQUEST';
export const UPDATE_INVENTORY_ITEM_SUCCESS = 'UPDATE_INVENTORY_ITEM_SUCCESS';
export const UPDATE_INVENTORY_ITEM_FAILURE = 'UPDATE_INVENTORY_ITEM_FAILURE';

const requestUpdateInventoryItem = (inventory_item_id, field, value) => ({
  type: UPDATE_INVENTORY_ITEM_REQUEST,
  payload: {
    inventory_item_id,
    [field]: value
  }
});

const finalizeUpdateInventoryItem = (inventory_item) => ({
  type: UPDATE_INVENTORY_ITEM_SUCCESS,
  payload: {
    inventory_item
  }
});

const failedUpdateInventoryItem = (inventory_item_id, field, value, previous_value, error) => ({
  type: UPDATE_INVENTORY_ITEM_FAILURE,
  payload: {
    inventory_item_id,
    field,
    [field]: value,
    previous_value,
    error
  }
});

export const updateInventoryItem = (inventory_item_id, field, value) => (dispatch, getState) => {
  const previous = getInventoryItemById(getState(), { inventory_item_id });
  dispatch(requestUpdateInventoryItem(inventory_item_id, field, value));
  return oauth('PUT', `inventory-item/${inventory_item_id}`, { [field]: value }).then(
    ({ json }) => dispatch(finalizeUpdateInventoryItem(json.inventory_item)),
    ({ json }) => dispatch(failedUpdateInventoryItem(inventory_item_id, field, value, previous[field], json.error))
  );
};

export const DELETE_INVENTORY_ITEM_REQUEST = 'DELETE_INVENTORY_ITEM_REQUEST';
export const DELETE_INVENTORY_ITEM_SUCCESS = 'DELETE_INVENTORY_ITEM_SUCCESS';
export const DELETE_INVENTORY_ITEM_FAILURE = 'DELETE_INVENTORY_ITEM_FAILURE';

const requestDeleteInventoryItem = (inventory_item_id) => ({
  type: DELETE_INVENTORY_ITEM_REQUEST,
  payload: {
    inventory_item_id
  }
});

const finalizeDeleteInventoryItem = (inventory_item_id) => ({
  type: DELETE_INVENTORY_ITEM_SUCCESS,
  payload: {
    inventory_item_id
  }
});

const failedDeleteInventoryItem = (inventory_item_id, error) => ({
  type: DELETE_INVENTORY_ITEM_SUCCESS,
  payload: {
    inventory_item_id,
    error
  }
});

export const deleteInventoryItem = inventory_item_id => dispatch => {
  dispatch(requestDeleteInventoryItem(inventory_item_id));
  return oauth('DELETE', `inventory-item/${inventory_item_id}`, {}).then(
    ({ json }) => dispatch(finalizeDeleteInventoryItem(inventory_item_id)),
    ({ json }) => dispatch(failedDeleteInventoryItem(inventory_item_id, json.error))
  );
};
