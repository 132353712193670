import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/item';
import { createUpdate, createFinalizeUpdate, createAddItemCost, createDeleteItemCost, createDeleteItemRetailAdjustment } from '../../actions';
import { createUpdateNetCostPopup, createSelectSkuOrAxisOptionPopup, createApplyMatrixToItemPopup } from '../../actions/popup';

import { getOrder, getItem, getOptionItems, getItemRunCosts, getItemLocations, getItemAdjustments, getShopType} from '../../selectors/items';

import { applyMargin, formatMoney, recalculateMargin } from '../../utils';
import { getExtraCharges } from '../../promostandard_utils';

import Field from './Field';
import ChargeMenu from './ChargeMenu';
import FixedCharges from './FixedCharges';
import PriceLabel from './PriceLabel';
import { TableIcon } from '@commonsku/styles';

const updateNextCostPopup = (item_id, locked) => (dispatch, getState) => {
  const item = getItem(getState(), { item_id });
  dispatch(createUpdateNetCostPopup(item, locked));
};

const updateItemSimple = createUpdate('item', {
  request: actions.SIMPLE_UPDATE_ITEM_REQUEST,
  success: actions.SIMPLE_UPDATE_ITEM_SUCCESS,
  failure: actions.SIMPLE_UPDATE_ITEM_FAILURE,
  failure_message: 'Unable to update item'
});

const baseUpdateBreakdownComplex = createUpdate('breakdown', {
  request: actions.COMPLEX_UPDATE_BREAKDOWN_REQUEST,
  success: actions.COMPLEX_UPDATE_BREAKDOWN_SUCCESS,
  failure: actions.COMPLEX_UPDATE_BREAKDOWN_FAILURE,
  failure_message: 'Unable to update breakdown'
});
const updateRequestBreakdownComplex = createFinalizeUpdate(actions.COMPLEX_UPDATE_BREAKDOWN_REQUEST);

const baseUpdateItemComplex = createUpdate('item', {
  request: actions.COMPLEX_UPDATE_ITEM_REQUEST,
  success: actions.COMPLEX_UPDATE_ITEM_SUCCESS,
  failure: actions.COMPLEX_UPDATE_ITEM_FAILURE,
  failure_message: 'Unable to update item'
});
const updateRequestItemComplex = createFinalizeUpdate(actions.COMPLEX_UPDATE_ITEM_REQUEST);
const updateCost = createUpdate('item-cost', {
  request: actions.UPDATE_ITEM_COST_REQUEST,
  success: actions.UPDATE_ITEM_COST_SUCCESS,
  failure: actions.UPDATE_ITEM_COST_FAILURE,
  failure_message: 'Unable to update item cost'
});
const changeCost = createFinalizeUpdate(actions.UPDATE_ITEM_COST_REQUEST);
const updateAdjustment = createUpdate('item-retail-adjustment', {
  request: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST,
  success: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS,
  failure: actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_FAILURE,
  failure_message: 'Unable to update up charge'
});
const changeAdjustment = createFinalizeUpdate(actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST);

class ItemHiddenField extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.hidden !== this.props.hidden;
  }

  handleToggle(e) {
    const { dispatch, item_id, hidden } = this.props;
    dispatch(updateItemSimple(item_id, 'hidden', hidden ? 1 : 0, hidden ? 0 : 1, { field_lock: 'unit_cost' }));
  }

  render() {
    const { hidden } = this.props;
    return (
      <th className="th-col-2">
        <label className="checkbox-label">Hidden
          <input type="checkbox" checked={hidden} onChange={this.handleToggle} />
          <span className="checkmark" />
        </label>
      </th>
    );
  }
}

class ItemCost extends React.Component {

  constructor(props) {
    super(props);

    ['handleDeleteCost', 'handleChangeCost', 'handleClickDecorationMatrix', 'handleUpdateItemCost'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleDeleteCost(e) {
    e.preventDefault();
    const { dispatch, item_cost_id } = this.props;
    dispatch(createDeleteItemCost(item_cost_id));
  }

  handleChangeCost(id, field, prev_value, value) {
    const { dispatch, request, options, item_cost_id } = this.props;
    return changeCost(id, 'unit_cost', prev_value, value);
  }

  handleUpdateItemCost(value, _field, error) {
    const { field_lock } = this.props;
    if (error) {
      return;
    }
    const [id, field] = _field.split('.');
    const cost = this.props.options.filter(o => o.item_cost_id === id)[0] || this.props;
    this.props.dispatch(updateCost(id, field, cost[field], value, { field_lock }));
  }

  handleClickDecorationMatrix(e) {
    const { item_cost_id, item_location_id, options } = this.props;
    e.preventDefault();
    this.props.dispatch(createApplyMatrixToItemPopup({
      qty: null,
      charge_type: 'run',
      onAddItemCost: this.handleUpdateItemCost,
      isPresentation: true,
      presentation_item_data: {
        item_options: options,
        item_option_qty_key: 'total_units',
        item_costs: options,
        item_cost_key: 'item_cost_id',
      },
      unit_cost_field: 'unit_cost',
      label_field: item_cost_id + '.item_cost_title',
      qty_field: null,
      item_location_id,
      item_cost_id
    }));
  }

  renderDecorationMatrix() {
    return (
      <TableIcon style={{ position: 'absolute', width: '2rem',  top: '.35rem', right: '0.25rem' }} onClick={this.handleClickDecorationMatrix} />
    );
  }

  render() {
    const { item_cost_id, ext_cost_id, item_cost_title, location_index, location_title, options, locked, isPS, division_id } = this.props;

    const extraCharges = getExtraCharges(division_id);
    const isEditableCharge = () => {
      if (ext_cost_id === null) {
        return true;
      }
      const charge = extraCharges.find(
        c => c.ps_charge_id == ext_cost_id && 'RUN' === c.charge_type.toUpperCase()
      );
      return !!charge && charge.is_editable;
    };

    return (
      <tr className="hover-x-container">
        <td className="flex-label">
          <div className="hover-x"><a onClick={this.handleDeleteCost}>&times;</a></div>
          <div className="icon-label-container" style={{ width: '100%', position: 'relative' }}>
            {!!location_index && <div className="icon-label" title={location_title}>{location_index}</div>}
            <Field
              id={item_cost_id}
              value={item_cost_title}
              update={updateCost}
              field="item_cost_title"
              className={!!location_index ? 'icon-field' : 'field1'}
              dispatch={this.props.dispatch}
              disabled={locked || !isEditableCharge()}
            />
	    {(!locked && !isPS && !!location_index) && this.renderDecorationMatrix()}
          </div>
        </td>
        {options.map(c => <td key={c.item_cost_id} style={{ opacity: c.hidden ? 0.5 : 1 }}>
          <Field
            id={c.item_cost_id}
            value={c.unit_cost}
            update={updateCost}
            request={this.handleChangeCost}
            decimals={4}
            field="unit_cost"
            dispatch={this.props.dispatch}
            disabled={locked}
          />
        </td>)}
      </tr>
    );
  }
}

class ItemAdjustment extends React.Component {

  constructor(props) {
    super(props);

    ['handleDeleteAdjustment', 'handleChangeAdjustment', 'handleChangeAxis'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleDeleteAdjustment(e) {
    e.preventDefault();
    const { dispatch, item_retail_adjustment_id } = this.props;
    return dispatch(createDeleteItemRetailAdjustment(item_retail_adjustment_id));
  }

  handleChangeAdjustment(id, field, prev_value, value) {
    const { dispatch, request, item_id, item_retail_adjustment_id } = this.props;
    return dispatch(changeAdjustment(id, 'adjustment', prev_value, value));
  }

  handleChangeAxis(e) {
    e.preventDefault();
    const { dispatch, item_retail_adjustment_id, axis, option, sku, item_id, product_id } = this.props;
    return dispatch(createSelectSkuOrAxisOptionPopup(product_id, !!axis ? 'axis' : 'sku', !!axis ? { axis, option } : { sku }, item_id, item_retail_adjustment_id));
  }

  render() {
    const { item_retail_adjustment_id, adjustment, axis, option, sku, locked } = this.props;
    const textTransform = 'color' === axis ? 'capitalize' : ('size' === axis ? 'uppercase' : undefined);
    return (
      <tr className="hover-x-container">
        <td className="flex-label">
          <div className="hover-x"><a onClick={this.handleDeleteAdjustment}>&times;</a></div>
          <input
            type="text"
            disabled={true}
            className="field1"
            onClick={this.handleChangeAxis}
            value={!!sku ? sku : option}
            style={{ textTransform }}
          />
        </td>
        <td>
          <Field
            id={item_retail_adjustment_id}
            value={adjustment}
            update={updateAdjustment}
            request={this.handleChangeAdjustment}
            decimals={2}
            field="adjustment"
            dispatch={this.props.dispatch}
            disabled={locked}
          />
        </td>
        <td colSpan={4}>&nbsp;</td>
      </tr>
    );
  }
}

class Pricing extends React.Component {

  constructor(props) {
    super(props);

    this.state = { field_lock: 'unit_cost' };

    [
      'handleToggleSharePricing',
      'handleAddProductRunCharge',
      'handleAddAdjustment',
      'handleCheckPricing'
    ].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  static getDerivedStateFromProps(props) {
    return props.options.reduce(
      (t, o, idx) => {
        const total_cost = formatMoney(parseFloat(o.unit_cost) + props.costs.reduce(
          (t, c) => t + parseFloat(get(c, ['options', idx, 'unit_cost']) || 0),
          0
        ), 4);
        const total_price = 0 == total_cost ? formatMoney(o.unit_price) : formatMoney(applyMargin(total_cost, o.total_margin, Number(o.exchange_rate)));
        return {
          ...t,
          [o.item_id]: {
            unit_cost: total_cost,
            total_cost,
            total_margin: o.total_margin,
            total_price
          }
        };
      },
      {}
    );
  }

  handleToggleSharePricing(e) {
    const { dispatch, item_id, share_pricing } = this.props;
    const { field_lock } = this.state;
    dispatch(updateItemSimple(item_id, 'share_pricing', share_pricing ? 0 : 1, share_pricing ? 1 : 0, { field_lock }));
  }

  handleAddProductRunCharge(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    dispatch(createAddItemCost(item_id, null, null, true));
  }

  handleAddAdjustment(e) {
    e.preventDefault();
    const { dispatch, item_id, product_id } = this.props;
    dispatch(createSelectSkuOrAxisOptionPopup(product_id, 'axis', null, item_id));
  }

  handleCheckPricing(e) {
    e.preventDefault();
    const { item_id, dispatch, locked } = this.props;
    dispatch(updateNextCostPopup(item_id, locked));
  }

  render() {
    const {
      item_id,
      currency_id,
      share_pricing,
      options,
      costs,
      adjustments,
      locations,
      locked,
      isPS,
      division_id,
      hasRetailPrice,
      retail_price,
      order_type,
      is_shop_free
    } = this.props;
    const { field_lock } = this.state;
    const isCostChanged = isPS && options.reduce((t, o) => t || 1 == o.costs_changed, false);

    const updateBreakdownComplex = (id, field, previous_value, value) => baseUpdateBreakdownComplex(id, field, previous_value, value, { field_lock });
    const updateItemComplex = (id, field, previous_value, value) => baseUpdateItemComplex(id, field, previous_value, value, { field_lock });

    return (
      <React.Fragment>
        <div className="row">
          <div className="large-9 columns flex-hdr-row">
            <h2>Pricing ({currency_id})</h2>
            {'SHOP' !== order_type && <div className="checkbox">
              <label className="checkbox-container">Hide all from client
                <input type="checkbox" checked={!share_pricing} onChange={this.handleToggleSharePricing} disabled={locked} />
                <span className="checkmark" />
              </label>
            </div>}
          </div>
          <div className="large-3 columns">
            {!isPS && <a className="medium-button secondary-button button under-img-btn" onClick={this.handleCheckPricing}>Check Pricing</a>}
          </div>
        </div>

        <div className="row" style={{ margin: 0 }}>
          <div className="table-wrapper" style={{ overflow: 'visible' }}>
            <table className="pricing-table1 unstriped" style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th className="th-col-1">&nbsp;</th>
                  {options.map(o => <ItemHiddenField
                    key={o.item_id}
                    item_id={o.item_id}
                    hidden={o.hidden}
                    dispatch={this.props.dispatch}
                    disabled={locked}
                  />)}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-row-title"><label>Quantity</label></td>
                  {options.map(o => <td key={o.item_id} style={{ opacity: o.hidden ? 0.5 : 1 }}>
                    <Field
                      id={o.breakdown_id}
                      value={o.quantity}
                      update={updateBreakdownComplex}
                      decimals={0}
                      field="quantity"
                      dispatch={this.props.dispatch}
                      disabled={locked}
                    />
                  </td>)}
                </tr>
                <tr>
                  <td className="table-row-title"><label>Cost ($)</label> {isCostChanged && <i title="The costs have been updated from those provided by the supplier.  The cost displayed will be used for all SKUs." style={{ color: '#BF3F69' }} className="fi-info"></i>}</td>
                  {options.map(o => <td key={o.item_id} style={{ opacity: o.hidden ? 0.5 : 1 }}>
                    <Field
                      id={o.breakdown_id}
                      value={o.unit_cost}
                      update={updateBreakdownComplex}
                      request={updateRequestBreakdownComplex}
                      decimals={4}
                      field="unit_cost"
                      dispatch={this.props.dispatch}
                      disabled={locked}
                    />
                  </td>)}
                </tr>
                <tr>
                  <td colSpan={6}><hr /></td>
                </tr>
                {costs.map(c => <ItemCost
                  key={c.item_cost_id}
                  {...c}
                  division_id={division_id}
                  dispatch={this.props.dispatch}
                  locked={locked}
                  field_lock={field_lock}
                />)}
                {!locked ? (
                  !isPS ? <React.Fragment>
                    <tr>
                      <td colSpan={6}>
                        <a onClick={this.handleAddProductRunCharge}>+ add product run charge</a>
                        <ChargeMenu item_id={item_id} charge_type="RUN" isPS={isPS}>
                          <a style={{ marginLeft: '2rem' }}>+ add decoration run charge</a>
                        </ChargeMenu>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6}>
                        <hr />
                      </td>
                    </tr>
                  </React.Fragment> :
                  <ChargeMenu item_id={item_id} charge_type="RUN" isPS={isPS} container={menu => (
                    <React.Fragment>
                      <tr>
                        <td colSpan={6}>{menu}</td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <hr />
                        </td>
                      </tr>
                    </React.Fragment>
                  )}>
                    <a>+ add decoration run charge</a>
                  </ChargeMenu>
                ) : (costs.length > 0) && <tr>
                    <td colSpan={6}><hr /></td>
                  </tr>
                }
                <tr>
                  <td className="table-row-title"><label>Margin (%)</label></td>
                  {options.map(o => <td key={o.item_id} style={{ opacity: o.hidden ? 0.5 : 1 }}>
                    <Field
                      id={o.item_id}
                      value={this.state[o.item_id].total_margin}
                      update={updateItemComplex}
                      request={updateRequestItemComplex}
                      decimals={2}
                      field="total_margin"
                      dispatch={this.props.dispatch}
                      disabled={locked}
                    />
                  </td>)}
                </tr>
                <tr>
                  <td className="table-row-title"><label>Total ($)</label></td>
                  {options.map(o => <td key={o.item_id} style={{ opacity: o.hidden ? 0.5 : 1 }}>
                    <Field
                      id={o.item_id}
                      value={this.state[o.item_id].total_price}
                      update={updateItemComplex}
                      request={(id, field, previous_value, value) => updateRequestItemComplex(id, field, previous_value, value, { item: this.state[o.item_id] })}
                      decimals={2}
                      field="unit_price"
                      dispatch={this.props.dispatch}
                      disabled={locked}
                    />
                  </td>)}
                </tr>
                {hasRetailPrice && <tr>
                  <td className="table-row-title"><label>Retail ($)</label></td>
                  <td>
                    <Field
                      id={item_id}
                      value={is_shop_free == 1 ? 0 : retail_price}
                      update={updateItemComplex}
                      request={updateRequestItemComplex}
                      decimals={2}
                      field="retail_price"
                      dispatch={this.props.dispatch}
                      disabled={locked}
                    />
                  </td>
                  <td colSpan={4}>&nbsp;</td>
                </tr>}
                <tr>
                  <td className="table-row-title"><label>Up Charges ($)</label></td>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                {adjustments.map(a => <ItemAdjustment
                  key={a.item_retail_adjustment_id}
                  {...a}
                  dispatch={this.props.dispatch}
                  locked={locked}
                />)}
                {!locked && <tr>
                  <td colSpan={6}>
                    <a onClick={this.handleAddAdjustment}>+ add up charge</a>
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
        <hr style={{ marginBottom: '1rem' }} />
        <FixedCharges item_id={item_id} locked={locked} isPS={isPS} field_lock={field_lock} />
        <hr style={{ marginBottom: '1rem' }} />
        <PriceLabel item_id={item_id} locked={locked} isPS={isPS} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrder(state, ownProps);
  const item = getItem(state, ownProps);
  const options = getOptionItems(state, ownProps);
  const costs = getItemRunCosts(state, ownProps);
  const locations = getItemLocations(state, ownProps);
  const adjustments = getItemAdjustments(state, ownProps);

  return {
    order_type: order.order_type,
    hasRetailPrice: 'SHOP' === order.order_type && 1 == order.aggregate,
    retail_price: item.retail_price,
    currency_id: item.currency_id,
    share_pricing: item.share_pricing == 1,
    product_id: item.parent_id,
    division_id: item.division_id,
    options,
    costs,
    adjustments,
    locations
  };
};

export default connect(mapStateToProps)(Pricing);
