import React from 'react';

import createPopup from '../popup-factory';

const Modal = ({ modal, ...props }) => {
  const m = React.cloneElement(modal, props);
  const popups = props.popups || [];
  return (
    <div className="row full-width content">
      {m}
      {popups.map((p, k) => createPopup(p, k, props))}
    </div>
  );
};

export default Modal;
