import { find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from './Select';
import PS from './PromostandardsLabel';
import { createUpdateItemDecoration } from '../actions';

class PSDecorationSelect extends Component {
  render() {
    const { item_decoration, decorations, onUpdatePSImprint } = this.props;
    const { item_decoration_id, imprint_id } = item_decoration || {};
    const options = decorations.map(
      d => ({
        key: d.ps_decoration_id,
        value: <span><PS />{d.decoration_name}</span>
      })
    );

    return <Select
      placeholder="Select an imprint type..."
      options={options}
      value={+item_decoration.ext_decoration_id}
      change={(ps_decoration_id) => {
        const decoration = find(decorations, { ps_decoration_id });
        return onUpdatePSImprint(item_decoration_id, imprint_id, {
          ext_decoration_id: decoration.ps_decoration_id,
          ext_location_id: decoration.ps_location_id,
          imprint_name: decoration.decoration_name,
        });
      }}
      style={{ margin: 0 }}
    />;
  }
}

export default connect(
  (state, ownProps) => {
    return {};
  },
  (dispatch, ownProps) => {
    return {
      onUpdatePSImprint: (item_decoration_id, previous_value, value) => {
        return dispatch(createUpdateItemDecoration(
          item_decoration_id, 'ps_imprint', previous_value, value
        ));
      },
    };
  }
)(PSDecorationSelect);
