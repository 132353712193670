import React from 'react';
import { DEFAULT_COLOR } from './utils';

export default function Orders({
  hover,
  item,
  padding,
  aggregate,
  onAddItem,
  onRemoveItem,
  template_color = DEFAULT_COLOR,
  showItemCount = true,
  useV2 = false,
  right = 0,
}) {
  const visible_options = item.options.filter(o => 0 === +o.hidden && 0 !== +o.total_units);

  if ((!item.quantity && !hover) || !visible_options.length && !aggregate) {
    return null;
  }

  const style = {
    position: 'absolute',
    top: 2 * padding || 0,
    right: right,
    background: template_color,
    color: 'white',
    opacity: '0.8',
    borderBottomLeftRadius: '5rem',
    padding: '0.5rem 0.5rem 1rem 1rem',
    fontWeight: 'bold',
    fontSize: item.quantity ? '1.5em' : '2em',
    lineHeight: '1rem',
    cursor: 'pointer',
    ...(useV2 ? {
      top: 20,
      right: 20,
      background: 'var(--color-secondary1-60)',
      opacity: '1',
      borderRadius: '5rem',
      padding: '6px',
      lineHeight: '22px',
      fontSize: '16px',
    } : {}),
  };

  const handleClick = e => {
    e.stopPropagation();
    if (showItemCount || !item.quantity) {
      onAddItem(item.item_id, item.parent_type);
    } else {
      onRemoveItem(item.item_id);
    }
  };

  if (!item.quantity) {
    return null;
  }

  return (
    <div style={style} onClick={handleClick}>
      {item.quantity ? (showItemCount ? item.quantity : <i className="fi-check"></i>) : '+'}
    </div>
  );
}
