import { useEffect, useState } from 'react';

const useOnMount = (callback) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      callback();
    }
  }, [mounted, callback]);

  return {
    reset: () => {
      setMounted(false);
    },
  };
};

export default useOnMount;
