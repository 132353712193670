import React, { Component } from 'react';

import BaseItemSummary from './BaseItemSummary';
import DragItemTypes from '../DragItemTypes';
import { withDraggableItem } from './DraggableItem';
import { getImageSrc } from '../utils';
import { connect } from 'react-redux';
import { getShopByOrderId } from '../selectors';

class TitleItemSummary extends BaseItemSummary {

  constructor(props) {
    super(props);

    this.state = { value: props.item.item_name };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ value });
  }

  renderActions() {
    return (
      <span className="actions">
        {this.renderAddAction()}
        {['ESTIMATE', 'PRESENTATION'].includes(this.props.order_type) ? this.renderToggleAction('hidden', 'hide', 'show') : null}
        {this.renderDeleteAction()}
      </span>
    );
  }

  renderTitleImages() {
    const { item, onDeleteItemThumb } = this.props;
    return item.item_images.map((image) =>  {
      const handleThumbDelete = e => {
        onDeleteItemThumb(image);
      };
      return (
      <div className="title-thumb">
        <img src={getImageSrc(image, 'small')} alt="thumb" />
        <div className="delete-button">
          <a class="button" onClick={handleThumbDelete}>&times;</a>
        </div>
      </div>
    );});
  }

  renderItem() {
    const { item, onUpdateItem, detailed, locked, onUpdateItemThumb } = this.props;
    const showImage = ['SHOP'].includes(this.props.order_type) && this.props.is_image_enabled;

    const handleUpdate = e => {
      onUpdateItem(item.item_id, 'item_name', item.item_name)(this.state.value);
    };

    const handleThumbClick = e => {
      onUpdateItemThumb(item.item_id, 'item_description');
    };

    return (
      <tr className="title">
        <td colSpan={detailed ? 7 : 5}>
            <input
              className="title-name"
              disabled={locked}
              value={this.state.value}
              onChange={this.handleChange}
              onBlur={handleUpdate}
              placeholder={'COLLECTION' === this.props.order_type ? 'Add a category' : 'Add a title'}
              style={{ 'fontWeight' : 'bold', 'border' : '1px solid #ddd', 'marginTop' : '5px' }}
            />
            {showImage && <div className="title-images">
              {this.renderTitleImages()}
              {item.item_images.length < 2 && <div className="title-add-thumb" onClick={handleThumbClick}>
                <a className="button">Add Image</a>
              </div>}
            </div>}
            {this.renderActions()}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_image_enabled: !('SHOP' === ownProps.order_type && 'SEPARATOR' === ownProps.item.parent_type),
  shop: getShopByOrderId(state, { order_id: ownProps.item.order_id }), // todo: decide to keep or delete this line
});

export default connect(mapStateToProps, null)(withDraggableItem(DragItemTypes.ITEM, TitleItemSummary));
