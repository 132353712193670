import { useState, useEffect, useCallback } from 'react';
import { fetchApi } from '../utils';

function useOnlineAgents(agentIds = []) {
    const [agents, setAgents] = useState([]);

    const getAgents = useCallback(async () => {
        if (agentIds.length === 0) return false;
        const data = await fetchApi('chat-online-agent', {ids:agentIds.join(',')});
        setAgents(data.json.agents);
    }, [agentIds]);

    useEffect(() => {
        getAgents();
        const intervalId = setInterval(getAgents, 30 * 1000);
        return () => clearInterval(intervalId);
    }, [getAgents]);

    return [
        agents,
    ];
}

export default useOnlineAgents;