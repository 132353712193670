import React from 'react';
import { Button, } from '@commonsku/styles';

const CheckoutPanelSaveButton = ({ color, style={}, onClick, children='Save', ...props }) => (
  <Button
    float='right'
    style={{ background: color, borderColor: color, ...style }}
    className='shop-checkout-box-save-btn'
    size='medium'
    onClick={onClick}
    {...props}
  >{children}</Button>
);

export default CheckoutPanelSaveButton;
